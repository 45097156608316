import './styles.modules.scss';
import React, { Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Redirect, useLocation } from 'react-router';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { PartialRequired } from '~types/utils';
import IndividualPageLayout from '~view/widgets/IndividualPageLayout';
import IndividualRoute, {
  IndividualRouteProps,
} from '~view/widgets/IndividualRoute';
import LoadingContainer from '~view/widgets/LoadingContainer';
import logo from '~/assets/images/futura_homula_logo_white.svg';
import { Link } from 'react-router-dom';

//pages
const Home = React.lazy(() => import('~view/pages/individual/Home'));
const SupplierGroup = React.lazy(
  () => import('~view/pages/individual/SupplierGroup')
);
const ProductList = React.lazy(
  () => import('~view/pages/individual/ProductList')
);
const ProductDetails = React.lazy(
  () => import('~view/pages/individual/ProductDetails')
);
const Collection = React.lazy(
  () => import('~view/pages/individual/CollectionDetail')
);
const PrivateOrder = React.lazy(
  () => import('~view/pages/individual/PrivateOrder')
);
const ShoppingCart = React.lazy(
  () => import('~view/pages/individual/ShoppingCart')
);
const Checkout = React.lazy(() => import('~view/pages/individual/Checkout'));
const PaymentTerm = React.lazy(
  () => import('~view/pages/individual/PaymentTerm')
);
const OrderConfirmation = React.lazy(
  () => import('~view/pages/individual/OrderConfirmation')
);
const OrderComplete = React.lazy(
  () => import('~view/pages/individual/OrderComplete')
);
const Messages = React.lazy(() => import('~view/pages/common/Messages'));
const Profile = React.lazy(
  () => import('~view/pages/individual/Account/Profile')
);
const ProfileEdit = React.lazy(
  () => import('~view/pages/individual/Account/ProfileEdit')
);
const ChangePassword = React.lazy(
  () => import('~view/pages/individual/Account/ChangePassword')
);
const OrderHistory = React.lazy(
  () => import('~view/pages/individual/OrderHistory')
);

const IndividualRoutes: React.FC = () => {
  const authUser = useSelector(authUserSelector);
  const publicRoutes: PartialRequired<
    IndividualRouteProps,
    'path' | 'title'
  >[] = [
    {
      path: '/private-order/:brand_id',
      exact: true,
      component: PrivateOrder,
      title: 'プライベートオーダー',
    },
    {
      path: '/group/:group_id',
      exact: true,
      render: () => (
        <>
          <header styleName="header">
            <Link to="/">
              <img styleName="logo" src={logo} alt="homula" />
            </Link>
          </header>
          <SupplierGroup />
        </>
      ),
      title: 'グループ',
    },
  ];

  const individualRoutes: PartialRequired<
    IndividualRouteProps,
    'path' | 'title'
  >[] = [
    {
      path: '/',
      title: 'ホーム',
      exact: true,
      component: () => <Redirect to="/home" />,
    },
    {
      path: '/private-order/:brand_id',
      exact: true,
      component: PrivateOrder,
      title: 'プライベートオーダー',
    },
    {
      path: '/group/:group_id',
      exact: true,
      component: SupplierGroup,
      title: 'グループ',
    },
    { path: '/home', title: 'ホーム', exact: true, component: Home },
    {
      path: '/brand/:brand_id',
      title: '商品一覧',
      exact: true,
      component: ProductList,
    },
    {
      path: '/productdetail/:product_id',
      title: '商品詳細',
      exact: true,
      component: ProductDetails,
    },
    {
      path: '/collection/:collection_id',
      title: 'コレクション',
      exact: true,
      component: Collection,
    },
    {
      path: '/cart',
      title: 'カート',
      exact: true,
      component: ShoppingCart,
    },
    {
      path: '/checkout',
      title: '注文',
      exact: true,
      component: Checkout,
    },
    {
      path: '/paymentterm',
      title: '注文',
      exact: true,
      component: PaymentTerm,
    },
    {
      path: '/confirmation',
      title: '確認',
      exact: true,
      component: OrderConfirmation,
    },
    {
      path: '/complete',
      title: '注文完了',
      exact: true,
      component: OrderComplete,
    },
    {
      path: '/messages',
      title: 'メッセージ',
      exact: true,
      component: Messages,
    },
    {
      path: '/Account/AccountProfile',
      title: 'アカウント',
      exact: true,
      component: Profile,
    },
    {
      path: '/Account/AccountProfileEdit',
      title: 'アカウント | 登録情報の編集',
      exact: true,
      component: ProfileEdit,
    },
    {
      path: '/Account/ChangePassword',
      title: 'アカウント | パスワード変更',
      exact: true,
      component: ChangePassword,
    },
    {
      path: '/Account/OrderHistory',
      title: '注文履歴',
      exact: false,
      component: OrderHistory,
    },
  ];

  const location = useLocation();
  const matchPublicPath = useMemo(
    () =>
      publicRoutes.some(({ path, exact }) =>
        matchPath(location.pathname, {
          path,
          exact,
        })
      ),
    [location.pathname]
  );
  const matchIndividualPath = useMemo(
    () =>
      individualRoutes.some(({ path, exact }) =>
        matchPath(location.pathname, {
          path,
          exact,
        })
      ),
    [location.pathname]
  );

  if (!authUser) {
    return (
      <>
        {matchPublicPath &&
          publicRoutes.map((route) => (
            <IndividualRoute
              key={Array.isArray(route.path) ? route.path[0] : route.path}
              {...route}
            />
          ))}
      </>
    );
  }

  return authUser?.attributes.profile === 'individual' ? (
    <>
      {matchIndividualPath && (
        <IndividualPageLayout>
          <Suspense fallback={<LoadingContainer isLoading />}>
            {individualRoutes.map((route) => (
              <IndividualRoute
                key={Array.isArray(route.path) ? route.path[0] : route.path}
                {...route}
              />
            ))}
          </Suspense>
        </IndividualPageLayout>
      )}
    </>
  ) : null;
};

export default IndividualRoutes;
