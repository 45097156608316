import { BuyerInfo } from './../../redux/buyer/actions/buyerInfo';
import { CartItem } from './CartItem';
import {
  CartSegmentBase,
  CartSegmentJson,
  CartSegmentType,
} from './base/CartSegmentBase';
import { isFreeShipping } from '~utils/shipping';

export class CartSegment extends CartSegmentBase {
  private constructor(params: CartSegmentType) {
    super(params);
  }

  static create(
    {
      items,
      ...segment
    }: CartSegmentJson & Pick<CartSegmentType, 'subtotalByBrand'>,
    buyerInfo: BuyerInfo
  ) {
    return new CartSegment({
      ...segment,
      buyerInfo,
      items: items.map(CartItem.create),
    });
  }

  get shippingFee() {
    return !isFreeShipping() && !this.hasPayDirectlyOrderItem
      ? this.displayShippingFee
      : 0;
  }

  get displayShippingFee() {
    return this.brand.brand_shippinng_fee_criteria > this.subtotalByBrand
      ? this.brand.brand_shippinng_fee
      : 0;
  }

  get firstOrderFee() {
    return this.brand.brand_first_order_fee;
  }

  get additionalOrderFee() {
    return this.brand.brand_additional_order_fee;
  }

  get directOrderFee() {
    return this.brand.brand_direct_order_fee;
  }

  get firstOrderFeeReferral() {
    return this.brand.brand_first_order_fee_referral;
  }

  get additionalOrderFeeReferral() {
    return this.brand.brand_additional_order_fee_referral;
  }
}
