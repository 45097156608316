export const searchFavoriteProductLists = /* GraphQL */ `
  query SearchFavoriteProductLists(
    $filter: SearchableFavoriteProductListFilterInput
    $sort: SearchableFavoriteProductListSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFavoriteProductLists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        account_id
        list_name
        order
        createdAt
        updatedAt
        products(limit: 1000) {
          items {
            id
            product_id
            product {
              imageKeys {
                items {
                  imageKey
                }
              }
            }
          }
        }
      }
    }
  }
`;
