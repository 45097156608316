import { createAction } from '@reduxjs/toolkit';
import { BuyerInfo } from '~redux/buyer/actions/buyerInfo';

export type Account = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  web_site_URL: string;
  location: string;
  tel: string | null;
  zip_code: string | null;
  company_name: string;
  term_approval_status: number;
  instagram_account: string;
  buyerInfo: BuyerInfo;
  supplierInfo: any;
  createdAt: string;
  updatedAt: string;
  owner: string[];
};

const ACCOUNT = 'ACCOUNT';

export const setAccount = createAction<Account>(`${ACCOUNT}/SET_ACCOUNT`);
export const clearAccount = createAction(`${ACCOUNT}/CLEAR_ACCOUNT`);
