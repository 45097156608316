import './styles.scss';
import { BiUser } from '@react-icons/all-files/bi/BiUser';
import { FiInfo } from '@react-icons/all-files/fi/FiInfo';
import { FiMenu } from '@react-icons/all-files/fi/FiMenu';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
import { clearAccount } from '~redux/account/actions/account';
import { accountSelector } from '~redux/account/selectors/account';
import { clearAuthUser } from '~redux/auth/actions/authUser';
import { checkNewMessages } from '~redux/messages/actions';
import { newMessagesSelector } from '~redux/messages/selectors';
import {
  getNewMessages,
  subscribeNewMessages,
  unsubscribeNewMessages,
} from '~redux/messages/thunk';
import Sidebar from '~view/widgets/Sidebar';

const UserIcon: React.FC<{ existNewMessages: boolean }> = ({
  existNewMessages,
}) => {
  return (
    <span
      className={classNames({
        'header-user-icon': true,
        'exist-new-messages-icon': existNewMessages,
      })}
    >
      <BiUser className="header-nav_menu-icon" />
    </span>
  );
};

type SupplierMenuProps = {};

const SupplierMenu: React.FC<SupplierMenuProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const signOut = useCallback(async () => {
    await Auth.signOut();
    dispatch(clearAuthUser());
    dispatch(clearAccount());
    setTimeout(() => history.push('/'), 100);
  }, []);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const showUserMenu = useCallback(() => {
    setUserMenuOpen(true);
  }, []);
  const hideUserMenu = useCallback(() => {
    setUserMenuOpen(false);
  }, []);
  const account = useSelector(accountSelector);
  const newMessages = useSelector(newMessagesSelector);
  const existNewMessages = useMemo(
    () => !!newMessages.unCheckedMessages.length,
    [newMessages]
  );
  const checkMessages = useCallback(() => {
    dispatch(checkNewMessages());
    hideUserMenu();
  }, []);
  useEffect(() => {
    if (!account) {
      return;
    }
    if (
      !newMessages.checkedMessages.length &&
      !newMessages.unCheckedMessages.length
    ) {
      dispatch(getNewMessages(account.id));
    }
    dispatch(subscribeNewMessages(account.id));
    return () => {
      dispatch(unsubscribeNewMessages());
    };
  }, []);

  const [isPopover, setPopover] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <div className="topbar topbar-bg-color-default topbar-desktop-on topbar-mobile-off">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <div
              className="d-block d-xl-none"
              style={{ position: 'absolute', top: '12px', left: '15px' }}
            >
              <button
                className="border-0 bg-transparent"
                onClick={() => setMenuOpen(true)}
              >
                <h5 className="mb-0">
                  <FiMenu />
                </h5>
              </button>
              {isMenuOpen && (
                <Modal
                  isOpen
                  toggle={() => setMenuOpen(false)}
                  contentClassName="rounded-0 p-6"
                  className="h-100 m-0 menu-modal"
                >
                  <Sidebar />
                </Modal>
              )}
            </div>
            <div className="logo-wrapper">
              <Link to="/home">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="ml-auto mr-3">
              <Button
                id="brand-news"
                className="rounded-pill px-3 py-2"
                onClick={() => setPopover((prev) => !prev)}
                outline
              >
                <FiInfo className="mr-2" />
                新着情報
              </Button>
              <Popover
                trigger="focus"
                placement="bottom-end"
                target="brand-news"
                innerClassName="bg-light"
                isOpen={isPopover}
                toggle={() => setPopover(false)}
              >
                <PopoverHeader className="bg-white text-center font-weight-bold">
                  <FiInfo className="mr-2" />
                  新着情報
                </PopoverHeader>
                <PopoverBody>
                  <iframe
                    src="https://edition-playground.vercel.app/embed"
                    style={{ border: 0, height: '500px', width: '260px' }}
                  />
                </PopoverBody>
              </Popover>
            </div>
            <div className="buyer-menu-area p-0">
              <div className="menu-area-inner">
                <Navbar>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <NavDropdown
                        id="user-menu"
                        title={<UserIcon existNewMessages={existNewMessages} />}
                        show={userMenuOpen}
                        onMouseEnter={showUserMenu}
                        onMouseLeave={hideUserMenu}
                        alignRight
                      >
                        <Nav.Item className="user-menu_user-info">
                          {`${account?.last_name}　${account?.first_name}`}
                          &nbsp;様
                        </Nav.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          as={Link}
                          to="/messages"
                          onClick={checkMessages}
                          className={classNames({
                            'exist-new-messages-link': existNewMessages,
                          })}
                        >
                          メッセージ
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/Account/AccountProfile"
                          onClick={hideUserMenu}
                        >
                          アカウント
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="https://support.homula.jp/help/%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89faq"
                          target="_blank"
                          onClick={hideUserMenu}
                        >
                          ヘルプ
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={signOut}>
                          ログアウト
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierMenu;
