import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { getOrderProductsByDateRange } from '../../graphql/custom_queries';
import * as mutations from '../../graphql/mutations';
import { calculatePrice } from './../../utils/price';
import { ReportProduct, ReportProductStatus } from './ReportProduct';
import { ReturnProduct } from './ReturnProduct';
import { Entity } from '~core/domain/Entity';
import { Product } from '~domain/product/Product';
import { Brand, ProductType } from '~redux/brand/types';
import {
  OrderStatus,
  OrderProduct as OrderProductJson,
  PaymentStatusType,
  ReturnProduct as ReturnProductJson,
  PaymentTermType,
  OrderStatusType,
  ReturnStatusType,
  Order,
} from '~redux/buyer/types';

export type OrderProductType = {
  id: string;
  order_id: string;
  product_id: string;
  order?: Order;
  createdAt?: Date;
  product_type_id: string;
  quantity: number;
  price: number;
  wholesale_rate: number;
  initial_quantity: number;
  initial_price: number;
  initial_wholesale_rate: number;
  payment_status: PaymentStatusType;
  payment_method: PaymentTermType;
  return_reason: string;
  orderstatus: OrderStatus[];
  product: Product;
  productType?: ProductType;
  owners: string[];
  returns: ReturnProduct[];
  reportProducts?: ReportProduct[];
};

export type OrderProductFilterValues = {
  startDate: string;
  endDate: string;
};
export type ListOrderProductParams = {
  owner: string;
  nextToken?: string;
  filterValues?: OrderProductFilterValues;
  isForCSV?: boolean;
};

export class OrderProduct extends Entity<OrderProductType> {
  private constructor(params: OrderProductType) {
    super(params);
  }

  static async create({
    order_product_quantity,
    order_product_price,
    order_product_wholesale_rate,
    order_product_payment_status,
    order_product_payment_method,
    initial_order_product_quantity,
    initial_order_product_price,
    initial_order_product_wholesale_rate,
    product,
    returns,
    ...orderProduct
  }: OrderProductJson & {
    returns: ReturnProductJson[];
  }) {
    return new OrderProduct({
      ...orderProduct,
      quantity: order_product_quantity,
      price: order_product_price,
      wholesale_rate: order_product_wholesale_rate,
      initial_quantity: initial_order_product_quantity,
      initial_price: initial_order_product_price,
      initial_wholesale_rate: initial_order_product_wholesale_rate,
      payment_status: order_product_payment_status,
      payment_method: order_product_payment_method,
      returns: await Promise.all(returns.map(ReturnProduct.create)),
      product: Product.create(product),
      reportProducts: orderProduct.reportProducts?.map
        ? orderProduct.reportProducts.map(ReportProduct.createInstance)
        : [],
    });
  }

  static async listOrderProduct(
    params: ListOrderProductParams
  ): Promise<{ nextToken: string | null; orderProducts: OrderProduct[] }> {
    const { owner, filterValues, isForCSV, nextToken } = params;

    const fromDate = filterValues
      ? moment(new Date(filterValues!.startDate)).startOf('date')
      : '';
    const toDate = filterValues
      ? moment(new Date(filterValues!.endDate)).endOf('date')
      : '';

    const res = await API.graphql<any>(
      graphqlOperation(getOrderProductsByDateRange, {
        owner,
        nextToken,
        sort: { field: 'createdAt', direction: 'desc' },
        limit: isForCSV ? 10000 : 100,
        fromDate,
        toDate,
      })
    );
    const {
      data: {
        getOrderProductsByDateRange: { items, nextToken: nextTokenRes },
      },
    } = res;

    const orderProducts: OrderProduct[] = await Promise.all(
      items.map((item: OrderProductJson) =>
        OrderProduct.create({ ...item, returns: [] })
      )
    );

    return { nextToken: nextTokenRes, orderProducts };
  }

  static async register({
    brand_owner,
    buyer_id,
    ...orderProduct
  }: {
    order_id: string;
    product_id: string;
    product_type_id?: string;
    order_product_quantity: number;
    order_product_price: number;
    order_product_payment_status: PaymentStatusType;
    order_product_payment_method: PaymentTermType;
    order_product_wholesale_rate: number;
    clientSecret?: string;
    brand_owner: Brand['brand_owner'];
    buyer_id: string;
    owners: string[];
  }) {
    const createOrderProductResponse = await API.graphql<any>(
      graphqlOperation(mutations.createOrderProduct, {
        input: {
          ...orderProduct,
          initial_order_product_quantity: orderProduct.order_product_quantity,
          initial_order_product_price: orderProduct.order_product_price,
          initial_order_product_wholesale_rate:
            orderProduct.order_product_wholesale_rate,
        },
      })
    );
    const {
      data: { createOrderProduct: orderProductData },
    } = createOrderProductResponse;

    const orderStatus: {
      order_product_id: string;
      status: OrderStatusType;
      owners: string[];
    } = {
      order_product_id: orderProductData.id,
      status: OrderStatusType.unconfirmed,
      owners: [brand_owner, buyer_id],
    };

    await API.graphql<any>(
      graphqlOperation(mutations.createOrderStatus, {
        input: orderStatus,
      })
    );

    return await OrderProduct.create({
      ...orderProductData,
      product: {
        ...orderProductData.product,
        discount: orderProductData.product.contacts,
      },
      orderstatus: [orderStatus],
      returns: [],
    });
  }

  get status() {
    // 優先度の高いステータスを上
    const ORDER_STATUS_PRIORITY = [
      OrderStatusType.default,
      OrderStatusType.canceledByStripe,
      OrderStatusType.canceledByBuyer,
      OrderStatusType.canceledBySupplier,
      OrderStatusType.shipped,
      OrderStatusType.confirmed,
      OrderStatusType.unconfirmed,
    ];
    const orderStatuses = this.orderstatus.map(({ status }) => status);

    return (
      ORDER_STATUS_PRIORITY.find((status) => orderStatuses.includes(status)) ??
      OrderStatusType.unconfirmed
    );
  }

  get shippedDate() {
    const shipped = this.orderstatus.find(
      ({ status }) => status === OrderStatusType.shipped
    );
    if (shipped) {
      return shipped.createdAt;
    }
    return undefined;
  }

  get returnRequestQuantity() {
    return (
      this.returns
        .filter(
          ({ return_status, return_product_id }) =>
            return_status === ReturnStatusType.inRequest &&
            !this.returns.find(
              (returnProduct) =>
                returnProduct.return_product_id === return_product_id &&
                [
                  ReturnStatusType.applied,
                  ReturnStatusType.returnedToBrand,
                ].includes(returnProduct.return_status)
            )
        )
        .reduce(
          (quantity, { return_quantity }) => quantity + return_quantity,
          0
        ) ?? 0
    );
  }

  get returnQuantity() {
    return (
      this.returns
        .filter(({ return_status }) =>
          [ReturnStatusType.applied, ReturnStatusType.returnedToBrand].includes(
            return_status
          )
        )
        .reduce(
          (quantity, { return_quantity }) => quantity + return_quantity,
          0
        ) ?? 0
    );
  }

  get returnRequestAmount() {
    return (
      this.returns
        .filter(
          ({ return_status }) => return_status === ReturnStatusType.inRequest
        )
        .reduce((amount, { returnAmount }) => amount + returnAmount, 0) ?? 0
    );
  }

  get returnAmount() {
    return (
      this.returns
        .filter(({ return_status }) =>
          [ReturnStatusType.applied, ReturnStatusType.returnedToBrand].includes(
            return_status
          )
        )
        .reduce((amount, { returnAmount }) => amount + returnAmount, 0) ?? 0
    );
  }

  get returnSubtotal() {
    return (
      this.returns
        .filter(({ return_status }) =>
          [ReturnStatusType.applied, ReturnStatusType.returnedToBrand].includes(
            return_status
          )
        )
        .reduce(
          (subtotal, { return_quantity, return_product_price }) =>
            subtotal + return_quantity * return_product_price,
          0
        ) ?? 0
    );
  }

  get reportQuantity() {
    return (
      this.reportProducts
        ?.filter(
          (report) => report.report_status === ReportProductStatus.Progress
        )
        .reduce((prev, report) => prev + report.report_quantity!, 0) ?? 0
    );
  }

  get approvedReportQuantity() {
    return (
      this.reportProducts
        ?.filter(
          (report) => report.report_status === ReportProductStatus.Approved
        )
        .reduce((prev, report) => prev + report.report_quantity!, 0) ?? 0
    );
  }

  get totalQuantity() {
    return (
      this.quantity -
      this.returnRequestQuantity -
      this.returnQuantity -
      this.reportQuantity -
      this.approvedReportQuantity -
      this.fulfilledQuantity
    );
  }

  get totalConfirmedQuantity() {
    return this.quantity - this.returnQuantity - this.approvedReportQuantity;
  }

  get fulfilledQuantity() {
    return (
      this.orderstatus.reduce(
        (prev, os) =>
          prev +
          (os.status === OrderStatusType.fulfilled ? os.quantity ?? 0 : 0),
        0
      ) ?? 0
    );
  }

  get fulfilledAmount() {
    return (
      this.orderstatus.reduce(
        (prev, os) =>
          prev +
          (os.status === OrderStatusType.fulfilled
            ? this.price * (os.quantity ?? 0)
            : 0),
        0
      ) ?? 0
    );
  }

  get totalPrice() {
    return (this.price ?? this.product.unitPrice) * this.totalConfirmedQuantity;
  }

  get totalPriceWithoutReturns() {
    return (this.price ?? this.product.unitPrice) * this.quantity;
  }

  get firstReturnRequestDate() {
    const sorted = this.returns.sort((a, b) => {
      return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
    });

    return sorted[0]?.createdAt;
  }

  async edit(data: { quantity?: number; wholesaleRate?: number }) {
    const order_product_quantity = data?.quantity ?? this.quantity;
    const order_product_wholesale_rate =
      data?.wholesaleRate ?? this.wholesale_rate;
    const order_product_price = data?.wholesaleRate
      ? calculatePrice(this.product.product_retail_price, data.wholesaleRate)
      : this.price;

    const response = await API.graphql<any>(
      graphqlOperation(mutations.updateOrderProduct, {
        input: {
          id: this.id,
          order_product_quantity,
          order_product_wholesale_rate,
          order_product_price,
        },
      })
    );

    const newData = response.data!.updateOrderProduct;

    const newOrderProduct = await OrderProduct.create({
      ...this.toJson(),
      order_product_quantity: newData.order_product_quantity,
      order_product_wholesale_rate: newData.order_product_wholesale_rate,
      returns: this.returns.map((returnProduct) => ({
        ...returnProduct.toJson(),
        order_id: this.order_id,
        order_product_id: this.id,
      })),
    });

    if (data?.quantity === 0) {
      await API.graphql<any>(
        graphqlOperation(mutations.createOrderStatus, {
          input: {
            order_product_id: this.id,
            status: OrderStatusType.canceledBySupplier,
            owners: this.owners,
          },
        })
      );
    }

    return newOrderProduct;
  }

  toJson(): OrderProductJson {
    return {
      id: this.id,
      order_id: this.order_id,
      product_id: this.product_id,
      product_type_id: this.product_type_id,
      order_product_quantity: this.quantity,
      order_product_price: this.price,
      order_product_wholesale_rate: this.wholesale_rate,
      order_product_payment_status: this.payment_status,
      order_product_payment_method: this.payment_method,
      initial_order_product_quantity: this.initial_quantity,
      initial_order_product_price: this.initial_price,
      initial_order_product_wholesale_rate: this.initial_wholesale_rate,
      return_reason: this.return_reason,
      orderstatus: this.orderstatus,
      product: this.product.toJson(),
      productType: this.productType,
      owners: this.owners,
    };
  }
}
