import { createAction } from '@reduxjs/toolkit';
import { Brand } from '../../types';
import { Product } from '~domain/product/Product';

const ACTION_BASE_TYPE = 'ADMIN/BRAND';

export const receiveAllBrands = createAction<Brand[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_ALL_BRANDS`
);

export const getProductStart = createAction(
  `${ACTION_BASE_TYPE}/GET_PRODUCT_START`
);

export const getProductsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_PRODUCTS_START`
);

export const receiveProduct = createAction<Product>(
  `${ACTION_BASE_TYPE}/RECEIVE_PRODUCT`
);

export const receiveProducts = createAction<Product[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_PRODUCTS`
);

export const clearProduct = createAction(`${ACTION_BASE_TYPE}/CLEAR_PRODUCT`);

export const copyProductStart = createAction(
  `${ACTION_BASE_TYPE}/COPY_PRODUCT_START`
);

export const copyProductComplete = createAction(
  `${ACTION_BASE_TYPE}/COPY_PRODUCT_COMPLETE`
);
