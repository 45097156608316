import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralMessage } from '~domain/referral/ReferralMessage';

type ReferralMessageStateType = ReferralMessage | null;

const referralMessageSlice = createSlice({
  name: 'referralMessage',
  initialState: null as ReferralMessageStateType,
  reducers: {
    setReferralMessage: (
      _,
      { payload }: PayloadAction<ReferralMessageStateType>
    ) => payload,
    clearReferralMessage: () => null,
  },
});

export const {
  setReferralMessage,
  clearReferralMessage,
} = referralMessageSlice.actions;
export default referralMessageSlice.reducer;
