import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, Nav } from 'reactstrap';

type NewNavLinkProps = {};

const NewNavMenu: React.FC<NewNavLinkProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const submenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const submenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  const toggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <Nav navbar>
      <Dropdown
        nav
        inNavbar
        onMouseEnter={submenuOpen}
        onMouseLeave={submenuClose}
        toggle={toggle}
        isOpen={isMenuOpen}
      >
        <Link
          aria-haspopup="true"
          // to="/BrandListView/new-brands/all"
          to="/list/new-products/all"
          className="nav-link"
          aria-expanded="true"
        >
          新着
        </Link>
        <DropdownMenu>
          {/* <DropdownItem
            tag={Link}
            className={`nav-item ${
              document.location.pathname === '/BrandListView/new-brands/all'
                ? 'active'
                : ''
            }`}
            to="/BrandListView/new-brands/all"
          >
            新着ブランド
          </DropdownItem> */}
          <DropdownItem
            tag={Link}
            className={`nav-item ${
              document.location.pathname === '/list/new-products/all'
                ? 'active'
                : ''
            }`}
            to="/list/new-products/all"
          >
            新着アイテム
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default NewNavMenu;
