import { combineReducers } from 'redux';
import adminAllBrandsReducer from './admin/adminAllBrandsReducer';
import adminProductReducer from './admin/adminProductReducer';
import adminProductsReducer from './admin/adminProductsReducer';
import brandName from './brandName';
import detailsReducer from './details';
import itemsReducer from './items';
import nextTokenReducer from './nextToken';
import searchReducer from './search';
import supplierDetailsReducer from './supplier/details';
import supplieritemsReducer from './supplier/items';

export const brandsReducer = combineReducers({
  search: searchReducer,
  items: itemsReducer,
  details: detailsReducer,
  nextToken: nextTokenReducer,
  brandName,
  adminAllBrands: adminAllBrandsReducer,
  adminProduct: adminProductReducer,
  adminProducts: adminProductsReducer,
  supplierItems: supplieritemsReducer,
  supplierDetails: supplierDetailsReducer,
});

export default brandsReducer;
