import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { searchSupplierGroups } from '~graphql/queries';
import { Individual, SearchSupplierGroupsQuery } from '~types/api';
import { executeQuery } from '~utils/graphql';
import { getImageUrl } from '~utils/image';

export type SupplierGroup = Individual['supplierGroup'];

export const fetchSupplierGroupThunk = createAsyncThunk(
  'group/fetchSupplierGroup',
  async ({ groupId, unAuth }: { groupId: string; unAuth: boolean }) => {
    const response = (await executeQuery(
      searchSupplierGroups,
      {
        filter: {
          id: { eq: groupId },
          published: { eq: true },
        },
        sort: {
          field: 'createdAt',
          direction: 'desc',
        },
      },
      unAuth
    )) as GraphQLResult<SearchSupplierGroupsQuery>;

    const group = response.data?.searchSupplierGroups?.items?.[0];
    if (!group) {
      return null;
    }

    const image = group?.image_key && (await getImageUrl(group?.image_key));
    const groupWithImage = {
      ...group,
      image,
    };

    return groupWithImage;
  }
);

export type SupplierGroupState = {
  entity:
    | (NonNullable<
        NonNullable<SearchSupplierGroupsQuery['searchSupplierGroups']>['items']
      >[number] & { image?: string | null })
    | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
};

const initialState: SupplierGroupState = {
  entity: null,
  loading: 'idle',
};

const supplierGroupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierGroupThunk.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(fetchSupplierGroupThunk.fulfilled, (state, action) => {
        return {
          ...state,
          entity: action.payload,
          loading: 'succeeded',
        };
      })
      .addCase(fetchSupplierGroupThunk.rejected, (state) => {
        return {
          ...state,
          loading: 'failed',
        };
      });
  },
});

export const {} = supplierGroupSlice.actions;
export default supplierGroupSlice.reducer;
