import { createReducer } from '@reduxjs/toolkit';
import { createdOrders, clearedLatestOrders } from '../actions';
import { Order } from '~redux/buyer/types';

const initialState: Order[] = [];

const latestOrderReducer = createReducer(initialState, {
  [createdOrders.type]: (_, { payload }) => payload,
  [clearedLatestOrders.type]: () => initialState,
});

export default latestOrderReducer;
