import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Entity } from '~core/domain/Entity';
import { inventoryReservation } from '~graphql/mutations';
import { OrderProduct } from '~redux/buyer/types';
import {
  Inventory as InventoryJson,
  SearchInventorysQueryVariables,
  SearchInventorysQuery,
  UpdateInventoryInput,
  UpdateInventoryMutation,
  GetInventoryQuery,
  CreateInventoryInput,
  CreateInventoryMutation,
  UpdateInventoriesInput,
  UpdateInventoriesMutation,
} from '~types/api';
import { PartialRequired } from '~types/utils';
import { executeQuery } from '~utils/graphql';

export type TInventory = InventoryJson;

export class Inventory extends Entity<TInventory> {
  private constructor(params: TInventory) {
    super(params);
  }

  static async create({
    brand_id,
    product_id,
    product_type_id,
    product,
    productType,
    ...rest
  }: PartialRequired<
    InventoryJson,
    'brand_id' | 'product_id' | 'sku' | 'inventory'
  >) {
    return new Inventory({
      ...rest,
      brand_id,
      product_id,
      product_type_id: product_type_id ?? undefined,
      product,
      productType,
    });
  }

  static async createById(id: string) {
    const {
      data: { getInventory },
    } = (await API.graphql<any>(
      graphqlOperation(queries.getInventory, {
        id,
      })
    )) as {
      data: GetInventoryQuery;
    };
    return getInventory
      ? Inventory.create(
          getInventory as Parameters<typeof Inventory['create']>[0]
        )
      : undefined;
  }

  static async createByProductId(productId: string, productTypeId?: string) {
    const filter: SearchInventorysQueryVariables['filter'] = {
      product_id: { eq: productId },
      product_type_id: productTypeId ? { eq: productTypeId } : undefined,
    };
    const {
      data: { searchInventorys },
    } = (await API.graphql<any>(
      graphqlOperation(queries.searchInventorys, {
        filter,
      })
    )) as {
      data: SearchInventorysQuery;
    };

    if (!searchInventorys?.items?.[0]) {
      return undefined;
    }

    return Inventory.create(
      searchInventorys.items[0] as Parameters<typeof Inventory['create']>[0]
    );
  }

  static async reservation(
    orderProducts: { id: string; order_product_quantity: number }[],
    prevOrderProducts?: { id: string; order_product_quantity: number }[]
  ): Promise<TInventory> {
    return (await executeQuery(inventoryReservation, {
      input: orderProducts
        .filter(
          (op) =>
            op.order_product_quantity !==
            prevOrderProducts?.find(({ id }) => id === op.id)
              ?.order_product_quantity
        )
        .map((op) => ({
          order_product_id: op.id,
          quantity:
            op.order_product_quantity -
            (prevOrderProducts?.find(({ id }) => id === op.id)
              ?.order_product_quantity ?? 0),
        })),
    })) as TInventory;
  }

  static async updateInventories(
    brandId: string,
    products: UpdateInventoriesInput[]
  ) {
    return await executeQuery<UpdateInventoriesMutation>(
      mutations.updateInventories,
      {
        brandId,
        products,
      }
    );
  }

  async update(input: Omit<UpdateInventoryInput, 'id'>) {
    const {
      data: { updateInventory },
    } = (await API.graphql<any>(
      graphqlOperation(mutations.updateInventory, {
        input: {
          id: this.id,
          ...input,
        },
      })
    )) as {
      data: UpdateInventoryMutation;
    };

    return updateInventory && Inventory.createById(updateInventory.id);
  }

  static async register({ ...input }: CreateInventoryInput) {
    const {
      data: { createInventory },
    } = (await API.graphql<any>(
      graphqlOperation(mutations.createInventory, {
        input: {
          ...input,
        },
      })
    )) as {
      data: CreateInventoryMutation;
    };

    return createInventory
      ? Inventory.createById(createInventory.id)
      : undefined;
  }
}
