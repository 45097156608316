import { createAction } from '@reduxjs/toolkit';
import { ActionCreator } from '../../../types/redux';
import { Product } from '~redux/brand/types';

const ACTION_BASE_TYPE = 'PRODUCT';

export const ADD_PRODUCT = `${ACTION_BASE_TYPE}/ADD_PRODUCT`;
export const addProduct: ActionCreator<{
  category: string;
  product: Product;
}> = (category, newProduct) => {
  return {
    type: ADD_PRODUCT,
    payload: {
      category,
      product: newProduct,
    },
  };
};

export const ADD_PRODUCTS = `${ACTION_BASE_TYPE}/ADD_PRODUCTS`;
export const addProducts: ActionCreator<{
  category: string;
  product: Product[];
}> = (category, newProducts) => {
  return {
    type: ADD_PRODUCTS,
    payload: {
      category,
      product: newProducts,
    },
  };
};

export const SET_PRODUCTS = `${ACTION_BASE_TYPE}/SET_PRODUCTS`;
export const setProducts: ActionCreator<{
  category: string;
  products: Product[];
}> = (category, signedProducts) => {
  return {
    type: SET_PRODUCTS,
    payload: {
      category,
      products: signedProducts,
    },
  };
};

export const CLEAR_PRODUCTS = `${ACTION_BASE_TYPE}/CLEAR_PRODUCTS`;
export const clearProducts: ActionCreator<string> = (category: string) => {
  return {
    type: CLEAR_PRODUCTS,
    payload: category,
  };
};

export const CLEAR_ALL_PRODUCTS = `${ACTION_BASE_TYPE}/CLEAR_ALL_PRODUCTS`;
export const clearAllProducts: ActionCreator = () => {
  return {
    type: CLEAR_ALL_PRODUCTS,
  };
};

export const GET_PRODUCTS_START = `${ACTION_BASE_TYPE}/GET_PRODUCTS_START`;
export const getProductsStart: ActionCreator = () => {
  return {
    type: GET_PRODUCTS_START,
  };
};

export const GET_SIGNED_POSTS_START = `${ACTION_BASE_TYPE}/GET_SIGNED_POSTS_START`;
export const getSignedProductsStart: ActionCreator = () => {
  return {
    type: GET_SIGNED_POSTS_START,
  };
};

export const GET_SIGNED_POST_START = `${ACTION_BASE_TYPE}/GET_SIGNED_POST_START`;
export const getSignedProductStart: ActionCreator = () => {
  return {
    type: GET_SIGNED_POST_START,
  };
};

export const RECEIVE_NEXT_TOKEN = `${ACTION_BASE_TYPE}/RECEIVE_NEXT_TOKEN`;
export const receiveNextToken: ActionCreator<string> = (nextToken: string) => {
  return {
    type: RECEIVE_NEXT_TOKEN,
    payload: nextToken,
  };
};

export const CLEAR_NEXT_TOKEN = `${ACTION_BASE_TYPE}/CLEAR_NEXT_TOKEN`;
export const clearNextToken: ActionCreator = () => {
  return {
    type: CLEAR_NEXT_TOKEN,
  };
};

export const searchProductsStart = createAction(
  `${ACTION_BASE_TYPE}/SEARCH_PRODUCTS_START`
);

export const addSearchProducts = createAction<{
  items: Product;
  nextToken: string | null;
}>(`${ACTION_BASE_TYPE}/ADD_SEARCH_PRODUCTS`);

export const receiveSearchProducts = createAction<{
  items: Product;
  nextToken: string | null;
}>(`${ACTION_BASE_TYPE}/RECEIVE_SEARCH_PRODUCTS`);

export const clearSearchProducts = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_SEARCH_PRODUCTS`
);
