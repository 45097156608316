import { createAction } from '@reduxjs/toolkit';
import { Brand } from '~domain/brand/Brand';
import { ProductType } from '~domain/product/ProductType';

const ACTION_BASE_TYPE = 'SUPPLIER/BRAND/DETAILS';

export const getBrandInfoStart = createAction(
  `${ACTION_BASE_TYPE}/GET_BRAND_INFO_START`
);

export const receiveBrandDetails = createAction<Brand>(
  `${ACTION_BASE_TYPE}/RECEIVE_BRAND_DETAILS`
);

export const getBrandProductsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_BRAND_PRODUCTS_START`
);

export const receiveBrandProductsNextToken = createAction<string>(
  `${ACTION_BASE_TYPE}/RECEIVE_BRAND_PRODUCTS_NEXT_TOKEN`
);

export const setBrandProducts = createAction<ProductType[]>(
  `${ACTION_BASE_TYPE}/SET_BRAND_PRODUCTS`
);

export const addBrandProducts = createAction<ProductType[]>(
  `${ACTION_BASE_TYPE}/ADD_BRAND_PRODUCTS`
);

export const createOrderStart = createAction(
  `${ACTION_BASE_TYPE}/CREATE_ORDER_START`
);

export const createOrderComplete = createAction(
  `${ACTION_BASE_TYPE}/CREATE_ORDER_COMPLETE`
);
