import { createReducer } from '@reduxjs/toolkit';
import { clearCartProducts, setCartProducts } from '../actions';
import { CartItem } from '../types';

type CartProductsState = CartItem[];
const initialState: CartProductsState = [];

const cartProductsReducer = createReducer(initialState, {
  [setCartProducts.type]: (_, { payload }) => payload,
  [clearCartProducts.type]: () => initialState,
});

export default cartProductsReducer;
