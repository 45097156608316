import { BuyerInfo as BuyerInfoType } from '../actions/buyerInfo';
import { TReportProduct } from '~domain/order/ReportProduct';
import { BrandDetails, Product, ProductType } from '~redux/brand/types';
import { ChargeStatus, ChargeStatusType } from '~redux/payment/types';
import { Review } from '~types/api';

export type BuyerInfo = BuyerInfoType;

// バイヤータイプ（0：アパレル系リアル店舗、１：雑貨系リアル店舗、２：ECサイト、３：その他）
export enum BuyerType {
  apparelPhysicalStore = 0,
  generalGoodsPhysicalStore = 1,
  ecSite = 2,
  misc = 3,
  individual = 4,
}

//
export enum PaymentStatusType {
  unconfirmed = 0,
  paymentCompleted = 1,
  canceledBySupplier = 2,
}

// 0:未確認(default)、1:オーダー完了、2:サプライヤーキャンセル、3:バイヤーキャンセル、4:発送済、5:Stripeキャンセル、6：デフォルト、7：売上確定
export enum OrderStatusType {
  unconfirmed = 0,
  confirmed = 1,
  canceledBySupplier = 2,
  canceledByBuyer = 3,
  shipped = 4,
  canceledByStripe = 5,
  default = 6,
  fulfilled = 7,
}

// 0:homula払い、1:直払い
export enum PaymentMethodType {
  homula = 0,
  direct = 1,
}

// 0:60日後払い、1:通常買付、2:出荷払い、3:プレオーダー、4:請求書払い、5:直取引、6:請求書払い（ファクタリング）、7:予約注文（ファクタリング）
export enum PaymentTermType {
  postpayment = 0,
  normal = 1,
  outgoingFreight = 2,
  preorder = 3,
  bill = 4,
  direct = 5,
  factoring = 6,
  factoredPreorder = 7,
}

// 1:カード払い、2:請求書払い
export enum PaymentType {
  card = 1,
  bill = 2,
}

export enum KycStatus {
  unconfirmed = 0,
  passed = 1,
  failed = 2,
}

// ０：返品申請中、1：返品済み、2：返品なし、3：ブランドへの返品
export enum ReturnStatusType {
  inRequest = 0,
  applied = 1,
  noReturns = 2,
  returnedToBrand = 3,
}

export const PAYMENT_TERM_LABEL: Record<PaymentTermType, string> = {
  [PaymentTermType.postpayment]: '60日後の後払い',
  [PaymentTermType.normal]: '通常買付',
  [PaymentTermType.outgoingFreight]: '出荷払い',
  [PaymentTermType.preorder]: '出荷日からの60日後払い',
  [PaymentTermType.bill]: '請求書',
  [PaymentTermType.direct]: 'ブランドに直接支払い',
  [PaymentTermType.factoring]: '請求書払い',
  [PaymentTermType.factoredPreorder]: 'プレオーダー（請求書払い）',
};

export const SHORTEN_PAYMENT_TERM_LABEL: Record<PaymentTermType, string> = {
  [PaymentTermType.postpayment]: '後払い',
  [PaymentTermType.normal]: '通常買付',
  [PaymentTermType.outgoingFreight]: '出荷払い',
  [PaymentTermType.preorder]: 'プレオーダー',
  [PaymentTermType.bill]: '請求書',
  [PaymentTermType.direct]: '直払い',
  [PaymentTermType.factoring]: '請求書払い',
  [PaymentTermType.factoredPreorder]: 'プレオーダー（請求書払い）',
};

export const ORDER_STAUTS_LABEL: Record<OrderStatusType, string> = {
  [OrderStatusType.unconfirmed]: '未確認',
  [OrderStatusType.confirmed]: '確認済',
  [OrderStatusType.canceledBySupplier]: 'キャンセル',
  [OrderStatusType.canceledByBuyer]: 'キャンセル',
  [OrderStatusType.shipped]: '発送済',
  [OrderStatusType.canceledByStripe]: 'キャンセル',
  [OrderStatusType.default]: 'キャンセル',
  [OrderStatusType.fulfilled]: '売上済',
};

export const ORDER_STATUS_LABEL_FOR_ORDERLIST: Record<
  OrderStatusType,
  string
> = {
  [OrderStatusType.unconfirmed]: '未確認',
  [OrderStatusType.confirmed]: '未発送',
  [OrderStatusType.canceledBySupplier]: 'キャンセル済',
  [OrderStatusType.canceledByBuyer]: 'キャンセル済',
  [OrderStatusType.shipped]: '発送済',
  [OrderStatusType.canceledByStripe]: 'キャンセル済',
  [OrderStatusType.default]: 'キャンセル済',
  [OrderStatusType.fulfilled]: '売上済',
};

export const ORDER_STAUTS_LABEL_FOR_BUYER: Record<OrderStatusType, string> = {
  [OrderStatusType.unconfirmed]: 'ブランド未確認',
  [OrderStatusType.confirmed]: 'ブランド確認済',
  [OrderStatusType.canceledBySupplier]: 'キャンセル',
  [OrderStatusType.canceledByBuyer]: 'キャンセル',
  [OrderStatusType.shipped]: '発送済',
  [OrderStatusType.canceledByStripe]: 'キャンセル',
  [OrderStatusType.default]: 'キャンセル',
  [OrderStatusType.fulfilled]: '売上済',
};

export const CHARGE_STAUTS_LABEL: Record<ChargeStatusType, string> = {
  [ChargeStatusType.uncharged]: '未支払い',
  [ChargeStatusType.charged]: '支払い済',
  [ChargeStatusType.failed]: '支払い失敗',
  [ChargeStatusType.partiallyCharged]: '一部支払い済み',
};

export const RETURN_STATUS_LABEL: Record<ReturnStatusType, string> = {
  [ReturnStatusType.inRequest]: '申請中',
  [ReturnStatusType.applied]: '返品済',
  [ReturnStatusType.noReturns]: '返品なし',
  [ReturnStatusType.returnedToBrand]: 'ブランドへの返品',
};

export type OrderStatus = {
  id: string;
  order_product_id: string;
  status: OrderStatusType;
  quantity?: number;
  createdAt: string;
  owners: string[];
};

export type OrderProduct = {
  id: string;
  order_id: string;
  product_id: string;
  product_type_id: string;
  order_product_quantity: number;
  order_product_price: number;
  order_product_wholesale_rate: number;
  initial_order_product_quantity: number;
  initial_order_product_price: number;
  initial_order_product_wholesale_rate: number;
  order_product_payment_status: PaymentStatusType;
  order_product_payment_method: PaymentTermType;
  return_reason: string;
  orderstatus: OrderStatus[];
  product: Product;
  productType?: ProductType;
  order?: Order;
  owners: string[];
  reportProducts?: TReportProduct[];
};

export type ReturnProduct = {
  id: string;
  return_product_id?: string; // 過去のデータではundefined場合があるためoptional
  order_id: string;
  order_product_id: string;
  return_quantity: number;
  return_product_price: number;
  return_status: ReturnStatusType;
  stripe_payment_id: string;
  returnReason: string;
  owners: string[];
  createdAt: string;
  order?: Order;
  orderproduct?: OrderProduct;
  returnedQuantity?: number;
};

export type Order = {
  id: string;
  order_owner: string;
  buyer?: BuyerInfoType;
  brand_id: string;
  brand?: BrandDetails;
  createdAt: string;
  updatedAt: string;
  chargeStatuses: ChargeStatus[];
  orderproducts: OrderProduct[];
  owners: string[];
  stripe_payment_id?: string;
  stripe_client_secret?: string;
  payment_method_id: string;
  fee: number;
  is_direct?: boolean;
  shipping_fee: number;
  invoice_shipping_fee?: number;
  tax: number;
  shipping_zip_code: string;
  shipping_address: string;
  shipping_phone_number: string;
  shipping_name: string;
  shipping_date: string;
  carrier: string;
  tracking_number: string;
  payment_term: PaymentTermType;
  returnproducts: ReturnProduct[];
  first_order: boolean;
  order_points?: number;
  transferred_date: string | null;
  reportProducts?: TReportProduct[];
  origin_order_id?: string;
  mf_authorization_id?: string;
  mf_transaction_id?: string;
  mf_canceled_transaction_id?: string;
  cashback?: number;
  reviews?: Review[];
  memo?: string;
};

export type ShippingAddress = {
  id: string;
  name: string;
  buyer_id: string;
  zip_code: string;
  prefecture: string;
  city: string;
  building: string;
  phone_number: string;
  createdAt: string;
  updatedAt: string;
};
