import { createAction } from '@reduxjs/toolkit';
import { Buyer } from '~domain/buyer/Buyer';

const ACTION_BASE_TYPE = 'ADMIN/BUYER';

export const getBuyersStart = createAction(
  `${ACTION_BASE_TYPE}/GET_BUYERS_START`
);

export const receiveBuyers = createAction<Buyer[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_BUYERS`
);

export const clearBuyers = createAction(`${ACTION_BASE_TYPE}/CLEAR_BUYERS`);

export const getNextBuyersStart = createAction(
  `${ACTION_BASE_TYPE}/GET_NEXT_BUYERS_START`
);

export const receiveNextBuyers = createAction<Buyer[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_NEXT_BUYERS`
);

export const updateBuyerStart = createAction(
  `${ACTION_BASE_TYPE}/UPDATE_BUYER_START`
);

export const receiveBuyer = createAction<Buyer>(
  `${ACTION_BASE_TYPE}/RECEIVE_BUYER`
);
