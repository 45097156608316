// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-IndividualPageLayout-___styles-modules__contaienr{display:grid;grid-template-rows:auto 1fr;min-height:0}.src-view-widgets-IndividualPageLayout-___styles-modules__sidebar-area{overflow:auto}.src-view-widgets-IndividualPageLayout-___styles-modules__content-area{overflow:auto;position:relative}", ""]);
// Exports
exports.locals = {
	"contaienr": "src-view-widgets-IndividualPageLayout-___styles-modules__contaienr",
	"sidebar-area": "src-view-widgets-IndividualPageLayout-___styles-modules__sidebar-area",
	"content-area": "src-view-widgets-IndividualPageLayout-___styles-modules__content-area"
};
module.exports = exports;
