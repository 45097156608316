import './styles.scss';
import { BiUser } from '@react-icons/all-files/bi/BiUser';
import { Auth } from 'aws-amplify';
import React, { useCallback, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
import { clearAccount } from '~redux/account/actions/account';
import { accountSelector } from '~redux/account/selectors/account';
import { clearAuthUser } from '~redux/auth/actions/authUser';

type SupplierMenuProps = {};

const SupplierMenu: React.FC<SupplierMenuProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signOut = useCallback(async () => {
    await Auth.signOut();
    dispatch(clearAuthUser());
    dispatch(clearAccount());
    setTimeout(() => history.push('/'), 100);
  }, []);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const showUserMenu = useCallback(() => {
    setUserMenuOpen(true);
  }, []);
  const hideUserMenu = useCallback(() => {
    setUserMenuOpen(false);
  }, []);
  const account = useSelector(accountSelector);

  return (
    <div>
      <div className="topbar topbar-bg-color-default topbar-desktop-on topbar-mobile-off">
        <div className="container-fluid">
          <Row>
            <div className="logo-wrapper">
              <Link to="/home">
                <img className="img-fluid" src={logo} alt="logo" />
              </Link>
            </div>
            <Col
              xl={6}
              lg={4}
              md={4}
              className="login-header-search d-none d-md-block"
            ></Col>
            <Col xl={4} lg={6} md={6} sm={6} xs={6} className="buyer-menu-area">
              <div className="menu-area-inner">
                <Navbar>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <NavDropdown
                        id="user-menu"
                        title={<BiUser className="header-nav_menu-icon" />}
                        show={userMenuOpen}
                        onMouseEnter={showUserMenu}
                        onMouseLeave={hideUserMenu}
                        alignRight
                      >
                        <Nav.Item className="user-menu_user-info">
                          {`${account?.last_name}　${account?.first_name}`}
                          &nbsp;様
                        </Nav.Item>
                        <NavDropdown.Item onClick={signOut}>
                          ログアウト
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SupplierMenu;
