import { createReducer } from '@reduxjs/toolkit';
import {
  setFavoriteProducts,
  clearFavoriteProduct,
  appendFavoriteProduct,
  removeFavoriteProduct,
  setFavoriteProduct,
  FavoriteProduct,
} from '../actions';

const initialState: FavoriteProduct[] = [];

const favoriteProductReducer = createReducer(initialState, {
  [setFavoriteProducts.type]: (_, { payload }) => payload,
  [appendFavoriteProduct.type]: (state, { payload }) => [...state, payload],
  [removeFavoriteProduct.type]: (state, { payload }) =>
    state.filter(({ id }) => id !== payload),
  [setFavoriteProduct.type]: (state, { payload }) =>
    state.map((item) => {
      var rs = null;
      for (const obj of payload) {
        if (item.product.id === obj.product_id) {
          rs = obj;
          break;
        }
      }
      if (rs) {
        return rs;
      }
    }),
  [clearFavoriteProduct.type]: () => initialState,
});

export default favoriteProductReducer;
