import { createAction } from '@reduxjs/toolkit';
import { Product } from '~domain/product/Product';

export type FavoriteProductList = {
  id: string;
  account_id: string;
  list_name: string;
  order: number;
  product?: { items: Product[] };
};

const ACTION_BASE_TYPE = 'FAVORITEPRODUCTLIST';

export const setFavoriteProductLists = createAction<FavoriteProductList[]>(
  `${ACTION_BASE_TYPE}/SET_FAVORITE_PRODUCT_LISTS`
);

export const clearFavoriteProductList = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_FAVORITE_PRODUCT_LIST`
);

export const appendFavoriteProductList = createAction<FavoriteProductList>(
  `${ACTION_BASE_TYPE}/APPEND_FAVORITE_PRODUCT_LIST`
);

export const removeFavoriteProductList = createAction<string>(
  `${ACTION_BASE_TYPE}/REMOVE_FAVORITE_PRODUCT_LIST`
);

export const setFavoriteProductList = createAction<{
  id: string;
  list_name: string;
}>(`${ACTION_BASE_TYPE}/SET_FAVORITE_PRODUCT_LIST`);
