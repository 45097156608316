import { FiHeart } from '@react-icons/all-files/fi/FiHeart';
import { FiHelpCircle } from '@react-icons/all-files/fi/FiHelpCircle';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import logo from '../../../assets/images/mag/logo.png';
import CartIcon from '../../../layouts/header/LoginHeader/BuyerMenu/CategoryList/CartIcon';
import BuyerNavDropdown from '../../../layouts/header/LoginHeader/BuyerMenu/NavDropdown';
import { signup } from '~redux/auth/reducers/signup';
import { authUserSelector } from '~redux/auth/selectors/authUser';

type MagHeaderProps = {};

const MagHeader: React.FC<MagHeaderProps> = () => {
  const auth = useSelector(authUserSelector);
  const dispatch = useDispatch();
  const signUp = useCallback(() => {
    dispatch(signup({ nextPath: window.location.pathname }));
  }, []);

  return (
    <header
      className="border-bottom login-header position-sticky w-100 bg-white"
      style={{ top: 0, zIndex: 1000 }}
    >
      <div className="px-4 py-2 d-flex justify-content-between align-items-center">
        {/* <div className="d-md-none">
          <FiMenu size={20} />
        </div> */}
        <div>
          <a href="/">
            <img src={logo} alt="MaG.+" style={{ width: '100px' }} />
          </a>
        </div>
        {!auth && (
          <div>
            <Button className="py-2 px-3" onClick={signUp}>
              homulaアカウントを作成
            </Button>
          </div>
        )}
        {auth && (
          <div className="d-flex align-items-center">
            <div className="ml-auto mr-3 flex-shrink-0">
              <CartIcon />
            </div>
            <div className="m-0 mr-3">
              <Link className="favorite--brands-link" to="/favorite/products">
                <FiHeart id="favorite-icon" size={20} />
                <UncontrolledTooltip target="favorite-icon" placement="bottom">
                  <p className="m-0">お気に入り</p>
                </UncontrolledTooltip>
              </Link>
            </div>
            <div className="d-none d-sm-block m-0 mr-3">
              <a
                className="blog-link"
                href="https://blog.homula.jp/help/buyers/%E5%95%8F%E5%90%88%E3%81%9B%E7%AA%93%E5%8F%A3%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                target="_blank"
              >
                <FiHelpCircle id="help-icon" size={20} />
                <UncontrolledTooltip target="help-icon" placement="bottom">
                  <p className="m-0">お問い合わせ</p>
                </UncontrolledTooltip>
              </a>
            </div>
            <BuyerNavDropdown />
          </div>
        )}
      </div>
    </header>
  );
};

export default MagHeader;
