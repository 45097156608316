import React from 'react';
import Spinner from 'react-spinner-material';

type LoadingContainerProps = {
  isLoading: boolean;
};

const LoadingContainer: React.FC<LoadingContainerProps> = ({
  isLoading,
  children,
}) => {
  return (
    <>
      {isLoading && (
        <div
          id="preloader"
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner radius={30} color={'#333'} stroke={2} visible={true} />
        </div>
      )}
      {children}
    </>
  );
};

export default LoadingContainer;
