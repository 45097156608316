import { Dialog } from '@material-ui/core';
import { FiSearch } from '@react-icons/all-files/fi/FiSearch';
import React, { useCallback, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import SearchForm from '../../../widgets/SearchForm';

const SearchFormDialog: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <button
        id="search-icon"
        className="border-0 bg-white"
        type="button"
        onClick={(e) => {
          e.currentTarget.blur();
          handleOpen();
        }}
      >
        <FiSearch size={20} />
      </button>
      <UncontrolledTooltip target="search-icon" placement="bottom">
        検索
      </UncontrolledTooltip>
      <Dialog fullScreen open={isOpen} onClose={handleClose}>
        <div className="p-4">
          <SearchForm dialog onSubmit={handleClose} />
        </div>
      </Dialog>
    </>
  );
};

export default SearchFormDialog;
