import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

const tagSelector = (state: RootState) => state.tag;

export const tagListSelector = createSelector(
  tagSelector,
  (tag) => tag.tagList
);

export const makeTagSelector = (tagId: string) =>
  createSelector(
    tagListSelector,
    (items) =>
      (tagId ? items.find((item) => item.id === tagId) : undefined) ?? {
        id: '',
        tag_name: '',
        brands: [],
      }
  );
