import React from 'react';
import { useTitle } from '~view/hooks/title';

const withTitle = (Component: React.ComponentType<any>, title?: string) => {
  return (props: any) => {
    useTitle(title);
    return <Component {...props} />;
  };
};

export default withTitle;
