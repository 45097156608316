import {
  createTransaction,
  billingAuthorization,
  cancelAuthorization,
  cancelTransaction,
  updateAuthorization,
} from '../../graphql/mutations';
import { getInvoiceCreditFacility } from '../../graphql/queries';
import { Entity } from '~core/domain/Entity';
import { executeQuery } from '~utils/graphql';

type BillPaymentType = {
  buyerId?: string;
  customerId?: string;
};

export class BillPayment extends Entity<BillPaymentType> {
  static create(props?: BillPaymentType) {
    return new BillPayment({
      ...props,
    });
  }

  async authorization(orderId: string, amount: number) {
    const res = await executeQuery(billingAuthorization, {
      customer_id: this.customerId,
      amount,
      number: orderId,
    });
    return JSON.parse(res.data.billingAuthorization);
  }

  async getCreditFacility(
    credit_ym?: string
  ): Promise<{ amount: number; balance: number }> {
    const res = await executeQuery(getInvoiceCreditFacility, {
      buyer_id: this.buyerId,
      credit_ym,
    });
    return res.data.getInvoiceCreditFacility;
  }

  async createTransaction(orderId: string) {
    const res = await executeQuery(createTransaction, {
      buyer_id: this.buyerId,
      order_id: orderId,
    });
    return JSON.parse(res.data.createTransaction);
  }

  async cancelAuthorization(authorizationId: string) {
    const res = await executeQuery(cancelAuthorization, {
      authorization_id: authorizationId,
    });
    return JSON.parse(res.data.cancelAuthorization);
  }

  async cancelTransaction(transactionId: string) {
    const res = await executeQuery(cancelTransaction, {
      transaction_id: transactionId,
    });
    return JSON.parse(res.data.cancelTransaction);
  }

  async updateAuthorization(order_id: string, amount: number) {
    const res = await executeQuery(updateAuthorization, {
      buyer_id: this.buyerId,
      order_id,
      amount,
    });
    return JSON.parse(res.data.updateAuthorization);
  }
}
