import { createSlice } from '@reduxjs/toolkit';

const receivedirectlyAtStoreSlice = createSlice({
  initialState: false,
  name: 'cart/receivedirectlyAtStore',
  reducers: {
    setReceivedirectlyAtStore: (_state, { payload }) => payload,
  },
});

export const {
  setReceivedirectlyAtStore,
} = receivedirectlyAtStoreSlice.actions;

export default receivedirectlyAtStoreSlice.reducer;
