import { combineReducers } from 'redux';
import itemsReducer from './items';
import loadingReducer from './loading';
import orderedBrands from './orderedBrands';
import productReducer from './product';
import cartProductsReducer from './products';
import receivedirectlyAtStoreReducer from './receivedirectlyAtStore';
import relatedProducts from './relatedProducts';

const cartReducer = combineReducers({
  items: itemsReducer,
  isLoading: loadingReducer,
  products: cartProductsReducer,
  product: productReducer,
  orderedBrands: orderedBrands,
  relatedProducts,
  receivedirectlyAtStore: receivedirectlyAtStoreReducer,
});

export default cartReducer;
