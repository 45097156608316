import { createReducer } from '@reduxjs/toolkit';
import { Menu, setMenuList } from '../actions';

const initialState: Menu[] = [];

const menuListReducer = createReducer(initialState, {
  [setMenuList.type]: (_, { payload }) => payload,
});

export default menuListReducer;
