import { createAction } from '@reduxjs/toolkit';
import { Order } from '~redux/buyer/types';

const ACTION_BASE_TYPE = 'ADMIN/CHARGE_BATCH';

export const getChargeBatchTargetsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_CHARGE_BATCH_TARGETS_START`
);

export const receiveChargeBatchTargets = createAction<Order[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_CHARGE_BATCH_TARGETS`
);
