import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '~redux/auth/reducers/signin';
import { isSignInSelector } from '~redux/auth/selectors/singin';
import SignInModal from './SignInModal';

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const isSignIn = useSelector(isSignInSelector);
  const close = useCallback(() => {
    dispatch(clear());
  }, []);
  const onSignIn = useCallback(() => {
    close();
  }, [close]);

  if (!isSignIn) {
    return null;
  }

  return <SignInModal isOpen toggle={close} onSignin={onSignIn} />;
};

export default SignIn;
