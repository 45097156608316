import { createReducer } from '@reduxjs/toolkit';
import {
  clearMessages,
  Message,
  postGetMessages,
  // postSendMessage,
  pushMessage,
} from '../actions';

type MessagesState = Message[];
const initialState: MessagesState = [];

const messagesReducer = createReducer(initialState, {
  [postGetMessages.type]: (state, { payload }) => [...payload, ...state],
  // [postSendMessage.type]: (state, { payload }) => {
  //   if (payload) return [...state, payload];
  //   return state;
  // },
  [pushMessage.type]: (state, { payload }) => [...state, payload],
  [clearMessages.type]: () => initialState,
});

export default messagesReducer;
