import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '~types/api';

const initialState: Record<string, Product> = {};

const relatedProductsSlice = createSlice({
  initialState,
  name: 'cart/relatedProducts',
  reducers: {
    recievedRelatedProducts: (
      _,
      {
        payload: { relatedProducts },
      }: PayloadAction<{ relatedProducts: Product[] }>
    ) => {
      return relatedProducts.reduce(
        (prev, product) => ({ ...prev, [product.id!]: product }),
        {}
      );
    },
    clearedRelatedProducts: () => initialState,
  },
});

export const {
  recievedRelatedProducts,
  clearedRelatedProducts,
} = relatedProductsSlice.actions;

export default relatedProductsSlice.reducer;
