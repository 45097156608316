import './styles.modules.scss';
import { AiOutlineShop } from '@react-icons/all-files/ai/AiOutlineShop';
import { FiActivity } from '@react-icons/all-files/fi/FiActivity';
import { FiFileText } from '@react-icons/all-files/fi/FiFileText';
import { FiHome } from '@react-icons/all-files/fi/FiHome';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiMessageSquare } from '@react-icons/all-files/fi/FiMessageSquare';
import { FiPackage } from '@react-icons/all-files/fi/FiPackage';
import { FiUser } from '@react-icons/all-files/fi/FiUser';
import { FiUsers } from '@react-icons/all-files/fi/FiUsers';
import { IconType } from '@react-icons/all-files/lib';
import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { checkNewMessages } from '~redux/messages/actions';
import { newMessagesSelector } from '~redux/messages/selectors';
import {
  getNewMessages,
  subscribeNewMessages,
  unsubscribeNewMessages,
} from '~redux/messages/thunk';
import { newOrdersSelector } from '~redux/order/selectors';
import {
  getNewOrders,
  subscribeOrders,
  unsubscribeOrders,
} from '~redux/order/thunk';

type SupplierMenuProps = {};

type MenuItemConfig = {
  title: string;
  to: string;
  emphasis?: boolean;
  hidden?: boolean;
  onClick?: () => void;
};

type MenuConfig = {
  icon: IconType;
  title: string;
  items?: MenuItemConfig[];
  hidden?: boolean;
}[];

export const SupplierMenu: React.FC<SupplierMenuProps> = () => {
  const dispatch = useDispatch();

  const authUser = useSelector(authUserSelector);
  const admin = useMemo(() => authUser?.attributes.profile === 'admin', [
    authUser,
  ]);

  const newOrders = useSelector(newOrdersSelector);
  const existsNewOrder = useMemo(() => !!newOrders.unCheckedOrders.length, [
    newOrders,
  ]);

  const newMessages = useSelector(newMessagesSelector);
  const existsNewMessages = useMemo(
    () => !!newMessages.unCheckedMessages.length,
    [newMessages]
  );
  const checkMessages = useCallback(() => {
    dispatch(checkNewMessages());
  }, []);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    if (!newOrders.checkedOrders.length && !newOrders.unCheckedOrders.length) {
      dispatch(getNewOrders(authUser.attributes.sub));
    }
    dispatch(subscribeOrders(authUser.attributes.sub));
    return () => {
      dispatch(unsubscribeOrders());
    };
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    if (
      !newMessages.checkedMessages.length &&
      !newMessages.unCheckedMessages.length
    ) {
      dispatch(getNewMessages(authUser.attributes.sub));
    }
    dispatch(subscribeNewMessages(authUser.attributes.sub));
    return () => {
      dispatch(unsubscribeNewMessages());
    };
  }, [authUser]);

  if (!authUser) {
    return null;
  }

  const menu: MenuConfig = [
    {
      icon: FiHome,
      title: 'ホーム',
      items: [
        {
          title: 'ホーム',
          to: '/home',
        },
      ],
    },
    {
      icon: FiFileText,
      title: '注文管理',
      items: [
        {
          title: '注文一覧',
          to: '/OrderList',
          emphasis: existsNewOrder,
          hidden: admin,
        },
        {
          title: 'セールスレポート',
          to: '/sales/report',
          hidden: admin,
        },
        {
          title: 'CSV出力',
          to: '/OrderExport',
          hidden: admin,
        },
        {
          title: '注文作成',
          to: '/OrderCreate',
          hidden: admin,
        },
        {
          title: 'お支払い明細',
          to: '/Invoices',
        },
      ],
    },
    {
      icon: FiMessageSquare,
      title: 'メッセージ',
      items: [
        {
          title: 'メッセージ',
          to: '/messages',
          emphasis: existsNewMessages,
          onClick: checkMessages,
        },
        {
          title: '自動送信設定',
          to: '/messages/auto-reply',
        },
      ],
    },
    {
      icon: FiPackage,
      title: '商品管理',
      items: [
        {
          title: 'ブランド一覧',
          to: '/MyBrandList',
        },
        {
          title: '商品一覧',
          to: '/MyProductList',
        },
        {
          title: '商品登録',
          to: '/ProductRegistration',
        },
        {
          title: '商品一括登録',
          to: '/ProductBulkRegistration',
        },
        {
          title: 'コレクション一覧',
          to: '/MyCollectionList',
        },
        {
          title: '在庫管理',
          to: '/inventory',
        },
      ],
    },
    {
      icon: AiOutlineShop,
      title: 'マーケットプレイス',
      items: [
        {
          title: '商品表示順入れ替え',
          to: '/marketplace/arrangement',
        },
      ],
    },
    {
      icon: FiUsers,
      title: 'カスタマー管理',
      items: [
        {
          title: '顧客リスト',
          to: '/customers',
        },
        {
          title: 'レビュー一覧',
          to: '/ReviewList',
        },
      ],
    },
    {
      icon: FiMail,
      title: 'マーケティング',
      items: [
        {
          title: '招待リンク',
          to: '/direct-link',
        },
        {
          title: '一斉メール',
          to: '/marketing/campaigns',
        },
      ],
    },
    {
      icon: FiActivity,
      title: 'アナリティクス',
      items: [
        {
          title: 'アナリティクス',
          to: '/analytics',
        },
      ],
    },
    {
      icon: FiUser,
      title: 'アカウント管理',
      hidden: admin,
      items: [
        {
          title: 'プロフィール確認',
          to: '/Account/AccountProfile',
        },
        {
          title: 'パスワード変更',
          to: '/Account/ChangePassword',
        },
      ],
    },
  ];

  return (
    <nav styleName="menu">
      {menu.map(
        ({ title, icon: Icon, items, hidden }, menuIndex) =>
          !hidden && (
            <div key={menuIndex} styleName="inner">
              <div>
                <strong>
                  <Icon className="mr-2" />
                  {title}
                </strong>
              </div>
              {items && items.length && (
                <ul styleName="items-container">
                  {items.map((item, itemIndex) => (
                    <MenuItem
                      key={`menu_${menuIndex}_item_${itemIndex}`}
                      {...item}
                    />
                  ))}
                </ul>
              )}
            </div>
          )
      )}
    </nav>
  );
};

type MenuItemProps = MenuItemConfig;

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  to,
  emphasis,
  hidden,
  onClick,
}) => {
  return !hidden ? (
    <li>
      <NavLink className="pl-4" exact to={to} onClick={onClick}>
        <span>
          {title}
          {emphasis && <strong>・</strong>}
        </span>
      </NavLink>
    </li>
  ) : null;
};

const NavLink: React.FC<RouterNavLinkProps> = (props) => {
  const activeStyle: CSSProperties = {
    fontWeight: 'bold',
    textDecoration: 'underline',
  };

  return <RouterNavLink {...props} activeStyle={activeStyle} />;
};
