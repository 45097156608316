// 0:未チャージ(default)、1:チャージ成功、2:チャージ失敗
export enum ChargeStatusType {
  uncharged = 0,
  charged = 1,
  failed = 2,
  partiallyCharged = 3,
}

export type ChargeStatus = {
  owners: string[];
  updatedAt: string;
  status: ChargeStatusType;
  createdAt: string;
  order_id: string;
  payment_id?: string;
  amount?: number;
};
