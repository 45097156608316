import React from 'react';
import SupplierPage from '~view/widgets/SupplierPage';

const withSupplierPage = (
  Component: React.ComponentType<any>,
  title?: string
) => {
  return (props: any) => {
    return (
      <SupplierPage title={title}>
        <Component {...props} />
      </SupplierPage>
    );
  };
};

export default withSupplierPage;
