/* eslint-disable import/order */
import React from 'react';
import { Route } from 'react-router';
import withTitle from '~view/hoc/withTitle';

import PublicRoute from '~view/widgets/PublicRoute';
import SupplierRoute from '~view/widgets/SupplierRoute';
import AutoReply from './AutoReply';
import SupplierSignup from './Signup';

//pages
const SupplierLp = React.lazy(() => import('~view/pages/common/SupplierLp'));
const Suppliertermsofservice = React.lazy(
  () => import('~view/pages/common/SupplierTermsofService')
);
const SupplierMaterialRequest = React.lazy(
  () => import('~view/pages/common/SupplierMaterialRequest')
);
const SupplierRegistrationHubs = React.lazy(
  () => import('~view/UserRegistration/SupplierRegistrationHubs')
);
const SupplierRegistrationDone = React.lazy(
  () => import('~view/UserRegistration/SupplierRegistrationDone')
);
const OrderList = React.lazy(() => import('~view/pages/common/OrderList'));
const OrderDetail = React.lazy(() => import('~view/pages/common/OrderDetail'));
const OrderCheck = React.lazy(() => import('~view/pages/common/OrderCheck'));
const OrderEdit = React.lazy(() => import('~view/pages/common/OrderEdit'));
const OrderSplit = React.lazy(() => import('~view/pages/supplier/OrderSplit'));
const ShippingInfo = React.lazy(
  () => import('~view/pages/common/ShippingInfo')
);
const ProductBulkRegistration = React.lazy(
  () => import('~view/pages/common/ProductBulkRegistration')
);
const BrandRegistration = React.lazy(
  () => import('~view/Account/BrandRegistration.js')
);
const MyBrandList = React.lazy(() => import('~view/pages/common/MyBrandList'));
const MyBrandedit = React.lazy(() => import('~view/pages/common/MyBrandedit'));
const BrandProductList = React.lazy(
  () => import('~view/pages/common/BrandProductList')
);
const MyProductedit = React.lazy(
  () => import('~view/pages/common/ProductEdit')
);
const MyCollectionList = React.lazy(
  () => import('~view/pages/common/MyCollectionList')
);
const CollectionRegistration = React.lazy(
  () => import('~view/pages/common/CollectionRegistration')
);
const DirectLinks = React.lazy(
  () => import('~view/pages/supplier/DirectLinks')
);
const Invoices = React.lazy(() => import('~view/pages/supplier/Invoices'));
const OrderExport = React.lazy(
  () => import('~view/pages/supplier/OrderExport')
);
const OrderCreate = React.lazy(() => import('./OrderCreate'));
const Analytics = React.lazy(() => import('./Analytics'));
const Customers = React.lazy(() => import('./Customers'));
const ReviewList = React.lazy(() => import('./ReviewList'));
const Customer = React.lazy(() => import('./Customer'));
const Campaigns = React.lazy(() => import('./Campaigns'));
const Campaign = React.lazy(() => import('./Campaign'));
const Inventory = React.lazy(() => import('./Inventory'));
const Arrangement = React.lazy(() => import('./Arrangement'));
const SalesReport = React.lazy(() => import('./SalesReport'));

const SupplierRoutes: React.FC = () => {
  return (
    <>
      <PublicRoute
        exact
        path="/registration/supplier"
        title="出店応募フォーム"
        component={SupplierRegistrationHubs}
        hideMenu
      />
      <PublicRoute
        exact
        path="/registration/supplier/done"
        title="出店応募フォーム"
        component={SupplierRegistrationDone}
        hideMenu
      />
      <PublicRoute
        exact
        path="/material/supplier"
        title="出店資料請求フォーム"
        component={SupplierMaterialRequest}
        hideMenu
      />
      <PublicRoute
        exact
        path="/terms/suppliertermsofservice"
        title="サプライヤー利用規約"
        component={Suppliertermsofservice}
        hideMenu
      />
      <PublicRoute
        exact
        path="/SupplierLp"
        title="homula - Supplier"
        component={SupplierLp}
        hideMenu
      />
      <SupplierRoute
        path={`/OrderList`}
        title="オーダー確認 - Supplier"
        component={OrderList}
      />
      <SupplierRoute
        path={`/OrderExport`}
        title="エクスポート - Supplier"
        component={OrderExport}
      />
      <SupplierRoute
        path={`/sales/report`}
        title="セールスレポート - Supplier"
        component={SalesReport}
      />
      <SupplierRoute path={`/OrderCheck/:order_id`} component={OrderCheck} />
      <SupplierRoute
        path={`/Orderdetail/:order_id`}
        title="注文詳細 - Supplier"
        component={OrderDetail}
        exact={true}
      />
      <SupplierRoute
        path={`/Orderdetail/:order_id/split`}
        title="注文分割 - Supplier"
        component={OrderSplit}
        exact={true}
      />
      <SupplierRoute
        path={`/OrderEdit/:order_id`}
        title="注文編集 - Supplier"
        component={OrderEdit}
      />
      <SupplierRoute
        path={`/Order/ShippingInfo/:order_id`}
        title="配送情報 - Supplier"
        component={ShippingInfo}
      />
      <SupplierRoute
        path={`/Invoices`}
        title="お支払い明細 - Supplier"
        component={Invoices}
      />
      <SupplierRoute
        path={`/OrderCreate`}
        title="オーダー作成 - Supplier"
        component={OrderCreate}
      />
      <SupplierRoute
        exact
        path="/ProductRegistration"
        title="商品登録 - Supplier"
        component={MyProductedit}
      />
      <SupplierRoute
        exact
        path="/ProductBulkRegistration"
        title="商品一括登録 - Supplier"
        component={ProductBulkRegistration}
      />
      <SupplierRoute
        exact
        path="/BrandRegistration"
        title="ブランド登録 - Supplier"
        component={BrandRegistration}
      />
      <SupplierRoute
        exact
        path="/MyBrandList"
        title="ブランド一覧 - Supplier"
        component={MyBrandList}
      />
      <SupplierRoute
        exact
        path="/MyBrandList/edit/:brand_id"
        title="ブランド編集 - Supplier"
        component={MyBrandedit}
      />
      <SupplierRoute
        exact
        path="/MyProductList"
        title="商品一覧 - Supplier"
        component={BrandProductList}
      />
      <SupplierRoute
        exact
        path="/MyProductList/:brand_id"
        title="商品一覧 - Supplier"
        component={BrandProductList}
      />
      <SupplierRoute
        exact
        path="/MyProductList/edit/:product_id"
        title="商品編集 - Supplier"
        component={MyProductedit}
      />
      <SupplierRoute
        exact
        path="/MyCollectionList"
        title="コレクション一覧 - Supplier"
        component={MyCollectionList}
      />
      <SupplierRoute
        path="/MyCollectionList/:brand_id"
        title="コレクション一覧 - Supplier"
        component={MyCollectionList}
      />
      <SupplierRoute
        path="/CollectionRegistration/:brand_id"
        title="コレクション登録 - Supplier"
        component={CollectionRegistration}
      />
      <SupplierRoute
        path="/CollectionEdit/:brand_id/:collection_id"
        title="コレクション編集 - Supplier"
        component={CollectionRegistration}
      />
      <SupplierRoute
        path="/inventory"
        title="在庫管理 - Supplier"
        component={Inventory}
      />
      <SupplierRoute
        exact
        path="/customers"
        title="顧客一覧 - Supplier"
        component={Customers}
      />
      <SupplierRoute
        exact
        path="/customers/:customerId"
        title="顧客情報 - Supplier"
        component={Customer}
      />
      <SupplierRoute
        exact
        path="/ReviewList"
        title="レビュー一覧 - Supplier"
        component={ReviewList}
      />
      <SupplierRoute
        path="/marketing/campaigns"
        title="一斉メール - Supplier"
        component={Campaigns}
        exact
      />
      <SupplierRoute
        path="/marketing/campaigns/:campaignId"
        title="一斉メール - Supplier"
        component={Campaign}
        exact
      />
      <SupplierRoute
        path="/analytics"
        title="アナリティクス - Supplier"
        component={Analytics}
      />
      <SupplierRoute
        path="/direct-link"
        title="招待リンク - Supplier"
        component={DirectLinks}
      />
      <SupplierRoute
        exact
        path="/marketplace/arrangement"
        title="商品表示順入れ替え - Supplier"
        component={Arrangement}
      />
      <SupplierRoute
        exact
        path="/marketplace/arrangement/:brandId"
        title="商品表示順入れ替え - Supplier"
        component={Arrangement}
      />
      <Route
        exact
        path="/supplier/sign-up"
        component={withTitle(SupplierSignup, '会社情報入力 - Supplier')}
      />
      <SupplierRoute
        exact
        path="/messages/auto-reply"
        component={withTitle(AutoReply, 'メッセージ自動送信 - Supplier')}
      />
    </>
  );
};

export default SupplierRoutes;
