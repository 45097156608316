import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Order } from './Order';
import { Entity } from '~core/domain/Entity';
import {
  OrderProduct,
  ReturnProduct as OriginalReturnProductJson,
  ReturnStatusType,
} from '~redux/buyer/types';
import { removeItems } from '~utils/graphql';
import { calculateTax } from '~utils/price';

export type ReturnProductJson = OriginalReturnProductJson & {
  appliedAt?: string;
};

export type ReturnProductType = {
  id: string;
  return_product_id?: string; // 過去のデータではundefined場合があるためoptional
  order_id: string;
  order_product_id: string;
  return_quantity: number;
  return_product_price: number;
  return_status: ReturnStatusType;
  returnReason: string;
  stripe_payment_id: string;
  owners: string[];
  createdAt: string;
  appliedAt?: string;
  order?: Order;
  orderproduct?: OrderProduct;
  returnedQuantity?: number;
};

export class ReturnProduct extends Entity<ReturnProductType> {
  private constructor(params: ReturnProductType) {
    super(params);
  }

  static async create({
    order,
    ...returnProduct
  }: ReturnProductJson): Promise<ReturnProduct> {
    return new ReturnProduct({
      ...returnProduct,
      order: order && (await Order.create(order)),
    });
  }

  static async createById(id: string) {
    const {
      data: { getReturnProduct: getReturnProductResponse },
    } = await API.graphql<any>(
      graphqlOperation(queries.getReturnProduct, {
        id,
      })
    );

    const returnProductJson = removeItems<ReturnProductJson>(
      getReturnProductResponse
    );

    if (!returnProductJson.order?.id) {
      throw new Error('オーダーIDの取得に失敗しました。');
    }

    const order = await Order.createById(returnProductJson.order.id);

    return ReturnProduct.create({
      ...returnProductJson,
      order: order.toJson(),
    });
  }

  get returnAmount() {
    return this.calcReturnAmount(this.returnedQuantity ?? this.return_quantity);
  }

  calcReturnAmount(quantity: number) {
    const returnAmount = quantity * this.return_product_price;
    const tax = calculateTax(
      returnAmount,
      this.order?.tax && this.order.tax / 100
    );
    return returnAmount + tax;
  }

  async apply(status: ReturnStatusType, quantity: number) {
    if (this.return_status !== ReturnStatusType.inRequest) {
      throw new Error(`返品ステータスが不正です。返品ID:${this.id}`);
    }

    const response = await API.graphql<any>(
      graphqlOperation(mutations.createReturnProduct, {
        input: {
          return_product_id: this.return_product_id,
          order_id: this.order_id,
          order_product_id: this.order_product_id,
          return_quantity: quantity,
          return_product_price: this.return_product_price,
          return_status: status,
          stripe_payment_id: this.stripe_payment_id,
          owners: this.owners,
        },
      })
    );

    const returnProduct = response.data!.createReturnProduct;

    const getReturnProductResponse = await API.graphql<any>(
      graphqlOperation(queries.getReturnProduct, {
        id: returnProduct.id,
      })
    );
    const newReturnProductData = getReturnProductResponse.data!
      .getReturnProduct;

    const {
      order: {
        buyer: newBuyer,
        chargeStatuses: newChargeStatuses,
        orderproducts: newOrderproducts,
        returnproducts: newReturnproducts,
        ...restNewReturnProductOrder
      },
      ...restNewReturnProductData
    } = newReturnProductData;

    const newReturnProduct = await ReturnProduct.create({
      ...restNewReturnProductData,
      order: restNewReturnProductOrder,
      returnedQuantity: restNewReturnProductData.return_quantity,
    });

    return newReturnProduct;
  }

  toJson(): OriginalReturnProductJson {
    return {
      id: this.id,
      return_product_id: this.return_product_id,
      order_id: this.order_id,
      order_product_id: this.order_product_id,
      return_quantity: this.return_quantity,
      return_product_price: this.return_product_price,
      return_status: this.return_status,
      returnReason: this.returnReason,
      stripe_payment_id: this.stripe_payment_id,
      owners: this.owners,
      createdAt: this.createdAt,
    };
  }
}
