import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import AuthPage from '../AuthPage';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import './styles.scss';

type AdminPageProps = {
  title?: string;
};

const AdminPage: React.FC<AdminPageProps> = ({ title, children }) => {
  const authUser = useSelector(authUserSelector);

  if (authUser && (authUser.attributes.profile || 'admin') !== 'admin') {
    return <Redirect to="/home" />;
  }

  return (
    <AuthPage title={title} fullscreen>
      <div className="admin-page flex-grow-1">{children}</div>
    </AuthPage>
  );
};

export default AdminPage;
