import '../styles.scss';
import qs from 'querystring';
import { FiUser } from '@react-icons/all-files/fi/FiUser';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { BillPayment } from '~domain/payment/BillPayment';
import { clearAccount } from '~redux/account/actions/account';
import { accountSelector } from '~redux/account/selectors/account';
import { clearAuthUser } from '~redux/auth/actions/authUser';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { clearBuyerInfo } from '~redux/buyer/actions/buyerInfo';
import { buyerInfoSelector } from '~redux/buyer/selectors';
import { checkNewMessages } from '~redux/messages/actions';
import { newMessagesSelector } from '~redux/messages/selectors';
import {
  getNewMessages,
  subscribeNewMessages,
  unsubscribeNewMessages,
} from '~redux/messages/thunk';
import { selectTotalPoints } from '~redux/points/selectors';
import { getCreditFacility } from '~utils/buyer';
import { formatCurrency } from '~utils/formatter';

const UserIcon: React.FC<{ existNewMessages: boolean }> = React.memo(
  ({ existNewMessages }) => {
    return (
      <span
        className={classNames({
          'd-inline m-0': true,
          'header-user-icon': true,
          'exist-new-messages-icon': existNewMessages,
        })}
      >
        <FiUser size={20} />
      </span>
    );
  }
);

const BuyerNavDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const signOut = useCallback(async () => {
    await Auth.signOut();
    dispatch(clearAuthUser());
    dispatch(clearAccount());
    dispatch(clearBuyerInfo());
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  }, []);
  const user = useSelector(authUserSelector);
  const account = useSelector(accountSelector);
  const buyerInfo = useSelector(buyerInfoSelector);
  const newMessages = useSelector(newMessagesSelector);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const showUserMenu = useCallback(() => {
    setUserMenuOpen(true);
  }, []);
  const hideUserMenu = useCallback(() => {
    setUserMenuOpen(false);
  }, []);
  const existNewMessages = useMemo(
    () => !!newMessages.unCheckedMessages.length,
    [newMessages]
  );
  const checkMessages = useCallback(() => {
    dispatch(checkNewMessages());
    hideUserMenu();
  }, []);
  useEffect(() => {
    if (!account) {
      return;
    }
    if (
      !newMessages.checkedMessages.length &&
      !newMessages.unCheckedMessages.length
    ) {
      dispatch(getNewMessages(account.id));
    }
    dispatch(subscribeNewMessages(account.id));
    return () => {
      dispatch(unsubscribeNewMessages());
    };
  }, []);

  const totalPoint = useSelector(selectTotalPoints);

  const billingPath = useMemo(() => {
    if (!user || !account || !buyerInfo) return;
    if (buyerInfo.mf_examination_status) {
      return '/Account/billing';
    }
    const query = {
      email: user.attributes.email,
      zip: account.zip_code,
      name:
        account.company_name === '個人事業主'
          ? buyerInfo.shop_name
          : account.company_name,
      destination_name: buyerInfo.owner_name,
      website: account.web_site_URL,
      representative_name: buyerInfo.owner_name,
      buyer_id: buyerInfo.id,
    };
    const queryStr = qs.stringify(query);
    return `/Account/billing-examination?${queryStr}`;
  }, [user, account, buyerInfo]);

  const [creditFacility, setCreditFacility] = useState(0);
  useEffect(() => {
    if (!buyerInfo) return;
    getCreditFacility(buyerInfo.buyer_id).then(setCreditFacility);
  }, [buyerInfo]);

  const [billCreditFacility, setBillCreditFacility] = useState(0);
  useEffect(() => {
    if (!buyerInfo) return;
    const bill = new BillPayment({ buyerId: buyerInfo.id });
    bill
      .getCreditFacility()
      .then((res) => setBillCreditFacility(res?.balance ?? 0));
  }, [buyerInfo]);

  return (
    <Nav>
      <NavDropdown
        id="user-menu"
        className="menu-dropdown-account"
        title={<UserIcon existNewMessages={existNewMessages} />}
        show={userMenuOpen}
        onMouseEnter={() => showUserMenu()}
        onMouseLeave={() => hideUserMenu()}
        onTouchStart={() => showUserMenu()}
        alignRight
      >
        <Nav.Item className="user-menu_user-info">
          {`${account?.last_name}　${account?.first_name}`}
          &nbsp;様
        </Nav.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Link}
          to="/Account/AccountProfile"
          onClick={hideUserMenu}
        >
          <div>
            <small>クレジット後払い与信枠</small>
          </div>
          <b>{formatCurrency(creditFacility)}</b>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/Account/point" onClick={hideUserMenu}>
          <div>
            <small>ご利用可能ポイント</small>
          </div>
          <b>
            {totalPoint.toLocaleString()}
            pt
          </b>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to={billingPath!} onClick={hideUserMenu}>
          <div>
            <small>請求書払い与信枠</small>
          </div>
          <b>
            {buyerInfo?.mf_examination_status
              ? buyerInfo.mf_examination_status === 'passed'
                ? formatCurrency(billCreditFacility)
                : buyerInfo.mf_examination_status === 'rejected'
                ? '利用できません'
                : '審査中'
              : '請求書払いを利用する'}
          </b>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Link}
          to="/Account/OrderHistory"
          onClick={hideUserMenu}
        >
          注文履歴
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/Account/payments/unpaid"
          onClick={hideUserMenu}
        >
          お支払い明細
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/Account/OrderHistory"
          onClick={hideUserMenu}
        >
          不良品・欠品を報告
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/messages"
          onClick={checkMessages}
          className={classNames({
            'exist-new-messages-link': existNewMessages,
          })}
        >
          メッセージ
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/Account/AccountProfile"
          onClick={hideUserMenu}
        >
          アカウント
        </NavDropdown.Item>
        <NavDropdown.Item
          href="https://support.homula.jp/help/%E3%83%90%E3%82%A4%E3%83%A4%E3%83%BCfaq"
          target="_blank"
          onClick={hideUserMenu}
        >
          ヘルプ
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={signOut}>ログアウト</NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};

export default BuyerNavDropdown;
