import { combineReducers } from 'redux';
import byId from './slice/byId';
import inputPoint from './slice/inputPoint';

export const pointReducer = combineReducers({
  inputPoint,
  byId,
});

export default pointReducer;
