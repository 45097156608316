import './style.scss';
import { FiShoppingCart } from '@react-icons/all-files/fi/FiShoppingCart';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { selectCartQuantity } from '~redux/cart/selectors';

const CartIcon: React.FC = () => {
  const quantity = useSelector(selectCartQuantity);

  return (
    <Link to="/cart">
      <FiShoppingCart className="mr-1" id="cart-icon" size={20} />
      <span>{quantity > 999 ? '999+' : quantity}</span>
      <UncontrolledTooltip target="cart-icon" placement="bottom">
        <p className="m-0">カート</p>
      </UncontrolledTooltip>
    </Link>
  );
};

export default CartIcon;
