import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getAccount } from '../../../graphql/queries';
import {
  AuthUser,
  clearAuthUser,
  receiveAuthUser,
} from '../../../redux/auth/actions/authUser';
import './styles.scss';
import { clearAccount, setAccount } from '~redux/account/actions/account';
import { clear } from '~redux/auth/reducers/signin';
import { nextPathSelector } from '~redux/auth/selectors/singin';
import { clearBuyerInfo } from '~redux/buyer/actions/buyerInfo';
import { useGraphql } from '~view/hooks/graphql';

type AuthContainerProps = {
  modal?: boolean;
};

const AuthContainer: React.FC<AuthContainerProps> = ({ modal }) => {
  const dispatch = useDispatch();
  const nextPath = useSelector(nextPathSelector);
  const history = useHistory();

  const [, fetch] = useGraphql(getAccount, (data) => data.getAccount);

  useEffect(() => {
    return onAuthUIStateChange(async (newAuthState, authData) => {
      if (newAuthState === 'signedin') {
        await Auth.currentUserCredentials();
        const account = await fetch({ id: (authData as AuthUser).username });
        if (account) {
          dispatch(
            setAccount({
              ...account,
              buyerInfo: account.buyerInfo?.items?.[0],
              supplierInfo: account.supplierInfo?.items?.[0],
            })
          );
        }
        dispatch(receiveAuthUser(authData));
        if (nextPath) {
          history.push(nextPath);
        }
        dispatch(clear());
      } else {
        dispatch(clearAuthUser(authData));
        dispatch(clearAccount());
        dispatch(clearBuyerInfo());
      }
    });
  }, [history.location.pathname, nextPath]);

  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator
        className={`auth-container${modal ? ' auth-modal' : ''}`}
      >
        <AmplifySignIn
          slot="sign-in"
          formFields={[
            {
              type: 'username',
              label: 'メールアドレス',
              placeholder: '',
              required: true,
            },
            {
              type: 'password',
              label: 'パスワード',
              placeholder: '',
              required: true,
            },
          ]}
          hideSignUp
          usernameAlias="email"
        />
        <AmplifyForgotPassword
          slot="forgot-password"
          formFields={[
            {
              type: 'username',
              label: 'メールアドレス',
              placeholder: '',
              required: true,
            },
          ]}
        ></AmplifyForgotPassword>
      </AmplifyAuthenticator>
      {!modal && (
        <div className="login-footer">
          <Link to="/registration/buyer">アカウント登録はこちら</Link>
        </div>
      )}
    </AmplifyAuthContainer>
  );
};

export default AuthContainer;
