import { createAction } from '@reduxjs/toolkit';
import { Brand } from '~redux/brand/types';

export type Thread = {
  id?: string;
  buyer_owner: string;
  buyer_name?: string;
  brand_owner: string;
  brand_id: string;
  brand?: Brand;
  owners: string[];
  createdAt?: string;
  active?: boolean;
  newMessages?: number;
  lastMessageCreatedAt?: string;
};

export type Message = {
  id: string;
  content: string;
  product_name?: string;
  is_open: boolean;
  files?: MessageFile[];
  owner: string;
  recipient?: string;
  createdAt: string;
};

export type MessageFile = {
  object_key: string;
  file_name: string;
  file_type: string;
  signedUrl?: string;
};

export const clearThreads = createAction('MESSAGES/CLEAR_THREADS');
export const preGetThreads = createAction('MESSAGES/PRE_GET_THREADS');
export const postGetThreads = createAction<Thread[]>(
  'MESSAGES/POST_GET_THREADS'
);
export const pushThread = createAction<Thread>('MESSAGES/PUSH_THREAD');
export const setActiveThread = createAction<string>(
  'MESSAGES/SET_ACTIVE_THREAD'
);

export const preGetMessages = createAction('MESSAGES/PRE_GET_MESSAGES');
export const postGetMessages = createAction<Message[]>(
  'MESSAGES/POST_GET_MESSAGES'
);
export const preSendMessage = createAction('MESSAGES/PRE_SEND_MESSAGE');
export const postSendMessage = createAction<Message | undefined>(
  'MESSAGES/POST_SEND_MESSAGE'
);
export const pushMessage = createAction<Message>('MESSAGES/PUSH_MESSAGE');
export const postUpdateMessagesOpend = createAction<string>(
  'MESSAGES/POST_UPDATE_MESSAGES_OPENED'
);
export const clearMessages = createAction('MESSAGES/CLEAR_MESSAAGES');
export const pushNewMessages = createAction<string[]>(
  'MESSAGES/PUSH_NEW_MESSAGES'
);
export const checkNewMessages = createAction('MESSAGES/CHECK_NEW_MESSAGES');
export const clearNewMessages = createAction('MESSAGES/CLEAR_NEW_MESSAGES');
export const setNextMessagesToken = createAction<string | null>(
  'MESSAGES/SET_NEXT_TOKEN'
);
