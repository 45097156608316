import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { BreadcrumbList } from 'schema-dts';
import { Breadcrumb, useHeadContent } from '~view/contexts/headContext';

const Head: React.FC = () => {
  const location = useLocation();
  const { headContent } = useHeadContent();

  const title = `${headContent.title ?? ''} | ${
    process.env.REACT_APP_TITLE_SUFFIX ?? ''
  }`;
  const description = `${headContent.description ?? ''} | ${
    process.env.REACT_APP_TITLE_DESCRIPTION_SUFFIX ?? ''
  }`;
  const canonical = headContent.canonical
    ? headContent.canonical
    : `${process.env.REACT_APP_DOMAIN}${location.pathname}`;
  const breadcrumbs: Breadcrumb[] = headContent.breadcrumbs
    ? headContent.breadcrumbs
    : [];

  return (
    <Helmet script={getBreadCrumnbsJsonLD(breadcrumbs)}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        property="og:title"
        content={
          headContent.title ||
          'homula（ホムラ）| 服飾雑貨・アパレルの仕入れに最適・ブランド向けオンラインマーケットプレイス'
        }
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={
          headContent.image ||
          'https://www.homula.jp/futura_homula_logo_white_square.png'
        }
      />
      <meta
        property="og:url"
        content={headContent.url || process.env.REACT_APP_DOMAIN}
      />
      <meta property="og:site_name" content="homula（ホムラ）" />
      <meta
        property="og:description"
        content={
          headContent.description ||
          '最短即日発送で仕入れが可能な小売店・ブランド向けオンラインマーケットプレイス「homula（ホムラ）」。400超の服飾雑貨・アパレル、優良ブランドを小ロットから仕入れが可能。'
        }
      />
      <link rel="canonical" href={encodeURI(canonical)} />
    </Helmet>
  );
};

export const getBreadCrumnbsJsonLD = (breadcrumbs: Breadcrumb[]) => {
  const jsonLdFunction: any[] = [];
  if (breadcrumbs && breadcrumbs.length > 0) {
    jsonLdFunction.push(
      helmetJsonLdProp<BreadcrumbList>({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs
          .filter((item) => item.text !== '')
          .map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: item.text,
            item: item.url ? item.url : undefined,
          })),
      })
    );
  }

  return jsonLdFunction;
};

export default Head;
