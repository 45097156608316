import React from 'react';
import PublicPage from '~view/widgets/PublicPage';

const withPage = (
  Component: React.ComponentType<any>,
  title?: string,
  loginLink?: string,
  hideMenu?: boolean
) => {
  return (props: any) => {
    return (
      <PublicPage loginLink={loginLink} title={title} hideMenu={hideMenu}>
        <Component {...props} />
      </PublicPage>
    );
  };
};

export default withPage;
