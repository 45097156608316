import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usedPoints } from './byId';

const initialState = 0;

const inputPointSlice = createSlice({
  name: 'inputPoint',
  initialState,
  reducers: {
    setInputPoint(_, { payload }: PayloadAction<number>) {
      return payload;
    },
    clearedInputPoint() {
      return initialState;
    },
  },
  extraReducers: {
    [usedPoints.type]: () => initialState,
  },
});

export const { setInputPoint, clearedInputPoint } = inputPointSlice.actions;
export default inputPointSlice.reducer;
