import { createReducer } from '@reduxjs/toolkit';
import { clearNewOrders, pushNewOrder, checkNewOrders } from '../actions';

const initialState: {
  checkedOrders: string[];
  unCheckedOrders: string[];
} = { checkedOrders: [], unCheckedOrders: [] };

const newOrdersReducer = createReducer(initialState, {
  [pushNewOrder.type]: (state, { payload }) => {
    if (typeof payload === 'string') {
      return { ...state, unCheckedOrders: [...state.unCheckedOrders, payload] };
    }
    return {
      ...state,
      unCheckedOrders: [...state.unCheckedOrders, ...payload],
    };
  },
  [clearNewOrders.type]: () => initialState,
  [checkNewOrders.type]: (state) => ({
    unCheckedOrders: [],
    checkedOrders: [...state.checkedOrders, ...state.unCheckedOrders],
  }),
});

export default newOrdersReducer;
