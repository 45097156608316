import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

export const selectCoupon = (state: RootState) => state.coupon;

export const selectCoupons = createSelector(selectCoupon, (coupon) =>
  Object.values(coupon.byId)
);

export const makeSelectEnableCoupons = (
  brandIds?: string[],
  date: Date = new Date()
) =>
  createSelector(selectCoupons, (coupons) =>
    coupons
      .filter(
        (item) =>
          (!brandIds ||
            !item.coupon?.brand_ids?.length ||
            item.coupon?.brand_ids?.some((brandId) =>
              brandIds.includes(brandId!)
            )) &&
          new Date(item.coupon?.start_date ?? date).getTime() <=
            date.getTime() &&
          new Date(item.coupon?.end_date ?? date).getTime() >= date.getTime() &&
          (item.redemptions_left ?? 0) > 0
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdAt ?? new Date()).getTime() -
          new Date(b.createdAt ?? new Date()).getTime()
      )
  );

export const makeSelectEnableCoupon = (
  brandIds?: string[],
  couponId?: string,
  date?: Date
) =>
  createSelector(makeSelectEnableCoupons(brandIds, date), (coupons) =>
    couponId
      ? coupons?.find((coupon) => coupon.coupon_id === couponId)?.coupon
      : coupons?.[0]?.coupon
  );

export const makeSelectBuyerCouponById = (couponId: string) =>
  createSelector(selectCoupons, (coupons) =>
    coupons.find((coupon) => coupon.coupon_id === couponId)
  );
