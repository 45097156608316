import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'reactstrap';

type ConfirmModalProps = {
  isOpen: boolean;
  toggle: () => void;
  confirmMessage: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmContent?: React.FC<{ setResult?: (result: any) => void }>;
  onConfirm?: (result?: any) => void;
  onCancel?: () => void;
  className?: string;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  toggle,
  confirmMessage,
  confirmLabel,
  cancelLabel,
  confirmContent,
  onConfirm,
  onCancel,
  className,
}) => {
  const [result, setResult] = useState<any>();

  const cancel = useCallback(() => {
    onCancel?.();
    toggle();
  }, [onCancel, toggle]);

  const confirm = useCallback(() => {
    onConfirm?.(result);
    toggle();
  }, [onConfirm, result]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={className ? `rounded-0 ${className}` : 'rounded-0'}
    >
      <h5 className="text-center">{confirmMessage}</h5>
      {confirmContent && (
        <div className="mb-4">{confirmContent({ setResult })}</div>
      )}
      <div>
        <Button className="mb-3" onClick={confirm} block>
          {confirmLabel ?? 'OK'}
        </Button>
        <Button onClick={cancel} outline block>
          {cancelLabel ?? 'キャンセル'}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
