import { createAction } from '@reduxjs/toolkit';
import {
  BuyerType,
  KycStatus,
  PaymentTermType,
  ShippingAddress,
  PaymentType,
} from '../types';
import { BrandType } from './../../../utils/brand';
import { Account } from '~redux/account/actions/account';
import { Brand } from '~redux/brand/types';
import { ContactListConnection } from '~types/api';

export type BuyerInfo = {
  id: string;
  account_id: string;
  account?: Account;
  buyer_id: string;
  shop_name: string;
  owner_name: string;
  buyer_type: BuyerType;
  buyer_rating: number;
  buyer_credit: number;
  buyer_return_credit: number | null;
  referral_brand_id: string;
  primary_shipping_address_id: string | null;
  shipping_address: ShippingAddress | null;
  stripe_id: string | null;
  primary_payment_method_id: string | null;
  primary_payment_term: PaymentTermType | null;
  buyer_kyc_status: KycStatus;
  buyer_product_category: string;
  buyer_brand: string;
  payment_type: PaymentType | null;
  billing_close_day: number | null;
  mf_customer_id: string | null;
  mf_destination_id: string | null;
  mf_examination_status: string | null;
  contacts: {
    items: Contact[];
  } | null;
  referralBrand?: Brand;
  buyer_return_limit_per_brand: number | null;
  store_category?: string;
  item_category?: string[];
  shop_images?: {
    id: string;
    image_key: string;
    buyer_id: string;
  }[];
  metadata?: any;
};

export type Contact = {
  id: string;
  brand_owner: string;
  discount_rate: number | null;
  direct_payment: boolean | null;
  contact_status: 'direct' | 'manual';
  supplier_id: string;
  supplierInfo?: {
    id: string;
    account_id: string;
    supplier_contract_type: number;
    bank_name: string | null;
    bank_branch_name: string | null;
    bank_account_number: string | null;
    bank_account_name: string | null;
    bank_account_type: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string[];
  };
  contactListConnections?: {
    items: ContactListConnection[];
  };
};

const ACTION_BASE_TYPE = 'BUYER';

export const getBuyerInfoStart = createAction(
  `${ACTION_BASE_TYPE}/GET_BUYER_INFO_START`
);
export const receiveBuyerInfo = createAction<BuyerInfo>(
  `${ACTION_BASE_TYPE}/RECEIVE_BUYER_INFO`
);
export const removeBuyerAddress = createAction<BuyerInfo['id']>(
  `${ACTION_BASE_TYPE}/REMOVE_BUYER_ADDRESS`
);
export const updateBuyerInfoStart = createAction(
  `${ACTION_BASE_TYPE}/UPDATE_BUYER_INFO_START`
);
export const clearBuyerInfo = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_BUYER_INFO`
);
export const updatedBuyerInfo = createAction<Partial<BuyerInfo>>(
  `${ACTION_BASE_TYPE}/UPDATED_BUYER_INFO`
);

export type BuyerAddress = {
  id: string;
  name: string;
  buyer_id: string;
  zip_code: string;
  prefecture: string;
  city: string;
  building: string | null;
  phone_number: string;
};

export const getBuyerAddressesStart = createAction(
  `${ACTION_BASE_TYPE}/GET_BUYER_ADDRESSES_START`
);
export const receiveBuyerAddresses = createAction<BuyerAddress[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_BUYER_ADDRESSES`
);
export const addBuyerAddress = createAction<BuyerAddress>(
  `${ACTION_BASE_TYPE}/ADD_BUYER_ADDRESS`
);

export type PaymentMethod = {
  id: string;
  card: Card;
  object: string;
  created: number;
  customer: string;
  livemode: boolean;
  metadata: any;
  type: string;
};

type Card = {
  brand: BrandType;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: any | null;
  last4: string;
  wallet: any | null;
};

export const getPaymentMethodsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_PAYMENT_METHODS_START`
);
export const receivePaymentMethods = createAction<PaymentMethod[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_PAYMENT_METHODS`
);
export const addPaymentMethod = createAction<PaymentMethod>(
  `${ACTION_BASE_TYPE}/ADD_PAYMENT_METHOD`
);
export const removePaymentMethod = createAction<PaymentMethod['id']>(
  `${ACTION_BASE_TYPE}/REMOVE_PAYMENT_METHOD`
);
