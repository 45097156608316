import { Dispatch } from 'redux';
import * as Cms from '~domain/cms/Cms';
import { setFeaturedContent } from '../slice/featuredContent';
import { setFeaturedContents } from '../slice/featuredContents';

export const getFeaturedContent = (
  contentId: string,
  draftKey?: string
) => async (dispatch: Dispatch) => {
  const res = await Cms.getFeaturedContent(contentId, draftKey);
  dispatch(setFeaturedContent(res));
};

export const listFeaturedContents = () => async (dispatch: Dispatch) => {
  const res = await Cms.listFeaturedContents();
  dispatch(setFeaturedContents(res));
};
