import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import { Brand } from '~types/api';

type InviteStep = 'invite' | 'message';

const customersSlice = createSlice({
  name: 'ui/customers',
  initialState: {
    inviteStep: 'invite' as InviteStep,
    isInviteModalOpen: false,
    inviteContactIds: [] as string[],
    inviteBrand: undefined as Brand | undefined,
  },
  reducers: {
    openInviteModal(state, action: PayloadAction<string[] | undefined>) {
      state.inviteContactIds = action.payload ?? [];
      state.isInviteModalOpen = true;
    },
    closeInviteModal(state) {
      state.inviteContactIds = [];
      state.inviteStep = 'invite';
      state.isInviteModalOpen = false;
    },
    setInviteStep(state, action: PayloadAction<InviteStep>) {
      state.inviteStep = action.payload;
    },
    setInviteBrand(state, action: PayloadAction<Brand>) {
      state.inviteBrand = action.payload;
    },
  },
});

export const {
  openInviteModal,
  closeInviteModal,
  setInviteStep,
  setInviteBrand,
} = customersSlice.actions;

export default customersSlice.reducer;

export const selectCustomersState = (state: RootState) => state.ui.customers;
export const selectIsInviteModalOpen = createSelector(
  selectCustomersState,
  (state) => state.isInviteModalOpen
);
export const selectInviteContactIds = createSelector(
  selectCustomersState,
  (state) => state.inviteContactIds
);
export const selectInviteStep = createSelector(
  selectCustomersState,
  (state) => state.inviteStep
);
export const selectInviteBrand = createSelector(
  selectCustomersState,
  (state) => state.inviteBrand
);
