import { API, graphqlOperation, Storage } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import * as customQueries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { PaymentTermType, PaymentType } from '../types';
import {
  addBuyerAddress,
  BuyerAddress,
  BuyerInfo,
  removeBuyerAddress,
  receivePaymentMethods,
  addPaymentMethod,
  removePaymentMethod,
  getBuyerInfoStart,
  getPaymentMethodsStart,
  receiveBuyerAddresses,
  receiveBuyerInfo,
  getBuyerAddressesStart,
  PaymentMethod,
  updateBuyerInfoStart,
  updatedBuyerInfo,
} from './../actions/buyerInfo';

import { request } from '~redux/request/thunk';

export const getBuyerInfo = (ownerId: string) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(
      request({
        startAction: getBuyerInfoStart,
        do: async () => {
          const buyerInfoData = await API.graphql<any>(
            graphqlOperation(customQueries.listBuyerInfosByBuyerWithContact, {
              buyer_id: ownerId,
            })
          );
          const {
            data: {
              listBuyerInfosByBuyer: { items },
            },
          } = buyerInfoData;
          const buyerInfo = items?.find(
            ({ account_id }: any) => account_id === ownerId
          );
          return {
            ...buyerInfo,
            payment_type: buyerInfo?.payment_type ?? PaymentType.card,
          };
        },
        completeAction: receiveBuyerInfo,
      })
    );
  };
};

export const updateBuyerInfo = ({
  id,
  stripe_id,
  primary_payment_method_id,
  primary_payment_term,
  primary_shipping_address_id,
}: Omit<BuyerInfo, 'shipping_address'>) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(
      request({
        startAction: updateBuyerInfoStart,
        do: async () => {
          await API.graphql<any>(
            graphqlOperation(mutations.updateBuyerInfo, {
              input: {
                id,
                stripe_id,
                primary_payment_method_id,
                primary_payment_term,
                primary_shipping_address_id,
              },
            })
          );
          return {
            id,
            stripe_id,
            primary_payment_method_id,
            primary_payment_term,
            primary_shipping_address_id,
          };
        },
        completeAction: updatedBuyerInfo,
      })
    );
  };
};

export const updateShopImages = (
  buyerId: string,
  shopImages: {
    imageKey: string;
    file: File | null;
  }[],
  initialShopImages: {
    id: string;
    imageKey: string;
    file: File | null;
  }[],
  onSubmitSuccessed?: () => void
) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(
      request({
        startAction: updateBuyerInfoStart,
        do: async () => {
          await shopImages.reduce(async (prevPromise, shopImage) => {
            await prevPromise;
            if (shopImage?.file) {
              await Storage.put(
                `buyer/${buyerId}/${shopImage.imageKey}`,
                shopImage.file
              );
              await API.graphql<any>(
                graphqlOperation(mutations.createShopImage, {
                  input: {
                    buyer_id: buyerId,
                    image_key: `buyer/${buyerId}/${shopImage.imageKey}`,
                  },
                })
              );
            }
          }, Promise.resolve());

          await Promise.all(
            initialShopImages.map(async (initialShopImage) => {
              const shopImage = shopImages.find(
                (shopImage) => initialShopImage.imageKey === shopImage.imageKey
              );
              if (!shopImage) {
                await API.graphql<any>(
                  graphqlOperation(mutations.deleteShopImage, {
                    input: {
                      id: initialShopImage.id,
                    },
                  })
                );
                await Storage.remove(
                  `buyer/${buyerId}/${initialShopImage.imageKey}`
                );
              }
            })
          );
          onSubmitSuccessed?.();
        },
        completeAction: updatedBuyerInfo,
      })
    );
  };
};

export const createCustomer = (
  buyerInfo: Omit<BuyerInfo, 'shipping_address'>,
  params: {
    email: string;
    name: string;
    metadata: { account_id: string };
  }
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const customerData = await API.graphql<any>(
    graphqlOperation(mutations.createCustomer, {
      ...params,
    })
  );
  const {
    data: { createCustomer: item },
  } = customerData;

  dispatch(updateBuyerInfo({ ...buyerInfo, stripe_id: item.id }));
};

export const deleteBuyerAddress = (
  buyerInfoData: Omit<BuyerInfo, 'shipping_address'>,
  address: Pick<BuyerInfo, 'id'>
) => {
  const buyerInfo = buyerInfoData;
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const buyerInfoData = await API.graphql<any>(
      graphqlOperation(mutations.deleteShippingAddress, {
        input: address,
      })
    );
    const {
      data: { deleteShippingAddress: item },
    } = buyerInfoData;

    dispatch(removeBuyerAddress(item.id));

    if (buyerInfo.primary_shipping_address_id === address.id) {
      dispatch(
        updateBuyerInfo({ ...buyerInfo, primary_shipping_address_id: null })
      );
    }
  };
};

export const getBuyerAddressList = (ownerId: string) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const filter = { buyer_id: { eq: ownerId } };
    const sort = { field: 'updatedAt', direction: 'desc' };

    dispatch(
      request({
        startAction: getBuyerAddressesStart,
        do: async () => {
          const shippingAddressesData = await API.graphql<any>(
            graphqlOperation(queries.searchShippingAddresss, { filter, sort })
          );
          const {
            data: {
              searchShippingAddresss: { items },
            },
          } = shippingAddressesData;
          return items;
        },
        completeAction: receiveBuyerAddresses,
      })
    );
  };
};

export const createShippingAddress = (address: Omit<BuyerAddress, 'id'>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const buyerInfo = await API.graphql<any>(
      graphqlOperation(mutations.createShippingAddress, { input: address })
    );
    const {
      data: { createShippingAddress: item },
    } = buyerInfo;

    dispatch(addBuyerAddress(item));
  };
};

export const getPaymentMethodList = (stripeId: string) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(
      request({
        startAction: getPaymentMethodsStart,
        do: async () => {
          const paymentMethodData = await API.graphql<any>(
            graphqlOperation(queries.getPaymentMethods, { stripeId })
          );

          const {
            data: { getPaymentMethods: items },
          } = paymentMethodData;
          return items;
        },
        completeAction: receivePaymentMethods,
      })
    );
  };
};

export const deleteBuyerPaymentMethod = (
  buyerInfo: Omit<BuyerInfo, 'shipping_address'>,
  paymentMethodId: PaymentMethod['id']
) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    let paymentMethodData;
    try {
      paymentMethodData = await API.graphql<any>(
        graphqlOperation(mutations.deletePaymentMethod, {
          paymentMethodId,
        })
      );
    } catch (e) {
      if (e?.errors?.length) {
        throw new Error(e.errors[0].message);
      }
      throw e;
    }

    const {
      data: { deletePaymentMethod: item },
    } = paymentMethodData;

    dispatch(removePaymentMethod(item.id));

    if (buyerInfo.primary_payment_method_id === item.id) {
      dispatch(
        updateBuyerInfo({ ...buyerInfo, primary_payment_method_id: null })
      );
    }
  };
};

export const attachPaymentMethod = (
  stripeId: string,
  paymentMethodId: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const paymentMethodData = await API.graphql<any>(
        graphqlOperation(queries.attachPaymentMethod, {
          stripeId,
          paymentMethodId,
        })
      );

      const {
        data: { attachPaymentMethod: item },
      } = paymentMethodData;

      dispatch(addPaymentMethod(item));
    } catch (error) {
      toast.error(error.errors?.[0]?.message);
      console.log(error);
    }
  };
};

export const updatePaymentTerm = (
  stripeId: string,
  paymentTerm: PaymentTermType,
  paymentMethodId: string,
  buyerInfo: Omit<BuyerInfo, 'shipping_address'>
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    await attachPaymentMethod(stripeId, paymentMethodId)(dispatch);
    await updateBuyerInfo({
      ...buyerInfo,
      primary_payment_term: paymentTerm,
      primary_payment_method_id: paymentMethodId,
    })(dispatch);
  };
};
