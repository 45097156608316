import './styles.scss';
import { InputBase } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FiSearch } from '@react-icons/all-files/fi/FiSearch';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import QuerySuggestions from './QuerySuggestions';
import { changedQuery } from '~redux/search/product';
import { BrandReadModel } from '~types/api';
import { getPublicImageUrl } from '~utils/image';

export const QueryContext = createContext<{
  query?: string;
  setQueries?: (queries: string[]) => void;
  setBrands?: (brands: BrandReadModel[]) => void;
}>({});

type SearchFormProps = {
  dialog?: boolean;
  onSubmit?: (query?: string) => void;
};

const SearchForm: React.FC<SearchFormProps> = ({ dialog, onSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search).get('s');
  const [query, setQuery] = useState<string>();
  useEffect(() => setQuery(search ?? undefined), [search]);
  const handleSubmit = useCallback(
    (query) => {
      dispatch(changedQuery({ listId: `products > search`, query }));
      onSubmit?.(query);
    },
    [onSubmit]
  );
  const handleInput = useCallback((value) => {
    setQuery(value);
  }, []);
  const handleChange = useCallback(
    (value) => {
      if (value?.brand) {
        onSubmit?.(value.brand.brand_name);
        history.push(`/brand/${value.brand.id}`);
        return;
      }
      handleSubmit(value?.query ?? value ?? '');
      history.push(
        `/list/search?s=${decodeURIComponent(value?.query ?? value ?? '')}`
      );
    },
    [handleSubmit]
  );
  const [queries, setQueries] = useState<string[]>([]);
  const [brands, setBrands] = useState<BrandReadModel[]>([]);
  const options = useMemo<
    { type: string; query?: string; brand?: BrandReadModel }[]
  >(
    () =>
      query
        ? [
            ...queries.map((query) => ({
              type: 'query',
              query,
            })),
            ...brands.map((brand) => ({
              type: 'brand',
              brand,
            })),
          ]
        : [],
    [query, queries, brands]
  );

  return (
    <div className="d-flex align-items-center">
      <div
        aria-describedby="algolia-search-form"
        className="algolia-search-form rounded-pill"
      >
        <Autocomplete
          freeSolo
          className="w-100"
          disableClearable
          options={options}
          groupBy={(option) => option.type}
          getOptionLabel={(option) =>
            option.query ?? option.brand?.brand_name ?? ''
          }
          classes={{
            option: dialog ? 'px-0' : 'px-4',
          }}
          onInputChange={(_, newValue) => handleInput(newValue)}
          onChange={(_, newValue) => handleChange(newValue)}
          ListboxProps={{ style: { maxHeight: '600px' } }}
          PaperComponent={
            dialog
              ? ({ children, ...props }) => <div {...props}>{children}</div>
              : undefined
          }
          renderGroup={(params) =>
            params.group === 'brand' ? (
              <>
                <small className={`d-block${dialog ? ' px-0' : ' px-4'}`}>
                  ブランド
                </small>
                {params.children}
              </>
            ) : (
              params.children
            )
          }
          renderInput={(props) => (
            <div className="d-flex w-100 py-2 px-3" ref={props.InputProps.ref}>
              <InputBase
                {...props.inputProps}
                type="search"
                name="word"
                placeholder="商品名、ブランド名、カテゴリを検索"
                autoComplete="off"
                value={query}
                autoFocus
                classes={{
                  input: 'h-auto p-0 border-0 bg-transparent',
                }}
              />
              <Button
                className="search-button text-body border-0"
                onClick={() => handleChange(query)}
              >
                <FiSearch size={20} />
              </Button>
            </div>
          )}
          renderOption={(option) =>
            option.type === 'query' ? (
              <Link
                to={`/list/search?s=${encodeURIComponent(option.query!)}`}
                className="w-100"
              >
                <div className="d-flex align-items-center">
                  <div
                    className="mr-3 rounded-circle d-flex justify-content-center align-items-center border"
                    style={{ width: 40, height: 40 }}
                  >
                    <FiSearch size={20} />
                  </div>
                  {option.query}
                </div>
              </Link>
            ) : (
              <Link
                to={`/brand/${option.brand?.id}`}
                className="w-100"
                translate="no"
              >
                <img
                  className="mr-3 rounded-circle bg-light border"
                  style={{ width: 40, height: 40, objectFit: 'cover' }}
                  src={getPublicImageUrl(option.brand?.brand_logo_imageKey, {
                    w: 40,
                    h: 40,
                  })}
                />
                {option.brand?.brand_name}
              </Link>
            )
          }
        />
        <QueryContext.Provider value={{ query, setQueries, setBrands }}>
          <QuerySuggestions />
        </QueryContext.Provider>
      </div>
      {dialog && (
        <div className="flex-shrink-0">
          <button className="bg-white border-0" onClick={() => onSubmit!()}>
            閉じる
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchForm;
