import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { searchIndividuals } from '~graphql/custom_queries';
import { createIndividual } from '~graphql/mutations';
import { listSupplierInfosByAccountId } from '~graphql/queries';
import { accountSelector } from '~redux/account/selectors/account';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { createContact } from '~redux/contact';
import { RootState } from '~redux/reducer';
import {
  CreateContactMutation,
  CreateIndividualMutation,
  CreateIndividualMutationVariables,
  Individual,
  ListSupplierInfosByAccountIdQuery,
  ListSupplierInfosByAccountIdQueryVariables,
} from '~types/api';
import { executeQuery } from '~utils/graphql';

export type SearchIndividualsQuery = {
  searchIndividuals?: {
    __typename: 'SearchableIndividualConnection';
    items?: Array<{
      __typename: 'Individual';
      brand_id: string;
      buyer_id: string;
      supplier_group_id?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
      buyerInfo?: {
        __typename: 'BuyerInfo';
        id: string;
        account_id?: string | null;
        buyer_id?: string | null;
        owner_name?: string | null;
        shop_name?: string | null;
        store_category?: string | null;
        item_category?: Array<string | null> | null;
        buyer_type?: string | null;
        buyer_product_category?: string | null;
        buyer_brand?: string | null;
        buyer_rating?: number | null;
        buyer_kyc_status?: number | null;
        buyer_credit?: number | null;
        buyer_return_credit?: number | null;
        buyer_return_limit_per_brand?: number | null;
        primary_shipping_address_id?: string | null;
        stripe_id?: string | null;
        primary_payment_method_id?: string | null;
        primary_payment_term?: number | null;
        referral_brand_id?: string | null;
        payment_type?: number | null;
        billing_close_day?: number | null;
        mf_customer_id?: string | null;
        mf_destination_id?: string | null;
        mf_examination_status?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: Array<string | null> | null;
        metadata?: string | null;
        account?: {
          __typename: 'Account';
          id: string;
          email?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          web_site_URL?: string | null;
          instagram_account?: string | null;
          zip_code?: string | null;
          location?: string | null;
          tel?: string | null;
          company_name?: string | null;
          term_approval_status?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: Array<string | null> | null;
        } | null;
        shipping_address?: {
          __typename: 'ShippingAddress';
          id: string;
          name: string;
          buyer_id: string;
          zip_code: string;
          prefecture: string;
          city: string;
          building?: string | null;
          phone_number: string;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        referralBrand?: {
          __typename: 'Brand';
          id: string;
          brand_owner: string;
          brand_name: string;
          brand_yomigana?: string | null;
          brand_type?: string | null;
          brand_content?: string | null;
          brand_imageKey?: string | null;
          brand_icon_imageKey?: string | null;
          brand_logo_imageKey?: string | null;
          brand_publication?: string | null;
          brand_retail_stores_number?: string | null;
          brand_category?: string | null;
          brand_target_age?: string | null;
          brand_target_gender?: string | null;
          brand_website_url?: string | null;
          brand_work_with_big_box?: string | null;
          brand_address?: string | null;
          brand_minimum_buy?: number | null;
          brand_additional_minimum_buy?: number | null;
          brand_shippinng_fee?: number | null;
          brand_shippinng_fee_criteria?: number | null;
          brand_instagram_account?: string | null;
          brand_facebook_account?: string | null;
          brand_rating?: number | null;
          brand_annual_turnover?: number | null;
          brand_public_status?: string | null;
          brand_howtoknowhomula?: string | null;
          brand_audit_status?: number | null;
          brand_stripe_id?: string | null;
          brand_first_order_fee?: number | null;
          brand_additional_order_fee?: number | null;
          brand_first_order_fee_referral?: number | null;
          brand_additional_order_fee_referral?: number | null;
          brand_direct_order_fee?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: Array<string | null> | null;
          designed_in?: string | null;
          established?: string | null;
          tagIds?: Array<string | null> | null;
          tags?: Array<string | null> | null;
          brand_collections?: Array<string | null> | null;
          movie_url?: string | null;
          brand_modal_imageKeys?: Array<string | null> | null;
          brand_online_sale?: boolean | null;
          product_image_reprint?: boolean | null;
          brand_logo_reprint?: boolean | null;
          post_to_sns?: boolean | null;
          allow_direct_shipping?: boolean | null;
          allow_sell_before_buy?: boolean | null;
          other_terms?: string | null;
          featured_in?: Array<string | null> | null;
          maximum_wholesale_rate?: number | null;
          exclude_zip_code?: Array<string | null> | null;
          is_sale_brand?: boolean | null;
          hubspot_company_id?: string | null;
          return_to_brand?: boolean | null;
          terms_of_sample?: string | null;
          auto_message_enabled?: boolean | null;
          auto_message?: string | null;
          sell_only_direct?: boolean | null;
          supplier_group_id?: string | null;
        } | null;
        contacts?: {
          __typename: 'ModelContactConnection';
          nextToken?: string | null;
        } | null;
      } | null;
      supplierGroup?: {
        __typename: 'SupplierGroup';
        id: string;
        title?: string | null;
        description?: string | null;
        image_key?: string | null;
        published: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: Array<string | null> | null;
        brands?: {
          __typename: 'ModelBrandReadModelConnection';
          items:
            | {
                __typename: 'BrandReadModel';
                id: string;
                company_name?: string | null;
                brand_owner: string;
                brand_name: string;
                brand_yomigana?: string | null;
                brand_type?: string | null;
                brand_content?: string | null;
                brand_imageKey?: string | null;
                brand_icon_imageKey?: string | null;
                brand_logo_imageKey?: string | null;
                brand_publication?: string | null;
                brand_retail_stores_number?: string | null;
                brand_category?: string | null;
                brand_target_age?: string | null;
                brand_target_gender?: string | null;
                brand_website_url?: string | null;
                brand_work_with_big_box?: string | null;
                brand_address?: string | null;
                brand_minimum_buy?: number | null;
                brand_additional_minimum_buy?: number | null;
                brand_shippinng_fee?: number | null;
                brand_shippinng_fee_criteria?: number | null;
                brand_instagram_account?: string | null;
                brand_facebook_account?: string | null;
                brand_rating?: number | null;
                brand_annual_turnover?: number | null;
                brand_public_status?: string | null;
                brand_howtoknowhomula?: string | null;
                brand_audit_status?: number | null;
                brand_stripe_id?: string | null;
                brand_first_order_fee?: number | null;
                brand_additional_order_fee?: number | null;
                brand_first_order_fee_referral?: number | null;
                brand_additional_order_fee_referral?: number | null;
                brand_direct_order_fee?: number | null;
                createdAt?: string | null;
                createdAtTimestamp?: number | null;
                updatedAt?: string | null;
                updatedAtTimestamp?: number | null;
                owner?: Array<string | null> | null;
                designed_in?: string | null;
                established?: string | null;
                tagIds?: Array<string> | null;
                tags?: Array<string> | null;
                brand_collections?: Array<string> | null;
                movie_url?: string | null;
                brand_modal_imageKeys?: Array<string> | null;
                brand_online_sale?: boolean | null;
                product_image_reprint?: boolean | null;
                brand_logo_reprint?: boolean | null;
                post_to_sns?: boolean | null;
                allow_direct_shipping?: boolean | null;
                allow_sell_before_buy?: boolean | null;
                other_terms?: string | null;
                stockists?: Array<{
                  __typename: 'Stockist';
                  stockist?: string | null;
                  address?: string | null;
                }> | null;
                featured_in?: Array<string> | null;
                maximum_wholesale_rate?: number | null;
                exclude_zip_code?: Array<string> | null;
                is_sale_brand?: boolean | null;
                hubspot_company_id?: string | null;
                return_to_brand?: boolean | null;
                terms_of_sample?: string | null;
                auto_message_enabled?: boolean | null;
                auto_message?: string | null;
                sell_only_direct?: boolean | null;
                is_published?: boolean | null;
                lastPublishedAt?: string | null;
                lastPublishedAtTimestamp?: number | null;
                category_page_id?: Array<string> | null;
                product_category?: Array<string> | null;
                product_subcategory?: Array<string> | null;
                average_shipping_days?: number | null;
                service_rate?: number | null;
                review_average?: number | null;
                conversion_rate?: number | null;
                sell_through_rate?: number | null;
                reviews?: number | null;
                items?: number | null;
                conversion_rate_tier?: number | null;
                sell_through_rate_tier?: number | null;
                service_rate_rate_tier?: number | null;
                average_shipping_days_tier?: number | null;
                minimum_tier?: number | null;
                items_tier?: number | null;
                contacts_tier?: number | null;
                supplier_group_id?: string | null;
                supplier_group_title?: string | null;
                supplier_group_description?: string | null;
                supplier_group_image_key?: string | null;
                supplier_group_published?: boolean | null;
                collections?: {
                  __typename: 'ModelCollectionConnection';
                  nextToken?: string | null;
                } | null;
                contacts?: {
                  __typename: 'ModelContactConnection';
                  nextToken?: string | null;
                } | null;
                review?: {
                  __typename: 'ModelReviewConnection';
                  nextToken?: string | null;
                } | null;
                products?: {
                  __typename: 'ModelProductReadModelConnection';
                  nextToken?: string | null;
                } | null;
              }[]
            | null;
          nextToken?: string | null;
        } | null;
      } | null;
      brand?: {
        __typename: 'BrandReadModel';
        id: string;
        company_name?: string | null;
        brand_owner: string;
        brand_name: string;
        brand_yomigana?: string | null;
        brand_type?: string | null;
        brand_content?: string | null;
        brand_imageKey?: string | null;
        brand_icon_imageKey?: string | null;
        brand_logo_imageKey?: string | null;
        brand_publication?: string | null;
        brand_retail_stores_number?: string | null;
        brand_category?: string | null;
        brand_target_age?: string | null;
        brand_target_gender?: string | null;
        brand_website_url?: string | null;
        brand_work_with_big_box?: string | null;
        brand_address?: string | null;
        brand_minimum_buy?: number | null;
        brand_additional_minimum_buy?: number | null;
        brand_shippinng_fee?: number | null;
        brand_shippinng_fee_criteria?: number | null;
        brand_instagram_account?: string | null;
        brand_facebook_account?: string | null;
        brand_rating?: number | null;
        brand_annual_turnover?: number | null;
        brand_public_status?: string | null;
        brand_howtoknowhomula?: string | null;
        brand_audit_status?: number | null;
        brand_stripe_id?: string | null;
        brand_first_order_fee?: number | null;
        brand_additional_order_fee?: number | null;
        brand_first_order_fee_referral?: number | null;
        brand_additional_order_fee_referral?: number | null;
        brand_direct_order_fee?: number | null;
        createdAt?: string | null;
        createdAtTimestamp?: number | null;
        updatedAt?: string | null;
        updatedAtTimestamp?: number | null;
        owner?: Array<string | null> | null;
        designed_in?: string | null;
        established?: string | null;
        tagIds?: Array<string> | null;
        tags?: Array<string> | null;
        brand_collections?: Array<string> | null;
        movie_url?: string | null;
        brand_modal_imageKeys?: Array<string> | null;
        brand_online_sale?: boolean | null;
        product_image_reprint?: boolean | null;
        brand_logo_reprint?: boolean | null;
        post_to_sns?: boolean | null;
        allow_direct_shipping?: boolean | null;
        allow_sell_before_buy?: boolean | null;
        other_terms?: string | null;
        stockists?: Array<{
          __typename: 'Stockist';
          stockist?: string | null;
          address?: string | null;
        }> | null;
        featured_in?: Array<string> | null;
        maximum_wholesale_rate?: number | null;
        exclude_zip_code?: Array<string> | null;
        is_sale_brand?: boolean | null;
        hubspot_company_id?: string | null;
        return_to_brand?: boolean | null;
        terms_of_sample?: string | null;
        auto_message_enabled?: boolean | null;
        auto_message?: string | null;
        sell_only_direct?: boolean | null;
        is_published?: boolean | null;
        lastPublishedAt?: string | null;
        lastPublishedAtTimestamp?: number | null;
        category_page_id?: Array<string> | null;
        product_category?: Array<string> | null;
        product_subcategory?: Array<string> | null;
        average_shipping_days?: number | null;
        service_rate?: number | null;
        review_average?: number | null;
        conversion_rate?: number | null;
        sell_through_rate?: number | null;
        reviews?: number | null;
        items?: number | null;
        conversion_rate_tier?: number | null;
        sell_through_rate_tier?: number | null;
        service_rate_rate_tier?: number | null;
        average_shipping_days_tier?: number | null;
        minimum_tier?: number | null;
        items_tier?: number | null;
        contacts_tier?: number | null;
        supplier_group_id?: string | null;
        supplier_group_title?: string | null;
        supplier_group_description?: string | null;
        supplier_group_image_key?: string | null;
        supplier_group_published?: boolean | null;
        collections?: {
          __typename: 'ModelCollectionConnection';
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: 'ModelContactConnection';
          nextToken?: string | null;
        } | null;
        review?: {
          __typename: 'ModelReviewConnection';
          nextToken?: string | null;
        } | null;
        products?: {
          __typename: 'ModelProductReadModelConnection';
          nextToken?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export const fetchIndividualsThunk = createAsyncThunk(
  'individual/fetchIndividuals',
  async (buyerId: string) => {
    const response = (await executeQuery(searchIndividuals, {
      filter: {
        buyer_id: { eq: buyerId },
      },
      sort: {
        field: 'createdAt',
        direction: 'desc',
      },
    })) as GraphQLResult<SearchIndividualsQuery>;

    return response.data?.searchIndividuals;
  }
);

export const addIndividual = createAsyncThunk<
  Individual | undefined,
  { buyerId: string; groupId: string; brandId: string; brandOwner: string }
>(
  'individual/addIndividual',
  async ({ buyerId, groupId, brandId, brandOwner }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const user = authUserSelector(state);
    const account = accountSelector(state);
    const supplier = await executeQuery<
      ListSupplierInfosByAccountIdQuery,
      ListSupplierInfosByAccountIdQueryVariables
    >(listSupplierInfosByAccountId, { account_id: brandOwner });
    const supplier_id =
      supplier.data?.listSupplierInfosByAccountId?.items?.[0]?.id;
    const res = await executeQuery<
      CreateIndividualMutation,
      CreateIndividualMutationVariables
    >(createIndividual, {
      input: {
        buyer_id: buyerId,
        supplier_group_id: groupId,
        brand_id: brandId,
      },
    });
    await dispatch(
      createContact({
        supplier_id: supplier_id!,
        buyer_id: buyerId!,
        buyer_owner: user?.attributes.sub,
        email: user?.attributes.email,
        contact_name: `${account?.last_name} ${account?.first_name}`,
        tel: account?.tel,
        contact_status: 'direct',
        contact_source: 'direct',
        direct_payment: false,
        contacted: false,
        signedup: true,
        ordered: false,
        invited: false,
        owner: brandOwner,
      })
    );
    return res.data?.createIndividual as Individual;
  }
);

export type IndividualState = EntityState<Individual> & {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  nextToken?: string;
};

export const individualAdopter = createEntityAdapter<Individual>({
  selectId: (individual) =>
    `${individual.buyer_id}/${
      individual.supplier_group_id ?? individual.brand_id
    }`,
});

const initialState: IndividualState = individualAdopter.getInitialState({
  loading: 'idle',
});

const individualSlice = createSlice({
  name: 'individual',
  initialState,
  reducers: {
    setOne: individualAdopter.setOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndividualsThunk.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchIndividualsThunk.fulfilled, (state, action) => {
        individualAdopter.upsertMany(
          state,
          (action.payload?.items ?? []) as Individual[]
        );
        state.nextToken = action.payload?.nextToken ?? undefined;
        state.loading = 'succeeded';
      })
      .addCase(fetchIndividualsThunk.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(addIndividual.fulfilled, (state, action) => {
        individualAdopter.upsertOne(state, action.payload as Individual);
      });
  },
});

export const { setOne } = individualSlice.actions;
export const {
  selectAll,
  selectById,
  selectEntities,
} = individualAdopter.getSelectors();
export default individualSlice.reducer;
