import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import { ProductReadModel } from '~types/api';

export type ProductSearchSortType =
  | 'brandRecommended'
  | 'recommended'
  | 'newest'
  | 'highestPrice'
  | 'lowestPrice'
  | 'highestWholesaleRate'
  | 'lowestWholesaleRate';

export type ProductListState = {
  listId: string;
  byId: Record<string, ProductReadModel>;
  isLoading: boolean;
  itemsPerPage: number;
  page: number;
  offset: number;
  nbHits: number;
  refinementAttributes: string[];
  refinementItems: Record<string, string[]>;
  filters: Record<string, string[]>;
  filterValues: Record<string, string[]>;
  sort: ProductSearchSortType;
  category?: string;
  subCategory?: string;
  query?: string;
  brandId?: string;
  groupByBrand?: boolean;
  isInitialized: boolean;
  newProducts?: boolean;
  isPreview?: boolean;
};

const initialListState: Omit<ProductListState, 'listId'> = {
  byId: {},
  isLoading: false,
  itemsPerPage: 40,
  page: 1,
  offset: 0,
  nbHits: 0,
  refinementAttributes: [],
  refinementItems: {},
  filters: {},
  filterValues: {},
  sort: 'recommended',
  isInitialized: true,
};

type TProductSearchState = {
  byList: Record<string, ProductListState>;
};

const initialState: TProductSearchState = {
  byList: {},
};

const productSlice = createSlice({
  name: 'search/product',
  initialState,
  reducers: {
    initializedProductSearchList(
      state,
      { payload }: PayloadAction<Partial<ProductListState>>
    ) {
      state.byList[payload.listId!] = {
        listId: payload.listId!,
        ...initialListState,
        ...payload,
      };
    },
    searchedProducts(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        state.byList[payload].isLoading = true;
      }
    },
    recievedProducts(
      state,
      {
        payload: { listId, products },
      }: PayloadAction<{ listId: string; products: ProductReadModel[] }>
    ) {
      if (state.byList[listId]) {
        products.forEach((product) => {
          state.byList[listId].byId[product.id!] = product;
        });
        state.byList[listId].isLoading = false;
      }
    },
    clearedProducts(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        state.byList[payload].byId = {};
      }
    },
    recievedRefinementItems(
      state,
      {
        payload: { listId, attribute, items },
      }: PayloadAction<{
        listId: string;
        attribute: string;
        items: string[];
      }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].refinementItems[attribute] = items;
      }
    },
    recievedNbHits(
      state,
      {
        payload: { listId, nbHits },
      }: PayloadAction<{ listId: string; nbHits: number }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].nbHits = nbHits;
      }
    },
    changedPage(
      state,
      {
        payload: { listId, page },
      }: PayloadAction<{ listId: string; page: number }>
    ) {
      state.byList[listId].page = page;
    },
    changedOffset(
      state,
      {
        payload: { listId, offset },
      }: PayloadAction<{ listId: string; offset: number }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].offset = offset;
      }
    },
    clearedProductSearchState(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        delete state.byList[payload];
      }
    },
    appliedFilters(
      state,
      {
        payload: { listId, attribute, items },
      }: PayloadAction<{ listId: string; attribute: string; items: string[] }>
    ) {
      if (state.byList[listId]) {
        if (!items.length) {
          delete state.byList[listId].filters[attribute];
        } else {
          state.byList[listId].filters[attribute] = items;
        }
        state.byList[listId].page = 1;
      }
    },
    changedFilterValues(
      state,
      {
        payload: { listId, attribute, items },
      }: PayloadAction<{ listId: string; attribute: string; items: string[] }>
    ) {
      if (state.byList[listId]) {
        if (!items.length) {
          delete state.byList[listId].filterValues[attribute];
        } else {
          state.byList[listId].filterValues[attribute] = items;
        }
      }
    },
    updatedFilters(
      state,
      {
        payload: { listId, items },
      }: PayloadAction<{
        listId: string;
        items: Record<string, Record<string, string | undefined>>;
      }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].filters = Object.entries(items).reduce(
          (prev, [attribute, filter]) => {
            prev[attribute] = Object.keys(filter);
            return prev;
          },
          {} as Record<string, string[]>
        );
        state.byList[listId].filterValues = Object.entries(items).reduce(
          (prev, [attribute, filter]) => {
            const value = Object.values(filter).filter(
              (value) => !!value
            ) as string[];
            if (!value.length) {
              return prev;
            }
            prev[attribute] = value;
            return prev;
          },
          {} as Record<string, string[]>
        );
      }
    },
    clearedFilters(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        state.byList[payload].filters = {};
        state.byList[payload].filterValues = {};
      }
    },
    changedSortType(
      state,
      {
        payload: { listId, sort },
      }: PayloadAction<{ listId: string; sort: ProductSearchSortType }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].sort = sort;
        state.byList[listId].page = 1;
      }
    },
    changedItemsPerPage(
      state,
      {
        payload: { listId, itemsPerPage },
      }: PayloadAction<{ listId: string; itemsPerPage: number }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].itemsPerPage = itemsPerPage;
      }
    },
    changedCategory(
      state,
      {
        payload: { listId, category, subCategory },
      }: PayloadAction<{
        listId: string;
        category?: string;
        subCategory?: string;
      }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].category = category;
        state.byList[listId].subCategory = subCategory;
        state.byList[listId].page = 1;
      }
    },
    changedQuery(
      state,
      {
        payload: { listId, query },
      }: PayloadAction<{ listId: string; query?: string }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].query = query;
        state.byList[listId].refinementItems = {};
        state.byList[listId].page = 1;
      }
    },
  },
});

export const {
  initializedProductSearchList,
  searchedProducts,
  recievedProducts,
  clearedProducts,
  recievedRefinementItems,
  recievedNbHits,
  changedPage,
  changedOffset,
  clearedProductSearchState,
  appliedFilters,
  changedFilterValues,
  updatedFilters,
  clearedFilters,
  changedItemsPerPage,
  changedSortType,
  changedCategory,
  changedQuery,
} = productSlice.actions;

export default productSlice.reducer;

export const selectProductSearchState = (state: RootState) =>
  state.search.product;
export const selectProductListId = (_: RootState, listId: string) => listId;
export const selectProductListState = createSelector(
  selectProductSearchState,
  selectProductListId,
  (state, listId: string) => state.byList[listId]
);
export const selectIsProductListInitialized = createSelector(
  selectProductListState,
  (state) => state?.isInitialized
);
export const selectIsProductSearching = createSelector(
  selectProductListState,
  (state) => state.isLoading
);
export const makeSelectProductById = (productId: string) =>
  createSelector(selectProductListState, (state) => state.byId[productId]);
export const selectProducts = createSelector(
  selectProductListState,
  (state) => {
    const products = Object.values(state.byId);
    const start = (state.page - 1) * state.itemsPerPage - state.offset;
    return products.slice(start, start + state.itemsPerPage);
  }
);
export const selectProductListOffset = createSelector(
  selectProductListState,
  (state) => state.offset
);
export const selectProductListPage = createSelector(
  selectProductListState,
  (state) => state.page
);
export const selectProductListNbPages = createSelector(
  selectProductListState,
  (state) => Math.max(Math.ceil(state.nbHits / state.itemsPerPage), 1)
);
export const selectProductListRefinementAttributes = createSelector(
  selectProductListState,
  (state) => state.refinementAttributes
);
export const selectProductListRefinementItems = createSelector(
  selectProductListState,
  (state) => state.refinementItems
);
export const makeSelectRefinementItemsByAttribute = (attribute: string) =>
  createSelector(selectProductListRefinementItems, (state) => state[attribute]);
export const selectProductListFilters = createSelector(
  selectProductListState,
  (state) => state.filters
);
export const makeSelectProductListFiltersByAttribute = (attribute: string) =>
  createSelector(
    selectProductListFilters,
    (filters) => filters[attribute] ?? []
  );
export const selectProductListFilterValueStrings = createSelector(
  selectProductListState,
  (state) =>
    Object.values(state.filterValues).map((items) => items.join(' OR '))
);
export const makeSelectProductListFilterValuesByAttribute = (
  attribute: string
) =>
  createSelector(
    selectProductListState,
    (state) => state.filterValues[attribute]
  );
export const selectProductListSortType = createSelector(
  selectProductListState,
  (state) => state.sort
);
export const selectProductListCategory = createSelector(
  selectProductListState,
  (state) => state.category
);
export const selectProductListSubCategory = createSelector(
  selectProductListState,
  (state) => state.subCategory
);
export const selectProductListBrandId = createSelector(
  selectProductListState,
  (state) => state.brandId
);
export const selectProductListQuery = createSelector(
  selectProductListState,
  (state) => state.query
);
export const selectProductListNbHits = createSelector(
  selectProductListState,
  (state) => state.nbHits
);
export const selectProductListGroupByBrand = createSelector(
  selectProductListState,
  (state) => state.groupByBrand
);
export const selectNewProducts = createSelector(
  selectProductListState,
  (state) => state.newProducts
);
export const selectIsPreview = createSelector(
  selectProductListState,
  (state) => state.isPreview
);
