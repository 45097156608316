import { createAction } from '@reduxjs/toolkit';
import { Order } from '~domain/order/Order';
import { Account } from '~redux/account/actions/account';
import { Order as OrderJson } from '~redux/buyer/types';
import { OrderDetailReadModel } from '~types/api';

const ACTION_BASE_TYPE = 'ORDER';
export const getOrdersStart = createAction(
  `${ACTION_BASE_TYPE}/GET_ORDERS_START`
);

export const setOrders = createAction<Order[]>('ORDER/SET_ORDERS');

export const pushOrders = createAction<Order[]>('ORDER/PUSH_ORDERS');

export const clearOrders = createAction('ORDER/CLEAR_ORDERS');

export const setOrder = createAction<Order>('ORDER/SET_ORDER');

export const clearOrder = createAction<string>('ORDER/CLEAR_ORDER');

export const createOrderStart = createAction(
  `${ACTION_BASE_TYPE}/CREATE_ORDER_START`
);

export const createOrderComplete = createAction(
  `${ACTION_BASE_TYPE}/CREATE_ORDER_COMPLETE`
);

export const createdOrders = createAction<OrderJson[]>(
  `${ACTION_BASE_TYPE}/CREATED_ORDERS`
);

export const clearedLatestOrders = createAction(
  `${ACTION_BASE_TYPE}/CLEARED_LATEST_ORDERS`
);

export const cancelOrderStart = createAction(
  `${ACTION_BASE_TYPE}/CANCEL_ORDER_START`
);

export const setIsOrderCancelable = createAction<{
  orderId: string;
  isCancelable: boolean;
}>(`${ACTION_BASE_TYPE}/SET_IS_ORDER_CANCELABLE`);

export const setReturnDueDate = createAction<{
  orderId: string;
  returnDueDate: string | undefined;
}>(`${ACTION_BASE_TYPE}/SET_RETURN_DUE_DATE`);

export const pushNewOrder = createAction<string | string[]>(
  `${ACTION_BASE_TYPE}/PUSH_NEW_ORDER`
);

export const clearNewOrders = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_NEW_ORDERS`
);

export const checkNewOrders = createAction(
  `${ACTION_BASE_TYPE}/CHECK_NEW_ORDERS`
);

export const getOrderAccountStart = createAction(
  `${ACTION_BASE_TYPE}/GET_ORDER_ACCOUNT_START`
);

export const receiveOrderAccount = createAction<Account>(
  `${ACTION_BASE_TYPE}/RECEIVE_ORDER_ACCOUNT`
);

export const clearOrderAccount = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_ORDER_ACCOUNT_START`
);

export const setNextOrdersToken = createAction<string>(
  `${ACTION_BASE_TYPE}/SET_ORDERS_NEXT_TOKEN`
);

export const clearNextOrdersToken = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_ORDERS_NEXT_TOKEN`
);

export const receiveOrderProducts = createAction<{
  list: OrderDetailReadModel[];
  nextToken: string | null;
}>(`${ACTION_BASE_TYPE}/RECIEV_ORDER_PRODUCTS`);

export const receiveOrderProductsForCSV = createAction<{
  csvData: OrderDetailReadModel[] | null;
}>(`${ACTION_BASE_TYPE}/RECIEV_ORDER_PRODUCTS_FOR_CSV`);
