import React, { useCallback, useMemo, useState } from 'react';
import ConfirmModal from '~view/widgets/ConfirmModal';

type ConfirmModalType = React.FC<{
  confirmLabel?: string;
  cancelLabel?: string;
  className?: string;
  confirmContent?: React.FC<{ setResult?: (result: any) => void }>;
  onCancel?: () => void;
}>;

const useConfirm = (): [
  (message: string) => Promise<any>,
  ConfirmModalType
] => {
  const [message, setMessage] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<(result?: any) => void>();

  const confirm = useCallback((message) => {
    return new Promise((resolve) => {
      setMessage(message);
      setIsOpen(true);
      setOnConfirm(() => (result?: any) => {
        resolve(result ?? true);
      });
    });
  }, []);

  const Modal = useMemo<ConfirmModalType>(
    () => ({
      confirmLabel,
      cancelLabel,
      className,
      confirmContent,
      onCancel,
    }) =>
      ConfirmModal({
        isOpen,
        toggle: () => setIsOpen(false),
        confirmMessage: message!,
        confirmLabel,
        cancelLabel,
        className,
        confirmContent,
        onConfirm,
        onCancel,
      }),
    [isOpen, message, onConfirm]
  );

  return [confirm, Modal];
};

export default useConfirm;
