import { createReducer } from '@reduxjs/toolkit';
import {
  addCartItem,
  CartItem,
  removeCartItem,
  setCartItems,
  setProductData,
  getBrand,
} from '../actions';

type BrandListState = { value: string; label: string }[];
const initialState: BrandListState = [];

const itemsReducer = createReducer(initialState, {
  [getBrand.type]: (_, { payload }) => payload,
});

export default itemsReducer;
