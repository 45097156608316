import { combineReducers } from 'redux';
import brand from './brand';
import product from './product';

const searchReducer = combineReducers({
  product,
  brand,
});

export default searchReducer;
