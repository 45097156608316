import React from 'react';
import PickupList from './PickupList';
import AdminRoute from '~view/widgets/AdminRoute';

//pages
const BuyerManagement = React.lazy(
  () => import('~view/pages/admin/BuyerManagement')
);
const BuyerDetails = React.lazy(() => import('~view/pages/admin/BuyerDetails'));
const EditBrandCollection = React.lazy(
  () => import('~view/pages/admin/EditBrandCollection')
);
const EditBrandTag = React.lazy(() => import('~view/pages/admin/EditBrandTag'));
const EditPickupList = React.lazy(
  () => import('~view/pages/admin/EditPickupList')
);
const OrderDetails = React.lazy(() => import('~view/pages/admin/OrderDetails'));
const OrderManagement = React.lazy(
  () => import('~view/pages/admin/OrderManagement')
);
const PrevProductBulkRegistration = React.lazy(
  () => import('~view/pages/admin/ProductBulkRegistration')
);
const ReferralManagement = React.lazy(
  () => import('~view/pages/admin/ReferralManagement')
);
const ReturnRequests = React.lazy(
  () => import('~view/pages/admin/ReturnRequests')
);
const ZipCodeRestriction = React.lazy(
  () => import('~view/pages/admin/ZipCodeRestriction')
);
const BrandRegistrationAdmin = React.lazy(
  () => import('~view/pages/common/BrandRegistrationAdmin')
);
const BuyerRegistrationAdmin = React.lazy(
  () => import('~view/pages/common/BuyerRegistrationAdmin')
);
const ChangeAttributes = React.lazy(
  () => import('~view/pages/common/ChangeAttributes')
);
const ChargeBatchTargetList = React.lazy(
  () => import('~view/pages/common/ChargeBatchTargetList')
);
const ProductCopy = React.lazy(() => import('~view/pages/common/ProductCopy'));
const ProductBulkCopy = React.lazy(
  () => import('~view/pages/admin/ProductBulkCopy')
);
const SupplierRegistrationAdmin = React.lazy(
  () => import('~view/pages/common/SupplierRegistrationAdmin')
);
const ReportProducts = React.lazy(
  () => import('~view/pages/admin/ReportProducts')
);
const CreateMagicLink = React.lazy(
  () => import('~view/pages/admin/CreateMagicLink')
);
const Coupons = React.lazy(() => import('~view/pages/admin/Coupons'));
const Coupon = React.lazy(() => import('~view/pages/admin/Coupon'));
const CouponApplication = React.lazy(
  () => import('~view/pages/admin/CouponApplication')
);
const FixIndividualOrders = React.lazy(
  () => import('~view/pages/admin/FixIndividualOrders')
);

const AdminRoutes: React.FC = () => {
  return (
    <>
      <AdminRoute
        exact
        path="/ProductCopy"
        title="商品コピー - Admin"
        component={ProductCopy}
      />
      <AdminRoute
        exact
        path="/ProductBulkCopy"
        title="商品一括コピー - Admin"
        component={ProductBulkCopy}
      />
      <AdminRoute
        exact
        path="/PrevProductBulkRegistration"
        title="商品一括登録(旧) - Admin"
        component={PrevProductBulkRegistration}
      />
      <AdminRoute
        exact
        path="/BrandRegistrationAdmin/045e8015-a826-4f6f-abd5-28470268462f"
        title="ブランド登録 - Admin"
        component={BrandRegistrationAdmin}
      />
      <AdminRoute
        exact
        path="/ChangeAttributes/cbe746a5-5b9a-49af-93d2-7f9be08cef2d"
        title="アカウント編集 - Admin"
        component={ChangeAttributes}
      />
      <AdminRoute
        exact
        path="/BuyerRegistration/ffbab2f4-9e48-4d13-9765-0413fa6a5e66"
        title="バイヤー登録 - Admin"
        component={BuyerRegistrationAdmin}
      />
      <AdminRoute
        exact
        path="/SupplierRegistrationAdmin/0cfcaf79-09d1-45c1-b28a-0d895102dc92"
        title="サプライヤー登録 - Admin"
        component={SupplierRegistrationAdmin}
      />
      <AdminRoute
        exact
        path="/ChargeBatchTargetList/ce2a06ee-7713-8456-856e-235047b22548"
        title="バッチ対象リスト - Admin"
        component={ChargeBatchTargetList}
      />
      <AdminRoute
        exact
        path="/PickupList/edit"
        title="ピックアップリスト登録 - Admin"
        component={EditPickupList}
      />
      <AdminRoute
        exact
        path="/PickupList/edit/:listId"
        title="ピックアップリスト編集 - Admin"
        component={EditPickupList}
      />
      <AdminRoute
        exact
        path="/PickupList"
        title="ピックアップリスト - Admin"
        component={PickupList}
      />
      <AdminRoute
        exact
        path="/EditBrandTag"
        title="ブランドタグ編集 - Admin"
        component={EditBrandTag}
      />
      <AdminRoute
        exact
        path="/EditBrandCollection"
        title="ブランドコレクション編集 - Admin"
        component={EditBrandCollection}
      />
      <AdminRoute
        exact
        path="/OrderManagement"
        title="オーダー管理 - Admin"
        component={OrderManagement}
      />
      <AdminRoute
        exact
        path="/ReturnRequests"
        title="返品申請一覧 - Admin"
        component={ReturnRequests}
      />
      <AdminRoute
        exact
        path="/ReportProducts"
        title="不良品／欠品報告一覧 - Admin"
        component={ReportProducts}
      />
      <AdminRoute
        exact
        path="/ZipCodeRestriction"
        title="販売制限地域設定 - Admin"
        component={ZipCodeRestriction}
      />
      <AdminRoute
        exact
        path="/OrderDetails/:orderId"
        title="オーダー詳細 - Admin"
        component={OrderDetails}
      />
      <AdminRoute
        exact
        path="/ReferralManagement"
        title="リファラル管理 - Admin"
        component={ReferralManagement}
      />
      <AdminRoute
        exact
        path="/BuyerManagement"
        title="ユーザー管理 - Admin"
        component={BuyerManagement}
      />
      <AdminRoute
        exact
        path="/BuyerManagement/:buyerId"
        title="バイヤー詳細 - Admin"
        component={BuyerDetails}
      />
      <AdminRoute
        exact
        path="/create-magic-link"
        title="ログインリンク生成 - Admin"
        component={CreateMagicLink}
      />
      <AdminRoute
        exact
        path="/coupons"
        title="クーポン一覧 - Admin"
        component={Coupons}
      />
      <AdminRoute
        exact
        path="/coupons/create"
        title="クーポン作成 - Admin"
        component={Coupon}
      />
      <AdminRoute
        exact
        path="/coupons/edit/:couponId"
        title="クーポン編集 - Admin"
        component={Coupon}
      />
      <AdminRoute
        exact
        path="/coupons/application/:couponId"
        title="クーポン適用 - Admin"
        component={CouponApplication}
      />
      <AdminRoute
        exact
        path="/fix-individual-orders"
        title="Admin"
        component={FixIndividualOrders}
      />
    </>
  );
};

export default AdminRoutes;
