import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Brand } from '~redux/brand/types';

type SignupStateType = {
  isSignup: boolean;
  nextPath?: string;
  referralId?: string;
  brand?: Brand;
  contactId?: string;
};

const signupSlice = createSlice({
  initialState: { isSignup: false } as SignupStateType,
  name: 'signup',
  reducers: {
    signup: (
      _,
      { payload }: PayloadAction<Omit<SignupStateType, 'isSignup'> | undefined>
    ) => {
      return {
        isSignup: true,
        nextPath: payload?.nextPath,
        referralId: payload?.referralId,
        brand: payload?.brand,
        contactId: payload?.contactId,
      };
    },
    clear: (state) => ({ ...state, isSignup: false }),
  },
});

export const { signup, clear } = signupSlice.actions;

export default signupSlice.reducer;
