// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-pages-individual-___styles-modules__header{display:flex;height:65px;border-bottom:1px solid #eaeaea;justify-content:center;align-items:center;margin-bottom:.8rem}.src-view-pages-individual-___styles-modules__logo{width:200px;max-height:45px;margin:0}", ""]);
// Exports
exports.locals = {
	"header": "src-view-pages-individual-___styles-modules__header",
	"logo": "src-view-pages-individual-___styles-modules__logo"
};
module.exports = exports;
