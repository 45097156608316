import { searchFavoriteProductLists } from './graphql';
import { createUseCase } from '~core/usecase/createUseCase';
import {
  fetchedFavoriteProductList,
  recievedFavoriteProductList,
} from '~redux/favorite/reducers/product';
import {
  FavoriteProductList,
  SearchableFavoriteProductListSortableFields,
  SearchableSortDirection,
  SearchFavoriteProductListsQuery,
  SearchFavoriteProductListsQueryVariables,
} from '~types/api';
import { executeQuery } from '~utils/graphql';

export const fetchFavoriteProductList = createUseCase(
  'favorite/product/fetchList',
  async ({ accountId }: { accountId: string }, { dispatch }) => {
    dispatch(fetchedFavoriteProductList());
    const res = await executeQuery<
      SearchFavoriteProductListsQuery,
      SearchFavoriteProductListsQueryVariables
    >(searchFavoriteProductLists, {
      filter: {
        account_id: { eq: accountId },
      },
      sort: {
        field: SearchableFavoriteProductListSortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
    });
    dispatch(
      recievedFavoriteProductList(
        (res.data?.searchFavoriteProductLists?.items ??
          []) as FavoriteProductList[]
      )
    );
  }
);
