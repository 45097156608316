import { createUseCase } from '~core/usecase/createUseCase';
import { pay } from '~domain/point/Points';
import { listPointsByAccount } from '~graphql/queries';
import { recievedPoints, usedPoints } from '~redux/points/slice/byId';
import { ModelSortDirection, Point, PointHistory } from '~types/api';
import { executeQuery } from '~utils/graphql';

export const getBuyerPoints = createUseCase(
  'point/getBuyerPoints',
  async ({ accountId }: { accountId: string }, { dispatch }) => {
    const res = await executeQuery(listPointsByAccount, {
      account_id: accountId,
      limit: 1000,
      sortDirection: ModelSortDirection.DESC,
    });
    dispatch(recievedPoints(res.data?.listPointsByAccount?.items as Point[]));
  }
);

export const usePoints = createUseCase(
  'point/usePoints',
  async (
    points: Record<string, { orderId: string; usedPoints: number }[]>,
    { dispatch }
  ) => {
    const result = await Promise.all(
      Object.entries(points).map(async ([pointId, orders]) => {
        const res = await pay(pointId, orders);
        return res;
      })
    );
    const histories = result.flat();
    dispatch(usedPoints(histories as PointHistory[]));
  }
);
