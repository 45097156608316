import { createTheme, ThemeProvider } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import React from 'react';

const MaterialThemeProvider: React.FC = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#333',
      },
      secondary: {
        main: orange[600],
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        '"Open Sans"',
        '"Segoe UI"',
        '"Helvetica Neue"',
        // '"游ゴシック体"',
        // 'YuGothic',
        // '"游ゴシック Medium"',
        // '"Yu Gothic Medium"',
        // '"游ゴシック"',
        // '"Yu Gothic"',
        '"Hiragino Kaku Gothic ProN"',
        '"Hiragino Sans"',
        'sans-serif',
      ].join(','),
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: '30px',
          lineHeight: '38px',
          fontWeight: 600,
        },
      },
      MuiInputBase: {
        root: {
          borderRadius: '0!important',
        },
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
