import * as holiday_jp from '@holiday-jp/holiday_jp';
import axios from 'axios';

export const addDays = (date: Date, days: number) => {
  const d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
};

export const isHoliday = (date: Date) => {
  //土、日、祝はtrueを返却
  return (
    date.getDay() === 0 || date.getDay() === 6 || holiday_jp.isHoliday(date)
  );
};

export const getWorkingDay = (date: Date, days = 0) => {
  let workingDay = new Date(date);
  const absDays = Math.abs(days);
  const subtract = days < 0;

  //起算日が休日の場合は再設定する
  while (isHoliday(workingDay)) {
    workingDay = addDays(workingDay, subtract ? -1 : 1);
  }

  let i = 0;
  while (i < absDays) {
    workingDay = addDays(workingDay, subtract ? -1 : 1);
    if (isHoliday(workingDay)) {
      continue;
    }
    i++;
  }

  return workingDay;
};

export const getJSTDate = async () => {
  try {
    const res = await axios.get(
      'http://worldtimeapi.org/api/timezone/Asia/Tokyo'
    );
    return new Date(res.data.datetime);
  } catch (e) {
    return new Date();
  }
};
