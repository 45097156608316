import { FiHelpCircle } from '@react-icons/all-files/fi/FiHelpCircle';
import React from 'react';
import { Button } from 'reactstrap';
import { SupplierMenu } from '../SupplierMenu';
import './styles.scss';

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  return (
    <div className="supplier-sidebar">
      <SupplierMenu />
      <div
        className="mt-4"
        style={{
          position: 'sticky',
          bottom: 0,
        }}
      >
        <a
          href="https://support.homula.jp/help/%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89faq"
          target="_blank"
        >
          <Button outline className="rounded-pill" block>
            <FiHelpCircle className="mr-2" />
            ヘルプページ
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
