import { IndividualCartItem } from './IndividualCartItem';
import { IndividualCartSegment } from './IndividualCartSegment';
import { CartBase, CartJson, CartType } from './base/CartBase';
import { BuyerInfo } from '~redux/buyer/types';

export class IndividualCart extends CartBase {
  receivedirectlyAtStore = false;

  private constructor(params: CartType, receivedirectlyAtStore: boolean) {
    super(params);
    this.receivedirectlyAtStore = receivedirectlyAtStore;
  }

  static create(
    { items, ...cart }: CartJson,
    buyerInfo: BuyerInfo,
    receivedirectlyAtStore = false
  ) {
    return new IndividualCart(
      {
        ...cart,
        buyerInfo,
        items: items.map(IndividualCartItem.create),
      },
      receivedirectlyAtStore
    );
  }

  get segments() {
    const segments = super.segments;
    segments.forEach((segment) => {
      (segment as IndividualCartSegment).receivedirectlyAtStore = this.receivedirectlyAtStore;
    });
    return segments;
  }

  create(...params: Parameters<typeof IndividualCart.create>) {
    return IndividualCart.create(...params);
  }

  createSegment(...params: Parameters<typeof IndividualCartSegment.create>) {
    return IndividualCartSegment.create(...params);
  }

  createCartItem(...params: Parameters<typeof IndividualCartItem.create>) {
    return IndividualCartItem.create(...params);
  }

  createCartItemById(id: string) {
    return IndividualCartItem.getById(id);
  }

  getBrandMinimumBuy(brandId: string): number {
    const item = this.items.find((item) => item.brand_id === brandId);
    return item?.product.brand.individual_minimum_buy ?? 0;
  }
}
