import { createReducer } from '@reduxjs/toolkit';
import { requestStart, requestComplete, requestError } from '../actions';

type Status = 'unsent' | 'loading' | 'completed' | 'failed';

type RequestState = {
  [type: string]: Status;
};

const initialState: RequestState = {};

const requestReducer = createReducer<RequestState>(initialState, {
  [requestStart.type]: (state, action) => ({
    ...state,
    [action.payload]: 'loading',
  }),
  [requestComplete.type]: (state, action) => ({
    ...state,
    [action.payload]: 'completed',
  }),
  [requestError.type]: (state, action) => ({
    ...state,
    [action.payload]: 'failed',
  }),
});

export default requestReducer;
