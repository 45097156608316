import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { REFERRAL_POINTS } from '~constants/trade';
import { formatCurrency } from '~utils/formatter';
import './styles.scss';

const GiftIcon: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const referralPoints = useMemo(() => formatCurrency(REFERRAL_POINTS), [
    REFERRAL_POINTS,
  ]);

  return (
    <Link
      id="brand-referrals-nav"
      className="brand-referrals-nav"
      to="/brand-referrals"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      ブランドをリクエスト
      <Popover
        target="brand-referrals-nav"
        placement="bottom"
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
      >
        <PopoverBody>
          <p className="font-weight-bold">
            ブランドをリクエストしてポイントをゲット
          </p>
          <p>
            リクエストしたブランドの商品がhomulaに出展されると、一ブランドにつき
            {referralPoints}分のサイト内で利用可能なポイントがもらえます
          </p>
          <Link to="/brand-referrals">
            <Button block>ブランドをリクエスト</Button>
          </Link>
        </PopoverBody>
      </Popover>
    </Link>
  );
};

export default GiftIcon;
