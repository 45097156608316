// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-CategoryBurgerMenu-MenuItem-___styles-modules__category-burger-menu-item{margin-bottom:15px}.src-view-widgets-CategoryBurgerMenu-MenuItem-___styles-modules__menu-title{color:#000 !important;margin-bottom:15px}", ""]);
// Exports
exports.locals = {
	"category-burger-menu-item": "src-view-widgets-CategoryBurgerMenu-MenuItem-___styles-modules__category-burger-menu-item",
	"menu-title": "src-view-widgets-CategoryBurgerMenu-MenuItem-___styles-modules__menu-title"
};
module.exports = exports;
