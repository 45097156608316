import { createReducer } from '@reduxjs/toolkit';
import {
  AuthUser,
  CLEAR_AUTH_USER,
  RECEIVE_AUTH_USER,
} from '../actions/authUser';

type AuthUserState = AuthUser | null;

const authUserReducer = createReducer(null as AuthUserState, {
  [CLEAR_AUTH_USER]: (state, action) => action.payload,
  [RECEIVE_AUTH_USER]: (state, action) => action.payload,
});

export default authUserReducer;
