import { createReducer } from '@reduxjs/toolkit';
import {
  clearNewMessages,
  pushNewMessages,
  checkNewMessages,
} from '../actions';

type NewMessagesState = {
  unCheckedMessages: string[];
  checkedMessages: string[];
};
const initialState: NewMessagesState = {
  unCheckedMessages: [],
  checkedMessages: [],
};

const newMessagesReducer = createReducer(initialState, {
  [pushNewMessages.type]: (state, { payload }) => ({
    ...state,
    unCheckedMessages: [...state.unCheckedMessages, ...payload],
  }),
  [checkNewMessages.type]: (state) => ({
    unCheckedMessages: [],
    checkedMessages: [...state.checkedMessages, ...state.unCheckedMessages],
  }),
  [clearNewMessages.type]: () => initialState,
});

export default newMessagesReducer;
