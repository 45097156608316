import {
  ProductBase,
  ProductType,
  CreateProductParams,
} from './base/ProductBase';
import { Product as TProduct } from '~redux/brand/types';

export class IndividualProduct extends ProductBase {
  private constructor(params: ProductType) {
    super(params);
  }

  static create({ ...product }: CreateProductParams) {
    return new IndividualProduct(product as ProductType);
  }

  create(params: CreateProductParams) {
    return IndividualProduct.create(params);
  }

  createById(productId: string) {
    return IndividualProduct.createById(productId);
  }

  createWithImageUrl(product: TProduct) {
    return IndividualProduct.createWithImageUrl(product);
  }

  getById(productId: string) {
    return IndividualProduct.getById(productId);
  }

  register(
    ...params: Parameters<typeof ProductBase.register>
  ): ReturnType<typeof ProductBase.register> {
    return IndividualProduct.register(...params);
  }

  get isSaleProduct() {
    return false;
  }

  // 卸掛率
  // セール商品の場合はセール用の卸掛率を返す
  get wholesaleRate() {
    return this.productSaleWholesaleRate ?? this.productWholesaleRate;
  }

  get productWholesaleRate() {
    return (
      // FIXME: ProductModelにProductReadModelの内容を渡している箇所があるため
      // @ts-ignore
      (this.individual_wholesale_rate ??
        this.brand.individual_wholesale_rate ??
        100) + (this.discountRate ?? 0)
    );
  }

  get productSaleWholesaleRate() {
    return (
      // FIXME: ProductModelにProductReadModelの内容を渡している箇所があるため
      // @ts-ignore
      (this.individual_wholesale_rate ??
        this.brand.individual_wholesale_rate ??
        100) + (this.discountRate ?? 0)
    );
  }
}
