import { Translations } from '@aws-amplify/ui-components';

const messages = {
  [Translations.SIGN_IN_HEADER_TEXT]: 'ログイン',
  [Translations.SIGN_IN_ACTION]: 'ログイン',
  [Translations.BACK_TO_SIGN_IN]: 'ログイン画面に戻る',
  [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れですか？',
  [Translations.RESET_PASSWORD_TEXT]: 'パスワードをリセット',
  [Translations.RESET_YOUR_PASSWORD]: 'パスワードをリセット',
  [Translations.SEND_CODE]: '確認コードを送信',
  [Translations.CODE_LABEL]: '確認コード',
  [Translations.CODE_PLACEHOLDER]: '確認コードを入力',
  [Translations.CHANGE_PASSWORD]: 'パスワードを変更',
  [Translations.CHANGE_PASSWORD_ACTION]: 'パスワードを変更',
  [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: '新しいパスワードを入力',
  [Translations.SUBMIT]: '送信',
};

export default messages;
