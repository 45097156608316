import { combineReducers } from 'redux';
import adminOrderBrandsReducer from './adminOrderBrands';
import adminOrderDetailsReducer from './adminOrderDetails';
import adminOrdersReducer from './adminOrders';
import adminOrdersCsvReducer from './adminOrdersCsv';
import adminReturnProductsReducer from './adminReturnProducts';
import byId from './byId';
import isOrderCancelableReducer from './isOrderCancelable';
import latestOrderReducer from './latestOrder';
import newOrdersReducer from './newOrders';
import nextOrdersTokenReducer from './nextOrdersToken';
import orderAccountReducer from './orderAccount';
import orderProductsReducer from './orderProducts';
import ordersReducer from './orders';

const orderReducer = combineReducers({
  orders: ordersReducer,
  latestOrder: latestOrderReducer,
  isOrderCancelable: isOrderCancelableReducer,
  newOrders: newOrdersReducer,
  orderAccount: orderAccountReducer,
  orderProducts: orderProductsReducer,
  nextOrdersToken: nextOrdersTokenReducer,
  adminOrders: adminOrdersReducer,
  adminOrdersCsv: adminOrdersCsvReducer,
  adminOrderBrands: adminOrderBrandsReducer,
  adminOrderDetails: adminOrderDetailsReducer,
  adminReturnProducts: adminReturnProductsReducer,
  byId,
});

export default orderReducer;
