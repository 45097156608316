import { createReducer } from '@reduxjs/toolkit';
import {
  addReturnProducts,
  receiveReturnProducts,
  receiveUpdatedReturnProduct,
} from '../actions/admin';
import { ReturnProduct } from '~domain/order/ReturnProduct';
import { ReturnStatusType } from '~redux/buyer/types';

const initialState: {
  list: ReturnProduct[];
  nextToken: string | null;
} = {
  list: [],
  nextToken: null,
};

const adminReturnProducts = createReducer(initialState, {
  [receiveReturnProducts.type]: (_, { payload }) => payload,
  [addReturnProducts.type]: (state, { payload }) => ({
    list: [...state.list, ...payload.list],
    nextToken: payload.nextToken,
  }),
  [receiveUpdatedReturnProduct.type]: (
    { list, ...rest },
    { payload }: { payload: ReturnProduct }
  ) => ({
    list: list.map((returnProduct) => {
      if (returnProduct.return_product_id !== payload.return_product_id) {
        return returnProduct;
      }

      returnProduct.return_status = payload.return_status;
      returnProduct.appliedAt = payload.createdAt;
      returnProduct.returnedQuantity = payload.return_quantity;

      return returnProduct;
    }),
    ...rest,
  }),
});

export default adminReturnProducts;
