import './styles.scss';
import React from 'react';
import { Link } from 'react-router-dom';

export const BrandOrderedButton: React.FC = () => {
  return (
    <Link className="brand-ordered-nav" to="/my-brands">
      注文済みブランド
    </Link>
  );
};
