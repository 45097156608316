/* eslint-disable import/order */
import 'react-toastify/dist/ReactToastify.min.css';
import React from 'react';
import './App.css';
import './Vendor.js';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { store } from './store';
import { BreakpointProvider } from '~view/contexts/breakpoint';

import { Amplify } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import awsmobile from './aws-exports';
import amplifyMessages from './amplifyMessages';

import Routes from '~view/pages/routes';
import { HeadProvider } from '~view/contexts/headContext';
import { ToastContainer } from 'react-toastify';
import MaterialThemeProvider from '~view/widgets/MaterialThemeProvider';
import { Replay } from '@sentry/replay';
import { FlagsProvider } from '~view/contexts/FlagsContext';

import { QueryClientProvider } from 'react-query';
import { queryClient } from './queryClient';

I18n.setLanguage('ja');
I18n.putVocabulariesForLanguage('ja', amplifyMessages);

Amplify.configure({
  ...awsmobile,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: awsmobile.aws_project_region,
      // identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      // level: 'public',
    },
  },
});
Auth.configure(awsmobile);

// Edition.init();

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? 'https://5b0779a1d13747a48db71083e042ff49@o812494.ingest.sentry.io/5805510'
      : undefined,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Integrations.BrowserTracing(),
    new Replay({
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10, // Or however deep you want your state context to be.
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer autoClose={5000} />
        <BreakpointProvider>
          <MaterialThemeProvider>
            <HeadProvider>
              <FlagsProvider>
                <Routes />
              </FlagsProvider>
            </HeadProvider>
          </MaterialThemeProvider>
        </BreakpointProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
