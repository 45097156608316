import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { Account } from '~redux/account/actions/account';

export const getAccount = async (accountId: string): Promise<Account> => {
  const {
    data: { getAccount: account },
  } = await API.graphql<any>(
    graphqlOperation(queries.getAccount, { id: accountId })
  );
  return account;
};
