import { API, Storage, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { ProductType as ProductTypeModel } from '../ProductType';
import { ProductVideo } from '../ProductVideo';
import { Entity } from '~core/domain/Entity';
import { Inventory } from '~domain/inventory/Inventory';
import * as mutations from '~graphql/mutations';
import * as queries from '~graphql/queries';
import {
  ImageKey,
  VideoKey,
  Brand,
  Product as ProductJson,
  ProductType as ProductTypeJson,
} from '~redux/brand/types';
import { OrderType } from '~redux/product/types';
import {
  CreateProductMutation,
  CreateReturnInventoryLogMutationVariables,
  StockType,
  StockMode,
  CreateReturnInventoryLogMutation,
  SearchProductsQuery,
  SearchProductsQueryVariables,
  UpdateInventoriesMutation,
  UpdateProductMutationVariables,
  UpdateProductMutation,
} from '~types/api';
import { RequiredOne } from '~types/utils';
import { executeQuery, removeItems } from '~utils/graphql';
import { getImageUrl } from '~utils/image';
import { calculatePrice } from '~utils/price';
import { isReturnableProductCategory } from '~utils/return';

export type ProductType = {
  id: string;
  product_name: string;
  product_number: string;
  product_brand_id: string;
  product_content: string;
  product_description: string;
  product_owner: string;
  product_wholesale_rate: number;
  product_sale_wholesale_rate: number | null;
  product_retail_price: number;
  is_open_price?: boolean;
  product_color: string;
  product_size: string;
  product_jancode: string;
  product_stock_quantity: number;
  product_type: string;
  product_public_status: '公開' | '非公開' | '限定公開';
  product_category: string;
  product_subcategory: string;
  product_estimated_ship_date_min: number;
  product_estimated_ship_date_max: number;
  product_preorder_shipping_date: string;
  product_minimum_quantity: number;
  product_order_lot: number;
  product_preorder: OrderType | null;
  product_display_order: number;
  product_colors?: string[];
  product_sizes?: string[];
  product_types?: string[];
  createdAt: string;
  imageUrl: string;
  videos?: ProductVideo[];
  brand: Omit<Brand, 'product' | 'createdAt' | 'updatedAt' | 'imageUrl'>;
  imageKeys: ImageKey[];
  videoKeys: VideoKey[];
  producttype: ProductTypeJson[];
  suspended: boolean;
  discount: { items: { discount_rate: number }[] } | null;
  collections?: { items: { collection_id: string }[] };
  sku?: string;
  out_of_stock?: boolean;
  limited_publishing_list?: string[];
  copied_product_id?: string | null;
  copied_brand_id?: string | null;
  publishedAt: string | null;
  product_season: string;
  product_year: number;
};

export type CreateProductParams = ProductJson & {
  videos?: ProductVideo[];
};

export abstract class ProductBase extends Entity<ProductType> {
  constructor(params: ProductType) {
    super(params);
  }

  abstract get isSaleProduct(): boolean;
  abstract get productWholesaleRate(): number;
  abstract get productSaleWholesaleRate(): number | null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static create(params: CreateProductParams): ProductBase {
    throw new Error('Not implemented');
  }

  abstract create(params: CreateProductParams): ProductBase;

  static async createById(productId: string): Promise<ProductBase> {
    const productResponse = await API.graphql<any>(
      graphqlOperation(queries.getProduct, {
        id: productId,
      })
    );
    const product = removeItems(productResponse.data.getProduct);
    return this.create({
      ...product,
    });
  }

  abstract createById(productId: string): Promise<ProductBase>;

  static async createWithImageUrl(product: ProductJson): Promise<ProductBase> {
    const imageUrl = product.imageKeys[0]?.imageKey
      ? ((await getImageUrl(product.imageKeys[0]?.imageKey)) as string)
      : 'https://placehold.jp/200x200.png?text=No image';
    return this.create({
      ...product,
      imageUrl,
    });
  }

  abstract createWithImageUrl(product: ProductJson): Promise<ProductBase>;

  static async getById(productId: string) {
    const {
      data: { getProduct: response },
    } = await API.graphql<any>(
      graphqlOperation(queries.getProduct, { id: productId })
    );
    const product = removeItems<ProductJson>(response);
    const imageUrl = product.imageKeys[0]?.imageKey
      ? ((await getImageUrl(product.imageKeys[0]?.imageKey)) as string)
      : 'https://placehold.jp/200x200.png?text=No image';
    const videoUrl = product.videoKeys[0]?.videoKey
      ? `${process.env.REACT_APP_CLOUD_FRONT_DOMAIN}/public/videos/product/${product.product_brand_id}/${product.id}/${product.videoKeys[0]?.videoKey}`
      : 'https://placehold.jp/200x200.png?text=No image';
    return this.create({
      ...product,
      imageUrl,
      videoUrl,
    });
  }

  abstract getById(productId: string): Promise<ProductBase>;

  static async checkSkuDuplication(
    brandId: string,
    sku: string,
    productId?: string,
    productTypeId?: string
  ) {
    const {
      data: {
        searchProducts: { items: products },
      },
    } = await executeQuery(queries.searchProducts, {
      filter: {
        sku: { eq: sku },
        id: productId ? { ne: productId } : undefined,
        product_brand_id: { eq: brandId },
      },
    });

    if (products.length) {
      return false;
    }

    const {
      data: {
        searchProductTypes: { items: productTypes },
      },
    } = await executeQuery(queries.searchProductTypes, {
      filter: {
        sku: { eq: sku },
        product_id: productId ? { ne: productId } : undefined,
        id: productTypeId ? { ne: productTypeId } : undefined,
      },
    });
    if (
      productTypes.some(
        (item: any) => item.product?.product_brand_id === brandId
      )
    ) {
      return false;
    }

    return true;
  }

  get isPublic() {
    return !(
      this.product_public_status === '非公開' ||
      this.product_public_status === '限定公開' ||
      this.brand.brand_public_status === '非公開' ||
      this.suspended
    );
  }

  get isReturnableCategory(): boolean {
    return isReturnableProductCategory(this);
  }

  isPublished(contactList?: string[]) {
    return (
      this.isPublic ||
      (this.product_public_status === '限定公開' &&
        this.limited_publishing_list?.some((list) =>
          contactList?.includes(list)
        ))
    );
  }

  static async register(product: {
    product_name: string;
    product_number: string;
    product_brand_id: string;
    product_content: string;
    product_description: string;
    product_owner: string;
    product_wholesale_rate: number;
    product_sale_wholesale_rate?: number;
    product_retail_price: number;
    is_open_price?: boolean;
    product_color: string;
    product_size: string;
    product_jancode: string;
    product_stock_quantity: number;
    product_type: string;
    product_public_status: '公開' | '非公開';
    product_category: string;
    product_subcategory: string;
    product_minimum_quantity: number;
    product_estimated_ship_date_min: number;
    product_estimated_ship_date_max: number;
    product_order_lot: number;
    product_preorder?: number;
    product_preorder_shipping_date?: string;
    product_display_order: number;
    suspended: boolean;
    owner: string;
    images?: ({
      name: string;
    } & RequiredOne<{
      data: Blob;
      copyKey: string;
    }>)[];
    product_types?: (Omit<
      ProductTypeJson,
      'id' | 'product_id' | 'product' | 'createdAt' | 'imageKey' | 'imageUrl'
    > & {
      image:
        | ({
            name: string;
          } & RequiredOne<{
            data: Blob;
            copyKey: string;
          }>)
        | undefined;
    })[];
  }) {
    const { images, product_types, ...restProduct } = product;

    const response = (await API.graphql<any>(
      graphqlOperation(mutations.createProduct, { input: restProduct })
    )) as {
      data: CreateProductMutation;
    };

    const {
      data: { createProduct: registered },
    } = response;

    await images?.reduce(
      (prev, image) =>
        prev.then(async () => {
          try {
            const newFile = (await (image.copyKey
              ? Storage.copy(
                  { key: encodeURIComponent(image.copyKey) },
                  { key: encodeURIComponent(image.name) }
                )
              : Storage.put(image.name, image.data))) as { key: string };
            const newImageKey = newFile.key;

            await API.graphql<any>(
              graphqlOperation(mutations.createProductImageKey, {
                input: {
                  imageKey: newImageKey,
                  product_id: registered?.id,
                  owner: registered?.product_owner,
                },
              })
            );
          } catch (e) {
            console.error(e);
          }
        }),
      Promise.resolve()
    );

    return {
      product_id: registered?.id!,
      product_types: [],
    };
  }

  abstract register(
    ...params: Parameters<typeof ProductBase.register>
  ): ReturnType<typeof ProductBase.register>;

  async copy(options?: {
    brandId?: string;
    saleWholesaleRate?: number;
    stock?: number;
    product_public_status?: '公開' | '非公開';
    productTypes?: { id: string; stock?: number }[];
    lot?: number;
  }) {
    let brand = this.brand;

    if (options?.brandId) {
      const {
        data: { getBrand },
      } = await API.graphql<any>(
        graphqlOperation(queries.getBrand, {
          id: options.brandId,
        })
      );

      brand = getBrand;
    }

    const {
      data: { searchProducts },
    } = (await API.graphql<any>(
      graphqlOperation(queries.searchProducts, {
        filter: {
          copied_product_id: { eq: this.id },
          product_brand_id: { eq: options?.brandId! },
        } as SearchProductsQueryVariables['filter'],
      })
    )) as {
      data: SearchProductsQuery;
    };

    let copiedProduct = searchProducts?.items?.[0]
      ? await this.createById(searchProducts.items[0].id)
      : undefined;
    console.log(copiedProduct);

    //商品情報を登録
    if (!copiedProduct) {
      console.log(`コピー先ブランドに同じ商品が存在しない`);
      // 商品画像コピー
      const newImages = this.imageKeys.map((imageKey: ImageKey) => {
        const extension = imageKey.imageKey.split('.').pop();
        return {
          name: `return_${uuid()}.${extension}`,
          copyKey: imageKey.imageKey,
        };
      });

      // let productName = `【${this.brand.brand_name}】${this.product_name}`;
      // const regexp = new RegExp(
      //   '^' + '(【' + this.brand.brand_name + '】)+',
      //   ''
      // );
      // productName = productName.replace(regexp, '$1');

      const data = {
        product_name: this.product_name,
        product_number: this.product_number,
        product_brand_id: brand.id,
        product_content: this.product_content,
        product_description: this.product_description,
        product_color: this.product_color,
        product_size: this.product_size,
        product_owner: brand.brand_owner,
        product_wholesale_rate: this.product_wholesale_rate,
        product_sale_wholesale_rate: options?.saleWholesaleRate,
        is_open_price: this.is_open_price,
        product_retail_price: this.product_retail_price,
        product_jancode: this.product_jancode,
        product_stock_quantity: options?.stock ?? this.product_stock_quantity,
        product_minimum_quantity: this.product_minimum_quantity,
        product_type: this.product_type,
        product_public_status: options?.product_public_status ?? '非公開',
        product_category: this.product_category,
        product_subcategory: this.product_subcategory,
        product_estimated_ship_date_min: this.product_estimated_ship_date_min,
        product_estimated_ship_date_max: this.product_estimated_ship_date_max,
        product_order_lot: options?.lot ?? this.product_order_lot,
        product_display_order: 0,
        suspended: false,
        copied_product_id: this.id,
        copied_brand_id: this.product_brand_id,
        owner: brand.brand_owner,
        images: newImages,
        // product_types: productTypes,
      };
      const product = await this.register(data);
      copiedProduct = await this.createById(product.product_id);
      console.log(copiedProduct);
    }

    if (!copiedProduct) {
      return;
    }

    // 商品種類がない場合は商品の在庫情報を更新
    if (!options?.productTypes?.length) {
      //在庫更新
      if (options?.stock) {
        const inventory = await Inventory.createByProductId(copiedProduct.id);
        const {
          data: { updateInventories },
        } = (await Inventory.updateInventories(options.brandId!, [
          {
            product_id: copiedProduct.id,
            sku: copiedProduct.sku ?? `homula_${copiedProduct.id}`,
            inventory: (inventory?.inventory ?? 0) + options.stock,
          },
        ])) as { data: UpdateInventoriesMutation };
        console.log(updateInventories);
        const res = updateInventories?.[0];

        // homula在庫ログ登録
        if (
          options?.brandId === process.env.REACT_APP_HOMULA_BRAND_ID &&
          res &&
          options?.stock
        ) {
          console.log(`homula在庫ログ登録`);
          const logVariables: CreateReturnInventoryLogMutationVariables = {
            input: {
              inventory_id: res.id,
              brand_id: res.brand_id,
              product_id: res.product_id,
              sku: res.sku,
              stock_type: StockType.STOCK,
              stock_mode: StockMode.AUTO,
              stock_date: moment().toISOString(),
              quantity: options.stock,
            },
          };
          (await API.graphql<any>(
            graphqlOperation(mutations.createReturnInventoryLog, logVariables)
          )) as {
            data: CreateReturnInventoryLogMutation;
          };
        }
      }

      return { copiedProduct, quantity: options?.stock };
    }

    //商品種類のコピー
    const copiedTypes = await Promise.all(
      options.productTypes.map(async (pt) => {
        const type = copiedProduct!.producttype?.find(
          (type) => type.copied_product_type_id === pt.id
        );
        let copiedType = type
          ? await ProductTypeModel.createById(type.id)
          : undefined;
        console.log(copiedType, type);

        //商品種類を登録
        if (!copiedType) {
          const productType = await ProductTypeModel.createById(pt.id);
          console.log(productType);
          copiedType = await productType.copy({
            destProductId: copiedProduct!.id,
            owner: copiedProduct!.product_owner,
          });
        }

        return { copiedType, quantity: pt.stock };
      })
    );
    console.log(copiedTypes);

    //在庫情報を更新
    const inventories = await Promise.all(
      options.productTypes
        .filter(({ stock }) => stock)
        .map(async ({ id, stock }) => {
          const copiedType = copiedTypes.find(
            (pt) => pt?.copiedType?.type?.copied_product_type_id === id
          );
          const inventory = await Inventory.createByProductId(
            copiedProduct!.id,
            copiedType?.copiedType?.type?.id
          );
          return {
            product_id: copiedProduct!.id,
            product_type_id: copiedType?.copiedType?.type?.id,
            sku: copiedType?.copiedType?.type?.sku ?? `homula_${id}`,
            inventory: (inventory?.inventory ?? 0) + stock!,
          };
        })
    );
    if (inventories.length) {
      await Inventory.updateInventories(options.brandId!, inventories);
    }

    // StockProduct更新
    try {
      if (copiedTypes) {
        await Promise.all(
          copiedTypes.map((copiedType) =>
            API.graphql<any>(
              graphqlOperation(mutations.updateStockProductByProductId, {
                srcProductId: copiedType.copiedType?.product?.copied_product_id,
                srcProductTypeId: copiedType.copiedType?.toJson()
                  ?.copied_product_type_id,
                productId: copiedType.copiedType?.product?.id,
                productTypeId: copiedType.copiedType?.toJson()?.id,
              })
            )
          )
        );
      } else {
        await API.graphql<any>(
          graphqlOperation(mutations.updateStockProductByProductId, {
            srcProductId: copiedProduct.copied_product_id,
            productId: copiedProduct.id,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }

    return { copiedProduct, copiedTypes };
  }

  async update(input: Omit<UpdateProductMutationVariables['input'], 'id'>) {
    const {
      data: { updateProduct },
    } = (await API.graphql<any>(
      graphqlOperation(mutations.updateProduct, {
        input: {
          id: this.id,
          ...input,
        },
      })
    )) as {
      data: UpdateProductMutation;
    };

    return updateProduct && this.createById(updateProduct.id);
  }

  // 卸掛率
  // セール商品の場合はセール用の卸掛率を返す
  get wholesaleRate() {
    return this.productSaleWholesaleRate ?? this.productWholesaleRate;
  }

  get discountRate() {
    return this.discount?.items?.[0]?.discount_rate;
  }

  get originalUnitPrice() {
    return calculatePrice(this.product_retail_price, this.productWholesaleRate);
  }

  get unitPrice() {
    return calculatePrice(this.product_retail_price, this.wholesaleRate);
  }

  toJson(): ProductJson {
    return {
      id: this.id,
      product_name: this.product_name,
      product_number: this.product_number,
      product_brand_id: this.product_brand_id,
      product_content: this.product_content,
      product_description: this.product_description,
      product_owner: this.product_owner,
      product_wholesale_rate: this.product_wholesale_rate,
      product_sale_wholesale_rate: this.product_sale_wholesale_rate,
      product_retail_price: this.product_retail_price,
      is_open_price: this.is_open_price,
      product_color: this.product_color,
      product_size: this.product_size,
      product_jancode: this.product_jancode,
      product_stock_quantity: this.product_stock_quantity,
      product_type: this.product_type,
      product_public_status: this.product_public_status,
      product_category: this.product_category,
      product_subcategory: this.product_subcategory,
      product_estimated_ship_date_min: this.product_estimated_ship_date_min,
      product_estimated_ship_date_max: this.product_estimated_ship_date_max,
      product_preorder_shipping_date: this.product_preorder_shipping_date,
      product_minimum_quantity: this.product_minimum_quantity,
      product_order_lot: this.product_order_lot,
      product_preorder: this.product_preorder,
      product_display_order: this.product_display_order,
      createdAt: this.createdAt,
      imageUrl: this.imageUrl,
      brand: this.brand,
      videoKeys: this.videoKeys,
      imageKeys: this.imageKeys,
      producttype: this.producttype,
      suspended: this.suspended,
      discount: this.discount,
      product_season: this.product_season,
      product_year: this.product_year,
      publishedAt: this.publishedAt,
    };
  }
}
