import { createReducer } from '@reduxjs/toolkit';
import {
  setFavoriteProductLists,
  clearFavoriteProductList,
  appendFavoriteProductList,
  removeFavoriteProductList,
  setFavoriteProductList,
  FavoriteProductList,
} from '../actions';

const initialState: FavoriteProductList[] = [];

const favoriteProdcutListReducer = createReducer(initialState, {
  [setFavoriteProductLists.type]: (_, { payload }) => payload,
  [appendFavoriteProductList.type]: (state, { payload }) => [...state, payload],
  [removeFavoriteProductList.type]: (state, { payload }) =>
    state.filter(({ id }) => id !== payload),
  [setFavoriteProductList.type]: (state, { payload: { list_id, list_name } }) =>
    state.map((item) => {
      if (item.id === list_id) {
        return { ...item, list_name };
      }
      return item;
    }),
  [clearFavoriteProductList.type]: () => initialState,
});

export default favoriteProdcutListReducer;
