import { createReducer } from '@reduxjs/toolkit';
import {
  clearThreads,
  postGetThreads,
  postUpdateMessagesOpend,
  pushThread,
  setActiveThread,
  Thread,
} from '../actions';

type ThreadsState = Thread[];
const initialState: ThreadsState = [];

const threadsReducer = createReducer(initialState, {
  [clearThreads.type]: () => initialState,
  [postGetThreads.type]: (_, { payload }) => payload,
  [pushThread.type]: (state, { payload }) => [...state, payload],
  [setActiveThread.type]: (state, { payload }) =>
    state.map((thread) => {
      if (thread.id === payload) {
        return { ...thread, active: true };
      }
      return { ...thread, active: false };
    }),
  [postUpdateMessagesOpend.type]: (state, { payload }) =>
    state.map((thread) => {
      if (thread.id === payload) {
        return { ...thread, newMessages: 0 };
      }
      return thread;
    }),
});

export default threadsReducer;
