/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuList } from '~redux/menu/thunk';
import { Route } from 'react-router';

import BuyerRoute from '~view/widgets/BuyerRoute';
import PublicRoute from '~view/widgets/PublicRoute';
import PrivateRoute from '~view/widgets/PrivateRoute';

import { authUserSelector } from '~redux/auth/selectors/authUser';
import { getTagList } from '~redux/tag/thunk';
import { listFeaturedContents } from '~redux/cms/thunk';
import { clearTagList } from '~redux/tag/actions';
import { clearFeaturedContents } from '~redux/cms/slice/featuredContents';
import withTitle from '~view/hoc/withTitle';
import { selectProjectId } from '~redux/project';

//pages
const ComingSoon = React.lazy(() => import('~view/pages/ComingSoon'));
const Maintenance = React.lazy(() => import('~view/pages/Maintenance'));
const PrivacyPolicy = React.lazy(
  () => import('~view/pages/common/PrivacyPolicy')
);
const ReturnPolicy = React.lazy(
  () => import('~view/pages/common/ReturnPolicy')
);
const BuyerRegistrationHubs = React.lazy(
  () => import('~view/UserRegistration/BuyerRegistrationHubs')
);
const ListViewBrand = React.lazy(
  () => import('~view/pages/buyer/ListViewBrand')
);
const ListViewSale = React.lazy(
  () => import('~view/pages/common/ListViewSale')
);
const ProductDetail = React.lazy(
  () => import('~view/pages/common/ProductDetail')
);
const ShoppingCart = React.lazy(() => import('~view/pages/buyer/ShoppingCart'));
const CheckOut = React.lazy(() => import('~view/pages/common/CheckOut'));
const PaymentTerm = React.lazy(() => import('~view/pages/common/PaymentTerm'));
const OrderConfirmation = React.lazy(
  () => import('~view/pages/common/OrderConfirmation')
);
const OrderComplete = React.lazy(
  () => import('~view/pages/common/OrderComplete')
);
const Profile = React.lazy(() => import('~view/pages/common/Profile'));
const Profileedit = React.lazy(() => import('~view/pages/common/Profileedit'));
const Messages = React.lazy(() => import('~view/pages/common/Messages'));
const PaymentMethod = React.lazy(
  () => import('~view/pages/common/Account/PaymentMethod')
);
const ShippingAddress = React.lazy(
  () => import('~view/pages/common/Account/ShippingAddress')
);
const OrderHistory = React.lazy(() => import('~view/pages/buyer/OrderHistory'));
const Payments = React.lazy(() => import('~view/pages/buyer/Payments'));
const Payment = React.lazy(() => import('~view/pages/buyer/Payment'));
const ListViewQuery = React.lazy(
  () => import('~view/pages/common/ListViewQuery')
);
const ListViewCollection = React.lazy(
  () => import('~view/pages/common/ListViewCollection')
);
const FavoriteBrands = React.lazy(
  () => import('~view/pages/buyer/FavoriteBrands')
);
const FavoriteProducts = React.lazy(
  () => import('~view/pages/buyer/FavoriteProducts')
);
const CollectionDetail = React.lazy(
  () => import('~view/pages/common/CollectionDetail')
);
const BrandReferrals = React.lazy(
  () => import('~view/pages/buyer/BrandReferrals')
);
const BrandOrdered = React.lazy(() => import('~view/pages/buyer/BrandOrdered'));
const PointHistory = React.lazy(() => import('~view/pages/buyer/PointHistory'));
const DirectLink = React.lazy(() => import('~view/pages/common/DirectLInk'));
const Featured = React.lazy(() => import('~view/pages/buyer/Featured'));
const ListViewSubCategory = React.lazy(
  () => import('~view/pages/common/ListViewSubcategory')
);
const ListViewCategory = React.lazy(
  () => import('~view/pages/common/ListViewCategory')
);
const ListViewNewProducts = React.lazy(
  () => import('~view/pages/common/ListViewNewProducts')
);
const BrandList = React.lazy(() => import('~view/pages/common/BrandList'));
const HomePage = React.lazy(() => import('~view/pages/common/Home'));
const LoginedHome = React.lazy(() => import('~view/pages/common/LoginHome'));
const BuyerSignUp = React.lazy(() => import('~view/pages/buyer/BuyerSignUp'));
const BillingExamination = React.lazy(
  () => import('~view/pages/buyer/BillingExamination')
);
const BillingInfo = React.lazy(() => import('~view/pages/buyer/BillingInfo'));
const EmailPreferences = React.lazy(
  () => import('~view/pages/buyer/EmailPreferences')
);
const Exhibition = React.lazy(() => import('~view/pages/buyer/Exhibition'));
const Buyertermsofservice = React.lazy(
  () => import('~view/pages/common/BuyerTermsofService')
);
const MagicLink = React.lazy(() => import('~view/pages/common/MagicLink'));
const ChangePassword = React.lazy(
  () => import('~view/pages/common/ChangePassword')
);
const MagHome = React.lazy(() => import('~view/pages/mag/home'));
const MagStory = React.lazy(() => import('~view/pages/mag/story'));
const MagBrand = React.lazy(() => import('~view/pages/mag/brand'));

const BuyerMenu = React.memo(() => {
  const dispatch = useDispatch();
  // const menuList = useSelector(menuListSelector);
  const authUser = useSelector(authUserSelector);

  useEffect(() => {
    dispatch(getMenuList());
  }, []);

  useEffect(() => {
    if (!authUser) {
      dispatch(getTagList(true));
      return () => {
        dispatch(clearTagList());
      };
    }

    dispatch(getTagList(false));
    dispatch(listFeaturedContents());
    return () => {
      dispatch(clearTagList());
      dispatch(clearFeaturedContents());
    };
  }, [authUser]);

  return null;
});

const BuyerRoutes = () => {
  const projectId = useSelector(selectProjectId);

  return (
    <>
      <BuyerMenu />
      {!['mag'].includes(projectId ?? '') && (
        <PublicRoute
          exact
          path="/"
          title="homula（ホムラ）| 服飾雑貨・アパレルの仕入れに最適・ブランド向けオンラインマーケットプレイス"
          component={HomePage}
        />
      )}
      {!['mag'].includes(projectId ?? '') && (
        <Route exact path="/home" component={LoginedHome} />
      )}
      <PublicRoute
        path="/ComingSoon"
        title="Coming Soon"
        component={ComingSoon}
      />
      <PublicRoute
        path="/Maintenance"
        title="Maintenance"
        component={Maintenance}
      />
      <PublicRoute
        exact
        path="/registration/buyer"
        title="バイヤー会員登録フォーム"
        component={BuyerRegistrationHubs}
      />
      <PublicRoute
        exact
        path="/terms/buyertermsofservice"
        title="バイヤー利用規約"
        component={Buyertermsofservice}
      />
      <PublicRoute
        exact
        path="/terms/privacypolicy"
        title="プライバシーポリシー"
        component={PrivacyPolicy}
      />
      <PublicRoute
        exact
        path="/terms/returnpolicy"
        title="返品・引取りポリシー"
        component={ReturnPolicy}
      />
      <PublicRoute
        exact
        path="/direct/:brandId"
        title="homula | Direct"
        component={DirectLink}
      />
      <Route exact path="/sign-up" component={BuyerSignUp} />
      <Route path="/brand/:brand_id" component={ListViewBrand} />
      <BuyerRoute
        exact
        path="/favorite/brands"
        title="お気に入り | ブランド - buyer"
        component={FavoriteBrands}
      />
      <BuyerRoute
        exact
        path="/favorite/products"
        title="お気に入り | 商品 - buyer"
        component={FavoriteBrands}
      />
      <BuyerRoute
        exact
        path="/favorite/products/list"
        title="お気に入り | 商品 - buyer"
        component={FavoriteProducts}
      />
      <BuyerRoute exact path="/list/search" component={ListViewQuery} unAuth />
      <BuyerRoute
        exact
        path="/list/subcategory/:category/:subcategory/:subcategory2?"
        component={ListViewSubCategory}
        unAuth
      />
      <BuyerRoute
        exact
        path="/list/new-products/:category?"
        component={ListViewNewProducts}
        unAuth
      />
      <BuyerRoute
        exact
        path="/list/:category"
        excludePaths={['/list/search']}
        component={ListViewCategory}
        unAuth
      />
      <BuyerRoute
        exact
        path="/BrandListView/:category"
        component={BrandList}
        unAuth
      />
      <BuyerRoute exact path="/sale" component={ListViewSale} />
      <Route exact path={`/productdetail/:id`} component={ProductDetail} />
      <Route
        exact
        path={`/collection/:collectionId`}
        component={CollectionDetail}
      />
      <Route exact path={`/sale/:collectionId`} component={CollectionDetail} />
      <BuyerRoute
        exact
        path="/BrandListView/collection/:collection"
        component={ListViewCollection}
        unAuth
      />
      <Route exact path="/Account/AccountProfile" component={Profile} />
      <Route exact path="/Account/AccountProfileedit" component={Profileedit} />
      <Route exact path="/Account/ChangePassword" component={ChangePassword} />
      <BuyerRoute
        exact
        path="/Account/PaymentMethod"
        component={PaymentMethod}
      />
      <BuyerRoute
        exact
        path="/Account/ShippingAddress"
        component={ShippingAddress}
      />
      <BuyerRoute path="/Account/OrderHistory" component={OrderHistory} />
      <BuyerRoute
        exact
        path="/Account/payments/:status"
        component={Payments}
        title="お支払い明細 - Buyer"
      />
      <BuyerRoute
        exact
        path="/Account/payments/:status/:paymentId"
        component={Payment}
      />
      <BuyerRoute
        path="/Account/point"
        title="アカウント | ポイント履歴 - Buyer"
        component={PointHistory}
      />
      <PrivateRoute exact path="/messages" component={Messages} fullscreen />
      <BuyerRoute
        exact
        path="/cart"
        title="ショッピングカート - Buyer"
        component={ShoppingCart}
      />
      <BuyerRoute
        exact
        path="/CheckOut"
        title="配送先選択 - Buyer"
        component={CheckOut}
      />
      <BuyerRoute
        exact
        path="/PaymentTerm"
        title="お支払い方法選択 - Buyer"
        component={PaymentTerm}
      />
      <BuyerRoute
        exact
        path="/Confirmation"
        title="注文内容確認 - Buyer"
        component={OrderConfirmation}
      />
      <BuyerRoute
        exact
        path="/Complete"
        title="注文申請完了 - Buyer"
        component={OrderComplete}
      />
      <BuyerRoute
        exact
        path="/brand-referrals"
        title="ブランド紹介 - Buyer"
        component={BrandReferrals}
      />
      <BuyerRoute
        exact
        path="/my-brands"
        title="注文済みブランド - Buyer"
        component={BrandOrdered}
      />
      <BuyerRoute exact path="/features/:contentId" component={Featured} />
      <BuyerRoute
        exact
        path="/exhibitions/:contentId"
        component={Exhibition}
        unAuth
      />
      <BuyerRoute
        exact
        path="/Account/billing"
        title="請求先情報 - Buyer"
        component={BillingInfo}
      />
      <BuyerRoute
        exact
        path="/Account/billing-examination"
        title="請求書払いご利用申請フォーム - Buyer"
        component={BillingExamination}
      />
      <BuyerRoute
        exact
        path="/Account/email-preferences"
        title="メール配信設定 - Buyer"
        component={EmailPreferences}
      />
      <Route
        exact
        path="/magic-link/:email/:token"
        component={withTitle(MagicLink, 'マジックリンク - Buyer')}
      />
      {projectId === 'mag' && (
        <Route
          exact
          path="/:venue?"
          component={withTitle(
            MagHome,
            'MaG.＋(ﾏｸﾞﾌﾟﾗｽ)→Onlineでつなぐ BrandとBuyer | MaG.'
          )}
        />
      )}
      {projectId !== 'mag' && (
        <Route
          exact
          path="/mag/:venue?"
          component={withTitle(
            MagHome,
            'MaG.＋(ﾏｸﾞﾌﾟﾗｽ)→Onlineでつなぐ BrandとBuyer | MaG.'
          )}
        />
      )}
      <Route exact path="/mag/story/:brandId" component={MagStory} />
      <Route
        exact
        path="/mag/brand"
        component={withTitle(MagBrand, 'Brand | MaG.')}
      />
    </>
  );
};

export default BuyerRoutes;
