import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type referralNextTokenStateType = string | null;

const referralNextTokenSlice = createSlice({
  name: 'referralNextToken',
  initialState: null as referralNextTokenStateType,
  reducers: {
    setNextToken(_, { payload }: PayloadAction<string>) {
      return payload;
    },
    clearNextToken() {
      return null;
    },
  },
});

export const { setNextToken, clearNextToken } = referralNextTokenSlice.actions;
export default referralNextTokenSlice.reducer;
