import React, { useContext } from 'react';
import { QueryContext } from '..';
import Brands from './Brands';
import PopularQueries from './PopularQueries';

const QuerySuggestions: React.FC = () => {
  const { query } = useContext(QueryContext);
  if (!query) {
    return null;
  }
  return (
    <>
      <PopularQueries />
      <Brands />
    </>
  );
};

export default QuerySuggestions;
