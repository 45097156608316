import { createSelector } from 'reselect';
import { RootState } from '../../reducer';
import { Cart } from '~domain/order/Cart';
import { IndividualCart } from '~domain/order/IndividualCart';
import { selectProfile } from '~redux/auth/selectors/authUser';
import { buyerInfoSelector } from '~redux/buyer/selectors';
import { BuyerInfo } from '~redux/buyer/types';
import { makeSelectEnableCoupon } from '~redux/coupon/selectors';
import { makeSelectHasInventoryAlertByProducts } from '~redux/inventory';
import {
  selectInputPoints,
  makeSelectReferralPointsByBrands,
} from '~redux/points/selectors';
import { DiscountType } from '~types/api';

const cartSelector = (state: RootState) => state.cart;

export const cartItemsSelector = createSelector(cartSelector, (cart) =>
  cart.items.slice().sort((a, b) => (a.product_id < b.product_id ? -1 : 1))
);

export const cartLoadingSelector = createSelector(
  cartSelector,
  (cart) => cart.isLoading
);

export const cartItemsBrandIdSelector = createSelector(
  cartItemsSelector,
  (items) => items[0]?.product?.product_brand_id
);

export const cartSavedForLaterBrandIds = ({
  brandIds,
}: {
  brandIds: string[] | null;
}) =>
  createSelector(cartItemsSelector, (items) => {
    if (brandIds === null) return [];
    const savedForLaterBrandIds = items
      .filter((item) => item.saved_for_later)
      .map(({ brand_id }) => brand_id);
    return brandIds.filter((id) => savedForLaterBrandIds.includes(id));
  });

export const makeCartModelSelector = ({
  buyerInfo,
  brandId,
  isPayDirectly,
}: {
  buyerInfo: BuyerInfo;
  brandId?: string;
  isPayDirectly?: boolean;
}) =>
  createSelector(cartItemsSelector, selectProfile, (items, profile) => {
    if (profile === 'individual') {
      return IndividualCart.create(
        {
          items: brandId
            ? items.filter((item) => item.product.brand.id === brandId)
            : items,
        },
        buyerInfo
      ).filter({ isPayDirectly });
    }
    return Cart.create(
      {
        items: brandId
          ? items.filter((item) => item.product.brand.id === brandId)
          : items,
      },
      buyerInfo
    ).filter({ isPayDirectly });
  });

export const makeCartSegmentModelSelector = (
  buyerInfo: BuyerInfo,
  brandId: string
) =>
  createSelector(makeCartModelSelector({ buyerInfo, brandId }), (cart) =>
    cart.getSegmentByBrandId(brandId)
  );

export const cartProductsSelector = createSelector(
  cartSelector,
  (cart) => cart.products
);

export const cartProductSelector = createSelector(
  cartSelector,
  (cart) => cart.product
);

export const selectCartModel = createSelector(
  (state: RootState) => state,
  buyerInfoSelector,
  (state, buyerInfo) => makeCartModelSelector({ buyerInfo: buyerInfo! })(state)
);
export const selectCartBrands = createSelector(cartItemsSelector, (items) => [
  ...new Set(items.map((item) => item.product.product_brand_id)),
]);
export const selectCartSavedForLaterItems = createSelector(
  cartItemsSelector,
  (items) => items.filter((item) => item.saved_for_later)
);
export const selectCartSavedForLaterBrands = createSelector(
  selectCartSavedForLaterItems,
  (items) => [...new Set(items.map((item) => item.product.product_brand_id))]
);
export const selectInCartBrands = createSelector(
  selectCartBrands,
  selectCartSavedForLaterBrands,
  (brands, savedForLaterBrands) => [
    ...new Set(brands.filter((brand) => !savedForLaterBrands.includes(brand))),
  ]
);
export const selectOverMinimumBrands = (state: RootState) => {
  const brands = selectCartBrands(state);
  return brands.filter((brandId) => {
    const subtotal = makeSelectCartSubtotalByBrands([brandId!])(state);
    const minimum = makeSelectCartMinimumByBrand(brandId!)(state);
    return subtotal >= minimum;
  });
};
export const selectUnderMinimumBrands = createSelector(
  selectCartBrands,
  selectOverMinimumBrands,
  (brandIds, overMinimumBrands) =>
    brandIds.filter((brand) => !overMinimumBrands.includes(brand)) as string[]
);
export const makeSelectCartItemsByBrands = (brandIds: string[]) =>
  createSelector(cartItemsSelector, (items) =>
    items.filter((item) => brandIds.includes(item.product.product_brand_id!))
  );
export const makeSelectCartItemsByBrand = (brandId: string) =>
  makeSelectCartItemsByBrands([brandId]);
export const makeSelectCartItemsByProduct = (productId: string) =>
  createSelector(cartItemsSelector, (items) =>
    items.filter((item) => item.product_id === productId)
  );
export const makeSelectCartBrands = (
  underMinimum?: boolean,
  savedForLater?: boolean
) =>
  createSelector(
    underMinimum ? selectUnderMinimumBrands : selectOverMinimumBrands,
    savedForLater ? selectCartSavedForLaterBrands : selectInCartBrands,
    (baseBrands, filterBrands) =>
      baseBrands.filter((brand) => filterBrands.includes(brand)) as string[]
  );
export const makeSelectCartBrand = (brandId: string) =>
  createSelector(
    makeSelectCartModelByBrands([brandId]),
    (cart) => cart.segments[0].brand
  );
export const makeSelectCartModelByBrands = (brandIds: string[]) =>
  createSelector(
    buyerInfoSelector,
    cartItemsSelector,
    selectProfile,
    selectReceivedirectlyAtStore,
    (buyerInfo, items, profile, receivedirectlyAtStore) => {
      if (profile === 'individual') {
        return IndividualCart.create(
          {
            items: items.filter((item) =>
              brandIds.includes(item.product.product_brand_id!)
            ),
          },
          buyerInfo!,
          receivedirectlyAtStore
        );
      }
      return Cart.create(
        {
          items: items.filter((item) =>
            brandIds.includes(item.product.product_brand_id!)
          ),
        },
        buyerInfo!
      );
    }
  );
export const makeSelectCartSubtotalByBrands = (brandIds: string[]) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    (cart) => cart.subtotalPrice
  );
export const makeSelectCartRetailSubtotalByBrands = (brandIds: string[]) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    (cart) => cart.totalRetailPrice
  );
export const makeSelectCartHasOpenPriceByBrands = (brandIds: string[]) =>
  createSelector(makeSelectCartModelByBrands(brandIds), (cart) =>
    cart.hasOpenPrice()
  );
export const makeSelectCartOriginalShippingFeeByBrands = (brandIds: string[]) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    (cart) => cart.displayShippingFee
  );
export const makeSelectCartShippingFeeByBrands = (brandIds: string[]) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    (cart) => cart.shippingFee
  );
export const makeSelectCartHasPayDirectlyOrderItemByBrands = (
  brandIds: string[]
) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    (cart) => cart.hasPayDirectlyOrderItem
  );
export const makeSelectCartTaxByBrands = (brandIds: string[]) =>
  createSelector(makeSelectCartModelByBrands(brandIds), (cart) => cart.tax);
export const makeSelectCartDiscountAmountByBrand = (
  brandId: string,
  couponId?: string
) =>
  createSelector(
    makeSelectCartModelByBrands([brandId]),
    makeSelectEnableCoupon([brandId], couponId),
    (model, coupon) => {
      if (model.hasPayDirectlyOrderItem || !coupon) {
        return 0;
      }
      const amount = model.totalPrice;
      const discountAmount = Math.min(
        coupon.discount_type === DiscountType.PERCENTAGE
          ? Math.round(amount * ((coupon.discount_value ?? 0) / 100))
          : coupon.discount_type === DiscountType.AMOUNT
          ? Math.min(amount, coupon.discount_value ?? 0)
          : 0,
        coupon.maximum_amount ?? 0
      );
      return discountAmount;
    }
  );
export const makeSelectCartDiscountAmount = (
  brandIds: string[],
  couponId?: string
) =>
  createSelector(
    brandIds.map((brandId) =>
      makeSelectCartDiscountAmountByBrand(brandId, couponId)
    ),
    (...amounts) => amounts.reduce((prev, amount) => prev + amount, 0)
  );
export const makeSelectCartReferralPointsByBrands = (
  brandIds: string[],
  couponId?: string
) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    makeSelectCartDiscountAmount(brandIds, couponId),
    makeSelectReferralPointsByBrands(brandIds),
    (model, discountAmount, referralPoints) => {
      const discountedAmount = model.totalPrice - discountAmount;
      return Math.min(discountedAmount, referralPoints);
    }
  );
export const makeSelectCartAmountByBrands = (
  brandIds: string[],
  couponId?: string,
  notDiscounted?: boolean
) =>
  createSelector(
    makeSelectCartModelByBrands(brandIds),
    makeSelectCartDiscountAmount(brandIds, couponId),
    makeSelectCartReferralPointsByBrands(brandIds, couponId),
    selectInputPoints,
    (model, discountAmount, referralPoints, points) => {
      const amount = model.totalPrice;
      if (notDiscounted) {
        return amount;
      }
      return Math.max(
        amount - (discountAmount ?? 0) - (referralPoints ?? 0) - (points ?? 0),
        0
      );
    }
  );
export const makeSelectCartCashbackAmount = (
  brandIds: string[],
  couponId?: string
) =>
  createSelector(
    makeSelectCartAmountByBrands(brandIds),
    makeSelectEnableCoupon(brandIds, couponId),
    (amount, coupon) => {
      if (!coupon || coupon?.discount_type !== DiscountType.CASHBACK) {
        return 0;
      }
      return Math.min(
        Math.round(amount * (coupon.discount_value! / 100)),
        coupon.maximum_amount!
      );
    }
  );
export const makeSelectCartAlertByBrands = (brandIds: string[]) =>
  createSelector(
    (state: RootState) => state,
    makeSelectCartProductIdsByBrands(brandIds),
    (state, cartProductIds) => {
      const hasAlert = makeSelectHasInventoryAlertByProducts(cartProductIds)(
        state
      );
      return hasAlert;
    }
  );
export const selectOrderedBrands = createSelector(
  cartSelector,
  (cart) => cart.orderedBrands
);
export const makeSelectHasOrderedBrand = (brandId: string) =>
  createSelector(selectOrderedBrands, (orderedBrands) =>
    orderedBrands.includes(brandId)
  );
export const makeSelectCartMinimumByBrand = (brandId: string) =>
  createSelector(
    makeSelectCartBrand(brandId),
    makeSelectHasOrderedBrand(brandId),
    selectProfile,
    (brand, hasOrdered, profile) => {
      if (profile === 'individual') {
        return brand.individual_minimum_buy ?? brand.brand_minimum_buy ?? 0;
      }
      return (
        (hasOrdered
          ? brand.brand_additional_minimum_buy ?? brand.brand_minimum_buy
          : brand.brand_minimum_buy) ?? 0
      );
    }
  );
export const makeSelectCartSegmentsByBrand = (brandId: string) =>
  createSelector(
    makeSelectCartModelByBrands([brandId]),
    (cart) => cart.segments
  );
export const makeSelectCartProductsByBrands = (brandIds: string[]) =>
  createSelector(cartItemsSelector, (items) =>
    items.filter((item) => brandIds.includes(item.product.product_brand_id!))
  );
export const makeSelectCartProductIdsByBrands = (brandIds: string[]) =>
  createSelector(makeSelectCartProductsByBrands(brandIds), (items) =>
    items.map((item) => item.product_id)
  );
export const selectRelatedProducts = createSelector(
  cartSelector,
  (cart) => cart.relatedProducts
);
export const makeSelectRelatedProducts = (brandId: string) =>
  createSelector(selectRelatedProducts, (products) =>
    Object.values(products).filter(
      (product) => product.product_brand_id === brandId
    )
  );
export const selectCartQuantity = createSelector(cartItemsSelector, (items) =>
  items.reduce((prev, item) => prev + item.quantity, 0)
);
export const selectCartCollections = createSelector(
  cartItemsSelector,
  (items) => [
    ...new Set(
      items
        .filter((item) => item.collection_id)
        .map((item) => item.collection_id)
    ),
  ]
);

export const selectReceivedirectlyAtStore = createSelector(
  cartSelector,
  (cart) => cart.receivedirectlyAtStore
);
