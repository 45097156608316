import React from 'react';
import FullStory from 'react-fullstory';
import { useSelector } from 'react-redux';
import { authUserSelector } from '~redux/auth/selectors/authUser';

const FullStoryLogger: React.FC = () => {
  const user = useSelector(authUserSelector);

  if (!user || process.env.NODE_ENV !== 'production') {
    return null;
  }

  return <FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID!} />;
};

export default FullStoryLogger;
