import { createReducer } from '@reduxjs/toolkit';
import {
  clearOrder,
  clearOrders,
  pushOrders,
  setOrder,
  setOrders,
} from '../actions';
import { Order } from '~domain/order/Order';
import { Payload } from '~types/redux';

const initialState: Order[] = [];

const ordersReducer = createReducer(initialState, {
  [setOrders.type]: (_, { payload }: { payload: Payload<typeof setOrders> }) =>
    payload,
  [pushOrders.type]: (
    state,
    { payload }: { payload: Payload<typeof pushOrders> }
  ) => [...state, ...payload],
  [clearOrders.type]: () => initialState,
  [setOrder.type]: (
    orders,
    { payload }: { payload: Payload<typeof setOrder> }
  ) => {
    if (!orders.length) {
      return [payload];
    }
    const newOrders = orders.slice();
    const index = newOrders.findIndex(({ id }) => id === payload.id);
    if (index < 0) {
      return [...orders, payload];
    }
    newOrders.splice(index, 1, payload);
    return newOrders;
  },
  [clearOrder.type]: (
    state,
    { payload }: { payload: Payload<typeof clearOrder> }
  ) => state.filter(({ id }) => id !== payload),
});

export default ordersReducer;
