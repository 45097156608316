import './styles.modules.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../../layouts/footer/Footer';
import LoginHeader from '../../../layouts/header/LoginHeader';
import AuthContainer from '../AuthContainer';
import ErrorBoundary from '../ErrorBoundary';
import LoadingContainer from '../LoadingContainer';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { authenticatingSelector } from '~redux/auth/selectors/authenticating';
import { useTitle } from '~view/hooks/title';
import Head from '~view/pages/common/Head';

type AuthPageProps = {
  title?: string;
  fullscreen?: boolean;
};

const AuthPage: React.FC<AuthPageProps> = ({ children, fullscreen, title }) => {
  const authUser = useSelector(authUserSelector);
  const authenticating = useSelector(authenticatingSelector);

  useTitle(authUser && title ? title : undefined);

  if (authenticating) {
    return <LoadingContainer isLoading />;
  }

  if (!authUser) {
    return <AuthContainer />;
  }

  return (
    <>
      <Head />
      <div styleName={`${fullscreen ? 'fullscreen-app' : ''}`}>
        <LoginHeader />
        <ErrorBoundary>{children}</ErrorBoundary>
        {fullscreen || <Footer />}
      </div>
    </>
  );
};

export default AuthPage;
