import './styles.scss';
import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
//@ts-ignore
import HubspotForm from 'react-hubspot-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Modal, Spinner } from 'reactstrap';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
import { signin } from '~redux/auth/reducers/signin';
import {
  referralIdSelector,
  signUpPathSelector,
} from '~redux/auth/selectors/signup';

type SignUpModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSignup?: (email: string) => void;
};

const MagSignUpModal: React.FC<SignUpModalProps> = ({
  isOpen,
  toggle,
  onSignup,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExist, setIsExist] = useState(false);
  const nextPath = useSelector(signUpPathSelector);
  const referralId = useSelector(referralIdSelector);

  const checkExistUser = useCallback(async (email: string) => {
    try {
      await Auth.confirmSignUp(email, 'x', {
        forceAliasCreation: false,
      });
    } catch (e: any) {
      if (e.code === 'UserNotFoundException') {
        return false;
      }
      return true;
    }
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      if (
        event.data.type !== 'hsFormCallback' ||
        event.data.eventName !== 'onFormSubmit' ||
        event.data.id !== process.env.REACT_APP_HUBSPOT_MAG_SIGNUP_FORM_ID
      ) {
        return;
      }
      const email =
        event.data.data.email ??
        event.data.data?.find((data: any) => data.name === 'email')?.value;
      if (await checkExistUser(email)) {
        setIsExist(true);
        alert(
          '入力されたメールアドレスはすでに登録されています。下部のログインボタンを押してログインしてください。'
        );
        return;
      }
      if (onSignup) onSignup(email);
      history.push(
        `/sign-up?email=${email}${
          referralId ? `&referral_id=${encodeURIComponent(referralId)}` : ''
        }`
      );
    },
    [referralId]
  );

  const login = useCallback(() => {
    dispatch(signin(nextPath));
    toggle();
  }, [nextPath]);

  useEffect(() => {
    window.addEventListener('message', handleSubmit);
    return () => {
      window.removeEventListener('message', handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (isExist) {
      setIsExist(false);
    }
  }, [isExist]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} contentClassName="signup-modal p-5">
      {isExist ? (
        <Spinner />
      ) : (
        <>
          <div>
            <img style={{ width: '120px' }} src={logo} />
          </div>
          <div>
            <h5 className="font-weight-bold">アカウントを作成</h5>
            <div className="bg-light p-4 mb-4">
              <p className="font-weight-bold">
                homulaアカウント作成で以下の機能がご利用いただけます。
              </p>
              <ul className="text-left">
                <li>卸売価格・お取引条件の閲覧</li>
                <li>ブランドへのダイレクトメッセージ</li>
                <li>ブランドへの仕入れ発注</li>
              </ul>
            </div>
            <HubspotForm
              portalId="9130201"
              formId={process.env.REACT_APP_HUBSPOT_MAG_SIGNUP_FORM_ID}
              loading={<Spinner />}
              onFormSubmit={() => null}
            />
            <div className="border-top pt-4">
              <Button outline onClick={login} block>
                すでにアカウントをお持ちの場合はこちら
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MagSignUpModal;
