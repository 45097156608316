import moment from 'moment';
import * as mutations from '../../graphql/mutations';
import { Entity } from '~core/domain/Entity';
import { TReferral } from '~domain/referral/Referral';
import { Brand } from '~redux/brand/types';
import { Order } from '~redux/buyer/types';
import { CreatePointHistoryMutation } from '~types/api';
import { executeQuery } from '~utils/graphql';

export enum PointType {
  earnedByReferral = 10,
  earnedByEntry = 11,
  earnedByCampaign = 12,
  earnedByReview = 13,
  earnedByCashback = 14,
}

export const PointTypeLabel = {
  [PointType.earnedByReferral]: 'ブランド紹介ポイント',
  [PointType.earnedByEntry]: '新規会員登録特典',
  [PointType.earnedByCampaign]: 'プレゼントキャンペーン',
  [PointType.earnedByReview]: 'レビューポイント',
  [PointType.earnedByCashback]: 'キャッシュバックポイント',
};

export enum PointHistoryType {
  earn = 10,
  earnedByOrderEdit = 11,
  earnedByOrderCancel = 12,
  earnedByOrderReturn = 13,
  pay = 20,
}

export const PointHistoryTypeLabel = {
  [PointHistoryType.earn]: 'ポイント獲得',
  [PointHistoryType.earnedByOrderEdit]: '注文変更',
  [PointHistoryType.earnedByOrderCancel]: '注文キャンセル',
  [PointHistoryType.earnedByOrderReturn]: '返品',
  [PointHistoryType.pay]: 'ポイント利用',
};

export type TPoint = {
  id?: string;
  account_id: string;
  points: number;
  point_type: PointType;
  order_id?: string;
  referral_id?: string;
  referral_brand_id?: string;
  createdAt?: string;
  order?: Order;
  referral?: TReferral;
  brand?: Brand;
  duration: number;
  history?: TPointHistory[];
  review_id?: string;
};

export type TPointHistory = {
  id?: string;
  point_id: string;
  account_id: string;
  points: number;
  order_id?: string;
  createdAt?: string;
  order?: Order;
  history_type: PointHistoryType;
};

export default class Point extends Entity<TPoint> {
  private constructor(params: TPoint) {
    super(params);
  }

  public static createInstance(params: TPoint) {
    return new Point(params);
  }

  async earn() {
    //Pointテーブルを登録
    await executeQuery(mutations.createPoint, { input: this.toJson() });
  }

  async createHistory(
    history_type: PointHistoryType,
    points: number,
    order_id?: string
  ) {
    //PointHistoryを登録
    return await executeQuery<CreatePointHistoryMutation>(
      mutations.createPointHistory,
      {
        input: {
          account_id: this.account_id,
          point_id: this.id,
          points,
          history_type,
          order_id,
        },
      }
    );
  }

  async pay(payedPoints: number) {
    //Pointテーブルを更新
    await executeQuery(mutations.updatePoint, {
      input: { id: this.id, points: this.points - payedPoints },
    });
  }

  get expirationDate() {
    const createdAt = moment(this.createdAt).startOf('day');
    return createdAt.add(this.duration, 'days').endOf('day').toDate();
  }

  get isExpired() {
    const today = moment().startOf('day');
    return today.diff(this.expirationDate) > 0;
  }

  toJson(): TPoint {
    return {
      id: this.id,
      account_id: this.account_id,
      points: this.points,
      point_type: this.point_type,
      order_id: this.order_id,
      referral_id: this.referral_id,
      referral_brand_id: this.referral_brand_id,
      duration: this.duration,
      review_id: this.review_id,
    };
  }
}
