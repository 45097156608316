import { calculatePrice } from './price';
import { Product, ProductType } from '~redux/brand/types';
import { ProductType as TProductType } from '~types/api';

// deprecated
export const calculateProductPrice = (product: Product, quantity: number) =>
  calculatePrice(
    product.product_retail_price!,
    product.product_sale_wholesale_rate ?? product.product_wholesale_rate!
  ) * quantity;

export const getProductTypeString = (
  productType: ProductType | Omit<TProductType, '__typename'>
) =>
  productType.color && productType.size
    ? `${productType.color}／${productType.size}`
    : productType.color || productType.size || productType.type_name || '';
