import { CartItemBase, CartItemType } from './base/CartItemBase';
import { Product } from '~domain/product/Product';
import { CartItem as CartItemJson } from '~redux/cart/types';

export class CartItem extends CartItemBase {
  private constructor(params: CartItemType) {
    super(params);
  }

  static create({ product, ...cartItem }: CartItemJson) {
    return new CartItem({
      ...cartItem,
      product: Product.create(product),
    });
  }
}
