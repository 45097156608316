import { createAction } from '@reduxjs/toolkit';
import { Brand } from '../../types';

const ACTION_BASE_TYPE = 'SUPPLIER/BRAND';

export const setBrands = createAction<Brand[]>(
  `${ACTION_BASE_TYPE}/SET_BRANDS`
);

export const clearAllBrands = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_ALL_BRANDS`
);
