import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SigninStateType = {
  isSignin: boolean;
  nextPath?: string;
};

const signinSlice = createSlice({
  initialState: { isSignin: false } as SigninStateType,
  name: 'signin',
  reducers: {
    signin: (_, { payload }: PayloadAction<string | undefined>) => ({
      isSignin: true,
      nextPath: payload,
    }),
    clear: () => ({ isSignin: false }),
  },
});

export const { signin, clear } = signinSlice.actions;

export default signinSlice.reducer;
