import { combineReducers } from 'redux';
import addressReducer from './address';
import adminBuyersReducer from './adminBuyers';
import contacts from './contacts';
import infoReducer from './info';
import paymentMethodReducer from './paymentMethod';

export const buyerReducer = combineReducers({
  info: infoReducer,
  addresses: addressReducer,
  paymentMethod: paymentMethodReducer,
  adminBuyers: adminBuyersReducer,
  contacts,
});

export default buyerReducer;
