import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import { rootReducer } from './redux/reducer';
import queue from '~redux/queue';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => ({ authUser: state.authUser }),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [queue, thunk],
  enhancers: [sentryReduxEnhancer],
});
