/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/api";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateMessage = /* GraphQL */ `subscription OnCreateMessage($thread_id: ID!) {
  onCreateMessage(thread_id: $thread_id) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageSubscriptionVariables,
  APITypes.OnCreateMessageSubscription
>;
export const onUpdateMessage = /* GraphQL */ `subscription OnUpdateMessage($thread_id: ID!) {
  onUpdateMessage(thread_id: $thread_id) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMessageSubscriptionVariables,
  APITypes.OnUpdateMessageSubscription
>;
export const onDeleteMessage = /* GraphQL */ `subscription OnDeleteMessage($thread_id: ID!) {
  onDeleteMessage(thread_id: $thread_id) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageSubscriptionVariables,
  APITypes.OnDeleteMessageSubscription
>;
export const onCreateBrandOrder = /* GraphQL */ `subscription OnCreateBrandOrder($brand_owner: ID!) {
  onCreateBrandOrder(brand_owner: $brand_owner) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBrandOrderSubscriptionVariables,
  APITypes.OnCreateBrandOrderSubscription
>;
export const onCreateMessageRecipient = /* GraphQL */ `subscription OnCreateMessageRecipient($message_recipient: ID!) {
  onCreateMessageRecipient(message_recipient: $message_recipient) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageRecipientSubscriptionVariables,
  APITypes.OnCreateMessageRecipientSubscription
>;
export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount {
  onCreateAccount {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount {
  onUpdateAccount {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount {
  onDeleteAccount {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateBuyerInfo = /* GraphQL */ `subscription OnCreateBuyerInfo {
  onCreateBuyerInfo {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBuyerInfoSubscriptionVariables,
  APITypes.OnCreateBuyerInfoSubscription
>;
export const onUpdateBuyerInfo = /* GraphQL */ `subscription OnUpdateBuyerInfo {
  onUpdateBuyerInfo {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBuyerInfoSubscriptionVariables,
  APITypes.OnUpdateBuyerInfoSubscription
>;
export const onDeleteBuyerInfo = /* GraphQL */ `subscription OnDeleteBuyerInfo {
  onDeleteBuyerInfo {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBuyerInfoSubscriptionVariables,
  APITypes.OnDeleteBuyerInfoSubscription
>;
export const onCreateShopImage = /* GraphQL */ `subscription OnCreateShopImage {
  onCreateShopImage {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateShopImageSubscriptionVariables,
  APITypes.OnCreateShopImageSubscription
>;
export const onUpdateShopImage = /* GraphQL */ `subscription OnUpdateShopImage {
  onUpdateShopImage {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateShopImageSubscriptionVariables,
  APITypes.OnUpdateShopImageSubscription
>;
export const onDeleteShopImage = /* GraphQL */ `subscription OnDeleteShopImage {
  onDeleteShopImage {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteShopImageSubscriptionVariables,
  APITypes.OnDeleteShopImageSubscription
>;
export const onCreateIndividual = /* GraphQL */ `subscription OnCreateIndividual {
  onCreateIndividual {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateIndividualSubscriptionVariables,
  APITypes.OnCreateIndividualSubscription
>;
export const onUpdateIndividual = /* GraphQL */ `subscription OnUpdateIndividual {
  onUpdateIndividual {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateIndividualSubscriptionVariables,
  APITypes.OnUpdateIndividualSubscription
>;
export const onDeleteIndividual = /* GraphQL */ `subscription OnDeleteIndividual {
  onDeleteIndividual {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteIndividualSubscriptionVariables,
  APITypes.OnDeleteIndividualSubscription
>;
export const onCreateSupplierGroup = /* GraphQL */ `subscription OnCreateSupplierGroup {
  onCreateSupplierGroup {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSupplierGroupSubscriptionVariables,
  APITypes.OnCreateSupplierGroupSubscription
>;
export const onUpdateSupplierGroup = /* GraphQL */ `subscription OnUpdateSupplierGroup {
  onUpdateSupplierGroup {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSupplierGroupSubscriptionVariables,
  APITypes.OnUpdateSupplierGroupSubscription
>;
export const onDeleteSupplierGroup = /* GraphQL */ `subscription OnDeleteSupplierGroup {
  onDeleteSupplierGroup {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSupplierGroupSubscriptionVariables,
  APITypes.OnDeleteSupplierGroupSubscription
>;
export const onCreateSupplierInfo = /* GraphQL */ `subscription OnCreateSupplierInfo {
  onCreateSupplierInfo {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSupplierInfoSubscriptionVariables,
  APITypes.OnCreateSupplierInfoSubscription
>;
export const onUpdateSupplierInfo = /* GraphQL */ `subscription OnUpdateSupplierInfo {
  onUpdateSupplierInfo {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSupplierInfoSubscriptionVariables,
  APITypes.OnUpdateSupplierInfoSubscription
>;
export const onDeleteSupplierInfo = /* GraphQL */ `subscription OnDeleteSupplierInfo {
  onDeleteSupplierInfo {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSupplierInfoSubscriptionVariables,
  APITypes.OnDeleteSupplierInfoSubscription
>;
export const onCreateShippingAddress = /* GraphQL */ `subscription OnCreateShippingAddress {
  onCreateShippingAddress {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateShippingAddressSubscriptionVariables,
  APITypes.OnCreateShippingAddressSubscription
>;
export const onUpdateShippingAddress = /* GraphQL */ `subscription OnUpdateShippingAddress {
  onUpdateShippingAddress {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateShippingAddressSubscriptionVariables,
  APITypes.OnUpdateShippingAddressSubscription
>;
export const onDeleteShippingAddress = /* GraphQL */ `subscription OnDeleteShippingAddress {
  onDeleteShippingAddress {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteShippingAddressSubscriptionVariables,
  APITypes.OnDeleteShippingAddressSubscription
>;
export const onCreateEmailPreferences = /* GraphQL */ `subscription OnCreateEmailPreferences($owner: String) {
  onCreateEmailPreferences(owner: $owner) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEmailPreferencesSubscriptionVariables,
  APITypes.OnCreateEmailPreferencesSubscription
>;
export const onUpdateEmailPreferences = /* GraphQL */ `subscription OnUpdateEmailPreferences($owner: String) {
  onUpdateEmailPreferences(owner: $owner) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEmailPreferencesSubscriptionVariables,
  APITypes.OnUpdateEmailPreferencesSubscription
>;
export const onDeleteEmailPreferences = /* GraphQL */ `subscription OnDeleteEmailPreferences($owner: String) {
  onDeleteEmailPreferences(owner: $owner) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEmailPreferencesSubscriptionVariables,
  APITypes.OnDeleteEmailPreferencesSubscription
>;
export const onCreateProduct = /* GraphQL */ `subscription OnCreateProduct {
  onCreateProduct {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductSubscriptionVariables,
  APITypes.OnCreateProductSubscription
>;
export const onUpdateProduct = /* GraphQL */ `subscription OnUpdateProduct {
  onUpdateProduct {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductSubscriptionVariables,
  APITypes.OnUpdateProductSubscription
>;
export const onDeleteProduct = /* GraphQL */ `subscription OnDeleteProduct {
  onDeleteProduct {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductSubscriptionVariables,
  APITypes.OnDeleteProductSubscription
>;
export const onCreateProductImageKey = /* GraphQL */ `subscription OnCreateProductImageKey {
  onCreateProductImageKey {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductImageKeySubscriptionVariables,
  APITypes.OnCreateProductImageKeySubscription
>;
export const onUpdateProductImageKey = /* GraphQL */ `subscription OnUpdateProductImageKey {
  onUpdateProductImageKey {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductImageKeySubscriptionVariables,
  APITypes.OnUpdateProductImageKeySubscription
>;
export const onDeleteProductImageKey = /* GraphQL */ `subscription OnDeleteProductImageKey {
  onDeleteProductImageKey {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductImageKeySubscriptionVariables,
  APITypes.OnDeleteProductImageKeySubscription
>;
export const onCreateProductVideoKey = /* GraphQL */ `subscription OnCreateProductVideoKey {
  onCreateProductVideoKey {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductVideoKeySubscriptionVariables,
  APITypes.OnCreateProductVideoKeySubscription
>;
export const onUpdateProductVideoKey = /* GraphQL */ `subscription OnUpdateProductVideoKey {
  onUpdateProductVideoKey {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductVideoKeySubscriptionVariables,
  APITypes.OnUpdateProductVideoKeySubscription
>;
export const onDeleteProductVideoKey = /* GraphQL */ `subscription OnDeleteProductVideoKey {
  onDeleteProductVideoKey {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductVideoKeySubscriptionVariables,
  APITypes.OnDeleteProductVideoKeySubscription
>;
export const onCreateProductType = /* GraphQL */ `subscription OnCreateProductType {
  onCreateProductType {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductTypeSubscriptionVariables,
  APITypes.OnCreateProductTypeSubscription
>;
export const onUpdateProductType = /* GraphQL */ `subscription OnUpdateProductType {
  onUpdateProductType {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductTypeSubscriptionVariables,
  APITypes.OnUpdateProductTypeSubscription
>;
export const onDeleteProductType = /* GraphQL */ `subscription OnDeleteProductType {
  onDeleteProductType {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductTypeSubscriptionVariables,
  APITypes.OnDeleteProductTypeSubscription
>;
export const onCreateInventory = /* GraphQL */ `subscription OnCreateInventory {
  onCreateInventory {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInventorySubscriptionVariables,
  APITypes.OnCreateInventorySubscription
>;
export const onUpdateInventory = /* GraphQL */ `subscription OnUpdateInventory {
  onUpdateInventory {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInventorySubscriptionVariables,
  APITypes.OnUpdateInventorySubscription
>;
export const onDeleteInventory = /* GraphQL */ `subscription OnDeleteInventory {
  onDeleteInventory {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInventorySubscriptionVariables,
  APITypes.OnDeleteInventorySubscription
>;
export const onCreateReturnInventoryLog = /* GraphQL */ `subscription OnCreateReturnInventoryLog {
  onCreateReturnInventoryLog {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReturnInventoryLogSubscriptionVariables,
  APITypes.OnCreateReturnInventoryLogSubscription
>;
export const onUpdateReturnInventoryLog = /* GraphQL */ `subscription OnUpdateReturnInventoryLog {
  onUpdateReturnInventoryLog {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReturnInventoryLogSubscriptionVariables,
  APITypes.OnUpdateReturnInventoryLogSubscription
>;
export const onDeleteReturnInventoryLog = /* GraphQL */ `subscription OnDeleteReturnInventoryLog {
  onDeleteReturnInventoryLog {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReturnInventoryLogSubscriptionVariables,
  APITypes.OnDeleteReturnInventoryLogSubscription
>;
export const onCreateInventoryLog = /* GraphQL */ `subscription OnCreateInventoryLog {
  onCreateInventoryLog {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInventoryLogSubscriptionVariables,
  APITypes.OnCreateInventoryLogSubscription
>;
export const onUpdateInventoryLog = /* GraphQL */ `subscription OnUpdateInventoryLog {
  onUpdateInventoryLog {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInventoryLogSubscriptionVariables,
  APITypes.OnUpdateInventoryLogSubscription
>;
export const onDeleteInventoryLog = /* GraphQL */ `subscription OnDeleteInventoryLog {
  onDeleteInventoryLog {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInventoryLogSubscriptionVariables,
  APITypes.OnDeleteInventoryLogSubscription
>;
export const onCreateInStockNotification = /* GraphQL */ `subscription OnCreateInStockNotification {
  onCreateInStockNotification {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInStockNotificationSubscriptionVariables,
  APITypes.OnCreateInStockNotificationSubscription
>;
export const onUpdateInStockNotification = /* GraphQL */ `subscription OnUpdateInStockNotification {
  onUpdateInStockNotification {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInStockNotificationSubscriptionVariables,
  APITypes.OnUpdateInStockNotificationSubscription
>;
export const onDeleteInStockNotification = /* GraphQL */ `subscription OnDeleteInStockNotification {
  onDeleteInStockNotification {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInStockNotificationSubscriptionVariables,
  APITypes.OnDeleteInStockNotificationSubscription
>;
export const onCreateBrand = /* GraphQL */ `subscription OnCreateBrand {
  onCreateBrand {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBrandSubscriptionVariables,
  APITypes.OnCreateBrandSubscription
>;
export const onUpdateBrand = /* GraphQL */ `subscription OnUpdateBrand {
  onUpdateBrand {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBrandSubscriptionVariables,
  APITypes.OnUpdateBrandSubscription
>;
export const onDeleteBrand = /* GraphQL */ `subscription OnDeleteBrand {
  onDeleteBrand {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBrandSubscriptionVariables,
  APITypes.OnDeleteBrandSubscription
>;
export const onCreateTag = /* GraphQL */ `subscription OnCreateTag {
  onCreateTag {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTagSubscriptionVariables,
  APITypes.OnCreateTagSubscription
>;
export const onUpdateTag = /* GraphQL */ `subscription OnUpdateTag {
  onUpdateTag {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTagSubscriptionVariables,
  APITypes.OnUpdateTagSubscription
>;
export const onDeleteTag = /* GraphQL */ `subscription OnDeleteTag {
  onDeleteTag {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTagSubscriptionVariables,
  APITypes.OnDeleteTagSubscription
>;
export const onCreateBrandCollection = /* GraphQL */ `subscription OnCreateBrandCollection {
  onCreateBrandCollection {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBrandCollectionSubscriptionVariables,
  APITypes.OnCreateBrandCollectionSubscription
>;
export const onUpdateBrandCollection = /* GraphQL */ `subscription OnUpdateBrandCollection {
  onUpdateBrandCollection {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBrandCollectionSubscriptionVariables,
  APITypes.OnUpdateBrandCollectionSubscription
>;
export const onDeleteBrandCollection = /* GraphQL */ `subscription OnDeleteBrandCollection {
  onDeleteBrandCollection {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBrandCollectionSubscriptionVariables,
  APITypes.OnDeleteBrandCollectionSubscription
>;
export const onCreateShopCart = /* GraphQL */ `subscription OnCreateShopCart($owner: String) {
  onCreateShopCart(owner: $owner) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateShopCartSubscriptionVariables,
  APITypes.OnCreateShopCartSubscription
>;
export const onUpdateShopCart = /* GraphQL */ `subscription OnUpdateShopCart($owner: String) {
  onUpdateShopCart(owner: $owner) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateShopCartSubscriptionVariables,
  APITypes.OnUpdateShopCartSubscription
>;
export const onDeleteShopCart = /* GraphQL */ `subscription OnDeleteShopCart($owner: String) {
  onDeleteShopCart(owner: $owner) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteShopCartSubscriptionVariables,
  APITypes.OnDeleteShopCartSubscription
>;
export const onCreateOrder = /* GraphQL */ `subscription OnCreateOrder($owners: String) {
  onCreateOrder(owners: $owners) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderSubscriptionVariables,
  APITypes.OnCreateOrderSubscription
>;
export const onUpdateOrder = /* GraphQL */ `subscription OnUpdateOrder($owners: String) {
  onUpdateOrder(owners: $owners) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderSubscriptionVariables,
  APITypes.OnUpdateOrderSubscription
>;
export const onDeleteOrder = /* GraphQL */ `subscription OnDeleteOrder($owners: String) {
  onDeleteOrder(owners: $owners) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderSubscriptionVariables,
  APITypes.OnDeleteOrderSubscription
>;
export const onCreateChargeStatus = /* GraphQL */ `subscription OnCreateChargeStatus($owners: String) {
  onCreateChargeStatus(owners: $owners) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChargeStatusSubscriptionVariables,
  APITypes.OnCreateChargeStatusSubscription
>;
export const onUpdateChargeStatus = /* GraphQL */ `subscription OnUpdateChargeStatus($owners: String) {
  onUpdateChargeStatus(owners: $owners) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChargeStatusSubscriptionVariables,
  APITypes.OnUpdateChargeStatusSubscription
>;
export const onDeleteChargeStatus = /* GraphQL */ `subscription OnDeleteChargeStatus($owners: String) {
  onDeleteChargeStatus(owners: $owners) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChargeStatusSubscriptionVariables,
  APITypes.OnDeleteChargeStatusSubscription
>;
export const onCreateOrderProduct = /* GraphQL */ `subscription OnCreateOrderProduct($owners: String) {
  onCreateOrderProduct(owners: $owners) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderProductSubscriptionVariables,
  APITypes.OnCreateOrderProductSubscription
>;
export const onUpdateOrderProduct = /* GraphQL */ `subscription OnUpdateOrderProduct($owners: String) {
  onUpdateOrderProduct(owners: $owners) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderProductSubscriptionVariables,
  APITypes.OnUpdateOrderProductSubscription
>;
export const onDeleteOrderProduct = /* GraphQL */ `subscription OnDeleteOrderProduct($owners: String) {
  onDeleteOrderProduct(owners: $owners) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderProductSubscriptionVariables,
  APITypes.OnDeleteOrderProductSubscription
>;
export const onCreateOrderStatus = /* GraphQL */ `subscription OnCreateOrderStatus($owners: String) {
  onCreateOrderStatus(owners: $owners) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderStatusSubscriptionVariables,
  APITypes.OnCreateOrderStatusSubscription
>;
export const onUpdateOrderStatus = /* GraphQL */ `subscription OnUpdateOrderStatus($owners: String) {
  onUpdateOrderStatus(owners: $owners) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderStatusSubscriptionVariables,
  APITypes.OnUpdateOrderStatusSubscription
>;
export const onDeleteOrderStatus = /* GraphQL */ `subscription OnDeleteOrderStatus($owners: String) {
  onDeleteOrderStatus(owners: $owners) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderStatusSubscriptionVariables,
  APITypes.OnDeleteOrderStatusSubscription
>;
export const onCreateReturnProduct = /* GraphQL */ `subscription OnCreateReturnProduct($owners: String) {
  onCreateReturnProduct(owners: $owners) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReturnProductSubscriptionVariables,
  APITypes.OnCreateReturnProductSubscription
>;
export const onUpdateReturnProduct = /* GraphQL */ `subscription OnUpdateReturnProduct($owners: String) {
  onUpdateReturnProduct(owners: $owners) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReturnProductSubscriptionVariables,
  APITypes.OnUpdateReturnProductSubscription
>;
export const onDeleteReturnProduct = /* GraphQL */ `subscription OnDeleteReturnProduct($owners: String) {
  onDeleteReturnProduct(owners: $owners) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReturnProductSubscriptionVariables,
  APITypes.OnDeleteReturnProductSubscription
>;
export const onCreateReportProduct = /* GraphQL */ `subscription OnCreateReportProduct($owners: String) {
  onCreateReportProduct(owners: $owners) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReportProductSubscriptionVariables,
  APITypes.OnCreateReportProductSubscription
>;
export const onUpdateReportProduct = /* GraphQL */ `subscription OnUpdateReportProduct($owners: String) {
  onUpdateReportProduct(owners: $owners) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReportProductSubscriptionVariables,
  APITypes.OnUpdateReportProductSubscription
>;
export const onDeleteReportProduct = /* GraphQL */ `subscription OnDeleteReportProduct($owners: String) {
  onDeleteReportProduct(owners: $owners) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReportProductSubscriptionVariables,
  APITypes.OnDeleteReportProductSubscription
>;
export const onCreateFeedback = /* GraphQL */ `subscription OnCreateFeedback {
  onCreateFeedback {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeedbackSubscriptionVariables,
  APITypes.OnCreateFeedbackSubscription
>;
export const onUpdateFeedback = /* GraphQL */ `subscription OnUpdateFeedback {
  onUpdateFeedback {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeedbackSubscriptionVariables,
  APITypes.OnUpdateFeedbackSubscription
>;
export const onDeleteFeedback = /* GraphQL */ `subscription OnDeleteFeedback {
  onDeleteFeedback {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeedbackSubscriptionVariables,
  APITypes.OnDeleteFeedbackSubscription
>;
export const onCreateOrderEditReason = /* GraphQL */ `subscription OnCreateOrderEditReason {
  onCreateOrderEditReason {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderEditReasonSubscriptionVariables,
  APITypes.OnCreateOrderEditReasonSubscription
>;
export const onUpdateOrderEditReason = /* GraphQL */ `subscription OnUpdateOrderEditReason {
  onUpdateOrderEditReason {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderEditReasonSubscriptionVariables,
  APITypes.OnUpdateOrderEditReasonSubscription
>;
export const onDeleteOrderEditReason = /* GraphQL */ `subscription OnDeleteOrderEditReason {
  onDeleteOrderEditReason {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderEditReasonSubscriptionVariables,
  APITypes.OnDeleteOrderEditReasonSubscription
>;
export const onCreateOrderCancelReason = /* GraphQL */ `subscription OnCreateOrderCancelReason {
  onCreateOrderCancelReason {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderCancelReasonSubscriptionVariables,
  APITypes.OnCreateOrderCancelReasonSubscription
>;
export const onUpdateOrderCancelReason = /* GraphQL */ `subscription OnUpdateOrderCancelReason {
  onUpdateOrderCancelReason {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderCancelReasonSubscriptionVariables,
  APITypes.OnUpdateOrderCancelReasonSubscription
>;
export const onDeleteOrderCancelReason = /* GraphQL */ `subscription OnDeleteOrderCancelReason {
  onDeleteOrderCancelReason {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderCancelReasonSubscriptionVariables,
  APITypes.OnDeleteOrderCancelReasonSubscription
>;
export const onCreateThread = /* GraphQL */ `subscription OnCreateThread($owners: String) {
  onCreateThread(owners: $owners) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateThreadSubscriptionVariables,
  APITypes.OnCreateThreadSubscription
>;
export const onUpdateThread = /* GraphQL */ `subscription OnUpdateThread($owners: String) {
  onUpdateThread(owners: $owners) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateThreadSubscriptionVariables,
  APITypes.OnUpdateThreadSubscription
>;
export const onDeleteThread = /* GraphQL */ `subscription OnDeleteThread($owners: String) {
  onDeleteThread(owners: $owners) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteThreadSubscriptionVariables,
  APITypes.OnDeleteThreadSubscription
>;
export const onCreatePickupProduct = /* GraphQL */ `subscription OnCreatePickupProduct {
  onCreatePickupProduct {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePickupProductSubscriptionVariables,
  APITypes.OnCreatePickupProductSubscription
>;
export const onUpdatePickupProduct = /* GraphQL */ `subscription OnUpdatePickupProduct {
  onUpdatePickupProduct {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePickupProductSubscriptionVariables,
  APITypes.OnUpdatePickupProductSubscription
>;
export const onDeletePickupProduct = /* GraphQL */ `subscription OnDeletePickupProduct {
  onDeletePickupProduct {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePickupProductSubscriptionVariables,
  APITypes.OnDeletePickupProductSubscription
>;
export const onCreatePickupBrands = /* GraphQL */ `subscription OnCreatePickupBrands {
  onCreatePickupBrands {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePickupBrandsSubscriptionVariables,
  APITypes.OnCreatePickupBrandsSubscription
>;
export const onUpdatePickupBrands = /* GraphQL */ `subscription OnUpdatePickupBrands {
  onUpdatePickupBrands {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePickupBrandsSubscriptionVariables,
  APITypes.OnUpdatePickupBrandsSubscription
>;
export const onDeletePickupBrands = /* GraphQL */ `subscription OnDeletePickupBrands {
  onDeletePickupBrands {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePickupBrandsSubscriptionVariables,
  APITypes.OnDeletePickupBrandsSubscription
>;
export const onCreateMenu = /* GraphQL */ `subscription OnCreateMenu {
  onCreateMenu {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMenuSubscriptionVariables,
  APITypes.OnCreateMenuSubscription
>;
export const onUpdateMenu = /* GraphQL */ `subscription OnUpdateMenu {
  onUpdateMenu {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMenuSubscriptionVariables,
  APITypes.OnUpdateMenuSubscription
>;
export const onDeleteMenu = /* GraphQL */ `subscription OnDeleteMenu {
  onDeleteMenu {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMenuSubscriptionVariables,
  APITypes.OnDeleteMenuSubscription
>;
export const onCreateFavoriteBrand = /* GraphQL */ `subscription OnCreateFavoriteBrand($owner: String) {
  onCreateFavoriteBrand(owner: $owner) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFavoriteBrandSubscriptionVariables,
  APITypes.OnCreateFavoriteBrandSubscription
>;
export const onUpdateFavoriteBrand = /* GraphQL */ `subscription OnUpdateFavoriteBrand($owner: String) {
  onUpdateFavoriteBrand(owner: $owner) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFavoriteBrandSubscriptionVariables,
  APITypes.OnUpdateFavoriteBrandSubscription
>;
export const onDeleteFavoriteBrand = /* GraphQL */ `subscription OnDeleteFavoriteBrand($owner: String) {
  onDeleteFavoriteBrand(owner: $owner) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFavoriteBrandSubscriptionVariables,
  APITypes.OnDeleteFavoriteBrandSubscription
>;
export const onCreateFavoriteProduct = /* GraphQL */ `subscription OnCreateFavoriteProduct($owner: String) {
  onCreateFavoriteProduct(owner: $owner) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFavoriteProductSubscriptionVariables,
  APITypes.OnCreateFavoriteProductSubscription
>;
export const onUpdateFavoriteProduct = /* GraphQL */ `subscription OnUpdateFavoriteProduct($owner: String) {
  onUpdateFavoriteProduct(owner: $owner) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFavoriteProductSubscriptionVariables,
  APITypes.OnUpdateFavoriteProductSubscription
>;
export const onDeleteFavoriteProduct = /* GraphQL */ `subscription OnDeleteFavoriteProduct($owner: String) {
  onDeleteFavoriteProduct(owner: $owner) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFavoriteProductSubscriptionVariables,
  APITypes.OnDeleteFavoriteProductSubscription
>;
export const onCreateFavoriteProductList = /* GraphQL */ `subscription OnCreateFavoriteProductList($owner: String) {
  onCreateFavoriteProductList(owner: $owner) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFavoriteProductListSubscriptionVariables,
  APITypes.OnCreateFavoriteProductListSubscription
>;
export const onUpdateFavoriteProductList = /* GraphQL */ `subscription OnUpdateFavoriteProductList($owner: String) {
  onUpdateFavoriteProductList(owner: $owner) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFavoriteProductListSubscriptionVariables,
  APITypes.OnUpdateFavoriteProductListSubscription
>;
export const onDeleteFavoriteProductList = /* GraphQL */ `subscription OnDeleteFavoriteProductList($owner: String) {
  onDeleteFavoriteProductList(owner: $owner) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFavoriteProductListSubscriptionVariables,
  APITypes.OnDeleteFavoriteProductListSubscription
>;
export const onCreateCollection = /* GraphQL */ `subscription OnCreateCollection {
  onCreateCollection {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCollectionSubscriptionVariables,
  APITypes.OnCreateCollectionSubscription
>;
export const onUpdateCollection = /* GraphQL */ `subscription OnUpdateCollection {
  onUpdateCollection {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCollectionSubscriptionVariables,
  APITypes.OnUpdateCollectionSubscription
>;
export const onDeleteCollection = /* GraphQL */ `subscription OnDeleteCollection {
  onDeleteCollection {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCollectionSubscriptionVariables,
  APITypes.OnDeleteCollectionSubscription
>;
export const onCreateProductCollection = /* GraphQL */ `subscription OnCreateProductCollection {
  onCreateProductCollection {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductCollectionSubscriptionVariables,
  APITypes.OnCreateProductCollectionSubscription
>;
export const onUpdateProductCollection = /* GraphQL */ `subscription OnUpdateProductCollection {
  onUpdateProductCollection {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductCollectionSubscriptionVariables,
  APITypes.OnUpdateProductCollectionSubscription
>;
export const onDeleteProductCollection = /* GraphQL */ `subscription OnDeleteProductCollection {
  onDeleteProductCollection {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductCollectionSubscriptionVariables,
  APITypes.OnDeleteProductCollectionSubscription
>;
export const onCreateReferral = /* GraphQL */ `subscription OnCreateReferral($owner: String, $referral_account_id: String) {
  onCreateReferral(owner: $owner, referral_account_id: $referral_account_id) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReferralSubscriptionVariables,
  APITypes.OnCreateReferralSubscription
>;
export const onUpdateReferral = /* GraphQL */ `subscription OnUpdateReferral($owner: String, $referral_account_id: String) {
  onUpdateReferral(owner: $owner, referral_account_id: $referral_account_id) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReferralSubscriptionVariables,
  APITypes.OnUpdateReferralSubscription
>;
export const onDeleteReferral = /* GraphQL */ `subscription OnDeleteReferral($owner: String, $referral_account_id: String) {
  onDeleteReferral(owner: $owner, referral_account_id: $referral_account_id) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReferralSubscriptionVariables,
  APITypes.OnDeleteReferralSubscription
>;
export const onCreateReferralMessage = /* GraphQL */ `subscription OnCreateReferralMessage($owner: String) {
  onCreateReferralMessage(owner: $owner) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReferralMessageSubscriptionVariables,
  APITypes.OnCreateReferralMessageSubscription
>;
export const onUpdateReferralMessage = /* GraphQL */ `subscription OnUpdateReferralMessage($owner: String) {
  onUpdateReferralMessage(owner: $owner) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReferralMessageSubscriptionVariables,
  APITypes.OnUpdateReferralMessageSubscription
>;
export const onDeleteReferralMessage = /* GraphQL */ `subscription OnDeleteReferralMessage($owner: String) {
  onDeleteReferralMessage(owner: $owner) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReferralMessageSubscriptionVariables,
  APITypes.OnDeleteReferralMessageSubscription
>;
export const onCreatePoint = /* GraphQL */ `subscription OnCreatePoint($account_id: String) {
  onCreatePoint(account_id: $account_id) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePointSubscriptionVariables,
  APITypes.OnCreatePointSubscription
>;
export const onUpdatePoint = /* GraphQL */ `subscription OnUpdatePoint($account_id: String) {
  onUpdatePoint(account_id: $account_id) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePointSubscriptionVariables,
  APITypes.OnUpdatePointSubscription
>;
export const onDeletePoint = /* GraphQL */ `subscription OnDeletePoint($account_id: String) {
  onDeletePoint(account_id: $account_id) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePointSubscriptionVariables,
  APITypes.OnDeletePointSubscription
>;
export const onCreatePointHistory = /* GraphQL */ `subscription OnCreatePointHistory($account_id: String) {
  onCreatePointHistory(account_id: $account_id) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePointHistorySubscriptionVariables,
  APITypes.OnCreatePointHistorySubscription
>;
export const onUpdatePointHistory = /* GraphQL */ `subscription OnUpdatePointHistory($account_id: String) {
  onUpdatePointHistory(account_id: $account_id) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePointHistorySubscriptionVariables,
  APITypes.OnUpdatePointHistorySubscription
>;
export const onDeletePointHistory = /* GraphQL */ `subscription OnDeletePointHistory($account_id: String) {
  onDeletePointHistory(account_id: $account_id) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePointHistorySubscriptionVariables,
  APITypes.OnDeletePointHistorySubscription
>;
export const onCreateContact = /* GraphQL */ `subscription OnCreateContact($owner: String, $buyer_owner: String) {
  onCreateContact(owner: $owner, buyer_owner: $buyer_owner) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContactSubscriptionVariables,
  APITypes.OnCreateContactSubscription
>;
export const onUpdateContact = /* GraphQL */ `subscription OnUpdateContact($owner: String, $buyer_owner: String) {
  onUpdateContact(owner: $owner, buyer_owner: $buyer_owner) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContactSubscriptionVariables,
  APITypes.OnUpdateContactSubscription
>;
export const onDeleteContact = /* GraphQL */ `subscription OnDeleteContact($owner: String, $buyer_owner: String) {
  onDeleteContact(owner: $owner, buyer_owner: $buyer_owner) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContactSubscriptionVariables,
  APITypes.OnDeleteContactSubscription
>;
export const onCreateContactList = /* GraphQL */ `subscription OnCreateContactList {
  onCreateContactList {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContactListSubscriptionVariables,
  APITypes.OnCreateContactListSubscription
>;
export const onUpdateContactList = /* GraphQL */ `subscription OnUpdateContactList {
  onUpdateContactList {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContactListSubscriptionVariables,
  APITypes.OnUpdateContactListSubscription
>;
export const onDeleteContactList = /* GraphQL */ `subscription OnDeleteContactList {
  onDeleteContactList {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContactListSubscriptionVariables,
  APITypes.OnDeleteContactListSubscription
>;
export const onCreateContactListConnection = /* GraphQL */ `subscription OnCreateContactListConnection {
  onCreateContactListConnection {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContactListConnectionSubscriptionVariables,
  APITypes.OnCreateContactListConnectionSubscription
>;
export const onUpdateContactListConnection = /* GraphQL */ `subscription OnUpdateContactListConnection {
  onUpdateContactListConnection {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContactListConnectionSubscriptionVariables,
  APITypes.OnUpdateContactListConnectionSubscription
>;
export const onDeleteContactListConnection = /* GraphQL */ `subscription OnDeleteContactListConnection {
  onDeleteContactListConnection {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContactListConnectionSubscriptionVariables,
  APITypes.OnDeleteContactListConnectionSubscription
>;
export const onCreateContactActivity = /* GraphQL */ `subscription OnCreateContactActivity($owner: String) {
  onCreateContactActivity(owner: $owner) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContactActivitySubscriptionVariables,
  APITypes.OnCreateContactActivitySubscription
>;
export const onUpdateContactActivity = /* GraphQL */ `subscription OnUpdateContactActivity($owner: String) {
  onUpdateContactActivity(owner: $owner) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContactActivitySubscriptionVariables,
  APITypes.OnUpdateContactActivitySubscription
>;
export const onDeleteContactActivity = /* GraphQL */ `subscription OnDeleteContactActivity($owner: String) {
  onDeleteContactActivity(owner: $owner) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContactActivitySubscriptionVariables,
  APITypes.OnDeleteContactActivitySubscription
>;
export const onCreateCampaign = /* GraphQL */ `subscription OnCreateCampaign($owner: String) {
  onCreateCampaign(owner: $owner) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCampaignSubscriptionVariables,
  APITypes.OnCreateCampaignSubscription
>;
export const onUpdateCampaign = /* GraphQL */ `subscription OnUpdateCampaign($owner: String) {
  onUpdateCampaign(owner: $owner) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCampaignSubscriptionVariables,
  APITypes.OnUpdateCampaignSubscription
>;
export const onDeleteCampaign = /* GraphQL */ `subscription OnDeleteCampaign($owner: String) {
  onDeleteCampaign(owner: $owner) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCampaignSubscriptionVariables,
  APITypes.OnDeleteCampaignSubscription
>;
export const onCreateCampaignContact = /* GraphQL */ `subscription OnCreateCampaignContact($owner: String) {
  onCreateCampaignContact(owner: $owner) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCampaignContactSubscriptionVariables,
  APITypes.OnCreateCampaignContactSubscription
>;
export const onUpdateCampaignContact = /* GraphQL */ `subscription OnUpdateCampaignContact($owner: String) {
  onUpdateCampaignContact(owner: $owner) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCampaignContactSubscriptionVariables,
  APITypes.OnUpdateCampaignContactSubscription
>;
export const onDeleteCampaignContact = /* GraphQL */ `subscription OnDeleteCampaignContact($owner: String) {
  onDeleteCampaignContact(owner: $owner) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCampaignContactSubscriptionVariables,
  APITypes.OnDeleteCampaignContactSubscription
>;
export const onCreateDailySession = /* GraphQL */ `subscription OnCreateDailySession {
  onCreateDailySession {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDailySessionSubscriptionVariables,
  APITypes.OnCreateDailySessionSubscription
>;
export const onUpdateDailySession = /* GraphQL */ `subscription OnUpdateDailySession {
  onUpdateDailySession {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDailySessionSubscriptionVariables,
  APITypes.OnUpdateDailySessionSubscription
>;
export const onDeleteDailySession = /* GraphQL */ `subscription OnDeleteDailySession {
  onDeleteDailySession {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDailySessionSubscriptionVariables,
  APITypes.OnDeleteDailySessionSubscription
>;
export const onCreateConversionRate = /* GraphQL */ `subscription OnCreateConversionRate {
  onCreateConversionRate {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConversionRateSubscriptionVariables,
  APITypes.OnCreateConversionRateSubscription
>;
export const onUpdateConversionRate = /* GraphQL */ `subscription OnUpdateConversionRate {
  onUpdateConversionRate {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConversionRateSubscriptionVariables,
  APITypes.OnUpdateConversionRateSubscription
>;
export const onDeleteConversionRate = /* GraphQL */ `subscription OnDeleteConversionRate {
  onDeleteConversionRate {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConversionRateSubscriptionVariables,
  APITypes.OnDeleteConversionRateSubscription
>;
export const onCreatePageView = /* GraphQL */ `subscription OnCreatePageView($owner: String) {
  onCreatePageView(owner: $owner) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePageViewSubscriptionVariables,
  APITypes.OnCreatePageViewSubscription
>;
export const onUpdatePageView = /* GraphQL */ `subscription OnUpdatePageView($owner: String) {
  onUpdatePageView(owner: $owner) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePageViewSubscriptionVariables,
  APITypes.OnUpdatePageViewSubscription
>;
export const onDeletePageView = /* GraphQL */ `subscription OnDeletePageView($owner: String) {
  onDeletePageView(owner: $owner) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePageViewSubscriptionVariables,
  APITypes.OnDeletePageViewSubscription
>;
export const onCreateReview = /* GraphQL */ `subscription OnCreateReview {
  onCreateReview {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewSubscriptionVariables,
  APITypes.OnCreateReviewSubscription
>;
export const onUpdateReview = /* GraphQL */ `subscription OnUpdateReview {
  onUpdateReview {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewSubscriptionVariables,
  APITypes.OnUpdateReviewSubscription
>;
export const onDeleteReview = /* GraphQL */ `subscription OnDeleteReview {
  onDeleteReview {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewSubscriptionVariables,
  APITypes.OnDeleteReviewSubscription
>;
export const onCreateReviewReply = /* GraphQL */ `subscription OnCreateReviewReply {
  onCreateReviewReply {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewReplySubscriptionVariables,
  APITypes.OnCreateReviewReplySubscription
>;
export const onUpdateReviewReply = /* GraphQL */ `subscription OnUpdateReviewReply {
  onUpdateReviewReply {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewReplySubscriptionVariables,
  APITypes.OnUpdateReviewReplySubscription
>;
export const onDeleteReviewReply = /* GraphQL */ `subscription OnDeleteReviewReply {
  onDeleteReviewReply {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewReplySubscriptionVariables,
  APITypes.OnDeleteReviewReplySubscription
>;
export const onCreateCoupon = /* GraphQL */ `subscription OnCreateCoupon {
  onCreateCoupon {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCouponSubscriptionVariables,
  APITypes.OnCreateCouponSubscription
>;
export const onUpdateCoupon = /* GraphQL */ `subscription OnUpdateCoupon {
  onUpdateCoupon {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCouponSubscriptionVariables,
  APITypes.OnUpdateCouponSubscription
>;
export const onDeleteCoupon = /* GraphQL */ `subscription OnDeleteCoupon {
  onDeleteCoupon {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCouponSubscriptionVariables,
  APITypes.OnDeleteCouponSubscription
>;
export const onCreateCouponLog = /* GraphQL */ `subscription OnCreateCouponLog($owner: String) {
  onCreateCouponLog(owner: $owner) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCouponLogSubscriptionVariables,
  APITypes.OnCreateCouponLogSubscription
>;
export const onUpdateCouponLog = /* GraphQL */ `subscription OnUpdateCouponLog($owner: String) {
  onUpdateCouponLog(owner: $owner) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCouponLogSubscriptionVariables,
  APITypes.OnUpdateCouponLogSubscription
>;
export const onDeleteCouponLog = /* GraphQL */ `subscription OnDeleteCouponLog($owner: String) {
  onDeleteCouponLog(owner: $owner) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCouponLogSubscriptionVariables,
  APITypes.OnDeleteCouponLogSubscription
>;
export const onCreateBuyerCoupon = /* GraphQL */ `subscription OnCreateBuyerCoupon($owner: String, $buyer_id: String) {
  onCreateBuyerCoupon(owner: $owner, buyer_id: $buyer_id) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBuyerCouponSubscriptionVariables,
  APITypes.OnCreateBuyerCouponSubscription
>;
export const onUpdateBuyerCoupon = /* GraphQL */ `subscription OnUpdateBuyerCoupon($owner: String, $buyer_id: String) {
  onUpdateBuyerCoupon(owner: $owner, buyer_id: $buyer_id) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBuyerCouponSubscriptionVariables,
  APITypes.OnUpdateBuyerCouponSubscription
>;
export const onDeleteBuyerCoupon = /* GraphQL */ `subscription OnDeleteBuyerCoupon($owner: String, $buyer_id: String) {
  onDeleteBuyerCoupon(owner: $owner, buyer_id: $buyer_id) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBuyerCouponSubscriptionVariables,
  APITypes.OnDeleteBuyerCouponSubscription
>;
export const onCreateCashback = /* GraphQL */ `subscription OnCreateCashback($owner: String, $buyer_id: String) {
  onCreateCashback(owner: $owner, buyer_id: $buyer_id) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashbackSubscriptionVariables,
  APITypes.OnCreateCashbackSubscription
>;
export const onUpdateCashback = /* GraphQL */ `subscription OnUpdateCashback($owner: String, $buyer_id: String) {
  onUpdateCashback(owner: $owner, buyer_id: $buyer_id) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashbackSubscriptionVariables,
  APITypes.OnUpdateCashbackSubscription
>;
export const onDeleteCashback = /* GraphQL */ `subscription OnDeleteCashback($owner: String, $buyer_id: String) {
  onDeleteCashback(owner: $owner, buyer_id: $buyer_id) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashbackSubscriptionVariables,
  APITypes.OnDeleteCashbackSubscription
>;
export const onCreateStockProduct = /* GraphQL */ `subscription OnCreateStockProduct {
  onCreateStockProduct {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStockProductSubscriptionVariables,
  APITypes.OnCreateStockProductSubscription
>;
export const onUpdateStockProduct = /* GraphQL */ `subscription OnUpdateStockProduct {
  onUpdateStockProduct {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStockProductSubscriptionVariables,
  APITypes.OnUpdateStockProductSubscription
>;
export const onDeleteStockProduct = /* GraphQL */ `subscription OnDeleteStockProduct {
  onDeleteStockProduct {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStockProductSubscriptionVariables,
  APITypes.OnDeleteStockProductSubscription
>;
export const onCreateLogilessAuthToken = /* GraphQL */ `subscription OnCreateLogilessAuthToken {
  onCreateLogilessAuthToken {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLogilessAuthTokenSubscriptionVariables,
  APITypes.OnCreateLogilessAuthTokenSubscription
>;
export const onUpdateLogilessAuthToken = /* GraphQL */ `subscription OnUpdateLogilessAuthToken {
  onUpdateLogilessAuthToken {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLogilessAuthTokenSubscriptionVariables,
  APITypes.OnUpdateLogilessAuthTokenSubscription
>;
export const onDeleteLogilessAuthToken = /* GraphQL */ `subscription OnDeleteLogilessAuthToken {
  onDeleteLogilessAuthToken {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLogilessAuthTokenSubscriptionVariables,
  APITypes.OnDeleteLogilessAuthTokenSubscription
>;
export const onCreateBuyerReadModel = /* GraphQL */ `subscription OnCreateBuyerReadModel($owner: String) {
  onCreateBuyerReadModel(owner: $owner) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBuyerReadModelSubscriptionVariables,
  APITypes.OnCreateBuyerReadModelSubscription
>;
export const onUpdateBuyerReadModel = /* GraphQL */ `subscription OnUpdateBuyerReadModel($owner: String) {
  onUpdateBuyerReadModel(owner: $owner) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBuyerReadModelSubscriptionVariables,
  APITypes.OnUpdateBuyerReadModelSubscription
>;
export const onDeleteBuyerReadModel = /* GraphQL */ `subscription OnDeleteBuyerReadModel($owner: String) {
  onDeleteBuyerReadModel(owner: $owner) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBuyerReadModelSubscriptionVariables,
  APITypes.OnDeleteBuyerReadModelSubscription
>;
export const onCreateBrandReadModel = /* GraphQL */ `subscription OnCreateBrandReadModel {
  onCreateBrandReadModel {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBrandReadModelSubscriptionVariables,
  APITypes.OnCreateBrandReadModelSubscription
>;
export const onUpdateBrandReadModel = /* GraphQL */ `subscription OnUpdateBrandReadModel {
  onUpdateBrandReadModel {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBrandReadModelSubscriptionVariables,
  APITypes.OnUpdateBrandReadModelSubscription
>;
export const onDeleteBrandReadModel = /* GraphQL */ `subscription OnDeleteBrandReadModel {
  onDeleteBrandReadModel {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBrandReadModelSubscriptionVariables,
  APITypes.OnDeleteBrandReadModelSubscription
>;
export const onCreateProductReadModel = /* GraphQL */ `subscription OnCreateProductReadModel {
  onCreateProductReadModel {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductReadModelSubscriptionVariables,
  APITypes.OnCreateProductReadModelSubscription
>;
export const onUpdateProductReadModel = /* GraphQL */ `subscription OnUpdateProductReadModel {
  onUpdateProductReadModel {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductReadModelSubscriptionVariables,
  APITypes.OnUpdateProductReadModelSubscription
>;
export const onDeleteProductReadModel = /* GraphQL */ `subscription OnDeleteProductReadModel {
  onDeleteProductReadModel {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductReadModelSubscriptionVariables,
  APITypes.OnDeleteProductReadModelSubscription
>;
export const onCreateCollectionReadModel = /* GraphQL */ `subscription OnCreateCollectionReadModel {
  onCreateCollectionReadModel {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCollectionReadModelSubscriptionVariables,
  APITypes.OnCreateCollectionReadModelSubscription
>;
export const onUpdateCollectionReadModel = /* GraphQL */ `subscription OnUpdateCollectionReadModel {
  onUpdateCollectionReadModel {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCollectionReadModelSubscriptionVariables,
  APITypes.OnUpdateCollectionReadModelSubscription
>;
export const onDeleteCollectionReadModel = /* GraphQL */ `subscription OnDeleteCollectionReadModel {
  onDeleteCollectionReadModel {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCollectionReadModelSubscriptionVariables,
  APITypes.OnDeleteCollectionReadModelSubscription
>;
export const onCreateOrderReadModel = /* GraphQL */ `subscription OnCreateOrderReadModel($buyer_id: String, $supplier_id: String) {
  onCreateOrderReadModel(buyer_id: $buyer_id, supplier_id: $supplier_id) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderReadModelSubscriptionVariables,
  APITypes.OnCreateOrderReadModelSubscription
>;
export const onUpdateOrderReadModel = /* GraphQL */ `subscription OnUpdateOrderReadModel($buyer_id: String, $supplier_id: String) {
  onUpdateOrderReadModel(buyer_id: $buyer_id, supplier_id: $supplier_id) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderReadModelSubscriptionVariables,
  APITypes.OnUpdateOrderReadModelSubscription
>;
export const onDeleteOrderReadModel = /* GraphQL */ `subscription OnDeleteOrderReadModel($buyer_id: String, $supplier_id: String) {
  onDeleteOrderReadModel(buyer_id: $buyer_id, supplier_id: $supplier_id) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderReadModelSubscriptionVariables,
  APITypes.OnDeleteOrderReadModelSubscription
>;
export const onCreateOrderDetailReadModel = /* GraphQL */ `subscription OnCreateOrderDetailReadModel(
  $buyer_id: String
  $supplier_id: String
) {
  onCreateOrderDetailReadModel(buyer_id: $buyer_id, supplier_id: $supplier_id) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderDetailReadModelSubscriptionVariables,
  APITypes.OnCreateOrderDetailReadModelSubscription
>;
export const onUpdateOrderDetailReadModel = /* GraphQL */ `subscription OnUpdateOrderDetailReadModel(
  $buyer_id: String
  $supplier_id: String
) {
  onUpdateOrderDetailReadModel(buyer_id: $buyer_id, supplier_id: $supplier_id) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderDetailReadModelSubscriptionVariables,
  APITypes.OnUpdateOrderDetailReadModelSubscription
>;
export const onDeleteOrderDetailReadModel = /* GraphQL */ `subscription OnDeleteOrderDetailReadModel(
  $buyer_id: String
  $supplier_id: String
) {
  onDeleteOrderDetailReadModel(buyer_id: $buyer_id, supplier_id: $supplier_id) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderDetailReadModelSubscriptionVariables,
  APITypes.OnDeleteOrderDetailReadModelSubscription
>;
export const onCreateReturnDetailReadModel = /* GraphQL */ `subscription OnCreateReturnDetailReadModel(
  $buyer_id: String
  $supplier_id: String
) {
  onCreateReturnDetailReadModel(
    buyer_id: $buyer_id
    supplier_id: $supplier_id
  ) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReturnDetailReadModelSubscriptionVariables,
  APITypes.OnCreateReturnDetailReadModelSubscription
>;
export const onUpdateReturnDetailReadModel = /* GraphQL */ `subscription OnUpdateReturnDetailReadModel(
  $buyer_id: String
  $supplier_id: String
) {
  onUpdateReturnDetailReadModel(
    buyer_id: $buyer_id
    supplier_id: $supplier_id
  ) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReturnDetailReadModelSubscriptionVariables,
  APITypes.OnUpdateReturnDetailReadModelSubscription
>;
export const onDeleteReturnDetailReadModel = /* GraphQL */ `subscription OnDeleteReturnDetailReadModel(
  $buyer_id: String
  $supplier_id: String
) {
  onDeleteReturnDetailReadModel(
    buyer_id: $buyer_id
    supplier_id: $supplier_id
  ) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReturnDetailReadModelSubscriptionVariables,
  APITypes.OnDeleteReturnDetailReadModelSubscription
>;
export const onCreateContactReadModel = /* GraphQL */ `subscription OnCreateContactReadModel($owner: String, $buyer_owner: String) {
  onCreateContactReadModel(owner: $owner, buyer_owner: $buyer_owner) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContactReadModelSubscriptionVariables,
  APITypes.OnCreateContactReadModelSubscription
>;
export const onUpdateContactReadModel = /* GraphQL */ `subscription OnUpdateContactReadModel($owner: String, $buyer_owner: String) {
  onUpdateContactReadModel(owner: $owner, buyer_owner: $buyer_owner) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContactReadModelSubscriptionVariables,
  APITypes.OnUpdateContactReadModelSubscription
>;
export const onDeleteContactReadModel = /* GraphQL */ `subscription OnDeleteContactReadModel($owner: String, $buyer_owner: String) {
  onDeleteContactReadModel(owner: $owner, buyer_owner: $buyer_owner) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContactReadModelSubscriptionVariables,
  APITypes.OnDeleteContactReadModelSubscription
>;
export const onCreateOrderEvent = /* GraphQL */ `subscription OnCreateOrderEvent {
  onCreateOrderEvent {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderEventSubscriptionVariables,
  APITypes.OnCreateOrderEventSubscription
>;
export const onUpdateOrderEvent = /* GraphQL */ `subscription OnUpdateOrderEvent {
  onUpdateOrderEvent {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderEventSubscriptionVariables,
  APITypes.OnUpdateOrderEventSubscription
>;
export const onDeleteOrderEvent = /* GraphQL */ `subscription OnDeleteOrderEvent {
  onDeleteOrderEvent {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderEventSubscriptionVariables,
  APITypes.OnDeleteOrderEventSubscription
>;
export const onCreateOrderProductEvent = /* GraphQL */ `subscription OnCreateOrderProductEvent {
  onCreateOrderProductEvent {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderProductEventSubscriptionVariables,
  APITypes.OnCreateOrderProductEventSubscription
>;
export const onUpdateOrderProductEvent = /* GraphQL */ `subscription OnUpdateOrderProductEvent {
  onUpdateOrderProductEvent {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderProductEventSubscriptionVariables,
  APITypes.OnUpdateOrderProductEventSubscription
>;
export const onDeleteOrderProductEvent = /* GraphQL */ `subscription OnDeleteOrderProductEvent {
  onDeleteOrderProductEvent {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderProductEventSubscriptionVariables,
  APITypes.OnDeleteOrderProductEventSubscription
>;
export const onCreateBrandEvent = /* GraphQL */ `subscription OnCreateBrandEvent {
  onCreateBrandEvent {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBrandEventSubscriptionVariables,
  APITypes.OnCreateBrandEventSubscription
>;
export const onUpdateBrandEvent = /* GraphQL */ `subscription OnUpdateBrandEvent {
  onUpdateBrandEvent {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBrandEventSubscriptionVariables,
  APITypes.OnUpdateBrandEventSubscription
>;
export const onDeleteBrandEvent = /* GraphQL */ `subscription OnDeleteBrandEvent {
  onDeleteBrandEvent {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBrandEventSubscriptionVariables,
  APITypes.OnDeleteBrandEventSubscription
>;
export const onCreateProductEvent = /* GraphQL */ `subscription OnCreateProductEvent {
  onCreateProductEvent {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductEventSubscriptionVariables,
  APITypes.OnCreateProductEventSubscription
>;
export const onUpdateProductEvent = /* GraphQL */ `subscription OnUpdateProductEvent {
  onUpdateProductEvent {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductEventSubscriptionVariables,
  APITypes.OnUpdateProductEventSubscription
>;
export const onDeleteProductEvent = /* GraphQL */ `subscription OnDeleteProductEvent {
  onDeleteProductEvent {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductEventSubscriptionVariables,
  APITypes.OnDeleteProductEventSubscription
>;
export const onCreateProductTypeEvent = /* GraphQL */ `subscription OnCreateProductTypeEvent {
  onCreateProductTypeEvent {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductTypeEventSubscriptionVariables,
  APITypes.OnCreateProductTypeEventSubscription
>;
export const onUpdateProductTypeEvent = /* GraphQL */ `subscription OnUpdateProductTypeEvent {
  onUpdateProductTypeEvent {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductTypeEventSubscriptionVariables,
  APITypes.OnUpdateProductTypeEventSubscription
>;
export const onDeleteProductTypeEvent = /* GraphQL */ `subscription OnDeleteProductTypeEvent {
  onDeleteProductTypeEvent {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductTypeEventSubscriptionVariables,
  APITypes.OnDeleteProductTypeEventSubscription
>;
