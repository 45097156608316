import './styles.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';
import AdminMenu from './AdminMenu';
import BuyerMenu from './BuyerMenu';
import SupplierMenu from './SupplierMenu';
import { CAMPAIGN_LINK, CAMPAIGN_MESSAGE } from '~constants/campaign';
import { authUserSelector } from '~redux/auth/selectors/authUser';

const LoginHeader: React.FC = () => {
  const authUser = useSelector(authUserSelector);
  const profile = authUser?.attributes.profile;

  return (
    <>
      {profile === 'buyer' && !!CAMPAIGN_MESSAGE && (
        <header className="header-campaign w-100 text-center py-2 d-none d-lg-block bg-orange">
          {CAMPAIGN_MESSAGE}
          <a
            className="ml-2 alert-link"
            href={CAMPAIGN_LINK}
            target="_blank"
            rel="noreferrer"
          >
            詳細はこちら
          </a>
        </header>
      )}
      {/* {profile === 'supplier' && (
        <UncontrolledAlert className="w-100 text-dark text-center rounded-0 mb-0 py-2 border-0">
          <strong className="mr-2">ブランド紹介キャンペーン</strong>
          ご紹介いただいたブランドがhomulaに出展されると、2万円の謝礼金をお支払いいたします。
          <a
            className="alert-link ml-2"
            href="https://blog.homula.jp/brand-referral_1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            詳細を見る
          </a>
        </UncontrolledAlert>
      )} */}
      <header
        className="site-header header-style-menu-center login-header sticky-top"
        id="site-header"
      >
        {profile === 'buyer' && <BuyerMenu />}
        {profile === 'supplier' && <SupplierMenu />}
        {profile === 'admin' && <AdminMenu />}
      </header>
    </>
  );
};

export default LoginHeader;
