import { createAction } from '@reduxjs/toolkit';
import { Brand } from '~redux/brand/types';
import { PickupBrands as TPickupBrands } from '~types/api';

export type PickupBrands = TPickupBrands;

const ACTION_BASE_TYPE = 'PICKUP';

export const setPickupBrands = createAction<{
  items: PickupBrands[];
  nextToken: string | null;
}>(`${ACTION_BASE_TYPE}/SET_PICKUP_BRANDS`);

export const clearPickupBrands = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_PICKUP_BRANDS`
);

export const setBrandList = createAction<{
  items: Brand[];
}>(`${ACTION_BASE_TYPE}/SET_BRAND_LIST`);
