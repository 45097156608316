import { combineReducers } from 'redux';
import featuredContent from './slice/featuredContent';
import featuredContents from './slice/featuredContents';

const cmsReducer = combineReducers({
  featuredContent,
  featuredContents,
});

export default cmsReducer;
