import { createAction } from '@reduxjs/toolkit';

export type Category = {
  name: string;
  path: string;
};

export type Menu = {
  id: string;
  title: string;
  product_list_path: string;
  brand_list_path: string;
  search_categories: Category[];
  sub_categories: Category[];
  display_order: number;
};

const ACTION_BASE_TYPE = 'MENU';

export const setMenuList = createAction<Menu[]>(
  `${ACTION_BASE_TYPE}/SET_MENU_LIST`
);
