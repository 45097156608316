import { createAction } from '@reduxjs/toolkit';
import { Order } from '~domain/order/Order';
import { ReturnProduct } from '~domain/order/ReturnProduct';
import { BrandDetails } from '~redux/brand/types';

const ACTION_BASE_TYPE = 'ADMIN/ORDER';

export const getOrdersStart = createAction(
  `${ACTION_BASE_TYPE}/GET_ORDERS_START`
);

export const receiveOrders = createAction<Order[]>('ORDER/RECEIVE_ORDERS');

export const getNextOrdersStart = createAction(
  `${ACTION_BASE_TYPE}/GET_NEXT_ORDERS_START`
);

export const receiveNextOrders = createAction<Order[]>(
  'ORDER/RECEIVE_NEXT_ORDERS'
);

export const updateToTransferedStart = createAction(
  `${ACTION_BASE_TYPE}/UPDATE_TO_PAID_START`
);

export const receiveUpdatedOrder = createAction<Order>(
  'ORDER/RECEIVE_UPDATED_ORDER'
);

export const getOrdersCsvStart = createAction(
  `${ACTION_BASE_TYPE}/GET_ORDERS_CSV_START`
);

export const receiveOrdersCsv = createAction<Order[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_ORDERS_CSV`
);

export const getOrderBrandsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_ORDER_BRANDS_START`
);

export const receiveOrderBrands = createAction<BrandDetails[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_ORDER_BRANDS`
);

export const getOrderDetailsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_ORDER_DETAILS_START`
);

export const receiveOrderDetails = createAction<Order>(
  `${ACTION_BASE_TYPE}/RECEIVE_ORDER_DETAILS`
);

export const getReturnProductsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_RETURN_PRODUCTS_START`
);

export const receiveReturnProducts = createAction<ReturnProduct[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_RETURN_PRODUCTS`
);

export const receiveReturnProductOrders = createAction<Order[]>(
  `${ACTION_BASE_TYPE}/RECEIVE_RETURN_PRODUCT_ORDERS`
);

export const addReturnProducts = createAction<ReturnProduct[]>(
  `${ACTION_BASE_TYPE}/ADD_RETURN_PRODUCTS`
);

export const applyStart = createAction(`${ACTION_BASE_TYPE}/APPLY_START`);

export const receiveUpdatedReturnProduct = createAction<ReturnProduct>(
  `${ACTION_BASE_TYPE}/RECEIVE_UPDATED_RETURN_PRODUCT`
);

export const completeUpdatedReturnProducts = createAction(
  `${ACTION_BASE_TYPE}/COMPLETE_UPDATED_RETURN_PRODUCTS`
);

export const getNextReturnProductsStart = createAction(
  `${ACTION_BASE_TYPE}/GET_NEXT_RETURN_PRODUCTS_START`
);
