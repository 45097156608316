import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Entity } from '~core/domain/Entity';
import {
  ChargeStatus as ChargeStatusJson,
  ChargeStatusType as ChargeStatusEnum,
} from '~redux/payment/types';

export type ChargeStatusType = {
  orderId?: string;
  statuses: ChargeStatusJson[];
};

export class ChargeStatus extends Entity<ChargeStatusType> {
  private constructor(params: ChargeStatusType) {
    super(params);
  }

  static create(statuses: ChargeStatusJson[] = []): ChargeStatus {
    return new ChargeStatus({
      orderId: statuses[0]?.order_id,
      statuses,
    });
  }

  static async createByOrderId(orderId: string): Promise<ChargeStatus> {
    const chargeStatusFilter = { order_id: { eq: orderId } };
    const chargeStatusData = await API.graphql<any>(
      graphqlOperation(queries.searchChargeStatuss, {
        filter: chargeStatusFilter,
        limit: 1000,
      })
    );
    const {
      data: {
        searchChargeStatuss: { items: chargeStatuses },
      },
    } = chargeStatusData;

    return ChargeStatus.create(chargeStatuses);
  }

  get status() {
    // 優先度の高いステータスを上
    const CHARGE_STATUS_PRIORITY = [
      ChargeStatusEnum.charged,
      ChargeStatusEnum.partiallyCharged,
      ChargeStatusEnum.failed,
      ChargeStatusEnum.uncharged,
    ];
    const statuses = this.statuses.map(({ status }) => status);

    return (
      CHARGE_STATUS_PRIORITY.find((status) => statuses.includes(status)) ??
      ChargeStatusEnum.uncharged
    );
  }

  get chargeDate() {
    return this.statuses.find(
      ({ status }) => status === ChargeStatusEnum.charged
    )?.createdAt;
  }

  toJson(): ChargeStatusJson[] {
    return this.statuses;
  }
}
