import { createReducer } from '@reduxjs/toolkit';
import { clearTagList, removeTag, setTag, setTagList, Tag } from '../actions';

const initialState: Tag[] = [];

const itemsReducer = createReducer(initialState, {
  [setTagList.type]: (_, { payload }) => payload,
  [setTag.type]: (state, { payload: { id, tag } }) =>
    state.some((item) => item.id === id)
      ? state.map((item) => (item.id === id ? tag : item))
      : [...state, tag],
  [removeTag.type]: (state, { payload: { id } }) =>
    state.filter((item) => item.id !== id),
  [clearTagList.type]: () => [],
});

export default itemsReducer;
