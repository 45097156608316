import { Stripe } from '@stripe/stripe-js';
import { API, graphqlOperation } from 'aws-amplify';
import { Dispatch } from 'redux';
import * as mutations from '../../graphql/mutations';
import { store } from '../../store';
import { loadStripe, resolveError } from './../../utils/stripe';
import { Entity } from '~core/domain/Entity';
import { BuyerInfo } from '~redux/buyer/types';
import { cancelPayment } from '~redux/payment/thunk';

type PaymentIntentType = {
  stripe: Stripe;
  stripeClientSecret: string;
  stripePaymentIntentId: string;
  isPostpayment: boolean;
  buyerInfo: BuyerInfo;
};

type PayParams = {
  buyerId: string;
  price: number;
  captureMethod: 'automatic' | 'manual';
};

export class PaymentIntent extends Entity<PaymentIntentType> {
  static async create(props: Omit<PaymentIntentType, 'stripe'>) {
    const stripe = await loadStripe();

    if (!stripe) {
      throw Error('stripeインスタンス初期化に失敗しました。');
    }

    return new PaymentIntent({
      ...props,
      stripe,
    });
  }

  static async register(
    { price, isPostpayment }: { price: number; isPostpayment: boolean },
    buyerInfo: BuyerInfo
  ) {
    const params: PayParams = {
      buyerId: buyerInfo.account_id,
      price: price,
      captureMethod: isPostpayment ? 'automatic' : 'manual',
    };
    // StripeにPaymentIntentを作成
    const response = await API.graphql<any>(
      graphqlOperation(mutations.createPayment, {
        ...params,
      })
    );

    const {
      data: {
        createPayment: { id: paymentIntentId, client_secret: clientSecret },
      },
    } = response;

    return PaymentIntent.create({
      stripePaymentIntentId: paymentIntentId,
      stripeClientSecret: clientSecret,
      isPostpayment,
      buyerInfo,
    });
  }

  async authorize(dispatch: Dispatch<any>) {
    // 後払いではない場合
    if (!this.isPostpayment) {
      // オーソリ
      const {
        data: { authorizePayment: result },
      } = await API.graphql<any>(
        graphqlOperation(mutations.authorizePayment, {
          paymentId: this.stripePaymentIntentId,
        })
      );

      if (!result || result.error_code) {
        const errorMessage = resolveError(result?.error_code);
        dispatch(cancelPayment(this.stripePaymentIntentId));

        throw new Error(errorMessage);
      }

      // 3Dセキュアが必須の場合エラー表示
      if (result?.status === 'requires_action') {
        const errorMessage = resolveError('card_not_supported');
        dispatch(cancelPayment(this.stripePaymentIntentId));

        throw new Error(errorMessage);
      }
    }

    // 後払いの場合
    if (this.isPostpayment) {
      const oneYenParams: PayParams = {
        buyerId: this.buyerInfo.account_id,
        price: 50,
        captureMethod: 'manual',
      };

      // 50円オーソリ
      const response = await API.graphql<any>(
        graphqlOperation(mutations.createPayment, oneYenParams)
      );

      const {
        data: {
          createPayment: { id: oneYenPaymentIntentId },
        },
      } = response;

      const {
        data: { authorizePayment: oneYenResult },
      } = await API.graphql<any>(
        graphqlOperation(mutations.authorizePayment, {
          paymentId: oneYenPaymentIntentId,
        })
      );

      if (!oneYenResult || oneYenResult.error_code) {
        const errorMessage = resolveError(oneYenResult?.error_code);
        dispatch(cancelPayment(this.stripePaymentIntentId));
        dispatch(cancelPayment(oneYenPaymentIntentId));

        throw new Error(errorMessage);
      }

      // 3Dセキュアが必須の場合エラー表示
      if (oneYenResult?.status === 'requires_action') {
        const errorMessage = resolveError('card_not_supported');
        dispatch(cancelPayment(this.stripePaymentIntentId));
        dispatch(cancelPayment(oneYenPaymentIntentId));

        throw new Error(errorMessage);
      }

      dispatch(cancelPayment(oneYenPaymentIntentId));
    }

    return this;
  }
}
