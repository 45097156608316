import { createSlice } from '@reduxjs/toolkit';

const authenticatingSlice = createSlice({
  initialState: false,
  name: 'authenticating',
  reducers: {
    setAuthenticating: (_, { payload }) => payload,
  },
});

export const { setAuthenticating } = authenticatingSlice.actions;

export default authenticatingSlice.reducer;
