/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const sendToSlack = /* GraphQL */ `mutation SendToSlack($channelId: String!, $text: String!) {
  sendToSlack(channelId: $channelId, text: $text)
}
` as GeneratedMutation<
  APITypes.SendToSlackMutationVariables,
  APITypes.SendToSlackMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer(
  $email: String!
  $name: String!
  $metadata: CustomerMetadataInput
) {
  createCustomer(email: $email, name: $name, metadata: $metadata) {
    id
    object
    address
    balance
    created
    currency
    default_source
    delinquent
    description
    discount
    email
    invoice_prefix
    invoice_settings {
      custom_fields
      default_payment_method
      footer
      __typename
    }
    livemode
    metadata {
      account_id
      __typename
    }
    name
    next_invoice_sequence
    phone
    preferred_locales
    shipping
    tax_exempt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $buyerId: String!
  $price: Int!
  $captureMethod: String!
  $metadata: PaymentMetadataInput
) {
  createPayment(
    buyerId: $buyerId
    price: $price
    captureMethod: $captureMethod
    metadata: $metadata
  ) {
    id
    client_secret
    error_code
    payment_method {
      id
      object
      billing_details {
        address
        email
        name
        phone
        __typename
      }
      card {
        brand
        checks
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
        networks
        three_d_secure_usage
        wallet
        __typename
      }
      created
      customer
      livemode
      metadata {
        name
        __typename
      }
      type
      __typename
    }
    code
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const refundPayment = /* GraphQL */ `mutation RefundPayment(
  $paymentId: String!
  $amount: Int!
  $orderId: String
  $shopName: String
  $brandName: String
  $productName: String
  $productNumber: String
  $quantity: Int
) {
  refundPayment(
    paymentId: $paymentId
    amount: $amount
    orderId: $orderId
    shopName: $shopName
    brandName: $brandName
    productName: $productName
    productNumber: $productNumber
    quantity: $quantity
  )
}
` as GeneratedMutation<
  APITypes.RefundPaymentMutationVariables,
  APITypes.RefundPaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $paymentId: String!
  $price: Int
  $metadata: PaymentMetadataInput
) {
  updatePayment(paymentId: $paymentId, price: $price, metadata: $metadata) {
    id
    client_secret
    error_code
    payment_method {
      id
      object
      billing_details {
        address
        email
        name
        phone
        __typename
      }
      card {
        brand
        checks
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
        networks
        three_d_secure_usage
        wallet
        __typename
      }
      created
      customer
      livemode
      metadata {
        name
        __typename
      }
      type
      __typename
    }
    code
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const updatePaymentMeta = /* GraphQL */ `mutation UpdatePaymentMeta(
  $paymentId: String!
  $metadata: PaymentMetadataInput
) {
  updatePaymentMeta(paymentId: $paymentId, metadata: $metadata) {
    id
    client_secret
    error_code
    payment_method {
      id
      object
      billing_details {
        address
        email
        name
        phone
        __typename
      }
      card {
        brand
        checks
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
        networks
        three_d_secure_usage
        wallet
        __typename
      }
      created
      customer
      livemode
      metadata {
        name
        __typename
      }
      type
      __typename
    }
    code
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMetaMutationVariables,
  APITypes.UpdatePaymentMetaMutation
>;
export const capturePayment = /* GraphQL */ `mutation CapturePayment($paymentIntentId: String!) {
  capturePayment(paymentIntentId: $paymentIntentId) {
    id
    status
    error_code
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CapturePaymentMutationVariables,
  APITypes.CapturePaymentMutation
>;
export const deletePaymentMethod = /* GraphQL */ `mutation DeletePaymentMethod($paymentMethodId: String!) {
  deletePaymentMethod(paymentMethodId: $paymentMethodId) {
    id
    client_secret
    error_code
    payment_method {
      id
      object
      billing_details {
        address
        email
        name
        phone
        __typename
      }
      card {
        brand
        checks
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
        networks
        three_d_secure_usage
        wallet
        __typename
      }
      created
      customer
      livemode
      metadata {
        name
        __typename
      }
      type
      __typename
    }
    code
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMethodMutationVariables,
  APITypes.DeletePaymentMethodMutation
>;
export const cancelPayment = /* GraphQL */ `mutation CancelPayment($paymentId: String!) {
  cancelPayment(paymentId: $paymentId) {
    id
    client_secret
    error_code
    payment_method {
      id
      object
      billing_details {
        address
        email
        name
        phone
        __typename
      }
      card {
        brand
        checks
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
        networks
        three_d_secure_usage
        wallet
        __typename
      }
      created
      customer
      livemode
      metadata {
        name
        __typename
      }
      type
      __typename
    }
    code
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelPaymentMutationVariables,
  APITypes.CancelPaymentMutation
>;
export const sendFeedbackMail = /* GraphQL */ `mutation SendFeedbackMail($message: String!) {
  sendFeedbackMail(message: $message)
}
` as GeneratedMutation<
  APITypes.SendFeedbackMailMutationVariables,
  APITypes.SendFeedbackMailMutation
>;
export const chargeReturnFee = /* GraphQL */ `mutation ChargeReturnFee($stripeId: String!, $paymentMethodId: String!) {
  chargeReturnFee(stripeId: $stripeId, paymentMethodId: $paymentMethodId)
}
` as GeneratedMutation<
  APITypes.ChargeReturnFeeMutationVariables,
  APITypes.ChargeReturnFeeMutation
>;
export const authorizePayment = /* GraphQL */ `mutation AuthorizePayment($paymentId: String!) {
  authorizePayment(paymentId: $paymentId) {
    id
    status
    error_code
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AuthorizePaymentMutationVariables,
  APITypes.AuthorizePaymentMutation
>;
export const createHubspotContact = /* GraphQL */ `mutation CreateHubspotContact($options: CreateHubspotContactInput!) {
  createHubspotContact(options: $options)
}
` as GeneratedMutation<
  APITypes.CreateHubspotContactMutationVariables,
  APITypes.CreateHubspotContactMutation
>;
export const registerOpenLogiItem = /* GraphQL */ `mutation RegisterOpenLogiItem($item: OpenLogiItemInput!) {
  registerOpenLogiItem(item: $item)
}
` as GeneratedMutation<
  APITypes.RegisterOpenLogiItemMutationVariables,
  APITypes.RegisterOpenLogiItemMutation
>;
export const createContacts = /* GraphQL */ `mutation CreateContacts(
  $accountId: String!
  $supplierId: String!
  $contacts: [CreateContactsInput]!
) {
  createContacts(
    accountId: $accountId
    supplierId: $supplierId
    contacts: $contacts
  ) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactsMutationVariables,
  APITypes.CreateContactsMutation
>;
export const processCampaign = /* GraphQL */ `mutation ProcessCampaign(
  $campaign: CampaignInput!
  $contacts: [CampaignContactInput]
  $operation: String!
) {
  processCampaign(
    campaign: $campaign
    contacts: $contacts
    operation: $operation
  )
}
` as GeneratedMutation<
  APITypes.ProcessCampaignMutationVariables,
  APITypes.ProcessCampaignMutation
>;
export const createProductsInBulk = /* GraphQL */ `mutation CreateProductsInBulk(
  $owner: String!
  $productDataList: [ProductCsvInput]!
  $imageKeys: [String]
  $isOldFormat: Boolean
) {
  createProductsInBulk(
    owner: $owner
    productDataList: $productDataList
    imageKeys: $imageKeys
    isOldFormat: $isOldFormat
  ) {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductsInBulkMutationVariables,
  APITypes.CreateProductsInBulkMutation
>;
export const createHubspotDeal = /* GraphQL */ `mutation CreateHubspotDeal(
  $order_id: String!
  $order_date: String
  $amount: Int
  $quantity: Int
  $shipping_fee: Int
  $tax: Int
  $fee_rate: Int
  $email: String
  $store_name: String
  $brand_name: String
) {
  createHubspotDeal(
    order_id: $order_id
    order_date: $order_date
    amount: $amount
    quantity: $quantity
    shipping_fee: $shipping_fee
    tax: $tax
    fee_rate: $fee_rate
    email: $email
    store_name: $store_name
    brand_name: $brand_name
  )
}
` as GeneratedMutation<
  APITypes.CreateHubspotDealMutationVariables,
  APITypes.CreateHubspotDealMutation
>;
export const approveReports = /* GraphQL */ `mutation ApproveReports($report_product_ids: [String]!, $reject: Boolean) {
  approveReports(report_product_ids: $report_product_ids, reject: $reject)
}
` as GeneratedMutation<
  APITypes.ApproveReportsMutationVariables,
  APITypes.ApproveReportsMutation
>;
export const billingAuthorization = /* GraphQL */ `mutation BillingAuthorization(
  $amount: Int!
  $customer_id: String!
  $number: String!
) {
  billingAuthorization(
    amount: $amount
    customer_id: $customer_id
    number: $number
  )
}
` as GeneratedMutation<
  APITypes.BillingAuthorizationMutationVariables,
  APITypes.BillingAuthorizationMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction($orderId: String!, $canceledTransactionId: String) {
  createTransaction(
    orderId: $orderId
    canceledTransactionId: $canceledTransactionId
  )
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction($orderId: String!) {
  updateTransaction(orderId: $orderId)
}
` as GeneratedMutation<
  APITypes.UpdateTransactionMutationVariables,
  APITypes.UpdateTransactionMutation
>;
export const cancelTransaction = /* GraphQL */ `mutation CancelTransaction($transaction_id: String!) {
  cancelTransaction(transaction_id: $transaction_id)
}
` as GeneratedMutation<
  APITypes.CancelTransactionMutationVariables,
  APITypes.CancelTransactionMutation
>;
export const cancelAuthorization = /* GraphQL */ `mutation CancelAuthorization($authorization_id: String!) {
  cancelAuthorization(authorization_id: $authorization_id)
}
` as GeneratedMutation<
  APITypes.CancelAuthorizationMutationVariables,
  APITypes.CancelAuthorizationMutation
>;
export const updateAuthorization = /* GraphQL */ `mutation UpdateAuthorization(
  $buyer_id: String!
  $order_id: String!
  $amount: Int!
) {
  updateAuthorization(buyer_id: $buyer_id, order_id: $order_id, amount: $amount)
}
` as GeneratedMutation<
  APITypes.UpdateAuthorizationMutationVariables,
  APITypes.UpdateAuthorizationMutation
>;
export const cashback = /* GraphQL */ `mutation Cashback($orderId: String!, $amount: Int!) {
  cashback(orderId: $orderId, amount: $amount)
}
` as GeneratedMutation<
  APITypes.CashbackMutationVariables,
  APITypes.CashbackMutation
>;
export const syncBuyerReadModel = /* GraphQL */ `mutation SyncBuyerReadModel($buyerId: String) {
  syncBuyerReadModel(buyerId: $buyerId) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncBuyerReadModelMutationVariables,
  APITypes.SyncBuyerReadModelMutation
>;
export const updateInventories = /* GraphQL */ `mutation UpdateInventories(
  $brandId: String!
  $products: [UpdateInventoriesInput]!
) {
  updateInventories(brandId: $brandId, products: $products) {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInventoriesMutationVariables,
  APITypes.UpdateInventoriesMutation
>;
export const inventoryReservation = /* GraphQL */ `mutation InventoryReservation($input: [InventoryReservationInput]!) {
  inventoryReservation(input: $input) {
    order_product_id
    inventory {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    error
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InventoryReservationMutationVariables,
  APITypes.InventoryReservationMutation
>;
export const trackEvent = /* GraphQL */ `mutation TrackEvent($accountId: String, $event: String, $properties: AWSJSON) {
  trackEvent(accountId: $accountId, event: $event, properties: $properties)
}
` as GeneratedMutation<
  APITypes.TrackEventMutationVariables,
  APITypes.TrackEventMutation
>;
export const syncOrderReadModel = /* GraphQL */ `mutation SyncOrderReadModel($orderId: String) {
  syncOrderReadModel(orderId: $orderId) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncOrderReadModelMutationVariables,
  APITypes.SyncOrderReadModelMutation
>;
export const syncOrderDetailReadModel = /* GraphQL */ `mutation SyncOrderDetailReadModel($orderId: String, $orderProductId: String) {
  syncOrderDetailReadModel(orderId: $orderId, orderProductId: $orderProductId) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncOrderDetailReadModelMutationVariables,
  APITypes.SyncOrderDetailReadModelMutation
>;
export const syncReturnDetailReadModel = /* GraphQL */ `mutation SyncReturnDetailReadModel($returnProductId: String) {
  syncReturnDetailReadModel(returnProductId: $returnProductId) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncReturnDetailReadModelMutationVariables,
  APITypes.SyncReturnDetailReadModelMutation
>;
export const updateOrderPaymentMethod = /* GraphQL */ `mutation UpdateOrderPaymentMethod(
  $orderId: String!
  $paymentMethodId: String!
) {
  updateOrderPaymentMethod(
    orderId: $orderId
    paymentMethodId: $paymentMethodId
  ) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderPaymentMethodMutationVariables,
  APITypes.UpdateOrderPaymentMethodMutation
>;
export const syncProductReadModel = /* GraphQL */ `mutation SyncProductReadModel($productId: String) {
  syncProductReadModel(productId: $productId) {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncProductReadModelMutationVariables,
  APITypes.SyncProductReadModelMutation
>;
export const syncCollectionReadModel = /* GraphQL */ `mutation SyncCollectionReadModel($collectionId: String) {
  syncCollectionReadModel(collectionId: $collectionId) {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncCollectionReadModelMutationVariables,
  APITypes.SyncCollectionReadModelMutation
>;
export const copyProducts = /* GraphQL */ `mutation CopyProducts($input: [CopyProductInput]!) {
  copyProducts(input: $input)
}
` as GeneratedMutation<
  APITypes.CopyProductsMutationVariables,
  APITypes.CopyProductsMutation
>;
export const syncBrandReadModel = /* GraphQL */ `mutation SyncBrandReadModel($brandId: String) {
  syncBrandReadModel(brandId: $brandId) {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncBrandReadModelMutationVariables,
  APITypes.SyncBrandReadModelMutation
>;
export const createBillingTransaction = /* GraphQL */ `mutation CreateBillingTransaction($input: CreateBillingTransactionInput!) {
  createBillingTransaction(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateBillingTransactionMutationVariables,
  APITypes.CreateBillingTransactionMutation
>;
export const createLogilessToken = /* GraphQL */ `mutation CreateLogilessToken($code: String!) {
  createLogilessToken(code: $code)
}
` as GeneratedMutation<
  APITypes.CreateLogilessTokenMutationVariables,
  APITypes.CreateLogilessTokenMutation
>;
export const createLogilessProduct = /* GraphQL */ `mutation CreateLogilessProduct($productId: String, $productTypeId: String) {
  createLogilessProduct(productId: $productId, productTypeId: $productTypeId)
}
` as GeneratedMutation<
  APITypes.CreateLogilessProductMutationVariables,
  APITypes.CreateLogilessProductMutation
>;
export const updateLogilessProduct = /* GraphQL */ `mutation UpdateLogilessProduct($product: AWSJSON) {
  updateLogilessProduct(product: $product)
}
` as GeneratedMutation<
  APITypes.UpdateLogilessProductMutationVariables,
  APITypes.UpdateLogilessProductMutation
>;
export const arrivalLogilessProduct = /* GraphQL */ `mutation ArrivalLogilessProduct($returnProductIds: [String]!) {
  arrivalLogilessProduct(returnProductIds: $returnProductIds)
}
` as GeneratedMutation<
  APITypes.ArrivalLogilessProductMutationVariables,
  APITypes.ArrivalLogilessProductMutation
>;
export const orderLogilessProduct = /* GraphQL */ `mutation OrderLogilessProduct($orderIds: [String]!) {
  orderLogilessProduct(orderIds: $orderIds)
}
` as GeneratedMutation<
  APITypes.OrderLogilessProductMutationVariables,
  APITypes.OrderLogilessProductMutation
>;
export const updateProductSku = /* GraphQL */ `mutation UpdateProductSku($productId: String, $productTypeIds: [String]) {
  updateProductSku(productId: $productId, productTypeIds: $productTypeIds)
}
` as GeneratedMutation<
  APITypes.UpdateProductSkuMutationVariables,
  APITypes.UpdateProductSkuMutation
>;
export const updateStockProductByProductId = /* GraphQL */ `mutation UpdateStockProductByProductId(
  $srcProductId: String
  $srcProductTypeId: String
  $productId: String
  $productTypeId: String
) {
  updateStockProductByProductId(
    srcProductId: $srcProductId
    srcProductTypeId: $srcProductTypeId
    productId: $productId
    productTypeId: $productTypeId
  )
}
` as GeneratedMutation<
  APITypes.UpdateStockProductByProductIdMutationVariables,
  APITypes.UpdateStockProductByProductIdMutation
>;
export const importStockProduct = /* GraphQL */ `mutation ImportStockProduct {
  importStockProduct
}
` as GeneratedMutation<
  APITypes.ImportStockProductMutationVariables,
  APITypes.ImportStockProductMutation
>;
export const deleteContactListConnectionsByListId = /* GraphQL */ `mutation DeleteContactListConnectionsByListId($listId: String) {
  deleteContactListConnectionsByListId(listId: $listId)
}
` as GeneratedMutation<
  APITypes.DeleteContactListConnectionsByListIdMutationVariables,
  APITypes.DeleteContactListConnectionsByListIdMutation
>;
export const addContactsToList = /* GraphQL */ `mutation AddContactsToList(
  $listId: String!
  $contactIds: [String]!
  $owner: String!
  $supplierId: String!
) {
  addContactsToList(
    listId: $listId
    contactIds: $contactIds
    owner: $owner
    supplierId: $supplierId
  )
}
` as GeneratedMutation<
  APITypes.AddContactsToListMutationVariables,
  APITypes.AddContactsToListMutation
>;
export const removeContactsFromList = /* GraphQL */ `mutation RemoveContactsFromList($listId: String!, $contactIds: [String]!) {
  removeContactsFromList(listId: $listId, contactIds: $contactIds)
}
` as GeneratedMutation<
  APITypes.RemoveContactsFromListMutationVariables,
  APITypes.RemoveContactsFromListMutation
>;
export const splitOrder = /* GraphQL */ `mutation SplitOrder($input: SplitOrderInput) {
  splitOrder(input: $input)
}
` as GeneratedMutation<
  APITypes.SplitOrderMutationVariables,
  APITypes.SplitOrderMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createBuyerInfo = /* GraphQL */ `mutation CreateBuyerInfo(
  $input: CreateBuyerInfoInput!
  $condition: ModelBuyerInfoConditionInput
) {
  createBuyerInfo(input: $input, condition: $condition) {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuyerInfoMutationVariables,
  APITypes.CreateBuyerInfoMutation
>;
export const updateBuyerInfo = /* GraphQL */ `mutation UpdateBuyerInfo(
  $input: UpdateBuyerInfoInput!
  $condition: ModelBuyerInfoConditionInput
) {
  updateBuyerInfo(input: $input, condition: $condition) {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuyerInfoMutationVariables,
  APITypes.UpdateBuyerInfoMutation
>;
export const deleteBuyerInfo = /* GraphQL */ `mutation DeleteBuyerInfo(
  $input: DeleteBuyerInfoInput!
  $condition: ModelBuyerInfoConditionInput
) {
  deleteBuyerInfo(input: $input, condition: $condition) {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuyerInfoMutationVariables,
  APITypes.DeleteBuyerInfoMutation
>;
export const createShopImage = /* GraphQL */ `mutation CreateShopImage(
  $input: CreateShopImageInput!
  $condition: ModelShopImageConditionInput
) {
  createShopImage(input: $input, condition: $condition) {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShopImageMutationVariables,
  APITypes.CreateShopImageMutation
>;
export const updateShopImage = /* GraphQL */ `mutation UpdateShopImage(
  $input: UpdateShopImageInput!
  $condition: ModelShopImageConditionInput
) {
  updateShopImage(input: $input, condition: $condition) {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShopImageMutationVariables,
  APITypes.UpdateShopImageMutation
>;
export const deleteShopImage = /* GraphQL */ `mutation DeleteShopImage(
  $input: DeleteShopImageInput!
  $condition: ModelShopImageConditionInput
) {
  deleteShopImage(input: $input, condition: $condition) {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShopImageMutationVariables,
  APITypes.DeleteShopImageMutation
>;
export const createIndividual = /* GraphQL */ `mutation CreateIndividual(
  $input: CreateIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  createIndividual(input: $input, condition: $condition) {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIndividualMutationVariables,
  APITypes.CreateIndividualMutation
>;
export const updateIndividual = /* GraphQL */ `mutation UpdateIndividual(
  $input: UpdateIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  updateIndividual(input: $input, condition: $condition) {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIndividualMutationVariables,
  APITypes.UpdateIndividualMutation
>;
export const deleteIndividual = /* GraphQL */ `mutation DeleteIndividual(
  $input: DeleteIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  deleteIndividual(input: $input, condition: $condition) {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIndividualMutationVariables,
  APITypes.DeleteIndividualMutation
>;
export const createSupplierGroup = /* GraphQL */ `mutation CreateSupplierGroup(
  $input: CreateSupplierGroupInput!
  $condition: ModelSupplierGroupConditionInput
) {
  createSupplierGroup(input: $input, condition: $condition) {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSupplierGroupMutationVariables,
  APITypes.CreateSupplierGroupMutation
>;
export const updateSupplierGroup = /* GraphQL */ `mutation UpdateSupplierGroup(
  $input: UpdateSupplierGroupInput!
  $condition: ModelSupplierGroupConditionInput
) {
  updateSupplierGroup(input: $input, condition: $condition) {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSupplierGroupMutationVariables,
  APITypes.UpdateSupplierGroupMutation
>;
export const deleteSupplierGroup = /* GraphQL */ `mutation DeleteSupplierGroup(
  $input: DeleteSupplierGroupInput!
  $condition: ModelSupplierGroupConditionInput
) {
  deleteSupplierGroup(input: $input, condition: $condition) {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSupplierGroupMutationVariables,
  APITypes.DeleteSupplierGroupMutation
>;
export const createSupplierInfo = /* GraphQL */ `mutation CreateSupplierInfo(
  $input: CreateSupplierInfoInput!
  $condition: ModelSupplierInfoConditionInput
) {
  createSupplierInfo(input: $input, condition: $condition) {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSupplierInfoMutationVariables,
  APITypes.CreateSupplierInfoMutation
>;
export const updateSupplierInfo = /* GraphQL */ `mutation UpdateSupplierInfo(
  $input: UpdateSupplierInfoInput!
  $condition: ModelSupplierInfoConditionInput
) {
  updateSupplierInfo(input: $input, condition: $condition) {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSupplierInfoMutationVariables,
  APITypes.UpdateSupplierInfoMutation
>;
export const deleteSupplierInfo = /* GraphQL */ `mutation DeleteSupplierInfo(
  $input: DeleteSupplierInfoInput!
  $condition: ModelSupplierInfoConditionInput
) {
  deleteSupplierInfo(input: $input, condition: $condition) {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSupplierInfoMutationVariables,
  APITypes.DeleteSupplierInfoMutation
>;
export const createShippingAddress = /* GraphQL */ `mutation CreateShippingAddress(
  $input: CreateShippingAddressInput!
  $condition: ModelShippingAddressConditionInput
) {
  createShippingAddress(input: $input, condition: $condition) {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShippingAddressMutationVariables,
  APITypes.CreateShippingAddressMutation
>;
export const updateShippingAddress = /* GraphQL */ `mutation UpdateShippingAddress(
  $input: UpdateShippingAddressInput!
  $condition: ModelShippingAddressConditionInput
) {
  updateShippingAddress(input: $input, condition: $condition) {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShippingAddressMutationVariables,
  APITypes.UpdateShippingAddressMutation
>;
export const deleteShippingAddress = /* GraphQL */ `mutation DeleteShippingAddress(
  $input: DeleteShippingAddressInput!
  $condition: ModelShippingAddressConditionInput
) {
  deleteShippingAddress(input: $input, condition: $condition) {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShippingAddressMutationVariables,
  APITypes.DeleteShippingAddressMutation
>;
export const createEmailPreferences = /* GraphQL */ `mutation CreateEmailPreferences(
  $input: CreateEmailPreferencesInput!
  $condition: ModelEmailPreferencesConditionInput
) {
  createEmailPreferences(input: $input, condition: $condition) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmailPreferencesMutationVariables,
  APITypes.CreateEmailPreferencesMutation
>;
export const updateEmailPreferences = /* GraphQL */ `mutation UpdateEmailPreferences(
  $input: UpdateEmailPreferencesInput!
  $condition: ModelEmailPreferencesConditionInput
) {
  updateEmailPreferences(input: $input, condition: $condition) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailPreferencesMutationVariables,
  APITypes.UpdateEmailPreferencesMutation
>;
export const deleteEmailPreferences = /* GraphQL */ `mutation DeleteEmailPreferences(
  $input: DeleteEmailPreferencesInput!
  $condition: ModelEmailPreferencesConditionInput
) {
  deleteEmailPreferences(input: $input, condition: $condition) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmailPreferencesMutationVariables,
  APITypes.DeleteEmailPreferencesMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createProductImageKey = /* GraphQL */ `mutation CreateProductImageKey(
  $input: CreateProductImageKeyInput!
  $condition: ModelProductImageKeyConditionInput
) {
  createProductImageKey(input: $input, condition: $condition) {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductImageKeyMutationVariables,
  APITypes.CreateProductImageKeyMutation
>;
export const updateProductImageKey = /* GraphQL */ `mutation UpdateProductImageKey(
  $input: UpdateProductImageKeyInput!
  $condition: ModelProductImageKeyConditionInput
) {
  updateProductImageKey(input: $input, condition: $condition) {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductImageKeyMutationVariables,
  APITypes.UpdateProductImageKeyMutation
>;
export const deleteProductImageKey = /* GraphQL */ `mutation DeleteProductImageKey(
  $input: DeleteProductImageKeyInput!
  $condition: ModelProductImageKeyConditionInput
) {
  deleteProductImageKey(input: $input, condition: $condition) {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductImageKeyMutationVariables,
  APITypes.DeleteProductImageKeyMutation
>;
export const createProductVideoKey = /* GraphQL */ `mutation CreateProductVideoKey(
  $input: CreateProductVideoKeyInput!
  $condition: ModelProductVideoKeyConditionInput
) {
  createProductVideoKey(input: $input, condition: $condition) {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductVideoKeyMutationVariables,
  APITypes.CreateProductVideoKeyMutation
>;
export const updateProductVideoKey = /* GraphQL */ `mutation UpdateProductVideoKey(
  $input: UpdateProductVideoKeyInput!
  $condition: ModelProductVideoKeyConditionInput
) {
  updateProductVideoKey(input: $input, condition: $condition) {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductVideoKeyMutationVariables,
  APITypes.UpdateProductVideoKeyMutation
>;
export const deleteProductVideoKey = /* GraphQL */ `mutation DeleteProductVideoKey(
  $input: DeleteProductVideoKeyInput!
  $condition: ModelProductVideoKeyConditionInput
) {
  deleteProductVideoKey(input: $input, condition: $condition) {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductVideoKeyMutationVariables,
  APITypes.DeleteProductVideoKeyMutation
>;
export const createProductType = /* GraphQL */ `mutation CreateProductType(
  $input: CreateProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  createProductType(input: $input, condition: $condition) {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductTypeMutationVariables,
  APITypes.CreateProductTypeMutation
>;
export const updateProductType = /* GraphQL */ `mutation UpdateProductType(
  $input: UpdateProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  updateProductType(input: $input, condition: $condition) {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductTypeMutationVariables,
  APITypes.UpdateProductTypeMutation
>;
export const deleteProductType = /* GraphQL */ `mutation DeleteProductType(
  $input: DeleteProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  deleteProductType(input: $input, condition: $condition) {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductTypeMutationVariables,
  APITypes.DeleteProductTypeMutation
>;
export const createInventory = /* GraphQL */ `mutation CreateInventory(
  $input: CreateInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  createInventory(input: $input, condition: $condition) {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInventoryMutationVariables,
  APITypes.CreateInventoryMutation
>;
export const updateInventory = /* GraphQL */ `mutation UpdateInventory(
  $input: UpdateInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  updateInventory(input: $input, condition: $condition) {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInventoryMutationVariables,
  APITypes.UpdateInventoryMutation
>;
export const deleteInventory = /* GraphQL */ `mutation DeleteInventory(
  $input: DeleteInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  deleteInventory(input: $input, condition: $condition) {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInventoryMutationVariables,
  APITypes.DeleteInventoryMutation
>;
export const createReturnInventoryLog = /* GraphQL */ `mutation CreateReturnInventoryLog(
  $input: CreateReturnInventoryLogInput!
  $condition: ModelReturnInventoryLogConditionInput
) {
  createReturnInventoryLog(input: $input, condition: $condition) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReturnInventoryLogMutationVariables,
  APITypes.CreateReturnInventoryLogMutation
>;
export const updateReturnInventoryLog = /* GraphQL */ `mutation UpdateReturnInventoryLog(
  $input: UpdateReturnInventoryLogInput!
  $condition: ModelReturnInventoryLogConditionInput
) {
  updateReturnInventoryLog(input: $input, condition: $condition) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReturnInventoryLogMutationVariables,
  APITypes.UpdateReturnInventoryLogMutation
>;
export const deleteReturnInventoryLog = /* GraphQL */ `mutation DeleteReturnInventoryLog(
  $input: DeleteReturnInventoryLogInput!
  $condition: ModelReturnInventoryLogConditionInput
) {
  deleteReturnInventoryLog(input: $input, condition: $condition) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReturnInventoryLogMutationVariables,
  APITypes.DeleteReturnInventoryLogMutation
>;
export const createInventoryLog = /* GraphQL */ `mutation CreateInventoryLog(
  $input: CreateInventoryLogInput!
  $condition: ModelInventoryLogConditionInput
) {
  createInventoryLog(input: $input, condition: $condition) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInventoryLogMutationVariables,
  APITypes.CreateInventoryLogMutation
>;
export const updateInventoryLog = /* GraphQL */ `mutation UpdateInventoryLog(
  $input: UpdateInventoryLogInput!
  $condition: ModelInventoryLogConditionInput
) {
  updateInventoryLog(input: $input, condition: $condition) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInventoryLogMutationVariables,
  APITypes.UpdateInventoryLogMutation
>;
export const deleteInventoryLog = /* GraphQL */ `mutation DeleteInventoryLog(
  $input: DeleteInventoryLogInput!
  $condition: ModelInventoryLogConditionInput
) {
  deleteInventoryLog(input: $input, condition: $condition) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInventoryLogMutationVariables,
  APITypes.DeleteInventoryLogMutation
>;
export const createInStockNotification = /* GraphQL */ `mutation CreateInStockNotification(
  $input: CreateInStockNotificationInput!
  $condition: ModelInStockNotificationConditionInput
) {
  createInStockNotification(input: $input, condition: $condition) {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInStockNotificationMutationVariables,
  APITypes.CreateInStockNotificationMutation
>;
export const updateInStockNotification = /* GraphQL */ `mutation UpdateInStockNotification(
  $input: UpdateInStockNotificationInput!
  $condition: ModelInStockNotificationConditionInput
) {
  updateInStockNotification(input: $input, condition: $condition) {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInStockNotificationMutationVariables,
  APITypes.UpdateInStockNotificationMutation
>;
export const deleteInStockNotification = /* GraphQL */ `mutation DeleteInStockNotification(
  $input: DeleteInStockNotificationInput!
  $condition: ModelInStockNotificationConditionInput
) {
  deleteInStockNotification(input: $input, condition: $condition) {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInStockNotificationMutationVariables,
  APITypes.DeleteInStockNotificationMutation
>;
export const createBrand = /* GraphQL */ `mutation CreateBrand(
  $input: CreateBrandInput!
  $condition: ModelBrandConditionInput
) {
  createBrand(input: $input, condition: $condition) {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandMutationVariables,
  APITypes.CreateBrandMutation
>;
export const updateBrand = /* GraphQL */ `mutation UpdateBrand(
  $input: UpdateBrandInput!
  $condition: ModelBrandConditionInput
) {
  updateBrand(input: $input, condition: $condition) {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandMutationVariables,
  APITypes.UpdateBrandMutation
>;
export const deleteBrand = /* GraphQL */ `mutation DeleteBrand(
  $input: DeleteBrandInput!
  $condition: ModelBrandConditionInput
) {
  deleteBrand(input: $input, condition: $condition) {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandMutationVariables,
  APITypes.DeleteBrandMutation
>;
export const createTag = /* GraphQL */ `mutation CreateTag(
  $input: CreateTagInput!
  $condition: ModelTagConditionInput
) {
  createTag(input: $input, condition: $condition) {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagMutationVariables,
  APITypes.CreateTagMutation
>;
export const updateTag = /* GraphQL */ `mutation UpdateTag(
  $input: UpdateTagInput!
  $condition: ModelTagConditionInput
) {
  updateTag(input: $input, condition: $condition) {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagMutationVariables,
  APITypes.UpdateTagMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag(
  $input: DeleteTagInput!
  $condition: ModelTagConditionInput
) {
  deleteTag(input: $input, condition: $condition) {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const createBrandCollection = /* GraphQL */ `mutation CreateBrandCollection(
  $input: CreateBrandCollectionInput!
  $condition: ModelBrandCollectionConditionInput
) {
  createBrandCollection(input: $input, condition: $condition) {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandCollectionMutationVariables,
  APITypes.CreateBrandCollectionMutation
>;
export const updateBrandCollection = /* GraphQL */ `mutation UpdateBrandCollection(
  $input: UpdateBrandCollectionInput!
  $condition: ModelBrandCollectionConditionInput
) {
  updateBrandCollection(input: $input, condition: $condition) {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandCollectionMutationVariables,
  APITypes.UpdateBrandCollectionMutation
>;
export const deleteBrandCollection = /* GraphQL */ `mutation DeleteBrandCollection(
  $input: DeleteBrandCollectionInput!
  $condition: ModelBrandCollectionConditionInput
) {
  deleteBrandCollection(input: $input, condition: $condition) {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandCollectionMutationVariables,
  APITypes.DeleteBrandCollectionMutation
>;
export const createShopCart = /* GraphQL */ `mutation CreateShopCart(
  $input: CreateShopCartInput!
  $condition: ModelShopCartConditionInput
) {
  createShopCart(input: $input, condition: $condition) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShopCartMutationVariables,
  APITypes.CreateShopCartMutation
>;
export const updateShopCart = /* GraphQL */ `mutation UpdateShopCart(
  $input: UpdateShopCartInput!
  $condition: ModelShopCartConditionInput
) {
  updateShopCart(input: $input, condition: $condition) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShopCartMutationVariables,
  APITypes.UpdateShopCartMutation
>;
export const deleteShopCart = /* GraphQL */ `mutation DeleteShopCart(
  $input: DeleteShopCartInput!
  $condition: ModelShopCartConditionInput
) {
  deleteShopCart(input: $input, condition: $condition) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShopCartMutationVariables,
  APITypes.DeleteShopCartMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createChargeStatus = /* GraphQL */ `mutation CreateChargeStatus(
  $input: CreateChargeStatusInput!
  $condition: ModelChargeStatusConditionInput
) {
  createChargeStatus(input: $input, condition: $condition) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChargeStatusMutationVariables,
  APITypes.CreateChargeStatusMutation
>;
export const updateChargeStatus = /* GraphQL */ `mutation UpdateChargeStatus(
  $input: UpdateChargeStatusInput!
  $condition: ModelChargeStatusConditionInput
) {
  updateChargeStatus(input: $input, condition: $condition) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChargeStatusMutationVariables,
  APITypes.UpdateChargeStatusMutation
>;
export const deleteChargeStatus = /* GraphQL */ `mutation DeleteChargeStatus(
  $input: DeleteChargeStatusInput!
  $condition: ModelChargeStatusConditionInput
) {
  deleteChargeStatus(input: $input, condition: $condition) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChargeStatusMutationVariables,
  APITypes.DeleteChargeStatusMutation
>;
export const createOrderProduct = /* GraphQL */ `mutation CreateOrderProduct(
  $input: CreateOrderProductInput!
  $condition: ModelOrderProductConditionInput
) {
  createOrderProduct(input: $input, condition: $condition) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderProductMutationVariables,
  APITypes.CreateOrderProductMutation
>;
export const updateOrderProduct = /* GraphQL */ `mutation UpdateOrderProduct(
  $input: UpdateOrderProductInput!
  $condition: ModelOrderProductConditionInput
) {
  updateOrderProduct(input: $input, condition: $condition) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderProductMutationVariables,
  APITypes.UpdateOrderProductMutation
>;
export const deleteOrderProduct = /* GraphQL */ `mutation DeleteOrderProduct(
  $input: DeleteOrderProductInput!
  $condition: ModelOrderProductConditionInput
) {
  deleteOrderProduct(input: $input, condition: $condition) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderProductMutationVariables,
  APITypes.DeleteOrderProductMutation
>;
export const createOrderStatus = /* GraphQL */ `mutation CreateOrderStatus(
  $input: CreateOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  createOrderStatus(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderStatusMutationVariables,
  APITypes.CreateOrderStatusMutation
>;
export const updateOrderStatus = /* GraphQL */ `mutation UpdateOrderStatus(
  $input: UpdateOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  updateOrderStatus(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderStatusMutationVariables,
  APITypes.UpdateOrderStatusMutation
>;
export const deleteOrderStatus = /* GraphQL */ `mutation DeleteOrderStatus(
  $input: DeleteOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  deleteOrderStatus(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderStatusMutationVariables,
  APITypes.DeleteOrderStatusMutation
>;
export const createReturnProduct = /* GraphQL */ `mutation CreateReturnProduct(
  $input: CreateReturnProductInput!
  $condition: ModelReturnProductConditionInput
) {
  createReturnProduct(input: $input, condition: $condition) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReturnProductMutationVariables,
  APITypes.CreateReturnProductMutation
>;
export const updateReturnProduct = /* GraphQL */ `mutation UpdateReturnProduct(
  $input: UpdateReturnProductInput!
  $condition: ModelReturnProductConditionInput
) {
  updateReturnProduct(input: $input, condition: $condition) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReturnProductMutationVariables,
  APITypes.UpdateReturnProductMutation
>;
export const deleteReturnProduct = /* GraphQL */ `mutation DeleteReturnProduct(
  $input: DeleteReturnProductInput!
  $condition: ModelReturnProductConditionInput
) {
  deleteReturnProduct(input: $input, condition: $condition) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReturnProductMutationVariables,
  APITypes.DeleteReturnProductMutation
>;
export const createReportProduct = /* GraphQL */ `mutation CreateReportProduct(
  $input: CreateReportProductInput!
  $condition: ModelReportProductConditionInput
) {
  createReportProduct(input: $input, condition: $condition) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportProductMutationVariables,
  APITypes.CreateReportProductMutation
>;
export const updateReportProduct = /* GraphQL */ `mutation UpdateReportProduct(
  $input: UpdateReportProductInput!
  $condition: ModelReportProductConditionInput
) {
  updateReportProduct(input: $input, condition: $condition) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportProductMutationVariables,
  APITypes.UpdateReportProductMutation
>;
export const deleteReportProduct = /* GraphQL */ `mutation DeleteReportProduct(
  $input: DeleteReportProductInput!
  $condition: ModelReportProductConditionInput
) {
  deleteReportProduct(input: $input, condition: $condition) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportProductMutationVariables,
  APITypes.DeleteReportProductMutation
>;
export const createFeedback = /* GraphQL */ `mutation CreateFeedback(
  $input: CreateFeedbackInput!
  $condition: ModelFeedbackConditionInput
) {
  createFeedback(input: $input, condition: $condition) {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeedbackMutationVariables,
  APITypes.CreateFeedbackMutation
>;
export const updateFeedback = /* GraphQL */ `mutation UpdateFeedback(
  $input: UpdateFeedbackInput!
  $condition: ModelFeedbackConditionInput
) {
  updateFeedback(input: $input, condition: $condition) {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeedbackMutationVariables,
  APITypes.UpdateFeedbackMutation
>;
export const deleteFeedback = /* GraphQL */ `mutation DeleteFeedback(
  $input: DeleteFeedbackInput!
  $condition: ModelFeedbackConditionInput
) {
  deleteFeedback(input: $input, condition: $condition) {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeedbackMutationVariables,
  APITypes.DeleteFeedbackMutation
>;
export const createOrderEditReason = /* GraphQL */ `mutation CreateOrderEditReason(
  $input: CreateOrderEditReasonInput!
  $condition: ModelOrderEditReasonConditionInput
) {
  createOrderEditReason(input: $input, condition: $condition) {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderEditReasonMutationVariables,
  APITypes.CreateOrderEditReasonMutation
>;
export const updateOrderEditReason = /* GraphQL */ `mutation UpdateOrderEditReason(
  $input: UpdateOrderEditReasonInput!
  $condition: ModelOrderEditReasonConditionInput
) {
  updateOrderEditReason(input: $input, condition: $condition) {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderEditReasonMutationVariables,
  APITypes.UpdateOrderEditReasonMutation
>;
export const deleteOrderEditReason = /* GraphQL */ `mutation DeleteOrderEditReason(
  $input: DeleteOrderEditReasonInput!
  $condition: ModelOrderEditReasonConditionInput
) {
  deleteOrderEditReason(input: $input, condition: $condition) {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderEditReasonMutationVariables,
  APITypes.DeleteOrderEditReasonMutation
>;
export const createOrderCancelReason = /* GraphQL */ `mutation CreateOrderCancelReason(
  $input: CreateOrderCancelReasonInput!
  $condition: ModelOrderCancelReasonConditionInput
) {
  createOrderCancelReason(input: $input, condition: $condition) {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderCancelReasonMutationVariables,
  APITypes.CreateOrderCancelReasonMutation
>;
export const updateOrderCancelReason = /* GraphQL */ `mutation UpdateOrderCancelReason(
  $input: UpdateOrderCancelReasonInput!
  $condition: ModelOrderCancelReasonConditionInput
) {
  updateOrderCancelReason(input: $input, condition: $condition) {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderCancelReasonMutationVariables,
  APITypes.UpdateOrderCancelReasonMutation
>;
export const deleteOrderCancelReason = /* GraphQL */ `mutation DeleteOrderCancelReason(
  $input: DeleteOrderCancelReasonInput!
  $condition: ModelOrderCancelReasonConditionInput
) {
  deleteOrderCancelReason(input: $input, condition: $condition) {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderCancelReasonMutationVariables,
  APITypes.DeleteOrderCancelReasonMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createThread = /* GraphQL */ `mutation CreateThread(
  $input: CreateThreadInput!
  $condition: ModelThreadConditionInput
) {
  createThread(input: $input, condition: $condition) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateThreadMutationVariables,
  APITypes.CreateThreadMutation
>;
export const updateThread = /* GraphQL */ `mutation UpdateThread(
  $input: UpdateThreadInput!
  $condition: ModelThreadConditionInput
) {
  updateThread(input: $input, condition: $condition) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateThreadMutationVariables,
  APITypes.UpdateThreadMutation
>;
export const deleteThread = /* GraphQL */ `mutation DeleteThread(
  $input: DeleteThreadInput!
  $condition: ModelThreadConditionInput
) {
  deleteThread(input: $input, condition: $condition) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteThreadMutationVariables,
  APITypes.DeleteThreadMutation
>;
export const createPickupProduct = /* GraphQL */ `mutation CreatePickupProduct(
  $input: CreatePickupProductInput!
  $condition: ModelPickupProductConditionInput
) {
  createPickupProduct(input: $input, condition: $condition) {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePickupProductMutationVariables,
  APITypes.CreatePickupProductMutation
>;
export const updatePickupProduct = /* GraphQL */ `mutation UpdatePickupProduct(
  $input: UpdatePickupProductInput!
  $condition: ModelPickupProductConditionInput
) {
  updatePickupProduct(input: $input, condition: $condition) {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePickupProductMutationVariables,
  APITypes.UpdatePickupProductMutation
>;
export const deletePickupProduct = /* GraphQL */ `mutation DeletePickupProduct(
  $input: DeletePickupProductInput!
  $condition: ModelPickupProductConditionInput
) {
  deletePickupProduct(input: $input, condition: $condition) {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePickupProductMutationVariables,
  APITypes.DeletePickupProductMutation
>;
export const createPickupBrands = /* GraphQL */ `mutation CreatePickupBrands(
  $input: CreatePickupBrandsInput!
  $condition: ModelPickupBrandsConditionInput
) {
  createPickupBrands(input: $input, condition: $condition) {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePickupBrandsMutationVariables,
  APITypes.CreatePickupBrandsMutation
>;
export const updatePickupBrands = /* GraphQL */ `mutation UpdatePickupBrands(
  $input: UpdatePickupBrandsInput!
  $condition: ModelPickupBrandsConditionInput
) {
  updatePickupBrands(input: $input, condition: $condition) {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePickupBrandsMutationVariables,
  APITypes.UpdatePickupBrandsMutation
>;
export const deletePickupBrands = /* GraphQL */ `mutation DeletePickupBrands(
  $input: DeletePickupBrandsInput!
  $condition: ModelPickupBrandsConditionInput
) {
  deletePickupBrands(input: $input, condition: $condition) {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePickupBrandsMutationVariables,
  APITypes.DeletePickupBrandsMutation
>;
export const createMenu = /* GraphQL */ `mutation CreateMenu(
  $input: CreateMenuInput!
  $condition: ModelMenuConditionInput
) {
  createMenu(input: $input, condition: $condition) {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenuMutationVariables,
  APITypes.CreateMenuMutation
>;
export const updateMenu = /* GraphQL */ `mutation UpdateMenu(
  $input: UpdateMenuInput!
  $condition: ModelMenuConditionInput
) {
  updateMenu(input: $input, condition: $condition) {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMenuMutationVariables,
  APITypes.UpdateMenuMutation
>;
export const deleteMenu = /* GraphQL */ `mutation DeleteMenu(
  $input: DeleteMenuInput!
  $condition: ModelMenuConditionInput
) {
  deleteMenu(input: $input, condition: $condition) {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMenuMutationVariables,
  APITypes.DeleteMenuMutation
>;
export const createFavoriteBrand = /* GraphQL */ `mutation CreateFavoriteBrand(
  $input: CreateFavoriteBrandInput!
  $condition: ModelFavoriteBrandConditionInput
) {
  createFavoriteBrand(input: $input, condition: $condition) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavoriteBrandMutationVariables,
  APITypes.CreateFavoriteBrandMutation
>;
export const updateFavoriteBrand = /* GraphQL */ `mutation UpdateFavoriteBrand(
  $input: UpdateFavoriteBrandInput!
  $condition: ModelFavoriteBrandConditionInput
) {
  updateFavoriteBrand(input: $input, condition: $condition) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavoriteBrandMutationVariables,
  APITypes.UpdateFavoriteBrandMutation
>;
export const deleteFavoriteBrand = /* GraphQL */ `mutation DeleteFavoriteBrand(
  $input: DeleteFavoriteBrandInput!
  $condition: ModelFavoriteBrandConditionInput
) {
  deleteFavoriteBrand(input: $input, condition: $condition) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavoriteBrandMutationVariables,
  APITypes.DeleteFavoriteBrandMutation
>;
export const createFavoriteProduct = /* GraphQL */ `mutation CreateFavoriteProduct(
  $input: CreateFavoriteProductInput!
  $condition: ModelFavoriteProductConditionInput
) {
  createFavoriteProduct(input: $input, condition: $condition) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavoriteProductMutationVariables,
  APITypes.CreateFavoriteProductMutation
>;
export const updateFavoriteProduct = /* GraphQL */ `mutation UpdateFavoriteProduct(
  $input: UpdateFavoriteProductInput!
  $condition: ModelFavoriteProductConditionInput
) {
  updateFavoriteProduct(input: $input, condition: $condition) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavoriteProductMutationVariables,
  APITypes.UpdateFavoriteProductMutation
>;
export const deleteFavoriteProduct = /* GraphQL */ `mutation DeleteFavoriteProduct(
  $input: DeleteFavoriteProductInput!
  $condition: ModelFavoriteProductConditionInput
) {
  deleteFavoriteProduct(input: $input, condition: $condition) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavoriteProductMutationVariables,
  APITypes.DeleteFavoriteProductMutation
>;
export const createFavoriteProductList = /* GraphQL */ `mutation CreateFavoriteProductList(
  $input: CreateFavoriteProductListInput!
  $condition: ModelFavoriteProductListConditionInput
) {
  createFavoriteProductList(input: $input, condition: $condition) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavoriteProductListMutationVariables,
  APITypes.CreateFavoriteProductListMutation
>;
export const updateFavoriteProductList = /* GraphQL */ `mutation UpdateFavoriteProductList(
  $input: UpdateFavoriteProductListInput!
  $condition: ModelFavoriteProductListConditionInput
) {
  updateFavoriteProductList(input: $input, condition: $condition) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavoriteProductListMutationVariables,
  APITypes.UpdateFavoriteProductListMutation
>;
export const deleteFavoriteProductList = /* GraphQL */ `mutation DeleteFavoriteProductList(
  $input: DeleteFavoriteProductListInput!
  $condition: ModelFavoriteProductListConditionInput
) {
  deleteFavoriteProductList(input: $input, condition: $condition) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavoriteProductListMutationVariables,
  APITypes.DeleteFavoriteProductListMutation
>;
export const createCollection = /* GraphQL */ `mutation CreateCollection(
  $input: CreateCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  createCollection(input: $input, condition: $condition) {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionMutationVariables,
  APITypes.CreateCollectionMutation
>;
export const updateCollection = /* GraphQL */ `mutation UpdateCollection(
  $input: UpdateCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  updateCollection(input: $input, condition: $condition) {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCollectionMutationVariables,
  APITypes.UpdateCollectionMutation
>;
export const deleteCollection = /* GraphQL */ `mutation DeleteCollection(
  $input: DeleteCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  deleteCollection(input: $input, condition: $condition) {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCollectionMutationVariables,
  APITypes.DeleteCollectionMutation
>;
export const createProductCollection = /* GraphQL */ `mutation CreateProductCollection(
  $input: CreateProductCollectionInput!
  $condition: ModelProductCollectionConditionInput
) {
  createProductCollection(input: $input, condition: $condition) {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductCollectionMutationVariables,
  APITypes.CreateProductCollectionMutation
>;
export const updateProductCollection = /* GraphQL */ `mutation UpdateProductCollection(
  $input: UpdateProductCollectionInput!
  $condition: ModelProductCollectionConditionInput
) {
  updateProductCollection(input: $input, condition: $condition) {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductCollectionMutationVariables,
  APITypes.UpdateProductCollectionMutation
>;
export const deleteProductCollection = /* GraphQL */ `mutation DeleteProductCollection(
  $input: DeleteProductCollectionInput!
  $condition: ModelProductCollectionConditionInput
) {
  deleteProductCollection(input: $input, condition: $condition) {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductCollectionMutationVariables,
  APITypes.DeleteProductCollectionMutation
>;
export const createReferral = /* GraphQL */ `mutation CreateReferral(
  $input: CreateReferralInput!
  $condition: ModelReferralConditionInput
) {
  createReferral(input: $input, condition: $condition) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReferralMutationVariables,
  APITypes.CreateReferralMutation
>;
export const updateReferral = /* GraphQL */ `mutation UpdateReferral(
  $input: UpdateReferralInput!
  $condition: ModelReferralConditionInput
) {
  updateReferral(input: $input, condition: $condition) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReferralMutationVariables,
  APITypes.UpdateReferralMutation
>;
export const deleteReferral = /* GraphQL */ `mutation DeleteReferral(
  $input: DeleteReferralInput!
  $condition: ModelReferralConditionInput
) {
  deleteReferral(input: $input, condition: $condition) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReferralMutationVariables,
  APITypes.DeleteReferralMutation
>;
export const createReferralMessage = /* GraphQL */ `mutation CreateReferralMessage(
  $input: CreateReferralMessageInput!
  $condition: ModelReferralMessageConditionInput
) {
  createReferralMessage(input: $input, condition: $condition) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReferralMessageMutationVariables,
  APITypes.CreateReferralMessageMutation
>;
export const updateReferralMessage = /* GraphQL */ `mutation UpdateReferralMessage(
  $input: UpdateReferralMessageInput!
  $condition: ModelReferralMessageConditionInput
) {
  updateReferralMessage(input: $input, condition: $condition) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReferralMessageMutationVariables,
  APITypes.UpdateReferralMessageMutation
>;
export const deleteReferralMessage = /* GraphQL */ `mutation DeleteReferralMessage(
  $input: DeleteReferralMessageInput!
  $condition: ModelReferralMessageConditionInput
) {
  deleteReferralMessage(input: $input, condition: $condition) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReferralMessageMutationVariables,
  APITypes.DeleteReferralMessageMutation
>;
export const createPoint = /* GraphQL */ `mutation CreatePoint(
  $input: CreatePointInput!
  $condition: ModelPointConditionInput
) {
  createPoint(input: $input, condition: $condition) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePointMutationVariables,
  APITypes.CreatePointMutation
>;
export const updatePoint = /* GraphQL */ `mutation UpdatePoint(
  $input: UpdatePointInput!
  $condition: ModelPointConditionInput
) {
  updatePoint(input: $input, condition: $condition) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePointMutationVariables,
  APITypes.UpdatePointMutation
>;
export const deletePoint = /* GraphQL */ `mutation DeletePoint(
  $input: DeletePointInput!
  $condition: ModelPointConditionInput
) {
  deletePoint(input: $input, condition: $condition) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePointMutationVariables,
  APITypes.DeletePointMutation
>;
export const createPointHistory = /* GraphQL */ `mutation CreatePointHistory(
  $input: CreatePointHistoryInput!
  $condition: ModelPointHistoryConditionInput
) {
  createPointHistory(input: $input, condition: $condition) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePointHistoryMutationVariables,
  APITypes.CreatePointHistoryMutation
>;
export const updatePointHistory = /* GraphQL */ `mutation UpdatePointHistory(
  $input: UpdatePointHistoryInput!
  $condition: ModelPointHistoryConditionInput
) {
  updatePointHistory(input: $input, condition: $condition) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePointHistoryMutationVariables,
  APITypes.UpdatePointHistoryMutation
>;
export const deletePointHistory = /* GraphQL */ `mutation DeletePointHistory(
  $input: DeletePointHistoryInput!
  $condition: ModelPointHistoryConditionInput
) {
  deletePointHistory(input: $input, condition: $condition) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePointHistoryMutationVariables,
  APITypes.DeletePointHistoryMutation
>;
export const createContact = /* GraphQL */ `mutation CreateContact(
  $input: CreateContactInput!
  $condition: ModelContactConditionInput
) {
  createContact(input: $input, condition: $condition) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactMutationVariables,
  APITypes.CreateContactMutation
>;
export const updateContact = /* GraphQL */ `mutation UpdateContact(
  $input: UpdateContactInput!
  $condition: ModelContactConditionInput
) {
  updateContact(input: $input, condition: $condition) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactMutationVariables,
  APITypes.UpdateContactMutation
>;
export const deleteContact = /* GraphQL */ `mutation DeleteContact(
  $input: DeleteContactInput!
  $condition: ModelContactConditionInput
) {
  deleteContact(input: $input, condition: $condition) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactMutationVariables,
  APITypes.DeleteContactMutation
>;
export const createContactList = /* GraphQL */ `mutation CreateContactList(
  $input: CreateContactListInput!
  $condition: ModelContactListConditionInput
) {
  createContactList(input: $input, condition: $condition) {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactListMutationVariables,
  APITypes.CreateContactListMutation
>;
export const updateContactList = /* GraphQL */ `mutation UpdateContactList(
  $input: UpdateContactListInput!
  $condition: ModelContactListConditionInput
) {
  updateContactList(input: $input, condition: $condition) {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactListMutationVariables,
  APITypes.UpdateContactListMutation
>;
export const deleteContactList = /* GraphQL */ `mutation DeleteContactList(
  $input: DeleteContactListInput!
  $condition: ModelContactListConditionInput
) {
  deleteContactList(input: $input, condition: $condition) {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactListMutationVariables,
  APITypes.DeleteContactListMutation
>;
export const createContactListConnection = /* GraphQL */ `mutation CreateContactListConnection(
  $input: CreateContactListConnectionInput!
  $condition: ModelContactListConnectionConditionInput
) {
  createContactListConnection(input: $input, condition: $condition) {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactListConnectionMutationVariables,
  APITypes.CreateContactListConnectionMutation
>;
export const updateContactListConnection = /* GraphQL */ `mutation UpdateContactListConnection(
  $input: UpdateContactListConnectionInput!
  $condition: ModelContactListConnectionConditionInput
) {
  updateContactListConnection(input: $input, condition: $condition) {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactListConnectionMutationVariables,
  APITypes.UpdateContactListConnectionMutation
>;
export const deleteContactListConnection = /* GraphQL */ `mutation DeleteContactListConnection(
  $input: DeleteContactListConnectionInput!
  $condition: ModelContactListConnectionConditionInput
) {
  deleteContactListConnection(input: $input, condition: $condition) {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactListConnectionMutationVariables,
  APITypes.DeleteContactListConnectionMutation
>;
export const createContactActivity = /* GraphQL */ `mutation CreateContactActivity(
  $input: CreateContactActivityInput!
  $condition: ModelContactActivityConditionInput
) {
  createContactActivity(input: $input, condition: $condition) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactActivityMutationVariables,
  APITypes.CreateContactActivityMutation
>;
export const updateContactActivity = /* GraphQL */ `mutation UpdateContactActivity(
  $input: UpdateContactActivityInput!
  $condition: ModelContactActivityConditionInput
) {
  updateContactActivity(input: $input, condition: $condition) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactActivityMutationVariables,
  APITypes.UpdateContactActivityMutation
>;
export const deleteContactActivity = /* GraphQL */ `mutation DeleteContactActivity(
  $input: DeleteContactActivityInput!
  $condition: ModelContactActivityConditionInput
) {
  deleteContactActivity(input: $input, condition: $condition) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactActivityMutationVariables,
  APITypes.DeleteContactActivityMutation
>;
export const createCampaign = /* GraphQL */ `mutation CreateCampaign(
  $input: CreateCampaignInput!
  $condition: ModelCampaignConditionInput
) {
  createCampaign(input: $input, condition: $condition) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCampaignMutationVariables,
  APITypes.CreateCampaignMutation
>;
export const updateCampaign = /* GraphQL */ `mutation UpdateCampaign(
  $input: UpdateCampaignInput!
  $condition: ModelCampaignConditionInput
) {
  updateCampaign(input: $input, condition: $condition) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampaignMutationVariables,
  APITypes.UpdateCampaignMutation
>;
export const deleteCampaign = /* GraphQL */ `mutation DeleteCampaign(
  $input: DeleteCampaignInput!
  $condition: ModelCampaignConditionInput
) {
  deleteCampaign(input: $input, condition: $condition) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCampaignMutationVariables,
  APITypes.DeleteCampaignMutation
>;
export const createCampaignContact = /* GraphQL */ `mutation CreateCampaignContact(
  $input: CreateCampaignContactInput!
  $condition: ModelCampaignContactConditionInput
) {
  createCampaignContact(input: $input, condition: $condition) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCampaignContactMutationVariables,
  APITypes.CreateCampaignContactMutation
>;
export const updateCampaignContact = /* GraphQL */ `mutation UpdateCampaignContact(
  $input: UpdateCampaignContactInput!
  $condition: ModelCampaignContactConditionInput
) {
  updateCampaignContact(input: $input, condition: $condition) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampaignContactMutationVariables,
  APITypes.UpdateCampaignContactMutation
>;
export const deleteCampaignContact = /* GraphQL */ `mutation DeleteCampaignContact(
  $input: DeleteCampaignContactInput!
  $condition: ModelCampaignContactConditionInput
) {
  deleteCampaignContact(input: $input, condition: $condition) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCampaignContactMutationVariables,
  APITypes.DeleteCampaignContactMutation
>;
export const createDailySession = /* GraphQL */ `mutation CreateDailySession(
  $input: CreateDailySessionInput!
  $condition: ModelDailySessionConditionInput
) {
  createDailySession(input: $input, condition: $condition) {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailySessionMutationVariables,
  APITypes.CreateDailySessionMutation
>;
export const updateDailySession = /* GraphQL */ `mutation UpdateDailySession(
  $input: UpdateDailySessionInput!
  $condition: ModelDailySessionConditionInput
) {
  updateDailySession(input: $input, condition: $condition) {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailySessionMutationVariables,
  APITypes.UpdateDailySessionMutation
>;
export const deleteDailySession = /* GraphQL */ `mutation DeleteDailySession(
  $input: DeleteDailySessionInput!
  $condition: ModelDailySessionConditionInput
) {
  deleteDailySession(input: $input, condition: $condition) {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailySessionMutationVariables,
  APITypes.DeleteDailySessionMutation
>;
export const createConversionRate = /* GraphQL */ `mutation CreateConversionRate(
  $input: CreateConversionRateInput!
  $condition: ModelConversionRateConditionInput
) {
  createConversionRate(input: $input, condition: $condition) {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversionRateMutationVariables,
  APITypes.CreateConversionRateMutation
>;
export const updateConversionRate = /* GraphQL */ `mutation UpdateConversionRate(
  $input: UpdateConversionRateInput!
  $condition: ModelConversionRateConditionInput
) {
  updateConversionRate(input: $input, condition: $condition) {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversionRateMutationVariables,
  APITypes.UpdateConversionRateMutation
>;
export const deleteConversionRate = /* GraphQL */ `mutation DeleteConversionRate(
  $input: DeleteConversionRateInput!
  $condition: ModelConversionRateConditionInput
) {
  deleteConversionRate(input: $input, condition: $condition) {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversionRateMutationVariables,
  APITypes.DeleteConversionRateMutation
>;
export const createPageView = /* GraphQL */ `mutation CreatePageView(
  $input: CreatePageViewInput!
  $condition: ModelPageViewConditionInput
) {
  createPageView(input: $input, condition: $condition) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePageViewMutationVariables,
  APITypes.CreatePageViewMutation
>;
export const updatePageView = /* GraphQL */ `mutation UpdatePageView(
  $input: UpdatePageViewInput!
  $condition: ModelPageViewConditionInput
) {
  updatePageView(input: $input, condition: $condition) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePageViewMutationVariables,
  APITypes.UpdatePageViewMutation
>;
export const deletePageView = /* GraphQL */ `mutation DeletePageView(
  $input: DeletePageViewInput!
  $condition: ModelPageViewConditionInput
) {
  deletePageView(input: $input, condition: $condition) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePageViewMutationVariables,
  APITypes.DeletePageViewMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createReviewReply = /* GraphQL */ `mutation CreateReviewReply(
  $input: CreateReviewReplyInput!
  $condition: ModelReviewReplyConditionInput
) {
  createReviewReply(input: $input, condition: $condition) {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewReplyMutationVariables,
  APITypes.CreateReviewReplyMutation
>;
export const updateReviewReply = /* GraphQL */ `mutation UpdateReviewReply(
  $input: UpdateReviewReplyInput!
  $condition: ModelReviewReplyConditionInput
) {
  updateReviewReply(input: $input, condition: $condition) {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewReplyMutationVariables,
  APITypes.UpdateReviewReplyMutation
>;
export const deleteReviewReply = /* GraphQL */ `mutation DeleteReviewReply(
  $input: DeleteReviewReplyInput!
  $condition: ModelReviewReplyConditionInput
) {
  deleteReviewReply(input: $input, condition: $condition) {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewReplyMutationVariables,
  APITypes.DeleteReviewReplyMutation
>;
export const createCoupon = /* GraphQL */ `mutation CreateCoupon(
  $input: CreateCouponInput!
  $condition: ModelCouponConditionInput
) {
  createCoupon(input: $input, condition: $condition) {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCouponMutationVariables,
  APITypes.CreateCouponMutation
>;
export const updateCoupon = /* GraphQL */ `mutation UpdateCoupon(
  $input: UpdateCouponInput!
  $condition: ModelCouponConditionInput
) {
  updateCoupon(input: $input, condition: $condition) {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCouponMutationVariables,
  APITypes.UpdateCouponMutation
>;
export const deleteCoupon = /* GraphQL */ `mutation DeleteCoupon(
  $input: DeleteCouponInput!
  $condition: ModelCouponConditionInput
) {
  deleteCoupon(input: $input, condition: $condition) {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCouponMutationVariables,
  APITypes.DeleteCouponMutation
>;
export const createCouponLog = /* GraphQL */ `mutation CreateCouponLog(
  $input: CreateCouponLogInput!
  $condition: ModelCouponLogConditionInput
) {
  createCouponLog(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCouponLogMutationVariables,
  APITypes.CreateCouponLogMutation
>;
export const updateCouponLog = /* GraphQL */ `mutation UpdateCouponLog(
  $input: UpdateCouponLogInput!
  $condition: ModelCouponLogConditionInput
) {
  updateCouponLog(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCouponLogMutationVariables,
  APITypes.UpdateCouponLogMutation
>;
export const deleteCouponLog = /* GraphQL */ `mutation DeleteCouponLog(
  $input: DeleteCouponLogInput!
  $condition: ModelCouponLogConditionInput
) {
  deleteCouponLog(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCouponLogMutationVariables,
  APITypes.DeleteCouponLogMutation
>;
export const createBuyerCoupon = /* GraphQL */ `mutation CreateBuyerCoupon(
  $input: CreateBuyerCouponInput!
  $condition: ModelBuyerCouponConditionInput
) {
  createBuyerCoupon(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuyerCouponMutationVariables,
  APITypes.CreateBuyerCouponMutation
>;
export const updateBuyerCoupon = /* GraphQL */ `mutation UpdateBuyerCoupon(
  $input: UpdateBuyerCouponInput!
  $condition: ModelBuyerCouponConditionInput
) {
  updateBuyerCoupon(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuyerCouponMutationVariables,
  APITypes.UpdateBuyerCouponMutation
>;
export const deleteBuyerCoupon = /* GraphQL */ `mutation DeleteBuyerCoupon(
  $input: DeleteBuyerCouponInput!
  $condition: ModelBuyerCouponConditionInput
) {
  deleteBuyerCoupon(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuyerCouponMutationVariables,
  APITypes.DeleteBuyerCouponMutation
>;
export const createCashback = /* GraphQL */ `mutation CreateCashback(
  $input: CreateCashbackInput!
  $condition: ModelCashbackConditionInput
) {
  createCashback(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCashbackMutationVariables,
  APITypes.CreateCashbackMutation
>;
export const updateCashback = /* GraphQL */ `mutation UpdateCashback(
  $input: UpdateCashbackInput!
  $condition: ModelCashbackConditionInput
) {
  updateCashback(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCashbackMutationVariables,
  APITypes.UpdateCashbackMutation
>;
export const deleteCashback = /* GraphQL */ `mutation DeleteCashback(
  $input: DeleteCashbackInput!
  $condition: ModelCashbackConditionInput
) {
  deleteCashback(input: $input, condition: $condition) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCashbackMutationVariables,
  APITypes.DeleteCashbackMutation
>;
export const createStockProduct = /* GraphQL */ `mutation CreateStockProduct(
  $input: CreateStockProductInput!
  $condition: ModelStockProductConditionInput
) {
  createStockProduct(input: $input, condition: $condition) {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStockProductMutationVariables,
  APITypes.CreateStockProductMutation
>;
export const updateStockProduct = /* GraphQL */ `mutation UpdateStockProduct(
  $input: UpdateStockProductInput!
  $condition: ModelStockProductConditionInput
) {
  updateStockProduct(input: $input, condition: $condition) {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStockProductMutationVariables,
  APITypes.UpdateStockProductMutation
>;
export const deleteStockProduct = /* GraphQL */ `mutation DeleteStockProduct(
  $input: DeleteStockProductInput!
  $condition: ModelStockProductConditionInput
) {
  deleteStockProduct(input: $input, condition: $condition) {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStockProductMutationVariables,
  APITypes.DeleteStockProductMutation
>;
export const createLogilessAuthToken = /* GraphQL */ `mutation CreateLogilessAuthToken(
  $input: CreateLogilessAuthTokenInput!
  $condition: ModelLogilessAuthTokenConditionInput
) {
  createLogilessAuthToken(input: $input, condition: $condition) {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogilessAuthTokenMutationVariables,
  APITypes.CreateLogilessAuthTokenMutation
>;
export const updateLogilessAuthToken = /* GraphQL */ `mutation UpdateLogilessAuthToken(
  $input: UpdateLogilessAuthTokenInput!
  $condition: ModelLogilessAuthTokenConditionInput
) {
  updateLogilessAuthToken(input: $input, condition: $condition) {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLogilessAuthTokenMutationVariables,
  APITypes.UpdateLogilessAuthTokenMutation
>;
export const deleteLogilessAuthToken = /* GraphQL */ `mutation DeleteLogilessAuthToken(
  $input: DeleteLogilessAuthTokenInput!
  $condition: ModelLogilessAuthTokenConditionInput
) {
  deleteLogilessAuthToken(input: $input, condition: $condition) {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLogilessAuthTokenMutationVariables,
  APITypes.DeleteLogilessAuthTokenMutation
>;
export const createBuyerReadModel = /* GraphQL */ `mutation CreateBuyerReadModel(
  $input: CreateBuyerReadModelInput!
  $condition: ModelBuyerReadModelConditionInput
) {
  createBuyerReadModel(input: $input, condition: $condition) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuyerReadModelMutationVariables,
  APITypes.CreateBuyerReadModelMutation
>;
export const updateBuyerReadModel = /* GraphQL */ `mutation UpdateBuyerReadModel(
  $input: UpdateBuyerReadModelInput!
  $condition: ModelBuyerReadModelConditionInput
) {
  updateBuyerReadModel(input: $input, condition: $condition) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuyerReadModelMutationVariables,
  APITypes.UpdateBuyerReadModelMutation
>;
export const deleteBuyerReadModel = /* GraphQL */ `mutation DeleteBuyerReadModel(
  $input: DeleteBuyerReadModelInput!
  $condition: ModelBuyerReadModelConditionInput
) {
  deleteBuyerReadModel(input: $input, condition: $condition) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuyerReadModelMutationVariables,
  APITypes.DeleteBuyerReadModelMutation
>;
export const createBrandReadModel = /* GraphQL */ `mutation CreateBrandReadModel(
  $input: CreateBrandReadModelInput!
  $condition: ModelBrandReadModelConditionInput
) {
  createBrandReadModel(input: $input, condition: $condition) {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandReadModelMutationVariables,
  APITypes.CreateBrandReadModelMutation
>;
export const updateBrandReadModel = /* GraphQL */ `mutation UpdateBrandReadModel(
  $input: UpdateBrandReadModelInput!
  $condition: ModelBrandReadModelConditionInput
) {
  updateBrandReadModel(input: $input, condition: $condition) {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandReadModelMutationVariables,
  APITypes.UpdateBrandReadModelMutation
>;
export const deleteBrandReadModel = /* GraphQL */ `mutation DeleteBrandReadModel(
  $input: DeleteBrandReadModelInput!
  $condition: ModelBrandReadModelConditionInput
) {
  deleteBrandReadModel(input: $input, condition: $condition) {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandReadModelMutationVariables,
  APITypes.DeleteBrandReadModelMutation
>;
export const createProductReadModel = /* GraphQL */ `mutation CreateProductReadModel(
  $input: CreateProductReadModelInput!
  $condition: ModelProductReadModelConditionInput
) {
  createProductReadModel(input: $input, condition: $condition) {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductReadModelMutationVariables,
  APITypes.CreateProductReadModelMutation
>;
export const updateProductReadModel = /* GraphQL */ `mutation UpdateProductReadModel(
  $input: UpdateProductReadModelInput!
  $condition: ModelProductReadModelConditionInput
) {
  updateProductReadModel(input: $input, condition: $condition) {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductReadModelMutationVariables,
  APITypes.UpdateProductReadModelMutation
>;
export const deleteProductReadModel = /* GraphQL */ `mutation DeleteProductReadModel(
  $input: DeleteProductReadModelInput!
  $condition: ModelProductReadModelConditionInput
) {
  deleteProductReadModel(input: $input, condition: $condition) {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductReadModelMutationVariables,
  APITypes.DeleteProductReadModelMutation
>;
export const createCollectionReadModel = /* GraphQL */ `mutation CreateCollectionReadModel(
  $input: CreateCollectionReadModelInput!
  $condition: ModelCollectionReadModelConditionInput
) {
  createCollectionReadModel(input: $input, condition: $condition) {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionReadModelMutationVariables,
  APITypes.CreateCollectionReadModelMutation
>;
export const updateCollectionReadModel = /* GraphQL */ `mutation UpdateCollectionReadModel(
  $input: UpdateCollectionReadModelInput!
  $condition: ModelCollectionReadModelConditionInput
) {
  updateCollectionReadModel(input: $input, condition: $condition) {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCollectionReadModelMutationVariables,
  APITypes.UpdateCollectionReadModelMutation
>;
export const deleteCollectionReadModel = /* GraphQL */ `mutation DeleteCollectionReadModel(
  $input: DeleteCollectionReadModelInput!
  $condition: ModelCollectionReadModelConditionInput
) {
  deleteCollectionReadModel(input: $input, condition: $condition) {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCollectionReadModelMutationVariables,
  APITypes.DeleteCollectionReadModelMutation
>;
export const createOrderReadModel = /* GraphQL */ `mutation CreateOrderReadModel(
  $input: CreateOrderReadModelInput!
  $condition: ModelOrderReadModelConditionInput
) {
  createOrderReadModel(input: $input, condition: $condition) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderReadModelMutationVariables,
  APITypes.CreateOrderReadModelMutation
>;
export const updateOrderReadModel = /* GraphQL */ `mutation UpdateOrderReadModel(
  $input: UpdateOrderReadModelInput!
  $condition: ModelOrderReadModelConditionInput
) {
  updateOrderReadModel(input: $input, condition: $condition) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderReadModelMutationVariables,
  APITypes.UpdateOrderReadModelMutation
>;
export const deleteOrderReadModel = /* GraphQL */ `mutation DeleteOrderReadModel(
  $input: DeleteOrderReadModelInput!
  $condition: ModelOrderReadModelConditionInput
) {
  deleteOrderReadModel(input: $input, condition: $condition) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderReadModelMutationVariables,
  APITypes.DeleteOrderReadModelMutation
>;
export const createOrderDetailReadModel = /* GraphQL */ `mutation CreateOrderDetailReadModel(
  $input: CreateOrderDetailReadModelInput!
  $condition: ModelOrderDetailReadModelConditionInput
) {
  createOrderDetailReadModel(input: $input, condition: $condition) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderDetailReadModelMutationVariables,
  APITypes.CreateOrderDetailReadModelMutation
>;
export const updateOrderDetailReadModel = /* GraphQL */ `mutation UpdateOrderDetailReadModel(
  $input: UpdateOrderDetailReadModelInput!
  $condition: ModelOrderDetailReadModelConditionInput
) {
  updateOrderDetailReadModel(input: $input, condition: $condition) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderDetailReadModelMutationVariables,
  APITypes.UpdateOrderDetailReadModelMutation
>;
export const deleteOrderDetailReadModel = /* GraphQL */ `mutation DeleteOrderDetailReadModel(
  $input: DeleteOrderDetailReadModelInput!
  $condition: ModelOrderDetailReadModelConditionInput
) {
  deleteOrderDetailReadModel(input: $input, condition: $condition) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderDetailReadModelMutationVariables,
  APITypes.DeleteOrderDetailReadModelMutation
>;
export const createReturnDetailReadModel = /* GraphQL */ `mutation CreateReturnDetailReadModel(
  $input: CreateReturnDetailReadModelInput!
  $condition: ModelReturnDetailReadModelConditionInput
) {
  createReturnDetailReadModel(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReturnDetailReadModelMutationVariables,
  APITypes.CreateReturnDetailReadModelMutation
>;
export const updateReturnDetailReadModel = /* GraphQL */ `mutation UpdateReturnDetailReadModel(
  $input: UpdateReturnDetailReadModelInput!
  $condition: ModelReturnDetailReadModelConditionInput
) {
  updateReturnDetailReadModel(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReturnDetailReadModelMutationVariables,
  APITypes.UpdateReturnDetailReadModelMutation
>;
export const deleteReturnDetailReadModel = /* GraphQL */ `mutation DeleteReturnDetailReadModel(
  $input: DeleteReturnDetailReadModelInput!
  $condition: ModelReturnDetailReadModelConditionInput
) {
  deleteReturnDetailReadModel(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReturnDetailReadModelMutationVariables,
  APITypes.DeleteReturnDetailReadModelMutation
>;
export const createContactReadModel = /* GraphQL */ `mutation CreateContactReadModel(
  $input: CreateContactReadModelInput!
  $condition: ModelContactReadModelConditionInput
) {
  createContactReadModel(input: $input, condition: $condition) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactReadModelMutationVariables,
  APITypes.CreateContactReadModelMutation
>;
export const updateContactReadModel = /* GraphQL */ `mutation UpdateContactReadModel(
  $input: UpdateContactReadModelInput!
  $condition: ModelContactReadModelConditionInput
) {
  updateContactReadModel(input: $input, condition: $condition) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactReadModelMutationVariables,
  APITypes.UpdateContactReadModelMutation
>;
export const deleteContactReadModel = /* GraphQL */ `mutation DeleteContactReadModel(
  $input: DeleteContactReadModelInput!
  $condition: ModelContactReadModelConditionInput
) {
  deleteContactReadModel(input: $input, condition: $condition) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactReadModelMutationVariables,
  APITypes.DeleteContactReadModelMutation
>;
export const createOrderEvent = /* GraphQL */ `mutation CreateOrderEvent(
  $input: CreateOrderEventInput!
  $condition: ModelOrderEventConditionInput
) {
  createOrderEvent(input: $input, condition: $condition) {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderEventMutationVariables,
  APITypes.CreateOrderEventMutation
>;
export const updateOrderEvent = /* GraphQL */ `mutation UpdateOrderEvent(
  $input: UpdateOrderEventInput!
  $condition: ModelOrderEventConditionInput
) {
  updateOrderEvent(input: $input, condition: $condition) {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderEventMutationVariables,
  APITypes.UpdateOrderEventMutation
>;
export const deleteOrderEvent = /* GraphQL */ `mutation DeleteOrderEvent(
  $input: DeleteOrderEventInput!
  $condition: ModelOrderEventConditionInput
) {
  deleteOrderEvent(input: $input, condition: $condition) {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderEventMutationVariables,
  APITypes.DeleteOrderEventMutation
>;
export const createOrderProductEvent = /* GraphQL */ `mutation CreateOrderProductEvent(
  $input: CreateOrderProductEventInput!
  $condition: ModelOrderProductEventConditionInput
) {
  createOrderProductEvent(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderProductEventMutationVariables,
  APITypes.CreateOrderProductEventMutation
>;
export const updateOrderProductEvent = /* GraphQL */ `mutation UpdateOrderProductEvent(
  $input: UpdateOrderProductEventInput!
  $condition: ModelOrderProductEventConditionInput
) {
  updateOrderProductEvent(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderProductEventMutationVariables,
  APITypes.UpdateOrderProductEventMutation
>;
export const deleteOrderProductEvent = /* GraphQL */ `mutation DeleteOrderProductEvent(
  $input: DeleteOrderProductEventInput!
  $condition: ModelOrderProductEventConditionInput
) {
  deleteOrderProductEvent(input: $input, condition: $condition) {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderProductEventMutationVariables,
  APITypes.DeleteOrderProductEventMutation
>;
export const createBrandEvent = /* GraphQL */ `mutation CreateBrandEvent(
  $input: CreateBrandEventInput!
  $condition: ModelBrandEventConditionInput
) {
  createBrandEvent(input: $input, condition: $condition) {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandEventMutationVariables,
  APITypes.CreateBrandEventMutation
>;
export const updateBrandEvent = /* GraphQL */ `mutation UpdateBrandEvent(
  $input: UpdateBrandEventInput!
  $condition: ModelBrandEventConditionInput
) {
  updateBrandEvent(input: $input, condition: $condition) {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandEventMutationVariables,
  APITypes.UpdateBrandEventMutation
>;
export const deleteBrandEvent = /* GraphQL */ `mutation DeleteBrandEvent(
  $input: DeleteBrandEventInput!
  $condition: ModelBrandEventConditionInput
) {
  deleteBrandEvent(input: $input, condition: $condition) {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandEventMutationVariables,
  APITypes.DeleteBrandEventMutation
>;
export const createProductEvent = /* GraphQL */ `mutation CreateProductEvent(
  $input: CreateProductEventInput!
  $condition: ModelProductEventConditionInput
) {
  createProductEvent(input: $input, condition: $condition) {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductEventMutationVariables,
  APITypes.CreateProductEventMutation
>;
export const updateProductEvent = /* GraphQL */ `mutation UpdateProductEvent(
  $input: UpdateProductEventInput!
  $condition: ModelProductEventConditionInput
) {
  updateProductEvent(input: $input, condition: $condition) {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductEventMutationVariables,
  APITypes.UpdateProductEventMutation
>;
export const deleteProductEvent = /* GraphQL */ `mutation DeleteProductEvent(
  $input: DeleteProductEventInput!
  $condition: ModelProductEventConditionInput
) {
  deleteProductEvent(input: $input, condition: $condition) {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductEventMutationVariables,
  APITypes.DeleteProductEventMutation
>;
export const createProductTypeEvent = /* GraphQL */ `mutation CreateProductTypeEvent(
  $input: CreateProductTypeEventInput!
  $condition: ModelProductTypeEventConditionInput
) {
  createProductTypeEvent(input: $input, condition: $condition) {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductTypeEventMutationVariables,
  APITypes.CreateProductTypeEventMutation
>;
export const updateProductTypeEvent = /* GraphQL */ `mutation UpdateProductTypeEvent(
  $input: UpdateProductTypeEventInput!
  $condition: ModelProductTypeEventConditionInput
) {
  updateProductTypeEvent(input: $input, condition: $condition) {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductTypeEventMutationVariables,
  APITypes.UpdateProductTypeEventMutation
>;
export const deleteProductTypeEvent = /* GraphQL */ `mutation DeleteProductTypeEvent(
  $input: DeleteProductTypeEventInput!
  $condition: ModelProductTypeEventConditionInput
) {
  deleteProductTypeEvent(input: $input, condition: $condition) {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductTypeEventMutationVariables,
  APITypes.DeleteProductTypeEventMutation
>;
export const sendEmail = /* GraphQL */ `mutation SendEmail($options: SendEmailInput!) {
  sendEmail(options: $options)
}
` as GeneratedMutation<
  APITypes.SendEmailMutationVariables,
  APITypes.SendEmailMutation
>;
export const createBuyerReferral = /* GraphQL */ `mutation CreateBuyerReferral(
  $email: String!
  $name: String
  $referralType: Int!
  $referralBrandId: String!
) {
  createBuyerReferral(
    email: $email
    name: $name
    referralType: $referralType
    referralBrandId: $referralBrandId
  ) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuyerReferralMutationVariables,
  APITypes.CreateBuyerReferralMutation
>;
export const createBuyerAccount = /* GraphQL */ `mutation CreateBuyerAccount($input: CreateBuyerAccountInput!) {
  createBuyerAccount(input: $input) {
    username
    token
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuyerAccountMutationVariables,
  APITypes.CreateBuyerAccountMutation
>;
export const sendInvitation = /* GraphQL */ `mutation SendInvitation(
  $brandId: String!
  $brandName: String!
  $contactIds: [String]
  $sender: String!
  $senderEmail: String!
  $subject: String!
  $body: String!
  $testmode: Boolean
) {
  sendInvitation(
    brandId: $brandId
    brandName: $brandName
    contactIds: $contactIds
    sender: $sender
    senderEmail: $senderEmail
    subject: $subject
    body: $body
    testmode: $testmode
  )
}
` as GeneratedMutation<
  APITypes.SendInvitationMutationVariables,
  APITypes.SendInvitationMutation
>;
export const createMagicLink = /* GraphQL */ `mutation CreateMagicLink($email: String!) {
  createMagicLink(email: $email)
}
` as GeneratedMutation<
  APITypes.CreateMagicLinkMutationVariables,
  APITypes.CreateMagicLinkMutation
>;
export const syncContactReadModel = /* GraphQL */ `mutation SyncContactReadModel($contactId: String) {
  syncContactReadModel(contactId: $contactId) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncContactReadModelMutationVariables,
  APITypes.SyncContactReadModelMutation
>;
