import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import { warn } from '~utils/log';

export const createUseCase = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    { state: RootState }
  >,
  options?: any
) =>
  createAsyncThunk(
    typePrefix,
    (async (arg, thunkApi) => {
      try {
        return await payloadCreator(arg, thunkApi);
      } catch (e) {
        warn(e as Error);
        throw e;
      }
    }) as AsyncThunkPayloadCreator<Returned, ThunkArg, { state: RootState }>,
    options
  );
