import { createReducer } from '@reduxjs/toolkit';
import { Account, clearAccount, setAccount } from '../actions/account';

type AccountState = Account | null;

const accountReducer = createReducer<AccountState>(null, {
  [setAccount.type]: (_, action) => action.payload,
  [clearAccount.type]: () => null,
});

export default accountReducer;
