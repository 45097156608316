import { createReducer } from '@reduxjs/toolkit';
import {
  BrandCollection,
  clearBrandCollections,
  removeBrandCollection,
  setBrandCollection,
  setBrandCollections,
} from '../actions';

const initialState: BrandCollection[] = [];

const brandCollectionsReducer = createReducer(initialState, {
  [setBrandCollections.type]: (_, { payload }) => payload,
  [setBrandCollection.type]: (state, { payload: { id, brandCollection } }) =>
    state.some((item) => item.id === id)
      ? state.map((item) => (item.id === id ? brandCollection : item))
      : [...state, brandCollection],
  [removeBrandCollection.type]: (state, { payload: { id } }) =>
    state.filter((item) => item.id !== id),
  [clearBrandCollections.type]: () => [],
});

export default brandCollectionsReducer;
