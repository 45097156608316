import { CognitoUser } from 'amazon-cognito-identity-js';
import { AnyAction, ActionCreator } from 'redux';

export type AuthUser = {
  username: string;
  userDataKey: string;
  preferredMFA: string;
  keyPrefix: string;
  deviceKey: string;
  authenticationFlowType: string;
  attributes: {
    sub: string;
    email_verified: 'true' | 'false';
    profile: 'supplier' | 'buyer' | 'admin' | 'individual';
    name: string;
    phone_number_verified: 'true' | 'false';
    phone_number: string;
    given_name: string;
    family_name: string;
    email: string;
  };
};

export const RECEIVE_AUTH_USER = 'RECEIVE_AUTH_USER';
export const receiveAuthUser: ActionCreator<AnyAction> = (
  user: CognitoUser & AuthUser
) => {
  return {
    type: RECEIVE_AUTH_USER,
    payload: user,
  };
};

export const CLEAR_AUTH_USER = 'CLEAR_AUTH_USER';
export const clearAuthUser: ActionCreator<AnyAction> = () => {
  return {
    type: CLEAR_AUTH_USER,
    payload: null,
  };
};
