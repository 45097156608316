import { BrandDetails, Product } from '../types';
import { ActionCreator } from '~types/redux';

export const RECEIVE_BRAND_DETAILS = 'BRAND/RECEIVE_BRAND_DETAILS';
export const receiveBrandDetails: ActionCreator<BrandDetails> = (info) => {
  return {
    type: RECEIVE_BRAND_DETAILS,
    payload: info,
  };
};

export const CLEAR_BRAND_DETAILS = 'BRAND/CLEAR_BRAND_DETAILS';
export const clearBrandDetails: ActionCreator = () => {
  return {
    type: CLEAR_BRAND_DETAILS,
  };
};

export const SET_BRAND_PRODUCTS = 'BRAND/SET_BRAND_PRODUCTS';
export const setBrandProducts: ActionCreator<Product[]> = (
  newBrandProducts
) => {
  return {
    type: SET_BRAND_PRODUCTS,
    payload: newBrandProducts,
  };
};

export const ADD_BRAND_PRODUCTS = 'BRAND/ADD_BRAND_PRODUCTS';
export const addBrandProducts: ActionCreator<Product[]> = (
  newBrandProducts
) => {
  return {
    type: ADD_BRAND_PRODUCTS,
    payload: newBrandProducts,
  };
};

export const CLEAR_ALL_BRAND_PRODUCTS = 'BRAND/CLEAR_ALL_BRAND_PRODUCTS';
export const clearAllBrandProducts: ActionCreator = () => {
  return {
    type: CLEAR_ALL_BRAND_PRODUCTS,
  };
};

export const GET_BRAND_INFO_START = 'BRAND/GET_BRAND_INFO_START';
export const getBrandInfoStart: ActionCreator = () => {
  return {
    type: GET_BRAND_INFO_START,
  };
};

export const GET_BRAND_PRODUCTS_START = 'BRAND/GET_BRAND_PRODUCTS_START';
export const getBrandProductsStart: ActionCreator = () => {
  return {
    type: GET_BRAND_PRODUCTS_START,
  };
};

export const GET_SIGNED_PRODUCTS_START = 'BRAND/GET_SIGNED_PRODUCTS_START';
export const getSignedBrandProductsStart: ActionCreator = () => {
  return {
    type: GET_SIGNED_PRODUCTS_START,
  };
};

export const RECEIVE_BRAND_PRODUCTS_NEXT_TOKEN =
  'BRAND/RECEIVE_BRAND_PRODUCTS_NEXT_TOKEN';
export const receiveBrandProductsNextToken: ActionCreator<string> = (
  nextToken: string
) => {
  return {
    type: RECEIVE_BRAND_PRODUCTS_NEXT_TOKEN,
    payload: nextToken,
  };
};

export const CLEAR_BRAND_PRODUCTS_NEXT_TOKEN =
  'BRAND/CLEAR_BRAND_PRODUCTS_NEXT_TOKEN';
export const clearBrandProductsNextToken: ActionCreator = () => {
  return {
    type: CLEAR_BRAND_PRODUCTS_NEXT_TOKEN,
  };
};
