import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';

export type ProjectType = 'mag';
export const AVAILABLE_PROJECT: ProjectType[] = ['mag'];

type ProjectState = {
  projectId?: ProjectType;
  logoUrl?: string;
  homeUrl?: string;
};

const projectSlice = createSlice({
  name: 'project',
  initialState: {} as ProjectState,
  reducers: {
    initializedProject: (_, { payload }: PayloadAction<ProjectState>) => {
      return payload;
    },
    clearedProject: () => ({}),
  },
});

export const { reducer } = projectSlice;
export const { initializedProject, clearedProject } = projectSlice.actions;

export const selectProjectState = (state: RootState) => state.project;
export const selectProjectId = createSelector(
  selectProjectState,
  (state) => state?.projectId
);
export const selectProjectLogoUrl = createSelector(
  selectProjectState,
  (state) => state?.logoUrl
);
export const selectProjectHomeUrl = createSelector(
  selectProjectState,
  (state) => state?.homeUrl
);
