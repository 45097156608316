import React, { useContext, useEffect } from 'react';
import { QueryContext } from '../..';
import { BRAND_SEARCH_INDEX_FEATURED } from '~constants/search';
import { BrandReadModel } from '~types/api';
import InstantSearchProvider, {
  useInstantSearch,
} from '~view/widgets/InstantSearchProvider';

const BrandList: React.VFC = React.memo(() => {
  const { setBrands } = useContext(QueryContext);
  const { hits } = useInstantSearch<BrandReadModel>();
  useEffect(() => {
    setBrands!(hits);
  }, [JSON.stringify(hits)]);
  return null;
});

const Brands: React.FC<{}> = () => {
  const { query } = useContext(QueryContext);
  return (
    <>
      <InstantSearchProvider
        indexName={BRAND_SEARCH_INDEX_FEATURED}
        query={query}
        hitsPerPage={5}
        filters="category_page_id:brands"
      >
        <BrandList />
      </InstantSearchProvider>
    </>
  );
};

export default Brands;
