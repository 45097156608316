import { createAction } from '@reduxjs/toolkit';

export type ReturnItem = {
  orderProductId: string;
  returnQuantity: number;
  returnReason: string;
};

const ACTION_BASE_TYPE = 'RETURNS';

export const setReturnItems = createAction<ReturnItem[]>(
  `${ACTION_BASE_TYPE}/SET_RETURN_ITEMS`
);

export const setReturnItem = createAction<ReturnItem>(
  `${ACTION_BASE_TYPE}/SET_RETURN_ITEM`
);

export const sendFeedbackStart = createAction(
  `${ACTION_BASE_TYPE}/SEND_FEEDBACK_START`
);

export const setReturnableAmount = createAction<number>(
  `${ACTION_BASE_TYPE}/SET_RETURNABLE_AMOUNT`
);

export const clearReturnableAmount = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_RETURNABLE_AMOUNT`
);
