import { createReducer } from '@reduxjs/toolkit';
import {
  addBrandProducts,
  receiveBrandDetails,
  receiveBrandProductsNextToken,
  setBrandProducts,
} from '../../actions/supplier/details';
import { Brand } from '~domain/brand/Brand';
import { ProductType } from '~domain/product/ProductType';

type State = {
  info: Brand | null;
  productTypes: ProductType[];
  nextToken: string | null;
};

const initialState: State = {
  info: null,
  productTypes: [],
  nextToken: null,
};

const supplierDetailsReducer = createReducer<State>(initialState, {
  [receiveBrandDetails.type]: (state, action) => ({
    ...state,
    info: action.payload,
  }),
  [addBrandProducts.type]: (state, action) => ({
    ...state,
    productTypes: [...state.productTypes, ...action.payload],
    lastItems: action.payload,
  }),
  [setBrandProducts.type]: (state, action) => ({
    ...state,
    productTypes: action.payload,
    lastItems: action.payload,
  }),
  [receiveBrandProductsNextToken.type]: (state, action) => ({
    ...state,
    nextToken: action.payload,
  }),
});

export default supplierDetailsReducer;
