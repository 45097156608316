export const PRODUCT_SEARCH_INDEX = process.env
  .REACT_APP_ALGOLIA_PRODUCT_INDEX_NAME!;
export const PRODUCT_SEARCH_INDEX_FEATURED = `${PRODUCT_SEARCH_INDEX}_featured`;
export const PRODUCT_SEARCH_INDEX_CREATED_AT_ASC = `${PRODUCT_SEARCH_INDEX}_createdAt_asc`;
export const PRODUCT_SEARCH_INDEX_CREATED_AT_DESC = `${PRODUCT_SEARCH_INDEX}_createdAt_desc`;
export const PRODUCT_SEARCH_INDEX_CREATED_AT_DESC_BY_BRAND = `${PRODUCT_SEARCH_INDEX}_createdAt_desc_by_brand`;
export const PRODUCT_SEARCH_INDEX_PRICE_ASC = `${PRODUCT_SEARCH_INDEX}_price_asc`;
export const PRODUCT_SEARCH_INDEX_PRICE_DESC = `${PRODUCT_SEARCH_INDEX}_price_desc`;
export const PRODUCT_SEARCH_INDEX_WHOLESALE_RATE_ASC = `${PRODUCT_SEARCH_INDEX}_wholesale_rate_asc`;
export const PRODUCT_SEARCH_INDEX_WHOLESALE_RATE_DESC = `${PRODUCT_SEARCH_INDEX}_wholesale_rate_desc`;
export const PRODUCT_SEARCH_INDEX_BRAND = `${PRODUCT_SEARCH_INDEX}_brand`;
export const PRODUCT_SEARCH_INDEX_GROUP_BY_BRAND = `${PRODUCT_SEARCH_INDEX}_group_by_brand`;
export const PRODUCT_SEARCH_INDEX_FEATURED_GROUP_BY_BRAND = `${PRODUCT_SEARCH_INDEX_FEATURED}_group_by_brand`;
export const PRODUCT_SEARCH_INDEX_FEATURED_QUERY_SUGGESTIONS = `${PRODUCT_SEARCH_INDEX_FEATURED}_query_suggestions`;

export const BRAND_SEARCH_INDEX = process.env
  .REACT_APP_ALGOLIA_BRAND_INDEX_NAME!;
export const BRAND_SEARCH_INDEX_FEATURED = `${BRAND_SEARCH_INDEX}_featured`;
export const BRAND_SEARCH_INDEX_CREATED_AT_ASC = `${BRAND_SEARCH_INDEX}_createdAt_asc`;
export const BRAND_SEARCH_INDEX_CREATED_AT_DESC = `${BRAND_SEARCH_INDEX}_createdAt_desc`;
