import { Storage } from 'aws-amplify';
import {
  createReportProduct,
  updateReportProduct,
} from '../../graphql/mutations';
import { Order } from './Order';
import { OrderProduct } from './OrderProduct';
import { Entity } from '~core/domain/Entity';
import { OrderDetailReadModel, OrderReadModel } from '~types/api';
import { sendReport } from '~utils/email';
import { executeQuery } from '~utils/graphql';
import { calculateTax } from '~utils/price';

export enum ReportProductType {
  Damaged = 'damaged',
  Missing = 'missing',
  Wrong = 'wrong',
}

export const ReportProductTypeLabel = {
  [ReportProductType.Damaged]: '商品に破損や汚れがある',
  [ReportProductType.Missing]: '数量が不足している',
  [ReportProductType.Wrong]: '注文と異なる商品が届いた',
};

export enum ReportRequestType {
  Cancel = 'cancel',
  Replace = 'replace',
}

export const ReportRequestTypeLabel = {
  [ReportRequestType.Cancel]: 'キャンセルを希望する',
  [ReportRequestType.Replace]: '再送を希望する',
};

export enum ReportProductStatus {
  Progress = 'progress',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const ReportProductStatusLabel = {
  [ReportProductStatus.Progress]: '確認中',
  [ReportProductStatus.Approved]: '承認済み',
  [ReportProductStatus.Rejected]: '否認済み',
};

export type TReportProduct = {
  id?: string;
  order_id: string;
  order_product_id: string;
  report_type: ReportProductType;
  request_type: ReportRequestType;
  report_quantity?: number;
  report_product_price?: number;
  report_status: ReportProductStatus;
  report_comment: string;
  replace_order_id?: string;
  replace_order_product_id?: string;
  approval_date?: string;
  approval_quantity?: number;
  image_keys?: string[];
  owners?: string[];
  createdAt?: string;
  order?: Order;
  orderproduct?: OrderProduct;
  replaceOrder?: Order;
  replaceOrderproduct?: OrderProduct;
};

export type SendReportInput = {
  detail: OrderDetailReadModel;
  reportType: ReportProductType;
  quantity?: number;
  comment: string;
  images?: File[];
  requestType: ReportRequestType;
};

export class ReportProduct extends Entity<TReportProduct> {
  private constructor(params: TReportProduct) {
    super(params);
  }

  static createInstance({ ...reportProduct }: TReportProduct): ReportProduct {
    return new ReportProduct({
      ...reportProduct,
    });
  }

  static async sendReport(order: OrderReadModel, items: SendReportInput[]) {
    const results = await Promise.all(
      items.map(async ({ detail, ...item }) => {
        //登録
        const {
          data: { createReportProduct: result },
        } = await executeQuery(createReportProduct, {
          input: {
            type: 'report',
            order_id: order.id,
            order_product_id: detail.id,
            report_type: item.reportType,
            request_type: item.requestType,
            report_quantity: item.quantity,
            report_comment: item.comment,
            report_product_price: detail.price,
            report_status: ReportProductStatus.Progress,
            owners: order.owners,
          },
        });

        //画像のアップロード
        if (item.images?.length) {
          const imageKeys = await Promise.all(
            item.images.map(async (image) => {
              const upload: any = await Storage.put(
                `report/${result.id}/${image.name}`,
                image
              );
              return upload.key;
            })
          );
          const {
            data: { updateReportProduct: result2 },
          } = await executeQuery(updateReportProduct, {
            input: {
              id: result.id,
              image_keys: imageKeys,
            },
          });
          return result2;
        }

        return result;
      })
    );

    sendReport(order, results);

    return results;
  }

  get reportAmount() {
    const reportAmount = this.report_quantity! * this.report_product_price!;
    const tax = calculateTax(
      reportAmount,
      this.order?.tax && this.order.tax / 100
    );

    return reportAmount + tax;
  }
}
