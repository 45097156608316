/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPaymentMethods = /* GraphQL */ `query GetPaymentMethods($stripeId: String!) {
  getPaymentMethods(stripeId: $stripeId) {
    id
    object
    billing_details {
      address
      email
      name
      phone
      __typename
    }
    card {
      brand
      checks
      country
      exp_month
      exp_year
      fingerprint
      funding
      generated_from
      last4
      networks
      three_d_secure_usage
      wallet
      __typename
    }
    created
    customer
    livemode
    metadata {
      name
      __typename
    }
    type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentMethodsQueryVariables,
  APITypes.GetPaymentMethodsQuery
>;
export const attachPaymentMethod = /* GraphQL */ `query AttachPaymentMethod($stripeId: String!, $paymentMethodId: String!) {
  attachPaymentMethod(stripeId: $stripeId, paymentMethodId: $paymentMethodId) {
    id
    object
    billing_details {
      address
      email
      name
      phone
      __typename
    }
    card {
      brand
      checks
      country
      exp_month
      exp_year
      fingerprint
      funding
      generated_from
      last4
      networks
      three_d_secure_usage
      wallet
      __typename
    }
    created
    customer
    livemode
    metadata {
      name
      __typename
    }
    type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AttachPaymentMethodQueryVariables,
  APITypes.AttachPaymentMethodQuery
>;
export const isOrderCancelable = /* GraphQL */ `query IsOrderCancelable($orderId: String!) {
  isOrderCancelable(orderId: $orderId)
}
` as GeneratedQuery<
  APITypes.IsOrderCancelableQueryVariables,
  APITypes.IsOrderCancelableQuery
>;
export const getReturnDueDate = /* GraphQL */ `query GetReturnDueDate($orderId: String!) {
  getReturnDueDate(orderId: $orderId) {
    isReturnable
    dueDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReturnDueDateQueryVariables,
  APITypes.GetReturnDueDateQuery
>;
export const getReturnableAmount = /* GraphQL */ `query GetReturnableAmount(
  $accountId: String!
  $brandId: String
  $orderId: String
) {
  getReturnableAmount(
    accountId: $accountId
    brandId: $brandId
    orderId: $orderId
  )
}
` as GeneratedQuery<
  APITypes.GetReturnableAmountQueryVariables,
  APITypes.GetReturnableAmountQuery
>;
export const getNewOrders = /* GraphQL */ `query GetNewOrders($owner: String!) {
  getNewOrders(owner: $owner)
}
` as GeneratedQuery<
  APITypes.GetNewOrdersQueryVariables,
  APITypes.GetNewOrdersQuery
>;
export const getBlogPosts = /* GraphQL */ `query GetBlogPosts($limit: Int) {
  getBlogPosts(limit: $limit)
}
` as GeneratedQuery<
  APITypes.GetBlogPostsQueryVariables,
  APITypes.GetBlogPostsQuery
>;
export const getOrdersByDateRange = /* GraphQL */ `query GetOrdersByDateRange($owner: String!, $from: String, $to: String) {
  getOrdersByDateRange(owner: $owner, from: $from, to: $to) {
    items {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrdersByDateRangeQueryVariables,
  APITypes.GetOrdersByDateRangeQuery
>;
export const getOrderProductsByDateRange = /* GraphQL */ `query GetOrderProductsByDateRange(
  $owner: String!
  $fromDate: String
  $toDate: String
  $limit: Int
  $nextToken: String
) {
  getOrderProductsByDateRange(
    owner: $owner
    fromDate: $fromDate
    toDate: $toDate
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderProductsByDateRangeQueryVariables,
  APITypes.GetOrderProductsByDateRangeQuery
>;
export const getBillingCustomer = /* GraphQL */ `query GetBillingCustomer($customer_id: String!, $destination_id: String!) {
  getBillingCustomer(customer_id: $customer_id, destination_id: $destination_id)
}
` as GeneratedQuery<
  APITypes.GetBillingCustomerQueryVariables,
  APITypes.GetBillingCustomerQuery
>;
export const getFactoringCreditFacility = /* GraphQL */ `query GetFactoringCreditFacility($buyer_id: String!, $credit_ym: String) {
  getFactoringCreditFacility(buyer_id: $buyer_id, credit_ym: $credit_ym) {
    amount
    balance
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFactoringCreditFacilityQueryVariables,
  APITypes.GetFactoringCreditFacilityQuery
>;
export const getInvoiceCreditFacility = /* GraphQL */ `query GetInvoiceCreditFacility($buyer_id: String!, $credit_ym: String) {
  getInvoiceCreditFacility(buyer_id: $buyer_id, credit_ym: $credit_ym) {
    amount
    balance
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceCreditFacilityQueryVariables,
  APITypes.GetInvoiceCreditFacilityQuery
>;
export const listProfilesByCohort = /* GraphQL */ `query ListProfilesByCohort($cohortId: String!) {
  listProfilesByCohort(cohortId: $cohortId)
}
` as GeneratedQuery<
  APITypes.ListProfilesByCohortQueryVariables,
  APITypes.ListProfilesByCohortQuery
>;
export const getRecentlyViewedProducts = /* GraphQL */ `query GetRecentlyViewedProducts($userId: String!) {
  getRecentlyViewedProducts(userId: $userId)
}
` as GeneratedQuery<
  APITypes.GetRecentlyViewedProductsQueryVariables,
  APITypes.GetRecentlyViewedProductsQuery
>;
export const getCreditFacility = /* GraphQL */ `query GetCreditFacility($buyerId: String!, $ignoreOrderId: String) {
  getCreditFacility(buyerId: $buyerId, ignoreOrderId: $ignoreOrderId)
}
` as GeneratedQuery<
  APITypes.GetCreditFacilityQueryVariables,
  APITypes.GetCreditFacilityQuery
>;
export const getAccountByEmail = /* GraphQL */ `query GetAccountByEmail($email: String!) {
  getAccountByEmail(email: $email)
}
` as GeneratedQuery<
  APITypes.GetAccountByEmailQueryVariables,
  APITypes.GetAccountByEmailQuery
>;
export const getLogilessToken = /* GraphQL */ `query GetLogilessToken {
  getLogilessToken
}
` as GeneratedQuery<
  APITypes.GetLogilessTokenQueryVariables,
  APITypes.GetLogilessTokenQuery
>;
export const getNewLogilessToken = /* GraphQL */ `query GetNewLogilessToken($code: String) {
  getNewLogilessToken(code: $code)
}
` as GeneratedQuery<
  APITypes.GetNewLogilessTokenQueryVariables,
  APITypes.GetNewLogilessTokenQuery
>;
export const getNewLogilessTokenByRefreshToken = /* GraphQL */ `query GetNewLogilessTokenByRefreshToken($refresh_token: String) {
  getNewLogilessTokenByRefreshToken(refresh_token: $refresh_token)
}
` as GeneratedQuery<
  APITypes.GetNewLogilessTokenByRefreshTokenQueryVariables,
  APITypes.GetNewLogilessTokenByRefreshTokenQuery
>;
export const getLogilessProduct = /* GraphQL */ `query GetLogilessProduct($productId: String, $productTypeId: String) {
  getLogilessProduct(productId: $productId, productTypeId: $productTypeId)
}
` as GeneratedQuery<
  APITypes.GetLogilessProductQueryVariables,
  APITypes.GetLogilessProductQuery
>;
export const getLogilessProducts = /* GraphQL */ `query GetLogilessProducts($page: Int) {
  getLogilessProducts(page: $page)
}
` as GeneratedQuery<
  APITypes.GetLogilessProductsQueryVariables,
  APITypes.GetLogilessProductsQuery
>;
export const searchIndex = /* GraphQL */ `query SearchIndex(
  $indexName: String
  $query: String
  $filters: String
  $hitsPerPage: Int
  $attributesToRetrieve: [String]
) {
  searchIndex(
    indexName: $indexName
    query: $query
    filters: $filters
    hitsPerPage: $hitsPerPage
    attributesToRetrieve: $attributesToRetrieve
  )
}
` as GeneratedQuery<
  APITypes.SearchIndexQueryVariables,
  APITypes.SearchIndexQuery
>;
export const getLookerSignedEmbedUrl = /* GraphQL */ `query GetLookerSignedEmbedUrl($options: AWSJSON) {
  getLookerSignedEmbedUrl(options: $options)
}
` as GeneratedQuery<
  APITypes.GetLookerSignedEmbedUrlQueryVariables,
  APITypes.GetLookerSignedEmbedUrlQuery
>;
export const getContactLists = /* GraphQL */ `query GetContactLists($supplierId: String) {
  getContactLists(supplierId: $supplierId) {
    id
    item {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    totalContacts
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactListsQueryVariables,
  APITypes.GetContactListsQuery
>;
export const getTotalContacts = /* GraphQL */ `query GetTotalContacts(
  $supplierId: String
  $segment: String
  $listId: String
  $keywords: String
) {
  getTotalContacts(
    supplierId: $supplierId
    segment: $segment
    listId: $listId
    keywords: $keywords
  )
}
` as GeneratedQuery<
  APITypes.GetTotalContactsQueryVariables,
  APITypes.GetTotalContactsQuery
>;
export const listContactsAllBySupplier = /* GraphQL */ `query ListContactsAllBySupplier($supplier_id: ID!, $segment: String) {
  listContactsAllBySupplier(supplier_id: $supplier_id, segment: $segment) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsAllBySupplierQueryVariables,
  APITypes.ListContactsAllBySupplierQuery
>;
export const listCampaignContactsAllByCampaign = /* GraphQL */ `query ListCampaignContactsAllByCampaign($campaign_id: ID!, $filter: AWSJSON) {
  listCampaignContactsAllByCampaign(
    campaign_id: $campaign_id
    filter: $filter
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignContactsAllByCampaignQueryVariables,
  APITypes.ListCampaignContactsAllByCampaignQuery
>;
export const getQlikAuthToken = /* GraphQL */ `query GetQlikAuthToken($appId: String) {
  getQlikAuthToken(appId: $appId)
}
` as GeneratedQuery<
  APITypes.GetQlikAuthTokenQueryVariables,
  APITypes.GetQlikAuthTokenQuery
>;
export const getRecommendationProducts = /* GraphQL */ `query GetRecommendationProducts($userId: String, $count: Int) {
  getRecommendationProducts(userId: $userId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetRecommendationProductsQueryVariables,
  APITypes.GetRecommendationProductsQuery
>;
export const getRecommendationProducts2 = /* GraphQL */ `query GetRecommendationProducts2($userId: String, $count: Int) {
  getRecommendationProducts2(userId: $userId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetRecommendationProducts2QueryVariables,
  APITypes.GetRecommendationProducts2Query
>;
export const getPersonalizedProducts = /* GraphQL */ `query GetPersonalizedProducts(
  $userId: String
  $itemIds: [String]
  $personalizeRate: Int
) {
  getPersonalizedProducts(
    userId: $userId
    itemIds: $itemIds
    personalizeRate: $personalizeRate
  )
}
` as GeneratedQuery<
  APITypes.GetPersonalizedProductsQueryVariables,
  APITypes.GetPersonalizedProductsQuery
>;
export const getRecommendationFeatures = /* GraphQL */ `query GetRecommendationFeatures($userId: String, $count: Int) {
  getRecommendationFeatures(userId: $userId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetRecommendationFeaturesQueryVariables,
  APITypes.GetRecommendationFeaturesQuery
>;
export const getSimilarProducts = /* GraphQL */ `query GetSimilarProducts($userId: String!, $productId: String, $count: Int) {
  getSimilarProducts(userId: $userId, productId: $productId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetSimilarProductsQueryVariables,
  APITypes.GetSimilarProductsQuery
>;
export const getSimilarProducts2 = /* GraphQL */ `query GetSimilarProducts2($userId: String!, $productId: String, $count: Int) {
  getSimilarProducts2(userId: $userId, productId: $productId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetSimilarProducts2QueryVariables,
  APITypes.GetSimilarProducts2Query
>;
export const getRecommendationBrands = /* GraphQL */ `query GetRecommendationBrands($userId: String, $count: Int) {
  getRecommendationBrands(userId: $userId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetRecommendationBrandsQueryVariables,
  APITypes.GetRecommendationBrandsQuery
>;
export const getSimilarBrands = /* GraphQL */ `query GetSimilarBrands($userId: String!, $brandId: String, $count: Int) {
  getSimilarBrands(userId: $userId, brandId: $brandId, count: $count)
}
` as GeneratedQuery<
  APITypes.GetSimilarBrandsQueryVariables,
  APITypes.GetSimilarBrandsQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    email
    first_name
    last_name
    web_site_URL
    instagram_account
    zip_code
    location
    tel
    company_name
    term_approval_status
    createdAt
    updatedAt
    owner
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    supplierInfo {
      items {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const searchAccounts = /* GraphQL */ `query SearchAccounts(
  $filter: SearchableAccountFilterInput
  $sort: SearchableAccountSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchAccounts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAccountsQueryVariables,
  APITypes.SearchAccountsQuery
>;
export const getBuyerInfo = /* GraphQL */ `query GetBuyerInfo($id: ID!) {
  getBuyerInfo(id: $id) {
    id
    account_id
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_id
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_return_credit
    buyer_return_limit_per_brand
    buyer_type
    invoice_credit_facility
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    referral_brand_id
    shop_name
    store_category
    stripe_id
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    shop_images {
      items {
        id
        buyer_id
        image_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    shipping_address {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    referralBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBuyerInfoQueryVariables,
  APITypes.GetBuyerInfoQuery
>;
export const listBuyerInfos = /* GraphQL */ `query ListBuyerInfos(
  $filter: ModelBuyerInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerInfosQueryVariables,
  APITypes.ListBuyerInfosQuery
>;
export const listBuyerInfosByBuyer = /* GraphQL */ `query ListBuyerInfosByBuyer(
  $buyer_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelBuyerInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerInfosByBuyer(
    buyer_id: $buyer_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerInfosByBuyerQueryVariables,
  APITypes.ListBuyerInfosByBuyerQuery
>;
export const searchBuyerInfos = /* GraphQL */ `query SearchBuyerInfos(
  $filter: SearchableBuyerInfoFilterInput
  $sort: SearchableBuyerInfoSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBuyerInfos(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBuyerInfosQueryVariables,
  APITypes.SearchBuyerInfosQuery
>;
export const getShopImage = /* GraphQL */ `query GetShopImage($id: ID!) {
  getShopImage(id: $id) {
    id
    buyer_id
    image_key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShopImageQueryVariables,
  APITypes.GetShopImageQuery
>;
export const listShopImages = /* GraphQL */ `query ListShopImages(
  $filter: ModelShopImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listShopImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      buyer_id
      image_key
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShopImagesQueryVariables,
  APITypes.ListShopImagesQuery
>;
export const searchShopImages = /* GraphQL */ `query SearchShopImages(
  $filter: SearchableShopImageFilterInput
  $sort: SearchableShopImageSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchShopImages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      buyer_id
      image_key
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchShopImagesQueryVariables,
  APITypes.SearchShopImagesQuery
>;
export const getIndividual = /* GraphQL */ `query GetIndividual($buyer_id: ID!, $brand_id: ID!) {
  getIndividual(buyer_id: $buyer_id, brand_id: $brand_id) {
    brand_id
    buyer_id
    supplier_group_id
    createdAt
    updatedAt
    owner
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndividualQueryVariables,
  APITypes.GetIndividualQuery
>;
export const listIndividuals = /* GraphQL */ `query ListIndividuals(
  $buyer_id: ID
  $brand_id: ModelIDKeyConditionInput
  $filter: ModelIndividualFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIndividuals(
    buyer_id: $buyer_id
    brand_id: $brand_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      brand_id
      buyer_id
      supplier_group_id
      createdAt
      updatedAt
      owner
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualsQueryVariables,
  APITypes.ListIndividualsQuery
>;
export const searchIndividuals = /* GraphQL */ `query SearchIndividuals(
  $filter: SearchableIndividualFilterInput
  $sort: SearchableIndividualSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchIndividuals(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      brand_id
      buyer_id
      supplier_group_id
      createdAt
      updatedAt
      owner
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchIndividualsQueryVariables,
  APITypes.SearchIndividualsQuery
>;
export const getSupplierGroup = /* GraphQL */ `query GetSupplierGroup($id: ID!) {
  getSupplierGroup(id: $id) {
    id
    title
    description
    image_key
    published
    createdAt
    updatedAt
    owner
    brands {
      items {
        id
        company_name
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        is_published
        firstPublishedAt
        firstPublishedAtTimestamp
        lastPublishedAt
        lastPublishedAtTimestamp
        category_page_id
        product_category
        product_subcategory
        average_shipping_days
        service_rate
        review_average
        conversion_rate
        sell_through_rate
        reviews
        items
        conversion_rate_tier
        sell_through_rate_tier
        service_rate_rate_tier
        average_shipping_days_tier
        minimum_tier
        items_tier
        contacts_tier
        supplier_group_id
        supplier_group_title
        supplier_group_description
        supplier_group_image_key
        supplier_group_published
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        review {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupplierGroupQueryVariables,
  APITypes.GetSupplierGroupQuery
>;
export const listSupplierGroups = /* GraphQL */ `query ListSupplierGroups(
  $filter: ModelSupplierGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupplierGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupplierGroupsQueryVariables,
  APITypes.ListSupplierGroupsQuery
>;
export const searchSupplierGroups = /* GraphQL */ `query SearchSupplierGroups(
  $filter: SearchableSupplierGroupFilterInput
  $sort: SearchableSupplierGroupSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchSupplierGroups(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSupplierGroupsQueryVariables,
  APITypes.SearchSupplierGroupsQuery
>;
export const getSupplierInfo = /* GraphQL */ `query GetSupplierInfo($id: ID!) {
  getSupplierInfo(id: $id) {
    id
    account_id
    supplier_contract_type
    bank_name
    bank_branch_name
    bank_account_number
    bank_account_name
    bank_account_type
    createdAt
    updatedAt
    owner
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupplierInfoQueryVariables,
  APITypes.GetSupplierInfoQuery
>;
export const listSupplierInfos = /* GraphQL */ `query ListSupplierInfos(
  $filter: ModelSupplierInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupplierInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupplierInfosQueryVariables,
  APITypes.ListSupplierInfosQuery
>;
export const listSupplierInfosByAccountId = /* GraphQL */ `query ListSupplierInfosByAccountId(
  $account_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSupplierInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupplierInfosByAccountId(
    account_id: $account_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupplierInfosByAccountIdQueryVariables,
  APITypes.ListSupplierInfosByAccountIdQuery
>;
export const searchSupplierInfos = /* GraphQL */ `query SearchSupplierInfos(
  $filter: SearchableSupplierInfoFilterInput
  $sort: SearchableSupplierInfoSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchSupplierInfos(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSupplierInfosQueryVariables,
  APITypes.SearchSupplierInfosQuery
>;
export const getShippingAddress = /* GraphQL */ `query GetShippingAddress($id: ID!) {
  getShippingAddress(id: $id) {
    id
    name
    buyer_id
    zip_code
    prefecture
    city
    building
    phone_number
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShippingAddressQueryVariables,
  APITypes.GetShippingAddressQuery
>;
export const listShippingAddresss = /* GraphQL */ `query ListShippingAddresss(
  $filter: ModelShippingAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listShippingAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShippingAddresssQueryVariables,
  APITypes.ListShippingAddresssQuery
>;
export const searchShippingAddresss = /* GraphQL */ `query SearchShippingAddresss(
  $filter: SearchableShippingAddressFilterInput
  $sort: SearchableShippingAddressSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchShippingAddresss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      name
      buyer_id
      zip_code
      prefecture
      city
      building
      phone_number
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchShippingAddresssQueryVariables,
  APITypes.SearchShippingAddresssQuery
>;
export const getEmailPreferences = /* GraphQL */ `query GetEmailPreferences($id: ID!) {
  getEmailPreferences(id: $id) {
    id
    new_arrivals
    sale_items
    bounced
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmailPreferencesQueryVariables,
  APITypes.GetEmailPreferencesQuery
>;
export const listEmailPreferencess = /* GraphQL */ `query ListEmailPreferencess(
  $filter: ModelEmailPreferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmailPreferencess(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      new_arrivals
      sale_items
      bounced
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmailPreferencessQueryVariables,
  APITypes.ListEmailPreferencessQuery
>;
export const searchEmailPreferencess = /* GraphQL */ `query SearchEmailPreferencess(
  $filter: SearchableEmailPreferencesFilterInput
  $sort: SearchableEmailPreferencesSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchEmailPreferencess(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      new_arrivals
      sale_items
      bounced
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEmailPreferencessQueryVariables,
  APITypes.SearchEmailPreferencessQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    product_name
    product_number
    product_brand_id
    product_content
    product_description
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    product_price
    is_open_price
    product_jancode
    product_stock_quantity
    product_type
    product_color
    product_size
    product_public_status
    product_category
    product_subcategory
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    publishedAt
    createdAt
    updatedAt
    sort_recommended
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    owner
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    imageKeys {
      items {
        id
        product_id
        imageKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    videoKeys {
      items {
        id
        product_id
        videoKey
        createdAt
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    producttype {
      items {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    copiedBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const listProductsByBrand = /* GraphQL */ `query ListProductsByBrand(
  $product_brand_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductsByBrand(
    product_brand_id: $product_brand_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsByBrandQueryVariables,
  APITypes.ListProductsByBrandQuery
>;
export const listProductsBySku = /* GraphQL */ `query ListProductsBySku(
  $sku: String
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductsBySku(
    sku: $sku
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsBySkuQueryVariables,
  APITypes.ListProductsBySkuQuery
>;
export const listProductsByCopiedProduct = /* GraphQL */ `query ListProductsByCopiedProduct(
  $copied_product_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductsByCopiedProduct(
    copied_product_id: $copied_product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsByCopiedProductQueryVariables,
  APITypes.ListProductsByCopiedProductQuery
>;
export const searchProducts = /* GraphQL */ `query SearchProducts(
  $filter: SearchableProductFilterInput
  $sort: SearchableProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductsQueryVariables,
  APITypes.SearchProductsQuery
>;
export const getProductImageKey = /* GraphQL */ `query GetProductImageKey($id: ID!) {
  getProductImageKey(id: $id) {
    id
    product_id
    imageKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductImageKeyQueryVariables,
  APITypes.GetProductImageKeyQuery
>;
export const listProductImageKeys = /* GraphQL */ `query ListProductImageKeys(
  $filter: ModelProductImageKeyFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductImageKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      imageKey
      createdAt
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductImageKeysQueryVariables,
  APITypes.ListProductImageKeysQuery
>;
export const searchProductImageKeys = /* GraphQL */ `query SearchProductImageKeys(
  $filter: SearchableProductImageKeyFilterInput
  $sort: SearchableProductImageKeySortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProductImageKeys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      product_id
      imageKey
      createdAt
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductImageKeysQueryVariables,
  APITypes.SearchProductImageKeysQuery
>;
export const getProductVideoKey = /* GraphQL */ `query GetProductVideoKey($id: ID!) {
  getProductVideoKey(id: $id) {
    id
    product_id
    videoKey
    createdAt
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductVideoKeyQueryVariables,
  APITypes.GetProductVideoKeyQuery
>;
export const listProductVideoKeys = /* GraphQL */ `query ListProductVideoKeys(
  $filter: ModelProductVideoKeyFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductVideoKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      videoKey
      createdAt
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductVideoKeysQueryVariables,
  APITypes.ListProductVideoKeysQuery
>;
export const searchProductVideoKeys = /* GraphQL */ `query SearchProductVideoKeys(
  $filter: SearchableProductVideoKeyFilterInput
  $sort: SearchableProductVideoKeySortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProductVideoKeys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      product_id
      videoKey
      createdAt
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductVideoKeysQueryVariables,
  APITypes.SearchProductVideoKeysQuery
>;
export const getProductType = /* GraphQL */ `query GetProductType($id: ID!) {
  getProductType(id: $id) {
    id
    product_id
    imageKey
    type_name
    color
    size
    createdAt
    suspended
    published
    sku
    out_of_stock
    copied_product_type_id
    copied_brand_id
    owner
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    inventoryConnection {
      items {
        id
        brand_id
        product_id
        product_type_id
        sku
        inventory
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        logs {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductTypeQueryVariables,
  APITypes.GetProductTypeQuery
>;
export const listProductTypes = /* GraphQL */ `query ListProductTypes(
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypesQueryVariables,
  APITypes.ListProductTypesQuery
>;
export const listProductTypesByProduct = /* GraphQL */ `query ListProductTypesByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypesByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypesByProductQueryVariables,
  APITypes.ListProductTypesByProductQuery
>;
export const listProductTypesBySku = /* GraphQL */ `query ListProductTypesBySku(
  $sku: String
  $sortDirection: ModelSortDirection
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypesBySku(
    sku: $sku
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypesBySkuQueryVariables,
  APITypes.ListProductTypesBySkuQuery
>;
export const listProductTypesByCopiedProductType = /* GraphQL */ `query ListProductTypesByCopiedProductType(
  $copied_product_type_id: String
  $sortDirection: ModelSortDirection
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypesByCopiedProductType(
    copied_product_type_id: $copied_product_type_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypesByCopiedProductTypeQueryVariables,
  APITypes.ListProductTypesByCopiedProductTypeQuery
>;
export const searchProductTypes = /* GraphQL */ `query SearchProductTypes(
  $filter: SearchableProductTypeFilterInput
  $sort: SearchableProductTypeSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProductTypes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductTypesQueryVariables,
  APITypes.SearchProductTypesQuery
>;
export const getInventory = /* GraphQL */ `query GetInventory($id: ID!) {
  getInventory(id: $id) {
    id
    brand_id
    product_id
    product_type_id
    sku
    inventory
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    logs {
      items {
        id
        inventory_id
        brand_id
        product_id
        product_type_id
        sku
        stock_type
        stock_mode
        stock_date
        quantity
        order_id
        order_product_id
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInventoryQueryVariables,
  APITypes.GetInventoryQuery
>;
export const listInventorys = /* GraphQL */ `query ListInventorys(
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventorysQueryVariables,
  APITypes.ListInventorysQuery
>;
export const listInventoriesByBrand = /* GraphQL */ `query ListInventoriesByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoriesByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoriesByBrandQueryVariables,
  APITypes.ListInventoriesByBrandQuery
>;
export const listInventoriesByProduct = /* GraphQL */ `query ListInventoriesByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoriesByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoriesByProductQueryVariables,
  APITypes.ListInventoriesByProductQuery
>;
export const listInventoriesByProductType = /* GraphQL */ `query ListInventoriesByProductType(
  $product_type_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoriesByProductType(
    product_type_id: $product_type_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoriesByProductTypeQueryVariables,
  APITypes.ListInventoriesByProductTypeQuery
>;
export const listInventoriesBySku = /* GraphQL */ `query ListInventoriesBySku(
  $sku: String
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoriesBySku(
    sku: $sku
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoriesBySkuQueryVariables,
  APITypes.ListInventoriesBySkuQuery
>;
export const searchInventorys = /* GraphQL */ `query SearchInventorys(
  $filter: SearchableInventoryFilterInput
  $sort: SearchableInventorySortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchInventorys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      brand_id
      product_id
      product_type_id
      sku
      inventory
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      logs {
        items {
          id
          inventory_id
          brand_id
          product_id
          product_type_id
          sku
          stock_type
          stock_mode
          stock_date
          quantity
          order_id
          order_product_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchInventorysQueryVariables,
  APITypes.SearchInventorysQuery
>;
export const getReturnInventoryLog = /* GraphQL */ `query GetReturnInventoryLog($id: ID!) {
  getReturnInventoryLog(id: $id) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReturnInventoryLogQueryVariables,
  APITypes.GetReturnInventoryLogQuery
>;
export const listReturnInventoryLogs = /* GraphQL */ `query ListReturnInventoryLogs(
  $filter: ModelReturnInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnInventoryLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnInventoryLogsQueryVariables,
  APITypes.ListReturnInventoryLogsQuery
>;
export const listReturnInventoryLogsByInventory = /* GraphQL */ `query ListReturnInventoryLogsByInventory(
  $inventory_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnInventoryLogsByInventory(
    inventory_id: $inventory_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnInventoryLogsByInventoryQueryVariables,
  APITypes.ListReturnInventoryLogsByInventoryQuery
>;
export const listReturnInventoryLogsByBrand = /* GraphQL */ `query ListReturnInventoryLogsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnInventoryLogsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnInventoryLogsByBrandQueryVariables,
  APITypes.ListReturnInventoryLogsByBrandQuery
>;
export const listReturnInventoryLogsByProduct = /* GraphQL */ `query ListReturnInventoryLogsByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnInventoryLogsByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnInventoryLogsByProductQueryVariables,
  APITypes.ListReturnInventoryLogsByProductQuery
>;
export const listReturnInventoryLogsByProductType = /* GraphQL */ `query ListReturnInventoryLogsByProductType(
  $product_type_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnInventoryLogsByProductType(
    product_type_id: $product_type_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnInventoryLogsByProductTypeQueryVariables,
  APITypes.ListReturnInventoryLogsByProductTypeQuery
>;
export const listReturnInventoryLogsBySku = /* GraphQL */ `query ListReturnInventoryLogsBySku(
  $sku: String
  $sortDirection: ModelSortDirection
  $filter: ModelReturnInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnInventoryLogsBySku(
    sku: $sku
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnInventoryLogsBySkuQueryVariables,
  APITypes.ListReturnInventoryLogsBySkuQuery
>;
export const searchReturnInventoryLogs = /* GraphQL */ `query SearchReturnInventoryLogs(
  $filter: SearchableReturnInventoryLogFilterInput
  $sort: SearchableReturnInventoryLogSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReturnInventoryLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReturnInventoryLogsQueryVariables,
  APITypes.SearchReturnInventoryLogsQuery
>;
export const getInventoryLog = /* GraphQL */ `query GetInventoryLog($id: ID!) {
  getInventoryLog(id: $id) {
    id
    inventory_id
    brand_id
    product_id
    product_type_id
    sku
    stock_type
    stock_mode
    stock_date
    quantity
    order_id
    order_product_id
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInventoryLogQueryVariables,
  APITypes.GetInventoryLogQuery
>;
export const listInventoryLogs = /* GraphQL */ `query ListInventoryLogs(
  $filter: ModelInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoryLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoryLogsQueryVariables,
  APITypes.ListInventoryLogsQuery
>;
export const listInventoryLogsByInventory = /* GraphQL */ `query ListInventoryLogsByInventory(
  $inventory_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoryLogsByInventory(
    inventory_id: $inventory_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoryLogsByInventoryQueryVariables,
  APITypes.ListInventoryLogsByInventoryQuery
>;
export const listInventoryLogsByBrand = /* GraphQL */ `query ListInventoryLogsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoryLogsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoryLogsByBrandQueryVariables,
  APITypes.ListInventoryLogsByBrandQuery
>;
export const listInventoryLogsByProduct = /* GraphQL */ `query ListInventoryLogsByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoryLogsByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoryLogsByProductQueryVariables,
  APITypes.ListInventoryLogsByProductQuery
>;
export const listInventoryLogsByProductType = /* GraphQL */ `query ListInventoryLogsByProductType(
  $product_type_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoryLogsByProductType(
    product_type_id: $product_type_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoryLogsByProductTypeQueryVariables,
  APITypes.ListInventoryLogsByProductTypeQuery
>;
export const listInventoryLogsBySku = /* GraphQL */ `query ListInventoryLogsBySku(
  $sku: String
  $sortDirection: ModelSortDirection
  $filter: ModelInventoryLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventoryLogsBySku(
    sku: $sku
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoryLogsBySkuQueryVariables,
  APITypes.ListInventoryLogsBySkuQuery
>;
export const searchInventoryLogs = /* GraphQL */ `query SearchInventoryLogs(
  $filter: SearchableInventoryLogFilterInput
  $sort: SearchableInventoryLogSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchInventoryLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      inventory_id
      brand_id
      product_id
      product_type_id
      sku
      stock_type
      stock_mode
      stock_date
      quantity
      order_id
      order_product_id
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchInventoryLogsQueryVariables,
  APITypes.SearchInventoryLogsQuery
>;
export const getInStockNotification = /* GraphQL */ `query GetInStockNotification($id: ID!) {
  getInStockNotification(id: $id) {
    id
    buyer_id
    brand_id
    product_id
    product_type_id
    completed
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInStockNotificationQueryVariables,
  APITypes.GetInStockNotificationQuery
>;
export const listInStockNotifications = /* GraphQL */ `query ListInStockNotifications(
  $filter: ModelInStockNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInStockNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      brand_id
      product_id
      product_type_id
      completed
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInStockNotificationsQueryVariables,
  APITypes.ListInStockNotificationsQuery
>;
export const listInStockNotificationsByBuyer = /* GraphQL */ `query ListInStockNotificationsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInStockNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInStockNotificationsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      brand_id
      product_id
      product_type_id
      completed
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInStockNotificationsByBuyerQueryVariables,
  APITypes.ListInStockNotificationsByBuyerQuery
>;
export const listInStockNotificationsByProduct = /* GraphQL */ `query ListInStockNotificationsByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInStockNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInStockNotificationsByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      brand_id
      product_id
      product_type_id
      completed
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInStockNotificationsByProductQueryVariables,
  APITypes.ListInStockNotificationsByProductQuery
>;
export const listInStockNotificationsByProductType = /* GraphQL */ `query ListInStockNotificationsByProductType(
  $product_type_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInStockNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInStockNotificationsByProductType(
    product_type_id: $product_type_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      brand_id
      product_id
      product_type_id
      completed
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInStockNotificationsByProductTypeQueryVariables,
  APITypes.ListInStockNotificationsByProductTypeQuery
>;
export const searchInStockNotifications = /* GraphQL */ `query SearchInStockNotifications(
  $filter: SearchableInStockNotificationFilterInput
  $sort: SearchableInStockNotificationSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchInStockNotifications(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      buyer_id
      brand_id
      product_id
      product_type_id
      completed
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchInStockNotificationsQueryVariables,
  APITypes.SearchInStockNotificationsQuery
>;
export const getBrand = /* GraphQL */ `query GetBrand($id: ID!) {
  getBrand(id: $id) {
    id
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    updatedAt
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    supplier_group_id
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierGroup {
      id
      title
      description
      image_key
      published
      createdAt
      updatedAt
      owner
      brands {
        items {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          firstPublishedAt
          firstPublishedAtTimestamp
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    product {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBrandQueryVariables, APITypes.GetBrandQuery>;
export const listBrands = /* GraphQL */ `query ListBrands(
  $filter: ModelBrandFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandsQueryVariables,
  APITypes.ListBrandsQuery
>;
export const listBrandsByOwner = /* GraphQL */ `query ListBrandsByOwner(
  $brand_owner: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBrandFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrandsByOwner(
    brand_owner: $brand_owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandsByOwnerQueryVariables,
  APITypes.ListBrandsByOwnerQuery
>;
export const searchBrands = /* GraphQL */ `query SearchBrands(
  $filter: SearchableBrandFilterInput
  $sort: SearchableBrandSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBrands(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBrandsQueryVariables,
  APITypes.SearchBrandsQuery
>;
export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    tag_name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const searchTags = /* GraphQL */ `query SearchTags(
  $filter: SearchableTagFilterInput
  $sort: SearchableTagSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchTags(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      tag_name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagsQueryVariables,
  APITypes.SearchTagsQuery
>;
export const getBrandCollection = /* GraphQL */ `query GetBrandCollection($id: ID!) {
  getBrandCollection(id: $id) {
    id
    collection_name
    visible
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandCollectionQueryVariables,
  APITypes.GetBrandCollectionQuery
>;
export const listBrandCollections = /* GraphQL */ `query ListBrandCollections(
  $filter: ModelBrandCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrandCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      collection_name
      visible
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandCollectionsQueryVariables,
  APITypes.ListBrandCollectionsQuery
>;
export const searchBrandCollections = /* GraphQL */ `query SearchBrandCollections(
  $filter: SearchableBrandCollectionFilterInput
  $sort: SearchableBrandCollectionSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBrandCollections(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      collection_name
      visible
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBrandCollectionsQueryVariables,
  APITypes.SearchBrandCollectionsQuery
>;
export const getShopCart = /* GraphQL */ `query GetShopCart($id: ID!) {
  getShopCart(id: $id) {
    id
    shopcart_owner
    shopcart_quantity
    product_id
    product_type_id
    brand_id
    is_direct
    is_pay_directly
    is_consign
    createdAt
    saved_for_later
    collection_id
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    producttype {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShopCartQueryVariables,
  APITypes.GetShopCartQuery
>;
export const listShopCarts = /* GraphQL */ `query ListShopCarts(
  $filter: ModelShopCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listShopCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shopcart_owner
      shopcart_quantity
      product_id
      product_type_id
      brand_id
      is_direct
      is_pay_directly
      is_consign
      createdAt
      saved_for_later
      collection_id
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      producttype {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShopCartsQueryVariables,
  APITypes.ListShopCartsQuery
>;
export const listShopCartsByOwner = /* GraphQL */ `query ListShopCartsByOwner(
  $shopcart_owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelShopCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listShopCartsByOwner(
    shopcart_owner: $shopcart_owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shopcart_owner
      shopcart_quantity
      product_id
      product_type_id
      brand_id
      is_direct
      is_pay_directly
      is_consign
      createdAt
      saved_for_later
      collection_id
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      producttype {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShopCartsByOwnerQueryVariables,
  APITypes.ListShopCartsByOwnerQuery
>;
export const searchShopCarts = /* GraphQL */ `query SearchShopCarts(
  $filter: SearchableShopCartFilterInput
  $sort: SearchableShopCartSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchShopCarts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      shopcart_owner
      shopcart_quantity
      product_id
      product_type_id
      brand_id
      is_direct
      is_pay_directly
      is_consign
      createdAt
      saved_for_later
      collection_id
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      producttype {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchShopCartsQueryVariables,
  APITypes.SearchShopCartsQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    order_owner
    brand_id
    brand_owner
    campaign_code
    createdAt
    updatedAt
    owners
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    fee
    shipping_fee
    invoice_shipping_fee
    tax
    payment_term
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    shipping_date
    carrier
    tracking_number
    first_order
    order_points
    is_direct
    is_consign
    transferred_date
    order_price
    origin_order_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    cashback
    coupon_id
    discount_amount
    billing_count
    return_due_date
    bill_payment_status
    memo
    split_source_id
    split_date
    buyer {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    originOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    chargeStatuses {
      items {
        id
        order_id
        status
        payment_id
        amount
        createdAt
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orderproducts {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnproducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const listOrdersByOwner = /* GraphQL */ `query ListOrdersByOwner(
  $order_owner: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrdersByOwner(
    order_owner: $order_owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersByOwnerQueryVariables,
  APITypes.ListOrdersByOwnerQuery
>;
export const listOrdersByBrandOwner = /* GraphQL */ `query ListOrdersByBrandOwner(
  $brand_owner: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrdersByBrandOwner(
    brand_owner: $brand_owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersByBrandOwnerQueryVariables,
  APITypes.ListOrdersByBrandOwnerQuery
>;
export const searchOrders = /* GraphQL */ `query SearchOrders(
  $filter: SearchableOrderFilterInput
  $sort: SearchableOrderSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrders(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrdersQueryVariables,
  APITypes.SearchOrdersQuery
>;
export const getChargeStatus = /* GraphQL */ `query GetChargeStatus($id: ID!) {
  getChargeStatus(id: $id) {
    id
    order_id
    status
    payment_id
    amount
    createdAt
    owners
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChargeStatusQueryVariables,
  APITypes.GetChargeStatusQuery
>;
export const listChargeStatuss = /* GraphQL */ `query ListChargeStatuss(
  $filter: ModelChargeStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listChargeStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      status
      payment_id
      amount
      createdAt
      owners
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChargeStatussQueryVariables,
  APITypes.ListChargeStatussQuery
>;
export const searchChargeStatuss = /* GraphQL */ `query SearchChargeStatuss(
  $filter: SearchableChargeStatusFilterInput
  $sort: SearchableChargeStatusSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchChargeStatuss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      status
      payment_id
      amount
      createdAt
      owners
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchChargeStatussQueryVariables,
  APITypes.SearchChargeStatussQuery
>;
export const getOrderProduct = /* GraphQL */ `query GetOrderProduct($id: ID!) {
  getOrderProduct(id: $id) {
    id
    order_id
    product_id
    product_type_id
    order_product_quantity
    order_product_price
    order_product_wholesale_rate
    discount_percentage
    initial_order_product_quantity
    initial_order_product_price
    initial_order_product_wholesale_rate
    order_product_payment_status
    order_product_payment_method
    order_product_return_reason
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderstatus {
      items {
        id
        order_id
        order_product_id
        quantity
        status
        owners
        createdAt
        updatedAt
        orderProduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    returnProducts {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reportProducts {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderProductQueryVariables,
  APITypes.GetOrderProductQuery
>;
export const listOrderProducts = /* GraphQL */ `query ListOrderProducts(
  $filter: ModelOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderProductsQueryVariables,
  APITypes.ListOrderProductsQuery
>;
export const listOrderProductsByOrder = /* GraphQL */ `query ListOrderProductsByOrder(
  $order_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderProductsByOrder(
    order_id: $order_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderProductsByOrderQueryVariables,
  APITypes.ListOrderProductsByOrderQuery
>;
export const searchOrderProducts = /* GraphQL */ `query SearchOrderProducts(
  $filter: SearchableOrderProductFilterInput
  $sort: SearchableOrderProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrderProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrderProductsQueryVariables,
  APITypes.SearchOrderProductsQuery
>;
export const getOrderStatus = /* GraphQL */ `query GetOrderStatus($id: ID!) {
  getOrderStatus(id: $id) {
    id
    order_id
    order_product_id
    quantity
    status
    owners
    createdAt
    updatedAt
    orderProduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderStatusQueryVariables,
  APITypes.GetOrderStatusQuery
>;
export const listOrderStatuss = /* GraphQL */ `query ListOrderStatuss(
  $filter: ModelOrderStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      order_product_id
      quantity
      status
      owners
      createdAt
      updatedAt
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderStatussQueryVariables,
  APITypes.ListOrderStatussQuery
>;
export const searchOrderStatuss = /* GraphQL */ `query SearchOrderStatuss(
  $filter: SearchableOrderStatusFilterInput
  $sort: SearchableOrderStatusSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrderStatuss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      order_product_id
      quantity
      status
      owners
      createdAt
      updatedAt
      orderProduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrderStatussQueryVariables,
  APITypes.SearchOrderStatussQuery
>;
export const getReturnProduct = /* GraphQL */ `query GetReturnProduct($id: ID!) {
  getReturnProduct(id: $id) {
    id
    return_product_id
    order_id
    order_product_id
    return_quantity
    return_product_price
    return_status
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    logiless_inbound_delivery_code
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    children {
      items {
        id
        return_product_id
        order_id
        order_product_id
        return_quantity
        return_product_price
        return_status
        stripe_payment_id
        mf_transaction_id
        owners
        createdAt
        logiless_inbound_delivery_code
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        children {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReturnProductQueryVariables,
  APITypes.GetReturnProductQuery
>;
export const listReturnProducts = /* GraphQL */ `query ListReturnProducts(
  $filter: ModelReturnProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      return_product_id
      order_id
      order_product_id
      return_quantity
      return_product_price
      return_status
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      logiless_inbound_delivery_code
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      children {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnProductsQueryVariables,
  APITypes.ListReturnProductsQuery
>;
export const listReturnProductsByReturnProduct = /* GraphQL */ `query ListReturnProductsByReturnProduct(
  $return_product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnProductsByReturnProduct(
    return_product_id: $return_product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      return_product_id
      order_id
      order_product_id
      return_quantity
      return_product_price
      return_status
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      logiless_inbound_delivery_code
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      children {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnProductsByReturnProductQueryVariables,
  APITypes.ListReturnProductsByReturnProductQuery
>;
export const searchReturnProducts = /* GraphQL */ `query SearchReturnProducts(
  $filter: SearchableReturnProductFilterInput
  $sort: SearchableReturnProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReturnProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      return_product_id
      order_id
      order_product_id
      return_quantity
      return_product_price
      return_status
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      logiless_inbound_delivery_code
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      children {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReturnProductsQueryVariables,
  APITypes.SearchReturnProductsQuery
>;
export const getReportProduct = /* GraphQL */ `query GetReportProduct($id: ID!) {
  getReportProduct(id: $id) {
    id
    type
    order_id
    order_product_id
    report_type
    request_type
    report_quantity
    report_product_price
    report_status
    report_comment
    replace_order_id
    replace_order_product_id
    approval_date
    approval_quantity
    image_keys
    stripe_refund_id
    owners
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replaceOrder {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    replaceOrderproduct {
      id
      order_id
      product_id
      product_type_id
      order_product_quantity
      order_product_price
      order_product_wholesale_rate
      discount_percentage
      initial_order_product_quantity
      initial_order_product_price
      initial_order_product_wholesale_rate
      order_product_payment_status
      order_product_payment_method
      order_product_return_reason
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderstatus {
        items {
          id
          order_id
          order_product_id
          quantity
          status
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnProducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportProductQueryVariables,
  APITypes.GetReportProductQuery
>;
export const listReportProducts = /* GraphQL */ `query ListReportProducts(
  $filter: ModelReportProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      order_id
      order_product_id
      report_type
      request_type
      report_quantity
      report_product_price
      report_status
      report_comment
      replace_order_id
      replace_order_product_id
      approval_date
      approval_quantity
      image_keys
      stripe_refund_id
      owners
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      replaceOrderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportProductsQueryVariables,
  APITypes.ListReportProductsQuery
>;
export const listReportProductsByType = /* GraphQL */ `query ListReportProductsByType(
  $type: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportProductsByType(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      order_id
      order_product_id
      report_type
      request_type
      report_quantity
      report_product_price
      report_status
      report_comment
      replace_order_id
      replace_order_product_id
      approval_date
      approval_quantity
      image_keys
      stripe_refund_id
      owners
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      replaceOrderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportProductsByTypeQueryVariables,
  APITypes.ListReportProductsByTypeQuery
>;
export const listReportProductsByOrder = /* GraphQL */ `query ListReportProductsByOrder(
  $order_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelReportProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportProductsByOrder(
    order_id: $order_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      order_id
      order_product_id
      report_type
      request_type
      report_quantity
      report_product_price
      report_status
      report_comment
      replace_order_id
      replace_order_product_id
      approval_date
      approval_quantity
      image_keys
      stripe_refund_id
      owners
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      replaceOrderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportProductsByOrderQueryVariables,
  APITypes.ListReportProductsByOrderQuery
>;
export const searchReportProducts = /* GraphQL */ `query SearchReportProducts(
  $filter: SearchableReportProductFilterInput
  $sort: SearchableReportProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReportProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      type
      order_id
      order_product_id
      report_type
      request_type
      report_quantity
      report_product_price
      report_status
      report_comment
      replace_order_id
      replace_order_product_id
      approval_date
      approval_quantity
      image_keys
      stripe_refund_id
      owners
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      orderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      replaceOrderproduct {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        discount_percentage
        initial_order_product_quantity
        initial_order_product_price
        initial_order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        orderstatus {
          nextToken
          __typename
        }
        returnProducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReportProductsQueryVariables,
  APITypes.SearchReportProductsQuery
>;
export const getFeedback = /* GraphQL */ `query GetFeedback($id: ID!) {
  getFeedback(id: $id) {
    id
    order_id
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeedbackQueryVariables,
  APITypes.GetFeedbackQuery
>;
export const listFeedbacks = /* GraphQL */ `query ListFeedbacks(
  $filter: ModelFeedbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeedbacksQueryVariables,
  APITypes.ListFeedbacksQuery
>;
export const searchFeedbacks = /* GraphQL */ `query SearchFeedbacks(
  $filter: SearchableFeedbackFilterInput
  $sort: SearchableFeedbackSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchFeedbacks(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFeedbacksQueryVariables,
  APITypes.SearchFeedbacksQuery
>;
export const getOrderEditReason = /* GraphQL */ `query GetOrderEditReason($id: ID!) {
  getOrderEditReason(id: $id) {
    id
    order_id
    edit_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderEditReasonQueryVariables,
  APITypes.GetOrderEditReasonQuery
>;
export const listOrderEditReasons = /* GraphQL */ `query ListOrderEditReasons(
  $filter: ModelOrderEditReasonFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderEditReasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      edit_reason
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderEditReasonsQueryVariables,
  APITypes.ListOrderEditReasonsQuery
>;
export const listOrderEditReasonsByOrder = /* GraphQL */ `query ListOrderEditReasonsByOrder(
  $order_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderEditReasonFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderEditReasonsByOrder(
    order_id: $order_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      edit_reason
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderEditReasonsByOrderQueryVariables,
  APITypes.ListOrderEditReasonsByOrderQuery
>;
export const searchOrderEditReasons = /* GraphQL */ `query SearchOrderEditReasons(
  $filter: SearchableOrderEditReasonFilterInput
  $sort: SearchableOrderEditReasonSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrderEditReasons(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      edit_reason
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrderEditReasonsQueryVariables,
  APITypes.SearchOrderEditReasonsQuery
>;
export const getOrderCancelReason = /* GraphQL */ `query GetOrderCancelReason($id: ID!) {
  getOrderCancelReason(id: $id) {
    id
    order_id
    cancel_reason
    feedback_comment
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderCancelReasonQueryVariables,
  APITypes.GetOrderCancelReasonQuery
>;
export const listOrderCancelReasons = /* GraphQL */ `query ListOrderCancelReasons(
  $filter: ModelOrderCancelReasonFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderCancelReasons(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      cancel_reason
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderCancelReasonsQueryVariables,
  APITypes.ListOrderCancelReasonsQuery
>;
export const listOrderCancelReasonsByOrder = /* GraphQL */ `query ListOrderCancelReasonsByOrder(
  $order_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderCancelReasonFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderCancelReasonsByOrder(
    order_id: $order_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      cancel_reason
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderCancelReasonsByOrderQueryVariables,
  APITypes.ListOrderCancelReasonsByOrderQuery
>;
export const searchOrderCancelReasons = /* GraphQL */ `query SearchOrderCancelReasons(
  $filter: SearchableOrderCancelReasonFilterInput
  $sort: SearchableOrderCancelReasonSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrderCancelReasons(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      cancel_reason
      feedback_comment
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrderCancelReasonsQueryVariables,
  APITypes.SearchOrderCancelReasonsQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    thread_id
    message_owner
    message_recipient
    message_content
    message_product_name
    message_is_open
    message_files {
      object_key
      file_name
      file_type
      __typename
    }
    owners
    createdAt
    updatedAt
    thread {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      thread_id
      message_owner
      message_recipient
      message_content
      message_product_name
      message_is_open
      message_files {
        object_key
        file_name
        file_type
        __typename
      }
      owners
      createdAt
      updatedAt
      thread {
        id
        thread_buyer_owner
        thread_buyer_name
        thread_brand_owner
        thread_brand_id
        owners
        createdAt
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        messages {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const searchMessages = /* GraphQL */ `query SearchMessages(
  $filter: SearchableMessageFilterInput
  $sort: SearchableMessageSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchMessages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      thread_id
      message_owner
      message_recipient
      message_content
      message_product_name
      message_is_open
      message_files {
        object_key
        file_name
        file_type
        __typename
      }
      owners
      createdAt
      updatedAt
      thread {
        id
        thread_buyer_owner
        thread_buyer_name
        thread_brand_owner
        thread_brand_id
        owners
        createdAt
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        messages {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMessagesQueryVariables,
  APITypes.SearchMessagesQuery
>;
export const getThread = /* GraphQL */ `query GetThread($id: ID!) {
  getThread(id: $id) {
    id
    thread_buyer_owner
    thread_buyer_name
    thread_brand_owner
    thread_brand_id
    owners
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    messages {
      items {
        id
        thread_id
        message_owner
        message_recipient
        message_content
        message_product_name
        message_is_open
        message_files {
          object_key
          file_name
          file_type
          __typename
        }
        owners
        createdAt
        updatedAt
        thread {
          id
          thread_buyer_owner
          thread_buyer_name
          thread_brand_owner
          thread_brand_id
          owners
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetThreadQueryVariables, APITypes.GetThreadQuery>;
export const listThreads = /* GraphQL */ `query ListThreads(
  $filter: ModelThreadFilterInput
  $limit: Int
  $nextToken: String
) {
  listThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListThreadsQueryVariables,
  APITypes.ListThreadsQuery
>;
export const listThreadsByBuyer = /* GraphQL */ `query ListThreadsByBuyer(
  $thread_buyer_owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelThreadFilterInput
  $limit: Int
  $nextToken: String
) {
  listThreadsByBuyer(
    thread_buyer_owner: $thread_buyer_owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListThreadsByBuyerQueryVariables,
  APITypes.ListThreadsByBuyerQuery
>;
export const listThreadsByBrand = /* GraphQL */ `query ListThreadsByBrand(
  $thread_brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelThreadFilterInput
  $limit: Int
  $nextToken: String
) {
  listThreadsByBrand(
    thread_brand_id: $thread_brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListThreadsByBrandQueryVariables,
  APITypes.ListThreadsByBrandQuery
>;
export const searchThreads = /* GraphQL */ `query SearchThreads(
  $filter: SearchableThreadFilterInput
  $sort: SearchableThreadSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchThreads(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      thread_buyer_owner
      thread_buyer_name
      thread_brand_owner
      thread_brand_id
      owners
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      messages {
        items {
          id
          thread_id
          message_owner
          message_recipient
          message_content
          message_product_name
          message_is_open
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchThreadsQueryVariables,
  APITypes.SearchThreadsQuery
>;
export const getPickupProduct = /* GraphQL */ `query GetPickupProduct($id: ID!) {
  getPickupProduct(id: $id) {
    id
    pickup_list_id
    brand_id
    product_id
    display_order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPickupProductQueryVariables,
  APITypes.GetPickupProductQuery
>;
export const listPickupProducts = /* GraphQL */ `query ListPickupProducts(
  $filter: ModelPickupProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listPickupProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pickup_list_id
      brand_id
      product_id
      display_order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPickupProductsQueryVariables,
  APITypes.ListPickupProductsQuery
>;
export const listProductsByPickupList = /* GraphQL */ `query ListProductsByPickupList(
  $pickup_list_id: ID
  $display_order: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPickupProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductsByPickupList(
    pickup_list_id: $pickup_list_id
    display_order: $display_order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pickup_list_id
      brand_id
      product_id
      display_order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsByPickupListQueryVariables,
  APITypes.ListProductsByPickupListQuery
>;
export const searchPickupProducts = /* GraphQL */ `query SearchPickupProducts(
  $filter: SearchablePickupProductFilterInput
  $sort: SearchablePickupProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPickupProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      pickup_list_id
      brand_id
      product_id
      display_order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPickupProductsQueryVariables,
  APITypes.SearchPickupProductsQuery
>;
export const getPickupBrands = /* GraphQL */ `query GetPickupBrands($id: ID!) {
  getPickupBrands(id: $id) {
    id
    title
    brands {
      brand_id
      display_order
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    display_num
    visible
    item_type
    display_size
    createdAt
    updatedAt
    products {
      items {
        id
        pickup_list_id
        brand_id
        product_id
        display_order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPickupBrandsQueryVariables,
  APITypes.GetPickupBrandsQuery
>;
export const listPickupBrandss = /* GraphQL */ `query ListPickupBrandss(
  $filter: ModelPickupBrandsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPickupBrandss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      brands {
        brand_id
        display_order
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      display_num
      visible
      item_type
      display_size
      createdAt
      updatedAt
      products {
        items {
          id
          pickup_list_id
          brand_id
          product_id
          display_order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPickupBrandssQueryVariables,
  APITypes.ListPickupBrandssQuery
>;
export const searchPickupBrandss = /* GraphQL */ `query SearchPickupBrandss(
  $filter: SearchablePickupBrandsFilterInput
  $sort: SearchablePickupBrandsSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPickupBrandss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      title
      brands {
        brand_id
        display_order
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        __typename
      }
      display_num
      visible
      item_type
      display_size
      createdAt
      updatedAt
      products {
        items {
          id
          pickup_list_id
          brand_id
          product_id
          display_order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPickupBrandssQueryVariables,
  APITypes.SearchPickupBrandssQuery
>;
export const getMenu = /* GraphQL */ `query GetMenu($id: ID!) {
  getMenu(id: $id) {
    id
    title
    product_list_path
    brand_list_path
    search_categories {
      name
      path
      __typename
    }
    sub_categories {
      name
      path
      __typename
    }
    display_order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMenuQueryVariables, APITypes.GetMenuQuery>;
export const listMenus = /* GraphQL */ `query ListMenus(
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      product_list_path
      brand_list_path
      search_categories {
        name
        path
        __typename
      }
      sub_categories {
        name
        path
        __typename
      }
      display_order
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMenusQueryVariables, APITypes.ListMenusQuery>;
export const searchMenus = /* GraphQL */ `query SearchMenus(
  $filter: SearchableMenuFilterInput
  $sort: SearchableMenuSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchMenus(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      title
      product_list_path
      brand_list_path
      search_categories {
        name
        path
        __typename
      }
      sub_categories {
        name
        path
        __typename
      }
      display_order
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMenusQueryVariables,
  APITypes.SearchMenusQuery
>;
export const getFavoriteBrand = /* GraphQL */ `query GetFavoriteBrand($id: ID!) {
  getFavoriteBrand(id: $id) {
    id
    account_id
    brand_id
    createdAt
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavoriteBrandQueryVariables,
  APITypes.GetFavoriteBrandQuery
>;
export const listFavoriteBrands = /* GraphQL */ `query ListFavoriteBrands(
  $filter: ModelFavoriteBrandFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account_id
      brand_id
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteBrandsQueryVariables,
  APITypes.ListFavoriteBrandsQuery
>;
export const searchFavoriteBrands = /* GraphQL */ `query SearchFavoriteBrands(
  $filter: SearchableFavoriteBrandFilterInput
  $sort: SearchableFavoriteBrandSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchFavoriteBrands(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_id
      brand_id
      createdAt
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFavoriteBrandsQueryVariables,
  APITypes.SearchFavoriteBrandsQuery
>;
export const getFavoriteProduct = /* GraphQL */ `query GetFavoriteProduct($id: ID!) {
  getFavoriteProduct(id: $id) {
    id
    account_id
    favorite_list_id
    product_id
    order
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    favorite_list {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavoriteProductQueryVariables,
  APITypes.GetFavoriteProductQuery
>;
export const listFavoriteProducts = /* GraphQL */ `query ListFavoriteProducts(
  $filter: ModelFavoriteProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account_id
      favorite_list_id
      product_id
      order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      owner
      favorite_list {
        id
        account_id
        list_name
        order
        createdAt
        updatedAt
        products {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteProductsQueryVariables,
  APITypes.ListFavoriteProductsQuery
>;
export const listFavoriteProductsByList = /* GraphQL */ `query ListFavoriteProductsByList(
  $favorite_list_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFavoriteProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteProductsByList(
    favorite_list_id: $favorite_list_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      favorite_list_id
      product_id
      order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      owner
      favorite_list {
        id
        account_id
        list_name
        order
        createdAt
        updatedAt
        products {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteProductsByListQueryVariables,
  APITypes.ListFavoriteProductsByListQuery
>;
export const listFavoriteProductsByProduct = /* GraphQL */ `query ListFavoriteProductsByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFavoriteProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteProductsByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      favorite_list_id
      product_id
      order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      owner
      favorite_list {
        id
        account_id
        list_name
        order
        createdAt
        updatedAt
        products {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteProductsByProductQueryVariables,
  APITypes.ListFavoriteProductsByProductQuery
>;
export const searchFavoriteProducts = /* GraphQL */ `query SearchFavoriteProducts(
  $filter: SearchableFavoriteProductFilterInput
  $sort: SearchableFavoriteProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchFavoriteProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_id
      favorite_list_id
      product_id
      order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      owner
      favorite_list {
        id
        account_id
        list_name
        order
        createdAt
        updatedAt
        products {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFavoriteProductsQueryVariables,
  APITypes.SearchFavoriteProductsQuery
>;
export const getFavoriteProductList = /* GraphQL */ `query GetFavoriteProductList($id: ID!) {
  getFavoriteProductList(id: $id) {
    id
    account_id
    list_name
    order
    createdAt
    updatedAt
    products {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavoriteProductListQueryVariables,
  APITypes.GetFavoriteProductListQuery
>;
export const listFavoriteProductLists = /* GraphQL */ `query ListFavoriteProductLists(
  $filter: ModelFavoriteProductListFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteProductLists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteProductListsQueryVariables,
  APITypes.ListFavoriteProductListsQuery
>;
export const listFavoriteProductsByAccount = /* GraphQL */ `query ListFavoriteProductsByAccount(
  $account_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFavoriteProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteProductsByAccount(
    account_id: $account_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      favorite_list_id
      product_id
      order
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      owner
      favorite_list {
        id
        account_id
        list_name
        order
        createdAt
        updatedAt
        products {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteProductsByAccountQueryVariables,
  APITypes.ListFavoriteProductsByAccountQuery
>;
export const searchFavoriteProductLists = /* GraphQL */ `query SearchFavoriteProductLists(
  $filter: SearchableFavoriteProductListFilterInput
  $sort: SearchableFavoriteProductListSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchFavoriteProductLists(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_id
      list_name
      order
      createdAt
      updatedAt
      products {
        items {
          id
          account_id
          favorite_list_id
          product_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFavoriteProductListsQueryVariables,
  APITypes.SearchFavoriteProductListsQuery
>;
export const getCollection = /* GraphQL */ `query GetCollection($id: ID!) {
  getCollection(id: $id) {
    id
    brand_id
    src_brand_id
    name
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    display_order
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    srcBrand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productCollections {
      items {
        id
        collection_id
        product_id
        product_type_id
        quantity
        sort_order
        owner
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        collection {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCollectionQueryVariables,
  APITypes.GetCollectionQuery
>;
export const listCollections = /* GraphQL */ `query ListCollections(
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionsQueryVariables,
  APITypes.ListCollectionsQuery
>;
export const listCollectionsByBrand = /* GraphQL */ `query ListCollectionsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollectionsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionsByBrandQueryVariables,
  APITypes.ListCollectionsByBrandQuery
>;
export const searchCollections = /* GraphQL */ `query SearchCollections(
  $filter: SearchableCollectionFilterInput
  $sort: SearchableCollectionSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCollections(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCollectionsQueryVariables,
  APITypes.SearchCollectionsQuery
>;
export const getProductCollection = /* GraphQL */ `query GetProductCollection($id: ID!) {
  getProductCollection(id: $id) {
    id
    collection_id
    product_id
    product_type_id
    quantity
    sort_order
    owner
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    collection {
      id
      brand_id
      src_brand_id
      name
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      display_order
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      srcBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      productCollections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductCollectionQueryVariables,
  APITypes.GetProductCollectionQuery
>;
export const listProductCollections = /* GraphQL */ `query ListProductCollections(
  $filter: ModelProductCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductCollections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collection_id
      product_id
      product_type_id
      quantity
      sort_order
      owner
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      collection {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductCollectionsQueryVariables,
  APITypes.ListProductCollectionsQuery
>;
export const searchProductCollections = /* GraphQL */ `query SearchProductCollections(
  $filter: SearchableProductCollectionFilterInput
  $sort: SearchableProductCollectionSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProductCollections(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      collection_id
      product_id
      product_type_id
      quantity
      sort_order
      owner
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      collection {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductCollectionsQueryVariables,
  APITypes.SearchProductCollectionsQuery
>;
export const getReferral = /* GraphQL */ `query GetReferral($id: ID!) {
  getReferral(id: $id) {
    id
    referral_type
    referral_email
    referral_name
    referral_website
    referral_domain
    referral_by
    referral_status
    referral_brand_id
    referral_account_id
    createdAt
    owner
    updatedAt
    ownerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReferralQueryVariables,
  APITypes.GetReferralQuery
>;
export const listReferrals = /* GraphQL */ `query ListReferrals(
  $filter: ModelReferralFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReferralsQueryVariables,
  APITypes.ListReferralsQuery
>;
export const listReferralsByOwner = /* GraphQL */ `query ListReferralsByOwner(
  $owner: ID
  $sortDirection: ModelSortDirection
  $filter: ModelReferralFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferralsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReferralsByOwnerQueryVariables,
  APITypes.ListReferralsByOwnerQuery
>;
export const searchReferrals = /* GraphQL */ `query SearchReferrals(
  $filter: SearchableReferralFilterInput
  $sort: SearchableReferralSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReferrals(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReferralsQueryVariables,
  APITypes.SearchReferralsQuery
>;
export const getReferralMessage = /* GraphQL */ `query GetReferralMessage($id: ID!) {
  getReferralMessage(id: $id) {
    id
    from
    subject
    message
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReferralMessageQueryVariables,
  APITypes.GetReferralMessageQuery
>;
export const listReferralMessages = /* GraphQL */ `query ListReferralMessages(
  $filter: ModelReferralMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferralMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      from
      subject
      message
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReferralMessagesQueryVariables,
  APITypes.ListReferralMessagesQuery
>;
export const listReferralMessagesByOwner = /* GraphQL */ `query ListReferralMessagesByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelReferralMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferralMessagesByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      from
      subject
      message
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReferralMessagesByOwnerQueryVariables,
  APITypes.ListReferralMessagesByOwnerQuery
>;
export const searchReferralMessages = /* GraphQL */ `query SearchReferralMessages(
  $filter: SearchableReferralMessageFilterInput
  $sort: SearchableReferralMessageSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReferralMessages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      from
      subject
      message
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReferralMessagesQueryVariables,
  APITypes.SearchReferralMessagesQuery
>;
export const getPoint = /* GraphQL */ `query GetPoint($id: ID!) {
  getPoint(id: $id) {
    id
    account_id
    points
    point_type
    order_id
    referral_id
    referral_brand_id
    createdAt
    duration
    review_id
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    referral {
      id
      referral_type
      referral_email
      referral_name
      referral_website
      referral_domain
      referral_by
      referral_status
      referral_brand_id
      referral_account_id
      createdAt
      owner
      updatedAt
      ownerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    history {
      items {
        id
        point_id
        account_id
        points
        history_type
        order_id
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPointQueryVariables, APITypes.GetPointQuery>;
export const listPoints = /* GraphQL */ `query ListPoints(
  $filter: ModelPointFilterInput
  $limit: Int
  $nextToken: String
) {
  listPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account_id
      points
      point_type
      order_id
      referral_id
      referral_brand_id
      createdAt
      duration
      review_id
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      referral {
        id
        referral_type
        referral_email
        referral_name
        referral_website
        referral_domain
        referral_by
        referral_status
        referral_brand_id
        referral_account_id
        createdAt
        owner
        updatedAt
        ownerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      history {
        items {
          id
          point_id
          account_id
          points
          history_type
          order_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPointsQueryVariables,
  APITypes.ListPointsQuery
>;
export const listPointsByAccount = /* GraphQL */ `query ListPointsByAccount(
  $account_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPointFilterInput
  $limit: Int
  $nextToken: String
) {
  listPointsByAccount(
    account_id: $account_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      points
      point_type
      order_id
      referral_id
      referral_brand_id
      createdAt
      duration
      review_id
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      referral {
        id
        referral_type
        referral_email
        referral_name
        referral_website
        referral_domain
        referral_by
        referral_status
        referral_brand_id
        referral_account_id
        createdAt
        owner
        updatedAt
        ownerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      history {
        items {
          id
          point_id
          account_id
          points
          history_type
          order_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPointsByAccountQueryVariables,
  APITypes.ListPointsByAccountQuery
>;
export const listPointsByOrder = /* GraphQL */ `query ListPointsByOrder(
  $order_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPointFilterInput
  $limit: Int
  $nextToken: String
) {
  listPointsByOrder(
    order_id: $order_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_id
      points
      point_type
      order_id
      referral_id
      referral_brand_id
      createdAt
      duration
      review_id
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      referral {
        id
        referral_type
        referral_email
        referral_name
        referral_website
        referral_domain
        referral_by
        referral_status
        referral_brand_id
        referral_account_id
        createdAt
        owner
        updatedAt
        ownerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      history {
        items {
          id
          point_id
          account_id
          points
          history_type
          order_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPointsByOrderQueryVariables,
  APITypes.ListPointsByOrderQuery
>;
export const searchPoints = /* GraphQL */ `query SearchPoints(
  $filter: SearchablePointFilterInput
  $sort: SearchablePointSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPoints(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_id
      points
      point_type
      order_id
      referral_id
      referral_brand_id
      createdAt
      duration
      review_id
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      referral {
        id
        referral_type
        referral_email
        referral_name
        referral_website
        referral_domain
        referral_by
        referral_status
        referral_brand_id
        referral_account_id
        createdAt
        owner
        updatedAt
        ownerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      history {
        items {
          id
          point_id
          account_id
          points
          history_type
          order_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPointsQueryVariables,
  APITypes.SearchPointsQuery
>;
export const getPointHistory = /* GraphQL */ `query GetPointHistory($id: ID!) {
  getPointHistory(id: $id) {
    id
    point_id
    account_id
    points
    history_type
    order_id
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPointHistoryQueryVariables,
  APITypes.GetPointHistoryQuery
>;
export const listPointHistorys = /* GraphQL */ `query ListPointHistorys(
  $filter: ModelPointHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listPointHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      point_id
      account_id
      points
      history_type
      order_id
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPointHistorysQueryVariables,
  APITypes.ListPointHistorysQuery
>;
export const listPointHistorysByAccount = /* GraphQL */ `query ListPointHistorysByAccount(
  $account_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPointHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listPointHistorysByAccount(
    account_id: $account_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      point_id
      account_id
      points
      history_type
      order_id
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPointHistorysByAccountQueryVariables,
  APITypes.ListPointHistorysByAccountQuery
>;
export const listPointHistorysByPoint = /* GraphQL */ `query ListPointHistorysByPoint(
  $point_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPointHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listPointHistorysByPoint(
    point_id: $point_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      point_id
      account_id
      points
      history_type
      order_id
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPointHistorysByPointQueryVariables,
  APITypes.ListPointHistorysByPointQuery
>;
export const searchPointHistorys = /* GraphQL */ `query SearchPointHistorys(
  $filter: SearchablePointHistoryFilterInput
  $sort: SearchablePointHistorySortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPointHistorys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      point_id
      account_id
      points
      history_type
      order_id
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPointHistorysQueryVariables,
  APITypes.SearchPointHistorysQuery
>;
export const getContact = /* GraphQL */ `query GetContact($id: ID!) {
  getContact(id: $id) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    updatedAt
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    buyerInfo {
      id
      account_id
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_id
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_return_credit
      buyer_return_limit_per_brand
      buyer_type
      invoice_credit_facility
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      referral_brand_id
      shop_name
      store_category
      stripe_id
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      shop_images {
        items {
          id
          buyer_id
          image_key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shipping_address {
        id
        name
        buyer_id
        zip_code
        prefecture
        city
        building
        phone_number
        createdAt
        updatedAt
        __typename
      }
      referralBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contactListConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactQueryVariables,
  APITypes.GetContactQuery
>;
export const listContacts = /* GraphQL */ `query ListContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsQueryVariables,
  APITypes.ListContactsQuery
>;
export const listContactsBySupplier = /* GraphQL */ `query ListContactsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsBySupplierQueryVariables,
  APITypes.ListContactsBySupplierQuery
>;
export const listContactsByOwner = /* GraphQL */ `query ListContactsByOwner(
  $owner: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactsByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsByOwnerQueryVariables,
  APITypes.ListContactsByOwnerQuery
>;
export const listContactsByBuyer = /* GraphQL */ `query ListContactsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsByBuyerQueryVariables,
  APITypes.ListContactsByBuyerQuery
>;
export const listContactsByBuyerOwner = /* GraphQL */ `query ListContactsByBuyerOwner(
  $buyer_owner: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactsByBuyerOwner(
    buyer_owner: $buyer_owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsByBuyerOwnerQueryVariables,
  APITypes.ListContactsByBuyerOwnerQuery
>;
export const searchContacts = /* GraphQL */ `query SearchContacts(
  $filter: SearchableContactFilterInput
  $sort: SearchableContactSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchContacts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchContactsQueryVariables,
  APITypes.SearchContactsQuery
>;
export const getContactList = /* GraphQL */ `query GetContactList($id: ID!) {
  getContactList(id: $id) {
    id
    list_name
    supplier_id
    order
    createdAt
    updatedAt
    owner
    contactConnections {
      items {
        id
        supplier_id
        contact_list_id
        contact_id
        createdAt
        updatedAt
        owner
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        contactList {
          id
          list_name
          supplier_id
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactListQueryVariables,
  APITypes.GetContactListQuery
>;
export const listContactLists = /* GraphQL */ `query ListContactLists(
  $filter: ModelContactListFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactListsQueryVariables,
  APITypes.ListContactListsQuery
>;
export const listContactListsBySupplier = /* GraphQL */ `query ListContactListsBySupplier(
  $supplier_id: ID
  $orderCreatedAt: ModelContactListBySupplierCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactListFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactListsBySupplier(
    supplier_id: $supplier_id
    orderCreatedAt: $orderCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactListsBySupplierQueryVariables,
  APITypes.ListContactListsBySupplierQuery
>;
export const searchContactLists = /* GraphQL */ `query SearchContactLists(
  $filter: SearchableContactListFilterInput
  $sort: SearchableContactListSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchContactLists(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchContactListsQueryVariables,
  APITypes.SearchContactListsQuery
>;
export const getContactListConnection = /* GraphQL */ `query GetContactListConnection($id: ID!) {
  getContactListConnection(id: $id) {
    id
    supplier_id
    contact_list_id
    contact_id
    createdAt
    updatedAt
    owner
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contactList {
      id
      list_name
      supplier_id
      order
      createdAt
      updatedAt
      owner
      contactConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactListConnectionQueryVariables,
  APITypes.GetContactListConnectionQuery
>;
export const listContactListConnections = /* GraphQL */ `query ListContactListConnections(
  $filter: ModelContactListConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactListConnections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      contact_list_id
      contact_id
      createdAt
      updatedAt
      owner
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      contactList {
        id
        list_name
        supplier_id
        order
        createdAt
        updatedAt
        owner
        contactConnections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactListConnectionsQueryVariables,
  APITypes.ListContactListConnectionsQuery
>;
export const listContactListConnectionsByContact = /* GraphQL */ `query ListContactListConnectionsByContact(
  $contact_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactListConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactListConnectionsByContact(
    contact_id: $contact_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      contact_list_id
      contact_id
      createdAt
      updatedAt
      owner
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      contactList {
        id
        list_name
        supplier_id
        order
        createdAt
        updatedAt
        owner
        contactConnections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactListConnectionsByContactQueryVariables,
  APITypes.ListContactListConnectionsByContactQuery
>;
export const listContactListConnectionsByContactList = /* GraphQL */ `query ListContactListConnectionsByContactList(
  $contact_list_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactListConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactListConnectionsByContactList(
    contact_list_id: $contact_list_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      contact_list_id
      contact_id
      createdAt
      updatedAt
      owner
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      contactList {
        id
        list_name
        supplier_id
        order
        createdAt
        updatedAt
        owner
        contactConnections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactListConnectionsByContactListQueryVariables,
  APITypes.ListContactListConnectionsByContactListQuery
>;
export const listContactListConnectionsBySupplier = /* GraphQL */ `query ListContactListConnectionsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactListConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactListConnectionsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      contact_list_id
      contact_id
      createdAt
      updatedAt
      owner
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      contactList {
        id
        list_name
        supplier_id
        order
        createdAt
        updatedAt
        owner
        contactConnections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactListConnectionsBySupplierQueryVariables,
  APITypes.ListContactListConnectionsBySupplierQuery
>;
export const searchContactListConnections = /* GraphQL */ `query SearchContactListConnections(
  $filter: SearchableContactListConnectionFilterInput
  $sort: SearchableContactListConnectionSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchContactListConnections(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      supplier_id
      contact_list_id
      contact_id
      createdAt
      updatedAt
      owner
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      contactList {
        id
        list_name
        supplier_id
        order
        createdAt
        updatedAt
        owner
        contactConnections {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchContactListConnectionsQueryVariables,
  APITypes.SearchContactListConnectionsQuery
>;
export const getContactActivity = /* GraphQL */ `query GetContactActivity($id: ID!) {
  getContactActivity(id: $id) {
    id
    contact_id
    activity_date
    activity_type
    order_id
    message_id
    campaign_id
    activity
    owner
    createdAt
    updatedAt
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactActivityQueryVariables,
  APITypes.GetContactActivityQuery
>;
export const listContactActivitys = /* GraphQL */ `query ListContactActivitys(
  $filter: ModelContactActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contact_id
      activity_date
      activity_type
      order_id
      message_id
      campaign_id
      activity
      owner
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactActivitysQueryVariables,
  APITypes.ListContactActivitysQuery
>;
export const listContactActivitiesByContact = /* GraphQL */ `query ListContactActivitiesByContact(
  $contact_id: ID
  $activity_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactActivitiesByContact(
    contact_id: $contact_id
    activity_date: $activity_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contact_id
      activity_date
      activity_type
      order_id
      message_id
      campaign_id
      activity
      owner
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactActivitiesByContactQueryVariables,
  APITypes.ListContactActivitiesByContactQuery
>;
export const searchContactActivitys = /* GraphQL */ `query SearchContactActivitys(
  $filter: SearchableContactActivityFilterInput
  $sort: SearchableContactActivitySortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchContactActivitys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      contact_id
      activity_date
      activity_type
      order_id
      message_id
      campaign_id
      activity
      owner
      createdAt
      updatedAt
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchContactActivitysQueryVariables,
  APITypes.SearchContactActivitysQuery
>;
export const getCampaign = /* GraphQL */ `query GetCampaign($id: ID!) {
  getCampaign(id: $id) {
    id
    supplier_id
    brand_id
    campaign_sender
    campaign_from
    campaign_title
    campaign_status
    campaign_subject
    preview_text
    campaign_template
    campaign_message
    campaign_components
    campaign_style
    campaign_segments
    deliveryTime
    sentAt
    createdAt
    owner
    stats {
      total
      sent
      delivered
      unsent
      open
      click
      bounce
      unsubscribe
      clickRate
      openRate
      __typename
    }
    updatedAt
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        email
        source
        contact_list_id
        status
        clicks
        views
        sentAt
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignQueryVariables,
  APITypes.GetCampaignQuery
>;
export const listCampaigns = /* GraphQL */ `query ListCampaigns(
  $filter: ModelCampaignFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignsQueryVariables,
  APITypes.ListCampaignsQuery
>;
export const listCampaignsBySupplier = /* GraphQL */ `query ListCampaignsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCampaignFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignsBySupplierQueryVariables,
  APITypes.ListCampaignsBySupplierQuery
>;
export const listCampaignsByOwner = /* GraphQL */ `query ListCampaignsByOwner(
  $owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCampaignFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignsByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignsByOwnerQueryVariables,
  APITypes.ListCampaignsByOwnerQuery
>;
export const searchCampaigns = /* GraphQL */ `query SearchCampaigns(
  $filter: SearchableCampaignFilterInput
  $sort: SearchableCampaignSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCampaigns(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCampaignsQueryVariables,
  APITypes.SearchCampaignsQuery
>;
export const getCampaignContact = /* GraphQL */ `query GetCampaignContact($id: ID!) {
  getCampaignContact(id: $id) {
    id
    supplier_id
    campaign_id
    contact_id
    email
    source
    contact_list_id
    status
    clicks
    views
    sentAt
    owner
    createdAt
    updatedAt
    contact {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      updatedAt
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      buyerInfo {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      contactListConnections {
        items {
          id
          supplier_id
          contact_list_id
          contact_id
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    campaign {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      stats {
        total
        sent
        delivered
        unsent
        open
        click
        bounce
        unsubscribe
        clickRate
        openRate
        __typename
      }
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          contact_list_id
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignContactQueryVariables,
  APITypes.GetCampaignContactQuery
>;
export const listCampaignContacts = /* GraphQL */ `query ListCampaignContacts(
  $filter: ModelCampaignContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      supplier_id
      campaign_id
      contact_id
      email
      source
      contact_list_id
      status
      clicks
      views
      sentAt
      owner
      createdAt
      updatedAt
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignContactsQueryVariables,
  APITypes.ListCampaignContactsQuery
>;
export const listCampaignContactsBySupplier = /* GraphQL */ `query ListCampaignContactsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCampaignContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignContactsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      campaign_id
      contact_id
      email
      source
      contact_list_id
      status
      clicks
      views
      sentAt
      owner
      createdAt
      updatedAt
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignContactsBySupplierQueryVariables,
  APITypes.ListCampaignContactsBySupplierQuery
>;
export const listCampaignContactsByContact = /* GraphQL */ `query ListCampaignContactsByContact(
  $contact_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCampaignContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignContactsByContact(
    contact_id: $contact_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      campaign_id
      contact_id
      email
      source
      contact_list_id
      status
      clicks
      views
      sentAt
      owner
      createdAt
      updatedAt
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignContactsByContactQueryVariables,
  APITypes.ListCampaignContactsByContactQuery
>;
export const listCampaignContactsByCampaign = /* GraphQL */ `query ListCampaignContactsByCampaign(
  $campaign_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCampaignContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignContactsByCampaign(
    campaign_id: $campaign_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      campaign_id
      contact_id
      email
      source
      contact_list_id
      status
      clicks
      views
      sentAt
      owner
      createdAt
      updatedAt
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignContactsByCampaignQueryVariables,
  APITypes.ListCampaignContactsByCampaignQuery
>;
export const listCampaignContactsByEmail = /* GraphQL */ `query ListCampaignContactsByEmail(
  $email: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCampaignContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampaignContactsByEmail(
    email: $email
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      campaign_id
      contact_id
      email
      source
      contact_list_id
      status
      clicks
      views
      sentAt
      owner
      createdAt
      updatedAt
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignContactsByEmailQueryVariables,
  APITypes.ListCampaignContactsByEmailQuery
>;
export const searchCampaignContacts = /* GraphQL */ `query SearchCampaignContacts(
  $filter: SearchableCampaignContactFilterInput
  $sort: SearchableCampaignContactSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCampaignContacts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      supplier_id
      campaign_id
      contact_id
      email
      source
      contact_list_id
      status
      clicks
      views
      sentAt
      owner
      createdAt
      updatedAt
      contact {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      campaign {
        id
        supplier_id
        brand_id
        campaign_sender
        campaign_from
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_message
        campaign_components
        campaign_style
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        stats {
          total
          sent
          delivered
          unsent
          open
          click
          bounce
          unsubscribe
          clickRate
          openRate
          __typename
        }
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCampaignContactsQueryVariables,
  APITypes.SearchCampaignContactsQuery
>;
export const getDailySession = /* GraphQL */ `query GetDailySession($brand_id: ID!, $session_date: String!) {
  getDailySession(brand_id: $brand_id, session_date: $session_date) {
    brand_id
    session_date
    session_count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailySessionQueryVariables,
  APITypes.GetDailySessionQuery
>;
export const listDailySessions = /* GraphQL */ `query ListDailySessions(
  $brand_id: ID
  $session_date: ModelStringKeyConditionInput
  $filter: ModelDailySessionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDailySessions(
    brand_id: $brand_id
    session_date: $session_date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      brand_id
      session_date
      session_count
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailySessionsQueryVariables,
  APITypes.ListDailySessionsQuery
>;
export const searchDailySessions = /* GraphQL */ `query SearchDailySessions(
  $filter: SearchableDailySessionFilterInput
  $sort: SearchableDailySessionSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchDailySessions(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      brand_id
      session_date
      session_count
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDailySessionsQueryVariables,
  APITypes.SearchDailySessionsQuery
>;
export const getConversionRate = /* GraphQL */ `query GetConversionRate($brand_id: ID!) {
  getConversionRate(brand_id: $brand_id) {
    brand_id
    order
    click
    conversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConversionRateQueryVariables,
  APITypes.GetConversionRateQuery
>;
export const listConversionRates = /* GraphQL */ `query ListConversionRates(
  $brand_id: ID
  $filter: ModelConversionRateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listConversionRates(
    brand_id: $brand_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      brand_id
      order
      click
      conversion
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConversionRatesQueryVariables,
  APITypes.ListConversionRatesQuery
>;
export const searchConversionRates = /* GraphQL */ `query SearchConversionRates(
  $filter: SearchableConversionRateFilterInput
  $sort: SearchableConversionRateSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchConversionRates(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      brand_id
      order
      click
      conversion
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchConversionRatesQueryVariables,
  APITypes.SearchConversionRatesQuery
>;
export const getPageView = /* GraphQL */ `query GetPageView($brand_id: ID!, $buyer_id: ID!) {
  getPageView(brand_id: $brand_id, buyer_id: $buyer_id) {
    brand_id
    buyer_id
    email
    page_view
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPageViewQueryVariables,
  APITypes.GetPageViewQuery
>;
export const listPageViews = /* GraphQL */ `query ListPageViews(
  $brand_id: ID
  $buyer_id: ModelIDKeyConditionInput
  $filter: ModelPageViewFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPageViews(
    brand_id: $brand_id
    buyer_id: $buyer_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      brand_id
      buyer_id
      email
      page_view
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPageViewsQueryVariables,
  APITypes.ListPageViewsQuery
>;
export const listPageViewsByBuyer = /* GraphQL */ `query ListPageViewsByBuyer(
  $buyer_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPageViewFilterInput
  $limit: Int
  $nextToken: String
) {
  listPageViewsByBuyer(
    buyer_id: $buyer_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      brand_id
      buyer_id
      email
      page_view
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPageViewsByBuyerQueryVariables,
  APITypes.ListPageViewsByBuyerQuery
>;
export const listPageViewsByEmail = /* GraphQL */ `query ListPageViewsByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelPageViewFilterInput
  $limit: Int
  $nextToken: String
) {
  listPageViewsByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      brand_id
      buyer_id
      email
      page_view
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPageViewsByEmailQueryVariables,
  APITypes.ListPageViewsByEmailQuery
>;
export const searchPageViews = /* GraphQL */ `query SearchPageViews(
  $filter: SearchablePageViewFilterInput
  $sort: SearchablePageViewSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPageViews(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      brand_id
      buyer_id
      email
      page_view
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPageViewsQueryVariables,
  APITypes.SearchPageViewsQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    brand_id
    product_ids
    buyer_id
    order_id
    rate
    title
    comment
    remove_flag
    createdAt
    updatedAt
    buyerInfo {
      items {
        id
        account_id
        billing_close_day
        buyer_brand
        buyer_credit
        buyer_id
        buyer_kyc_status
        buyer_product_category
        buyer_rating
        buyer_return_credit
        buyer_return_limit_per_brand
        buyer_type
        invoice_credit_facility
        item_category
        metadata
        mf_customer_id
        mf_destination_id
        mf_examination_status
        owner
        owner_name
        payment_type
        primary_payment_method_id
        primary_payment_term
        primary_shipping_address_id
        referral_brand_id
        shop_name
        store_category
        stripe_id
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        shop_images {
          nextToken
          __typename
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
          __typename
        }
        referralBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      order_owner
      brand_id
      brand_owner
      campaign_code
      createdAt
      updatedAt
      owners
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      invoice_shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      first_order
      order_points
      is_direct
      is_consign
      transferred_date
      order_price
      origin_order_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      cashback
      coupon_id
      discount_amount
      billing_count
      return_due_date
      bill_payment_status
      memo
      split_source_id
      split_date
      buyer {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      originOrder {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      chargeStatuses {
        items {
          id
          order_id
          status
          payment_id
          amount
          createdAt
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orderproducts {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          discount_percentage
          initial_order_product_quantity
          initial_order_product_price
          initial_order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      returnproducts {
        items {
          id
          return_product_id
          order_id
          order_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          mf_transaction_id
          owners
          createdAt
          logiless_inbound_delivery_code
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportProducts {
        items {
          id
          type
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          stripe_refund_id
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    replies {
      items {
        id
        review_id
        brand_id
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      brand_id
      product_ids
      buyer_id
      order_id
      rate
      title
      comment
      remove_flag
      createdAt
      updatedAt
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replies {
        items {
          id
          review_id
          brand_id
          comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const listReviewsByBrand = /* GraphQL */ `query ListReviewsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_ids
      buyer_id
      order_id
      rate
      title
      comment
      remove_flag
      createdAt
      updatedAt
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replies {
        items {
          id
          review_id
          brand_id
          comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsByBrandQueryVariables,
  APITypes.ListReviewsByBrandQuery
>;
export const listReviewsByBuyer = /* GraphQL */ `query ListReviewsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_ids
      buyer_id
      order_id
      rate
      title
      comment
      remove_flag
      createdAt
      updatedAt
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replies {
        items {
          id
          review_id
          brand_id
          comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsByBuyerQueryVariables,
  APITypes.ListReviewsByBuyerQuery
>;
export const listReviewsByOrder = /* GraphQL */ `query ListReviewsByOrder(
  $order_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewsByOrder(
    order_id: $order_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand_id
      product_ids
      buyer_id
      order_id
      rate
      title
      comment
      remove_flag
      createdAt
      updatedAt
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replies {
        items {
          id
          review_id
          brand_id
          comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsByOrderQueryVariables,
  APITypes.ListReviewsByOrderQuery
>;
export const searchReviews = /* GraphQL */ `query SearchReviews(
  $filter: SearchableReviewFilterInput
  $sort: SearchableReviewSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReviews(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      brand_id
      product_ids
      buyer_id
      order_id
      rate
      title
      comment
      remove_flag
      createdAt
      updatedAt
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replies {
        items {
          id
          review_id
          brand_id
          comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReviewsQueryVariables,
  APITypes.SearchReviewsQuery
>;
export const getReviewReply = /* GraphQL */ `query GetReviewReply($id: ID!) {
  getReviewReply(id: $id) {
    id
    review_id
    brand_id
    comment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReviewReplyQueryVariables,
  APITypes.GetReviewReplyQuery
>;
export const listReviewReplys = /* GraphQL */ `query ListReviewReplys(
  $filter: ModelReviewReplyFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewReplys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      review_id
      brand_id
      comment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewReplysQueryVariables,
  APITypes.ListReviewReplysQuery
>;
export const listReviewRepliesByReview = /* GraphQL */ `query ListReviewRepliesByReview(
  $review_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewReplyFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewRepliesByReview(
    review_id: $review_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      review_id
      brand_id
      comment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewRepliesByReviewQueryVariables,
  APITypes.ListReviewRepliesByReviewQuery
>;
export const listReviewRepliesByBrand = /* GraphQL */ `query ListReviewRepliesByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewReplyFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewRepliesByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      review_id
      brand_id
      comment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewRepliesByBrandQueryVariables,
  APITypes.ListReviewRepliesByBrandQuery
>;
export const searchReviewReplys = /* GraphQL */ `query SearchReviewReplys(
  $filter: SearchableReviewReplyFilterInput
  $sort: SearchableReviewReplySortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReviewReplys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      review_id
      brand_id
      comment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReviewReplysQueryVariables,
  APITypes.SearchReviewReplysQuery
>;
export const getCoupon = /* GraphQL */ `query GetCoupon($id: ID!) {
  getCoupon(id: $id) {
    id
    coupon_name
    start_date
    end_date
    brand_ids
    discount_type
    discount_value
    maximum_amount
    available_times
    duration
    createdAt
    updatedAt
    logs {
      items {
        id
        coupon_id
        buyer_id
        order_ids
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    buyers {
      items {
        id
        coupon_id
        buyer_id
        max_redemptions
        times_redeemed
        redemptions_left
        expiresAt
        createdAt
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCouponQueryVariables, APITypes.GetCouponQuery>;
export const listCoupons = /* GraphQL */ `query ListCoupons(
  $filter: ModelCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCouponsQueryVariables,
  APITypes.ListCouponsQuery
>;
export const searchCoupons = /* GraphQL */ `query SearchCoupons(
  $filter: SearchableCouponFilterInput
  $sort: SearchableCouponSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCoupons(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCouponsQueryVariables,
  APITypes.SearchCouponsQuery
>;
export const getCouponLog = /* GraphQL */ `query GetCouponLog($id: ID!) {
  getCouponLog(id: $id) {
    id
    coupon_id
    buyer_id
    order_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCouponLogQueryVariables,
  APITypes.GetCouponLogQuery
>;
export const listCouponLogs = /* GraphQL */ `query ListCouponLogs(
  $filter: ModelCouponLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listCouponLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      coupon_id
      buyer_id
      order_ids
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCouponLogsQueryVariables,
  APITypes.ListCouponLogsQuery
>;
export const listCouponLogsByCoupon = /* GraphQL */ `query ListCouponLogsByCoupon(
  $coupon_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCouponLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listCouponLogsByCoupon(
    coupon_id: $coupon_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      coupon_id
      buyer_id
      order_ids
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCouponLogsByCouponQueryVariables,
  APITypes.ListCouponLogsByCouponQuery
>;
export const searchCouponLogs = /* GraphQL */ `query SearchCouponLogs(
  $filter: SearchableCouponLogFilterInput
  $sort: SearchableCouponLogSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCouponLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      coupon_id
      buyer_id
      order_ids
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCouponLogsQueryVariables,
  APITypes.SearchCouponLogsQuery
>;
export const getBuyerCoupon = /* GraphQL */ `query GetBuyerCoupon($id: ID!) {
  getBuyerCoupon(id: $id) {
    id
    coupon_id
    buyer_id
    max_redemptions
    times_redeemed
    redemptions_left
    expiresAt
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBuyerCouponQueryVariables,
  APITypes.GetBuyerCouponQuery
>;
export const listBuyerCoupons = /* GraphQL */ `query ListBuyerCoupons(
  $filter: ModelBuyerCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      coupon_id
      buyer_id
      max_redemptions
      times_redeemed
      redemptions_left
      expiresAt
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerCouponsQueryVariables,
  APITypes.ListBuyerCouponsQuery
>;
export const listBuyerCouponsByBuyer = /* GraphQL */ `query ListBuyerCouponsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBuyerCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerCouponsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      coupon_id
      buyer_id
      max_redemptions
      times_redeemed
      redemptions_left
      expiresAt
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerCouponsByBuyerQueryVariables,
  APITypes.ListBuyerCouponsByBuyerQuery
>;
export const listBuyerCouponsByCoupon = /* GraphQL */ `query ListBuyerCouponsByCoupon(
  $coupon_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBuyerCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerCouponsByCoupon(
    coupon_id: $coupon_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      coupon_id
      buyer_id
      max_redemptions
      times_redeemed
      redemptions_left
      expiresAt
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerCouponsByCouponQueryVariables,
  APITypes.ListBuyerCouponsByCouponQuery
>;
export const searchBuyerCoupons = /* GraphQL */ `query SearchBuyerCoupons(
  $filter: SearchableBuyerCouponFilterInput
  $sort: SearchableBuyerCouponSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBuyerCoupons(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      coupon_id
      buyer_id
      max_redemptions
      times_redeemed
      redemptions_left
      expiresAt
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBuyerCouponsQueryVariables,
  APITypes.SearchBuyerCouponsQuery
>;
export const getCashback = /* GraphQL */ `query GetCashback($id: ID!) {
  getCashback(id: $id) {
    id
    coupon_id
    buyer_id
    order_id
    redeemed
    createdAt
    updatedAt
    account {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashbackQueryVariables,
  APITypes.GetCashbackQuery
>;
export const listCashbacks = /* GraphQL */ `query ListCashbacks(
  $filter: ModelCashbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      coupon_id
      buyer_id
      order_id
      redeemed
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashbacksQueryVariables,
  APITypes.ListCashbacksQuery
>;
export const listCashbacksByBuyer = /* GraphQL */ `query ListCashbacksByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashbacksByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      coupon_id
      buyer_id
      order_id
      redeemed
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashbacksByBuyerQueryVariables,
  APITypes.ListCashbacksByBuyerQuery
>;
export const listCashbacksByCoupon = /* GraphQL */ `query ListCashbacksByCoupon(
  $coupon_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashbacksByCoupon(
    coupon_id: $coupon_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      coupon_id
      buyer_id
      order_id
      redeemed
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashbacksByCouponQueryVariables,
  APITypes.ListCashbacksByCouponQuery
>;
export const searchCashbacks = /* GraphQL */ `query SearchCashbacks(
  $filter: SearchableCashbackFilterInput
  $sort: SearchableCashbackSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCashbacks(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      coupon_id
      buyer_id
      order_id
      redeemed
      createdAt
      updatedAt
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCashbacksQueryVariables,
  APITypes.SearchCashbacksQuery
>;
export const getStockProduct = /* GraphQL */ `query GetStockProduct($id: ID!) {
  getStockProduct(id: $id) {
    id
    stock_id
    src_product_id
    src_product_type_id
    src_brand_id
    product_id
    product_type_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStockProductQueryVariables,
  APITypes.GetStockProductQuery
>;
export const listStockProducts = /* GraphQL */ `query ListStockProducts(
  $filter: ModelStockProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listStockProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stock_id
      src_product_id
      src_product_type_id
      src_brand_id
      product_id
      product_type_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStockProductsQueryVariables,
  APITypes.ListStockProductsQuery
>;
export const listStockProductsByProduct = /* GraphQL */ `query ListStockProductsByProduct(
  $product_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelStockProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listStockProductsByProduct(
    product_id: $product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stock_id
      src_product_id
      src_product_type_id
      src_brand_id
      product_id
      product_type_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStockProductsByProductQueryVariables,
  APITypes.ListStockProductsByProductQuery
>;
export const listStockProductsBySrcProduct = /* GraphQL */ `query ListStockProductsBySrcProduct(
  $src_product_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelStockProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listStockProductsBySrcProduct(
    src_product_id: $src_product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stock_id
      src_product_id
      src_product_type_id
      src_brand_id
      product_id
      product_type_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStockProductsBySrcProductQueryVariables,
  APITypes.ListStockProductsBySrcProductQuery
>;
export const listStockProductsByStockId = /* GraphQL */ `query ListStockProductsByStockId(
  $stock_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelStockProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listStockProductsByStockId(
    stock_id: $stock_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stock_id
      src_product_id
      src_product_type_id
      src_brand_id
      product_id
      product_type_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStockProductsByStockIdQueryVariables,
  APITypes.ListStockProductsByStockIdQuery
>;
export const searchStockProducts = /* GraphQL */ `query SearchStockProducts(
  $filter: SearchableStockProductFilterInput
  $sort: SearchableStockProductSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchStockProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      stock_id
      src_product_id
      src_product_type_id
      src_brand_id
      product_id
      product_type_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchStockProductsQueryVariables,
  APITypes.SearchStockProductsQuery
>;
export const getLogilessAuthToken = /* GraphQL */ `query GetLogilessAuthToken($id: ID!) {
  getLogilessAuthToken(id: $id) {
    id
    logiless_client_id
    access_token
    refresh_token
    expires_at
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLogilessAuthTokenQueryVariables,
  APITypes.GetLogilessAuthTokenQuery
>;
export const listLogilessAuthTokens = /* GraphQL */ `query ListLogilessAuthTokens(
  $filter: ModelLogilessAuthTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listLogilessAuthTokens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      logiless_client_id
      access_token
      refresh_token
      expires_at
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLogilessAuthTokensQueryVariables,
  APITypes.ListLogilessAuthTokensQuery
>;
export const listLogilessAuthTokensByClientId = /* GraphQL */ `query ListLogilessAuthTokensByClientId(
  $logiless_client_id: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogilessAuthTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listLogilessAuthTokensByClientId(
    logiless_client_id: $logiless_client_id
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      logiless_client_id
      access_token
      refresh_token
      expires_at
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLogilessAuthTokensByClientIdQueryVariables,
  APITypes.ListLogilessAuthTokensByClientIdQuery
>;
export const searchLogilessAuthTokens = /* GraphQL */ `query SearchLogilessAuthTokens(
  $filter: SearchableLogilessAuthTokenFilterInput
  $sort: SearchableLogilessAuthTokenSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchLogilessAuthTokens(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      logiless_client_id
      access_token
      refresh_token
      expires_at
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchLogilessAuthTokensQueryVariables,
  APITypes.SearchLogilessAuthTokensQuery
>;
export const getBuyerReadModel = /* GraphQL */ `query GetBuyerReadModel($id: ID!) {
  getBuyerReadModel(id: $id) {
    id
    account_company_name
    account_first_name
    account_id
    account_instagram_account
    account_last_name
    account_location
    account_tel
    account_web_site_URL
    account_zip_code
    billing_close_day
    buyer_brand
    buyer_credit
    buyer_kyc_status
    buyer_product_category
    buyer_rating
    buyer_returen_credit
    buyer_return_limit_per_brand
    buyer_type
    item_category
    metadata
    mf_customer_id
    mf_destination_id
    mf_examination_status
    owner
    owner_name
    payment_type
    primary_payment_method_id
    primary_payment_term
    primary_shipping_address_id
    primary_shipping_building
    primary_shipping_city
    primary_shipping_name
    primary_shipping_phone_number
    primary_shipping_prefecture
    primary_shipping_zip_code
    referral_brand_id
    referral_brand_name
    shop_image_keys
    shop_name
    store_category
    stripe_id
    total_order_amount
    total_order_amount_last_9m
    total_order_quantity
    total_order_quantity_last_9m
    total_return_amount
    total_return_amount_last_9m
    total_return_quantity
    total_return_quantity_last_9m
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBuyerReadModelQueryVariables,
  APITypes.GetBuyerReadModelQuery
>;
export const listBuyerReadModels = /* GraphQL */ `query ListBuyerReadModels(
  $filter: ModelBuyerReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerReadModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account_company_name
      account_first_name
      account_id
      account_instagram_account
      account_last_name
      account_location
      account_tel
      account_web_site_URL
      account_zip_code
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_returen_credit
      buyer_return_limit_per_brand
      buyer_type
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      primary_shipping_building
      primary_shipping_city
      primary_shipping_name
      primary_shipping_phone_number
      primary_shipping_prefecture
      primary_shipping_zip_code
      referral_brand_id
      referral_brand_name
      shop_image_keys
      shop_name
      store_category
      stripe_id
      total_order_amount
      total_order_amount_last_9m
      total_order_quantity
      total_order_quantity_last_9m
      total_return_amount
      total_return_amount_last_9m
      total_return_quantity
      total_return_quantity_last_9m
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerReadModelsQueryVariables,
  APITypes.ListBuyerReadModelsQuery
>;
export const listBuyerReadModelsByAccount = /* GraphQL */ `query ListBuyerReadModelsByAccount(
  $account_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelBuyerReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuyerReadModelsByAccount(
    account_id: $account_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account_company_name
      account_first_name
      account_id
      account_instagram_account
      account_last_name
      account_location
      account_tel
      account_web_site_URL
      account_zip_code
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_returen_credit
      buyer_return_limit_per_brand
      buyer_type
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      primary_shipping_building
      primary_shipping_city
      primary_shipping_name
      primary_shipping_phone_number
      primary_shipping_prefecture
      primary_shipping_zip_code
      referral_brand_id
      referral_brand_name
      shop_image_keys
      shop_name
      store_category
      stripe_id
      total_order_amount
      total_order_amount_last_9m
      total_order_quantity
      total_order_quantity_last_9m
      total_return_amount
      total_return_amount_last_9m
      total_return_quantity
      total_return_quantity_last_9m
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuyerReadModelsByAccountQueryVariables,
  APITypes.ListBuyerReadModelsByAccountQuery
>;
export const searchBuyerReadModels = /* GraphQL */ `query SearchBuyerReadModels(
  $filter: SearchableBuyerReadModelFilterInput
  $sort: SearchableBuyerReadModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBuyerReadModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      account_company_name
      account_first_name
      account_id
      account_instagram_account
      account_last_name
      account_location
      account_tel
      account_web_site_URL
      account_zip_code
      billing_close_day
      buyer_brand
      buyer_credit
      buyer_kyc_status
      buyer_product_category
      buyer_rating
      buyer_returen_credit
      buyer_return_limit_per_brand
      buyer_type
      item_category
      metadata
      mf_customer_id
      mf_destination_id
      mf_examination_status
      owner
      owner_name
      payment_type
      primary_payment_method_id
      primary_payment_term
      primary_shipping_address_id
      primary_shipping_building
      primary_shipping_city
      primary_shipping_name
      primary_shipping_phone_number
      primary_shipping_prefecture
      primary_shipping_zip_code
      referral_brand_id
      referral_brand_name
      shop_image_keys
      shop_name
      store_category
      stripe_id
      total_order_amount
      total_order_amount_last_9m
      total_order_quantity
      total_order_quantity_last_9m
      total_return_amount
      total_return_amount_last_9m
      total_return_quantity
      total_return_quantity_last_9m
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBuyerReadModelsQueryVariables,
  APITypes.SearchBuyerReadModelsQuery
>;
export const getBrandReadModel = /* GraphQL */ `query GetBrandReadModel($id: ID!) {
  getBrandReadModel(id: $id) {
    id
    company_name
    brand_owner
    brand_name
    brand_yomigana
    brand_type
    brand_content
    brand_imageKey
    brand_icon_imageKey
    brand_logo_imageKey
    brand_publication
    brand_retail_stores_number
    brand_category
    brand_target_age
    brand_target_gender
    brand_website_url
    brand_work_with_big_box
    brand_address
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_shippinng_fee
    brand_shippinng_fee_criteria
    brand_instagram_account
    brand_facebook_account
    brand_rating
    brand_annual_turnover
    brand_public_status
    brand_howtoknowhomula
    brand_audit_status
    brand_stripe_id
    brand_first_order_fee
    brand_additional_order_fee
    brand_first_order_fee_referral
    brand_additional_order_fee_referral
    brand_direct_order_fee
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    owner
    designed_in
    established
    tagIds
    tags
    brand_collections
    movie_url
    brand_modal_imageKeys
    brand_online_sale
    product_image_reprint
    brand_logo_reprint
    post_to_sns
    allow_direct_shipping
    allow_sell_before_buy
    other_terms
    stockists {
      stockist
      address
      __typename
    }
    featured_in
    maximum_wholesale_rate
    exclude_zip_code
    is_sale_brand
    hubspot_company_id
    return_to_brand
    terms_of_sample
    auto_message_enabled
    auto_message
    sell_only_direct
    is_published
    firstPublishedAt
    firstPublishedAtTimestamp
    lastPublishedAt
    lastPublishedAtTimestamp
    category_page_id
    product_category
    product_subcategory
    average_shipping_days
    service_rate
    review_average
    conversion_rate
    sell_through_rate
    reviews
    items
    conversion_rate_tier
    sell_through_rate_tier
    service_rate_rate_tier
    average_shipping_days_tier
    minimum_tier
    items_tier
    contacts_tier
    supplier_group_id
    supplier_group_title
    supplier_group_description
    supplier_group_image_key
    supplier_group_published
    individual_minimum_buy
    individual_shippinng_fee
    individual_shippinng_fee_criteria
    individual_first_order_fee
    individual_additional_order_fee
    individual_wholesale_rate
    collections {
      items {
        id
        brand_id
        src_brand_id
        name
        description
        image_key
        video_key
        published
        createdAt
        owner
        preorder
        is_consign
        season
        season_year
        display_order
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        srcBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        productCollections {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    contacts {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        zipcode
        address
        discount_rate
        contact_status
        contact_source
        direct_payment
        contacted
        signedup
        ordered
        invited
        createdAt
        owner
        updatedAt
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        buyerInfo {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        orders {
          nextToken
          __typename
        }
        contactListConnections {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        pageViews {
          nextToken
          __typename
        }
        pageViewsByEmail {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    review {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    products {
      items {
        id
        product_name
        product_number
        brand_id
        brand_name
        brand_yomigana
        brand_image_key
        brand_published
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_individual_minimum_buy
        brand_individual_wholesale_rate
        brand_tags
        product_content
        product_description
        product_size
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        origin_wholesale_price
        wholesale_rate
        wholesale_price
        individual_wholesale_rate
        individual_price
        individual_minimum_buy
        is_open_price
        product_jancode
        product_stock_quantity
        is_published
        is_limited
        product_category
        product_subcategory
        category_page_id
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_preorder_shipping_date_timestamp
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        image_key
        image_keys
        video_keys
        conversion_rate
        sell_through_rate
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        protected_zip_code
        options {
          id
          product_id
          size
          color
          type_name
          sku
          imageKey
          out_of_stock
          suspended
          published
          createdAt
          copied_product_type_id
          __typename
        }
        publishedAt
        publishedAtTimestamp
        createdAt
        createdAtTimestamp
        updatedAt
        updatedAtTimestamp
        conversion_rate_tier
        sell_through_rate_tier
        wholesale_rate_tier
        brand_tier
        brand_conversion_rate_tier
        brand_sell_through_rate_tier
        brand_service_rate_rate_tier
        brand_average_shipping_days_tier
        brand_minimum_tier
        brand_items_tier
        brand_contacts_tier
        collection_id
        sell_only_direct
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandReadModelQueryVariables,
  APITypes.GetBrandReadModelQuery
>;
export const listBrandReadModels = /* GraphQL */ `query ListBrandReadModels(
  $filter: ModelBrandReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrandReadModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      is_published
      firstPublishedAt
      firstPublishedAtTimestamp
      lastPublishedAt
      lastPublishedAtTimestamp
      category_page_id
      product_category
      product_subcategory
      average_shipping_days
      service_rate
      review_average
      conversion_rate
      sell_through_rate
      reviews
      items
      conversion_rate_tier
      sell_through_rate_tier
      service_rate_rate_tier
      average_shipping_days_tier
      minimum_tier
      items_tier
      contacts_tier
      supplier_group_id
      supplier_group_title
      supplier_group_description
      supplier_group_image_key
      supplier_group_published
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      review {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          product_name
          product_number
          brand_id
          brand_name
          brand_yomigana
          brand_image_key
          brand_published
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_individual_minimum_buy
          brand_individual_wholesale_rate
          brand_tags
          product_content
          product_description
          product_size
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          origin_wholesale_price
          wholesale_rate
          wholesale_price
          individual_wholesale_rate
          individual_price
          individual_minimum_buy
          is_open_price
          product_jancode
          product_stock_quantity
          is_published
          is_limited
          product_category
          product_subcategory
          category_page_id
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_preorder_shipping_date_timestamp
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          image_key
          image_keys
          video_keys
          conversion_rate
          sell_through_rate
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          protected_zip_code
          publishedAt
          publishedAtTimestamp
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          conversion_rate_tier
          sell_through_rate_tier
          wholesale_rate_tier
          brand_tier
          brand_conversion_rate_tier
          brand_sell_through_rate_tier
          brand_service_rate_rate_tier
          brand_average_shipping_days_tier
          brand_minimum_tier
          brand_items_tier
          brand_contacts_tier
          collection_id
          sell_only_direct
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandReadModelsQueryVariables,
  APITypes.ListBrandReadModelsQuery
>;
export const getProductReadModel = /* GraphQL */ `query GetProductReadModel($id: ID!) {
  getProductReadModel(id: $id) {
    id
    product_name
    product_number
    brand_id
    brand_name
    brand_yomigana
    brand_image_key
    brand_published
    brand_minimum_buy
    brand_additional_minimum_buy
    brand_individual_minimum_buy
    brand_individual_wholesale_rate
    brand_tags
    product_content
    product_description
    product_size
    product_owner
    product_wholesale_rate
    product_sale_wholesale_rate
    product_retail_price
    origin_wholesale_price
    wholesale_rate
    wholesale_price
    individual_wholesale_rate
    individual_price
    individual_minimum_buy
    is_open_price
    product_jancode
    product_stock_quantity
    is_published
    is_limited
    product_category
    product_subcategory
    category_page_id
    product_minimum_quantity
    product_estimated_ship_date_min
    product_estimated_ship_date_max
    product_order_lot
    product_preorder
    product_preorder_shipping_date
    product_preorder_shipping_date_timestamp
    product_types
    product_colors
    product_sizes
    product_display_order
    product_season
    product_year
    image_key
    image_keys
    video_keys
    conversion_rate
    sell_through_rate
    suspended
    continue_selling
    out_of_stock
    sku
    copied_product_id
    copied_brand_id
    limited_publishing_list
    protected_zip_code
    options {
      id
      product_id
      size
      color
      type_name
      sku
      imageKey
      out_of_stock
      suspended
      published
      createdAt
      copied_product_type_id
      __typename
    }
    publishedAt
    publishedAtTimestamp
    createdAt
    createdAtTimestamp
    updatedAt
    updatedAtTimestamp
    conversion_rate_tier
    sell_through_rate_tier
    wholesale_rate_tier
    brand_tier
    brand_conversion_rate_tier
    brand_sell_through_rate_tier
    brand_service_rate_rate_tier
    brand_average_shipping_days_tier
    brand_minimum_tier
    brand_items_tier
    brand_contacts_tier
    collection_id
    sell_only_direct
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductReadModelQueryVariables,
  APITypes.GetProductReadModelQuery
>;
export const listProductReadModels = /* GraphQL */ `query ListProductReadModels(
  $filter: ModelProductReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductReadModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_name
      product_number
      brand_id
      brand_name
      brand_yomigana
      brand_image_key
      brand_published
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_individual_minimum_buy
      brand_individual_wholesale_rate
      brand_tags
      product_content
      product_description
      product_size
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      origin_wholesale_price
      wholesale_rate
      wholesale_price
      individual_wholesale_rate
      individual_price
      individual_minimum_buy
      is_open_price
      product_jancode
      product_stock_quantity
      is_published
      is_limited
      product_category
      product_subcategory
      category_page_id
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_preorder_shipping_date_timestamp
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      image_key
      image_keys
      video_keys
      conversion_rate
      sell_through_rate
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      protected_zip_code
      options {
        id
        product_id
        size
        color
        type_name
        sku
        imageKey
        out_of_stock
        suspended
        published
        createdAt
        copied_product_type_id
        __typename
      }
      publishedAt
      publishedAtTimestamp
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      conversion_rate_tier
      sell_through_rate_tier
      wholesale_rate_tier
      brand_tier
      brand_conversion_rate_tier
      brand_sell_through_rate_tier
      brand_service_rate_rate_tier
      brand_average_shipping_days_tier
      brand_minimum_tier
      brand_items_tier
      brand_contacts_tier
      collection_id
      sell_only_direct
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductReadModelsQueryVariables,
  APITypes.ListProductReadModelsQuery
>;
export const listProductReadModelsByBrand = /* GraphQL */ `query ListProductReadModelsByBrand(
  $brand_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelProductReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductReadModelsByBrand(
    brand_id: $brand_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_name
      product_number
      brand_id
      brand_name
      brand_yomigana
      brand_image_key
      brand_published
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_individual_minimum_buy
      brand_individual_wholesale_rate
      brand_tags
      product_content
      product_description
      product_size
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      origin_wholesale_price
      wholesale_rate
      wholesale_price
      individual_wholesale_rate
      individual_price
      individual_minimum_buy
      is_open_price
      product_jancode
      product_stock_quantity
      is_published
      is_limited
      product_category
      product_subcategory
      category_page_id
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_preorder_shipping_date_timestamp
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      image_key
      image_keys
      video_keys
      conversion_rate
      sell_through_rate
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      protected_zip_code
      options {
        id
        product_id
        size
        color
        type_name
        sku
        imageKey
        out_of_stock
        suspended
        published
        createdAt
        copied_product_type_id
        __typename
      }
      publishedAt
      publishedAtTimestamp
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      conversion_rate_tier
      sell_through_rate_tier
      wholesale_rate_tier
      brand_tier
      brand_conversion_rate_tier
      brand_sell_through_rate_tier
      brand_service_rate_rate_tier
      brand_average_shipping_days_tier
      brand_minimum_tier
      brand_items_tier
      brand_contacts_tier
      collection_id
      sell_only_direct
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductReadModelsByBrandQueryVariables,
  APITypes.ListProductReadModelsByBrandQuery
>;
export const listProductReadModelsBySku = /* GraphQL */ `query ListProductReadModelsBySku(
  $sku: String
  $sortDirection: ModelSortDirection
  $filter: ModelProductReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductReadModelsBySku(
    sku: $sku
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_name
      product_number
      brand_id
      brand_name
      brand_yomigana
      brand_image_key
      brand_published
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_individual_minimum_buy
      brand_individual_wholesale_rate
      brand_tags
      product_content
      product_description
      product_size
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      origin_wholesale_price
      wholesale_rate
      wholesale_price
      individual_wholesale_rate
      individual_price
      individual_minimum_buy
      is_open_price
      product_jancode
      product_stock_quantity
      is_published
      is_limited
      product_category
      product_subcategory
      category_page_id
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_preorder_shipping_date_timestamp
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      image_key
      image_keys
      video_keys
      conversion_rate
      sell_through_rate
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      protected_zip_code
      options {
        id
        product_id
        size
        color
        type_name
        sku
        imageKey
        out_of_stock
        suspended
        published
        createdAt
        copied_product_type_id
        __typename
      }
      publishedAt
      publishedAtTimestamp
      createdAt
      createdAtTimestamp
      updatedAt
      updatedAtTimestamp
      conversion_rate_tier
      sell_through_rate_tier
      wholesale_rate_tier
      brand_tier
      brand_conversion_rate_tier
      brand_sell_through_rate_tier
      brand_service_rate_rate_tier
      brand_average_shipping_days_tier
      brand_minimum_tier
      brand_items_tier
      brand_contacts_tier
      collection_id
      sell_only_direct
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductReadModelsBySkuQueryVariables,
  APITypes.ListProductReadModelsBySkuQuery
>;
export const getCollectionReadModel = /* GraphQL */ `query GetCollectionReadModel($id: ID!) {
  getCollectionReadModel(id: $id) {
    id
    name
    brand_id
    brand_name
    brand_categories
    brand_tags
    src_brand_id
    src_brand_name
    src_brand_categories
    src_brand_tags
    description
    image_key
    video_key
    published
    createdAt
    owner
    preorder
    is_consign
    season
    season_year
    product_ids
    product_names
    product_image_keys
    product_numbers
    product_categories
    product_subcategories
    total_quantity
    total_retail_amount
    total_original_amount
    total_amount
    total_items
    wholesale_rate
    minimum_wholesale_rate
    sell_only_direct
    display_order
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCollectionReadModelQueryVariables,
  APITypes.GetCollectionReadModelQuery
>;
export const listCollectionReadModels = /* GraphQL */ `query ListCollectionReadModels(
  $filter: ModelCollectionReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollectionReadModels(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      brand_id
      brand_name
      brand_categories
      brand_tags
      src_brand_id
      src_brand_name
      src_brand_categories
      src_brand_tags
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      product_ids
      product_names
      product_image_keys
      product_numbers
      product_categories
      product_subcategories
      total_quantity
      total_retail_amount
      total_original_amount
      total_amount
      total_items
      wholesale_rate
      minimum_wholesale_rate
      sell_only_direct
      display_order
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionReadModelsQueryVariables,
  APITypes.ListCollectionReadModelsQuery
>;
export const listCollectionReadModelsByBrand = /* GraphQL */ `query ListCollectionReadModelsByBrand(
  $brand_id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCollectionReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollectionReadModelsByBrand(
    brand_id: $brand_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      brand_id
      brand_name
      brand_categories
      brand_tags
      src_brand_id
      src_brand_name
      src_brand_categories
      src_brand_tags
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      product_ids
      product_names
      product_image_keys
      product_numbers
      product_categories
      product_subcategories
      total_quantity
      total_retail_amount
      total_original_amount
      total_amount
      total_items
      wholesale_rate
      minimum_wholesale_rate
      sell_only_direct
      display_order
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionReadModelsByBrandQueryVariables,
  APITypes.ListCollectionReadModelsByBrandQuery
>;
export const searchCollectionReadModels = /* GraphQL */ `query SearchCollectionReadModels(
  $filter: SearchableCollectionReadModelFilterInput
  $sort: SearchableCollectionReadModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCollectionReadModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      name
      brand_id
      brand_name
      brand_categories
      brand_tags
      src_brand_id
      src_brand_name
      src_brand_categories
      src_brand_tags
      description
      image_key
      video_key
      published
      createdAt
      owner
      preorder
      is_consign
      season
      season_year
      product_ids
      product_names
      product_image_keys
      product_numbers
      product_categories
      product_subcategories
      total_quantity
      total_retail_amount
      total_original_amount
      total_amount
      total_items
      wholesale_rate
      minimum_wholesale_rate
      sell_only_direct
      display_order
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCollectionReadModelsQueryVariables,
  APITypes.SearchCollectionReadModelsQuery
>;
export const getOrderReadModel = /* GraphQL */ `query GetOrderReadModel($id: ID!) {
  getOrderReadModel(id: $id) {
    id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    brand_image_key
    shipping_zip_code
    shipping_address
    shipping_name
    shipping_phone_number
    carrier
    tracking_number
    shipping_date
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_due_date
    shipping_fee
    invoice_shipping_fee
    tax_rate
    tax
    order_quantity
    order_subtotal
    order_amount
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_amount
    report_approved_quantity
    report_approved_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    points
    coupon_id
    discount_amount
    campaign_code
    cashback_amount
    order_fee_rate
    order_fee
    origin_order_id
    status
    status_label
    payment_status
    payment_status_label
    payment_term
    payment_term_label
    stripe_payment_id
    stripe_client_secret
    stripe_payment_method_id
    mf_authorization_id
    mf_transaction_id
    mf_canceled_transaction_id
    billing_count
    is_first
    is_direct
    is_consign
    is_preorder
    product_ids
    product_names
    product_numbers
    skus
    bill_payment_status
    owners
    createdAt
    updatedAt
    memo
    split_source_id
    split_date
    buyerAccount {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reviews {
      items {
        id
        brand_id
        product_ids
        buyer_id
        order_id
        rate
        title
        comment
        remove_flag
        createdAt
        updatedAt
        buyerInfo {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        replies {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    coupon {
      id
      coupon_name
      start_date
      end_date
      brand_ids
      discount_type
      discount_value
      maximum_amount
      available_times
      duration
      createdAt
      updatedAt
      logs {
        items {
          id
          coupon_id
          buyer_id
          order_ids
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      buyers {
        items {
          id
          coupon_id
          buyer_id
          max_redemptions
          times_redeemed
          redemptions_left
          expiresAt
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    details {
      items {
        id
        order_id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        product_id
        product_name
        product_number
        sku
        product_type_id
        product_image_key
        color
        size
        type_name
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        initial_quantity
        initial_price
        initial_wholesale_rate
        quantity
        price
        wholesale_rate
        subtotal
        amount
        tax_rate
        tax
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_approved_amount
        report_approved_quantity
        report_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        coupon_id
        discount_amount
        discount_percentage
        order_fee_rate
        order_fee
        status
        status_label
        payment_term
        payment_term_label
        payment_status
        payment_status_label
        memo
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        order {
          id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          brand_image_key
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          carrier
          tracking_number
          shipping_date
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          return_due_date
          shipping_fee
          invoice_shipping_fee
          tax_rate
          tax
          order_quantity
          order_subtotal
          order_amount
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_amount
          report_approved_quantity
          report_approved_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          points
          coupon_id
          discount_amount
          campaign_code
          cashback_amount
          order_fee_rate
          order_fee
          origin_order_id
          status
          status_label
          payment_status
          payment_status_label
          payment_term
          payment_term_label
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          billing_count
          is_first
          is_direct
          is_consign
          is_preorder
          product_ids
          product_names
          product_numbers
          skus
          bill_payment_status
          owners
          createdAt
          updatedAt
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderReadModelQueryVariables,
  APITypes.GetOrderReadModelQuery
>;
export const listOrderReadModels = /* GraphQL */ `query ListOrderReadModels(
  $filter: ModelOrderReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderReadModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderReadModelsQueryVariables,
  APITypes.ListOrderReadModelsQuery
>;
export const listOrderReadModelsByBuyer = /* GraphQL */ `query ListOrderReadModelsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderReadModelsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderReadModelsByBuyerQueryVariables,
  APITypes.ListOrderReadModelsByBuyerQuery
>;
export const listOrderReadModelsBySupplier = /* GraphQL */ `query ListOrderReadModelsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderReadModelsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderReadModelsBySupplierQueryVariables,
  APITypes.ListOrderReadModelsBySupplierQuery
>;
export const listOrderReadModelsByBrand = /* GraphQL */ `query ListOrderReadModelsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderReadModelsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderReadModelsByBrandQueryVariables,
  APITypes.ListOrderReadModelsByBrandQuery
>;
export const searchOrderReadModels = /* GraphQL */ `query SearchOrderReadModels(
  $filter: SearchableOrderReadModelFilterInput
  $sort: SearchableOrderReadModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrderReadModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrderReadModelsQueryVariables,
  APITypes.SearchOrderReadModelsQuery
>;
export const getOrderDetailReadModel = /* GraphQL */ `query GetOrderDetailReadModel($id: ID!) {
  getOrderDetailReadModel(id: $id) {
    id
    order_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    initial_quantity
    initial_price
    initial_wholesale_rate
    quantity
    price
    wholesale_rate
    subtotal
    amount
    tax_rate
    tax
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    report_quantity
    report_approved_amount
    report_approved_quantity
    report_amount
    extended_quantity
    extended_amount
    payment_quantity
    payment_amount
    coupon_id
    discount_amount
    discount_percentage
    order_fee_rate
    order_fee
    status
    status_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    memo
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    editReasons {
      items {
        id
        order_id
        edit_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    cancelReasons {
      items {
        id
        order_id
        cancel_reason
        feedback_comment
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderDetailReadModelQueryVariables,
  APITypes.GetOrderDetailReadModelQuery
>;
export const listOrderDetailReadModels = /* GraphQL */ `query ListOrderDetailReadModels(
  $filter: ModelOrderDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetailReadModels(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailReadModelsQueryVariables,
  APITypes.ListOrderDetailReadModelsQuery
>;
export const listOrderDetailReadModelsByBuyer = /* GraphQL */ `query ListOrderDetailReadModelsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetailReadModelsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailReadModelsByBuyerQueryVariables,
  APITypes.ListOrderDetailReadModelsByBuyerQuery
>;
export const listOrderDetailReadModelsBySupplier = /* GraphQL */ `query ListOrderDetailReadModelsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetailReadModelsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailReadModelsBySupplierQueryVariables,
  APITypes.ListOrderDetailReadModelsBySupplierQuery
>;
export const listOrderDetailReadModelsByBrand = /* GraphQL */ `query ListOrderDetailReadModelsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetailReadModelsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailReadModelsByBrandQueryVariables,
  APITypes.ListOrderDetailReadModelsByBrandQuery
>;
export const listOrderDetailReadModelsByProduct = /* GraphQL */ `query ListOrderDetailReadModelsByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetailReadModelsByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailReadModelsByProductQueryVariables,
  APITypes.ListOrderDetailReadModelsByProductQuery
>;
export const listOrderDetailReadModelsByOrder = /* GraphQL */ `query ListOrderDetailReadModelsByOrder(
  $order_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetailReadModelsByOrder(
    order_id: $order_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailReadModelsByOrderQueryVariables,
  APITypes.ListOrderDetailReadModelsByOrderQuery
>;
export const searchOrderDetailReadModels = /* GraphQL */ `query SearchOrderDetailReadModels(
  $filter: SearchableOrderDetailReadModelFilterInput
  $sort: SearchableOrderDetailReadModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchOrderDetailReadModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      initial_quantity
      initial_price
      initial_wholesale_rate
      quantity
      price
      wholesale_rate
      subtotal
      amount
      tax_rate
      tax
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_approved_amount
      report_approved_quantity
      report_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      coupon_id
      discount_amount
      discount_percentage
      order_fee_rate
      order_fee
      status
      status_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      memo
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      editReasons {
        items {
          id
          order_id
          edit_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cancelReasons {
        items {
          id
          order_id
          cancel_reason
          feedback_comment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrderDetailReadModelsQueryVariables,
  APITypes.SearchOrderDetailReadModelsQuery
>;
export const getReturnDetailReadModel = /* GraphQL */ `query GetReturnDetailReadModel($id: ID!) {
  getReturnDetailReadModel(id: $id) {
    id
    order_id
    order_product_id
    buyer_id
    buyer_company_name
    buyer_shop_name
    supplier_id
    supplier_company_name
    brand_id
    brand_name
    product_id
    product_name
    product_number
    sku
    product_type_id
    product_image_key
    color
    size
    type_name
    shipped_date
    extended_date
    payment_date
    extended_payment_date
    return_request_due_date
    return_request_date
    return_due_date
    return_date
    order_quantity
    order_price
    wholesale_rate
    order_subtotal
    order_amount
    order_tax
    tax_rate
    return_request_quantity
    return_request_amount
    return_quantity
    return_amount
    status
    status_label
    return_destination
    return_destination_label
    payment_term
    payment_term_label
    payment_status
    payment_status_label
    stripe_payment_id
    mf_transaction_id
    owners
    createdAt
    updatedAt
    product {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      product_season
      product_year
      publishedAt
      createdAt
      updatedAt
      sort_recommended
      suspended
      continue_selling
      out_of_stock
      sku
      copied_product_id
      copied_brand_id
      limited_publishing_list
      owner
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videoKeys {
        items {
          id
          product_id
          videoKey
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      producttype {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          sku
          out_of_stock
          copied_product_type_id
          copied_brand_id
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      copiedBrand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      collections {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productType {
      id
      product_id
      imageKey
      type_name
      color
      size
      createdAt
      suspended
      published
      sku
      out_of_stock
      copied_product_type_id
      copied_brand_id
      owner
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      inventoryConnection {
        items {
          id
          brand_id
          product_id
          product_type_id
          sku
          inventory
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    brand {
      id
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
        __typename
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      auto_message_enabled
      auto_message
      sell_only_direct
      supplier_group_id
      individual_minimum_buy
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
      individual_wholesale_rate
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      supplierGroup {
        id
        title
        description
        image_key
        published
        createdAt
        updatedAt
        owner
        brands {
          nextToken
          __typename
        }
        __typename
      }
      product {
        items {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        nextToken
        __typename
      }
      collections {
        items {
          id
          brand_id
          src_brand_id
          name
          description
          image_key
          video_key
          published
          createdAt
          owner
          preorder
          is_consign
          season
          season_year
          display_order
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          zipcode
          address
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          invited
          createdAt
          owner
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    order {
      id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      brand_image_key
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      carrier
      tracking_number
      shipping_date
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_due_date
      shipping_fee
      invoice_shipping_fee
      tax_rate
      tax
      order_quantity
      order_subtotal
      order_amount
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      report_quantity
      report_amount
      report_approved_quantity
      report_approved_amount
      extended_quantity
      extended_amount
      payment_quantity
      payment_amount
      points
      coupon_id
      discount_amount
      campaign_code
      cashback_amount
      order_fee_rate
      order_fee
      origin_order_id
      status
      status_label
      payment_status
      payment_status_label
      payment_term
      payment_term_label
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      mf_authorization_id
      mf_transaction_id
      mf_canceled_transaction_id
      billing_count
      is_first
      is_direct
      is_consign
      is_preorder
      product_ids
      product_names
      product_numbers
      skus
      bill_payment_status
      owners
      createdAt
      updatedAt
      memo
      split_source_id
      split_date
      buyerAccount {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      reviews {
        items {
          id
          brand_id
          product_ids
          buyer_id
          order_id
          rate
          title
          comment
          remove_flag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coupon {
        id
        coupon_name
        start_date
        end_date
        brand_ids
        discount_type
        discount_value
        maximum_amount
        available_times
        duration
        createdAt
        updatedAt
        logs {
          nextToken
          __typename
        }
        buyers {
          nextToken
          __typename
        }
        __typename
      }
      details {
        items {
          id
          order_id
          buyer_id
          buyer_company_name
          buyer_shop_name
          supplier_id
          supplier_company_name
          brand_id
          brand_name
          product_id
          product_name
          product_number
          sku
          product_type_id
          product_image_key
          color
          size
          type_name
          shipped_date
          extended_date
          payment_date
          extended_payment_date
          initial_quantity
          initial_price
          initial_wholesale_rate
          quantity
          price
          wholesale_rate
          subtotal
          amount
          tax_rate
          tax
          return_request_quantity
          return_request_amount
          return_quantity
          return_amount
          report_quantity
          report_approved_amount
          report_approved_quantity
          report_amount
          extended_quantity
          extended_amount
          payment_quantity
          payment_amount
          coupon_id
          discount_amount
          discount_percentage
          order_fee_rate
          order_fee
          status
          status_label
          payment_term
          payment_term_label
          payment_status
          payment_status_label
          memo
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReturnDetailReadModelQueryVariables,
  APITypes.GetReturnDetailReadModelQuery
>;
export const listReturnDetailReadModels = /* GraphQL */ `query ListReturnDetailReadModels(
  $filter: ModelReturnDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnDetailReadModels(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnDetailReadModelsQueryVariables,
  APITypes.ListReturnDetailReadModelsQuery
>;
export const listReturnDetailReadModelsByBuyer = /* GraphQL */ `query ListReturnDetailReadModelsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnDetailReadModelsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnDetailReadModelsByBuyerQueryVariables,
  APITypes.ListReturnDetailReadModelsByBuyerQuery
>;
export const listReturnDetailReadModelsBySupplier = /* GraphQL */ `query ListReturnDetailReadModelsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnDetailReadModelsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnDetailReadModelsBySupplierQueryVariables,
  APITypes.ListReturnDetailReadModelsBySupplierQuery
>;
export const listReturnDetailReadModelsByBrand = /* GraphQL */ `query ListReturnDetailReadModelsByBrand(
  $brand_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnDetailReadModelsByBrand(
    brand_id: $brand_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnDetailReadModelsByBrandQueryVariables,
  APITypes.ListReturnDetailReadModelsByBrandQuery
>;
export const listReturnDetailReadModelsByProduct = /* GraphQL */ `query ListReturnDetailReadModelsByProduct(
  $product_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnDetailReadModelsByProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnDetailReadModelsByProductQueryVariables,
  APITypes.ListReturnDetailReadModelsByProductQuery
>;
export const listReturnDetailReadModelsByOrder = /* GraphQL */ `query ListReturnDetailReadModelsByOrder(
  $order_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReturnDetailReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listReturnDetailReadModelsByOrder(
    order_id: $order_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReturnDetailReadModelsByOrderQueryVariables,
  APITypes.ListReturnDetailReadModelsByOrderQuery
>;
export const searchReturnDetailReadModels = /* GraphQL */ `query SearchReturnDetailReadModels(
  $filter: SearchableReturnDetailReadModelFilterInput
  $sort: SearchableReturnDetailReadModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReturnDetailReadModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      order_id
      order_product_id
      buyer_id
      buyer_company_name
      buyer_shop_name
      supplier_id
      supplier_company_name
      brand_id
      brand_name
      product_id
      product_name
      product_number
      sku
      product_type_id
      product_image_key
      color
      size
      type_name
      shipped_date
      extended_date
      payment_date
      extended_payment_date
      return_request_due_date
      return_request_date
      return_due_date
      return_date
      order_quantity
      order_price
      wholesale_rate
      order_subtotal
      order_amount
      order_tax
      tax_rate
      return_request_quantity
      return_request_amount
      return_quantity
      return_amount
      status
      status_label
      return_destination
      return_destination_label
      payment_term
      payment_term_label
      payment_status
      payment_status_label
      stripe_payment_id
      mf_transaction_id
      owners
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        product_season
        product_year
        publishedAt
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        contacts {
          nextToken
          __typename
        }
        imageKeys {
          nextToken
          __typename
        }
        videoKeys {
          nextToken
          __typename
        }
        producttype {
          nextToken
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        copiedBrand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        collections {
          nextToken
          __typename
        }
        __typename
      }
      productType {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        sku
        out_of_stock
        copied_product_type_id
        copied_brand_id
        owner
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          product_season
          product_year
          publishedAt
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          __typename
        }
        inventoryConnection {
          nextToken
          __typename
        }
        __typename
      }
      brand {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
          __typename
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
        sell_only_direct
        supplier_group_id
        individual_minimum_buy
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
        individual_wholesale_rate
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          nextToken
          __typename
        }
        collections {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        __typename
      }
      order {
        id
        buyer_id
        buyer_company_name
        buyer_shop_name
        supplier_id
        supplier_company_name
        brand_id
        brand_name
        brand_image_key
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        carrier
        tracking_number
        shipping_date
        shipped_date
        extended_date
        payment_date
        extended_payment_date
        return_due_date
        shipping_fee
        invoice_shipping_fee
        tax_rate
        tax
        order_quantity
        order_subtotal
        order_amount
        return_request_quantity
        return_request_amount
        return_quantity
        return_amount
        report_quantity
        report_amount
        report_approved_quantity
        report_approved_amount
        extended_quantity
        extended_amount
        payment_quantity
        payment_amount
        points
        coupon_id
        discount_amount
        campaign_code
        cashback_amount
        order_fee_rate
        order_fee
        origin_order_id
        status
        status_label
        payment_status
        payment_status_label
        payment_term
        payment_term_label
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        billing_count
        is_first
        is_direct
        is_consign
        is_preorder
        product_ids
        product_names
        product_numbers
        skus
        bill_payment_status
        owners
        createdAt
        updatedAt
        memo
        split_source_id
        split_date
        buyerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        details {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReturnDetailReadModelsQueryVariables,
  APITypes.SearchReturnDetailReadModelsQuery
>;
export const getContactReadModel = /* GraphQL */ `query GetContactReadModel($id: ID!) {
  getContactReadModel(id: $id) {
    id
    supplier_id
    buyer_id
    buyer_owner
    email
    store_name
    contact_name
    tel
    zipcode
    address
    discount_rate
    contact_status
    contact_source
    direct_payment
    contacted
    signedup
    ordered
    invited
    createdAt
    owner
    buyer_web_site_URL
    buyer_instagram_account
    buyer_company_name
    contact_list_id
    orderCount
    pageViewCount
    updatedAt
    supplierInfo {
      id
      account_id
      supplier_contract_type
      bank_name
      bank_branch_name
      bank_account_number
      bank_account_name
      bank_account_type
      createdAt
      updatedAt
      owner
      account {
        id
        email
        first_name
        last_name
        web_site_URL
        instagram_account
        zip_code
        location
        tel
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
        buyerInfo {
          nextToken
          __typename
        }
        supplierInfo {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    orders {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        order_price
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        coupon_id
        discount_amount
        billing_count
        return_due_date
        bill_payment_status
        memo
        split_source_id
        split_date
        buyer {
          nextToken
          __typename
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          supplier_group_id
          individual_minimum_buy
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          individual_wholesale_rate
          __typename
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        chargeStatuses {
          nextToken
          __typename
        }
        orderproducts {
          nextToken
          __typename
        }
        returnproducts {
          nextToken
          __typename
        }
        reportProducts {
          nextToken
          __typename
        }
        editReasons {
          nextToken
          __typename
        }
        cancelReasons {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        coupon {
          id
          coupon_name
          start_date
          end_date
          brand_ids
          discount_type
          discount_value
          maximum_amount
          available_times
          duration
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    activities {
      items {
        id
        contact_id
        activity_date
        activity_type
        order_id
        message_id
        campaign_id
        activity
        owner
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_sender
          campaign_from
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    pageViews {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pageViewsByEmail {
      items {
        brand_id
        buyer_id
        email
        page_view
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactReadModelQueryVariables,
  APITypes.GetContactReadModelQuery
>;
export const listContactReadModels = /* GraphQL */ `query ListContactReadModels(
  $filter: ModelContactReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactReadModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      buyer_web_site_URL
      buyer_instagram_account
      buyer_company_name
      contact_list_id
      orderCount
      pageViewCount
      updatedAt
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactReadModelsQueryVariables,
  APITypes.ListContactReadModelsQuery
>;
export const listContactReadModelsBySupplier = /* GraphQL */ `query ListContactReadModelsBySupplier(
  $supplier_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactReadModelsBySupplier(
    supplier_id: $supplier_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      buyer_web_site_URL
      buyer_instagram_account
      buyer_company_name
      contact_list_id
      orderCount
      pageViewCount
      updatedAt
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactReadModelsBySupplierQueryVariables,
  APITypes.ListContactReadModelsBySupplierQuery
>;
export const listContactReadModelsByOwner = /* GraphQL */ `query ListContactReadModelsByOwner(
  $owner: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactReadModelsByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      buyer_web_site_URL
      buyer_instagram_account
      buyer_company_name
      contact_list_id
      orderCount
      pageViewCount
      updatedAt
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactReadModelsByOwnerQueryVariables,
  APITypes.ListContactReadModelsByOwnerQuery
>;
export const listContactReadModelsByBuyer = /* GraphQL */ `query ListContactReadModelsByBuyer(
  $buyer_id: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactReadModelsByBuyer(
    buyer_id: $buyer_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      buyer_web_site_URL
      buyer_instagram_account
      buyer_company_name
      contact_list_id
      orderCount
      pageViewCount
      updatedAt
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactReadModelsByBuyerQueryVariables,
  APITypes.ListContactReadModelsByBuyerQuery
>;
export const listContactReadModelsByEmail = /* GraphQL */ `query ListContactReadModelsByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelContactReadModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactReadModelsByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      buyer_web_site_URL
      buyer_instagram_account
      buyer_company_name
      contact_list_id
      orderCount
      pageViewCount
      updatedAt
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactReadModelsByEmailQueryVariables,
  APITypes.ListContactReadModelsByEmailQuery
>;
export const searchContactReadModels = /* GraphQL */ `query SearchContactReadModels(
  $filter: SearchableContactReadModelFilterInput
  $sort: SearchableContactReadModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchContactReadModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      contact_status
      contact_source
      direct_payment
      contacted
      signedup
      ordered
      invited
      createdAt
      owner
      buyer_web_site_URL
      buyer_instagram_account
      buyer_company_name
      contact_list_id
      orderCount
      pageViewCount
      updatedAt
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      orders {
        items {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          is_consign
          transferred_date
          order_price
          origin_order_id
          mf_authorization_id
          mf_transaction_id
          mf_canceled_transaction_id
          cashback
          coupon_id
          discount_amount
          billing_count
          return_due_date
          bill_payment_status
          memo
          split_source_id
          split_date
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViews {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          email
          page_view
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchContactReadModelsQueryVariables,
  APITypes.SearchContactReadModelsQuery
>;
export const getOrderEvent = /* GraphQL */ `query GetOrderEvent($id: ID!) {
  getOrderEvent(id: $id) {
    id
    event_name
    event_date
    order_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderEventQueryVariables,
  APITypes.GetOrderEventQuery
>;
export const listOrderEvents = /* GraphQL */ `query ListOrderEvents(
  $filter: ModelOrderEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      event_name
      event_date
      order_id
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderEventsQueryVariables,
  APITypes.ListOrderEventsQuery
>;
export const listOrderEventsByEvent = /* GraphQL */ `query ListOrderEventsByEvent(
  $event_name: EventName
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderEventsByEvent(
    event_name: $event_name
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      event_name
      event_date
      order_id
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderEventsByEventQueryVariables,
  APITypes.ListOrderEventsByEventQuery
>;
export const getOrderProductEvent = /* GraphQL */ `query GetOrderProductEvent($id: ID!) {
  getOrderProductEvent(id: $id) {
    id
    order_id
    order_product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderProductEventQueryVariables,
  APITypes.GetOrderProductEventQuery
>;
export const listOrderProductEvents = /* GraphQL */ `query ListOrderProductEvents(
  $filter: ModelOrderProductEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderProductEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderProductEventsQueryVariables,
  APITypes.ListOrderProductEventsQuery
>;
export const listOrderEventsByOrder = /* GraphQL */ `query ListOrderEventsByOrder(
  $order_id: ID
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderEventsByOrder(
    order_id: $order_id
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      event_name
      event_date
      order_id
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderEventsByOrderQueryVariables,
  APITypes.ListOrderEventsByOrderQuery
>;
export const listOrderProductEventsByOrder = /* GraphQL */ `query ListOrderProductEventsByOrder(
  $order_product_id: ID
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderProductEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderProductEventsByOrder(
    order_product_id: $order_product_id
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderProductEventsByOrderQueryVariables,
  APITypes.ListOrderProductEventsByOrderQuery
>;
export const listOrderProductEventsByEvent = /* GraphQL */ `query ListOrderProductEventsByEvent(
  $event_name: EventName
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderProductEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderProductEventsByEvent(
    event_name: $event_name
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order_id
      order_product_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderProductEventsByEventQueryVariables,
  APITypes.ListOrderProductEventsByEventQuery
>;
export const getBrandEvent = /* GraphQL */ `query GetBrandEvent($id: ID!) {
  getBrandEvent(id: $id) {
    id
    event_name
    event_date
    brand_id
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandEventQueryVariables,
  APITypes.GetBrandEventQuery
>;
export const listBrandEvents = /* GraphQL */ `query ListBrandEvents(
  $filter: ModelBrandEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrandEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      event_name
      event_date
      brand_id
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandEventsQueryVariables,
  APITypes.ListBrandEventsQuery
>;
export const listBrandEventsByBrand = /* GraphQL */ `query ListBrandEventsByBrand(
  $brand_id: ID
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBrandEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrandEventsByBrand(
    brand_id: $brand_id
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      event_name
      event_date
      brand_id
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandEventsByBrandQueryVariables,
  APITypes.ListBrandEventsByBrandQuery
>;
export const listBrandEventsByEvent = /* GraphQL */ `query ListBrandEventsByEvent(
  $event_name: EventName
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBrandEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrandEventsByEvent(
    event_name: $event_name
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      event_name
      event_date
      brand_id
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandEventsByEventQueryVariables,
  APITypes.ListBrandEventsByEventQuery
>;
export const getProductEvent = /* GraphQL */ `query GetProductEvent($id: ID!) {
  getProductEvent(id: $id) {
    id
    product_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductEventQueryVariables,
  APITypes.GetProductEventQuery
>;
export const listProductEvents = /* GraphQL */ `query ListProductEvents(
  $filter: ModelProductEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductEventsQueryVariables,
  APITypes.ListProductEventsQuery
>;
export const listProductEventsByProduct = /* GraphQL */ `query ListProductEventsByProduct(
  $product_id: ID
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductEventsByProduct(
    product_id: $product_id
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductEventsByProductQueryVariables,
  APITypes.ListProductEventsByProductQuery
>;
export const listProductEventsByEvent = /* GraphQL */ `query ListProductEventsByEvent(
  $event_name: EventName
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductEventsByEvent(
    event_name: $event_name
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductEventsByEventQueryVariables,
  APITypes.ListProductEventsByEventQuery
>;
export const getProductTypeEvent = /* GraphQL */ `query GetProductTypeEvent($id: ID!) {
  getProductTypeEvent(id: $id) {
    id
    product_id
    product_type_id
    event_name
    event_date
    new_item
    old_item
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductTypeEventQueryVariables,
  APITypes.GetProductTypeEventQuery
>;
export const listProductTypeEvents = /* GraphQL */ `query ListProductTypeEvents(
  $filter: ModelProductTypeEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypeEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      product_type_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypeEventsQueryVariables,
  APITypes.ListProductTypeEventsQuery
>;
export const listProductTypeEventsByProduct = /* GraphQL */ `query ListProductTypeEventsByProduct(
  $product_id: ID
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductTypeEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypeEventsByProduct(
    product_id: $product_id
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      product_type_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypeEventsByProductQueryVariables,
  APITypes.ListProductTypeEventsByProductQuery
>;
export const listProductTypeEventsByProductType = /* GraphQL */ `query ListProductTypeEventsByProductType(
  $product_type_id: ID
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductTypeEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypeEventsByProductType(
    product_type_id: $product_type_id
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      product_type_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypeEventsByProductTypeQueryVariables,
  APITypes.ListProductTypeEventsByProductTypeQuery
>;
export const listProductTypeEventsByEvent = /* GraphQL */ `query ListProductTypeEventsByEvent(
  $event_name: EventName
  $event_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductTypeEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypeEventsByEvent(
    event_name: $event_name
    event_date: $event_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      product_type_id
      event_name
      event_date
      new_item
      old_item
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypeEventsByEventQueryVariables,
  APITypes.ListProductTypeEventsByEventQuery
>;
export const getBrandName = /* GraphQL */ `query GetBrandName($brandId: String!) {
  getBrandName(brandId: $brandId)
}
` as GeneratedQuery<
  APITypes.GetBrandNameQueryVariables,
  APITypes.GetBrandNameQuery
>;
export const getContent = /* GraphQL */ `query GetContent($endpoint: String!, $contentId: String, $draftKey: String) {
  getContent(endpoint: $endpoint, contentId: $contentId, draftKey: $draftKey)
}
` as GeneratedQuery<
  APITypes.GetContentQueryVariables,
  APITypes.GetContentQuery
>;
