import { Dispatch, useState } from 'react';
import { useDispatch } from 'react-redux';
import { warn } from './log';

const createThunk = <T extends (...args: any[]) => any>(
  thunk: T,
  setLoading: (loading: boolean) => void,
  setComplete: (complete: boolean) => void,
  setError: (error: string) => void,
  ...args: T extends (...args: infer P) => void ? P : never
) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      setLoading(true);
      setError('');
      setComplete(false);
      await thunk(...args)(dispatch);
      setComplete(true);
    } catch (e) {
      setError(e.message);
      console.error(e);

      //エラーを通知
      warn(e);
    } finally {
      setLoading(false);
    }
  };
};

export const useThunk = <T extends (...args: any[]) => any>(
  thunk: T
): [
  (...args: T extends (...args: infer P) => void ? P : never) => Promise<void>,
  boolean,
  boolean,
  string | undefined
] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState<string>();
  const thunkDispatch = async (
    ...args: T extends (...args: infer P) => void ? P : never
  ) => {
    await createThunk(
      thunk,
      setLoading,
      setComplete,
      setError,
      ...args
    )(dispatch);
  };
  return [thunkDispatch, loading, complete, error];
};
