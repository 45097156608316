import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }, [error]);

  return (
    <Container>
      <Row>
        <Col className="text-center p-5">
          <h4>ページが表示できません</h4>
          <p>
            ご迷惑をおかけしており大変申し訳ございません。
            <br />
            正常にご覧いただけるよう、解決に取り組んでおります。
            <br />
            お手数をお掛けしますが、しばらく経ってからご利用ください。
          </p>
          <Link to="/home">
            <Button outline>ホームに戻る</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorFallback;
