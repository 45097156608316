import { createReducer } from '@reduxjs/toolkit';
import {
  receiveBuyers,
  receiveNextBuyers,
  receiveBuyer,
  clearBuyers,
} from '../actions/admin';
import { Buyer } from '~domain/buyer/Buyer';

const initialState: {
  list: Buyer[];
  nextToken: string | null;
} = {
  list: [],
  nextToken: null,
};

const adminBuyersReducer = createReducer(initialState, {
  [receiveBuyers.type]: (_, { payload }) => payload,
  [receiveNextBuyers.type]: ({ list }, { payload }) => ({
    list: [...list, ...payload.list],
    nextToken: payload.nextToken,
  }),
  [receiveBuyer.type]: (state, { payload }) => ({
    ...state,
    list: state.list.map((buyer) => {
      if (buyer.buyer_id === payload.buyer_id) {
        return payload;
      }
      return buyer;
    }),
  }),
  [clearBuyers.type]: () => initialState,
});

export default adminBuyersReducer;
