export const CONSUMPTION_TAX_RATE = 0.1; // 消費税
export const MAXIMUM_WHOLESALE_RATE = 60; //標準掛け率上限
export const RETURNABLE_PRICE_LIMIT = 100000; // 返品不可商品金額（上代）
export const DEFAULT_RETURNABLE_AMOUNT_PER_BRAND = 60000; // 返品可能金額上限
export const RETURN_LIMIT_CHANGE_DATE = new Date('2023-11-02');

export const REFERRAL_POINTS = 5000; //招待ポイント
export const BUYER_INVITE_POINTS = 5000; //バイヤー招待ポイント

export const BASE_DIRECT_ORDER_FEE = 0;

export const REVIEW_POINTS = 500; //レビューポイント

export const INVOICE_REGISTER_NO = 'T5010001204759'; //インボイス登録番号
