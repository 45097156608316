import { createReducer } from '@reduxjs/toolkit';
import { clearCollection, setCollection } from '../actions';
import { Collection } from '~domain/product/Collection';

const initialState: Collection | null = null;

const collectionReducer = createReducer<Collection | null>(initialState, {
  [setCollection.type]: (_, { payload }) => payload,
  [clearCollection.type]: () => initialState,
});

export default collectionReducer;
