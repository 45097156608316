import { API, graphqlOperation } from 'aws-amplify';
import { Dispatch } from 'redux';
import { Menu, setMenuList } from '../actions';
import { listMenus } from '../../../graphql/queries';
import { executeQuery } from '~utils/graphql';

export const getMenuList = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    const res = await executeQuery(listMenus, undefined, true);
    const {
      data: {
        listMenus: { items },
      },
    } = res;
    dispatch(
      setMenuList(
        (items as Menu[]).sort((a, b) =>
          a.display_order <= b.display_order ? -1 : 1
        )
      )
    );
  };
};
