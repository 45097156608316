/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext } from 'react';

export type Breadcrumb = {
  text: string;
  url?: string;
};

export type HeadContent = {
  title?: string;
  description?: string;
  canonical?: string;
  breadcrumbs?: Breadcrumb[];
  image?: string;
  url?: string;
};

type HeadContentContextType = {
  headContent: HeadContent;
};

export const HeadContentContext = React.createContext<HeadContentContextType>({
  headContent: {},
});

type HeadContextType = {
  setHeadContent: (headContent: HeadContent) => void;
  setTitle: (title?: string) => void;
  setDescription: (description?: string) => void;
  setCanonical: (canonical?: string) => void;
  setBreadcrumbs: (breadcrumbs?: Breadcrumb[]) => void;
};

export const HeadContext = React.createContext<HeadContextType>({
  setHeadContent: (headContent: HeadContent) => {},
  setTitle: (title?: string) => {},
  setDescription: (description?: string) => {},
  setCanonical: (canonical?: string) => {},
  setBreadcrumbs: (breadcrumbs?: Breadcrumb[]) => {},
});

export const HeadProvider: React.FC = ({ children }) => {
  const [headContent, setHeadContent] = useState<HeadContent>({});

  const setTitle = (title?: string) => {
    if (!title) {
      return;
    }
    setHeadContent({
      ...headContent,
      title: title,
    });
  };
  const setDescription = (description?: string) => {
    if (!description) {
      return;
    }
    setHeadContent({
      ...headContent,
      description: description,
    });
  };
  const setCanonical = (canonical?: string) => {
    if (!canonical) {
      return;
    }
    setHeadContent({
      ...headContent,
      canonical: canonical,
    });
  };

  const setBreadcrumbs = (breadcrumbs?: Breadcrumb[]) => {
    if (!breadcrumbs || breadcrumbs.length === 0) {
      return;
    }
    setHeadContent({
      ...headContent,
      breadcrumbs: breadcrumbs,
    });
  };

  return (
    <HeadContentContext.Provider
      value={{
        headContent,
      }}
    >
      <HeadContext.Provider
        value={{
          setHeadContent,
          setTitle,
          setDescription,
          setCanonical,
          setBreadcrumbs,
        }}
      >
        {children}
      </HeadContext.Provider>
    </HeadContentContext.Provider>
  );
};

export const useHeadContent = () => useContext(HeadContentContext);
export const useHeadContext = () => useContext(HeadContext);
