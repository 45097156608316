// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-CategoryNav-___styles-modules__category-nav{justify-content:center !important;padding:0;margin:0}.src-view-widgets-CategoryNav-___styles-modules__category-nav li>a,.src-view-widgets-CategoryNav-___styles-modules__category-nav button{position:relative;display:inline-block;transition:.3s}.src-view-widgets-CategoryNav-___styles-modules__category-nav li>a::after,.src-view-widgets-CategoryNav-___styles-modules__category-nav button::after{position:absolute;bottom:7px;left:50%;content:\"\";width:0;height:1px;background-color:#000;transition:.3s ease;transform:translateX(-50%)}.src-view-widgets-CategoryNav-___styles-modules__category-nav li>a:hover::after,.src-view-widgets-CategoryNav-___styles-modules__category-nav button:hover::after{width:100%}.src-view-widgets-CategoryNav-___styles-modules__category-nav button{color:inherit !important}.src-view-widgets-CategoryNav-___styles-modules__category-nav div a{color:#969696 !important}.src-view-widgets-CategoryNav-___styles-modules__category-nav div a:hover{color:inherit !important}", ""]);
// Exports
exports.locals = {
	"category-nav": "src-view-widgets-CategoryNav-___styles-modules__category-nav"
};
module.exports = exports;
