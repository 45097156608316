import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import { Review } from '~types/api';

export type ReviewState = {
  byId: Record<string, Review>;
  isLoading: boolean;
};

const reviewSlice = createSlice({
  name: 'review',
  initialState: { byId: {}, isLoading: false } as ReviewState,
  reducers: {
    fetchedReviews: (state) => {
      state.isLoading = true;
    },
    recievedReviwes: (state, { payload }: PayloadAction<Review[]>) => {
      payload.forEach((review) => {
        state.byId[review.id!] = review;
      });
      state.isLoading = false;
    },
    reviewedOrder: (state, { payload }: PayloadAction<Review>) => {
      state.byId[payload.id!] = payload;
      state.isLoading = false;
    },
    clearedReviews: () => ({ byId: {}, isLoading: false }),
  },
});

export const { reducer } = reviewSlice;

export const {
  fetchedReviews,
  recievedReviwes,
  reviewedOrder,
  clearedReviews,
} = reviewSlice.actions;

export const selectReviewState = (state: RootState) => state.review;
export const selectReviews = createSelector(selectReviewState, (state) =>
  Object.values(state.byId)
);
export const makeSelectReviewByOrder = (orderId: string) =>
  createSelector(selectReviews, (reviews) =>
    reviews.find((review) => review.order_id === orderId)
  );
export const makeSelectReviewsByBrand = (brandId: string) =>
  createSelector(selectReviews, (reviews) =>
    reviews.filter((review) => review.brand_id === brandId)
  );
export const makeSelectIsReviewedByBrand = (brandId: string) =>
  createSelector(
    makeSelectReviewsByBrand(brandId),
    (reviews) => !!reviews.length
  );
