import { createReducer } from '@reduxjs/toolkit';
import {
  addSearchBrands,
  receiveSearchBrands,
  clearSearchBrands,
} from '../actions';
import { Brand } from '../types';

export type SearchBrandsState = {
  items: Brand[];
  nextToken: string | null;
};

const initialState: SearchBrandsState = {
  items: [],
  nextToken: null,
};

const searchReducer = createReducer(initialState, {
  [receiveSearchBrands.type]: (state, action) => {
    return action.payload;
  },
  [addSearchBrands.type]: (state, action) => {
    return {
      items: [...state.items, ...action.payload.items],
      nextToken: action.payload.nextToken,
    };
  },
  [clearSearchBrands.type]: (state, action) => {
    return initialState;
  },
});

export default searchReducer;
