import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact } from '~types/api';

const initialState: Record<string, Contact> = {};

const contactsSlice = createSlice({
  initialState,
  name: 'buyer/contacts',
  reducers: {
    recievedContacts: (_, { payload: contacts }: PayloadAction<Contact[]>) => {
      return contacts.reduce(
        (prev, contact) => ({ ...prev, [contact.id!]: contact }),
        {}
      );
    },
    removedContacts: () => initialState,
  },
});

export const { recievedContacts, removedContacts } = contactsSlice.actions;
export default contactsSlice.reducer;
