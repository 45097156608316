import './styles.scss';
import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import HubspotForm from 'react-hubspot-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Container, Modal, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
//@ts-ignore
import { createBuyerAccount } from '../../../../graphql/mutations';
import { signUpPathSelector } from '~redux/auth/selectors/signup';
import { signIn } from '~redux/auth/thunk';
import { Brand } from '~redux/brand/types';
import { useThunk } from '~utils/hooks';
import { getPublicImageUrl } from '~utils/image';
import { useGraphql } from '~view/hooks/graphql';
import FieldWithValidity from '~view/widgets/FieldWithValidity';

type DirectSignupModalProps = {
  isOpen: boolean;
  toggle: () => void;
  brand: Brand;
  contactId?: string;
};

const DirectSignupModal: React.FC<DirectSignupModalProps> = ({
  isOpen,
  toggle,
  brand,
  contactId,
}) => {
  const history = useHistory();
  const [isExist, setIsExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const nextPath = useSelector(signUpPathSelector);
  const [token, getToken] = useGraphql(
    createBuyerAccount,
    (data) => data.createBuyerAccount.token,
    true
  );
  const [, createAccount] = useGraphql(
    createBuyerAccount,
    (data) => data.createBuyerAccount,
    true
  );
  const [signin] = useThunk(signIn);

  const isUserExists = useCallback(async (email: string) => {
    try {
      await Auth.confirmSignUp(email, 'x', {
        forceAliasCreation: false,
      });
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        return false;
      }
      return true;
    }
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      if (
        event.data.type !== 'hsFormCallback' ||
        event.data.eventName !== 'onFormSubmit' ||
        event.data.id !== process.env.REACT_APP_HUBSPOT_INVITATION_FORM_ID
      ) {
        return;
      }

      try {
        setIsLoading(true);
        const email =
          event.data.data.email ??
          event.data.data?.find((data: any) => data.name === 'email')?.value;
        setEmail(email);
        if (await isUserExists(email)) {
          setIsExist(true);
          return;
        }
        if (!contactId) {
          history.push(`/sign-up?email=${email}&referral_id=${brand.id}`);
          toggle();
          return;
        }
        await getToken({ input: { email, contactId } });
      } finally {
        setIsLoading(false);
      }
    },
    [contactId, brand]
  );

  const handleLogin = useCallback(
    async (values) => {
      try {
        setIsLoading(true);
        if (!isExist) {
          await createAccount({
            input: {
              email,
              password: values.password,
              contactId,
              referral_id: brand.id,
              token,
            },
          });
        }
        signin(email!, values.password, nextPath);
        toggle();
      } catch (e) {
        alert('ログイン情報が不正です');
      } finally {
        setIsLoading(false);
      }
    },
    [email, isExist, token, nextPath, contactId, brand]
  );

  useEffect(() => {
    window.addEventListener('message', handleSubmit);
    return () => {
      window.removeEventListener('message', handleSubmit);
    };
  }, [handleSubmit]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="direct-signup-modal"
    >
      <div className="direct-signup-modal-header mb-4">
        <div className="mr-3">
          <img
            className="direct-signup-brand-icon"
            src={getPublicImageUrl(brand.brand_icon_imageKey!, 'small')}
            alt="brand icon"
          />
        </div>
        <div className="mr-2">×</div>
        <div className="pt-2">
          <img className="direct-signup-logo" src={logo} alt="homula" />
        </div>
      </div>
      <h5 className="mb-4">
        <b>
          <span translate="no">{brand.brand_name}</span>の卸売ページへログイン
        </b>
      </h5>
      {isLoading && (
        <Container className="text-center">
          <Spinner />
        </Container>
      )}
      {!(isExist || token) && (
        <>
          {!brand.sell_only_direct && (
            <div className="mb-4">
              ログインすると
              <b>60日後の後払い</b>などの特典を利用して
              <br />
              <span translate="no">{brand.brand_name}</span>
              の商品をご発注いただけます！
            </div>
          )}
          <HubspotForm
            portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
            formId={process.env.REACT_APP_HUBSPOT_INVITATION_FORM_ID}
            loading={
              <Container className="text-center">
                <Spinner />
              </Container>
            }
          />
        </>
      )}
      {!isLoading && (isExist || token) && (
        <Formik
          onSubmit={handleLogin}
          initialValues={{ email, password: null }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('メールアドレスを入力してください')
              .email('メールアドレスが不正です'),
            password: Yup.string()
              .required('パスワードは必須です。')
              .matches(/^[^\s]+$/, 'スペースはパスワードに含められません。')
              .matches(
                // eslint-disable-next-line no-control-regex
                /^[\u0000-\u007F]*$/,
                'ASCII制御文字以外はパスワードに含められません。'
              )
              .matches(
                /^[\p{ASCII}]*$/u,
                'Non-ASCII charactersはパスワードに含められません。'
              )
              .matches(
                /^[\p{L}\p{N}\p{P}\p{S}]*$/u,
                '利用できない文字が含まれています。'
              )
              .min(8, 'パスワードは8文字以上で入力してください。')
              .max(20, 'パスワードは20文字以下で入力してください。'),
          })}
        >
          {() => (
            <Form>
              <div className="text-left">
                <div className="mb-4">
                  <label>メールアドレス</label>
                  <FieldWithValidity type="email" name="email" readonly />
                </div>
                <div className="mb-4">
                  {!isExist && (
                    <label>パスワードを設定してください（8文字以上）</label>
                  )}
                  {isExist && <label>パスワード</label>}
                  <FieldWithValidity
                    type="password"
                    name="password"
                    minLength={8}
                  />
                </div>
              </div>
              <Button block style={{ background: '#333' }}>
                ログイン
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default DirectSignupModal;
