import './styles.modules.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Col, Row } from 'reactstrap';
import AuthPage from '../AuthPage';
import Sidebar from '../Sidebar';
import { authUserSelector } from '~redux/auth/selectors/authUser';

type SupplierPageProps = {
  title?: string;
};

const SupplierPage: React.FC<SupplierPageProps> = ({ title, children }) => {
  const authUser = useSelector(authUserSelector);

  if (
    authUser &&
    !['admin', 'supplier'].includes(authUser.attributes.profile)
  ) {
    return <Redirect to="/home" />;
  }

  return (
    <AuthPage title={title} fullscreen>
      <Row
        className="flex-grow-1 position-relative m-0"
        styleName="supplier-page"
      >
        <Col
          xs={2}
          className="d-none d-xl-block h-100 p-4 border-right"
          styleName="sidebar-area"
        >
          <Sidebar />
        </Col>
        <Col
          id="supplier-content"
          className="p-5 h-100"
          styleName="content-area"
        >
          {children}
        </Col>
      </Row>
    </AuthPage>
  );
};

export default SupplierPage;
