import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderReadModel, OrderReadModelStatus, Review } from '~types/api';
import { ORDER_STATUS_LABEL } from '~view/constants/labels';

type OrderByIdState = {
  byId: Record<string, OrderReadModel>;
  isLoading: boolean;
  nextToken?: string;
};

const initialState: OrderByIdState = {
  byId: {},
  isLoading: false,
};

const orderByIdSlice = createSlice({
  name: 'order/byId',
  initialState,
  reducers: {
    fetchedOrders: (state) => {
      state.isLoading = true;
    },
    recievedOrders: (
      state,
      {
        payload: { items, nextToken },
      }: PayloadAction<{ items: OrderReadModel[]; nextToken?: string }>
    ) => {
      items.forEach((item) => (state.byId[item.id!] = item));
      state.nextToken = nextToken;
      state.isLoading = false;
    },
    clearedOrders: () => initialState,
    extendedOrder: (
      state,
      {
        payload: { id, details },
      }: PayloadAction<{ id: string; details: Record<string, number> }>
    ) => {
      const date = new Date();
      state.byId[id].details!.items = state.byId[id].details!.items!.map(
        (item) => {
          const quantity = details[item?.id!];
          if (quantity == null) {
            return item;
          }
          item!.extended_quantity = quantity;
          item!.extended_amount = item!.price! * quantity;
          item!.extended_date = date.toISOString();
          return item;
        }
      );
      state.byId[id].extended_quantity = Object.values(details).reduce(
        (prev, quantity) => prev + quantity,
        0
      );
      state.byId[id].extended_amount = state.byId[id].details?.items?.reduce(
        (prev, item) => prev + (item?.extended_amount ?? 0),
        0
      );
      state.byId[id].extended_date = date.toISOString();
    },
    reportedOrder: (
      state,
      {
        payload: { id, details },
      }: PayloadAction<{ id: string; details: Record<string, number> }>
    ) => {
      state.byId[id].details!.items = state.byId[id].details!.items!.map(
        (item) => {
          const quantity = details[item?.id!];
          if (quantity == null) {
            return item;
          }
          item!.report_quantity = quantity;
          item!.report_amount = item!.price! * quantity;
          return item;
        }
      );
      state.byId[id].report_quantity = Object.values(details).reduce(
        (prev, quantity) => prev + quantity,
        0
      );
      state.byId[id].report_amount = state.byId[id].details?.items?.reduce(
        (prev, item) => prev + (item?.report_amount ?? 0),
        0
      );
    },
    reviewedOrder: (
      state,
      { payload: { id, review } }: PayloadAction<{ id: string; review: Review }>
    ) => {
      state.byId[id].reviews!.items = [review];
    },
    canceledOrder: (state, { payload: orderId }: PayloadAction<string>) => {
      const order = state.byId[orderId];
      order.status = OrderReadModelStatus.CANCELED;
      order.status_label = ORDER_STATUS_LABEL[order.status];
      order.order_quantity = 0;
      order.order_amount = 0;
      order.shipping_fee = 0;
      order.tax = 0;
      order.payment_quantity = 0;
      order.payment_amount = 0;
    },
    updatedPaymentMethod: (
      state,
      {
        payload: { id, paymentMethodId },
      }: PayloadAction<{ id: string; paymentMethodId: string }>
    ) => {
      state.byId[id].stripe_payment_method_id = paymentMethodId;
      state.byId[id].payment_status_label = '変更済み';
    },
  },
});

export default orderByIdSlice.reducer;

export const {
  fetchedOrders,
  recievedOrders,
  clearedOrders,
  extendedOrder,
  reportedOrder,
  reviewedOrder,
  canceledOrder,
  updatedPaymentMethod,
} = orderByIdSlice.actions;
