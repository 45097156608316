import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { redeemCoupon } from '../coupon';
import { usePoints } from '../point';
import { searchOrders } from './graphql';
import { createUseCase } from '~core/usecase/createUseCase';
import { searchProductswithimagekey } from '~graphql/custom_queries';
import { deleteShopCart, updateShopCart } from '~graphql/mutations';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { buyerInfoSelector } from '~redux/buyer/selectors';
import {
  movedToCart,
  removedCartItems,
  savedForLater,
} from '~redux/cart/actions';
import { recievedOrderedBrands } from '~redux/cart/reducers/orderedBrands';
import { recievedRelatedProducts } from '~redux/cart/reducers/relatedProducts';
import {
  makeSelectCartItemsByBrand,
  makeSelectCartItemsByBrands,
  makeSelectCartModelByBrands,
  makeSelectCartProductIdsByBrands,
  makeSelectCartReferralPointsByBrands,
} from '~redux/cart/selectors';
import { makeSelectEnableCoupon } from '~redux/coupon/selectors';
import { sendMessage } from '~redux/messages/thunk';
import { createdOrders } from '~redux/order/actions';
import { selectInputPoints } from '~redux/points/selectors';
import { RootState } from '~redux/reducer';
import {
  Product,
  SearchableProductSortableFields,
  SearchableSortDirection,
  SearchOrdersQuery,
  SearchOrdersQueryVariables,
  SearchProductsQuery,
  SearchProductsQueryVariables,
  ShopCart,
  UpdateShopCartMutation,
  UpdateShopCartMutationVariables,
} from '~types/api';
import { executeQuery } from '~utils/graphql';
import { pushPurchaseEvent, pushRemoveFromCartEvent } from '~utils/gtm';
import { calculateTax } from '~utils/price';
import { calculateProductPrice, getProductTypeString } from '~utils/product';
import { productRemoved } from '~utils/segment';

export const fetchOrderedBrands = createUseCase(
  'cart/fetchOrderedBrands',
  async (accountId: string, { dispatch }) => {
    const { data } = await executeQuery<
      SearchOrdersQuery,
      SearchOrdersQueryVariables
    >(searchOrders, {
      filter: {
        order_owner: { eq: accountId },
        createdAt: { lt: moment().add(-1, 'day').endOf('day').toISOString() },
      },
      limit: 1000,
    });
    const orderedBrands = [
      ...new Set(
        data?.searchOrders?.items?.map((item) => item?.brand_id!) ?? []
      ),
    ];
    dispatch(recievedOrderedBrands({ orderedBrands }));
  }
);

export const fetchRelatedProducts = createUseCase(
  'cart/fetchRelatedProducts',
  async (brandIds: string[], { dispatch, getState }) => {
    const state = getState();
    const excludeProductIds = makeSelectCartProductIdsByBrands(brandIds)(state);
    const { data } = await executeQuery<
      SearchProductsQuery,
      SearchProductsQueryVariables
    >(searchProductswithimagekey, {
      filter: {
        or: brandIds.map((brandId) => ({
          product_brand_id: { eq: brandId },
        })),
        and: excludeProductIds.map((productId) => ({ id: { ne: productId } })),
        product_public_status: { eq: '公開' },
        suspended: { ne: false },
      },
      limit: 1000,
      sort: {
        field: SearchableProductSortableFields.product_display_order,
        direction: SearchableSortDirection.asc,
      },
    });
    dispatch(
      recievedRelatedProducts({
        relatedProducts: (data?.searchProducts?.items ?? []) as Product[],
      })
    );
  }
);

export const saveForLater = createUseCase(
  'cart/saveForLater',
  async (brandId: string, { dispatch, getState }) => {
    const state = getState();
    const items = makeSelectCartItemsByBrand(brandId)(state);
    const result = await Promise.all(
      items.map(async (item) => {
        const { data } = await executeQuery<
          UpdateShopCartMutation,
          UpdateShopCartMutationVariables
        >(updateShopCart, { input: { id: item.id, saved_for_later: true } });
        return data?.updateShopCart as ShopCart;
      })
    );
    dispatch(savedForLater({ brandId }));
    return result;
  }
);

export const moveToCart = createUseCase(
  'cart/moveToCart',
  async (brandId: string, { dispatch, getState }) => {
    const state = getState();
    const items = makeSelectCartItemsByBrand(brandId)(state);
    const result = await Promise.all(
      items.map(async (item) => {
        const { data } = await executeQuery<
          UpdateShopCartMutation,
          UpdateShopCartMutationVariables
        >(updateShopCart, { input: { id: item.id, saved_for_later: false } });
        return data?.updateShopCart as ShopCart;
      })
    );
    dispatch(movedToCart({ brandId }));
    return result;
  }
);

export const removeCartByBrands = createUseCase(
  'cart/removeCartByBrands',
  async (
    { brandIds, isCheckout }: { brandIds: string[]; isCheckout?: boolean },
    { dispatch, getState }
  ) => {
    const state = getState();
    const user = authUserSelector(state)!;
    const items = makeSelectCartItemsByBrands(brandIds)(state);

    await Promise.all(
      items.map(async (item) => {
        const res = await executeQuery(deleteShopCart, {
          input: { id: item.id },
        });
        if (isCheckout) {
          return res;
        }
        //tracking
        productRemoved(`cart/${item.owner}`, {
          brand: item.product.brand.brand_name,
          brand_id: item.product.product_brand_id!,
          category: item.product.product_category!,
          category2: item.product.product_subcategory!,
          image_url: item.product.imageUrl,
          name: item.product.product_name!,
          price: calculateProductPrice(item.product, 1),
          product_id: item.product_id,
          quantity: item.quantity,
          sku: item.product.product_number!,
          url: `${document.location.protocol}//${document.location.host}/productdetail/${item.product_id}`,
          variant: item.productType
            ? getProductTypeString(item.productType)
            : undefined,
        });
        pushRemoveFromCartEvent(
          [
            {
              item_name: item.product.product_name!,
              item_id: item.product.id!,
              price: calculateProductPrice(item.product, 1),
              item_brand: item.product.brand.brand_name,
              item_category: item.product.product_category!,
              item_category2: item.product.product_subcategory!,
              item_variant: item.productType
                ? getProductTypeString(item.productType)
                : undefined,
              item_list_name: document.title,
              item_list_id: document.location.pathname,
              quantity: item.quantity,
            },
          ],
          user
        );
        return res;
      })
    );

    dispatch(removedCartItems({ brandIds }));
  }
);

export const checkout = createUseCase(
  'cart/checkout',
  async (
    {
      brandIds,
      campaignCode,
    }: {
      brandIds: string[];
      campaignCode?: string;
    },
    { dispatch, getState }
  ) => {
    const state: RootState = getState();
    const user = authUserSelector(state)!;
    const buyerInfo = buyerInfoSelector(state)!;
    const cart = makeSelectCartModelByBrands(brandIds)(state);
    const coupon = makeSelectEnableCoupon(brandIds)(state);
    const referralPoints = makeSelectCartReferralPointsByBrands(
      brandIds,
      coupon?.id
    )(state);
    const points = selectInputPoints(state);
    // const collections = selectCartCollections(state);

    //ポイント引き当て
    const attachedPointList = await cart.attachPoints(referralPoints, points);
    const pointOrders: Record<
      string,
      { orderId: string; usedPoints: number }[]
    > = {};

    //注文を作成
    const orders = await Promise.all(
      cart.validSegments.map(async (segment, i) => {
        const attachedPoints = segment.hasPayDirectlyOrderItem
          ? []
          : attachedPointList[i] ?? [];
        const order = await segment.checkout(
          dispatch,
          buyerInfo,
          attachedPoints,
          campaignCode,
          !coupon?.brand_ids?.length ||
            coupon.brand_ids.includes(segment.brand.id)
            ? coupon
            : undefined
        );
        attachedPoints.forEach((attached) => {
          pointOrders[attached.point.id!] = [
            ...(pointOrders[attached.point.id!] ?? []),
            { orderId: order.id, usedPoints: attached.points },
          ];
        });
        return order;
      })
    );
    dispatch(createdOrders(orders.map((order) => order.toJson())));

    //ポイント利用登録
    unwrapResult(await dispatch(usePoints(pointOrders)));

    //クーポン利用登録
    if (coupon) {
      unwrapResult(
        await dispatch(
          redeemCoupon({
            couponId: coupon.id!,
            orderIds: orders.map((order) => order.id),
          })
        )
      );
    }

    //カートをクリア
    unwrapResult(await dispatch(removeCartByBrands({ brandIds })));

    //自動応答メッセージを送信
    orders
      .filter(
        (a, i) => !orders.slice(0, i).some((b) => a.brand_id === b.brand_id)
      )
      .map((order) => {
        if (!order.brand!.auto_message_enabled || !order.brand!.auto_message)
          return;
        dispatch(
          sendMessage(order.brand!.auto_message, '', order.brand!.brand_owner, {
            buyer_owner: user.attributes.sub,
            brand_id: order.brand_id,
            brand_owner: order.brand!.brand_owner,
            owners: [user.attributes.sub, order.brand!.brand_owner],
          })
        );
      });

    //再販したコレクションを非公開にする
    // if (collections.length) {
    //   await Promise.all(
    //     collections.map(
    //       async (collectionId) =>
    //         await executeQuery(updateCollection, {
    //           input: { id: collectionId, published: false },
    //         })
    //     )
    //   );
    // }

    //購入イベントを送信
    orders.forEach((order) =>
      pushPurchaseEvent(
        order.id,
        order.totalPrice,
        order.shipping_fee,
        calculateTax(order.subtotalPrice + order.shipping_fee, order.taxRate),
        order.orderproducts.map(({ product, quantity, productType }, i) => ({
          item_name: product.product_name,
          item_id: product.id,
          price: calculateProductPrice(product, 1),
          item_brand: product.brand.brand_name,
          item_category: product.product_category,
          item_category2: product.product_subcategory,
          item_variant: productType ? getProductTypeString(productType) : '',
          index: i,
          quantity,
        })),
        user
      )
    );

    return orders;
  }
);
