import { createReducer } from '@reduxjs/toolkit';
import {
  receiveOrders,
  receiveNextOrders,
  receiveUpdatedOrder,
} from '../actions/admin';
import { Order } from '~domain/order/Order';

const initialState: {
  list: Order[];
  nextToken: string | null;
} = {
  list: [],
  nextToken: null,
};

const adminOrdersReducer = createReducer(initialState, {
  [receiveOrders.type]: (_, { payload }) => payload,
  [receiveNextOrders.type]: ({ list }, { payload }) => ({
    list: [...list, ...payload.list],
    nextToken: payload.nextToken,
  }),
  [receiveUpdatedOrder.type]: ({ list, ...rest }, { payload }) => ({
    list: list.map((order) => {
      if (order.id !== payload.id) {
        return order;
      }

      return payload;
    }),
    ...rest,
  }),
});

export default adminOrdersReducer;
