import { createAction } from '@reduxjs/toolkit';
import { Brand } from '~redux/brand/types';

export type BrandCollection = {
  id: string;
  collection_name: string;
  visible: boolean;
  brands?: Brand[];
};

const ACTION_BASE_TYPE = 'BRAND_COLLECTION';

export const setBrandCollections = createAction<BrandCollection[]>(
  `${ACTION_BASE_TYPE}/SET_COLLECTIONS`
);

export const setBrandCollection = createAction<{
  id: string;
  brandCollection: BrandCollection;
}>(`${ACTION_BASE_TYPE}/SET_COLLECTION`);

export const removeBrandCollection = createAction<{ id: string }>(
  `${ACTION_BASE_TYPE}/REMOVE_COLLECTION`
);

export const clearBrandCollections = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_COLLECTIONS`
);
