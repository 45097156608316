import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import {
  OrderDetailReadModel,
  OrderPaymentStatus,
  OrderReadModel,
  PaymentTerm,
} from '~types/api';
import { formatDate } from '~utils/formatter';

//types
type PaymentStatus = 'paid' | 'unpaid';

type PaymentsState = {
  totalAmountInMonth: number;
  totalAmount: number;
  returnRequestAmount: number;
  byId: Record<
    string,
    OrderReadModel & {
      paymentId: string;
      paymentStatus: PaymentStatus;
      isExtended: boolean;
    }
  >;
  isLoading: boolean;
  nextToken?: string;
};

//initial state
const initialState: PaymentsState = {
  totalAmountInMonth: 0,
  totalAmount: 0,
  returnRequestAmount: 0,
  byId: {},
  isLoading: false,
};

//slice
const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    fetchedPayments: (state) => {
      state.isLoading = true;
    },
    recievedPaymentTotal: (
      state,
      {
        payload: { total, totalInMonth, returnRequestAmount },
      }: PayloadAction<{
        total: number;
        totalInMonth: number;
        returnRequestAmount: number;
      }>
    ) => {
      return {
        ...state,
        totalAmount: total,
        totalAmountInMonth: totalInMonth,
        returnRequestAmount,
      };
    },
    recievedOrders: (
      state,
      {
        payload: { items, nextToken, startDate, endDate },
      }: PayloadAction<{
        items: OrderReadModel[];
        nextToken?: string;
        startDate?: Date;
        endDate?: Date;
      }>
    ) => {
      items.forEach((item) => {
        if (
          new Date(item.payment_date!).getTime() >=
            new Date(startDate ?? item.payment_date!).getTime() &&
          new Date(item.payment_date!).getTime() <=
            new Date(endDate ?? item.payment_date!).getTime() &&
          item.payment_amount
        ) {
          const details = item.details?.items?.filter(
            (item) => item?.payment_amount
          );
          const subtotal =
            details?.reduce(
              (prev, detail) =>
                prev + detail?.payment_quantity! * detail?.price!,
              0
            ) ?? 0;
          const tax = Math.round(
            (subtotal + item.shipping_fee!) * (item.tax_rate! / 100)
          );
          state.byId[
            `${item.id}_${formatDate(item.payment_date, 'yyyy-MM-dd')}`
          ] = {
            ...item,
            paymentId: `${item.id}_${formatDate(
              item.payment_date,
              'yyyy-MM-dd'
            )}`,
            paymentStatus:
              ([
                PaymentTerm.CARD_DEFFERED_PAYMENT,
                PaymentTerm.CARD_SHIPPING_PAYMENT,
              ].includes(item.payment_term!) &&
                [
                  OrderPaymentStatus.CHARGED,
                  OrderPaymentStatus.PARTIAL_CHARGED,
                ].includes(item.payment_status!)) ||
              new Date(item.payment_date!).getTime() < new Date().getTime()
                ? 'paid'
                : 'unpaid',
            order_subtotal: subtotal,
            tax,
            details: {
              ...item.details!,
              items: details,
            },
            isExtended: false,
          };
        }
        if (
          item.extended_payment_date &&
          new Date(item.extended_payment_date).getTime() >=
            new Date(startDate ?? item.extended_payment_date).getTime() &&
          new Date(item.extended_payment_date).getTime() <=
            new Date(endDate ?? item.extended_payment_date).getTime() &&
          item.extended_amount
        ) {
          const details = item.details?.items
            ?.filter((item) => item?.extended_amount)
            ?.map((item) => ({
              ...item,
              payment_quantity: item?.extended_quantity,
              payment_amount: item?.extended_amount,
            })) as OrderDetailReadModel[];
          const subtotal =
            details?.reduce(
              (prev, detail) =>
                prev + detail?.payment_quantity! * detail?.price!,
              0
            ) ?? 0;
          const tax = Math.round(subtotal * (item.tax_rate! / 100));
          state.byId[
            `${item.id}_${formatDate(item.extended_payment_date, 'yyyy-MM-dd')}`
          ] = {
            ...item,
            paymentId: `${item.id}_${formatDate(
              item.extended_payment_date,
              'yyyy-MM-dd'
            )}`,
            paymentStatus: [OrderPaymentStatus.CHARGED].includes(
              item.payment_status!
            )
              ? 'paid'
              : 'unpaid',
            payment_amount: item.extended_amount,
            payment_date: item.extended_payment_date,
            payment_quantity: item.extended_quantity,
            order_subtotal: subtotal,
            tax,
            details: {
              ...item.details!,
              items: details,
            },
            isExtended: true,
          };
        }
      });
      state.nextToken = nextToken;
      state.isLoading = false;
    },
    clearedPayments: (state) => {
      state.byId = {};
    },
    clearedPaymentsState: () => initialState,
  },
});

//reducer
const payments = paymentsSlice.reducer;
export default payments;

//actions
export const {
  fetchedPayments,
  recievedPaymentTotal,
  recievedOrders,
  clearedPayments,
  clearedPaymentsState,
} = paymentsSlice.actions;

//selectors
export const selectPaymentsState = (state: RootState) => state.payments;
export const selectTotalUnpaidAmount = createSelector(
  selectPaymentsState,
  (state) => state.totalAmount
);
export const selectTotalPaymentAmountInMonth = createSelector(
  selectPaymentsState,
  (state) => state.totalAmountInMonth
);
export const selectReturnRequestAmount = createSelector(
  selectPaymentsState,
  (state) => state.returnRequestAmount
);
export const selectPayments = createSelector(selectPaymentsState, (state) =>
  Object.values(state.byId)
);
export const selectIsPaymentsLoading = createSelector(
  selectPaymentsState,
  (state) => state.isLoading
);
export const selectNextPaymentsToken = createSelector(
  selectPaymentsState,
  (state) => state.nextToken
);
export const makeSelectPaymentsByStatus = (status: PaymentStatus) =>
  createSelector(selectPayments, (payments) =>
    payments.filter((payment) => payment.paymentStatus === status)
  );
export const makeSelectPaymentsTotalByStatus = (status: PaymentStatus) =>
  createSelector(
    selectPayments,
    makeSelectPaymentsByStatus(status),
    (payments) => payments.length
  );
export const makeSelectPaymentById = (paymentId: string) =>
  createSelector(selectPaymentsState, (state) => state.byId[paymentId]);
