const CURRENCY_SYMBOL: {
  [language: string]: string;
} = {
  ja: '￥',
};

const LANGUAGE_DEFAULT = 'ja';

export const formatCurrency = (value?: string | number | null) => {
  const num = Number(value);
  const currencySymbol =
    CURRENCY_SYMBOL[navigator.language] ?? CURRENCY_SYMBOL[LANGUAGE_DEFAULT];

  if (value === null || value === '' || isNaN(num)) {
    return currencySymbol;
  }

  return `${currencySymbol}${num.toLocaleString(navigator.language, {
    minimumFractionDigits: 0,
  })}`;
};

export const formatDate = (
  unformatedDate: Date | string | undefined | null,
  format: string,
  padding = false
): string => {
  if (!unformatedDate) {
    return '';
  }
  const date = new Date(unformatedDate);
  format = format.replace(/YY/g, date.getFullYear().toString().substring(2, 4));
  format = format.replace(/yyyy/g, date.getFullYear().toString());
  format = format.replace(
    /MM/g,
    `${padding ? '0' : ''}${date.getMonth() + 1}`.slice(-2)
  );
  format = format.replace(
    /dd/g,
    `${padding ? '0' : ''}${date.getDate()}`.slice(-2)
  );
  format = format.replace(
    /HH/g,
    `${padding ? '0' : ''}${date.getHours()}`.slice(-2)
  );
  format = format.replace(
    /mm/g,
    `${padding ? '0' : ''}${date.getMinutes()}`.slice(-2)
  );
  format = format.replace(
    /ss/g,
    `${padding ? '0' : ''}${date.getSeconds()}`.slice(-2)
  );
  format = format.replace(
    /SSS/g,
    `${padding ? '00' : ''}${date.getMilliseconds()}`.slice(-3)
  );
  return format;
};
