import { createUseCase } from '~core/usecase/createUseCase';
import {
  createCashback,
  createCouponLog,
  updateBuyerCoupon,
} from '~graphql/mutations';
import { searchBuyerCoupons } from '~graphql/queries';
import {
  makeSelectBuyerCouponById,
  makeSelectEnableCoupon,
} from '~redux/coupon/selectors';
import { recievedCoupons, redeemedCoupon } from '~redux/coupon/slices/byId';
import { RootState } from '~redux/reducer';
import {
  BuyerCoupon,
  CreateCashbackMutation,
  CreateCashbackMutationVariables,
  CreateCouponLogMutation,
  CreateCouponLogMutationVariables,
  DiscountType,
  SearchBuyerCouponsQuery,
  SearchBuyerCouponsQueryVariables,
  UpdateBuyerCouponMutation,
  UpdateBuyerCouponMutationVariables,
} from '~types/api';
import { executeQuery } from '~utils/graphql';

export const fetchCoupons = createUseCase(
  'coupon/fetchCoupons',
  async ({ accountId }: { accountId: string }, { dispatch }) => {
    const date = new Date();
    const res = await executeQuery<
      SearchBuyerCouponsQuery,
      SearchBuyerCouponsQueryVariables
    >(searchBuyerCoupons, {
      filter: {
        buyer_id: { eq: accountId },
        redemptions_left: { gt: 0 },
        expiresAt: { gte: date.toISOString() },
      },
    });
    const buyerCoupons = (res.data?.searchBuyerCoupons?.items ??
      []) as BuyerCoupon[];
    dispatch(recievedCoupons(buyerCoupons));
    return buyerCoupons;
  }
);

export const redeemCoupon = createUseCase(
  'coupon/redeemCoupon',
  async (
    input: { couponId: string; orderIds: string[] },
    { dispatch, getState }
  ) => {
    const state: RootState = getState();
    const buyerCoupon = makeSelectBuyerCouponById(input.couponId)(state);
    const coupon = makeSelectEnableCoupon(undefined, input.couponId)(state);
    await executeQuery<
      CreateCouponLogMutation,
      CreateCouponLogMutationVariables
    >(createCouponLog, {
      input: {
        buyer_id: buyerCoupon?.buyer_id,
        coupon_id: input.couponId,
        order_ids: input.orderIds,
      },
    });
    const res = await executeQuery<
      UpdateBuyerCouponMutation,
      UpdateBuyerCouponMutationVariables
    >(updateBuyerCoupon, {
      input: {
        id: buyerCoupon?.id!,
        redemptions_left: buyerCoupon?.redemptions_left! - 1,
        times_redeemed: buyerCoupon?.times_redeemed! + 1,
      },
    });
    if (coupon?.discount_type === DiscountType.CASHBACK) {
      await executeQuery<
        CreateCashbackMutation,
        CreateCashbackMutationVariables
      >(createCashback, {
        input: {
          buyer_id: buyerCoupon?.buyer_id,
          coupon_id: input.couponId,
          order_id: input.orderIds,
          redeemed: false,
        },
      });
    }
    dispatch(
      redeemedCoupon({
        buyerCouponId: buyerCoupon?.id!,
      })
    );
    return res.data?.updateBuyerCoupon;
  }
);
