import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { BuyerInfo, Contact } from '../../../redux/buyer/actions/buyerInfo';
import { CartItemBase } from './CartItemBase';
import { CartSegmentBase } from './CartSegmentBase';
import { Entity } from '~core/domain/Entity';
import { Brand } from '~domain/brand/Brand';
import * as Points from '~domain/point/Points';
import { CartItem as CartItemJson } from '~redux/cart/types';
import { OrderType } from '~redux/product/types';
import { ArrayItem } from '~types/utils';
import { executeQuery } from '~utils/graphql';
import { getPublicImageUrl } from '~utils/image';

export type CartJson = {
  items: Parameters<typeof CartItemBase.create>[0][];
};

export type CartType = {
  buyerInfo: BuyerInfo;
  items: CartItemBase[];
};

export abstract class CartBase extends Entity<CartType> {
  constructor(params: CartType) {
    super(params);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static create(cart: CartJson, buyerInfo: BuyerInfo): CartBase {
    throw new Error('Not implemented');
  }

  abstract create(...params: Parameters<typeof CartBase.create>): CartBase;

  abstract createSegment(
    ...params: Parameters<typeof CartSegmentBase.create>
  ): CartSegmentBase;
  abstract createCartItem(item: CartItemJson): CartItemBase;
  abstract createCartItemById(id: string): Promise<CartItemBase>;
  abstract getBrandMinimumBuy(brandId: string): number;

  static async fetch(buyerInfo: BuyerInfo): Promise<CartBase> {
    const res = await API.graphql<any>(
      graphqlOperation(customQueries.listShopCartsByOwnerWithImageKey, {
        shopcart_owner: buyerInfo.account_id,
        sortDirection: 'ASC',
        limit: 1000,
      })
    );

    const { items } = res.data.listShopCartsByOwner;

    const cartItems = await Promise.all<ArrayItem<CartJson['items']>>(
      (items as any)
        .sort((prev: any, next: any) =>
          prev.product_id < next.product_id ? -1 : 1
        )
        .filter(
          (item: any) =>
            item.product &&
            item.product.brand &&
            item.product.brand.brand_public_status !== '非公開' &&
            !item.product.suspended &&
            item.product.product_public_status !== '非公開' &&
            !item.productType?.suspended &&
            item.productType?.published !== false
        )
        .map(async (item: any) => ({
          id: item.id,
          owner: item.shopcart_owner,
          quantity: item.shopcart_quantity,
          product_id: item.product_id,
          product_type_id: item.product_type_id,
          product: item.product,
          productType: item.producttype,
          image: getPublicImageUrl(
            item.producttype?.imageKey ??
              item.product?.imageKeys?.items[0]?.imageKey
          ),
          brand_id: item.brand_id,
          is_direct: item.is_direct,
          is_pay_directly: item.is_pay_directly,
          is_consign: item.is_consign,
          saved_for_later: item.saved_for_later,
          collection_id: item.collection_id,
        }))
    );

    return this.create(
      {
        items: cartItems,
      },
      buyerInfo
    );
  }

  private isDirectItem(item: Omit<CartItemJson, 'id'>) {
    return (
      item.is_direct ||
      this.buyerInfo.contacts?.items.some(
        (contact) =>
          contact.brand_owner === item.product.product_owner &&
          contact.contact_status === 'direct'
      )
    );
  }

  async addItem(item: Omit<CartItemJson, 'id'>) {
    const targetItem = this.items.find(
      (cartItem) =>
        cartItem.product_id === item.product_id &&
        (!item.product_type_id ||
          cartItem.product_type_id === item.product_type_id)
    );

    let response;
    if (targetItem) {
      const res = await executeQuery(customQueries.updateShopCart, {
        input: {
          id: targetItem.id,
          shopcart_quantity: item.quantity + targetItem.quantity,
          brand_id: item.brand_id,
          is_direct: this.isDirectItem(item) ? true : undefined,
          is_pay_directly: item.is_pay_directly,
          is_consign: item.is_consign,
          collection_id: item.collection_id,
        },
      });
      response = res.data.updateShopCart;
    } else {
      const res = await executeQuery(customQueries.createShopCart, {
        input: {
          shopcart_owner: item.owner,
          shopcart_quantity: item.quantity,
          product_id: item.product_id,
          product_type_id: item.product_type_id,
          brand_id: item.brand_id,
          is_direct: this.isDirectItem(item) ? true : undefined,
          is_pay_directly: item.is_pay_directly,
          is_consign: item.is_consign,
          collection_id: item.collection_id,
        },
      });
      response = res.data.createShopCart;
    }

    const res = await API.graphql<any>(
      graphqlOperation(queries.searchProductImageKeys, {
        filter: { product_id: { eq: response.product_id } },
        sort: { field: 'createdAt', direction: 'asc' },
      })
    );
    const {
      data: {
        searchProductImageKeys: { items: imageKeys },
      },
    } = res;

    const newItem = this.createCartItem({
      id: response.id,
      owner: response.shopcart_owner,
      quantity: response.shopcart_quantity,
      product_id: response.product_id,
      product_type_id: response.product_type_id,
      product: response.product,
      productType: response.producttype,
      image: getPublicImageUrl(
        response.producttype?.imageKey ?? imageKeys?.[0]?.imageKey
      ),
      brand_id: response.brand_id,
      is_direct: response.is_direct,
      is_pay_directly: response.is_pay_directly,
      is_consign: response.is_consign,
      collection_id: response.collection_id,
    });

    return newItem;
  }

  async deleteItem(item: Omit<CartItemJson, 'product'>) {
    const {
      data: { deleteShopCart: response },
    } = await API.graphql<any>(
      graphqlOperation(mutations.deleteShopCart, { input: { id: item.id } })
    );

    const newItem = this.createCartItem({
      id: response.id,
      owner: response.shopcart_owner,
      quantity: 0,
      product_id: response.product_id,
      product_type_id: response.product_type_id,
      product: response.product,
      productType: response.producttype,
      image: getPublicImageUrl(
        response.producttype?.imageKey ??
          response.product?.imageKeys?.items?.[0]?.imageKey
      ),
      brand_id: response.brand_id,
      is_direct: response.is_direct,
      is_pay_directly: response.is_pay_directly,
      is_consign: response.is_consign,
    });

    return newItem;
  }

  async updateItems(newItems: CartItemJson[]): Promise<CartItemBase[]> {
    const currentItems = this.items;
    return await Promise.all(
      newItems.map(async (item) => {
        if (!item.id && item.quantity) {
          return await this.addItem(item);
        }

        if (item.id && !item.quantity) {
          return await this.deleteItem(item);
        }

        if (
          item.id &&
          currentItems.some(
            ({ id, quantity, is_pay_directly, saved_for_later }) => {
              return (
                id === item.id &&
                (quantity !== item.quantity ||
                  is_pay_directly !== item.is_pay_directly ||
                  saved_for_later !== item.saved_for_later)
              );
            }
          )
        ) {
          const {
            data: { updateShopCart: updateShopCartResponse },
          } = await API.graphql<any>(
            graphqlOperation(mutations.updateShopCart, {
              input: {
                id: item.id,
                shopcart_quantity: item.quantity,
                is_direct: this.isDirectItem(item) ? true : undefined,
                is_pay_directly: item.is_pay_directly,
                saved_for_later: item.saved_for_later,
              },
            })
          );
          const {
            data: { getShopCart: response },
          } = await API.graphql<any>(
            graphqlOperation(customQueries.getShopCartWithImagekey, {
              id: updateShopCartResponse.id,
            })
          );

          const newItem = this.createCartItem({
            id: response.id,
            owner: response.shopcart_owner,
            quantity: response.shopcart_quantity,
            product_id: response.product_id,
            product_type_id: response.product_type_id,
            product: response.product,
            productType: response.producttype,
            image: getPublicImageUrl(
              response.producttype?.imageKey ??
                response.product?.imageKeys?.items?.[0]?.imageKey
            ),
            brand_id: response.brand_id,
            is_direct: response.is_direct,
            is_pay_directly: response.is_pay_directly,
            is_consign: response.is_consign,
            saved_for_later: response.saved_for_later,
          });

          return newItem;
        }

        if (!item.id) {
          return this.createCartItem(item);
        }

        return this.createCartItemById(item.id);
      })
    );
  }

  get isEmpty() {
    return this.items.length === 0;
  }

  get cartBrands() {
    const brandIds = this.items.reduce((prev, cartItem) => {
      const brandId = cartItem.product.product_brand_id;
      if (prev.includes(brandId)) {
        return prev;
      }

      return [...prev, brandId];
    }, [] as string[]);

    return brandIds;
  }

  // 最低購入金額以上のブランドを返す
  get validBrandIds() {
    const brandIds = this.cartBrands;
    return brandIds.filter((brandId) => {
      const brandItems = this.items.filter(
        ({ product }) => product.product_brand_id === brandId
      );

      const totalPrice = brandItems.reduce((prev, item) => {
        return prev + item.totalPrice;
      }, 0);

      return this.getBrandMinimumBuy(brandId) <= totalPrice;
    });
  }

  get additionalValidBrandIds() {
    const brandIds = this.cartBrands;
    return brandIds.filter((brandId) => {
      const brandItems = this.items.filter(
        ({ product }) => product.product_brand_id === brandId
      );

      const totalPrice = brandItems.reduce((prev, item) => {
        return prev + item.totalPrice;
      }, 0);

      const brandAdditionalMinimumBuy =
        brandItems[0].product.brand.brand_additional_minimum_buy;
      if (brandAdditionalMinimumBuy === null) return;

      return brandAdditionalMinimumBuy <= totalPrice;
    });
  }

  get mergeValidBrandIds() {
    return Array.from(
      new Set([...this.validBrandIds, ...this.additionalValidBrandIds])
    );
  }

  async getValidBrandIds() {
    const brandIds = this.cartBrands;
    const segments = this.segments;
    const firstOrderBrandIds = await Promise.all(
      segments.map(async (segment) => {
        const isFirstOrder = await segment.isFirstOrder();
        if (isFirstOrder) return segment.brand.id;
      })
    );
    const filteredFirstOrderBrandIds = firstOrderBrandIds.filter(Boolean);

    return brandIds.filter((brandId) => {
      const brandItems = this.items.filter(
        ({ product }) => product.product_brand_id === brandId
      );

      const brand = brandItems[0].product.brand;

      const brandTotalPrice = brandItems.reduce((prev, item) => {
        return prev + item.totalPrice;
      }, 0);

      if (
        !filteredFirstOrderBrandIds.includes(brandId) &&
        brand.brand_additional_minimum_buy !== null
      ) {
        return brand.brand_additional_minimum_buy <= brandTotalPrice;
      }
      return this.getBrandMinimumBuy(brandId) <= brandTotalPrice;
    });
  }

  get segments() {
    const brands = this.cartBrands;
    const segments = brands.reduce((prev, brandId) => {
      let orders = prev;
      // const contactIds =
      //   this.buyerInfo?.contacts?.items
      //     .map(
      //       (item) =>
      //         item.contactListConnections?.items.map(
      //           (list) => list.contact_list_id!
      //         ) ?? []
      //     )
      //     .flat() ?? [];

      const brandItems = this.items.filter(
        ({ product }) => product.product_brand_id === brandId
      );
      // .filter(({ product }) => product.isPublished(contactIds));
      const subtotalByBrand = brandItems.reduce(
        (prev, item) => prev + item.totalPrice,
        0
      );

      const isDirect = brandItems.some((item) => item.is_direct);

      const items = brandItems.filter(
        ({ is_consign, product }) =>
          !is_consign &&
          (product.product_preorder === undefined ||
            product.product_preorder === null ||
            product.product_preorder === OrderType.inventory)
      );

      if (items.length) {
        orders = [
          ...orders,
          this.createSegment(
            {
              brand: items[0].product.brand,
              isPreorder: false,
              isDirect,
              items,
              subtotalByBrand,
            },
            this.buyerInfo
          ),
        ];
      }
      const consignItems = brandItems.filter(
        ({ is_consign, product }) =>
          is_consign &&
          (product.product_preorder === undefined ||
            product.product_preorder === null ||
            product.product_preorder === OrderType.inventory)
      );

      if (consignItems.length) {
        orders = [
          ...orders,
          this.createSegment(
            {
              brand: consignItems[0].product.brand,
              isPreorder: false,
              isConsign: true,
              isDirect,
              items: consignItems,
              subtotalByBrand,
            },
            this.buyerInfo
          ),
        ];
      }

      const preorderItems = brandItems.filter(
        ({ product }) => product.product_preorder === OrderType.preorder
      );

      // 配送予定日でプレオーダーを分割
      const preorderListByShippingDate = preorderItems.reduce(
        (prev, cartItem) => {
          const date =
            cartItem.product.product_preorder_shipping_date ?? 'not_fixed';

          return {
            ...prev,
            [date]: [...(prev[date] ?? []), cartItem],
          };
        },
        {} as Record<string, CartItemBase[]>
      );

      const preorders = Object.keys(preorderListByShippingDate).map(
        (key) => preorderListByShippingDate[key]
      );

      if (preorderItems.length) {
        orders = [
          ...orders,
          ...preorders.map((preorderItems) =>
            this.createSegment(
              {
                brand: preorderItems[0].product.brand,
                isPreorder: true,
                isDirect,
                items: preorderItems,
                subtotalByBrand,
              },
              this.buyerInfo
            )
          ),
        ];
      }

      return orders;
    }, [] as CartSegmentBase[]);

    return segments;
  }

  get allCartItems() {
    return this.items;
  }

  get validSegments() {
    const validBrandIds = this.validBrandIds;
    const additionalValidBrandIds = this.additionalValidBrandIds;
    return this.segments.filter((segment) => {
      const savedForLaterBrandIds = segment.items
        .filter((item) => item.saved_for_later)
        .map((item) => item.brand_id);
      const allValidBrandIds = Array.from(
        new Set([...validBrandIds, ...additionalValidBrandIds])
      );
      return allValidBrandIds
        .filter((id) => !savedForLaterBrandIds.includes(id))
        .includes(segment.brand.id);
    });
  }

  get cartItems() {
    return this.segments.reduce(
      (prev, segment) => [...prev, ...segment.items],
      [] as CartItemBase[]
    );
  }

  get totalPrice() {
    return this.segments.reduce(
      (prev, segment) => prev + segment.totalPrice,
      0
    );
  }

  get subtotalPrice() {
    return this.segments.reduce(
      (prev, segment) => prev + segment.subtotalPrice,
      0
    );
  }

  get totalRetailPrice() {
    return this.segments.reduce(
      (prev, segment) => prev + segment.totalRetailPrice,
      0
    );
  }

  get tax() {
    return this.segments.reduce((prev, segment) => prev + segment.tax, 0);
  }

  get shippingFee() {
    return this.segments.reduce(
      (prev, segment) => prev + segment.shippingFee,
      0
    );
  }

  get displayShippingFee() {
    return this.segments.reduce(
      (prev, segment) => prev + segment.displayShippingFee,
      0
    );
  }

  get payDirectlyOrderItems() {
    return this.segments.reduce(
      (prev, segment) => [...prev, ...segment.payDirectlyOrderItems],
      [] as CartType['items']
    );
  }

  get hasPayDirectlyOrderItem() {
    return this.segments.some((segment) => segment.hasPayDirectlyOrderItem);
  }

  get hasNotPayDirectlyOrderItem() {
    return this.segments.some((segment) => segment.hasNotPayDirectlyOrderItem);
  }

  async getBrandsAvailableForDirectTrade() {
    const {
      data: { listContactsByBuyer },
    } = await API.graphql<any>(
      graphqlOperation(queries.listContactsByBuyer, {
        buyer_id: this.buyerInfo.id,
      })
    );

    const brands = (
      await Promise.all(
        (listContactsByBuyer.items as Contact[])
          .filter((contact) => contact.direct_payment)
          .map((contact) =>
            contact.supplierInfo?.account_id
              ? Brand.listByOwner(contact.supplierInfo.account_id)
              : undefined
          )
      )
    ).flat();

    const validBrandIds = await this.getValidBrandIds();

    return brands.filter(
      (brand) => brand && validBrandIds.includes(brand.id)
    ) as Brand[];
  }

  filter({ isPayDirectly }: { isPayDirectly?: boolean }) {
    const cart = this.toJson();
    return this.create(
      {
        ...cart,
        items: cart.items.filter(
          ({ is_pay_directly }) =>
            isPayDirectly === undefined || !!is_pay_directly === isPayDirectly
        ),
      },
      this.buyerInfo
    );
  }

  getSegmentByBrandId(brandId: string) {
    return this.segments.find((segment) => segment.brand.id === brandId);
  }

  hasOpenPrice() {
    return this.items.some((item) => item.product.is_open_price);
  }

  async attachPoints(referralPoints: number, points: number) {
    let unAttachedReferral = referralPoints;
    let unAttached = points;
    let attachedPointList: Points.AttachedPoint[][] = [];
    for (const segment of this.segments) {
      if (!unAttached && !unAttachedReferral) break;
      const attachedPoints = await Points.attach(
        this.buyerInfo.account_id,
        segment.brand.id,
        segment.totalPrice,
        unAttachedReferral,
        unAttached
      );
      const [attachedReferral, attached] = attachedPoints.reduce(
        (prev: number[], p) => {
          p.isReferral ? (prev[0] += p.points) : (prev[1] += p.points);
          return prev;
        },
        [0, 0]
      );
      unAttachedReferral -= attachedReferral;
      unAttached -= attached;
      attachedPointList = [...attachedPointList, attachedPoints];
    }
    return attachedPointList;
  }

  static async getCartItemsByBuyer(buyerId: string) {
    const res = await API.graphql<any>(
      graphqlOperation(customQueries.listShopCartsByOwnerWithImageKey, {
        shopcart_owner: buyerId,
        sortDirection: 'ASC',
      })
    );
    const { items } = res.data.listShopCartsByOwner;
    return items;
  }

  toJson(): CartJson {
    return {
      items: this.items.map((item) => item.toJson()),
    };
  }
}
