import { combineReducers } from 'redux';
import authUser from './authUser';
import authenticating from './authenticating';
import signin from './signin';
import signup from './signup';

export const authReducer = combineReducers({
  authUser,
  signin,
  signup,
  authenticating,
});

export default authReducer;
