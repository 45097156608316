import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { warn } from '~utils/log';
import { requestStart, requestComplete, requestError } from '../actions';

type Request = {
  startAction: ActionCreatorWithoutPayload<any>;
  do: () => Promise<any>;
  completeAction:
    | ActionCreatorWithPayload<any, any>
    | ((payload: any) => (dispatch: Dispatch<any>) => Promise<void>);
};

export const request = (request: Request) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(request.startAction());
    dispatch(requestStart(request.startAction.type));

    try {
      const response = await request.do();

      dispatch(await request.completeAction(response));
      dispatch(requestComplete(request.startAction.type));
    } catch (error) {
      console.error(error);
      dispatch(requestError(request.startAction.type));
      if (error.message) {
        toast.error(error.message);
        return;
      }

      toast.error('処理に失敗しました。');

      //エラーを通知
      warn(error);
    }
  };
};
