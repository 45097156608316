import { createReducer } from '@reduxjs/toolkit';
import {
  CartItem,
  setProductData,
  setImage,
} from '../actions';

type CartItemsState = CartItem[];
const initialState: CartItemsState = [];

const imageReducer = createReducer(initialState, {
  [setImage.type]: (_, { payload }) => payload,
});

export default imageReducer;
