import './styles.scss';
// import './header.scss';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo from '../../assets/images/futura_homula_logo_white.svg';
import SearchForm from '../../widgets/SearchForm';
import { signin } from '~redux/auth/reducers/signin';
import { signup } from '~redux/auth/reducers/signup';
import { useViewport } from '~view/hooks/viewport';
import CategoryBurgerMenu from '~view/widgets/CategoryBurgerMenu';
import CategoryNav from '~view/widgets/CategoryNav';
import SearchFormDialog from '~view/widgets/SearchFormDialog';
import Translation from '~view/widgets/Translation';

type Props = {
  loginLink?: string;
  hideMenu?: boolean;
};

const Header: React.FC<Props> = ({ loginLink, hideMenu = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { atMost: atMostLg } = useViewport({ atMost: 'lg' });

  const login = useCallback(() => {
    dispatch(signin(loginLink));
  }, [loginLink, history.location.pathname]);

  const handleSignup = useCallback(() => {
    dispatch(signup({ nextPath: loginLink }));
  }, [loginLink, history.location.pathname]);

  return (
    <header
      id="header-home"
      className="border-bottom bg-white"
      style={{ position: 'sticky', top: 0, left: 0, zIndex: 100 }}
    >
      {atMostLg && <CategoryBurgerMenu />}
      <nav className="d-flex align-items-center px-4 py-1">
        <div className="logo ml-2 mr-2 mr-md-4 pt-2">
          <Link to="/">
            <img
              src={logo}
              alt="homula"
              style={{ maxWidth: '180px', maxHeight: '54px' }}
            />
          </Link>
        </div>
        <div className="d-none d-sm-block flex-grow-1 mr-2 mr-md-4">
          <SearchForm />
        </div>
        <div className="d-none d-lg-block mr-4">
          <a
            style={{ textDecoration: 'underline' }}
            href="https://brands.homula.jp"
            target="_blank"
            rel="noopener noreferrer"
          >
            for Brands
          </a>
        </div>
        <div className="d-none d-lg-block mr-4">
          <a
            style={{ textDecoration: 'underline' }}
            href="https://corp.homula.jp/contact-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact
          </a>
        </div>
        <div className="d-none d-lg-block mr-4">
          <Translation />
        </div>
        <div className="d-flex align-items-center ml-auto">
          <div className="mr-2 d-sm-none">
            <SearchFormDialog />
          </div>
          <div className="mr-2 mr-md-4">
            <Button onClick={login} outline className="p-1 px-md-4 py-md-2">
              ログイン
            </Button>
          </div>
          <div>
            <Button className="p-1 px-md-4 py-md-2" onClick={handleSignup}>
              新規登録
            </Button>
          </div>
        </div>
      </nav>
      {!hideMenu && !atMostLg && (
        <div>
          <CategoryNav />
        </div>
      )}
    </header>
  );
};

export default Header;
