import { createReducer } from '@reduxjs/toolkit';
import { setReturnItems, setReturnItem, ReturnItem } from '../actions';

const initialState: ReturnItem[] = [];

const itemsReducer = createReducer(initialState, {
  [setReturnItems.type]: (_, { payload }) => payload,
  [setReturnItem.type]: (prev, { payload }) => {
    return prev.map((item) => {
      if (item.orderProductId === payload.orderProductId) {
        return payload;
      }
      return item;
    });
  },
});

export default itemsReducer;
