import {
  initialize,
  Event,
  VariationValue,
  Result,
  Target,
  Options,
} from '@harnessio/ff-javascript-client-sdk';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { accountSelector } from '~redux/account/selectors/account';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { identify } from '~utils/segment';

let flagsClient: Result;

const initializeFlags = (apiKey: string, user: Target, options: Options) => {
  flagsClient = initialize(apiKey, user, options);
  return flagsClient;
};

export const getFlag = <T extends unknown>(key: string, defaultValue: T): T => {
  if (!flagsClient) return defaultValue;
  return flagsClient.variation(key, defaultValue) as T;
};

export const FlagsContext = React.createContext<Record<string, unknown>>({});

const parseFlagValue = (value: VariationValue): VariationValue => {
  if (typeof value !== 'string') return value;
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const FlagsProvider: React.FC = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<Record<string, unknown>>({});
  const auth = useSelector(authUserSelector);
  const account = useSelector(accountSelector);
  useEffect(() => {
    if (!auth || !account) return;
    const cf = initializeFlags(
      process.env.REACT_APP_HARNESS_API_KEY!,
      {
        identifier: auth.attributes.sub,
        name: `${account.last_name} ${account.first_name}`.replace(
          /[^\p{L}\d .@_-]/gu,
          ''
        ),
        attributes: {
          lastUpdated: Date(),
          host: window.location.href,
          email: auth.attributes.email,
          profile: auth.attributes.profile,
        },
      },
      {
        baseUrl: 'https://config.ff.harness.io/api/1.0',
        eventUrl: 'https://events.ff.harness.io/api/1.0',
      }
    );
    cf.on(Event.READY, (flags) => {
      const flagsObject = Object.entries(flags).reduce((acc, [key, value]) => {
        acc[key] = parseFlagValue(value);
        return acc;
      }, {} as Record<string, unknown>);
      setFeatureFlags(flagsObject);
      identify(
        auth.attributes.sub,
        auth.attributes.email,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        flagsObject
      );
    });
    cf.on(Event.CHANGED, (flagInfo) => {
      if (flagInfo.deleted) {
        setFeatureFlags((currentFlags) => {
          delete currentFlags[flagInfo.flag];
          return { ...currentFlags };
        });
      } else {
        setFeatureFlags((currentFlags) => ({
          ...currentFlags,
          [flagInfo.flag]: parseFlagValue(flagInfo.value),
        }));
      }
    });
    return () => {
      cf?.close();
    };
  }, [auth, account]);

  return (
    <FlagsContext.Provider value={featureFlags}>
      {children}
    </FlagsContext.Provider>
  );
};

export const useFlags = <T extends Record<string, unknown>>(): T => {
  const flags = React.useContext(FlagsContext);
  return flags as T;
};

export const useFlag = <T,>(flag: string, defaultValue: T): T => {
  const flags = useFlags();
  return (flags?.[flag] ?? defaultValue) as T;
};
