import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Dispatch } from 'redux';
import * as customQueries from '../../graphql/custom_queries';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { isPostpayment } from './../../utils/order';
import { ChargeStatus } from './ChargeStatus';
import { OrderProduct } from './OrderProduct';
import {
  ReportProduct,
  ReportProductStatus,
  ReportRequestType,
} from './ReportProduct';
import { Entity } from '~core/domain/Entity';
import { Brand } from '~domain/brand/Brand';
import { Inventory } from '~domain/inventory/Inventory';
import { BillPayment } from '~domain/payment/BillPayment';
import { Payment } from '~domain/payment/Payment';
import { PaymentIntent } from '~domain/payment/PaymentIntent';
import { Product } from '~domain/product/Product';
import { ProductType } from '~domain/product/ProductType';
import { Account } from '~redux/account/actions/account';
import { BuyerInfo } from '~redux/buyer/actions/buyerInfo';
import {
  BuyerType,
  Order as OrderJson,
  OrderStatusType,
  PaymentStatusType,
  PaymentTermType,
} from '~redux/buyer/types';
import { sendMessage } from '~redux/messages/thunk';
import { ChargeStatusType } from '~redux/payment/types';
import {
  Coupon,
  DiscountType,
  GetIndividualQuery,
  Individual,
  Review,
} from '~types/api';
import { getCreditFacility } from '~utils/buyer';
import {
  sendOrderApprovalToBuyer,
  sendOrderCancelBySystem,
  sendOrderUpdateNotification,
} from '~utils/email';
import { formatCurrency } from '~utils/formatter';
import { executeQuery, removeItems } from '~utils/graphql';
import { warn } from '~utils/log';
import { calculateHomulaFee, calculatePrice, calculateTax } from '~utils/price';
import { getProductTypeString } from '~utils/product';
import { isFreeShipping } from '~utils/shipping';
import { getFlag } from '~view/contexts/FlagsContext';

type OrderType = {
  id: string;
  order_owner: string;
  buyer?: BuyerInfo;
  brand_id: string;
  brand?: Brand;
  campaign_code?: string;
  createdAt: string;
  updatedAt: string;
  chargeStatuses: ChargeStatus;
  owners: string[];
  fee: number;
  is_direct?: boolean;
  is_consign?: boolean;
  shipping_fee: number;
  invoice_shipping_fee?: number;
  tax: number;
  shipping_zip_code: string;
  shipping_address: string;
  shipping_phone_number: string;
  shipping_name: string;
  shipping_date: string;
  carrier: string;
  tracking_number: string;
  payment_term: PaymentTermType;
  first_order: boolean;
  transferred_date: string | null;
  payment: Payment;
  orderproducts: OrderProduct[];
  returnDueDate?: string;
  isReturnable?: boolean;
  order_points?: number;
  origin_order_id?: string;
  reportProducts?: ReportProduct[];
  mf_authorization_id?: string;
  mf_transaction_id?: string;
  mf_canceled_transaction_id?: string;
  cashback?: number;
  reviews?: Review[] | undefined;
  coupon_id?: string;
  coupon?: Coupon;
  discount_amount?: number;
  memo?: string;
};

export class Order extends Entity<OrderType> {
  private constructor(params: OrderType) {
    super(params);
  }

  static async create(
    {
      stripe_client_secret,
      stripe_payment_id,
      payment_method_id,
      orderproducts,
      returnproducts,
      chargeStatuses,
      reportProducts,
      brand,
      ...order
    }: OrderJson,
    isSupplier = false,
    requirePayment = true
  ) {
    let res;
    if (!isSupplier) {
      res = order.id
        ? await API.graphql<any>(
            graphqlOperation(queries.getReturnDueDate, { orderId: order.id })
          )
        : undefined;
    }

    return new Order({
      ...order,
      brand: brand && Brand.create(brand),
      chargeStatuses: ChargeStatus.create(chargeStatuses),
      payment: requirePayment
        ? await Payment.create({
            orderId: order.id,
            stripeClientSecret: stripe_client_secret,
            stripePaymentId: stripe_payment_id,
            stripePaymentMethodId: payment_method_id,
            orderOwner: order.order_owner,
            brandOwner: brand?.brand_owner,
          })
        : ({} as Payment),
      returnDueDate: res?.data?.getReturnDueDate?.dueDate,
      isReturnable: res?.data?.getReturnDueDate?.isReturnable,
      orderproducts: orderproducts
        ? await Promise.all(
            orderproducts.map((op) =>
              OrderProduct.create({
                ...op,
                returns: (returnproducts ?? []).filter(
                  (returnProduct) => returnProduct.order_product_id === op.id
                ),
              })
            )
          )
        : [],
      reportProducts: reportProducts?.map
        ? reportProducts.map(ReportProduct.createInstance)
        : [],
    });
  }

  static async createById(orderId: string) {
    const orderData = await API.graphql<any>(
      graphqlOperation(customQueries.getOrderWithProducts, { id: orderId })
    );
    const {
      data: {
        getOrder: { buyer, ...restOrder },
      },
    } = orderData;

    const orderJsonData = {
      ...removeItems(restOrder),
      buyer: buyer.items[0],
    };

    return Order.create(orderJsonData);
  }

  static async getOrdersByBuyer(buyerId: string) {
    const orderData = await API.graphql<any>(
      graphqlOperation(customQueries.searchOrdersWithProducts, {
        filter: {
          order_owner: { eq: buyerId },
        },
        limit: 10000,
      })
    );
    const {
      data: {
        searchOrders: { items },
      },
    } = orderData;

    const ordersJson = items.map(
      ({
        buyer,
        chargeStatuses,
        orderproducts,
        returnproducts,
        ...rest
      }: any) => ({
        ...rest,
        buyer: buyer.items[0],
        chargeStatuses: chargeStatuses.items,
        orderproducts: orderproducts.items.map(
          ({ orderstatus, ...rest }: any) => ({
            orderstatus: orderstatus.items,
            ...rest,
          })
        ),
        returnproducts: returnproducts.items,
      })
    );
    const orders = await Promise.all<Order>(ordersJson.map(Order.create));
    return orders;
  }

  static async register({
    orderproducts,
    ...params
  }: {
    brand_id: string;
    brand_owner: string;
    campaign_code?: string;
    order_owner: string;
    payment_term: PaymentTermType;
    fee: number;
    is_direct?: boolean;
    is_consign?: boolean;
    tax: number;
    invoice_shipping_fee?: number;
    shipping_fee: number;
    owners: string[];
    stripe_payment_id?: string;
    stripe_client_secret?: string;
    first_order: boolean;
    stripe_payment_method_id: string;
    shipping_zip_code: string;
    shipping_address: string;
    shipping_name: string;
    shipping_phone_number: string;
    shipping_date?: string;
    mf_authorization_id?: string;
    orderproducts: Omit<
      Parameters<typeof OrderProduct['register']>[0],
      'order_id' | 'buyer_id' | 'brand_owner'
    >[];
    order_points?: number;
    coupon_id?: string;
    discount_amount?: number;
    memo?: string;
  }) {
    const createOrderResponse = await executeQuery(mutations.createOrder, {
      input: params,
    });

    const {
      data: { createOrder: orderData },
    } = createOrderResponse;

    const tempOrder = await Order.create({
      ...orderData,
      buyer: orderData.buyer?.items?.[0] ?? orderData.buyer,
      orderproducts: [],
    });

    // メタデータを更新
    if (params.stripe_payment_id) {
      await tempOrder.payment.update({
        metadata: { order_id: orderData.id, coupon_id: params.coupon_id },
      });
    }

    // 決済ステータスを登録
    const chargeStatusResponse = await API.graphql<any>(
      graphqlOperation(mutations.createChargeStatus, {
        input: {
          order_id: orderData.id,
          status: ChargeStatusType.uncharged,
          owners: tempOrder.owners,
        },
      })
    );

    const {
      data: { createChargeStatus: chargeStatusData },
    } = chargeStatusResponse;

    const newOrderProducts = await Promise.all(
      orderproducts.map((orderproduct) =>
        OrderProduct.register({
          ...orderproduct,
          order_id: tempOrder.id,
          buyer_id: params.order_owner,
          brand_owner: params.brand_owner,
        })
      )
    );

    const newOrder = await Order.create({
      ...tempOrder.toJson(),
      chargeStatuses: chargeStatusData,
      orderproducts: newOrderProducts.map((newOrderProduct) =>
        newOrderProduct.toJson()
      ),
    });

    return newOrder;
  }

  get isPayDirectly() {
    return this.payment_term === PaymentTermType.direct;
  }

  get status() {
    //未確認がある場合
    if (
      this.orderproducts.some(
        (orderproduct) => orderproduct.status === OrderStatusType.unconfirmed
      )
    ) {
      //未確認
      return OrderStatusType.unconfirmed;
    }

    //確認済みがある場合
    if (
      this.orderproducts.some(
        (orderproduct) => orderproduct.status === OrderStatusType.confirmed
      )
    ) {
      //確認済
      return OrderStatusType.confirmed;
    }

    //出荷済みがある場合
    if (this.shipped) {
      //出荷済み
      return OrderStatusType.shipped;
    }

    //キャンセルがある場合
    if (this.canceledByStripe) {
      // Stripeキャンセル
      return OrderStatusType.canceledByStripe;
    }

    //キャンセルがある場合
    if (this.canceledByBuyer) {
      // バイヤーキャンセル
      return OrderStatusType.canceledByBuyer;
    }

    //キャンセルがある場合
    if (this.canceledBySupplier) {
      // サプライヤーキャンセル
      return OrderStatusType.canceledBySupplier;
    }

    //上記以外はデフォルト（未払い）
    return OrderStatusType.default;
  }

  get shipped() {
    return this.orderproducts.some(
      (orderproduct) => orderproduct.status === OrderStatusType.shipped
    );
  }

  get canceled() {
    return (
      this.canceledByBuyer ||
      this.canceledBySupplier ||
      this.canceledByStripe ||
      this.orderproducts.some(
        (orderproduct) => orderproduct.status === OrderStatusType.default
      )
    );
  }

  get canceledByBuyer() {
    return this.orderproducts.some(
      (orderproduct) => orderproduct.status === OrderStatusType.canceledByBuyer
    );
  }

  get canceledBySupplier() {
    return this.orderproducts.some(
      (orderproduct) =>
        orderproduct.status === OrderStatusType.canceledBySupplier
    );
  }

  get canceledByStripe() {
    return this.orderproducts.some(
      (orderproduct) => orderproduct.status === OrderStatusType.canceledByStripe
    );
  }

  get chargeStatus() {
    return this.chargeStatuses.status;
  }

  get transferred() {
    return !!this.transferred_date;
  }

  // notifyReturnCheck(AWS Lambda)に同じロジックをコピーしているので、修正する際はそちらと一緒に修正する
  get chargeDate() {
    if (this.chargeStatus === ChargeStatusType.charged) {
      return this.chargeStatuses.chargeDate;
    }

    // キャンセルされていないOrderProductを探す
    const validOrderStatus = this.orderproducts.find(
      (orderProduct) => orderProduct.status === OrderStatusType.shipped
    );
    // 発送日を取得
    const shippedDate = validOrderStatus?.shippedDate;
    //出荷払いの場合
    if (this.payment_term === PaymentTermType.outgoingFreight) {
      return shippedDate;
    }

    // カード払いの後払いの場合
    if (
      [PaymentTermType.postpayment, PaymentTermType.preorder].includes(
        this.payment_term
      )
    ) {
      if (this.status === OrderStatusType.shipped) {
        // 発送日の60日後が請求予定日
        const targetDate =
          shippedDate && moment(shippedDate).add(60, 'days').toISOString();
        return targetDate;
      }
    }

    // 請求書払い（ファクタリング）の場合
    if (
      [PaymentTermType.factoring, PaymentTermType.factoredPreorder].includes(
        this.payment_term
      )
    ) {
      if (this.status === OrderStatusType.shipped) {
        // 発送日を取得
        const shippedDate = validOrderStatus?.shippedDate;
        // 発送日の2ヶ月後の末日が請求予定日
        const targetDate =
          shippedDate &&
          moment(shippedDate).add(2, 'months').endOf('month').toISOString();
        return targetDate;
      }
    }

    return undefined;
  }

  get shippedDate() {
    const shippedProduct = this.orderproducts.find((op) => op.shippedDate);
    if (shippedProduct) {
      return shippedProduct.shippedDate;
    }

    if (this.carrier || this.tracking_number) {
      return this.updatedAt;
    }

    return undefined;
  }

  get extendedDate() {
    if (!this.is_consign || this.status !== OrderStatusType.shipped) {
      return null;
    }
    const fulfilledProduct = this.orderproducts.find((orderProduct) =>
      orderProduct.orderstatus.some(
        (os) => os.status === OrderStatusType.fulfilled
      )
    );
    return fulfilledProduct?.orderstatus.find(
      (os) => os.status === OrderStatusType.fulfilled
    )?.createdAt;
  }

  // 不良品、返品を差し引いた商品合計金額
  get subtotalPrice() {
    const subtotal = this.orderproducts.reduce(
      (prevPrice, orderproduct) => prevPrice + orderproduct.totalPrice,
      0
    );
    return subtotal;
  }

  get subtotalPriceWithoutReturns() {
    const subtotal = this.orderproducts.reduce(
      (prevPrice, orderproduct) =>
        prevPrice + orderproduct.totalPriceWithoutReturns,
      0
    );

    return subtotal;
  }

  // 発注金額（税込）
  get orderAmount() {
    const tax = calculateTax(
      this.subtotalPriceWithoutReturns + this.shipping_fee,
      this.taxRate
    );
    return this.subtotalPriceWithoutReturns + this.shipping_fee + tax;
  }

  get feeRate() {
    return this.fee / 100;
  }

  get taxRate() {
    return this.tax / 100;
  }

  get taxPrice() {
    return calculateTax(this.subtotalPrice + this.shipping_fee, this.taxRate);
  }

  get taxPriceWithoutReturns() {
    return calculateTax(
      this.subtotalPriceWithoutReturns + this.shipping_fee,
      this.taxRate
    );
  }

  get productQuantity() {
    return this.orderproducts.reduce(
      (total, { quantity }) => total + quantity,
      0
    );
  }

  get totalPrice() {
    const shippingFee = this.shipping_fee;
    const total = this.subtotalPrice + shippingFee;
    const tax = this.taxPrice;

    return total + tax;
  }

  get totalQuantity() {
    return this.orderproducts.reduce(
      (total, { totalQuantity }) => total + totalQuantity,
      0
    );
  }

  // 請求金額
  get billingAmount() {
    return (
      this.totalPrice - (this.order_points ?? 0) - this.approvedReportAmount
    );
  }

  get returnRequestQuantity() {
    return this.orderproducts.reduce(
      (total, { returnRequestQuantity }) => total + returnRequestQuantity,
      0
    );
  }

  get returnQuantity() {
    return this.orderproducts.reduce(
      (total, { returnQuantity }) => total + returnQuantity,
      0
    );
  }

  get returnRequestAmount() {
    const returnRequestAmount = this.orderproducts.reduce(
      (total, { returnRequestAmount }) => total + returnRequestAmount,
      0
    );
    return returnRequestAmount;
  }

  get returnAmount() {
    const returnAmount = this.orderproducts.reduce(
      (total, { returnAmount }) => total + returnAmount,
      0
    );
    return returnAmount;
  }

  get returnSubtotal() {
    const returnAmount = this.orderproducts.reduce(
      (total, { returnSubtotal }) => total + returnSubtotal,
      0
    );
    return returnAmount;
  }

  get reportedQuantity() {
    return (this.reportProducts ?? []).reduce(
      (total, { report_quantity }) => total + (report_quantity ?? 0),
      0
    );
  }

  get cancelQuantity() {
    return (this.reportProducts ?? [])
      .filter(
        (reportProduct) =>
          reportProduct.request_type === ReportRequestType.Cancel
      )
      .reduce(
        (total, { report_quantity }) => total + (report_quantity ?? 0),
        0
      );
  }

  get replaceQuantity() {
    return (this.reportProducts ?? [])
      .filter(
        (reportProduct) =>
          reportProduct.request_type === ReportRequestType.Replace
      )
      .reduce(
        (total, { report_quantity }) => total + (report_quantity ?? 0),
        0
      );
  }

  //
  get reportedAmount() {
    return (
      this.reportProducts?.reduce(
        (prev, item) => prev + item.reportAmount,
        0
      ) ?? 0
    );
  }

  get cancelAmount() {
    return (
      this.reportProducts
        ?.filter(
          (reportProduct) =>
            reportProduct.request_type === ReportRequestType.Cancel
        )
        .reduce((prev, item) => prev + item.reportAmount, 0) ?? 0
    );
  }

  get replaceAmount() {
    return (
      this.reportProducts
        ?.filter(
          (reportProduct) =>
            reportProduct.request_type === ReportRequestType.Replace
        )
        .reduce((prev, item) => prev + item.reportAmount, 0) ?? 0
    );
  }

  //
  get approvedReportQuantity() {
    return (
      this.reportProducts
        ?.filter(
          (report) => report.report_status === ReportProductStatus.Approved
        )
        .reduce((prev, item) => prev + (item.report_quantity ?? 0), 0) ?? 0
    );
  }

  //
  get approvedReportAmount() {
    return (
      this.reportProducts
        ?.filter(
          (report) => report.report_status === ReportProductStatus.Approved
        )
        .reduce((prev, item) => prev + item.reportAmount, 0) ?? 0
    );
  }

  //
  get approvedReportSubtotal() {
    return (
      this.reportProducts
        ?.filter(
          (report) => report.report_status === ReportProductStatus.Approved
        )
        .reduce(
          (prev, item) =>
            prev +
            (item.report_quantity ?? 0) * (item.report_product_price ?? 0),
          0
        ) ?? 0
    );
  }

  get homulaFee() {
    return calculateHomulaFee(this.subtotalPrice, this.fee);
  }

  // 振込金額
  get transferAmount() {
    const total = this.subtotalPrice - this.homulaFee + this.shipping_fee; // homula手数料を引いた送料込みの合計金額（税抜）
    const tax = calculateTax(total, this.taxRate); // 消費税
    return total + tax;
  }

  // 初回返品申請日
  get firstReturnRequestDate() {
    const sorted = this.orderproducts.sort((a, b) => {
      return (
        moment(a.firstReturnRequestDate).valueOf() -
        moment(b.firstReturnRequestDate).valueOf()
      );
    });

    return sorted[0]?.firstReturnRequestDate;
  }

  get chargedAmount() {
    return this.chargeStatuses.statuses.reduce(
      (prev, cs) => prev + (cs.amount ?? 0),
      0
    );
  }

  get discountAmount() {
    return this.discount_amount ?? 0;
  }

  //支払い金額（合計金額ー返品金額ーポイント-払い戻し）
  get paymentAmount() {
    return (
      this.totalPrice -
      this.discountAmount -
      (this.order_points ?? 0) -
      (this.cashback ?? 0)
    );
  }

  //確定済み価格
  get fulfilledAmount() {
    return this.orderproducts.reduce(
      (prev, op) => prev + op.fulfilledAmount,
      0
    );
  }

  get hasFulfilled() {
    return this.orderproducts.some((op) =>
      op.orderstatus.some((os) => os.status === OrderStatusType.fulfilled)
    );
  }

  get paymentAmount1() {
    return Math.max(
      this.fulfilledAmount +
        this.shipping_fee +
        calculateTax(this.fulfilledAmount + this.shipping_fee, this.taxRate) -
        (this.order_points ?? 0) -
        (this.cashback ?? 0),
      0
    );
  }

  get paymentDate1() {
    return this.chargeDate;
  }

  get paymentAmount2() {
    return Math.max(this.totalPrice - this.paymentAmount1, 0);
  }

  get paymentDate2() {
    if (
      ![PaymentTermType.postpayment, PaymentTermType.preorder].includes(
        this.payment_term
      )
    ) {
      return null;
    }
    return this.extendedDate
      ? moment(this.extendedDate).startOf('day').add(60, 'days').toISOString()
      : null;
  }

  // 想定送料（税込）
  get assumedShippingFee() {
    if (
      this.brand &&
      this.brand.brand_shippinng_fee_criteria &&
      this.brand.brand_shippinng_fee &&
      this.brand.brand_shippinng_fee_criteria > this.orderAmount
    ) {
      const tax = calculateTax(this.brand.brand_shippinng_fee, this.taxRate);
      return this.brand?.brand_shippinng_fee + tax;
    }

    return 0;
  }

  get isIndividual() {
    return this.buyer?.buyer_type === BuyerType.individual;
  }

  // 送料無料の基準を上回る場合はtrueを返す
  get isFreeShipping() {
    if (!this.brand) {
      throw new Error('ブランド情報の取得失敗しました。');
    }

    if (this.isIndividual) {
      // 個人オーダーの場合
      if (
        !(
          this.brand.individual_shippinng_fee_criteria ??
          this.brand.brand_shippinng_fee_criteria
        )
      ) {
        return false;
      }

      return (
        (this.brand.individual_shippinng_fee_criteria ??
          this.brand.brand_shippinng_fee_criteria)! <= this.subtotalPrice
      );
    }

    if (this.brand.brand_shippinng_fee_criteria === null) {
      return false;
    }

    return this.brand.brand_shippinng_fee_criteria <= this.subtotalPrice;
  }

  // 実費送料（税込）
  get actualShippingFee() {
    if (this.invoice_shipping_fee === undefined) {
      return undefined;
    }
    const tax = calculateTax(this.invoice_shipping_fee, this.taxRate);
    return this.invoice_shipping_fee + tax;
  }

  /* 振り込み */
  async transfer() {
    const response = await API.graphql<any>(
      graphqlOperation(mutations.updateOrder, {
        input: {
          id: this.id,
          transferred_date: moment().toISOString(),
        },
      })
    );

    return Order.create({
      ...this.toJson(),
      transferred_date: response.data.updateOrder.transferred_date,
    });
  }

  /* 出荷 */
  async shipping(params: {
    shippingDate: string;
    carrier: string;
    trackingNumber: string;
    shippingFee?: number;
  }) {
    if (params.shippingFee !== undefined) {
      const { shippingFee, ...restPrams } = params;
      // Orderテーブルを更新
      const freeShipping = isFreeShipping(new Date(this.createdAt));
      const {
        data: { updateOrder: newOrderData },
      } = await API.graphql<any>(
        graphqlOperation(mutations.updateOrder, {
          input: {
            id: this.id,
            // shipping_fee:
            //   (!this.isIndividual && freeShipping) || this.isFreeShipping
            //     ? 0
            //     : shippingFee ?? this.shipping_fee,
            // invoice_shipping_fee: this.isFreeShipping ? 0 : shippingFee,
          },
        })
      );

      const newOrderJson = removeItems(newOrderData);
      const newOrder = await Order.createById(newOrderJson.id);
      await newOrder.shipping(restPrams);
      return newOrder;
    }

    // 請求処理
    if (this.chargeStatus !== ChargeStatusType.charged) {
      // 予約注文の場合
      if (this.payment_term === PaymentTermType.preorder) {
        await this.commitPreorder();
      }

      // 出荷払いの場合
      if (this.payment_term === PaymentTermType.outgoingFreight) {
        await this.commitOutgoingFreightOrder();
      }

      // 請求書払いの場合
      if (
        [PaymentTermType.factoring, PaymentTermType.factoredPreorder].includes(
          this.payment_term
        )
      ) {
        await this.commitTransaction();
      }
    }

    await this.saveShippingInfo(params);

    // OrderStatusテーブルを更新
    await Promise.all(
      this.orderproducts
        .filter(
          (orderProduct) => orderProduct.status === OrderStatusType.confirmed
        )
        .map(async (item) => {
          await API.graphql<any>(
            graphqlOperation(mutations.createOrderStatus, {
              input: {
                order_product_id: item.id,
                status: OrderStatusType.shipped,
                owners: this.owners,
              },
            })
          );
        })
    );

    // 直取引以外の場合
    if (this.payment_term !== PaymentTermType.direct) {
      const sendEmailParams: OrderJson = {
        ...this.toJson(),
        shipping_date: params.shippingDate,
        carrier: params.carrier,
        tracking_number: params.trackingNumber,
        stripe_client_secret: this.payment.stripeClientSecret,
        stripe_payment_id: this.payment.stripePaymentId,
      };

      // バイヤーに発送通知を送信
      sendOrderApprovalToBuyer(
        await Order.create(sendEmailParams),
        this.orderproducts
          .filter(
            (orderProduct) => orderProduct.status === OrderStatusType.confirmed
          )
          .map((orderProduct) => orderProduct.toJson())
      );
    }

    return this;
  }

  private async commitOutgoingFreightOrder() {
    try {
      await this.payment.capture('manual');
    } catch (e) {
      warn(e as Error);

      // 処理失敗時は注文をキャンセル
      // await Promise.all(
      //   this.orderproducts
      //     .filter(
      //       (orderProduct) => orderProduct.status === OrderStatusType.confirmed
      //     )
      //     .map(async (orderProduct) => {
      //       await API.graphql<any>(
      //         graphqlOperation(mutations.createOrderStatus, {
      //           input: {
      //             order_product_id: orderProduct.id,
      //             status: OrderStatusType.canceledByStripe,
      //             owners: this.owners,
      //           },
      //         })
      //       );
      //     })
      // );

      //ポイントを返還
      // if ((this.order_points ?? 0) > 0) {
      //   await Point.createInstance({
      //     account_id: this.order_owner,
      //     history_type: PointHistoryType.chargeByOrderCancel,
      //     point: this.order_points!,
      //     order_id: this.id,
      //   }).update();
      // }

      await this.onCommitError();

      throw e;
    }
  }

  private async commitPreorder() {
    try {
      if (!this.buyer?.account_id) {
        throw new Error('バイヤー情報の取得に失敗しました。');
      }

      const creditFacility = await getCreditFacility(
        this.buyer?.account_id,
        this.id
      );
      // 与信枠を超えた合計額の場合即チャージを行う
      if (this.totalPrice > creditFacility) {
        await this.payment.capture('automatic');
      }
    } catch (e) {
      console.log(e);

      // 処理失敗時は注文をキャンセル
      // await Promise.all(
      //   this.orderproducts
      //     .filter(
      //       (orderproduct) =>
      //         (orderproduct.orderstatus[0]?.status ??
      //           OrderStatusType.confirmed) === OrderStatusType.confirmed
      //     )
      //     .map(async (item) => {
      //       await API.graphql<any>(
      //         graphqlOperation(mutations.createOrderStatus, {
      //           input: {
      //             order_product_id: item.id,
      //             status: OrderStatusType.default,
      //             owners: this.owners,
      //           },
      //         })
      //       );
      //     })
      // );

      //ポイントを返還
      // if ((this.order_points ?? 0) > 0) {
      //   await Point.createInstance({
      //     account_id: this.order_owner,
      //     history_type: PointHistoryType.chargeByOrderCancel,
      //     point: this.order_points!,
      //     order_id: this.id,
      //   }).update();
      // }

      await this.onCommitError();

      throw e;
    }
  }

  private async commitTransaction() {
    try {
      // const bill = new BillPayment({ buyerId: this.buyer?.id });
      // return await bill.createTransaction(this.id!);
    } catch (e) {
      warn(e as Error);

      // 処理失敗時は注文をキャンセル
      // await Promise.all(
      //   this.orderproducts
      //     .filter(
      //       (orderProduct) => orderProduct.status === OrderStatusType.confirmed
      //     )
      //     .map(async (orderProduct) => {
      //       await API.graphql<any>(
      //         graphqlOperation(mutations.createOrderStatus, {
      //           input: {
      //             order_product_id: orderProduct.id,
      //             status: OrderStatusType.canceledByStripe,
      //             owners: this.owners,
      //           },
      //         })
      //       );
      //     })
      // );

      //ポイントを返還
      // if ((this.order_points ?? 0) > 0) {
      //   await Point.createInstance({
      //     account_id: this.order_owner,
      //     history_type: PointHistoryType.chargeByOrderCancel,
      //     point: this.order_points!,
      //     order_id: this.id,
      //   }).update();
      // }

      await this.onCommitError();

      throw e;
    }
  }

  private async saveShippingInfo({
    shippingDate,
    carrier,
    trackingNumber,
  }: {
    shippingDate: string;
    carrier: string;
    trackingNumber: string;
  }) {
    await API.graphql<any>(
      graphqlOperation(mutations.updateOrder, {
        input: {
          id: this.id,
          shipping_date: shippingDate,
          carrier,
          tracking_number: trackingNumber,
        },
      })
    );
  }

  async onCommitError() {
    // 決済ステータスを登録
    await API.graphql<any>(
      graphqlOperation(mutations.createChargeStatus, {
        input: {
          order_id: this.id,
          status: ChargeStatusType.failed,
          owners: [this.order_owner, this.brand?.brand_owner],
        },
      })
    );

    // const sendOrderCancelBySystemParams: Parameters<
    //   typeof sendOrderCancelBySystem
    // >[0] = {
    //   ...this.toJson(),
    //   stripe_client_secret: this.payment.stripeClientSecret,
    //   stripe_payment_id: this.payment.stripePaymentId,
    // };

    // // サプライヤーへキャンセル通知を送信
    // await sendOrderCancelBySystem(
    //   sendOrderCancelBySystemParams,
    //   this.orderproducts.map((orderProduct) => orderProduct.toJson())
    // );
  }

  async updatePayment(price: number, metadata: any, dispatch: Dispatch<any>) {
    //決済情報を更新
    if (this.payment.stripePaymentId && price > 0) {
      // 決済金額が変更された場合、決済情報を更新
      const payment = await this.payment.update({
        price,
        metadata,
        isPostpayment: isPostpayment(this.payment_term),
      });
      return {
        paymentIntentId: payment.stripePaymentId,
        clientSecret: payment.stripeClientSecret,
      };
    } else if (this.payment.stripePaymentId && price <= 0) {
      //決済金額が1以上から0に変更された場合、決済情報を取り消し
      await this.payment.cancel();
      return null;
    } else if (price > 0) {
      //決済金額が0から1以上に変更された場合、決済情報を登録
      const intent = await PaymentIntent.register(
        {
          price,
          isPostpayment: isPostpayment(this.payment_term),
        },
        this.buyer!
      );
      await intent.authorize(dispatch);

      // メタデータを更新
      (
        await Payment.create({
          orderId: this.id,
          stripePaymentId: intent.stripePaymentIntentId,
          orderOwner: this.order_owner,
          stripePaymentMethodId: this.payment.stripePaymentMethodId,
        })
      ).update({ metadata: { order_id: this.id } });
      return {
        paymentIntentId: intent.stripePaymentIntentId,
        clientSecret: intent.stripeClientSecret,
      };
    }
  }

  async addOrderProducts(
    additionalProducts: {
      quantity: number;
      wholesaleRate?: number;
      productTypes: ProductType;
    }[]
  ) {
    const orderProducts = await Promise.all(
      additionalProducts.map(async (orderproduct) => {
        const product = await Product.getById(
          orderproduct.productTypes.product.id
        );
        const wholesaleRate =
          orderproduct.wholesaleRate ?? product.wholesaleRate;
        const unitPrice = calculatePrice(
          product.product_retail_price,
          wholesaleRate
        );
        return await OrderProduct.register({
          order_id: this.id,
          product_id: product.id,
          product_type_id: orderproduct.productTypes.type?.id,
          order_product_quantity: orderproduct.quantity,
          order_product_price: unitPrice,
          order_product_payment_status: PaymentStatusType.unconfirmed,
          order_product_payment_method: this.payment_term,
          order_product_wholesale_rate:
            orderproduct.wholesaleRate ?? product.wholesaleRate,
          buyer_id: this.buyer?.buyer_id!,
          brand_owner: this.brand?.brand_owner!,
          owners: [this.brand?.brand_owner!, this.buyer?.buyer_id!],
        });
      })
    );

    return orderProducts;
  }

  async edit(
    params: {
      price?: number;
      metadata?: any;
      edit_reason: string;
      editReason: string;
      shippingName?: string;
      shippingZipCode?: string;
      shippingAddress?: string;
      shippingPhoneNumber?: string;
      shippingFee?: number;
      tax?: number;
      changedProducts?: {
        id: string;
        quantity?: number;
        wholesaleRate?: number;
      }[];
      additionalProducts?: {
        quantity: number;
        wholesaleRate?: number;
        productTypes: ProductType;
      }[];
    },
    account: Account,
    dispatch: Dispatch<any>
  ): Promise<Order> {
    const { additionalProducts, price, ...restParams } = params;

    // 後払いの場合
    // if (price && this.payment_term === PaymentTermType.postpayment) {
    //   if (!this.buyer?.account_id) {
    //     throw new Error('与信情報が登録されていません。');
    //   }
    //   // 与信枠のチェック
    //   const creditFacility = await getCreditFacility(
    //     this.buyer?.account_id,
    //     this.id
    //   );
    //   // 与信枠を超えた合計額の場合
    //   if (price > creditFacility) {
    //     throw new Error('与信枠を超えた注文です。');
    //   }
    // }

    if (additionalProducts?.length) {
      await this.addOrderProducts(additionalProducts);
    }

    const {
      metadata,
      edit_reason,
      editReason,
      shippingName,
      shippingZipCode,
      shippingAddress,
      shippingPhoneNumber,
      shippingFee,
      tax,
      changedProducts,
    } = restParams;

    let points = this.order_points;
    let paymentIntentId = this.payment.stripePaymentId;
    let clientSecret = this.payment.stripeClientSecret;
    let mf_authorization_id = null;
    let discount_amount = this.discountAmount;

    //金額が変更された場合
    if (price !== undefined) {
      discount_amount = !this.coupon
        ? 0
        : this.coupon.discount_type === DiscountType.AMOUNT
        ? this.coupon.discount_value ?? 0
        : this.coupon.discount_type === DiscountType.PERCENTAGE
        ? Math.min(
            Math.round(price * ((this.coupon.discount_value ?? 0) / 100)),
            this.coupon.maximum_amount ?? 0
          )
        : 0;
      const paymentAmount = price - discount_amount - (this.order_points ?? 0);
      if (paymentAmount < 0) {
        points = points! + paymentAmount;
      }
      console.log(price, points, discount_amount);

      //決済情報を更新（カード払い）
      if (
        [
          PaymentTermType.postpayment,
          PaymentTermType.outgoingFreight,
          PaymentTermType.preorder,
        ].includes(this.payment_term)
      ) {
        const payment = await this.updatePayment(
          paymentAmount,
          metadata,
          dispatch
        );
        paymentIntentId = payment?.paymentIntentId;
        clientSecret = payment?.clientSecret;
      }

      //オーソリゼーション（請求書払い）
      if (
        [PaymentTermType.factoring, PaymentTermType.factoredPreorder].includes(
          this.payment_term
        )
      ) {
        // オーソリゼーションの更新
        const bill = new BillPayment({ buyerId: this.buyer?.id });
        const { available, authorization } = await bill.updateAuthorization(
          this.id,
          price
        );
        // 与信枠を超えた合計額の場合
        if (!available) {
          throw new Error('与信枠を超えた注文です。');
        }
        if (authorization) {
          mf_authorization_id = authorization.id;
        }
      }
    }

    // OrderProductテーブルを更新
    let changedOrderProducts: OrderProduct[] | undefined = undefined;
    if (changedProducts) {
      changedOrderProducts = await Promise.all(
        this.orderproducts
          .filter((item) => item.quantity !== 0)
          .filter((item) => changedProducts.some(({ id }) => id === item.id))
          .map(async (orderProduct) => {
            const changedItem = changedProducts.find(
              ({ id }) => id === orderProduct.id
            );
            const changedProduct = await orderProduct.edit({
              quantity: changedItem?.quantity,
              wholesaleRate: changedItem?.wholesaleRate,
            });
            return changedProduct;
          })
      );
    }

    // Orderテーブルを更新
    const {
      data: { updateOrder: newOrderData },
    } = await API.graphql<any>(
      graphqlOperation(mutations.updateOrder, {
        input: {
          id: this.id,
          shipping_name: shippingName ?? this.shipping_name,
          shipping_zip_code: shippingZipCode ?? this.shipping_zip_code,
          shipping_address: shippingAddress ?? this.shipping_address,
          shipping_phone_number:
            shippingPhoneNumber ?? this.shipping_phone_number,
          shipping_fee: isFreeShipping(new Date(this.createdAt))
            ? 0
            : shippingFee ?? this.shipping_fee,
          stripe_payment_id: paymentIntentId ?? null,
          stripe_client_secret: clientSecret ?? null,
          order_points: points,
          invoice_shipping_fee: shippingFee,
          mf_authorization_id,
          mf_canceled_transaction_id: mf_authorization_id ? null : undefined,
          discount_amount,
        },
      })
    );

    const newOrderJson = {
      ...newOrderData,
      buyer: newOrderData.buyer.items[0],
      orderproducts: newOrderData.orderproducts.items,
      returnproducts: newOrderData.returnproducts.items,
      chargeStatuses: newOrderData.chargeStatuses.items,
    };
    const newOrder = await Order.create(newOrderJson);

    //ポイント履歴を登録
    // if (points !== this.order_points) {
    //   const pointsDiff = this.order_points! - points!;
    //   await Point.createInstance({
    //     point: pointsDiff,
    //     history_type: PointHistoryType.chargeByOrderEdit,
    //     order_id: this.id,
    //     account_id: this.order_owner,
    //   }).update();
    // }

    if (
      this.payment_term !== PaymentTermType.direct &&
      (!isFreeShipping(new Date(this.createdAt)) ||
        shippingName ||
        shippingZipCode ||
        shippingAddress ||
        shippingPhoneNumber ||
        changedProducts?.length ||
        additionalProducts?.length)
    ) {
      this.sendMessageOnEdit(
        {
          price,
          metadata,
          edit_reason,
          editReason,
          shippingName,
          shippingZipCode,
          shippingAddress,
          shippingPhoneNumber,
          shippingFee,
          tax,
          changedOrderProducts,
          additionalProducts,
        },
        account,
        dispatch
      );
    }

    //在庫更新
    await Inventory.reservation(
      newOrder.toJson().orderproducts,
      this.toJson().orderproducts
    );

    return newOrder;
  }

  private async sendMessageOnEdit(
    {
      price,
      edit_reason,
      editReason,
      shippingName,
      shippingZipCode,
      shippingAddress,
      shippingPhoneNumber,
      shippingFee,
      tax,
      changedOrderProducts,
      additionalProducts,
    }: Omit<Parameters<Order['edit']>[0], 'changedProducts'> & {
      changedOrderProducts: OrderProduct[] | undefined;
    },
    account: Parameters<Order['edit']>[1],
    dispatch: Dispatch<any>
  ) {
    //メッセージを送信
    let message = `以下のオーダーが変更されました。

・注文番号：${this.id}`;
    if (shippingName) {
      message = `${message}
・納入先：${shippingName}`;
    }
    if (shippingZipCode || shippingAddress) {
      message = `${message}
・納入先住所：${shippingZipCode ?? this.shipping_zip_code}　${
        shippingAddress ?? this.shipping_address
      }`;
    }
    if (shippingPhoneNumber) {
      message = `${message}
・連絡先：${shippingPhoneNumber}`;
    }
    if (shippingFee) {
      message = `${message}
・送料：${formatCurrency(shippingFee)}`;
    }
    if (tax) {
      message = `${message}
・消費税額：${formatCurrency(tax)}`;
    }
    if (price) {
      message = `${message}
・合計金額：${formatCurrency(price)}`;
    }

    if ((changedOrderProducts?.length ?? 0) > 0) {
      message = `${message}

・変更された商品：
${this.orderproducts
  .filter((item) =>
    changedOrderProducts?.some((orderProduct) => orderProduct.id === item.id)
  )
  .reduce((msg, item) => {
    const changedProduct = changedOrderProducts?.find(
      (orderProduct) => orderProduct.id === item.id
    );
    const quantity = changedProduct?.quantity ?? item.quantity;
    const wholesaleRate =
      changedProduct?.wholesale_rate ??
      item.wholesale_rate ??
      item.product.wholesaleRate;
    const price = calculatePrice(
      item.product.product_retail_price,
      wholesaleRate
    );
    return `${msg}　${item.product.product_name}${
      item.productType && `（${getProductTypeString(item.productType)}）`
    }（数量：${quantity}、${
      item.product.is_open_price ? '' : `掛け率：${wholesaleRate}%、`
    }卸売価格：${formatCurrency(price)}）
`;
  }, '')}`;
    }

    if (additionalProducts !== undefined && additionalProducts.length > 0) {
      message = `${message}

・追加された商品：
${additionalProducts
  .map((additionalProduct) => {
    return `　${additionalProduct.productTypes.product.product_name}${
      additionalProduct.productTypes.type &&
      `（${getProductTypeString(additionalProduct.productTypes.toJson()!)}）`
    }（数量：${additionalProduct.quantity}、${
      additionalProduct.productTypes.product.is_open_price
        ? ''
        : `掛け率：${additionalProduct.productTypes.product.wholesaleRate}%、`
    }卸売価格：${formatCurrency(
      additionalProduct.productTypes.product.unitPrice
    )}）`;
  })
  .join('\n')}
`;
    }

    message = `${message}
・変更理由：
${editReason}`;

    await API.graphql<any>(
      graphqlOperation(mutations.createOrderEditReason, {
        input: { order_id: this.id, edit_reason, feedback_comment: editReason },
      })
    );

    await sendMessage(
      message,
      '',
      account.id,
      {
        buyer_owner: this.buyer?.account_id!,
        brand_id: this.brand_id,
        brand_owner: this.brand?.brand_owner!,
        owners: this.owners,
      },
      undefined,
      false
    )(dispatch);

    // 変更通知メールを送信
    sendOrderUpdateNotification({
      order: this.toJson(),
      updateReason: editReason,
      changedProducts: changedOrderProducts?.map((orderProduct) =>
        orderProduct.toJson()
      ),
      shipping_name: shippingName,
      shipping_zip_code: shippingZipCode,
      shipping_address: shippingAddress,
      shipping_phone_number: shippingPhoneNumber,
      shipping_fee: shippingFee,
      tax,
      price,
      additionalProducts,
    });
  }

  toJson(): OrderJson {
    return {
      id: this.id,
      order_owner: this.order_owner,
      brand: this.brand?.toJson(),
      brand_id: this.brand_id,
      buyer: this.buyer,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      stripe_payment_id: this.payment.stripePaymentId,
      stripe_client_secret: this.payment.stripeClientSecret,
      payment_method_id: this.payment.stripePaymentMethodId,
      fee: this.fee,
      is_direct: this.is_direct,
      shipping_fee: this.shipping_fee,
      invoice_shipping_fee: this.invoice_shipping_fee,
      tax: this.tax,
      shipping_zip_code: this.shipping_zip_code,
      shipping_address: this.shipping_address,
      shipping_phone_number: this.shipping_phone_number,
      shipping_name: this.shipping_name,
      shipping_date: this.shipping_date,
      carrier: this.carrier,
      tracking_number: this.tracking_number,
      payment_term: this.payment_term,
      first_order: this.first_order,
      transferred_date: this.transferred_date,
      owners: this.owners,
      chargeStatuses: this.chargeStatuses.toJson(),
      orderproducts: this.orderproducts.map((orderProduct) =>
        orderProduct.toJson()
      ),
      returnproducts: this.orderproducts.reduce(
        (prev, { returns }) => [
          ...prev,
          ...returns.map((returnProduct) => ({
            ...returnProduct.toJson(),
            order_id: this.id,
            order_product_id: returnProduct.id,
          })),
        ],
        [] as OrderJson['returnproducts']
      ),
      order_points: this.order_points,
      origin_order_id: this.origin_order_id,
      mf_authorization_id: this.mf_authorization_id,
      mf_transaction_id: this.mf_transaction_id,
      mf_canceled_transaction_id: this.mf_canceled_transaction_id,
      cashback: this.cashback,
    };
  }
}
