import { uuid4 } from '@sentry/utils';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Product } from './Product';
import { Entity } from '~core/domain/Entity';
import { ProductType as ProductTypeJson } from '~redux/brand/types';
import {
  CreateProductTypeInput,
  CreateProductTypeMutation,
  GetProductQuery,
  GetProductTypeQuery,
  UpdateProductTypeMutation,
  UpdateProductTypeMutationVariables,
} from '~types/api';
import { PartialRequired } from '~types/utils';
import { removeItems } from '~utils/graphql';

export type ProductTypeType = {
  product: Product;
  type?: {
    id: string;
    product_id: string;
    type_name: string | null;
    createdAt: string | null;
    imageKey: string | null;
    imageUrl: string | null;
    suspended: boolean;
    color?: string;
    size?: string;
    published?: boolean;
    sku?: string;
    copied_product_type_id?: string | null;
    copied_brand_id?: string | null;
    product_owner?: string;
  };
};

export class ProductType extends Entity<ProductTypeType> {
  private constructor(params: ProductTypeType) {
    super(params);
  }

  static create({
    product,
    ...productType
  }: PartialRequired<Partial<ProductTypeJson>, 'product'>) {
    if (!productType.id) {
      return new ProductType({
        product: Product.create(product),
      });
    }

    return new ProductType({
      type: productType as ProductTypeJson,
      product: Product.create(product),
    });
  }

  static async createById(productTypeId: string) {
    const {
      data: { getProductType },
    } = (await API.graphql<any>(
      graphqlOperation(queries.getProductType, {
        id: productTypeId,
      })
    )) as {
      data: GetProductTypeQuery;
    };
    const productType = removeItems<ProductTypeJson>(getProductType);
    const {
      data: { getProduct },
    } = (await API.graphql<any>(
      graphqlOperation(queries.getProduct, {
        id: productType?.product_id,
      })
    )) as {
      data: GetProductQuery;
    };
    const product = removeItems<NonNullable<ProductTypeJson['product']>>(
      getProduct
    );
    return ProductType.create({ ...productType, product });
  }

  static createNoType({
    product,
    ...productType
  }: PartialRequired<ProductTypeJson, 'product'>) {
    return new ProductType({
      type: productType,
      product: Product.create(product),
    });
  }

  async update(input: Omit<UpdateProductTypeMutationVariables['input'], 'id'>) {
    if (!this.type) {
      return undefined;
    }

    const {
      data: { updateProductType },
    } = (await API.graphql<any>(
      graphqlOperation(mutations.updateProductType, {
        input: {
          id: this.type.id,
          ...input,
        },
      })
    )) as {
      data: UpdateProductTypeMutation;
    };

    return updateProductType && ProductType.createById(updateProductType.id);
  }

  async copy({
    destProductId,
    owner,
  }: {
    destProductId: string;
    owner: string;
  }) {
    //画像ファイルのコピー
    let newProductTypeImage;
    if (this.type?.imageKey) {
      const extension = this.type.imageKey.split('.').pop();
      newProductTypeImage = {
        name: `return_${uuid4()}.${extension}`,
        copyKey: this.type.imageKey,
      };
    }
    let newImageKey;
    if (newProductTypeImage) {
      try {
        const newFile = (await Storage.copy(
          { key: encodeURIComponent(newProductTypeImage.copyKey) },
          { key: encodeURIComponent(newProductTypeImage.name) }
        )) as { key: string };
        newImageKey = (newFile as { key: string }).key;
      } catch (e) {
        console.error(e);
      }
    }

    //商品種類を登録
    const input: CreateProductTypeInput = {
      product_id: destProductId,
      type_name: this.type?.type_name,
      imageKey: newImageKey,
      suspended: false,
      published: true,
      color: this.type?.color,
      size: this.type?.size,
      copied_product_type_id: this.type?.id,
      owner,
    };
    const {
      data: { createProductType: registeredProductType },
    } = (await API.graphql<any>(
      graphqlOperation(mutations.createProductType, {
        input,
      })
    )) as {
      data: CreateProductTypeMutation;
    };
    console.log(registeredProductType);

    if (!registeredProductType) {
      return;
    }

    return ProductType.createById(registeredProductType.id);
  }

  toJson(): ProductTypeJson | undefined {
    return (
      this.type && {
        id: this.type.id,
        color: this.type.color,
        size: this.type.size,
        published: this.type.published,
        sku: this.type.sku,
        copied_product_type_id: this.type.copied_product_type_id,
        copied_brand_id: this.type.copied_brand_id,
        product_id: this.type.product_id,
        type_name: this.type.type_name,
        createdAt: this.type.createdAt,
        imageKey: this.type.imageKey,
        imageUrl: this.type.imageUrl,
        suspended: this.type.suspended,
        product: this.product.toJson(),
      }
    );
  }
}
