import React from 'react';
import { useLocation } from 'react-router';
import BuyerPage from '~view/widgets/BuyerPage';

const withBuyerPage = (
  Component: React.ComponentType<any>,
  title?: string,
  loginLink?: string,
  unAuth?: boolean,
  excludePaths?: string[]
) => {
  return (props: any) => {
    const location = useLocation();
    if (excludePaths?.includes(location.pathname)) {
      return null;
    }
    return (
      <BuyerPage title={title} loginLink={loginLink} unAuth={unAuth}>
        <Component {...props} />
      </BuyerPage>
    );
  };
};

export default withBuyerPage;
