import { createReducer } from '@reduxjs/toolkit';
import {
  setFavoriteBrands,
  appendFavoriteBrand,
  clearFavoriteBrands,
  FavoriteBrand,
  removeFavoriteBrand,
  setFavoriteBrand,
} from '../actions';

const initialState: FavoriteBrand[] = [];

const favoriteBrandsReducer = createReducer(initialState, {
  [setFavoriteBrands.type]: (_, { payload }) => payload,
  [appendFavoriteBrand.type]: (state, { payload }) => [...state, payload],
  [removeFavoriteBrand.type]: (state, { payload }) =>
    state.filter(({ id }) => id !== payload),
  [setFavoriteBrand.type]: (state, { payload: { id, brand_id } }) =>
    state.map((item) => {
      if (item.brand_id === brand_id) {
        return { ...item, id };
      }
      return item;
    }),
  [clearFavoriteBrands.type]: () => initialState,
});

export default favoriteBrandsReducer;
