import { createSelector } from 'reselect';
import { RootState } from '../../reducer';
import { PointType } from '~domain/point/Point';
import { getExpirationDate } from '~domain/point/Points';

export const pointsSelector = (state: RootState) => state.point;
export const selectPoints = createSelector(pointsSelector, (points) =>
  Object.values(points.byId)
);
export const selectTotalPoints = createSelector(selectPoints, (points) => {
  const now = new Date();
  const total = points
    .filter((point) => Number(getExpirationDate(point)) >= Number(now))
    .reduce((prev, point) => prev + (point.points ?? 0), 0);
  return total;
});
export const makeSelectReferralPointsByBrands = (brandIds?: string[]) =>
  createSelector(selectPoints, (points) => {
    const now = new Date();
    return points
      .filter(
        (point) =>
          point.point_type === PointType.earnedByEntry &&
          point.referral_brand_id &&
          (!brandIds?.length || brandIds.includes(point.referral_brand_id)) &&
          new Date(getExpirationDate(point)).getTime() >= now.getTime()
      )
      .reduce((prev, point) => prev + (point.points ?? 0), 0);
  });
export const selectAvailablePoints = createSelector(
  selectTotalPoints,
  makeSelectReferralPointsByBrands(),
  (totalPoints, referralPoints) => Math.max(totalPoints - referralPoints, 0)
);
export const selectInputPoints = createSelector(
  pointsSelector,
  (point) => point.inputPoint
);
export const makeSelectPointBalance = (brandIds: string[]) =>
  createSelector(
    selectTotalPoints,
    makeSelectReferralPointsByBrands(brandIds),
    selectInputPoints,
    (totalPoint, referralPoint, inputPoint) =>
      totalPoint - (referralPoint ?? 0) - (inputPoint ?? 0)
  );
