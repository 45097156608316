export const searchProductswithimagekey = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_color
        product_size
        product_jancode
        product_stock_quantity
        product_type
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_preorder_shipping_date
        product_order_lot
        product_preorder
        product_display_order
        product_colors
        product_sizes
        product_types
        createdAt
        out_of_stock
        sku
        continue_selling
        publishedAt
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          exclude_zip_code
          is_sale_brand
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
        }
        imageKeys(sortDirection: ASC) {
          items {
            createdAt
            id
            imageKey
            product_id
          }
          nextToken
        }
        videoKeys(sortDirection: ASC) {
          items {
            createdAt
            id
            videoKey
            product_id
          }
          nextToken
        }
        producttype(
          sortDirection: ASC
          filter: { suspended: { ne: true }, published: { ne: false } }
        ) {
          items {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            published
            sku
          }
          nextToken
        }
        discount: contacts {
          items {
            discount_rate
          }
        }
        suspended
      }
      nextToken
      total
    }
  }
`;
export const searchProductswithimagekeyForSupplier = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_color
        product_size
        product_jancode
        product_stock_quantity
        product_type
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_preorder_shipping_date
        product_order_lot
        product_preorder
        product_display_order
        product_colors
        product_sizes
        product_types
        createdAt
        out_of_stock
        continue_selling
        publishedAt
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          exclude_zip_code
          is_sale_brand
        }
        imageKeys(sortDirection: ASC) {
          items {
            createdAt
            id
            imageKey
            product_id
          }
          nextToken
        }
        videoKeys(sortDirection: ASC) {
          items {
            createdAt
            id
            videoKey
            product_id
          }
          nextToken
        }
        producttype(
          sortDirection: ASC
          filter: { suspended: { ne: true }, published: { ne: false } }
        ) {
          items {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            published
          }
          nextToken
        }
        discount: contacts {
          items {
            discount_rate
          }
        }
        suspended
      }
      nextToken
      total
    }
  }
`;
export const searchProductswithimagekeyPreview = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_color
        product_size
        product_jancode
        product_stock_quantity
        product_type
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_preorder_shipping_date
        product_order_lot
        product_preorder
        product_display_order
        product_colors
        product_sizes
        product_types
        createdAt
        out_of_stock
        continue_selling
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          exclude_zip_code
          is_sale_brand
        }
        imageKeys(sortDirection: ASC) {
          items {
            createdAt
            id
            imageKey
            product_id
          }
          nextToken
        }
        producttype(
          sortDirection: ASC
          filter: { suspended: { ne: true }, published: { ne: false } }
        ) {
          items {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            published
          }
          nextToken
        }
        suspended
      }
      nextToken
      total
    }
  }
`;
export const searchProductsWithCollection = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        createdAt
        updatedAt
        sort_recommended
        suspended
        continue_selling
        out_of_stock
        sku
        copied_product_id
        copied_brand_id
        limited_publishing_list
        owner
        imageKeys {
          items {
            id
            product_id
            imageKey
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
        videoKeys {
          items {
            id
            product_id
            videoKey
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
        producttype {
          items {
            id
            product_id
            imageKey
            type_name
            color
            size
            createdAt
            suspended
            published
            sku
            out_of_stock
            copied_product_type_id
            copied_brand_id
            owner
            updatedAt
          }
          nextToken
        }
        inventoryConnection {
          items {
            id
            brand_id
            product_id
            product_type_id
            sku
            inventory
            createdAt
            updatedAt
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          updatedAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          stockists {
            stockist
            address
          }
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          account {
            id
            email
            first_name
            last_name
            web_site_URL
            instagram_account
            zip_code
            location
            tel
            company_name
            term_approval_status
            createdAt
            updatedAt
            owner
          }
          product {
            nextToken
          }
          collections {
            nextToken
          }
          contacts {
            nextToken
          }
        }
        collections {
          items {
            id
            collection_id
            product_id
            product_type_id
            quantity
            sort_order
            owner
            createdAt
            updatedAt
            collection {
              id
              brand_id
              name
              description
              image_key
              is_consign
              published
              preorder
              is_consign
              season
              season_year
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            supplier_id
            buyer_id
            buyer_owner
            email
            store_name
            contact_name
            tel
            zipcode
            address
            discount_rate
            contact_status
            contact_source
            direct_payment
            contacted
            signedup
            ordered
            invited
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchProductswithimagekeyUnAuth = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_retail_price
        is_open_price
        product_color
        product_size
        product_jancode
        product_stock_quantity
        product_type
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_display_order
        product_colors
        product_sizes
        product_types
        createdAt
        out_of_stock
        continue_selling
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          exclude_zip_code
          is_sale_brand
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
        }
        imageKeys(sortDirection: ASC) {
          items {
            createdAt
            id
            imageKey
            product_id
          }
          nextToken
        }
        producttype(
          sortDirection: ASC
          filter: { suspended: { ne: true }, published: { ne: false } }
        ) {
          items {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            published
          }
          nextToken
        }
        suspended
      }
      nextToken
      total
    }
  }
`;
export const getShopCartWithImagekey = /* GraphQL */ `
  query GetShopCart($id: ID!) {
    getShopCart(id: $id) {
      id
      shopcart_owner
      shopcart_quantity
      product_id
      product_type_id
      product {
        id
        product_name
        product_brand_id
        product_content
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        is_open_price
        product_stock_quantity
        product_type
        product_public_status
        product_category
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_colors
        product_sizes
        product_types
        createdAt
        updatedAt
        suspended
        out_of_stock
        continue_selling
        imageKeys {
          items {
            id
            imageKey
            product_id
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          exclude_zip_code
          createdAt
          updatedAt
          is_sale_brand
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
        }
        producttype(
          sortDirection: ASC
          filter: { suspended: { ne: true }, published: { ne: false } }
        ) {
          items {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            published
            out_of_stock
          }
        }
        discount: contacts {
          items {
            discount_rate
          }
        }
      }
      producttype {
        id
        product_id
        imageKey
        type_name
        createdAt
        updatedAt
        suspended
        color
        size
        out_of_stock
      }
      brand_id
      is_direct
      is_pay_directly
      createdAt
      updatedAt
      owner
      saved_for_later
    }
  }
`;
export const listShopCartsByOwnerWithImageKey = /* GraphQL */ `
  query ListShopCartsByOwner(
    $shopcart_owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopCartsByOwner(
      shopcart_owner: $shopcart_owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopcart_owner
        shopcart_quantity
        product_id
        product_type_id
        collection_id
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          exclude_zip_code
          createdAt
          updatedAt
          is_sale_brand
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
        }
        product {
          id
          product_name
          product_brand_id
          product_content
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          is_open_price
          product_stock_quantity
          product_type
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          createdAt
          updatedAt
          suspended
          product_colors
          product_sizes
          product_types
          out_of_stock
          continue_selling
          imageKeys {
            items {
              id
              imageKey
              product_id
            }
            nextToken
          }
          brand {
            id
            brand_owner
            brand_name
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_additional_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_stripe_id
            brand_audit_status
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            brand_direct_order_fee
            exclude_zip_code
            createdAt
            updatedAt
            is_sale_brand
            individual_minimum_buy
            individual_wholesale_rate
            individual_shippinng_fee
            individual_shippinng_fee_criteria
            individual_first_order_fee
            individual_additional_order_fee
          }
          producttype(
            sortDirection: ASC
            filter: { suspended: { ne: true }, published: { ne: false } }
          ) {
            items {
              id
              product_id
              imageKey
              type_name
              createdAt
              updatedAt
              suspended
              color
              size
              published
              out_of_stock
            }
          }
          discount: contacts {
            items {
              discount_rate
            }
          }
        }
        producttype {
          id
          product_id
          imageKey
          type_name
          createdAt
          updatedAt
          suspended
          color
          size
          published
          out_of_stock
        }
        brand_id
        is_direct
        is_pay_directly
        is_consign
        createdAt
        updatedAt
        owner
        saved_for_later
      }
      nextToken
    }
  }
`;
export const searchPublicBrands = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_stripe_id
        brand_audit_status
        createdAt
        product(
          filter: {
            product_public_status: { eq: "公開" }
            suspended: { ne: true }
          }
        ) {
          nextToken
          items {
            createdAt
            id
            product_brand_id
            product_category
            product_color
            product_content
            product_description
            product_display_order
            product_estimated_ship_date_max
            product_estimated_ship_date_min
            product_jancode
            product_name
            product_minimum_quantity
            product_number
            product_order_lot
            product_preorder
            product_owner
            product_price
            product_public_status
            product_retail_price
            product_stock_quantity
            product_size
            product_type
            product_subcategory
            product_wholesale_rate
            product_sale_wholesale_rate
            is_open_price
            product_colors
            product_sizes
            product_types
            suspended
            out_of_stock
            continue_selling
            imageKeys {
              items {
                createdAt
                id
                imageKey
                product_id
                updatedAt
              }
              nextToken
            }
            producttype(
              sortDirection: ASC
              filter: { suspended: { ne: true }, published: { ne: false } }
            ) {
              items {
                id
                product_id
                imageKey
                type_name
                createdAt
                updatedAt
                suspended
                color
                size
                published
                out_of_stock
              }
              nextToken
            }
          }
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPublicBrandsUnAuth = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        createdAt
        product(
          filter: {
            product_public_status: { eq: "公開" }
            suspended: { ne: true }
          }
        ) {
          nextToken
          items {
            createdAt
            id
            product_brand_id
            product_category
            product_color
            product_content
            product_description
            product_display_order
            product_estimated_ship_date_max
            product_estimated_ship_date_min
            product_jancode
            product_name
            product_minimum_quantity
            product_number
            product_order_lot
            product_preorder
            product_owner
            product_public_status
            product_retail_price
            is_open_price
            product_stock_quantity
            product_size
            product_type
            product_subcategory
            product_colors
            product_sizes
            product_types
            suspended
            out_of_stock
            continue_selling
            imageKeys {
              items {
                createdAt
                id
                imageKey
                product_id
                updatedAt
              }
              nextToken
            }
            producttype(
              sortDirection: ASC
              filter: { suspended: { ne: true }, published: { ne: false } }
            ) {
              items {
                id
                product_id
                imageKey
                type_name
                createdAt
                updatedAt
                suspended
                color
                size
                published
                out_of_stock
              }
              nextToken
            }
          }
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchThreadsWithMessages = /* GraphQL */ `
  query SearchThreads(
    $filter: SearchableThreadFilterInput
    $sort: SearchableThreadSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchThreads(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        thread_buyer_owner
        thread_buyer_name
        thread_brand_owner
        thread_brand_id
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          exclude_zip_code
          createdAt
          updatedAt
          is_sale_brand
        }
        messages(filter: { message_is_open: { eq: false } }) {
          items {
            id
            message_owner
            message_is_open
          }
          nextToken
        }
        owners
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const searchOrderProductIds = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        brand_id
        order_owner
        createdAt
      }
      nextToken
      total
    }
  }
`;

export const searchOrdersWithProducts = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        order_owner
        buyer {
          items {
            id
            account_id
            account {
              id
              first_name
              last_name
              web_site_URL
              instagram_account
              location
              company_name
              term_approval_status
              createdAt
              updatedAt
              owner
            }
            buyer_id
            shop_name
            owner_name
            buyer_type
            buyer_rating
            buyer_credit
            primary_shipping_address_id
            shipping_address {
              id
              name
              buyer_id
              zip_code
              prefecture
              city
              building
              phone_number
              createdAt
              updatedAt
            }
            stripe_id
            primary_payment_method_id
            primary_payment_term
            payment_type
            billing_close_day
            createdAt
            updatedAt
            referralBrand {
              id
              brand_name
            }
          }
          nextToken
        }
        brand_id
        brand_owner
        brand {
          id
          account {
            id
            first_name
            last_name
            web_site_URL
            instagram_account
            location
            company_name
            term_approval_status
            createdAt
            updatedAt
            owner
          }
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_stripe_id
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          is_sale_brand
          exclude_zip_code
          createdAt
          updatedAt
        }
        shipping_zip_code
        shipping_address
        shipping_phone_number
        shipping_name
        first_order
        createdAt
        chargeStatuses {
          items {
            id
            order_id
            status
            createdAt
            updatedAt
            owners
          }
          nextToken
        }
        orderproducts(limit: 1000) {
          items {
            id
            order_id
            product_id
            product_type_id
            order_product_quantity
            order_product_price
            order_product_wholesale_rate
            order_product_payment_status
            order_product_payment_method
            order_product_return_reason
            orderstatus {
              items {
                id
                order_product_id
                status
                createdAt
                updatedAt
                owners
                quantity
              }
              nextToken
            }
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_color
              product_size
              product_jancode
              product_stock_quantity
              product_type
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_display_order
              product_colors
              product_sizes
              product_types
              out_of_stock
              continue_selling
              imageKeys {
                items {
                  createdAt
                  id
                  imageKey
                  product_id
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              suspended
              discount: contacts {
                items {
                  discount_rate
                }
              }
            }
            productType {
              id
              product_id
              imageKey
              type_name
              createdAt
              updatedAt
              suspended
              color
              size
              published
              out_of_stock
            }
            createdAt
            updatedAt
            owners
            reportProducts(limit: 1000) {
              items {
                id
                order_id
                order_product_id
                report_type
                request_type
                report_quantity
                report_product_price
                report_status
                report_comment
                replace_order_id
                replace_order_product_id
                approval_date
                approval_quantity
                image_keys
                owners
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        payment_term
        stripe_payment_id
        stripe_client_secret
        fee
        shipping_fee
        tax
        shipping_date
        carrier
        tracking_number
        updatedAt
        owners
        returnproducts(limit: 1000) {
          items {
            id
            order_id
            order_product_id
            return_quantity
            return_product_price
            return_status
            stripe_payment_id
            owners
            createdAt
            updatedAt
          }
          nextToken
        }
        order_points
        campaign_code
        is_direct
        is_consign
        transferred_date
        origin_order_id
        mf_authorization_id
        mf_transaction_id
        mf_canceled_transaction_id
        cashback
        reportProducts(limit: 1000) {
          items {
            id
            order_id
            order_product_id
            report_type
            request_type
            report_quantity
            report_product_price
            report_status
            report_comment
            replace_order_id
            replace_order_product_id
            approval_date
            approval_quantity
            image_keys
            owners
            createdAt
            updatedAt
          }
        }
        reviews {
          items {
            id
            brand_id
            product_ids
            buyer_id
            order_id
            rate
            title
            comment
            remove_flag
            createdAt
            updatedAt
          }
          nextToken
        }
        coupon_id
        coupon {
          id
          coupon_name
          discount_type
          discount_value
        }
        discount_amount
      }
      nextToken
      total
    }
  }
`;
export const getOrderWithProducts = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      order_owner
      buyer {
        items {
          id
          account_id
          buyer_id
          owner_name
          shop_name
          buyer_type
          buyer_product_category
          buyer_brand
          buyer_rating
          buyer_kyc_status
          buyer_credit
          buyer_return_credit
          primary_shipping_address_id
          stripe_id
          primary_payment_method_id
          primary_payment_term
          referral_brand_id
          payment_type
          billing_close_day
          createdAt
          updatedAt
          owner
          shipping_address {
            id
            name
            buyer_id
            zip_code
            prefecture
            city
            building
            phone_number
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      brand_id
      brand_owner
      brand {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        createdAt
        owner
        product {
          nextToken
        }
        designed_in
        established
        tagIds
        tags
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
        }
        is_sale_brand
        featured_in
        maximum_wholesale_rate
        return_to_brand
        exclude_zip_code
        updatedAt
      }
      createdAt
      owners
      chargeStatuses {
        items {
          id
          order_id
          status
          createdAt
          owners
          updatedAt
        }
        nextToken
      }
      orderproducts(limit: 1000) {
        items {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          orderstatus {
            items {
              id
              order_product_id
              status
              quantity
              createdAt
              updatedAt
              owners
              quantity
            }
            nextToken
          }
          product {
            id
            product_name
            product_number
            product_brand_id
            product_content
            product_description
            product_owner
            product_wholesale_rate
            product_sale_wholesale_rate
            product_retail_price
            product_price
            is_open_price
            product_color
            product_size
            product_jancode
            product_stock_quantity
            product_type
            product_public_status
            product_category
            product_subcategory
            product_minimum_quantity
            product_estimated_ship_date_min
            product_estimated_ship_date_max
            product_order_lot
            product_preorder
            product_display_order
            product_colors
            product_sizes
            product_types
            out_of_stock
            continue_selling
            imageKeys {
              items {
                createdAt
                id
                imageKey
                product_id
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            suspended
          }
          productType {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            published
            out_of_stock
          }
          reportProducts {
            items {
              id
              order_id
              order_product_id
              report_type
              request_type
              report_quantity
              report_product_price
              report_status
              report_comment
              replace_order_id
              replace_order_product_id
              approval_date
              approval_quantity
              image_keys
              owners
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      stripe_payment_id
      stripe_client_secret
      stripe_payment_method_id
      fee
      shipping_fee
      tax
      payment_term
      shipping_zip_code
      shipping_address
      shipping_name
      shipping_phone_number
      shipping_date
      carrier
      tracking_number
      returnproducts(limit: 1000) {
        items {
          id
          order_id
          order_product_id
          return_product_id
          return_quantity
          return_product_price
          return_status
          stripe_payment_id
          owners
          createdAt
          updatedAt
        }
        nextToken
      }
      first_order
      updatedAt
      order_points
      campaign_code
      invoice_shipping_fee
      is_direct
      transferred_date
      origin_order_id
      mf_transaction_id
      mf_authorization_id
      mf_canceled_transaction_id
      cashback
      is_consign
      memo
      reportProducts {
        items {
          id
          order_id
          order_product_id
          report_type
          request_type
          report_quantity
          report_product_price
          report_status
          report_comment
          replace_order_id
          replace_order_product_id
          approval_date
          approval_quantity
          image_keys
          owners
          createdAt
          updatedAt
        }
      }
      coupon_id
      coupon {
        id
        coupon_name
        discount_type
        discount_value
      }
      discount_amount
    }
  }
`;
export const searchAccountswithInfo = /* GraphQL */ `
  query SearchAccounts(
    $filter: SearchableAccountFilterInput
    $sort: SearchableAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        first_name
        last_name
        web_site_URL
        instagram_account
        location
        tel
        company_name
        term_approval_status
        buyerInfo {
          items {
            account_id
            buyer_brand
            buyer_credit
            buyer_kyc_status
            buyer_product_category
            buyer_id
            buyer_rating
            buyer_type
            buyer_return_credit
            createdAt
            id
            owner
            owner_name
            primary_payment_method_id
            primary_payment_term
            primary_shipping_address_id
            referral_brand_id
            shop_name
            stripe_id
            payment_type
            billing_close_day
            updatedAt
            shipping_address {
              building
              buyer_id
              city
              createdAt
              id
              name
              phone_number
              prefecture
              zip_code
              updatedAt
            }
          }
        }
        supplierInfo {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrandReadModel($id: ID!) {
    getBrandReadModel(id: $id) {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_minimum_buy
      brand_additional_minimum_buy
      brand_shippinng_fee
      brand_shippinng_fee_criteria
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      brand_stripe_id
      brand_first_order_fee
      brand_additional_order_fee
      brand_first_order_fee_referral
      brand_additional_order_fee_referral
      brand_direct_order_fee
      createdAt
      updatedAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      brand_online_sale
      product_image_reprint
      brand_logo_reprint
      post_to_sns
      allow_direct_shipping
      allow_sell_before_buy
      other_terms
      stockists {
        stockist
        address
      }
      featured_in
      maximum_wholesale_rate
      exclude_zip_code
      is_sale_brand
      hubspot_company_id
      return_to_brand
      terms_of_sample
      reviews
      review_average
      service_rate
      average_shipping_days
      sell_only_direct
      individual_minimum_buy
      individual_wholesale_rate
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
    }
  }
`;
export const getBrandUnAuth = /* GraphQL */ `
  query GetBrandReadModel($id: ID!) {
    getBrandReadModel(id: $id) {
      id
      company_name
      brand_owner
      brand_name
      brand_yomigana
      brand_type
      brand_content
      brand_imageKey
      brand_icon_imageKey
      brand_logo_imageKey
      brand_publication
      brand_retail_stores_number
      brand_category
      brand_target_age
      brand_target_gender
      brand_website_url
      brand_work_with_big_box
      brand_address
      brand_instagram_account
      brand_facebook_account
      brand_rating
      brand_annual_turnover
      brand_public_status
      brand_howtoknowhomula
      brand_audit_status
      createdAt
      owner
      designed_in
      established
      tagIds
      tags
      brand_collections
      movie_url
      brand_modal_imageKeys
      stockists {
        stockist
        address
      }
      featured_in
      exclude_zip_code
      is_sale_brand
      updatedAt
      reviews
      review_average
      sell_only_direct
      individual_minimum_buy
      individual_wholesale_rate
      individual_shippinng_fee
      individual_shippinng_fee_criteria
      individual_first_order_fee
      individual_additional_order_fee
    }
  }
`;
export const searchBrandsUnAuth = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        createdAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        stockists {
          stockist
          address
        }
        featured_in
        exclude_zip_code
        is_sale_brand
        updatedAt
        account {
          id
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
        }
        product {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchBrandsWithProducts = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        tags
        exclude_zip_code
        createdAt
        owner
        product(
          filter: {
            product_public_status: { eq: "公開" }
            suspended: { ne: true }
          }
        ) {
          items {
            id
            product_name
            createdAt
            product_display_order
            imageKeys {
              items {
                imageKey
              }
            }
            publishedAt
          }
          nextToken
        }
        reviews {
          items {
            rate
          }
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchBrandsWithProductsUnAuth = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        exclude_zip_code
        tags
        createdAt
        owner
        product(
          filter: {
            product_public_status: { eq: "公開" }
            suspended: { ne: true }
          }
        ) {
          items {
            id
            product_name
            createdAt
            product_display_order
            imageKeys {
              items {
                imageKey
              }
            }
            publishedAt
          }
          nextToken
        }
        reviews {
          items {
            rate
          }
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPickupBrands = /* GraphQL */ `
  query SearchPickupBrandss(
    $filter: SearchablePickupBrandsFilterInput
    $sort: SearchablePickupBrandsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPickupBrandss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        brands {
          brand_id
          display_order
          brand {
            id
            brand_owner
            brand_name
            brand_yomigana
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_logo_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_additional_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_audit_status
            brand_stripe_id
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            brand_direct_order_fee
            createdAt
            updatedAt
            owner
            designed_in
            established
            tagIds
            tags
            brand_collections
            movie_url
            brand_modal_imageKeys
            brand_online_sale
            product_image_reprint
            brand_logo_reprint
            post_to_sns
            allow_direct_shipping
            allow_sell_before_buy
            other_terms
            featured_in
            maximum_wholesale_rate
            exclude_zip_code
            is_sale_brand
            hubspot_company_id
            return_to_brand
            terms_of_sample
            auto_message_enabled
            auto_message
            product {
              items {
                id
              }
            }
          }
        }
        display_num
        visible
        item_type
        display_size
        createdAt
        updatedAt
        products {
          items {
            id
            pickup_list_id
            brand_id
            product_id
            display_order
            createdAt
            updatedAt
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_jancode
              product_stock_quantity
              product_type
              product_color
              product_size
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_types
              product_colors
              product_sizes
              product_display_order
              createdAt
              updatedAt
              sort_recommended
              suspended
              continue_selling
              out_of_stock
              sku
              copied_product_id
              copied_brand_id
              limited_publishing_list
              owner
              imageKeys {
                items {
                  id
                  product_id
                  imageKey
                  createdAt
                  owner
                  updatedAt
                }
                nextToken
              }
              producttype {
                items {
                  id
                  product_id
                  imageKey
                  type_name
                  color
                  size
                  createdAt
                  suspended
                  published
                  sku
                  out_of_stock
                  copied_product_type_id
                  copied_brand_id
                  owner
                  updatedAt
                }
              }
              brand {
                id
                brand_owner
                brand_name
                brand_yomigana
                brand_type
                brand_content
                brand_imageKey
                brand_icon_imageKey
                brand_logo_imageKey
                brand_publication
                brand_retail_stores_number
                brand_category
                brand_target_age
                brand_target_gender
                brand_website_url
                brand_work_with_big_box
                brand_address
                brand_minimum_buy
                brand_additional_minimum_buy
                brand_shippinng_fee
                brand_shippinng_fee_criteria
                brand_instagram_account
                brand_facebook_account
                brand_rating
                brand_annual_turnover
                brand_public_status
                brand_howtoknowhomula
                brand_audit_status
                brand_stripe_id
                brand_first_order_fee
                brand_additional_order_fee
                brand_first_order_fee_referral
                brand_additional_order_fee_referral
                brand_direct_order_fee
                createdAt
                updatedAt
                owner
                designed_in
                established
                tagIds
                tags
                brand_collections
                movie_url
                brand_modal_imageKeys
                brand_online_sale
                product_image_reprint
                brand_logo_reprint
                post_to_sns
                allow_direct_shipping
                allow_sell_before_buy
                other_terms
                stockists {
                  stockist
                  address
                }
                featured_in
                maximum_wholesale_rate
                exclude_zip_code
                is_sale_brand
                hubspot_company_id
                return_to_brand
                terms_of_sample
                auto_message_enabled
                auto_message
                account {
                  id
                  email
                  first_name
                  last_name
                  web_site_URL
                  instagram_account
                  zip_code
                  location
                  tel
                  company_name
                  term_approval_status
                  createdAt
                  updatedAt
                  owner
                  buyerInfo {
                    nextToken
                  }
                  supplierInfo {
                    nextToken
                  }
                }
                product {
                  items {
                    id
                    product_name
                    product_number
                    product_brand_id
                    product_content
                    product_description
                    product_owner
                    product_wholesale_rate
                    product_sale_wholesale_rate
                    product_retail_price
                    product_price
                    is_open_price
                    product_jancode
                    product_stock_quantity
                    product_type
                    product_color
                    product_size
                    product_public_status
                    product_category
                    product_subcategory
                    product_minimum_quantity
                    product_estimated_ship_date_min
                    product_estimated_ship_date_max
                    product_order_lot
                    product_preorder
                    product_preorder_shipping_date
                    product_types
                    product_colors
                    product_sizes
                    product_display_order
                    createdAt
                    updatedAt
                    sort_recommended
                    suspended
                    continue_selling
                    out_of_stock
                    sku
                    copied_product_id
                    copied_brand_id
                    limited_publishing_list
                    owner
                  }
                  nextToken
                }
                collections {
                  items {
                    id
                    brand_id
                    name
                    description
                    image_key
                    video_key
                    published
                    createdAt
                    owner
                    preorder
                    is_consign
                    season
                    season_year
                    updatedAt
                  }
                  nextToken
                }
                contacts {
                  items {
                    id
                    supplier_id
                    buyer_id
                    buyer_owner
                    email
                    store_name
                    contact_name
                    tel
                    zipcode
                    address
                    discount_rate
                    contact_status
                    contact_source
                    direct_payment
                    contacted
                    signedup
                    ordered
                    invited
                    createdAt
                    owner
                    updatedAt
                  }
                  nextToken
                }
              }
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchBrandIds = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
      }
      nextToken
      total
    }
  }
`;
export const listCollectionsByBrandWithProducts = /* GraphQL */ `
  query ListCollectionsByBrand(
    $brand_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionsByBrand(
      brand_id: $brand_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brand_id
        name
        description
        image_key
        is_consign
        brand {
          brand_owner
          sell_only_direct
        }
        productCollections(limit: 1000) {
          items {
            id
            collection_id
            product_id
            product_type_id
            quantity
            sort_order
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_color
              product_size
              product_jancode
              product_stock_quantity
              product_type
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_display_order
              createdAt
              sort_recommended
              suspended
              updatedAt
              product_colors
              product_sizes
              product_types
              producttype(
                filter: { suspended: { ne: true }, published: { ne: false } }
              ) {
                items {
                  id
                  product_id
                  imageKey
                  type_name
                  color
                  size
                  createdAt
                  suspended
                  published
                  owner
                  updatedAt
                }
                nextToken
              }
              imageKeys {
                items {
                  id
                  product_id
                  imageKey
                  createdAt
                  owner
                  updatedAt
                }
                nextToken
              }
              brand {
                id
                sell_only_direct
                brand_owner
                brand_name
                brand_type
                brand_content
                brand_imageKey
                brand_icon_imageKey
                brand_publication
                brand_retail_stores_number
                brand_category
                brand_target_age
                brand_target_gender
                brand_website_url
                brand_work_with_big_box
                brand_address
                brand_minimum_buy
                brand_shippinng_fee
                brand_shippinng_fee_criteria
                brand_instagram_account
                brand_facebook_account
                brand_rating
                brand_annual_turnover
                brand_public_status
                brand_howtoknowhomula
                brand_audit_status
                brand_stripe_id
                brand_first_order_fee
                brand_additional_order_fee
                brand_first_order_fee_referral
                brand_additional_order_fee_referral
                exclude_zip_code
                createdAt
                owner
                updatedAt
                is_sale_brand
                individual_minimum_buy
                individual_wholesale_rate
                individual_shippinng_fee
                individual_shippinng_fee_criteria
                individual_first_order_fee
                individual_additional_order_fee
              }
              discount: contacts {
                items {
                  discount_rate
                }
              }
            }
            productType {
              id
              product_id
              imageKey
              type_name
              createdAt
              suspended
              updatedAt
              color
              size
              published
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        preorder
        is_consign
        season
        season_year
        display_order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCollectionsByBrandWithProductsPreview = /* GraphQL */ `
  query ListCollectionsByBrand(
    $brand_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionsByBrand(
      brand_id: $brand_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brand_id
        name
        description
        image_key
        is_consign
        brand {
          brand_owner
          sell_only_direct
        }
        productCollections(limit: 1000) {
          items {
            id
            collection_id
            product_id
            product_type_id
            quantity
            sort_order
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_color
              product_size
              product_jancode
              product_stock_quantity
              product_type
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_display_order
              createdAt
              sort_recommended
              suspended
              updatedAt
              product_colors
              product_sizes
              product_types
              out_of_stock
              continue_selling
              producttype(
                filter: { suspended: { ne: true }, published: { ne: false } }
              ) {
                items {
                  id
                  product_id
                  imageKey
                  type_name
                  color
                  size
                  createdAt
                  suspended
                  published
                  owner
                  updatedAt
                  out_of_stock
                }
                nextToken
              }
              imageKeys {
                items {
                  id
                  product_id
                  imageKey
                  createdAt
                  owner
                  updatedAt
                }
                nextToken
              }
              brand {
                id
                brand_owner
                brand_name
                brand_type
                brand_content
                brand_imageKey
                brand_icon_imageKey
                brand_publication
                brand_retail_stores_number
                brand_category
                brand_target_age
                brand_target_gender
                brand_website_url
                brand_work_with_big_box
                brand_address
                brand_minimum_buy
                brand_shippinng_fee
                brand_shippinng_fee_criteria
                brand_instagram_account
                brand_facebook_account
                brand_rating
                brand_annual_turnover
                brand_public_status
                brand_howtoknowhomula
                brand_audit_status
                brand_stripe_id
                brand_first_order_fee
                brand_additional_order_fee
                brand_first_order_fee_referral
                brand_additional_order_fee_referral
                exclude_zip_code
                createdAt
                owner
                updatedAt
                is_sale_brand
              }
            }
            productType {
              id
              product_id
              imageKey
              type_name
              createdAt
              suspended
              updatedAt
              color
              size
              published
              out_of_stock
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        preorder
        season
        season_year
        display_order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCollectionsByBrandWithProductsUnAuth = /* GraphQL */ `
  query ListCollectionsByBrand(
    $brand_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionsByBrand(
      brand_id: $brand_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brand_id
        name
        description
        image_key
        is_consign
        brand {
          brand_owner
          sell_only_direct
        }
        productCollections(limit: 1000) {
          items {
            id
            collection_id
            product_id
            product_type_id
            quantity
            sort_order
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_retail_price
              is_open_price
              product_color
              product_size
              product_jancode
              product_stock_quantity
              product_type
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_display_order
              createdAt
              sort_recommended
              suspended
              updatedAt
              product_colors
              product_sizes
              product_types
              out_of_stock
              continue_selling
              producttype(
                filter: { suspended: { ne: true }, published: { ne: false } }
              ) {
                items {
                  id
                  product_id
                  imageKey
                  type_name
                  color
                  size
                  createdAt
                  suspended
                  published
                  owner
                  updatedAt
                  out_of_stock
                }
                nextToken
              }
              imageKeys {
                items {
                  id
                  product_id
                  imageKey
                  createdAt
                  owner
                  updatedAt
                }
                nextToken
              }
              brand {
                id
                brand_owner
                brand_name
                brand_type
                brand_content
                brand_imageKey
                brand_icon_imageKey
                brand_publication
                brand_retail_stores_number
                brand_category
                brand_target_age
                brand_target_gender
                brand_website_url
                brand_work_with_big_box
                brand_address
                brand_instagram_account
                brand_facebook_account
                brand_rating
                brand_annual_turnover
                brand_public_status
                brand_howtoknowhomula
                brand_audit_status
                exclude_zip_code
                createdAt
                owner
                updatedAt
                is_sale_brand
              }
            }
            productType {
              id
              product_id
              imageKey
              type_name
              createdAt
              suspended
              updatedAt
              color
              size
              published
              out_of_stock
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        preorder
        is_consign
        season
        season_year
        display_order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCollectionWithProduct = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      brand_id
      name
      description
      image_key
      video_key
      productCollections(limit: 1000) {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          createdAt
          updatedAt
          product {
            id
            product_name
            product_number
            product_brand_id
            product_content
            product_description
            product_owner
            product_wholesale_rate
            product_sale_wholesale_rate
            product_retail_price
            product_price
            is_open_price
            product_color
            product_size
            product_jancode
            product_stock_quantity
            product_type
            product_public_status
            product_category
            product_subcategory
            product_minimum_quantity
            product_estimated_ship_date_min
            product_estimated_ship_date_max
            product_order_lot
            product_preorder
            product_preorder_shipping_date
            product_display_order
            createdAt
            sort_recommended
            suspended
            updatedAt
            product_colors
            product_sizes
            product_types
            sku
            out_of_stock
            continue_selling
            limited_publishing_list
            publishedAt
            imageKeys(sortDirection: ASC) {
              items {
                createdAt
                id
                imageKey
                product_id
              }
              nextToken
            }
            brand {
              id
              brand_owner
              brand_name
              brand_type
              brand_content
              brand_imageKey
              brand_icon_imageKey
              brand_publication
              brand_retail_stores_number
              brand_category
              brand_target_age
              brand_target_gender
              brand_website_url
              brand_work_with_big_box
              brand_address
              brand_minimum_buy
              brand_shippinng_fee
              brand_shippinng_fee_criteria
              brand_instagram_account
              brand_facebook_account
              brand_rating
              brand_annual_turnover
              brand_public_status
              brand_howtoknowhomula
              brand_audit_status
              brand_stripe_id
              brand_first_order_fee
              brand_additional_order_fee
              brand_first_order_fee_referral
              brand_additional_order_fee_referral
              exclude_zip_code
              createdAt
              owner
              updatedAt
              is_sale_brand
              individual_minimum_buy
              individual_wholesale_rate
              individual_shippinng_fee
              individual_shippinng_fee_criteria
              individual_first_order_fee
              individual_additional_order_fee
            }
            discount: contacts {
              items {
                discount_rate
              }
            }
            producttype(
              filter: { suspended: { ne: true }, published: { ne: false } }
            ) {
              items {
                id
                product_id
                imageKey
                type_name
                color
                size
                createdAt
                suspended
                published
                owner
                updatedAt
                out_of_stock
              }
              nextToken
            }
          }
          productType {
            id
            product_id
            imageKey
            type_name
            createdAt
            suspended
            updatedAt
            color
            size
            published
            sku
            out_of_stock
          }
        }
        nextToken
      }
      published
      preorder
      is_consign
      season
      season_year
      createdAt
      updatedAt
      brand {
        sell_only_direct
        brand_owner
      }
    }
  }
`;
export const getCollectionWithProductPreview = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      brand_id
      name
      description
      image_key
      video_key
      productCollections(limit: 1000) {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          createdAt
          updatedAt
          product {
            id
            product_name
            product_number
            product_brand_id
            product_content
            product_description
            product_owner
            product_wholesale_rate
            product_sale_wholesale_rate
            product_retail_price
            product_price
            is_open_price
            product_color
            product_size
            product_jancode
            product_stock_quantity
            product_type
            product_public_status
            product_category
            product_subcategory
            product_minimum_quantity
            product_estimated_ship_date_min
            product_estimated_ship_date_max
            product_order_lot
            product_preorder
            product_preorder_shipping_date
            product_display_order
            createdAt
            sort_recommended
            suspended
            updatedAt
            product_colors
            product_sizes
            product_types
            sku
            out_of_stock
            continue_selling
            publishedAt
            imageKeys(sortDirection: ASC) {
              items {
                createdAt
                id
                imageKey
                product_id
              }
              nextToken
            }
            brand {
              id
              brand_owner
              brand_name
              brand_type
              brand_content
              brand_imageKey
              brand_icon_imageKey
              brand_publication
              brand_retail_stores_number
              brand_category
              brand_target_age
              brand_target_gender
              brand_website_url
              brand_work_with_big_box
              brand_address
              brand_minimum_buy
              brand_shippinng_fee
              brand_shippinng_fee_criteria
              brand_instagram_account
              brand_facebook_account
              brand_rating
              brand_annual_turnover
              brand_public_status
              brand_howtoknowhomula
              brand_audit_status
              brand_stripe_id
              brand_first_order_fee
              brand_additional_order_fee
              brand_first_order_fee_referral
              brand_additional_order_fee_referral
              exclude_zip_code
              createdAt
              owner
              updatedAt
              is_sale_brand
            }
            producttype(
              filter: { suspended: { ne: true }, published: { ne: false } }
            ) {
              items {
                id
                product_id
                imageKey
                type_name
                color
                size
                createdAt
                suspended
                published
                owner
                updatedAt
                sku
                out_of_stock
              }
              nextToken
            }
          }
          productType {
            id
            product_id
            imageKey
            type_name
            createdAt
            suspended
            updatedAt
            color
            size
            published
            sku
            out_of_stock
          }
        }
        nextToken
      }
      published
      preorder
      is_consign
      season
      season_year
      createdAt
      updatedAt
      brand {
        sell_only_direct
        brand_owner
      }
    }
  }
`;
export const getCollectionWithProductUnAuth = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      brand_id
      name
      description
      image_key
      video_key
      productCollections(limit: 1000) {
        items {
          id
          collection_id
          product_id
          product_type_id
          quantity
          sort_order
          createdAt
          updatedAt
          product {
            id
            product_name
            product_number
            product_brand_id
            product_content
            product_description
            product_owner
            product_retail_price
            is_open_price
            product_color
            product_size
            product_jancode
            product_stock_quantity
            product_type
            product_public_status
            product_category
            product_subcategory
            product_minimum_quantity
            product_estimated_ship_date_min
            product_estimated_ship_date_max
            product_order_lot
            product_preorder
            product_preorder_shipping_date
            product_display_order
            createdAt
            sort_recommended
            suspended
            updatedAt
            publishedAt
            product_colors
            product_sizes
            product_types
            sku
            out_of_stock
            continue_selling
            imageKeys(sortDirection: ASC) {
              items {
                createdAt
                id
                imageKey
                product_id
              }
              nextToken
            }
            brand {
              id
              brand_owner
              brand_name
              brand_type
              brand_content
              brand_imageKey
              brand_icon_imageKey
              brand_publication
              brand_retail_stores_number
              brand_category
              brand_target_age
              brand_target_gender
              brand_website_url
              brand_work_with_big_box
              brand_address
              brand_instagram_account
              brand_facebook_account
              brand_rating
              brand_annual_turnover
              brand_public_status
              brand_howtoknowhomula
              brand_audit_status
              exclude_zip_code
              createdAt
              owner
              updatedAt
              is_sale_brand
              individual_minimum_buy
              individual_wholesale_rate
              individual_shippinng_fee
              individual_shippinng_fee_criteria
              individual_first_order_fee
              individual_additional_order_fee
            }
            producttype(
              filter: { suspended: { ne: true }, published: { ne: false } }
            ) {
              items {
                id
                product_id
                imageKey
                type_name
                color
                size
                createdAt
                suspended
                published
                owner
                updatedAt
                sku
                out_of_stock
              }
              nextToken
            }
          }
          productType {
            id
            product_id
            imageKey
            type_name
            createdAt
            suspended
            updatedAt
            color
            size
            published
            sku
            out_of_stock
          }
        }
        nextToken
      }
      published
      preorder
      is_consign
      season
      season_year
      createdAt
      updatedAt
      brand {
        sell_only_direct
        brand_owner
      }
    }
  }
`;
export const searchReturnProducts = /* GraphQL */ `
  query SearchReturnProducts(
    $filter: SearchableReturnProductFilterInput
    $sort: SearchableReturnProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReturnProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        orderproduct {
          product_id
          product_type_id
          product {
            product_brand_id
            product_name
            product_number
            out_of_stock
            continue_selling
            copied_product_id
            copied_brand_id
          }
          productType {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            out_of_stock
            copied_product_type_id
          }
        }
        order {
          id
          brand_id
          buyer {
            items {
              shop_name
            }
          }
          brand {
            id
            brand_name
            return_to_brand
          }
          stripe_payment_id
          fee
          tax
          is_consign
          payment_term
          orderproducts {
            items {
              id
              order_id
              product_id
              product_type_id
              orderstatus {
                items {
                  id
                  order_product_id
                  status
                  createdAt
                  updatedAt
                  owners
                  quantity
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        children {
          items {
            return_status
            return_quantity
            createdAt
          }
        }
        order_product_id
        return_product_id
        return_product_price
        return_quantity
        return_status
        stripe_payment_id
        createdAt
        order_id
        owners
      }
      nextToken
    }
  }
`;
export const getOrdersByDateRangeWithProducts = /* GraphQL */ `
  query GetOrdersByDateRange($owner: String!, $from: String, $to: String) {
    getOrdersByDateRange(owner: $owner, from: $from, to: $to) {
      items {
        id
        order_owner
        brand_id
        brand_owner
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        campaign_code
        buyer {
          items {
            id
            account_id
            account {
              id
              first_name
              last_name
              web_site_URL
              instagram_account
              location
              company_name
              term_approval_status
              createdAt
              updatedAt
              owner
            }
            buyer_id
            shop_name
            owner_name
            buyer_type
            buyer_rating
            buyer_credit
            primary_shipping_address_id
            shipping_address {
              id
              name
              buyer_id
              zip_code
              prefecture
              city
              building
              phone_number
              createdAt
              updatedAt
            }
            stripe_id
            primary_payment_method_id
            primary_payment_term
            payment_type
            billing_close_day
            createdAt
            updatedAt
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          updatedAt
        }
        chargeStatuses {
          nextToken
        }
        orderproducts {
          items {
            id
            order_id
            product_id
            product_type_id
            order_product_quantity
            order_product_price
            order_product_wholesale_rate
            order_product_payment_status
            order_product_payment_method
            order_product_return_reason
            owners
            createdAt
            updatedAt
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_color
              product_size
              product_jancode
              product_stock_quantity
              product_type
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_display_order
              product_colors
              product_sizes
              product_types
              createdAt
              sort_recommended
              suspended
              owner
              updatedAt
              out_of_stock
              continue_selling
            }
            productType {
              id
              product_id
              imageKey
              type_name
              createdAt
              suspended
              owner
              updatedAt
              color
              size
              published
              out_of_stock
            }
            orderstatus {
              nextToken
            }
          }
          nextToken
        }
        returnproducts {
          nextToken
        }
        is_direct
        transferred_date
        origin_order_id
      }
      nextToken
    }
  }
`;
export const getOrdersWithProducts = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        order_owner
        brand_id
        brand_owner
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        campaign_code
        is_consign
        buyer {
          items {
            id
            account_id
            account {
              id
              first_name
              last_name
              web_site_URL
              instagram_account
              location
              company_name
              term_approval_status
              createdAt
              updatedAt
              owner
            }
            buyer_id
            shop_name
            owner_name
            buyer_type
            buyer_rating
            buyer_credit
            primary_shipping_address_id
            shipping_address {
              id
              name
              buyer_id
              zip_code
              prefecture
              city
              building
              phone_number
              createdAt
              updatedAt
            }
            stripe_id
            primary_payment_method_id
            primary_payment_term
            payment_type
            billing_close_day
            createdAt
            updatedAt
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          updatedAt
        }
        chargeStatuses {
          nextToken
        }
        orderproducts(limit: 1000) {
          items {
            id
            order_id
            product_id
            product_type_id
            order_product_quantity
            order_product_price
            order_product_wholesale_rate
            order_product_payment_status
            order_product_payment_method
            order_product_return_reason
            owners
            createdAt
            updatedAt
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_color
              product_size
              product_jancode
              product_stock_quantity
              product_type
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_display_order
              product_colors
              product_sizes
              product_types
              createdAt
              sort_recommended
              suspended
              owner
              updatedAt
              out_of_stock
              continue_selling
            }
            productType {
              id
              product_id
              imageKey
              type_name
              createdAt
              suspended
              owner
              updatedAt
              color
              size
              published
              out_of_stock
            }
            orderstatus {
              items {
                order_product_id
                status
                owners
                createdAt
                updatedAt
                quantity
              }
            }
          }
          nextToken
        }
        returnproducts(filter: { return_status: { eq: 0 } }, limit: 1000) {
          items {
            id
            return_product_id
            order_id
            order_product_id
            return_quantity
            return_product_price
            return_status
            stripe_payment_id
            mf_transaction_id
            owners
            createdAt
            updatedAt
            order {
              id
              order_owner
              brand_id
              brand_owner
              campaign_code
              createdAt
              updatedAt
              owners
              stripe_payment_id
              stripe_client_secret
              stripe_payment_method_id
              fee
              shipping_fee
              invoice_shipping_fee
              tax
              payment_term
              shipping_zip_code
              shipping_address
              shipping_name
              shipping_phone_number
              shipping_date
              carrier
              tracking_number
              first_order
              order_points
              is_direct
              is_consign
              transferred_date
              order_price
              origin_order_id
              mf_authorization_id
              mf_transaction_id
              mf_canceled_transaction_id
              cashback
            }
            orderproduct {
              id
              order_id
              product_id
              product_type_id
              order_product_quantity
              order_product_price
              order_product_wholesale_rate
              order_product_payment_status
              order_product_payment_method
              order_product_return_reason
              owners
              createdAt
              updatedAt
            }
            children {
              nextToken
            }
          }
          nextToken
        }
        is_direct
        transferred_date
        origin_order_id
      }
      nextToken
    }
  }
`;
export const listPointsByAccountWithHistory = /* GraphQL */ `
  query ListPointsByAccount(
    $account_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointsByAccount(
      account_id: $account_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        points
        point_type
        order_id
        referral_id
        referral_brand_id
        createdAt
        duration
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          updatedAt
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
        }
        referral {
          id
          referral_type
          referral_email
          referral_name
          referral_website
          referral_domain
          referral_by
          referral_status
          referral_brand_id
          referral_account_id
          createdAt
          owner
          updatedAt
        }
        history(limit: 1000) {
          items {
            id
            point_id
            account_id
            points
            history_type
            order_id
            createdAt
            updatedAt
            order {
              id
              order_owner
              brand_id
              brand_owner
              createdAt
              updatedAt
              owners
              stripe_payment_id
              stripe_client_secret
              stripe_payment_method_id
              fee
              shipping_fee
              invoice_shipping_fee
              tax
              payment_term
              shipping_zip_code
              shipping_address
              shipping_name
              shipping_phone_number
              shipping_date
              carrier
              tracking_number
              first_order
              order_points
              campaign_code
              is_direct
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPublishedProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      createdAt
      sort_recommended
      suspended
      owner
      updatedAt
      out_of_stock
      continue_selling
      limited_publishing_list
      sku
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      videoKeys(sortDirection: ASC) {
        items {
          createdAt
          id
          videoKey
          product_id
        }
        nextToken
      }
      producttype(
        filter: { suspended: { ne: true }, published: { ne: false } }
      ) {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          owner
          updatedAt
          out_of_stock
          sku
        }
        nextToken
      }
      brand {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        createdAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        terms_of_sample
        stockists {
          stockist
          address
        }
        featured_in
        maximum_wholesale_rate
        return_to_brand
        exclude_zip_code
        is_sale_brand
        updatedAt
        product {
          nextToken
        }
        reviews {
          items {
            rate
          }
        }
        sell_only_direct
        individual_minimum_buy
        individual_wholesale_rate
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
      }
      discount: contacts {
        items {
          discount_rate
        }
      }
      collections {
        items {
          collection_id
        }
      }
    }
  }
`;
export const getPublishedProductPreview = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_wholesale_rate
      product_sale_wholesale_rate
      product_retail_price
      product_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      createdAt
      sort_recommended
      suspended
      owner
      updatedAt
      sku
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      videoKeys(sortDirection: ASC) {
        items {
          createdAt
          id
          videoKey
          product_id
        }
        nextToken
      }
      producttype(
        filter: { suspended: { ne: true }, published: { ne: false } }
      ) {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          owner
          updatedAt
          sku
        }
        nextToken
      }
      brand {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        createdAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
        }
        featured_in
        maximum_wholesale_rate
        return_to_brand
        exclude_zip_code
        is_sale_brand
        updatedAt
        product {
          nextToken
        }
        reviews {
          items {
            rate
          }
        }
        sell_only_direct
      }
      collections {
        items {
          collection_id
        }
      }
    }
  }
`;
export const getPublishedProductUnAuth = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      product_name
      product_number
      product_brand_id
      product_content
      product_description
      product_owner
      product_retail_price
      is_open_price
      product_jancode
      product_stock_quantity
      product_type
      product_color
      product_size
      product_public_status
      product_category
      product_subcategory
      product_minimum_quantity
      product_estimated_ship_date_min
      product_estimated_ship_date_max
      product_order_lot
      product_preorder
      product_preorder_shipping_date
      product_types
      product_colors
      product_sizes
      product_display_order
      createdAt
      sort_recommended
      suspended
      owner
      updatedAt
      sku
      imageKeys {
        items {
          id
          product_id
          imageKey
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      videoKeys(sortDirection: ASC) {
        items {
          createdAt
          id
          videoKey
          product_id
        }
        nextToken
      }
      producttype(
        filter: { suspended: { ne: true }, published: { ne: false } }
      ) {
        items {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          owner
          updatedAt
          sku
        }
        nextToken
      }
      brand {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        createdAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        stockists {
          stockist
          address
        }
        featured_in
        exclude_zip_code
        is_sale_brand
        updatedAt
        product {
          nextToken
        }
        reviews {
          items {
            rate
          }
        }
        sell_only_direct
        individual_minimum_buy
        individual_wholesale_rate
        individual_shippinng_fee
        individual_shippinng_fee_criteria
        individual_first_order_fee
        individual_additional_order_fee
      }
      collections {
        items {
          collection_id
        }
      }
    }
  }
`;
export const getOrderProductsByDateRange = /* GraphQL */ `
  query GetOrderProductsByDateRange(
    $owner: String!
    $fromDate: String
    $toDate: String
    $limit: Int
    $nextToken: String
  ) {
    getOrderProductsByDateRange(
      owner: $owner
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order_id
        product_id
        product_type_id
        order_product_quantity
        order_product_price
        order_product_wholesale_rate
        order_product_payment_status
        order_product_payment_method
        order_product_return_reason
        owners
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          createdAt
          sort_recommended
          suspended
          owner
          updatedAt
          out_of_stock
          continue_selling
        }
        productType {
          id
          product_id
          imageKey
          type_name
          color
          size
          createdAt
          suspended
          published
          owner
          updatedAt
          out_of_stock
        }
        order {
          id
          order_owner
          brand_id
          brand_owner
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          campaign_code
          is_direct
          transferred_date
          brand {
            id
            brand_owner
            brand_name
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_audit_status
            brand_stripe_id
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            createdAt
            owner
            designed_in
            established
            tagIds
            tags
            brand_collections
            movie_url
            brand_modal_imageKeys
            brand_online_sale
            product_image_reprint
            brand_logo_reprint
            post_to_sns
            allow_direct_shipping
            allow_sell_before_buy
            other_terms
            featured_in
            maximum_wholesale_rate
            return_to_brand
            exclude_zip_code
            is_sale_brand
            updatedAt
          }
          buyer {
            items {
              id
              account_id
              buyer_id
              owner_name
              shop_name
              buyer_type
              buyer_product_category
              buyer_brand
              buyer_rating
              buyer_kyc_status
              buyer_credit
              buyer_return_credit
              primary_shipping_address_id
              stripe_id
              primary_payment_method_id
              primary_payment_term
              referral_brand_id
              payment_type
              billing_close_day
              createdAt
              updatedAt
              owner
            }
          }
        }
        orderstatus {
          items {
            order_product_id
            status
            owners
            createdAt
            updatedAt
            quantity
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const searchContactsBySupplierWithShippingAddress = /* GraphQL */ `
  query SearchContactsBySupplierWithShippingAddress(
    $owner_id: ID
    $filter: SearchableContactFilterInput
    $sort: SearchableContactSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        supplier_id
        buyer_id
        buyer_owner
        email
        store_name
        contact_name
        tel
        discount_rate
        direct_payment
        contact_status
        contact_source
        contacted
        signedup
        ordered
        invited
        createdAt
        updatedAt
        buyerInfo {
          id
          account_id
          buyer_id
          owner_name
          shop_name
          buyer_type
          buyer_product_category
          buyer_brand
          buyer_rating
          buyer_kyc_status
          buyer_credit
          buyer_return_credit
          buyer_return_limit_per_brand
          primary_shipping_address_id
          stripe_id
          primary_payment_method_id
          primary_payment_term
          referral_brand_id
          payment_type
          billing_close_day
          createdAt
          updatedAt
          owner
          account {
            id
            email
            first_name
            last_name
            web_site_URL
            instagram_account
            zip_code
            location
            company_name
            term_approval_status
            createdAt
            updatedAt
            owner
          }
          shipping_address {
            id
            name
            buyer_id
            zip_code
            prefecture
            city
            building
            phone_number
            createdAt
            updatedAt
          }
        }
        supplierInfo {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
        }
        orders(filter: { brand_owner: { eq: $owner_id } }, limit: 1000) {
          items {
            id
          }
        }
        pageViews {
          items {
            page_view
          }
        }
        pageViewsByEmail {
          items {
            page_view
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const searchContactSegments = /* GraphQL */ `
  query SearchContactSegments($supplier_id: ID!) {
    all: listContactsBySupplier(supplier_id: $supplier_id, limit: 1000) {
      items {
        id
      }
    }
    signedup: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { signedup: { eq: true } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    notcontacted: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { contacted: { ne: true } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    contacted: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { contacted: { eq: true } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    ordered: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { ordered: { eq: true } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    notyet: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { signedup: { ne: true } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    market: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { contact_status: { eq: "marketplace" } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    direct: listContactsBySupplier(
      supplier_id: $supplier_id
      filter: { contact_status: { eq: "direct" } }
      limit: 1000
    ) {
      items {
        id
      }
    }
  }
`;
export const getContactWithOrders = /* GraphQL */ `
  query GetContact($id: ID!, $owner_id: ID!) {
    getContact(id: $id) {
      id
      supplier_id
      buyer_id
      buyer_owner
      email
      store_name
      contact_name
      tel
      zipcode
      address
      discount_rate
      direct_payment
      contact_status
      contact_source
      contacted
      signedup
      ordered
      createdAt
      updatedAt
      buyerInfo {
        id
        account_id
        buyer_id
        owner_name
        shop_name
        buyer_type
        buyer_product_category
        buyer_brand
        buyer_rating
        buyer_kyc_status
        buyer_credit
        buyer_return_credit
        buyer_return_limit_per_brand
        primary_shipping_address_id
        stripe_id
        primary_payment_method_id
        primary_payment_term
        referral_brand_id
        payment_type
        billing_close_day
        createdAt
        updatedAt
        owner
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
        }
      }
      buyerAccount {
        id
        first_name
        last_name
        web_site_URL
        instagram_account
        location
        company_name
        term_approval_status
        createdAt
        updatedAt
        owner
      }
      supplierInfo {
        id
        account_id
        supplier_contract_type
        bank_name
        bank_branch_name
        bank_account_number
        bank_account_name
        bank_account_type
        createdAt
        updatedAt
        owner
      }
      orders(
        filter: { brand_owner: { eq: $owner_id } }
        sortDirection: DESC
        limit: 100
      ) {
        items {
          id
          order_owner
          brand_id
          brand_owner
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          campaign_code
          is_direct
          transferred_date
          order_price
          brand {
            brand_name
            brand_shippinng_fee
            brand_shippinng_fee_criteria
          }
          orderproducts(filter: { order_product_quantity: { ne: 0 } }) {
            items {
              id
              order_id
              product_id
              product_type_id
              order_product_quantity
              order_product_price
              order_product_wholesale_rate
              order_product_payment_status
              order_product_payment_method
              order_product_return_reason
              orderstatus {
                items {
                  id
                  order_product_id
                  status
                  createdAt
                  updatedAt
                  owners
                  quantity
                }
                nextToken
              }
              product {
                id
                product_name
                product_number
                product_brand_id
                product_content
                product_description
                product_owner
                product_wholesale_rate
                product_sale_wholesale_rate
                product_retail_price
                product_price
                is_open_price
                product_color
                product_size
                product_jancode
                product_stock_quantity
                product_type
                product_public_status
                product_category
                product_subcategory
                product_minimum_quantity
                product_estimated_ship_date_min
                product_estimated_ship_date_max
                product_order_lot
                product_preorder
                product_display_order
                product_colors
                product_sizes
                product_types
                out_of_stock
                continue_selling
                imageKeys {
                  items {
                    createdAt
                    id
                    imageKey
                    product_id
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                suspended
              }
              productType {
                id
                product_id
                imageKey
                type_name
                createdAt
                updatedAt
                suspended
                color
                size
                published
                out_of_stock
              }
              createdAt
              updatedAt
              owners
            }
            nextToken
          }
          payment_term
          stripe_payment_id
          stripe_client_secret
          fee
          shipping_fee
          tax
          shipping_date
          carrier
          tracking_number
          updatedAt
          owners
          returnproducts {
            items {
              id
              order_id
              order_product_id
              return_quantity
              return_product_price
              return_status
              stripe_payment_id
              owners
              createdAt
              updatedAt
            }
            nextToken
          }
          order_points
          campaign_code
          is_direct
          transferred_date
        }
      }
      activities(sortDirection: DESC, limit: 100) {
        items {
          id
          contact_id
          activity_date
          activity_type
          order_id
          message_id
          campaign_id
          activity
          createdAt
          updatedAt
          owner
          order {
            order_price
          }
          campaign {
            campaign_title
          }
        }
      }
      pageViews {
        items {
          brand_id
          buyer_id
          page_view
        }
      }
      pageViewsByEmail {
        items {
          brand_id
          buyer_id
          page_view
        }
      }
      owner
    }
  }
`;
export const listBuyerInfosByBuyerWithContact = /* GraphQL */ `
  query ListBuyerInfosByBuyer(
    $buyer_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBuyerInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuyerInfosByBuyer(
      buyer_id: $buyer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        buyer_id
        owner_name
        shop_name
        buyer_type
        buyer_product_category
        buyer_brand
        buyer_rating
        buyer_kyc_status
        buyer_credit
        buyer_return_credit
        buyer_return_limit_per_brand
        primary_shipping_address_id
        stripe_id
        primary_payment_method_id
        primary_payment_term
        referral_brand_id
        payment_type
        billing_close_day
        mf_customer_id
        mf_destination_id
        mf_examination_status
        createdAt
        updatedAt
        owner
        store_category
        item_category
        metadata
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
        }
        shipping_address {
          id
          name
          buyer_id
          zip_code
          prefecture
          city
          building
          phone_number
          createdAt
          updatedAt
        }
        contacts(limit: 1000) {
          items {
            id
            contact_status
            discount_rate
            direct_payment
            brand_owner: owner
            contactListConnections {
              items {
                contact_list_id
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const createShopCart = /* GraphQL */ `
  mutation CreateShopCart(
    $input: CreateShopCartInput!
    $condition: ModelShopCartConditionInput
  ) {
    createShopCart(input: $input, condition: $condition) {
      id
      shopcart_owner
      shopcart_quantity
      product_id
      product_type_id
      brand_id
      is_direct
      is_pay_directly
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        createdAt
        sort_recommended
        suspended
        owner
        updatedAt
        out_of_stock
        continue_selling
        imageKeys {
          nextToken
        }
        producttype {
          items {
            out_of_stock
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          updatedAt
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
        }
        discount: contacts {
          items {
            discount_rate
          }
          nextToken
        }
      }
      producttype {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        owner
        updatedAt
        out_of_stock
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          createdAt
          sort_recommended
          suspended
          owner
          updatedAt
          out_of_stock
          continue_selling
        }
      }
      owner
    }
  }
`;
export const updateShopCart = /* GraphQL */ `
  mutation UpdateShopCart(
    $input: UpdateShopCartInput!
    $condition: ModelShopCartConditionInput
  ) {
    updateShopCart(input: $input, condition: $condition) {
      id
      shopcart_owner
      shopcart_quantity
      product_id
      product_type_id
      brand_id
      is_direct
      is_pay_directly
      createdAt
      updatedAt
      product {
        id
        product_name
        product_number
        product_brand_id
        product_content
        product_description
        product_owner
        product_wholesale_rate
        product_sale_wholesale_rate
        product_retail_price
        product_price
        is_open_price
        product_jancode
        product_stock_quantity
        product_type
        product_color
        product_size
        product_public_status
        product_category
        product_subcategory
        product_minimum_quantity
        product_estimated_ship_date_min
        product_estimated_ship_date_max
        product_order_lot
        product_preorder
        product_preorder_shipping_date
        product_types
        product_colors
        product_sizes
        product_display_order
        createdAt
        sort_recommended
        suspended
        owner
        updatedAt
        out_of_stock
        continue_selling
        imageKeys {
          nextToken
        }
        producttype {
          items {
            out_of_stock
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          updatedAt
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
        }
        discount: contacts {
          items {
            discount_rate
          }
          nextToken
        }
      }
      producttype {
        id
        product_id
        imageKey
        type_name
        color
        size
        createdAt
        suspended
        published
        owner
        updatedAt
        out_of_stock
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          createdAt
          sort_recommended
          suspended
          owner
          updatedAt
          out_of_stock
          continue_selling
        }
      }
      owner
    }
  }
`;
export const getCampaignSegments = /* GraphQL */ `
  query GetCampaignSegments($supplier_id: ID!) {
    all: searchCampaigns(
      filter: { supplier_id: { eq: $supplier_id } }
      limit: 1000
    ) {
      items {
        id
      }
    }
    draft: searchCampaigns(
      filter: {
        supplier_id: { eq: $supplier_id }
        campaign_status: { eq: "draft" }
      }
      limit: 1000
    ) {
      items {
        id
      }
    }
    scheduled: searchCampaigns(
      filter: {
        supplier_id: { eq: $supplier_id }
        campaign_status: { eq: "scheduled" }
      }
      limit: 1000
    ) {
      items {
        id
      }
    }
    completed: searchCampaigns(
      filter: {
        supplier_id: { eq: $supplier_id }
        or: [
          { campaign_status: { eq: "completed" } }
          { campaign_status: { eq: "sending" } }
        ]
      }
      limit: 1000
    ) {
      items {
        id
      }
    }
  }
`;
export const searchCampaignsBySupplierWithContacts = /* GraphQL */ `
  query SearchCampaigns(
    $filter: SearchableCampaignFilterInput
    $sort: SearchableCampaignSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaigns(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        supplier_id
        brand_id
        campaign_title
        campaign_status
        campaign_subject
        preview_text
        campaign_template
        campaign_segments
        deliveryTime
        sentAt
        createdAt
        owner
        updatedAt
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          updatedAt
        }
        contacts(limit: 1000) {
          items {
            id
            supplier_id
            campaign_id
            contact_id
            source
            status
            clicks
            views
            owner
            createdAt
            updatedAt
            contact {
              id
              supplier_id
              buyer_id
              buyer_owner
              email
              store_name
              contact_name
              tel
              discount_rate
              contact_status
              contact_source
              direct_payment
              contacted
              signedup
              ordered
              createdAt
              owner
              updatedAt
            }
          }
          nextToken
        }
        stats {
          total
          sent
          unsent
          delivered
          open
          click
          bounce
          openRate
          clickRate
        }
      }
      nextToken
    }
  }
`;
export const listCampaignContactsWithShippingAddressByCampaign = /* GraphQL */ `
  query ListCampaignContactsByCampaign(
    $campaign_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignContactsByCampaign(
      campaign_id: $campaign_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplier_id
        campaign_id
        contact_id
        source
        status
        clicks
        views
        owner
        createdAt
        updatedAt
        contact {
          id
          supplier_id
          buyer_id
          buyer_owner
          email
          store_name
          contact_name
          tel
          discount_rate
          contact_status
          contact_source
          direct_payment
          contacted
          signedup
          ordered
          createdAt
          owner
          updatedAt
          buyerInfo {
            id
            account_id
            buyer_id
            owner_name
            shop_name
            buyer_type
            buyer_product_category
            buyer_brand
            buyer_rating
            buyer_kyc_status
            buyer_credit
            buyer_return_credit
            buyer_return_limit_per_brand
            primary_shipping_address_id
            stripe_id
            primary_payment_method_id
            primary_payment_term
            referral_brand_id
            payment_type
            billing_close_day
            createdAt
            updatedAt
            owner
            account {
              id
              email
              first_name
              last_name
              web_site_URL
              instagram_account
              zip_code
              location
              company_name
              term_approval_status
              createdAt
              updatedAt
              owner
            }
            shipping_address {
              id
              name
              buyer_id
              zip_code
              prefecture
              city
              building
              phone_number
              createdAt
              updatedAt
            }
          }
        }
        campaign {
          id
          supplier_id
          brand_id
          campaign_title
          campaign_status
          campaign_subject
          preview_text
          campaign_template
          campaign_message
          campaign_components
          campaign_style
          campaign_segments
          deliveryTime
          sentAt
          createdAt
          owner
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchCampaignContactSegments = /* GraphQL */ `
  query SearchCampaignContactSegments($supplier_id: ID!, $campaign_id: ID!) {
    all: listContactsAllBySupplier(supplier_id: $supplier_id) {
      items {
        id
      }
    }
    signedup: listContactsAllBySupplier(
      supplier_id: $supplier_id
      segment: "signedup"
    ) {
      items {
        id
      }
    }
    contacted: listContactsAllBySupplier(
      supplier_id: $supplier_id
      segment: "contacted"
    ) {
      items {
        id
      }
    }
    ordered: listContactsAllBySupplier(
      supplier_id: $supplier_id
      segment: "ordered"
    ) {
      items {
        id
      }
    }
    notyet: listContactsAllBySupplier(
      supplier_id: $supplier_id
      segment: "notyet"
    ) {
      items {
        id
      }
    }
    market: listContactsAllBySupplier(
      supplier_id: $supplier_id
      segment: "marketplace"
    ) {
      items {
        id
      }
    }
    direct: listContactsAllBySupplier(
      supplier_id: $supplier_id
      segment: "direct"
    ) {
      items {
        id
      }
    }
    manual: listCampaignContactsByCampaign(
      campaign_id: $campaign_id
      filter: { source: { eq: "manual" } }
      limit: 1000
    ) {
      items {
        id: contact_id
      }
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      supplier_id
      brand_id
      campaign_sender
      campaign_from
      campaign_title
      campaign_status
      campaign_subject
      preview_text
      campaign_template
      campaign_message
      campaign_components
      campaign_style
      campaign_segments
      deliveryTime
      sentAt
      createdAt
      owner
      updatedAt
      brand {
        id
        brand_owner
        brand_name
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        createdAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
        }
        featured_in
        maximum_wholesale_rate
        return_to_brand
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        updatedAt
        account {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
        }
        product(sortDirection: DESC, limit: 4) {
          items {
            id
            product_name
            product_number
            product_brand_id
            product_content
            product_description
            product_owner
            product_wholesale_rate
            product_sale_wholesale_rate
            product_retail_price
            product_price
            is_open_price
            product_jancode
            product_stock_quantity
            product_type
            product_color
            product_size
            product_public_status
            product_category
            product_subcategory
            product_minimum_quantity
            product_estimated_ship_date_min
            product_estimated_ship_date_max
            product_order_lot
            product_preorder
            product_preorder_shipping_date
            product_types
            product_colors
            product_sizes
            product_display_order
            createdAt
            updatedAt
            sort_recommended
            suspended
            owner
            imageKeys {
              items {
                id
                product_id
                imageKey
                createdAt
                owner
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      contacts(limit: 1000) {
        items {
          id
          supplier_id
          campaign_id
          contact_id
          email
          source
          status
          clicks
          views
          sentAt
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      stats {
        total
        sent
        unsent
        delivered
        open
        click
        bounce
        openRate
        clickRate
      }
    }
  }
`;
export const listReportProductsByTypeWithProduct = /* GraphQL */ `
  query ListReportProductsByStatus(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportProductsByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          transferred_date
          order_price
          buyer {
            items {
              account_id
              buyer_brand
              buyer_credit
              buyer_kyc_status
              buyer_product_category
              buyer_id
              buyer_rating
              buyer_type
              buyer_return_credit
              createdAt
              id
              owner
              owner_name
              primary_payment_method_id
              primary_payment_term
              primary_shipping_address_id
              referral_brand_id
              shop_name
              stripe_id
              payment_type
              billing_close_day
              updatedAt
              shipping_address {
                building
                buyer_id
                city
                createdAt
                id
                name
                phone_number
                prefecture
                zip_code
                updatedAt
              }
            }
          }
          brand {
            id
            brand_owner
            brand_name
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_stripe_id
            brand_audit_status
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            exclude_zip_code
            is_sale_brand
          }
          createdAt
          updatedAt
          owners
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          transferred_date
          order_price
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
          product {
            id
            product_name
            product_number
            product_brand_id
            product_content
            product_description
            product_owner
            product_wholesale_rate
            product_sale_wholesale_rate
            product_retail_price
            product_price
            is_open_price
            product_jancode
            product_stock_quantity
            product_type
            product_color
            product_size
            product_public_status
            product_category
            product_subcategory
            product_minimum_quantity
            product_estimated_ship_date_min
            product_estimated_ship_date_max
            product_order_lot
            product_preorder
            product_preorder_shipping_date
            product_types
            product_colors
            product_sizes
            product_display_order
            createdAt
            sort_recommended
            suspended
            owner
            updatedAt
            out_of_stock
            continue_selling
          }
          productType {
            id
            product_id
            imageKey
            type_name
            createdAt
            updatedAt
            suspended
            color
            size
            out_of_stock
          }
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listOrdersByBrandOwnerWithProduct = /* GraphQL */ `
  query ListOrdersByBrandOwner(
    $brand_owner: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByBrandOwner(
      brand_owner: $brand_owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order_owner
        brand_id
        brand_owner
        campaign_code
        createdAt
        updatedAt
        owners
        stripe_payment_id
        stripe_client_secret
        stripe_payment_method_id
        fee
        shipping_fee
        invoice_shipping_fee
        tax
        payment_term
        shipping_zip_code
        shipping_address
        shipping_name
        shipping_phone_number
        shipping_date
        carrier
        tracking_number
        first_order
        order_points
        is_direct
        is_consign
        transferred_date
        origin_order_id
        buyer {
          items {
            id
            account_id
            buyer_id
            owner_name
            shop_name
            buyer_type
            buyer_product_category
            buyer_brand
            buyer_rating
            buyer_kyc_status
            buyer_credit
            buyer_return_credit
            buyer_return_limit_per_brand
            primary_shipping_address_id
            stripe_id
            primary_payment_method_id
            primary_payment_term
            referral_brand_id
            payment_type
            billing_close_day
            createdAt
            updatedAt
            owner
            account {
              id
              email
              first_name
              last_name
              web_site_URL
              instagram_account
              zip_code
              location
              company_name
              term_approval_status
              createdAt
              updatedAt
              owner
            }
            shipping_address {
              id
              name
              buyer_id
              zip_code
              prefecture
              city
              building
              phone_number
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
          }
          nextToken
        }
        brand {
          id
          brand_owner
          brand_name
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          createdAt
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          featured_in
          maximum_wholesale_rate
          return_to_brand
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          updatedAt
        }
        originOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          transferred_date
          order_price
          origin_order_id
        }
        chargeStatuses {
          items {
            id
            order_id
            status
            createdAt
            owners
            updatedAt
          }
          nextToken
        }
        orderproducts {
          items {
            id
            order_id
            product_id
            product_type_id
            order_product_quantity
            order_product_price
            order_product_wholesale_rate
            order_product_payment_status
            order_product_payment_method
            order_product_return_reason
            owners
            createdAt
            updatedAt
            product {
              id
              product_name
              product_number
              product_brand_id
              product_content
              product_description
              product_owner
              product_wholesale_rate
              product_sale_wholesale_rate
              product_retail_price
              product_price
              is_open_price
              product_jancode
              product_stock_quantity
              product_type
              product_color
              product_size
              product_public_status
              product_category
              product_subcategory
              product_minimum_quantity
              product_estimated_ship_date_min
              product_estimated_ship_date_max
              product_order_lot
              product_preorder
              product_preorder_shipping_date
              product_types
              product_colors
              product_sizes
              product_display_order
              createdAt
              sort_recommended
              suspended
              owner
              updatedAt
              out_of_stock
              continue_selling
            }
            productType {
              id
              product_id
              imageKey
              type_name
              color
              size
              createdAt
              suspended
              published
              owner
              updatedAt
              out_of_stock
            }
            order {
              id
              order_owner
              brand_id
              brand_owner
              campaign_code
              createdAt
              updatedAt
              owners
              stripe_payment_id
              stripe_client_secret
              stripe_payment_method_id
              fee
              shipping_fee
              invoice_shipping_fee
              tax
              payment_term
              shipping_zip_code
              shipping_address
              shipping_name
              shipping_phone_number
              shipping_date
              carrier
              tracking_number
              first_order
              order_points
              is_direct
              transferred_date
              order_price
              origin_order_id
            }
            orderstatus {
              items {
                id
                order_product_id
                status
                owners
                createdAt
                updatedAt
                quantity
              }
              nextToken
            }
            reportProducts {
              nextToken
            }
          }
          nextToken
        }
        returnproducts {
          items {
            id
            return_product_id
            order_id
            order_product_id
            return_quantity
            return_product_price
            return_status
            stripe_payment_id
            owners
            createdAt
            updatedAt
            orderproduct {
              id
              order_id
              product_id
              product_type_id
              order_product_quantity
              order_product_price
              order_product_wholesale_rate
              order_product_payment_status
              order_product_payment_method
              order_product_return_reason
              owners
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reportProducts {
          nextToken
        }
        reviews {
          items {
            id
            brand_id
            product_ids
            buyer_id
            order_id
            rate
            title
            comment
            remove_flag
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchReportProductsWithOrder = /* GraphQL */ `
  query SearchReportProducts(
    $filter: SearchableReportProductFilterInput
    $sort: SearchableReportProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReportProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        order_id
        order_product_id
        report_type
        request_type
        report_quantity
        report_product_price
        report_status
        report_comment
        replace_order_id
        replace_order_product_id
        approval_date
        approval_quantity
        image_keys
        stripe_refund_id
        owners
        createdAt
        updatedAt
        order {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          transferred_date
          order_price
          origin_order_id
          buyer {
            items {
              id
              account_id
              buyer_id
              owner_name
              shop_name
              buyer_type
              buyer_product_category
              buyer_brand
              buyer_rating
              buyer_kyc_status
              buyer_credit
              buyer_return_credit
              buyer_return_limit_per_brand
              primary_shipping_address_id
              stripe_id
              primary_payment_method_id
              primary_payment_term
              referral_brand_id
              payment_type
              billing_close_day
              createdAt
              updatedAt
              owner
              account {
                id
                email
                first_name
                last_name
                web_site_URL
                instagram_account
                zip_code
                location
                company_name
                term_approval_status
                createdAt
                updatedAt
                owner
              }
              shipping_address {
                id
                name
                buyer_id
                zip_code
                prefecture
                city
                building
                phone_number
                createdAt
                updatedAt
              }
              contacts {
                nextToken
              }
            }
            nextToken
          }
          brand {
            id
            brand_owner
            brand_name
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_audit_status
            brand_stripe_id
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            createdAt
            owner
            designed_in
            established
            tagIds
            tags
            brand_collections
            movie_url
            brand_modal_imageKeys
            brand_online_sale
            product_image_reprint
            brand_logo_reprint
            post_to_sns
            allow_direct_shipping
            allow_sell_before_buy
            other_terms
            featured_in
            maximum_wholesale_rate
            return_to_brand
            exclude_zip_code
            is_sale_brand
            hubspot_company_id
            updatedAt
          }
          orderproducts {
            nextToken
          }
        }
        replaceOrder {
          id
          order_owner
          brand_id
          brand_owner
          campaign_code
          createdAt
          updatedAt
          owners
          stripe_payment_id
          stripe_client_secret
          stripe_payment_method_id
          fee
          shipping_fee
          invoice_shipping_fee
          tax
          payment_term
          shipping_zip_code
          shipping_address
          shipping_name
          shipping_phone_number
          shipping_date
          carrier
          tracking_number
          first_order
          order_points
          is_direct
          transferred_date
          order_price
          origin_order_id
        }
        orderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
        }
        replaceOrderproduct {
          id
          order_id
          product_id
          product_type_id
          order_product_quantity
          order_product_price
          order_product_wholesale_rate
          order_product_payment_status
          order_product_payment_method
          order_product_return_reason
          owners
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchReferralsWithBuyerInfo = /* GraphQL */ `
  query SearchReferrals(
    $filter: SearchableReferralFilterInput
    $sort: SearchableReferralSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReferrals(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        referral_type
        referral_email
        referral_name
        referral_website
        referral_domain
        referral_by
        referral_status
        referral_brand_id
        referral_account_id
        createdAt
        owner
        updatedAt
        ownerAccount {
          id
          email
          first_name
          last_name
          web_site_URL
          instagram_account
          zip_code
          location
          tel
          company_name
          term_approval_status
          createdAt
          updatedAt
          owner
          buyerInfo {
            items {
              shop_name
            }
            nextToken
          }
          supplierInfo {
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const searchFavoriteProducts = /* GraphQL */ `
  query SearchFavoriteProducts(
    $filter: SearchableFavoriteProductFilterInput
    $sort: SearchableFavoriteProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFavoriteProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        account_id
        favorite_list_id
        product_id
        order
        createdAt
        updatedAt
        product {
          id
          product_name
          product_number
          product_brand_id
          product_content
          product_description
          product_owner
          product_wholesale_rate
          product_sale_wholesale_rate
          product_retail_price
          product_price
          is_open_price
          product_jancode
          product_stock_quantity
          product_type
          product_color
          product_size
          product_public_status
          product_category
          product_subcategory
          product_minimum_quantity
          product_estimated_ship_date_min
          product_estimated_ship_date_max
          product_order_lot
          product_preorder
          product_preorder_shipping_date
          product_types
          product_colors
          product_sizes
          product_display_order
          createdAt
          updatedAt
          sort_recommended
          suspended
          continue_selling
          out_of_stock
          sku
          copied_product_id
          copied_brand_id
          limited_publishing_list
          owner
          imageKeys {
            items {
              imageKey
            }
            nextToken
          }
          videoKeys {
            nextToken
          }
          producttype(
            sortDirection: ASC
            filter: { suspended: { ne: true }, published: { ne: false } }
          ) {
            items {
              id
              product_id
              imageKey
              type_name
              createdAt
              updatedAt
              suspended
              color
              size
              published
            }
            nextToken
          }
          inventoryConnection {
            nextToken
          }
          brand {
            id
            brand_owner
            brand_name
            brand_yomigana
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_logo_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_additional_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_audit_status
            brand_stripe_id
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            brand_direct_order_fee
            createdAt
            updatedAt
            owner
            designed_in
            established
            tagIds
            tags
            brand_collections
            movie_url
            brand_modal_imageKeys
            brand_online_sale
            product_image_reprint
            brand_logo_reprint
            post_to_sns
            allow_direct_shipping
            allow_sell_before_buy
            other_terms
            featured_in
            maximum_wholesale_rate
            exclude_zip_code
            is_sale_brand
            hubspot_company_id
            return_to_brand
            terms_of_sample
            auto_message_enabled
            auto_message
          }
          collections {
            nextToken
          }
          contacts {
            nextToken
          }
        }
        owner
        favorite_list {
          id
          account_id
          list_name
          order
          createdAt
          updatedAt
          products {
            nextToken
          }
          owner
        }
      }
      nextToken
      total
    }
  }
`;

export const searchIndividuals = /* GraphQL */ `
  query SearchIndividuals(
    $filter: SearchableIndividualFilterInput
    $sort: SearchableIndividualSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchIndividuals(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        brand_id
        buyer_id
        supplier_group_id
        createdAt
        updatedAt
        owner
        buyerInfo {
          id
          account_id
          buyer_id
          owner_name
          shop_name
          store_category
          item_category
          buyer_type
          buyer_product_category
          buyer_brand
          buyer_rating
          buyer_kyc_status
          buyer_credit
          buyer_return_credit
          buyer_return_limit_per_brand
          primary_shipping_address_id
          stripe_id
          primary_payment_method_id
          primary_payment_term
          referral_brand_id
          payment_type
          billing_close_day
          mf_customer_id
          mf_destination_id
          mf_examination_status
          createdAt
          updatedAt
          owner
          metadata
          account {
            id
            email
            first_name
            last_name
            web_site_URL
            instagram_account
            zip_code
            location
            tel
            company_name
            term_approval_status
            createdAt
            updatedAt
            owner
          }
          shipping_address {
            id
            name
            buyer_id
            zip_code
            prefecture
            city
            building
            phone_number
            createdAt
            updatedAt
          }
          referralBrand {
            id
            brand_owner
            brand_name
            brand_yomigana
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_logo_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_additional_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_audit_status
            brand_stripe_id
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            brand_direct_order_fee
            createdAt
            updatedAt
            owner
            designed_in
            established
            tagIds
            tags
            brand_collections
            movie_url
            brand_modal_imageKeys
            brand_online_sale
            product_image_reprint
            brand_logo_reprint
            post_to_sns
            allow_direct_shipping
            allow_sell_before_buy
            other_terms
            featured_in
            maximum_wholesale_rate
            exclude_zip_code
            is_sale_brand
            hubspot_company_id
            return_to_brand
            terms_of_sample
            auto_message_enabled
            auto_message
            sell_only_direct
            supplier_group_id
          }
          contacts {
            nextToken
          }
        }
        supplierGroup {
          id
          title
          description
          image_key
          published
          createdAt
          updatedAt
          owner
          brands {
            items {
              id
              company_name
              brand_owner
              brand_name
              brand_yomigana
              brand_type
              brand_content
              brand_imageKey
              brand_icon_imageKey
              brand_logo_imageKey
              brand_publication
              brand_retail_stores_number
              brand_category
              brand_target_age
              brand_target_gender
              brand_website_url
              brand_work_with_big_box
              brand_address
              brand_minimum_buy
              brand_additional_minimum_buy
              brand_shippinng_fee
              brand_shippinng_fee_criteria
              brand_instagram_account
              brand_facebook_account
              brand_rating
              brand_annual_turnover
              brand_public_status
              brand_howtoknowhomula
              brand_audit_status
              brand_stripe_id
              brand_first_order_fee
              brand_additional_order_fee
              brand_first_order_fee_referral
              brand_additional_order_fee_referral
              brand_direct_order_fee
              createdAt
              createdAtTimestamp
              updatedAt
              updatedAtTimestamp
              owner
              designed_in
              established
              tagIds
              tags
              brand_collections
              movie_url
              brand_modal_imageKeys
              brand_online_sale
              product_image_reprint
              brand_logo_reprint
              post_to_sns
              allow_direct_shipping
              allow_sell_before_buy
              other_terms
              stockists {
                stockist
                address
              }
              featured_in
              maximum_wholesale_rate
              exclude_zip_code
              is_sale_brand
              hubspot_company_id
              return_to_brand
              terms_of_sample
              auto_message_enabled
              auto_message
              sell_only_direct
              is_published
              lastPublishedAt
              lastPublishedAtTimestamp
              category_page_id
              product_category
              product_subcategory
              average_shipping_days
              service_rate
              review_average
              conversion_rate
              sell_through_rate
              reviews
              items
              conversion_rate_tier
              sell_through_rate_tier
              service_rate_rate_tier
              average_shipping_days_tier
              minimum_tier
              items_tier
              contacts_tier
              supplier_group_id
              supplier_group_title
              supplier_group_description
              supplier_group_image_key
              supplier_group_published
              individual_minimum_buy
              individual_wholesale_rate
              individual_shippinng_fee
              individual_shippinng_fee_criteria
              individual_first_order_fee
              individual_additional_order_fee
            }
            nextToken
          }
        }
        brand {
          id
          company_name
          brand_owner
          brand_name
          brand_yomigana
          brand_type
          brand_content
          brand_imageKey
          brand_icon_imageKey
          brand_logo_imageKey
          brand_publication
          brand_retail_stores_number
          brand_category
          brand_target_age
          brand_target_gender
          brand_website_url
          brand_work_with_big_box
          brand_address
          brand_minimum_buy
          brand_additional_minimum_buy
          brand_shippinng_fee
          brand_shippinng_fee_criteria
          brand_instagram_account
          brand_facebook_account
          brand_rating
          brand_annual_turnover
          brand_public_status
          brand_howtoknowhomula
          brand_audit_status
          brand_stripe_id
          brand_first_order_fee
          brand_additional_order_fee
          brand_first_order_fee_referral
          brand_additional_order_fee_referral
          brand_direct_order_fee
          createdAt
          createdAtTimestamp
          updatedAt
          updatedAtTimestamp
          owner
          designed_in
          established
          tagIds
          tags
          brand_collections
          movie_url
          brand_modal_imageKeys
          brand_online_sale
          product_image_reprint
          brand_logo_reprint
          post_to_sns
          allow_direct_shipping
          allow_sell_before_buy
          other_terms
          stockists {
            stockist
            address
          }
          featured_in
          maximum_wholesale_rate
          exclude_zip_code
          is_sale_brand
          hubspot_company_id
          return_to_brand
          terms_of_sample
          auto_message_enabled
          auto_message
          sell_only_direct
          is_published
          lastPublishedAt
          lastPublishedAtTimestamp
          category_page_id
          product_category
          product_subcategory
          average_shipping_days
          service_rate
          review_average
          conversion_rate
          sell_through_rate
          reviews
          items
          conversion_rate_tier
          sell_through_rate_tier
          service_rate_rate_tier
          average_shipping_days_tier
          minimum_tier
          items_tier
          contacts_tier
          supplier_group_id
          supplier_group_title
          supplier_group_description
          supplier_group_image_key
          supplier_group_published
          individual_minimum_buy
          individual_wholesale_rate
          individual_shippinng_fee
          individual_shippinng_fee_criteria
          individual_first_order_fee
          individual_additional_order_fee
          collections {
            nextToken
          }
          contacts {
            nextToken
          }
          review {
            nextToken
          }
          products {
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const getAccountWithShopImages = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      email
      first_name
      last_name
      web_site_URL
      instagram_account
      zip_code
      location
      tel
      company_name
      term_approval_status
      createdAt
      updatedAt
      owner
      buyerInfo {
        items {
          id
          account_id
          billing_close_day
          buyer_brand
          buyer_credit
          buyer_id
          buyer_kyc_status
          buyer_product_category
          buyer_rating
          buyer_return_credit
          buyer_return_limit_per_brand
          buyer_type
          invoice_credit_facility
          item_category
          metadata
          mf_customer_id
          mf_destination_id
          mf_examination_status
          owner
          owner_name
          payment_type
          primary_payment_method_id
          primary_payment_term
          primary_shipping_address_id
          referral_brand_id
          shop_name
          store_category
          stripe_id
          createdAt
          updatedAt
          account {
            id
            email
            first_name
            last_name
            web_site_URL
            instagram_account
            zip_code
            location
            tel
            company_name
            term_approval_status
            createdAt
            updatedAt
            owner
          }
          shop_images {
            items {
              id
              buyer_id
              image_key
            }
            nextToken
          }
          shipping_address {
            id
            name
            buyer_id
            zip_code
            prefecture
            city
            building
            phone_number
            createdAt
            updatedAt
          }
          referralBrand {
            id
            brand_owner
            brand_name
            brand_yomigana
            brand_type
            brand_content
            brand_imageKey
            brand_icon_imageKey
            brand_logo_imageKey
            brand_publication
            brand_retail_stores_number
            brand_category
            brand_target_age
            brand_target_gender
            brand_website_url
            brand_work_with_big_box
            brand_address
            brand_minimum_buy
            brand_additional_minimum_buy
            brand_shippinng_fee
            brand_shippinng_fee_criteria
            brand_instagram_account
            brand_facebook_account
            brand_rating
            brand_annual_turnover
            brand_public_status
            brand_howtoknowhomula
            brand_audit_status
            brand_stripe_id
            brand_first_order_fee
            brand_additional_order_fee
            brand_first_order_fee_referral
            brand_additional_order_fee_referral
            brand_direct_order_fee
            createdAt
            updatedAt
            owner
            designed_in
            established
            tagIds
            tags
            brand_collections
            movie_url
            brand_modal_imageKeys
            brand_online_sale
            product_image_reprint
            brand_logo_reprint
            post_to_sns
            allow_direct_shipping
            allow_sell_before_buy
            other_terms
            featured_in
            maximum_wholesale_rate
            exclude_zip_code
            is_sale_brand
            hubspot_company_id
            return_to_brand
            terms_of_sample
            auto_message_enabled
            auto_message
            sell_only_direct
            supplier_group_id
            individual_minimum_buy
            individual_shippinng_fee
            individual_shippinng_fee_criteria
            individual_first_order_fee
            individual_additional_order_fee
            individual_wholesale_rate
          }
          contacts {
            nextToken
          }
        }
        nextToken
      }
      supplierInfo {
        items {
          id
          account_id
          supplier_contract_type
          bank_name
          bank_branch_name
          bank_account_number
          bank_account_name
          bank_account_type
          createdAt
          updatedAt
          owner
          account {
            id
            email
            first_name
            last_name
            web_site_URL
            instagram_account
            zip_code
            location
            tel
            company_name
            term_approval_status
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
    }
  }
`;
