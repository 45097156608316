import { createReducer } from '@reduxjs/toolkit';
import { clearReturnableAmount, setReturnableAmount } from '../actions';

const initialState: number | null = null;

const returnableAmountReducer = createReducer(initialState, {
  [setReturnableAmount.type]: (_, { payload }) => payload,
  [clearReturnableAmount.type]: () => null,
});

export default returnableAmountReducer;
