import { Immutable } from './../../types/utils';

export type EntityType<T extends {}> = Immutable<T & _Entity<T>>;

type EntityConstructor = new <T extends {}>(props: T) => EntityType<T>;

abstract class _Entity<T extends {}> {
  constructor(props: T) {
    Object.assign(this, props);
  }
}

export const Entity: EntityConstructor = _Entity as any;
