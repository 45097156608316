import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Point, PointHistory } from '~types/api';

const initialState: Record<string, Point> = {};

const pointsByIdSlice = createSlice({
  name: 'points/byId',
  initialState,
  reducers: {
    recievedPoints(_, { payload: items }: PayloadAction<Point[]>) {
      return items.reduce((prev, item) => ({ ...prev, [item.id!]: item }), {});
    },
    clearedPoints() {
      return initialState;
    },
    usedPoints(state, { payload: histories }: PayloadAction<PointHistory[]>) {
      histories.forEach((history) => {
        state[history.point_id!].history?.items?.push(history);
        state[history.point_id!].points =
          (state[history.point_id!].points ?? 0) - (history.points ?? 0);
      });
    },
  },
});

export const {
  recievedPoints,
  clearedPoints,
  usedPoints,
} = pointsByIdSlice.actions;

export default pointsByIdSlice.reducer;
