import './styles.scss';
import React, { useCallback } from 'react';
import { Modal } from 'reactstrap';
import AuthContainer from '~view/widgets/AuthContainer';
import { useDispatch, useSelector } from 'react-redux';
import { nextPathSelector } from '~redux/auth/selectors/singin';
import { signup } from '~redux/auth/reducers/signup';
import { referralIdSelector } from '~redux/auth/selectors/signup';

type SignUpModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSignin?: (email: string) => void;
};

const SignUpModal: React.FC<SignUpModalProps> = ({
  isOpen,
  toggle,
  onSignin,
}) => {
  const dispatch = useDispatch();
  const nextPath = useSelector(nextPathSelector);
  const referralId = useSelector(referralIdSelector);
  const handleSignUp = useCallback(() => {
    dispatch(signup({ nextPath, referralId }));
    toggle();
  }, [nextPath]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} contentClassName="signin-modal">
      <AuthContainer modal />
      <div>
        アカウントをお持ちでない方は
        <a className="signup-link" onClick={handleSignUp}>
          こちら
        </a>
        からご登録ください。
      </div>
    </Modal>
  );
};

export default SignUpModal;
