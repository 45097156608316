import { BuyerType } from '~redux/buyer/types';
import {
  OrderPaymentStatus,
  OrderReadModelStatus,
  PaymentTerm,
} from '~types/api';

export const BUYER_TYPE: Record<BuyerType, string> = {
  [BuyerType.apparelPhysicalStore]: 'アパレル系リアル店舗',
  [BuyerType.generalGoodsPhysicalStore]: '雑貨系リアル店舗',
  [BuyerType.ecSite]: 'ECサイト',
  [BuyerType.misc]: 'その他',
};

export const ORDER_STATUS_LABEL: Record<OrderReadModelStatus, string> = {
  UNCHECKED: '未確認',
  CHECKED: '確認済',
  SHIPPED: '発送済',
  CANCELED: 'キャンセル',
  CANCELED_BY_BUYER: 'キャンセル',
  CANCELED_BY_SUPPLIER: 'キャンセル',
  CANCELED_BY_SYSTEM: 'キャンセル',
};

export const PAYMENT_TERM_LABEL: Record<PaymentTerm, string> = {
  CARD_DEFFERED_PAYMENT: '後払い(出荷の60日後)',
  CARD_SHIPPING_PAYMENT: '出荷払い',
  BILL_PAYMENT: '請求書払い(出荷の翌々月末)',
  BILL_PAYMENT_FACTORING: '請求書払い(出荷の翌々月末)',
  DIRECT_PAYMENT: 'ブランドへお支払い',
};

export const PAYMENT_STATUS_LABEL: Record<OrderPaymentStatus, string> = {
  [OrderPaymentStatus.UNCHARGED]: '未支払い',
  [OrderPaymentStatus.CHARGED]: '支払い済',
  [OrderPaymentStatus.PARTIAL_CHARGED]: '一部支払い済',
  [OrderPaymentStatus.DEFAULT]: '支払い失敗',
};
