import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: string[] = [];

const orderedBrandsSlice = createSlice({
  initialState,
  name: 'cart/orderedBrands',
  reducers: {
    recievedOrderedBrands: (
      _,
      { payload: { orderedBrands } }: PayloadAction<{ orderedBrands: string[] }>
    ) => {
      return orderedBrands;
    },
    clearedOrderedBrands: () => [],
  },
});

export const {
  recievedOrderedBrands,
  clearedOrderedBrands,
} = orderedBrandsSlice.actions;

export default orderedBrandsSlice.reducer;
