import { createAction } from '@reduxjs/toolkit';
import { Collection } from '~domain/product/Collection';

const ACTION_BASE_TYPE = 'COLLECTION';

export const setCollection = createAction<Collection>(
  `${ACTION_BASE_TYPE}/SET_COLLECTION`
);

export const clearCollection = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_COLLECTION`
);

export const setCollectionList = createAction<{
  items: Collection[];
  nextToken?: string;
}>(`${ACTION_BASE_TYPE}/SET_COLLECTION_LIST`);

export const removeCollection = createAction<string>(
  `${ACTION_BASE_TYPE}/REMOVE_COLLECTION`
);

export const clearCollectionList = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_COLLECTION_LIST`
);
