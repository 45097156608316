import '../styles.scss';
import { FiUser } from '@react-icons/all-files/fi/FiUser';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearAccount } from '~redux/account/actions/account';
import { accountSelector } from '~redux/account/selectors/account';
import { clearAuthUser } from '~redux/auth/actions/authUser';
import { clearBuyerInfo } from '~redux/buyer/actions/buyerInfo';
import { checkNewMessages } from '~redux/messages/actions';
import { newMessagesSelector } from '~redux/messages/selectors';
import {
  getNewMessages,
  subscribeNewMessages,
  unsubscribeNewMessages,
} from '~redux/messages/thunk';

const UserIcon: React.FC<{ existNewMessages: boolean }> = React.memo(
  ({ existNewMessages }) => {
    return (
      <span
        className={classNames({
          'd-inline m-0': true,
          'header-user-icon': true,
          'exist-new-messages-icon': existNewMessages,
        })}
      >
        <FiUser size={20} />
      </span>
    );
  }
);

const IndividualNavDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const signOut = useCallback(async () => {
    await Auth.signOut();
    dispatch(clearAuthUser());
    dispatch(clearAccount());
    dispatch(clearBuyerInfo());
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  }, []);
  const account = useSelector(accountSelector);
  const newMessages = useSelector(newMessagesSelector);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const showUserMenu = useCallback(() => {
    setUserMenuOpen(true);
  }, []);
  const hideUserMenu = useCallback(() => {
    setUserMenuOpen(false);
  }, []);
  const existNewMessages = useMemo(
    () => !!newMessages.unCheckedMessages.length,
    [newMessages]
  );
  const checkMessages = useCallback(() => {
    dispatch(checkNewMessages());
    hideUserMenu();
  }, []);
  useEffect(() => {
    if (!account) {
      return;
    }
    if (
      !newMessages.checkedMessages.length &&
      !newMessages.unCheckedMessages.length
    ) {
      dispatch(getNewMessages(account.id));
    }
    dispatch(subscribeNewMessages(account.id));
    return () => {
      dispatch(unsubscribeNewMessages());
    };
  }, []);

  return (
    <Nav>
      <NavDropdown
        id="user-menu"
        className="menu-dropdown-account"
        title={<UserIcon existNewMessages={existNewMessages} />}
        show={userMenuOpen}
        onMouseEnter={() => showUserMenu()}
        onMouseLeave={() => hideUserMenu()}
        onTouchStart={() => showUserMenu()}
        alignRight
      >
        <Nav.Item className="user-menu_user-info">
          {`${account?.last_name} ${account?.first_name}`}
          &nbsp;様
        </Nav.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Link}
          to="/Account/OrderHistory"
          onClick={hideUserMenu}
        >
          注文履歴
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/messages"
          onClick={checkMessages}
          className={classNames({
            'exist-new-messages-link': existNewMessages,
          })}
        >
          メッセージ
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/Account/AccountProfile"
          onClick={hideUserMenu}
        >
          アカウント
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={signOut}>ログアウト</NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};

export default IndividualNavDropdown;
