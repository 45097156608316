import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

const buyerSelector = (state: RootState) => state.buyer;

export const buyerInfoSelector = createSelector(
  buyerSelector,
  (buyer) => buyer.info
);

export const buyerAddressListSelector = createSelector(
  buyerSelector,
  (buyer) => buyer.addresses
);

export const makeAddressSelector = (id?: string | null) =>
  createSelector(buyerSelector, (buyer) =>
    buyer.addresses.find((address) => address.id === id)
  );

export const buyerPaymentMethodListSelector = createSelector(
  buyerSelector,
  (buyer) => buyer.paymentMethod
);

export const paymentMethodSelector = createSelector(
  buyerInfoSelector,
  buyerPaymentMethodListSelector,
  (buyer, paymentMethods) =>
    paymentMethods.find(
      (paymentMethod) => paymentMethod.id === buyer?.primary_payment_method_id
    )
);

export const buyerIdSelector = createSelector(
  buyerInfoSelector,
  (buyer) => buyer?.account_id
);

export const selectBuyerContacts = createSelector(
  buyerSelector,
  (buyer) => buyer.contacts
);
export const makeSelectBuyerContactByBrandOwner = (brandOwner: string) =>
  createSelector(
    selectBuyerContacts,
    (contacts) =>
      Object.values(contacts).filter(
        (contact) => contact.owner === brandOwner
      )[0]
  );
export const makeSelectContactDiscountRateByBrandOwner = (brandOwner: string) =>
  createSelector(
    makeSelectBuyerContactByBrandOwner(brandOwner),
    (contact) => contact?.discount_rate ?? 0
  );
