import React, { useContext, useEffect, useMemo } from 'react';
import { QueryContext } from '../..';
import { PRODUCT_SEARCH_INDEX_FEATURED_QUERY_SUGGESTIONS } from '~constants/search';
import InstantSearchProvider, {
  useInstantSearch,
} from '~view/widgets/InstantSearchProvider';

const Queries: React.VFC = React.memo(() => {
  const { setQueries } = useContext(QueryContext);
  const { hits } = useInstantSearch<{ query: string }>();
  const queries = useMemo(() => hits.map((hit) => hit.query), [hits]);
  useEffect(() => {
    setQueries!(queries);
  }, [JSON.stringify(queries)]);
  return null;
});

const PopularQueries: React.FC = () => {
  const { query } = useContext(QueryContext);
  return (
    <InstantSearchProvider
      indexName={PRODUCT_SEARCH_INDEX_FEATURED_QUERY_SUGGESTIONS}
      query={query}
      hitsPerPage={5}
    >
      <Queries />
    </InstantSearchProvider>
  );
};

export default PopularQueries;
