import { combineReducers } from 'redux';
import itemsReducer from './items';
import productLoadingReducer from './loading';
import nextTokenReducer from './nextToken';
import searchReducer from './search';

export const productsReducer = combineReducers({
  search: searchReducer,
  items: itemsReducer,
  nextToken: nextTokenReducer,
  loading: productLoadingReducer,
});

export default productsReducer;
