import { createAction } from '@reduxjs/toolkit';

const ACTION_BASE_TYPE = 'BLOG';

export type BlogPost = any;

export const setBlogPosts = createAction<BlogPost[]>(
  `${ACTION_BASE_TYPE}/SET_BLOG_POSTS`
);
export const clearBlogPosts = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_BLOG_POSTS`
);
