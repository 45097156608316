import { createReducer } from '@reduxjs/toolkit';
import {
  Message,
  postGetMessages,
  postGetThreads,
  preGetMessages,
  preGetThreads,
} from '../actions';

type Loadingtate = { isThreadsLoading: boolean; isMessagesLoading: boolean };
const initialState: Loadingtate = {
  isThreadsLoading: false,
  isMessagesLoading: false,
};

const loadingReducer = createReducer(initialState, {
  [preGetThreads.type]: (state) => ({
    ...state,
    isThreadsLoading: true,
  }),
  [postGetThreads.type]: (state) => ({
    ...state,
    isThreadsLoading: false,
  }),
  [preGetMessages.type]: (state) => ({
    ...state,
    isMessagesLoading: true,
  }),
  [postGetMessages.type]: (state) => ({
    ...state,
    isMessagesLoading: false,
  }),
});

export default loadingReducer;
