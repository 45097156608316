import { combineReducers } from 'redux';
import returnItemsReducer from './items';
import returnableAmountReducer from './returnableAmount';

const returnsReducer = combineReducers({
  items: returnItemsReducer,
  returnableAmount: returnableAmountReducer,
});

export default returnsReducer;
