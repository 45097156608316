import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import PickupListTable from './PickupListTable';

type PickupListProps = {};

const PickupList: React.FC<PickupListProps> = () => {
  return (
    <Container className="p-6">
      <div className="d-flex mb-4">
        <h2 className="mb-0">ピックアップリスト</h2>
        <div className="ml-auto">
          <Link to="/PickupList/edit">
            <Button>リストを追加</Button>
          </Link>
        </div>
      </div>
      <PickupListTable />
    </Container>
  );
};

export default PickupList;
