import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { usePickupList } from './hooks';
import { formatDate } from '~utils/formatter';
import useConfirm from '~view/hooks/confirm';
import LoadingContainer from '~view/widgets/LoadingContainer';

type PickupListTableProps = {};

const PickupListTable: React.FC<PickupListTableProps> = () => {
  const {
    pickupList,
    updateOrder,
    updateVisible,
    deleteList,
    isLoading,
  } = usePickupList();

  const [confirm, ConfirmModal] = useConfirm();

  const handleSubmit = useCallback(async (values) => {
    await updateOrder(values.pickupList);
  }, []);

  const handleDelete = useCallback(async (id) => {
    await confirm('削除してよろしいですか？').then(
      async () => await deleteList(id)
    );
  }, []);

  const handleVisible = useCallback(async (id, visible) => {
    await confirm(`${visible ? '表示' : '非表示に'}してよろしいですか？`).then(
      async () => await updateVisible(id, visible)
    );
  }, []);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Formik
        initialValues={{ pickupList }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, dirty }) => (
          <Form>
            <Table borderless className="border">
              <thead>
                <tr className="border-bottom">
                  <th>タイトル</th>
                  <th>アイテム種別</th>
                  <th>アイテム数</th>
                  <th>作成日</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="pickupList">
                  {(helpers) => (
                    <>
                      {values.pickupList?.map((pickupList, index) => (
                        <tr key={pickupList.id} className="border-bottom">
                          <td>{pickupList.title}</td>
                          <td>
                            {pickupList.item_type === 'PRODUCT'
                              ? '商品'
                              : 'ブランド'}
                          </td>
                          <td>
                            {pickupList.item_type === 'PRODUCT'
                              ? pickupList.products?.items?.length
                              : pickupList.brands?.length}
                          </td>
                          <td>
                            {formatDate(pickupList.createdAt, 'yyyy/MM/dd')}
                          </td>
                          <td>
                            <Button
                              onClick={() => helpers.swap(index, index - 1)}
                              outline
                              disabled={index === 0}
                              className="mr-2"
                            >
                              ↑
                            </Button>
                            <Button
                              onClick={() => helpers.swap(index, index + 1)}
                              outline
                              disabled={index + 1 === values.pickupList?.length}
                              className="mr-2"
                            >
                              ↓
                            </Button>
                            <Link to={`/PickupList/edit/${pickupList.id}`}>
                              <Button className="mr-2" type="button" outline>
                                編集
                              </Button>
                            </Link>
                            <Button
                              className="mr-2"
                              onClick={() => handleDelete(pickupList.id)}
                              outline
                            >
                              削除
                            </Button>
                            <Button
                              type="button"
                              outline={!!pickupList.visible}
                              onClick={() =>
                                handleVisible(
                                  pickupList.id,
                                  !pickupList.visible
                                )
                              }
                            >
                              {pickupList.visible ? '非表示にする' : '表示する'}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </FieldArray>
              </tbody>
            </Table>
            {dirty && (
              <div
                className="w-100 shadow-lg d-flex p-4 bg-white"
                style={{ position: 'fixed', bottom: 0, left: 0 }}
              >
                <Button className="ml-auto" style={{ width: '250px' }}>
                  保存
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <ConfirmModal />
    </LoadingContainer>
  );
};

export default PickupListTable;
