import { createReducer } from '@reduxjs/toolkit';
import {
  CartItem,
  setProductData,
} from '../actions';

type CartItemsState = CartItem[];
const initialState: CartItemsState = [];

const productReducer = createReducer(initialState, {
  [setProductData.type]: (_, { payload }) => payload,
});

export default productReducer;
