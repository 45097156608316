import { createUseCase } from '~core/usecase/createUseCase';
import { listReviewsByBuyer } from '~graphql/queries';
import {
  fetchedReviews,
  recievedReviwes as recievedReviews,
} from '~redux/review';
import {
  ListReviewsByBuyerQuery,
  ListReviewsByBuyerQueryVariables,
  Review,
} from '~types/api';
import { executeQuery } from '~utils/graphql';

export const fetchReviews = createUseCase(
  'review/fetchReviews',
  async (
    {
      accountId,
    }: {
      accountId: string;
    },
    { dispatch }
  ) => {
    dispatch(fetchedReviews());
    const res = await executeQuery<
      ListReviewsByBuyerQuery,
      ListReviewsByBuyerQueryVariables
    >(listReviewsByBuyer, {
      buyer_id: accountId,
      limit: 1000,
    });
    dispatch(
      recievedReviews((res.data?.listReviewsByBuyer?.items ?? []) as Review[])
    );
  }
);
