// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-SupplierMenu-___styles-modules__menu{display:flex;flex-direction:column;gap:1.5rem}.src-view-widgets-SupplierMenu-___styles-modules__menu ul{list-style:none;padding:0;margin:0}.src-view-widgets-SupplierMenu-___styles-modules__inner,.src-view-widgets-SupplierMenu-___styles-modules__items-container{display:flex;flex-direction:column;gap:.5rem}", ""]);
// Exports
exports.locals = {
	"menu": "src-view-widgets-SupplierMenu-___styles-modules__menu",
	"inner": "src-view-widgets-SupplierMenu-___styles-modules__inner",
	"items-container": "src-view-widgets-SupplierMenu-___styles-modules__items-container"
};
module.exports = exports;
