import { createReducer } from '@reduxjs/toolkit';
import {
  addSearchProducts,
  receiveSearchProducts,
  clearSearchProducts,
} from '../actions';
import { Product } from '~redux/brand/types';

export type SearchProductsState = {
  items: Product[];
  nextToken: string | null;
  lastItems: Product[];
  lastItemsIndex: number;
};

const initialState: SearchProductsState = {
  items: [],
  nextToken: null,
  lastItems: [],
  lastItemsIndex: 0,
};

const searchReducer = createReducer(initialState, {
  [receiveSearchProducts.type]: (state, action) => {
    return {
      ...action.payload,
      lastItems: action.payload.items,
      lastItemsIndex: 0,
    };
  },
  [addSearchProducts.type]: (state, action) => {
    return {
      items: [...state.items, ...action.payload.items],
      nextToken: action.payload.nextToken,
      lastItems: action.payload.items,
      lastItemsIndex: state.items.length,
    };
  },
  [clearSearchProducts.type]: (state, action) => {
    return initialState;
  },
});

export default searchReducer;
