import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Entity } from '~core/domain/Entity';
import { Account } from '~redux/account/actions/account';
import { BrandDetails as BrandDetailsJson, Product } from '~redux/brand/types';
import { Contact } from '~redux/contacts';
import { removeItems } from '~utils/graphql';
import { getImageUrl } from '~utils/image';

type BrandType = {
  id: string;
  account?: Account;
  company_name: string | null;
  brand_owner: string;
  brand_name: string;
  brand_type: BrandType | null;
  brand_content: string;
  brand_imageKey: string;
  brand_icon_imageKey: string | null;
  brand_logo_imageKey: string | null;
  brand_publication: string | null;
  brand_available_store: string | null;
  brand_category: string;
  brand_target_age: number | null;
  brand_target_gender: string;
  brand_website_url: string;
  brand_address: string;
  brand_minimum_buy: number;
  brand_additional_minimum_buy: number | null;
  brand_shippinng_fee: number | null;
  brand_shippinng_fee_criteria: number | null;
  individual_shippinng_fee_criteria?: number | null;
  brand_instagram_account: string | null;
  brand_facebook_account: string | null;
  brand_public_status: string;
  brand_stripe_id: string | null;
  imageUrl: string;
  modalImageUrl: string[];
  iconUrl: string;
  is_sale_brand: boolean;
  product: {
    items: Product[];
  };
  designed_in: string | null;
  established: string | null;
  movie_url: string | null;
  stockists:
    | {
        stockist: string;
        address: string;
      }[]
    | null;
  tagIds: string[];
  tags: string[];
  brand_online_sale: boolean | null;
  product_image_reprint: boolean | null;
  brand_logo_reprint: boolean | null;
  post_to_sns: boolean | null;
  allow_direct_shipping: boolean | null;
  allow_sell_before_buy: boolean | null;
  other_terms: string;
  featured_in: string[];
  exclude_zip_code: string[] | null;
  maximum_wholesale_rate: number | null;
  contacts?: Contact[];
  return_to_brand?: boolean;
  auto_message?: string;
  auto_message_enabled?: boolean;
};

export class Brand extends Entity<BrandType> {
  private constructor(params: BrandType) {
    super(params);
  }

  static create({ ...brand }: BrandDetailsJson) {
    return new Brand({
      ...brand,
    });
  }

  static async getById(id: string) {
    const {
      data: { getBrand: responseData },
    } = await API.graphql<any>(
      graphqlOperation(queries.getBrand, {
        id,
      })
    );
    const response = removeItems(responseData);
    const imageUrl = response.brand_imageKey
      ? ((await getImageUrl(response.brand_imageKey)) as string)
      : 'https://placehold.jp/300x300.png?text=No image';
    const iconUrl = await getImageUrl(response.brand_icon_imageKey);
    const modalImageUrl = (response.brand_modal_imageKeys?.length
      ? await Promise.all(
          response.brand_modal_imageKeys?.map(async (imageKey: string) =>
            imageKey ? await getImageUrl(imageKey) : ''
          )
        )
      : []) as string[];

    const brand = Brand.create({
      ...response,
      imageUrl,
      iconUrl,
      modalImageUrl,
    });
    return brand;
  }

  static async listByOwner(owner: string): Promise<Brand[]> {
    const {
      data: { searchBrands },
    } = await API.graphql<any>(
      graphqlOperation(queries.searchBrands, {
        filter: {
          brand_owner: { eq: owner },
        },
        limit: 1000,
      })
    );

    return await Promise.all(
      searchBrands.items.map(async (item: any) => {
        const imageUrl = await getImageUrl(item.brand_imageKey);
        const iconUrl = await getImageUrl(item.brand_icon_imageKey);
        const modalImageUrl = (item.brand_modal_imageKeys?.length
          ? await Promise.all(
              item.brand_modal_imageKeys?.map(async (imageKey: string) =>
                imageKey ? await getImageUrl(imageKey) : ''
              )
            )
          : []) as string[];

        return Brand.create({
          ...item,
          imageUrl,
          iconUrl,
          modalImageUrl,
        });
      })
    );
  }

  toJson(): BrandDetailsJson {
    return {
      id: this.id,
      account: this.account,
      company_name: this.company_name,
      brand_owner: this.brand_owner,
      brand_name: this.brand_name,
      brand_type: this.brand_type,
      brand_content: this.brand_content,
      brand_imageKey: this.brand_imageKey,
      brand_icon_imageKey: this.brand_icon_imageKey,
      brand_logo_imageKey: this.brand_logo_imageKey,
      brand_publication: this.brand_publication,
      brand_available_store: this.brand_available_store,
      brand_category: this.brand_category,
      brand_target_age: this.brand_target_age,
      brand_target_gender: this.brand_target_gender,
      brand_website_url: this.brand_website_url,
      brand_address: this.brand_address,
      brand_minimum_buy: this.brand_minimum_buy,
      brand_additional_minimum_buy: this.brand_additional_minimum_buy,
      brand_shippinng_fee: this.brand_shippinng_fee,
      brand_shippinng_fee_criteria: this.brand_shippinng_fee_criteria,
      brand_instagram_account: this.brand_instagram_account,
      brand_facebook_account: this.brand_facebook_account,
      brand_public_status: this.brand_public_status,
      brand_stripe_id: this.brand_stripe_id,
      imageUrl: this.imageUrl,
      modalImageUrl: this.modalImageUrl,
      iconUrl: this.iconUrl,
      is_sale_brand: this.is_sale_brand,
      product: this.product,
      designed_in: this.designed_in,
      established: this.established,
      movie_url: this.movie_url,
      stockists: this.stockists,
      tagIds: this.tagIds,
      tags: this.tags,
      brand_online_sale: this.brand_online_sale,
      product_image_reprint: this.product_image_reprint,
      brand_logo_reprint: this.brand_logo_reprint,
      post_to_sns: this.post_to_sns,
      allow_direct_shipping: this.allow_direct_shipping,
      allow_sell_before_buy: this.allow_sell_before_buy,
      other_terms: this.other_terms,
      featured_in: this.featured_in,
      exclude_zip_code: this.exclude_zip_code,
      maximum_wholesale_rate: this.maximum_wholesale_rate,
      auto_message_enabled: this.auto_message_enabled,
      auto_message: this.auto_message,
    };
  }
}
