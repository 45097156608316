import React from 'react';
import { Route, RouteProps } from 'react-router';
import withAdminPage from '~view/hoc/withAdminPage';
import AdminPage from '../AdminPage';

type AdminRouteProps = {
  title?: string;
} & RouteProps;

const AdminRoute: React.FC<AdminRouteProps> = ({
  component,
  render,
  title,
  ...props
}) => {
  return (
    <Route
      {...props}
      component={component && withAdminPage(component, title)}
      render={
        render &&
        ((props) => <AdminPage title={title}>{render(props)}</AdminPage>)
      }
    />
  );
};

export default AdminRoute;
