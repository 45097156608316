// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-SupplierPage-___styles-modules__supplier-page{height:0;overflow:hidden}.src-view-widgets-SupplierPage-___styles-modules__sidebar-area{z-index:50;overflow:auto}.src-view-widgets-SupplierPage-___styles-modules__content-area{background-color:#f8f8f8;overflow:auto;position:relative}", ""]);
// Exports
exports.locals = {
	"supplier-page": "src-view-widgets-SupplierPage-___styles-modules__supplier-page",
	"sidebar-area": "src-view-widgets-SupplierPage-___styles-modules__sidebar-area",
	"content-area": "src-view-widgets-SupplierPage-___styles-modules__content-area"
};
module.exports = exports;
