import React, { useMemo } from 'react';
import { Route, RouteProps } from 'react-router';
import BuyerPage from '../BuyerPage';
import withBuyerPage from '~view/hoc/withBuyerPage';

type BuyerRouteProps = {
  title?: string;
  loginLink?: string;
  unAuth?: boolean;
  excludePaths?: string[];
} & RouteProps;

const BuyerRoute: React.FC<BuyerRouteProps> = ({
  component,
  render,
  title,
  loginLink,
  unAuth,
  excludePaths,
  ...props
}) => {
  const page = useMemo(
    () =>
      component
        ? withBuyerPage(component, title, loginLink, unAuth, excludePaths)
        : undefined,
    [component, title, loginLink, unAuth, excludePaths]
  );
  const pageRender = useMemo(
    () =>
      render
        ? (props: any) => {
            if (excludePaths?.includes(props.location.pathname)) {
              return null;
            }
            return (
              <BuyerPage title={title} loginLink={loginLink} unAuth={unAuth}>
                {render(props)}
              </BuyerPage>
            );
          }
        : undefined,
    [title, loginLink, unAuth, excludePaths]
  );
  return <Route {...props} component={page} render={pageRender} />;
};

export default BuyerRoute;
