import './styles.modules.scss';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../../assets/images/futura_homula_logo_white.svg';
import CategoryBurgerMenu from '../CategoryBurgerMenu';
import CategoryNav from '../CategoryNav';
import { REFERRAL_POINTS } from '~constants/trade';
import { signup } from '~redux/auth/reducers/signup';
import { Brand } from '~redux/brand/types';
import { formatCurrency } from '~utils/formatter';
import { useViewport } from '~view/hooks/viewport';

type DirectLinkHeaderProps = {
  brand: Brand;
  contactId?: string;
};

const DirectLinkHeader: React.FC<DirectLinkHeaderProps> = ({
  brand,
  contactId,
}) => {
  const dispatch = useDispatch();
  const handleSignUp = useCallback(() => {
    dispatch(
      signup({
        nextPath: `/brand/${brand.id}?direct=true`,
        referralId: brand.id,
        brand,
        contactId,
      })
    );
  }, [brand, contactId]);
  const { atMost: atMostLg } = useViewport({ atMost: 'lg' });
  const { atMost: atMostMd } = useViewport({ atMost: 'md' });

  return (
    <header styleName="direct-link-header">
      <div styleName="nav">
        {atMostLg && <CategoryBurgerMenu />}
        <div styleName="direct-header">
          <div styleName="header-logo">
            <span styleName="brand-name" translate="no">
              {brand.brand_name}
            </span>
            <span className="ml-3">×</span>
            <img styleName="logo" src={logo} alt="homula" />
          </div>
          {!atMostMd && (
            <div styleName="sign-up">
              <button onClick={handleSignUp}>新規登録</button>
            </div>
          )}
        </div>
        {!brand.sell_only_direct && !atMostLg && (
          <div>
            <CategoryNav />
          </div>
        )}
      </div>
      {!brand.sell_only_direct && (
        <div styleName="direct-message">
          <a className="ml-2" onClick={handleSignUp}>
            <b>無料バイヤー登録</b>
          </a>
        </div>
      )}
    </header>
  );
};

export default DirectLinkHeader;
