import { combineReducers } from 'redux';
import favoriteBrandsReducer from './brands';
import { reducer } from './product';

const favoriteReducer = combineReducers({
  favoriteBrands: favoriteBrandsReducer,
  product: reducer,
});

export default favoriteReducer;
