import { createAction } from '@reduxjs/toolkit';
import { Product } from '~redux/brand/types';

export type FavoriteProduct = {
  id: string;
  account_id: string;
  favorite_list_id: string;
  product_id: string;
  order: string;
  product: Product;
};

const ACTION_BASE_TYPE = 'FAVORITEPRODUCT';

export const setFavoriteProducts = createAction<FavoriteProduct[]>(
  `${ACTION_BASE_TYPE}/SET_FAVORITE_PRODUCTS`
);

export const clearFavoriteProduct = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_FAVORITE_PRODUCT`
);

export const appendFavoriteProduct = createAction<FavoriteProduct>(
  `${ACTION_BASE_TYPE}/APPEND_FAVORITE_PRODUCT`
);

export const removeFavoriteProduct = createAction<string>(
  `${ACTION_BASE_TYPE}/REMOVE_FAVORITE_PRODUCT`
);

export const setFavoriteProduct = createAction<{}>(
  `${ACTION_BASE_TYPE}/SET_FAVORITE_PRODUCT`
);
