import './styles.modules.scss';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'reactstrap';
import CategoryNavMenu from './CategoryNavMenu';
import FeaturedNavMenu from './FeaturedNavMenu';
import NewNavMenu from './NewNavMenu';
import menuJson from '~constants/menu.json';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { featuredContentsSelector } from '~redux/cms/selectors';
import { tagListSelector } from '~redux/tag/selectors';

const CategoryNav: React.FC = () => {
  //constants
  const auth = useSelector(authUserSelector);
  const contents = useSelector(featuredContentsSelector);
  const tagList = useSelector(tagListSelector);

  const menu = useMemo(() => {
    return Object.entries(menuJson['2']);
  }, []);

  return (
    <Navbar light expand="md" styleName="category-nav">
      {auth && (
        <Nav navbar>
          <li>
            <Link to="/sale" className="nav-link font-weight-bold">
              限定セール
            </Link>
          </li>
        </Nav>
      )}
      {(!!contents?.length || !!tagList?.length) && (
        <FeaturedNavMenu features={contents} tagList={tagList} />
      )}
      <NewNavMenu />
      {menu.map(([key, value]) => (
        <CategoryNavMenu
          key={key}
          displayName={key}
          productListPath={value['product_list_path']}
          brandListPath={value['brand_list_path']}
          categories={value['categories']}
        />
      ))}
    </Navbar>
  );
};

export default CategoryNav;
