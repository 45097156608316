import './styles.scss';
import './styles.modules.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
import { CartIcon } from './CartIcon';
import NavDropdown from './NavDropdown';
import { accountSelector } from '~redux/account/selectors/account';
import { newMessagesSelector } from '~redux/messages/selectors';
import {
  getNewMessages,
  subscribeNewMessages,
  unsubscribeNewMessages,
} from '~redux/messages/thunk';

type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);
  const newMessages = useSelector(newMessagesSelector);
  useEffect(() => {
    if (!account) {
      return;
    }
    if (
      !newMessages.checkedMessages.length &&
      !newMessages.unCheckedMessages.length
    ) {
      dispatch(getNewMessages(account.id));
    }
    dispatch(subscribeNewMessages(account.id));
    return () => {
      dispatch(unsubscribeNewMessages());
    };
  }, []);

  return (
    <div styleName="header" className="login-header">
      <div styleName="logo">
        <Link to="/home">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div styleName="icons">
        <CartIcon />
        <div styleName="dropdown">
          <NavDropdown />
        </div>
      </div>
    </div>
  );
};

export default Header;
