import { createAction } from '@reduxjs/toolkit';
import { CartItem } from '../types';
import { Product } from '~redux/brand/types';

const ACTION_BASE_TYPE = 'CART';

export const addCartStart = createAction(`${ACTION_BASE_TYPE}/ADD_CART_START`);
export const addCartItem = createAction<CartItem>(
  `${ACTION_BASE_TYPE}/ADD_CART_ITEM`
);
export const getCartStart = createAction(`${ACTION_BASE_TYPE}/GET_CART_START`);
export const setCartItems = createAction<CartItem[]>(
  `${ACTION_BASE_TYPE}/SET_CART_ITEMS`
);
export const removeCartStart = createAction(
  `${ACTION_BASE_TYPE}/REMOVE_CART_START`
);
export const removeCartItem = createAction<{ id: string; quantity: number }>(
  `${ACTION_BASE_TYPE}/REMOVE_CART_ITEM`
);
export const updateCartStart = createAction(
  `${ACTION_BASE_TYPE}/UPDATE_CART_START`
);
export const setCartItemQuantity = createAction<{
  id: string;
  quantity: number;
  prev: number;
}>(`${ACTION_BASE_TYPE}/SET_CART_ITEM_QUANTITY`);
export const getCartQuantityStart = createAction(
  `${ACTION_BASE_TYPE}/GET_CART_QUANTITY_START`
);
export const restockItemsStart = createAction(
  `${ACTION_BASE_TYPE}/RESTOCK_ITEMS_START`
);
export const restockItemsEnd = createAction(
  `${ACTION_BASE_TYPE}/RESTOCK_ITEMS_END`
);

export const checkoutStart = createAction(`${ACTION_BASE_TYPE}/CHECKOUT_START`);
export const checkoutComplete = createAction(
  `${ACTION_BASE_TYPE}/CHECKOUT_COMPLETE`
);

export const setCartProducts = createAction<CartItem[]>(
  `${ACTION_BASE_TYPE}/SET_CART_PRODUCTS`
);

export const clearCartProducts = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_CART_PRODUCTS`
);

export const setProductCart = createAction<Product>(
  `${ACTION_BASE_TYPE}/SET_PRODUCT`
);

export const clearProductCart = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_PRODUCT`
);

export const updateCartItemsStart = createAction(
  `${ACTION_BASE_TYPE}/UPDATE_CART_ITEMS_START`
);

export const savedForLater = createAction<{ brandId: string }>(
  `${ACTION_BASE_TYPE}/SAVED_FOR_LATER`
);
export const movedToCart = createAction<{ brandId: string }>(
  `${ACTION_BASE_TYPE}/MOVED_TO_CART`
);

export const clearedCartItems = createAction(
  `${ACTION_BASE_TYPE}/CLEARED_CART_ITEMS`
);

export const removedCartItems = createAction<{ brandIds: string[] }>(
  `${ACTION_BASE_TYPE}/REMOVED_CART_ITEMS`
);

export const updatedCartItems = createAction<
  ({ id: string } & Partial<CartItem>)[]
>(`${ACTION_BASE_TYPE}/UPDATED_CART_ITEMS`);
