import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DirectSignupModal from './DirectSignupModal';
import MagSignUpModal from './MagSignupModal';
import SignUpModal from './SignUpModal';
import { clear } from '~redux/auth/reducers/signup';
import {
  brandSelector,
  contactIdSelector,
  isSignUpSelector,
  referralIdSelector,
} from '~redux/auth/selectors/signup';
import { selectProjectId } from '~redux/project';

const SignUp: React.FC = () => {
  const dispatch = useDispatch();
  const isSignup = useSelector(isSignUpSelector);
  const contactId = useSelector(contactIdSelector);
  const brand = useSelector(brandSelector);
  const referralId = useSelector(referralIdSelector);
  const projectId = useSelector(selectProjectId);
  const close = useCallback(() => {
    dispatch(clear());
  }, []);
  const onSignUp = useCallback(() => {
    close();
  }, [close]);

  if (!isSignup) {
    return null;
  }

  if (projectId === 'mag') {
    return <MagSignUpModal isOpen toggle={close} onSignup={onSignUp} />;
  }

  if (referralId) {
    return (
      <DirectSignupModal
        brand={brand!}
        contactId={contactId}
        isOpen
        toggle={close}
      />
    );
  }

  return <SignUpModal isOpen toggle={close} onSignup={onSignUp} />;
};

export default SignUp;
