import { createReducer } from '@reduxjs/toolkit';
import { setNextMessagesToken } from '../actions';

const initialState: string | null = null;

const nextTokenReducer = createReducer(initialState, {
  [setNextMessagesToken.type]: (_, { payload }) => payload,
});

export default nextTokenReducer;
