import { combineReducers } from 'redux';
import imageReducer from './images';
import itemsReducer from './items';
import loadingReducer from './loading';
import productReducer from './product';


const bulkRegistrationReducer = combineReducers({
  items: itemsReducer,
  isLoading: loadingReducer,
  product_data: productReducer,
  images: imageReducer,
});

export default bulkRegistrationReducer;
