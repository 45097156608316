import { createReducer } from '@reduxjs/toolkit';
import { receiveOrderBrands } from '../actions/admin';
import { BrandDetails } from '~redux/brand/types';

const initialState: BrandDetails[] = [];

const adminOrderBrandsReducer = createReducer(initialState, {
  [receiveOrderBrands.type]: (_, { payload }) => payload,
});

export default adminOrderBrandsReducer;
