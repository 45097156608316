import React, { useCallback } from 'react';
import {
  Route,
  RouteComponentProps,
  RouteProps,
  StaticContext,
} from 'react-router';
import { useTitle } from '~view/hooks/title';

export type IndividualRouteProps = {
  title: string;
} & RouteProps;

const IndividualRoute: React.FC<IndividualRouteProps> = ({
  component: Component,
  render,
  title,
  ...restProps
}) => {
  const renderPage = useCallback(
    (
      props: RouteComponentProps<
        {
          [x: string]: string | undefined;
        },
        StaticContext,
        unknown
      >
    ) =>
      render ? (
        <Container title={title}>{render(props)}</Container>
      ) : Component ? (
        <Container title={title}>
          <Component {...props} />
        </Container>
      ) : null,
    [render, title, Component]
  );

  return <Route {...restProps} render={renderPage} />;
};

type InnerProps = Pick<IndividualRouteProps, 'title' | 'children'>;

const Container: React.FC<InnerProps> = ({ title, children }) => {
  useTitle(title);

  return <>{children}</>;
};

export default IndividualRoute;
