/**
 *  Header Main
 */
import './styles.module.scss';
import React from 'react';
import CategoryBurgerMenu from '~view/widgets/CategoryBurgerMenu';
import CategoryNav from '~view/widgets/CategoryNav';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      timeout: true,
      modal: false,
      activeTab: '1',
      isOpen: false,
      collapsed: true,
      CartHide: true,
      classset: '',
    };
    this.toggle = this.toggle.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  logintoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  closeNavbar() {
    if (this.state.collapsed !== true) {
      this.toggleNavbar();
    }
  }

  onClickClassAdd(pages) {
    if (this.state.classset != pages) {
      this.setState({
        ...this.state,
        classset: pages,
      });
    } else {
      if (Object.keys(this.state.classset).length == 0) {
        this.setState({
          ...this.state,
          classset: pages,
        });
      } else {
        this.setState({
          ...this.state,
          classset: '',
        });
      }
    }
  }

  render() {
    const pathnames = document.location.href;
    const pathArray = pathnames.split('/');
    const pageName = '/' + pathArray[pathArray.length - 1];
    let searchName;
    if (pageName == '/topbar-with-load-more') {
      searchName = '/topbar-with-load-more';
    } else if (pageName == '/sidebar-without-lazyload') {
      searchName = '/sidebar-without-lazyload';
    } else if (pageName == '/topbar-without-lazyload') {
      searchName = '/topbar-without-lazyload';
    } else if (pageName == '/sidebar-with-lazyload') {
      searchName = '/sidebar-with-lazyload';
    } else {
      searchName = '/sidebar-with-load-more';
    }
    if (this.state.timeout == true) {
      setTimeout(
        function () {
          this.setState({ timeout: false });
        }.bind(this),
        2000
      ); // wait 5 seconds, then reset to false
    }
    return (
      <>
        <div className="d-none d-lg-block">
          <CategoryNav />
        </div>
        <div className="d-block d-lg-none">
          <CategoryBurgerMenu />
        </div>
      </>
    );
  }
}

export default Header;
