import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

export const signInSelector = (state: RootState) => state.auth.signin;
export const isSignInSelector = createSelector(
  signInSelector,
  (signIn) => signIn.isSignin
);
export const nextPathSelector = createSelector(
  signInSelector,
  (signIn) => signIn.nextPath
);
