import { createAction } from '@reduxjs/toolkit';

const ACTION_BASE_TYPE = 'REQUEST';

export const requestStart = createAction<string>(
  `${ACTION_BASE_TYPE}/REQUEST_START`
);

export const requestComplete = createAction<string>(
  `${ACTION_BASE_TYPE}/REQUEST_COMPLETE`
);

type RequestError = {
  type: string;
  error: {
    message: string;
    status: number;
  };
};
export const requestError = createAction<RequestError>(
  `${ACTION_BASE_TYPE}/REQUEST_ERROR`
);
