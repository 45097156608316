/**
 *  Footer Main
 */
import React from 'react';
import { Container } from 'reactstrap';
import './footer.scss';
import logo from '../../../assets/images/mag/logo.png';

const MagFooter = () => {
  return (
    <footer className="py-5 border-top">
      <Container>
        <div>
          <a href="/">
            <img
              src={logo}
              alt=""
              style={{
                width: '120px',
              }}
            />
          </a>
        </div>
        <div className="px-3 text-black-50">
          The place for meeting the needs.
        </div>
      </Container>
    </footer>
  );
};

export default MagFooter;
