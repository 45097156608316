import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TReferral } from '~domain/referral/Referral';

const initialState: TReferral[] = [];

const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    setReferrals(_, { payload }: PayloadAction<TReferral[]>) {
      return payload;
    },
    pushReferrals(state, { payload }: PayloadAction<TReferral[]>) {
      return [...state, ...payload];
    },
    setReferral(state, { payload }: PayloadAction<TReferral>) {
      return state.map((referral) =>
        referral.id === payload.id ? payload : referral
      );
    },
    clearReferrals() {
      return initialState;
    },
  },
});

export const {
  setReferrals,
  setReferral,
  pushReferrals,
  clearReferrals,
} = referralsSlice.actions;
export default referralsSlice.reducer;
