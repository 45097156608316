import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import 'delayed-scroll-restoration-polyfill/index.js';

export const ScrollController: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return null;
};
