import { createAction } from '@reduxjs/toolkit';

const ACTION_BASE_TYPE = 'PAYMENT';

export const payStart = createAction(`${ACTION_BASE_TYPE}/PAY_START`);
export const payFailed = createAction(`${ACTION_BASE_TYPE}/PAY_FAILED`);
export const paySuccess = createAction(`${ACTION_BASE_TYPE}/PAY_SUCCESS`);
export const initializeState = createAction(
  `${ACTION_BASE_TYPE}/INITIALIZE_STATE`
);
