import { createReducer } from '@reduxjs/toolkit';
import { Product } from '~redux/brand/types';
import { clearProductCart, setProductCart } from '../actions';

type ProductState = Product | null;
const initialState: ProductState = null;

const productReducer = createReducer<ProductState>(initialState, {
  [setProductCart.type]: (_, { payload }) => payload,
  [clearProductCart.type]: () => initialState,
});

export default productReducer;
