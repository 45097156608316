import { createReducer } from '@reduxjs/toolkit';
import { setNextOrdersToken, clearNextOrdersToken } from '../actions';

const initialState: string | null = null;

const nextOrdersTokenReducer = createReducer(initialState, {
  [setNextOrdersToken.type]: (_, { payload }) => payload,
  [clearNextOrdersToken.type]: (_, { payload }) => initialState,
});

export default nextOrdersTokenReducer;
