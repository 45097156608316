import React from 'react';
import useBreakpoint, { Breakpoint } from 'use-breakpoint';

// bootstrapのブレイクポイントに合わせている
export const BREAKPOINT_CONFIG = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const BreakpointContext = React.createContext<
  Partial<Breakpoint<typeof BREAKPOINT_CONFIG>> & {
    atMost?: boolean;
    atLeast?: boolean;
  }
>({});

type ProviderProps = {
  children: React.ReactNode;
};

export const BreakpointProvider = ({ children }: ProviderProps) => {
  const { breakpoint, minWidth, maxWidth } = useBreakpoint(
    BREAKPOINT_CONFIG,
    'xs'
  );

  return (
    <BreakpointContext.Provider
      value={{
        breakpoint,
        minWidth,
        maxWidth,
      }}
    >
      {children}
    </BreakpointContext.Provider>
  );
};
