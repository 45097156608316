import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import { BrandReadModel } from '~types/api';

export type BrandSearchSortType = 'recommended' | 'newest';

export type BrandListState = {
  listId: string;
  byId: Record<string, BrandReadModel>;
  isLoading: boolean;
  itemsPerPage: number;
  page: number;
  offset: number;
  nbHits: number;
  filters: Record<string, string[]>;
  filterValues: Record<string, string[]>;
  sort: BrandSearchSortType;
  category?: string;
  query?: string;
  isInitialized: boolean;
};

const initialListState: Omit<BrandListState, 'listId'> = {
  byId: {},
  isLoading: false,
  itemsPerPage: 40,
  page: 1,
  offset: 0,
  nbHits: 0,
  filters: {},
  filterValues: {},
  sort: 'recommended',
  isInitialized: true,
};

type BrandSearchState = {
  byList: Record<string, BrandListState>;
};

const initialState: BrandSearchState = {
  byList: {},
};

const brandSlice = createSlice({
  name: 'search/brand',
  initialState,
  reducers: {
    initializedBrandSearchList(
      state,
      { payload }: PayloadAction<Partial<BrandListState>>
    ) {
      state.byList[payload.listId!] = {
        listId: payload.listId!,
        ...initialListState,
        ...payload,
      };
    },
    searchedBrands(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        state.byList[payload].isLoading = true;
      }
    },
    recievedBrands(
      state,
      {
        payload: { listId, brands },
      }: PayloadAction<{ listId: string; brands: BrandReadModel[] }>
    ) {
      if (state.byList[listId]) {
        brands.forEach((brand) => {
          state.byList[listId].byId[brand.id!] = brand;
        });
        state.byList[listId].isLoading = false;
      }
    },
    clearedBrands(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        state.byList[payload].byId = {};
      }
    },
    recievedNbHits(
      state,
      {
        payload: { listId, nbHits },
      }: PayloadAction<{ listId: string; nbHits: number }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].nbHits = nbHits;
      }
    },
    changedPage(
      state,
      {
        payload: { listId, page },
      }: PayloadAction<{ listId: string; page: number }>
    ) {
      state.byList[listId].page = page;
    },
    changedOffset(
      state,
      {
        payload: { listId, offset },
      }: PayloadAction<{ listId: string; offset: number }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].offset = offset;
      }
    },
    clearedBrandSearchState(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        delete state.byList[payload];
      }
    },
    changedFilters(
      state,
      {
        payload: { listId, attribute, items },
      }: PayloadAction<{ listId: string; attribute: string; items: string[] }>
    ) {
      if (state.byList[listId]) {
        if (!items.length) {
          delete state.byList[listId].filters[attribute];
        } else {
          state.byList[listId].filters[attribute] = items;
        }
        state.byList[listId].page = 1;
      }
    },
    changedFilterValues(
      state,
      {
        payload: { listId, attribute, items },
      }: PayloadAction<{ listId: string; attribute: string; items: string[] }>
    ) {
      if (state.byList[listId]) {
        if (!items.length) {
          delete state.byList[listId].filterValues[attribute];
        } else {
          state.byList[listId].filterValues[attribute] = items;
        }
        state.byList[listId].page = 1;
      }
    },
    clearedFilters(state, { payload }: PayloadAction<string>) {
      if (state.byList[payload]) {
        state.byList[payload].filters = {};
        state.byList[payload].filterValues = {};
      }
    },
    changedSortType(
      state,
      {
        payload: { listId, sort },
      }: PayloadAction<{ listId: string; sort: BrandSearchSortType }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].sort = sort;
        state.byList[listId].page = 1;
      }
    },
    changedItemsPerPage(
      state,
      {
        payload: { listId, itemsPerPage },
      }: PayloadAction<{ listId: string; itemsPerPage: number }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].itemsPerPage = itemsPerPage;
      }
    },
    changedCategory(
      state,
      {
        payload: { listId, category },
      }: PayloadAction<{
        listId: string;
        category?: string;
      }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].category = category;
        state.byList[listId].page = 1;
      }
    },
    changedQuery(
      state,
      {
        payload: { listId, query },
      }: PayloadAction<{ listId: string; query?: string }>
    ) {
      if (state.byList[listId]) {
        state.byList[listId].query = query;
        state.byList[listId].page = 1;
      }
    },
  },
});

export const {
  initializedBrandSearchList,
  searchedBrands,
  recievedBrands,
  clearedBrands,
  recievedNbHits,
  changedPage,
  changedOffset,
  clearedBrandSearchState,
  changedFilters,
  changedFilterValues,
  clearedFilters,
  changedItemsPerPage,
  changedSortType,
  changedCategory,
  changedQuery,
} = brandSlice.actions;

export default brandSlice.reducer;

export const selectBrandSearchState = (state: RootState) => state.search.brand;
export const selectBrandListId = (_: RootState, listId: string) => listId;
export const selectBrandListState = createSelector(
  selectBrandSearchState,
  selectBrandListId,
  (state, listId: string) => state.byList[listId]
);
export const selectIsBrandListInitialized = createSelector(
  selectBrandListState,
  (state) => state?.isInitialized
);
export const selectIsBrandSearching = createSelector(
  selectBrandListState,
  (state) => state.isLoading
);
export const makeSelectBrandById = (brandId: string) =>
  createSelector(selectBrandListState, (state) => state.byId[brandId]);
export const selectBrands = createSelector(selectBrandListState, (state) => {
  const brands = Object.values(state.byId);
  const start = (state.page - 1) * state.itemsPerPage - state.offset;
  return brands.slice(start, start + state.itemsPerPage);
});
export const selectBrandListOffset = createSelector(
  selectBrandListState,
  (state) => state.offset
);
export const selectBrandListPage = createSelector(
  selectBrandListState,
  (state) => state.page
);
export const selectBrandListNbPages = createSelector(
  selectBrandListState,
  (state) => Math.max(Math.ceil(state.nbHits / state.itemsPerPage), 1)
);
export const selectBrandListFilters = createSelector(
  selectBrandListState,
  (state) => state.filters
);
export const selectBrandListFilterStrings = createSelector(
  selectBrandListState,
  (state) => {
    return Object.values(state.filterValues).length
      ? Object.values(state.filterValues).map((items) => items.join(' OR '))
      : [];
  }
);
export const makeSelectBrandListFilterValuesByAttribute = (attribute: string) =>
  createSelector(
    selectBrandListState,
    (state) => state.filterValues[attribute]
  );
export const selectBrandListSortType = createSelector(
  selectBrandListState,
  (state) => state.sort
);
export const selectBrandListCategory = createSelector(
  selectBrandListState,
  (state) => state.category
);
export const selectBrandListQuery = createSelector(
  selectBrandListState,
  (state) => state.query
);
export const selectBrandListNbHits = createSelector(
  selectBrandListState,
  (state) => state.nbHits
);
