import { AnyAction } from 'redux';
import {
  ADD_BRAND,
  ADD_BRANDS,
  SET_BRANDS,
  CLEAR_ALL_BRANDS,
} from '../actions';

type Brand = any; // TODO 型付け
type BrandItemsState = Brand[];

const initialState: BrandItemsState = [];

const itemsReducer = (
  state: BrandItemsState = initialState,
  action: AnyAction
): BrandItemsState => {
  const { payload } = action;
  switch (action.type) {
    case ADD_BRAND: {
      return [...state, payload];
    }
    case ADD_BRANDS: {
      return [...state, ...payload];
    }
    case SET_BRANDS: {
      return payload;
    }
    case CLEAR_ALL_BRANDS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default itemsReducer;
