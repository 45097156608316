import { executeQuery } from '~utils/graphql';
import * as queries from '../../graphql/queries';

export enum ContentType {
  Features = 'features',
}

export const getContent = async (
  endpoint: string,
  contentId: string,
  draftKey?: string,
  unAuth?: boolean
) => {
  const res = await executeQuery(
    queries.getContent,
    {
      endpoint,
      contentId,
      draftKey,
    },
    unAuth
  );
  const {
    data: { getContent: item },
  } = res;
  const content = JSON.parse(item);
  return content?.contents ? content.contents[0] : content;
};

export const listContents = async (endpoint: string) => {
  const res = await executeQuery(queries.getContent, {
    endpoint,
  });
  const {
    data: { getContent: item },
  } = res;
  const content = JSON.parse(item);
  return content.contents;
};

export const getFeaturedContent = async (
  contentId: string,
  draftKey?: string
) => {
  return await getContent(ContentType.Features, contentId, draftKey);
};

export const listFeaturedContents = async () => {
  return await listContents(ContentType.Features);
};
