import moment from 'moment';
import { listPointsByAccountWithHistory } from '../../graphql/custom_queries';
import { getPoint } from '../../graphql/queries';
import Point, { PointHistoryType, PointType, TPoint } from './Point';
import { Point as ApiPoint } from '~types/api';
import { executeQuery } from '~utils/graphql';

export const list = async (
  accountId: string,
  sortDirection: string,
  available?: boolean
) => {
  const res = await executeQuery(listPointsByAccountWithHistory, {
    account_id: accountId,
    sortDirection,
    filter: available ? { points: { gt: 0 } } : undefined,
    limit: 1000,
  });
  const {
    data: {
      listPointsByAccount: { items },
    },
  } = res;
  return items.map((item: any) => ({
    ...item,
    history: item.history?.items,
  })) as TPoint[];
};

export type AttachedPoint = {
  point: TPoint;
  points: number;
  isReferral?: boolean;
};

export const attach = async (
  accountId: string,
  brandId: string,
  price: number,
  referralPoints: number,
  inputPoints: number
) => {
  const pointList = await list(accountId, 'ASC', true);
  let remainingPrice = price;
  let unAttached = inputPoints;
  let attachedPoints: AttachedPoint[] = [];

  //リファラルポイントを引き当て
  if (referralPoints) {
    const referralPoint = pointList.filter(
      (point) =>
        point.point_type === PointType.earnedByEntry &&
        point.referral_brand_id === brandId &&
        !Point.createInstance(point).isExpired
    )?.[0];
    if (referralPoint) {
      const points = Math.min(referralPoints, remainingPrice);
      attachedPoints = [
        ...attachedPoints,
        {
          point: referralPoint,
          points,
          isReferral: true,
        },
      ];
      remainingPrice -= points;
    }
  }

  //残りのポイントを引き当て
  if (unAttached && remainingPrice) {
    for (const point of pointList.filter(
      (point) =>
        !(
          point.point_type === PointType.earnedByEntry &&
          point.referral_brand_id
        ) && !Point.createInstance(point).isExpired
    )) {
      if (!unAttached) break;
      const points = Math.min(unAttached, remainingPrice, point.points);
      attachedPoints = [...attachedPoints, { point, points }];
      unAttached -= points;
    }
  }

  return attachedPoints;
};

export const pay = async (
  pointId: string,
  orders: { orderId: string; usedPoints: number }[]
) => {
  //ポイントテーブルを取得
  const res = await executeQuery(getPoint, { id: pointId });
  const {
    data: { getPoint: item },
  } = res;
  const point = Point.createInstance(item);

  //ポイント履歴を登録
  const result = await Promise.all(
    orders.map(async ({ orderId, usedPoints: points }) => {
      //PointHistoryを登録
      const { data } = await point.createHistory(
        PointHistoryType.pay,
        points,
        orderId
      );
      return data?.createPointHistory;
    })
  );

  //ポイントテーブルを更新
  await point.pay(
    orders.reduce((prev, { usedPoints: points }) => prev + points, 0)
  );

  return result;
};

export const getExpirationDate = (point: ApiPoint) => {
  const createdAt = moment(point.createdAt).startOf('day');
  return createdAt.add(point.duration, 'days').endOf('day').toDate();
};

export const isExpired = (point: ApiPoint, date: Date = new Date()) => {
  const expirationDate = getExpirationDate(point);
  return Number(expirationDate) < Number(date);
};
