import { Stripe } from '@stripe/stripe-js';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { PartialRequired } from './../../types/utils';
import { Entity } from '~core/domain/Entity';
import { ChargeStatusType } from '~redux/payment/types';
import { DeclineCode, loadStripe, resolveError } from '~utils/stripe';

type PaymentType = {
  stripe: Stripe;
  stripeClientSecret?: string;
  stripePaymentId?: string;
  stripePaymentMethodId: string;
  orderId: string;
  brandOwner?: string;
  orderOwner: string;
};

export class Payment extends Entity<PaymentType> {
  static async create(props: Omit<PaymentType, 'stripe'>) {
    const stripe = await loadStripe();

    if (!stripe) {
      throw Error('stripeインスタンス初期化に失敗しました。');
    }

    return new Payment({
      ...props,
      stripe,
    });
  }

  async capture(captureMethod: 'automatic' | 'manual') {
    if (this.stripePaymentId) {
      if (captureMethod === 'automatic') {
        const capturePayment = await this.stripe.confirmCardPayment(
          this.stripeClientSecret!
        );

        if (capturePayment?.error) {
          const declineCode =
            capturePayment.error.decline_code ?? capturePayment.error.code;
          const errorMessage = resolveError(declineCode as DeclineCode);
          throw new Error(errorMessage);
        }
      }

      if (captureMethod === 'manual') {
        const response = await API.graphql<any>(
          graphqlOperation(mutations.capturePayment, {
            paymentIntentId: this.stripePaymentId,
          })
        );
        const {
          data: { capturePayment },
        } = response;

        if (capturePayment.error_code) {
          const errorMessage = resolveError(
            capturePayment.error_code as DeclineCode
          );
          throw new Error(errorMessage);
        }

        if (!response || response.errors) {
          const errorMessage = resolveError();
          throw new Error(errorMessage);
        }
      }
    }

    // 決済ステータスを登録
    await API.graphql<any>(
      graphqlOperation(mutations.createChargeStatus, {
        input: {
          order_id: this.orderId,
          status: ChargeStatusType.charged,
          owners: [this.orderOwner, this.brandOwner].filter((value) => !!value),
        },
      })
    );
  }

  async update({
    price,
    metadata,
    isPostpayment,
    isExtend = false,
  }: {
    price?: number;
    metadata?: PartialRequired<
      Partial<{
        order_id: string;
        coupon_id: string;
        shopName: string;
        brandName: string;
        products: Partial<{
          productName: string;
          productNumber: string;
          quantity: number;
        }>[];
      }>,
      'order_id'
    >;
    isPostpayment?: boolean;
    isExtend?: boolean;
  }) {
    if (price === undefined && metadata) {
      const newPayment = await this.updateMetadata({ metadata });
      return newPayment;
    }

    const newPayment = await this.updatePrice({
      price,
      metadata,
      isPostpayment: isPostpayment!,
      isExtend,
    });

    return newPayment;
  }

  async cancel() {
    const res = await API.graphql<any>(
      graphqlOperation(mutations.cancelPayment, {
        paymentId: this.stripePaymentId,
      })
    );
    if (!res || !res.data || res.errors) {
      throw new Error('決済情報の取り消しに失敗しました。');
    }
  }

  private async updatePrice({
    price,
    metadata,
    isPostpayment,
    isExtend = false,
  }: {
    price?: number;
    metadata?: any;
    isPostpayment: boolean;
    isExtend?: boolean;
  }) {
    const stripe = await loadStripe();
    const response = await stripe
      ?.retrievePaymentIntent(this.stripeClientSecret!)
      .catch(() => ({ paymentIntent: undefined }));
    const originalPaymentIntent = response?.paymentIntent;

    //元の決済情報をキャンセル
    if (!isExtend) {
      const response = await API.graphql<any>(
        graphqlOperation(mutations.cancelPayment, {
          paymentId: this.stripePaymentId,
        })
      );
      const code = response.data.cancelPayment.code;
      const status = response.data.cancelPayment.status;

      if (
        code &&
        status &&
        (code !== 'payment_intent_unexpected_state' || status !== 'canceled')
      ) {
        throw new Error('決済のキャンセルに失敗');
      }
    }

    if (price !== undefined && price <= 0) {
      return this;
    }

    //新規の決済情報を登録
    const res = await API.graphql<any>(
      graphqlOperation(mutations.updatePayment, {
        paymentId: this.stripePaymentId,
        price,
        metadata,
      })
    );
    if (!res || !res.data || res.errors) {
      throw new Error('決済情報の更新に失敗しました。');
    }

    const newPaymentIntent = res.data.updatePayment as {
      id: string;
      client_secret: string;
    };

    const newPayment = await Payment.create({
      ...this,
      stripePaymentId: newPaymentIntent.id,
      stripeClientSecret: newPaymentIntent.client_secret,
    });

    await API.graphql<any>(
      graphqlOperation(mutations.updateOrder, {
        input: {
          id: this.orderId,
          stripe_payment_id: newPayment.stripePaymentId,
          stripe_client_secret: newPayment.stripeClientSecret,
        },
      })
    );

    if (!isPostpayment) {
      const {
        data: { authorizePayment },
      } = await API.graphql<any>(
        graphqlOperation(mutations.authorizePayment, {
          paymentId: newPaymentIntent.id,
        })
      );

      if (authorizePayment.error_code) {
        const errorMessage = resolveError(authorizePayment.error_code);
        if (!isExtend) {
          await newPayment.updatePrice({
            price: originalPaymentIntent?.amount,
            metadata,
            isPostpayment,
            isExtend,
          });
        }
        throw new Error(errorMessage);
      }
    }

    return newPayment;
  }

  private async updateMetadata({
    metadata,
  }: {
    metadata: { order_id: string };
  }) {
    // メタデータを更新
    await API.graphql<any>(
      graphqlOperation(mutations.updatePaymentMeta, {
        paymentId: this.stripePaymentId,
        metadata: metadata,
      })
    );

    return this;
  }
}
