import { createAction } from '@reduxjs/toolkit';
import { ActionCreator } from '../../../types/redux';
import { Brand } from '../types';

const ACTION_BASE_TYPE = 'BRAND';

export const ADD_BRAND = `${ACTION_BASE_TYPE}/ADD_BRAND`;
export const addBrand: ActionCreator<Brand> = (newBrand: Brand) => {
  return {
    type: ADD_BRAND,
    payload: newBrand,
  };
};

export const ADD_BRANDS = `${ACTION_BASE_TYPE}/ADD_BRANDS`;
export const addBrands: ActionCreator<Brand[]> = (newBrands: Brand[]) => {
  return {
    type: ADD_BRANDS,
    payload: newBrands,
  };
};

export const SET_BRANDS = `${ACTION_BASE_TYPE}/SET_BRANDS`;
export const setBrands: ActionCreator<Brand[]> = (signedBrands: Brand[]) => {
  return {
    type: SET_BRANDS,
    payload: signedBrands,
  };
};

export const CLEAR_ALL_BRANDS = `${ACTION_BASE_TYPE}/CLEAR_ALL_BRANDS`;
export const clearAllBrands: ActionCreator = () => {
  return {
    type: CLEAR_ALL_BRANDS,
  };
};

export const GET_BRANDS_START = `${ACTION_BASE_TYPE}/GET_BRANDS_START`;
export const getBrandsStart: ActionCreator = () => {
  return {
    type: GET_BRANDS_START,
  };
};

export const GET_SIGNED_BRANDS_START = `${ACTION_BASE_TYPE}/GET_SIGNED_BRANDS_START`;
export const getSignedBrandsStart: ActionCreator = () => {
  return {
    type: GET_SIGNED_BRANDS_START,
  };
};

export const GET_SIGNED_BRAND_START = `${ACTION_BASE_TYPE}/GET_SIGNED_BRAND_START`;
export const getSignedBrandStart: ActionCreator = () => {
  return {
    type: GET_SIGNED_BRAND_START,
  };
};

export const RECEIVE_NEXT_TOKEN = `${ACTION_BASE_TYPE}/RECEIVE_NEXT_TOKEN`;
export const receiveNextToken: ActionCreator<string> = (nextToken: string) => {
  return {
    type: RECEIVE_NEXT_TOKEN,
    payload: nextToken,
  };
};

export const CLEAR_NEXT_TOKEN = `${ACTION_BASE_TYPE}/CLEAR_NEXT_TOKEN`;
export const clearNextToken: ActionCreator = () => {
  return {
    type: CLEAR_NEXT_TOKEN,
  };
};

export const searchBrandsStart = createAction(
  `${ACTION_BASE_TYPE}/SEARCH_BRANDS_START`
);

export const addSearchBrands = createAction<{
  items: Brand;
  nextToken: string | null;
}>(`${ACTION_BASE_TYPE}/ADD_SEARCH_BRANDS`);

export const receiveSearchBrands = createAction<{
  items: Brand;
  nextToken: string | null;
}>(`${ACTION_BASE_TYPE}/RECEIVE_SEARCH_BRANDS`);

export const clearSearchBrands = createAction(
  `${ACTION_BASE_TYPE}/clear_search_brands`
);
