import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../../layouts/footer/Footer';
import Header from '../../../layouts/header/Header';
import DirectLinkHeader from '../DirectLinkHeader';
import ErrorBoundary from '../ErrorBoundary';
import LoadingContainer from '../LoadingContainer';
import { authenticatingSelector } from '~redux/auth/selectors/authenticating';
import { Brand } from '~redux/brand/types';
import { useTitle } from '~view/hooks/title';
import Head from '~view/pages/common/Head';

type PageProps = {
  title?: string;
  loginLink?: string;
  hideMenu?: boolean;
  direct?: boolean;
  directBrand?: Brand;
  contactId?: string;
};

const PublicPage: React.FC<PageProps> = ({
  title,
  loginLink,
  hideMenu,
  direct,
  directBrand,
  contactId,
  children,
}) => {
  const isAuthenticating = useSelector(authenticatingSelector);
  const sellOnlyDirect = useMemo(
    () => direct && directBrand?.sell_only_direct,
    [direct, directBrand]
  );

  useTitle(title);

  if (isAuthenticating) {
    return <LoadingContainer isLoading />;
  }

  return (
    <>
      <Head />
      {direct && directBrand ? (
        <DirectLinkHeader brand={directBrand!} contactId={contactId} />
      ) : (
        <Header loginLink={loginLink} hideMenu={hideMenu} />
      )}
      <ErrorBoundary>{children}</ErrorBoundary>
      {!sellOnlyDirect && <Footer />}
    </>
  );
};

export default PublicPage;
